<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
	props: {
		dismissable: {
			default: true,
			type: Boolean,
		},
		overlayClickDismiss: {
			default: false,
			type: Boolean,
		},
		size: {
			default: 'md',
			type: String,
		},
		title: {
			type: String,
		},
		isCreateModal: {
			type: Boolean,
			default: false,
		},
		isEditModal: {
			type: Boolean,
			default: false,
		},
		extraContentClasses: {
			type: String,
			default: '',
		},

	},
	emits: [
		'hide',
		'show',
	],
	watch: {

		async show(value): Promise<void> {
			if (value) {
				await this.$nextTick();

				this.$emit('show');

				(this.$refs.modalContent as HTMLDivElement).scrollTo(0, 0);
			}
		},
		visible(newVal) {
			if (newVal) {
				this.$nextTick(() => {
					if (this.$refs.modalElement) {
						(this.$refs.modalElement as HTMLElement).focus();
					}
				});
			}
		}


	},
	data() {
		return {
			visible: false as boolean,
		};
	},
	methods: {

		hide(): void {
			this.visible = false;

			this.$emit('hide');
		},

		async show(): Promise<void> {
			this.visible = true;

			this.$emit('show');
		},

		// Events
		buttonCloseClick(): void {
			this.hide();
		},

		modalOverlayClick(): void {
			if (this.overlayClickDismiss) {
				this.hide();
			}
		},

	}
});
</script>
<template>
	<Teleport to="body">
		<div
			ref="modalElement"
			v-show="visible"
			:class="['fixed flex items-center justify-center h-screen left-0 top-0 w-screen modal-basic', $attrs.class]"
			@keyup.esc="buttonCloseClick"
			tabindex="-1"
		>
			<div
				:class="[
					'flex flex-col justify-between bg-white rounded-md shadow-lg modal-dialog',
					size
				]"
			>
				<div v-if="$slots.header || title"
					:class="['modal-header', { 'flex justify-between p-4': title }]"
				>
					<slot name="header">
						<div class="flex items-center justify-center">
							<div
								v-if="isCreateModal"
								class="rounded-[50%] bg-[#3F87F5] w-[35px] h-[35px] shrink-0 mr-2 flex items-center justify-center"
							>
								<svg viewBox="0 0 24 24"
									class="w-full h-full fill-[#fff]"
								>
									<path d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
								</svg>
							</div>
							<div
								v-if="isEditModal"
								class="rounded-[50%] bg-[#3F87F5] w-[35px] h-[35px] shrink-0 mr-2 flex items-center justify-center"
							>
								<svg viewBox="0 0 24 24"
									class="w-[65%] h-[65%] fill-[#fff]"
								>
									<path d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z" />
								</svg>
							</div>
							<span
								class="text-2xl flex items-center justify-center h-full font-bold"
							>
								{{ title }}
							</span>
						</div>
						<button v-if="dismissable"
							@click="buttonCloseClick"
						>
							<svg class="w-6 h-6"
								fill="currentColor"
								viewBox="0 0 20 20"
								xmlns="http://www.w3.org/2000/svg"
							><path fill-rule="evenodd"
								d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
								clip-rule="evenodd"
							/></svg>
						</button>
					</slot>
				</div>
				<div :class="['modal-content', 'flex-1', extraContentClasses]"
					ref="modalContent"
				>
					<slot name="content" />
				</div>
				<div v-if="$slots.footer"
					class="modal-footer"
				>
					<slot name="footer" />
				</div>
			</div>
		</div>
	</Teleport>
</template>
<style lang="scss" scoped>
.modal-basic {
    background-color: rgba(59,66,77,.7);
    z-index: 1000;

    .modal-dialog {
        background-color: #fff;
        max-height: 90vh;

        &.xs {
            width: 150px;
        }

        &.sm {
            width: 300px;
        }

        &.md {
            width: 512px;
        }

        &.lg {
            width: 800px;
        }

        &.xl {
            width: 1024px;
        }

        &.full {
            width: 90vw;
        }

        .modal-content {
            overflow: auto;
        }

    }
}
</style>
