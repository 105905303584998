<!-- eslint-disable vue/valid-template-root -->
<script lang="ts">
import auth from '../services/auth';
import { defineComponent } from 'vue';

export default defineComponent({
	beforeMount() {
		auth
			.signout()
			.finally(
				() => {
					this.$router.replace('/login');
				}
			);
	},
});
</script>
<template>
	<div />
</template>