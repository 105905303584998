<script lang="ts">
import {
	useGlobalStore, GlobalState
} from '../../stores/global';

import storage from '../../services/storage';

import LogoCj from './LogoCj.vue';
import SidebarDropdown from './SidebarDropdown.vue';
import {
	defineComponent, inject
} from 'vue';
import { Helpers } from '../../models/crm';
import {
	Store
} from 'pinia';

export default defineComponent({
	components: {
		LogoCj,
		SidebarDropdown,
	},
	data() {
		return {
			store: {} as Store<'global', GlobalState, {
				sidebar(state: GlobalState): {
					isCollapsed: boolean;
					isVisible: boolean;
				};
			}, {
				setSidebarCollapse(value: boolean): void;
				toggleSidebarCollapse(): void;
				setSidebarShow(value: boolean): void;
			}>,
			envVars: inject<Record<string, string>>('envVars'),
			helpers: inject<Helpers>('helpers'),
		};
	},
	computed: {

		currentYear(): number {
			const now = new Date();

			return now.getFullYear();
		},

		isCrm(): boolean {
			return !!this.$route.fullPath.match(/\/crm/);
		},

		isFinance(): boolean {
			return !!this.$route.fullPath.match(/\/financas/);
		},

	},
	mounted() {
		const globalStore = useGlobalStore();
		this.store = globalStore;

		if (window.innerWidth < 1150) {
			globalStore.setSidebarCollapse(true);

			storage.setItem('localStorage', 'global/ui/sidebar/is_collapsed', true);

			return;
		}

		this.setSidebarStateInitial();
	},
	methods: {
		setSidebarStateInitial(): void {
			this.store.setSidebarCollapse(storage.getItem('localStorage', 'global/ui/sidebar/is_collapsed') === 'true');
		},

		// Events

		buttonCollapseClick(): void {
			this.store.toggleSidebarCollapse();
		},

	},

});
</script>
<template>
	<div
		id="sidebar"
		:class="{ collapsed: store?.sidebar?.isCollapsed }"
	>
		<div class="side-content">
			<logo-cj :collapsed="store?.sidebar?.isCollapsed || false" />
			<nav class="main-items">
				<a
					v-tippy="helpers?.getTippy('Início')"
					:href="`${envVars?.VITE_CJ_WEB_APP_URL}bem-vindo`"
					class="menu-item"
				>
					<div class="icon">
						<svg
							class="h-5 w-5"
							viewBox="0 0 512 512"
							fill="#B4C5DF"
						>
							<g>
								<g>
									<path d="M256,2.938l-256,256v48.427h62.061v201.697h155.152V384.941h77.576v124.121h155.151V307.365H512v-48.427L256,2.938z M403.394,260.82v201.697h-62.061V338.396H170.667v124.121h-62.061V260.82H63.943L256,68.762L448.057,260.82H403.394z" />
								</g>
							</g>
						</svg>
					</div>
					<div class="menu-item-title">
						<span class="main-title">Início</span>
					</div>
				</a>
				<a
					v-tippy="helpers?.getTippy('Cálculos')"
					:href="`${envVars?.VITE_CJ_WEB_APP_URL}calculos`"
					class="menu-item"
				>
					<div class="icon"><img
						src="/img/icons/icon-calc.svg"
						alt=""
					></div>
					<div class="menu-item-title">
						<span class="main-title">Cálculos</span>
					</div>
				</a>
				<a
					v-tippy="helpers?.getTippy('Contatos')"
					:href="`${envVars?.VITE_CJ_WEB_APP_URL}clientes/`"
					class="menu-item"
				>
					<div class="icon"><img
						src="/img/icons/icon-clients.svg"
						alt=""
					></div>
					<div class="menu-item-title">
						<span class="main-title">Contatos</span>
					</div>
				</a>

				<a
					v-tippy="helpers?.getTippy('Modelos de Petições')"
					:href="`${envVars?.VITE_CJ_WEB_APP_URL}modelos-de-peticoes`"
					class="menu-item"
				>
					<div class="icon"><img
						src="/img/icons/icon-petition-templates.svg"
						alt=""
					></div>
					<div class="menu-item-title">
						<span class="main-title">Modelos de Petições</span>
					</div>
				</a>
				<a
					v-tippy="helpers?.getTippy('Cursos')"
					:href="`${envVars?.VITE_CJ_WEB_APP_URL}cursos-e-treinamentos`"
					class="menu-item"
				>
					<div class="icon"><img
						src="/img/icons/icon-courses.svg"
						alt=""
					></div>
					<div class="menu-item-title">
						<span class="main-title">Cursos e Treinamentos</span>
					</div>
				</a>
			</nav>
			<nav class="main-items">
				<router-link
					v-tippy="helpers?.getTippy('Gestão')"
					:class="{ active: isCrm }"
					:to="{name: 'crm-panel'}"
					class="menu-item"
				>
					<div class="icon icon-crm">
						<img
							src="/img/icons/icon-crm.svg"
							alt=""
						>
					</div>
					<div class="menu-item-title">
						<div class="menu-item-title menu-item-new">
							<span class="main-title">Gestão</span>
						</div>
						<div class="subtitle">
							Tarefas e casos
						</div>
					</div>
				</router-link>
				<a
					v-tippy="helpers?.getTippy('Extensões')"
					:href="`${envVars?.VITE_CJ_WEB_APP_URL}extensoes`"
					class="menu-item"
				>
					<div class="icon"><img
						src="/img/icons/icon-extensions.svg"
						alt=""
					></div>
					<div class="menu-item-title menu-item-title menu-item-new">
						<span class="main-title">Extensões e Utilidades</span>
					</div>
				</a>
			</nav>
			<div class="help-side-menu">
				<div class="help-title">
					AJUDA
				</div>
				<a
					v-tippy="helpers?.getTippy('Central de ajuda')"
					href="https://ajuda.calculojuridico.com.br/pt-BR"
					target="_blank"
					class="menu-item"
				>
					<div class="icon"><img
						src="/img/icons/icon-help.svg"
						alt=""
					></div>
					<div class="whitespace-nowrap menu-item-title">
						<span class="main-title link--external">
							Central de ajuda
							<img
								src="/img/icons/icon-external-link.svg"
								alt=""
							>
						</span>
					</div>
				</a>
				<a
					v-tippy="helpers?.getTippy('Vídeos tutoriais')"
					href="https://ajuda.calculojuridico.com.br/pt-BR/collections/4013286-videos-tutoriais"
					target="_blank"
					class="menu-item"
				>
					<div class="icon"><img
						src="/img/icons/icon-video-tutorial.svg"
						alt=""
					></div>
					<div class="whitespace-nowrap menu-item-title">
						<span class="main-title link--external">
							Vídeos tutoriais
							<img
								src="/img/icons/icon-external-link.svg"
								alt=""
							>
						</span>
					</div>
				</a>
			</div>
		</div>
		<div>
			<div class="copyright-container text-center">
				<p>
					© {{ currentYear }} Cálculo Jurídico
				</p>
			</div>
			<div class="my-3 container-button-collapse">
				<button @click="buttonCollapseClick">
					<span>
						<svg
							class="w-6 h-6"
							fill="currentColor"
							viewBox="0 0 20 20"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fill-rule="evenodd"
								d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
								clip-rule="evenodd"
							/>
						</svg>
					</span>
				</button>
			</div>
		</div>
	</div>
	<Teleport to="body">
		<SidebarDropdown :trigger-element="($refs.buttonTables as HTMLButtonElement)">
			<a :href="`${envVars?.VITE_CJ_WEB_APP_URL}tabelas/correcao-salarios-contribuicao`">
				<svg viewBox="0 0 24 24">
					<path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
				</svg>
				<span>Índices de Correção dos Salários de Contribuição</span>
			</a>
			<a :href="`${envVars?.VITE_CJ_WEB_APP_URL}tabelas/correcao-monetaria`">
				<svg viewBox="0 0 24 24">
					<path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
				</svg>
				<span>Índices de Correção Monetária</span>
			</a>
			<a :href="`${envVars?.VITE_CJ_WEB_APP_URL}tabelas/selic`">
				<svg viewBox="0 0 24 24">
					<path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
				</svg>
				<span>Índices Selic</span>
			</a>
			<a :href="`${envVars?.VITE_CJ_WEB_APP_URL}tabelas/reajustes-integrais`">
				<svg viewBox="0 0 24 24">
					<path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
				</svg>
				<span>Reajustes Integrais</span>
			</a>
			<a :href="`${envVars?.VITE_CJ_WEB_APP_URL}tabelas/reajustes-proporcionais`">
				<svg viewBox="0 0 24 24">
					<path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
				</svg>
				<span>Reajustes Proporcionais</span>
			</a>
			<a :href="`${envVars?.VITE_CJ_WEB_APP_URL}tabelas/salarios-minimos-tetos`">
				<svg viewBox="0 0 24 24">
					<path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
				</svg>
				<span>Salários Mínimos e Tetos</span>
			</a>
			<a :href="`${envVars?.VITE_CJ_WEB_APP_URL}tabelas/expectativas-vida`">
				<svg viewBox="0 0 24 24">
					<path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
				</svg>
				<span>Expectativas de vida</span>
			</a>
		</SidebarDropdown>
	</Teleport>
	<Teleport to="body">
		<SidebarDropdown :trigger-element="($refs.buttonUtilities as HTMLButtonElement)">
			<div class="mb-2">
				Utilidades para facilitar a sua vida!
				<br>
				Cálculos das <b>Utilidades</b> são ilimitados!
			</div>
			<div>
				<a :href="`${envVars?.VITE_CJ_WEB_APP_URL}utilidades/calculadora-tempo-contribuicao`">
					<svg viewBox="0 0 24 24">
						<path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
					</svg>
					<span>Calculadora simplificada de Tempo de Contribuição</span>

				</a>
				<a :href="`${envVars?.VITE_CJ_WEB_APP_URL}utilidades/calculadora-qualidade-segurado`">
					<svg viewBox="0 0 24 24">
						<path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
					</svg>
					<span>Calculadora de Qualidade de Segurado</span>
				</a>
				<a :href="`${envVars?.VITE_CJ_WEB_APP_URL}utilidades/calculadora-fator-previdenciario`">
					<svg viewBox="0 0 24 24">
						<path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
					</svg>
					<span>Calculadora de Fator Previdenciário</span>
				</a>
				<a :href="`${envVars?.VITE_CJ_WEB_APP_URL}utilidades/calculos-evolucao-rmi`">
					<svg viewBox="0 0 24 24">
						<path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
					</svg>
					<span>Calculadora de evolução da RMI</span>
				</a>
				<a
					href="https://calculojuridico.com.br/calculadora-ruido-normalizado/"
					rel="noopener noreferrer"
					target="_blank"
				>
					<svg viewBox="0 0 24 24">
						<path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
					</svg>
					<span>Calculadora de Nível de Ruído</span>
				</a>
				<a
					href="https://calculojuridico.com.br/honorarios-previdenciarios/"
					rel="noopener noreferrer"
					target="_blank"
				>
					<svg viewBox="0 0 24 24">
						<path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
					</svg>
					<span>Calculadora de Honorários Previdenciários</span>
				</a>
				<a
					href="https://calculojuridico.com.br/calculadoras-atualizadas/"
					rel="noopener noreferrer"
					target="_blank"
				>
					<svg viewBox="0 0 24 24">
						<path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
					</svg>
					<span>Outras Calculadoras</span>
				</a>
				<a
					href="https://calculojuridico.net.br/custom_parsers"
					rel="noopener noreferrer"
					target="_blank"
				>
					<svg viewBox="0 0 24 24">
						<path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
					</svg>
					<span>Ferramenta para Extrair dados de PDFs</span>
				</a>
				<a
					href="https://calculojuridico.net.br/nfe_viewers/new"
					rel="noopener noreferrer"
					target="_blank"
				>
					<svg viewBox="0 0 24 24">
						<path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
					</svg>
					<span>Ferramenta para Visualizar NFe</span>
				</a>
			</div>
		</SidebarDropdown>
	</Teleport>
</template>
<style lang="scss" scoped>
$sidebar-collapsed-width: 50px;
$sidebar-width: 164px;
$base-spacing: 20px;

#sidebar {
    background-color: #3B424D;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	grid-area: mainsidebar;
	overflow: visible;
	width: $sidebar-width;
	z-index: 100;

    &.collapsed {
        width: $sidebar-collapsed-width;

        :deep(#logo-cj) {

            .symbol {
                margin-left: 3px;
            }

            .types {
                margin-left: 0;
                width: 0;
            }
        }

        .side-content {
			width: 100%;
		}

        .copyright-container, .help-title, .menu-item-title{
            display: none;
        }

        .container-button-collapse {
            width: $sidebar-collapsed-width;

            button {
                span {
                    transform: rotate(0deg);
                }
            }
        }
    }

    .main-items {
		padding: 13px 0 8px;
		border-bottom: 1px solid #2e343e;
		display: flex;
		flex-direction: column;
    }
	.menu-item {
		display: grid;
		grid-template-columns: 50px 1fr;
		align-items: center;
		padding: 14px 0;
		border-right: 5px solid #3B424D;
		cursor: pointer;
		width: 100%;
		border: 0;
		transition: background 0.12s cubic-bezier(0, 0, 0.3, 1);

		.icon {
			svg {
				margin: 0 auto;
				display: block;

				path {
					fill: #B4C5DF;
				}
			}

			img {
				margin: 0 auto;
				display: block;
			}
		}

		.menu-item-title {
			font-weight: 500;
			line-height: 16px;
			font-size: 12px;

			.main-title {
				color: #B4C5DF;
			}

			.subtitle {
				color: rgb(117, 124, 136);
				font-size: 10px;
				padding-right: 5px;
			}
		}

		.link--external {
			img {
				width: 1.2em;
				height: 1.2em;
				display: inline;
				vertical-align: top;
			}
		}

		&.show {
			background-color: #2e343e;

			.menu-item-title {
			.main-title {
				color: white;
			}
			}

			.icon {
				svg path {
					fill: white;
					margin: 0 auto;
					display: block;
				}
			}
		}

		&:hover,
		&:focus,
		&:active,
		&.active {
			outline: none;
			text-decoration: none;
			border-color: #2e343e;
			background-color: #2e343e;
			background-color: rgba(46, 52, 62, 0.6);
		}

		&.active {
			border-right: 5px solid #3F87F5;
			background-color: #2e343e;

			.menu-item-title {
				.main-title {
					color: white;
				}
			}

			.icon {
				svg path {
					fill: white;
					margin: 0 auto;
					display: block;
				}
			}
		}

		&.active:focus {
			background-color: rgba(46, 52, 62, 0.6);
		}
	}

	.menu-item-new .main-title::after {
      @apply px-1 py-0.5 mx-2 text-white rounded-sm;

      content: "Novo";
      font-size: 9px;
      background-color: #007bff;
    }

    .help-side-menu {
		.help-title {
			font-weight: 600;
			font-size: 12px;
			text-transform: uppercase;
			color: #B4C5DF;
			margin: 18px 12px 8px;
		}

		.menu-item {
			position: relative;
			grid-template-columns: 38px 1fr;
			padding: 10px 0;

			.icon {
				svg,
				img {
					margin: 0 0 0 15px;
				}
			}
		}
	}

    .copyright-container {
		p {
			color: rgba(180, 197, 223, 0.5);
			margin: 0 0 $base-spacing;
			font-size: 10px;
			font-weight: 500;
		}
	}

    .container-button-collapse {
        display: flex;
        justify-content: flex-end;

        width: $sidebar-width;

        button {
            border: 1px solid #b4c5df80;
            border-right: none;
            border-radius: 3px 0 0 3px;
            display: inline-flex;
            padding: 0.25em 0;

            span {
                transform: rotate(180deg);

                svg {
                    fill: #b4c5df80;
                    height: 20px;
                    width: 20px;
                }
            }
        }
    }
}
</style>
