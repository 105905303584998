<script lang="ts">
import {
	defineComponent, PropType
} from 'vue';
export default defineComponent({
	emits: [ 'close' ],
	props: {
		triggerElement: {
			type: Object as PropType<Element>
		},
		width: {
			default: 300,
			type: Number,
		},
		smallPadding: {
			default: false,
			type: Boolean,
		},
	},
	data() {
		return {

			dropdownPosition: {
				left: '0px',
				top: '0px',
				width: `${this.width}px`,
			} as Record<string, string> | null,

			show: false,

		};
	},
	methods: {

		setDropdownPosition(): void {
			if (!this.triggerElement) {
				this.dropdownPosition = null;

				return;
			}

			const topDistance = 6;

			const {triggerElement} = this;
			const triggerElementBcr = triggerElement.getBoundingClientRect();

			this.dropdownPosition = {
				left: `${(triggerElementBcr.x - this.width) + triggerElementBcr.width}px`,
				top: `${topDistance + triggerElementBcr.y + triggerElementBcr.height}px`,
				width: `${this.width}px`,
			};
		},

		// Events

		buttonClick(): void {
			this.show = false;
		},

		clickOutside(): void {
			if (this.show) {
				this.show = false;
				this.$emit('close');
			}
		},

		toggleShow(value?: boolean): void {
			if (value !== undefined) {
				this.show = value;

				return;
			}

			this.show = !this.show;
		},
	},
	watch: {
		show(value): void {
			if (value) {
				this.$nextTick(
					() => {
						this.setDropdownPosition();
					}
				);
			}
		},
	},
});
</script>
<template>
	<div
		v-if="triggerElement"
		v-show="show"
		class="topbar-dropdown"
		:class="{'!py-2': smallPadding}"
		ref="topbarDropdown"
		:style="dropdownPosition || {}"
		v-click-outside="clickOutside"
	>
		<slot />
	</div>
</template>
<style lang="scss" scoped>
.topbar-dropdown {
    background-color: #fff;
    border: 1px solid #f0f0f0;
    border-radius: 3px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
    overflow: hidden;
    position: absolute;
    z-index: 1000;
	padding: 15px 0;
}
</style>