<script lang="ts">
import {
	defineComponent, inject
} from 'vue';
import {
	mapActions, mapState
} from 'pinia';

import { useAccountStore } from '../../../../stores/account';
import { useCategoriesStore } from '../../../../stores/models/financial/categories';

import { EventBus } from '../../../../models/crm';

import DataTable from '../../../../components/DataTable/DataTable.vue';

import DefaultSeeds from '../../components/DefaultSeeds.vue';
import ModalCategory from '../../components/ModalCategory.vue';
import ModalCostCenter from '../../components/ModalCostCenter.vue';
import {
	Category, CostCenter
} from '../../../../models/financial';

type DeleteConfig = {
	dataType: 'cost_center' | 'income_category' | 'expense_category';
	messageRequesting: string;
	messageSuccess: string;
	messageError: string;
};

export default defineComponent({
	components: {
		DataTable,

		DefaultSeeds,
		ModalCategory,
		ModalCostCenter,
	},
	data() {
		return {

			eventBus: inject<EventBus>('eventBus'),

			dataTableCostCenter: {
				columns: [
					{
						key: 'name', label: 'Nome', initial: true
					},
					{
						key: 'description', label: 'Descrição', initial: true
					},
				],
				data: [] as CostCenter[],
				dataRaw: [] as CostCenter[],
				options: {
					columnCssClass: {
						'name': 'text-blue-600 w-1/3',
					},
					initial: {
						orderBy: {
							column: 'name',
							sort: 'asc',
						},
					},
					orderableColumns: [
						'name',
					],
				},
				total_count: 100,
			},

			dataTableIncomeCategories: {
				columns: [
					{
						key: 'name', label: 'Nome', initial: true
					},
					{
						key: 'description', label: 'Descrição', initial: true
					},
				],
				data: [] as Category[],
				dataRaw: [] as Category[],
				options: {
					columnCssClass: {
						'name': 'text-blue-600 w-1/3',
					},
					initial: {
						orderBy: {
							column: 'name',
							sort: 'asc',
						},
					},
					orderableColumns: [
						'name',
					],
				},
				total_count: 100,
			},

			dataTableExpenseCategories: {
				columns: [
					{
						key: 'name', label: 'Nome', initial: true
					},
					{
						key: 'description', label: 'Descrição', initial: true
					},
				],
				data: [] as Category[],
				dataRaw: [] as Category[],
				options: {
					columnCssClass: {
						'name': 'text-blue-600 w-1/3',
					},
					initial: {
						orderBy: {
							column: 'name',
							sort: 'asc',
						},
					},
					orderableColumns: [
						'name',
					],
				},
				total_count: 100,
			},

			order: {
				incomes: {
					column: 'name',
					sort: 'asc',
				},
				expenses: {
					column: 'name',
					sort: 'asc',
				},
				costCenters: {
					column: 'name',
					sort: 'asc',
				},
			},

			requesting: {
				loadCostCenters: false,
				loadIncomeCategories: false,
				loadExpenseCategories: false,
			},

		};
	},
	computed: {
		...mapState(useAccountStore, [ 'office' ]),
		...mapState(useCategoriesStore, [ 'income_categories', 'expense_categories', 'cost_centers', 'maxIncomeCategories', 'maxExpenseCategories', 'maxCostCenters' ]),

		isFinancesSeeded(): boolean {
			return this.office.finances_seeded;
		},
	},
	methods: {
		...mapActions(useCategoriesStore, [
			'fetchIncomeCategories',
			'fetchExpenseCategories',
			'fetchCostCenters',
			'deleteIncomeCategory',
			'deleteExpenseCategory',
			'deleteCostCenter',
		]),

		async deleteSetting(id: number, config: DeleteConfig): Promise<void> {
			const {
				dataType,
				messageRequesting,
				messageSuccess,
				messageError,
			} = config;

			this.eventBus?.emit('BlockingOverlay/show', {
				text: messageRequesting,
			});

			try {
				let response = false;

				if (dataType === 'cost_center') {
					response = await this.deleteCostCenter(id);
				} else if (dataType === 'income_category') {
					response = await this.deleteIncomeCategory(id);
				} else if (dataType === 'expense_category') {
					response = await this.deleteExpenseCategory(id);
				}

				if (!response) {
					throw new Error('Erro ao excluir');
				}

				this.eventBus?.emit(
					'Toast/add',
					{
						content: messageSuccess,
						type: 'success'
					}
				);
			} catch (err) {
				console.error(err);

				this.eventBus?.emit(
					'Toast/add',
					{
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						content: (err as any)?.response?.data?.errors[0] || messageError,
						type: 'error'
					}
				);
			} finally {
				this.eventBus?.emit('BlockingOverlay/hide');
			}
		},

		async loadAll(forceReload = false): Promise<void> {
			await this.loadCostCenters(forceReload);
			await this.loadExpenseCategories(forceReload);
			await this.loadIncomeCategories(forceReload);
		},

		async loadCostCenters(forceReload = false): Promise<void> {
			try {
				this.requesting.loadCostCenters = true;

				if (!this.cost_centers.length || forceReload) {
					const orderBy = `${this.order.costCenters.column}:${this.order.costCenters.sort}`;
					await this.fetchCostCenters(orderBy);
				}

				this.dataTableCostCenter.data
					= this.dataTableCostCenter.dataRaw
					= this.cost_centers;

				this.dataTableCostCenter.total_count = this.maxCostCenters;
			} catch (err) {
				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Erro ao carregar centros de custo',
						type: 'error'
					}
				);
			}

			this.requesting.loadCostCenters = false;
		},

		async loadIncomeCategories(forceReload = false): Promise<void> {
			try {
				this.requesting.loadIncomeCategories = true;

				if (!this.income_categories.length || forceReload) {
					const orderBy = `${this.order.incomes.column}:${this.order.incomes.sort}`;
					await this.fetchIncomeCategories(orderBy);
				}

				this.dataTableIncomeCategories.data
					= this.dataTableIncomeCategories.dataRaw
					= this.income_categories;

				this.dataTableIncomeCategories.total_count = this.maxIncomeCategories;
			} catch (err) {
				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Erro ao carregar categorias de entradas',
						type: 'error'
					}
				);
			}

			this.requesting.loadIncomeCategories = false;
		},

		async loadExpenseCategories(forceReload = false): Promise<void> {
			try {
				this.requesting.loadExpenseCategories = true;

				if (!this.expense_categories.length || forceReload) {
					const orderBy = `${this.order.expenses.column}:${this.order.expenses.sort}`;
					await this.fetchExpenseCategories(orderBy);
				}

				this.dataTableExpenseCategories.data
					= this.dataTableExpenseCategories.dataRaw
					= this.expense_categories;

				this.dataTableExpenseCategories.total_count = this.maxExpenseCategories;
			} catch (err) {
				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Erro ao carregar categorias de saídas',
						type: 'error'
					}
				);
			}

			this.requesting.loadExpenseCategories = false;
		},

		async orderApply(dataType: 'incomes' | 'expenses' | 'costCenters', column: string, sort: string): Promise<void> {
			switch (dataType) {
			case 'incomes':
				this.order.incomes.column = column;
				this.order.incomes.sort = sort;

				await this.loadIncomeCategories(true);
				break;
			case 'expenses':
				this.order.expenses.column = column;
				this.order.expenses.sort = sort;

				await this.loadExpenseCategories(true);
				break;
			case 'costCenters':
				this.order.costCenters.column = column;
				this.order.costCenters.sort = sort;

				await this.loadCostCenters(true);
				break;
			}
		},

		// Events

		buttonAddCostCenterClick(): void {
			(this.$refs.modalCostCenter as typeof ModalCostCenter).show();
		},

		buttonAddCategoryClick(type: 'income' | 'expense'): void {
			(this.$refs.modalCategory as typeof ModalCategory).show(type);
		},

		buttonCategoryClick(type: 'income' | 'expense', row: Category): void {
			(this.$refs.modalCategory as typeof ModalCategory).edit(type, row);
		},

		buttonCostCenterClick(row: CostCenter): void {
			(this.$refs.modalCostCenter as typeof ModalCostCenter).edit(row);
		},

		async buttonCreateDefaultSeeds(): Promise<void> {
			await (this.$refs.defaultSeeds as typeof DefaultSeeds).createFinanceDefaultSeeds();
			await this.loadAll(true);
		},

		dataTableCostCenter_searchChanged(): void {

		},

		dataTableCostCenter_deleteClick(id: number): void {
			this.deleteSetting(id, {
				dataType: 'cost_center',
				messageRequesting: 'Aguarde, excluindo Centro de Custo/Receita',
				messageSuccess: 'Centro de Custo/Receita excluído com sucesso',
				messageError: 'Erro ao excluir Centro de Custo/Receita',
			});
		},

		dataTableCostCenter_savedConfiguration(): void {
			this.loadCostCenters(true);
		},

		dataTableCostCenter_tdClick(_row: CostCenter): void {
			// this.$router.push({name: 'specific-contact', params: {id: row.id}});
		},

		dataTableCostCenter_nextPage(_nextPage: number): void {

		},

		dataTableCostCenter_goToPage(_page: number): void {

		},

		dataTableIncomeCategories_searchChanged(): void {},
		dataTableIncomeCategories_savedConfiguration(): void {},
		dataTableIncomeCategories_tdClick(_row: Category): void {},
		dataTableIncomeCategories_nextPage(_nextPage: number): void {},
		dataTableIncomeCategories_goToPage(_page: number): void {},
		dataTableIncomeCategories_deleteClick(id: number): void {
			this.deleteSetting(id, {
				dataType: 'income_category',
				messageRequesting: 'Aguarde, excluindo categoria',
				messageSuccess: 'Categoria excluída com sucesso',
				messageError: 'Erro ao excluir categoria',
			});
		},

		dataTableExpenseCategories_searchChanged(): void {},
		dataTableExpenseCategories_savedConfiguration(): void {},
		dataTableExpenseCategories_tdClick(_row: Category): void {},
		dataTableExpenseCategories_nextPage(_nextPage: number): void {},
		dataTableExpenseCategories_goToPage(_page: number): void {},
		dataTableExpenseCategories_deleteClick(id: number): void {
			this.deleteSetting(id, {
				dataType: 'expense_category',
				messageRequesting: 'Aguarde, excluindo categoria',
				messageSuccess: 'Categoria excluída com sucesso',
				messageError: 'Erro ao excluir categoria',
			});
		},

		modalCategory_categoryCreated({ type }: { type: 'income' | 'expense' }): void {
			if (type === 'income') {
				this.loadIncomeCategories();
			} else {
				this.loadExpenseCategories();
			}
		},

		modalCostCenter_costCenterCreated(): void {
			this.loadCostCenters();
		},

	},
	async beforeMount() {
		await this.loadAll();
	},
});
</script>

<template>
	<section class="feature">

		<h1 class="mt-1">
			Configurações
		</h1>

		<div
			v-if="!isFinancesSeeded"
			class="mt-5 text-xs"
		>
			Os Centros de Custos/Receitas e Categorias estão vazios. <button
				class="text-blue-500 underline"
				@click="buttonCreateDefaultSeeds"
			>
				Clique aqui
			</button> para utilizar as configurações recomendadas.
		</div>

		<div class="bg-white mt-5 p-3 rounded">
			<div class="border-b border-blue-500/25 flex items-center justify-between pb-2">
				<h2 class="font-semibold text-blue-500/75">
					Centros de Custos e Receitas
				</h2>
				<button
					class="btn btn--primary h-auto min-h-0 normal-case !pl-2 !pr-3 !py-1 rounded text-white"
					title="Adicionar um novo centro de custos/receitas"
					@click="buttonAddCostCenterClick"
				>
					<svg
						class="inline w-6 h-6"
						fill="none"
						stroke="currentColor"
						viewBox="0 0 24 24"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-width="2"
							d="M12 6v6m0 0v6m0-6h6m-6 0H6"
						/>
					</svg>
					<span>Adicionar</span>
				</button>
			</div>
			<DataTable
				id="data_table_finance_settings_cost_center"

				class="mt-2"
				:actions-column-show="true"
				:columns="dataTableCostCenter.columns"
				:data="dataTableCostCenter.data"
				:data-raw="dataTableCostCenter.dataRaw"
				:filters="{}"
				:options="dataTableCostCenter.options"
				:loading-data="requesting.loadCostCenters"
				:search-and-filters-show="false"
				:config-button-show="false"
				:max-data-length="dataTableCostCenter.total_count"

				@search-changed="dataTableCostCenter_searchChanged"
				@saved-configuration="dataTableCostCenter_savedConfiguration"
				@td-click="dataTableCostCenter_tdClick"
				@next-page="dataTableCostCenter_nextPage"
				@go-to-page="dataTableCostCenter_goToPage"
				@order-apply="(column, sort) => orderApply('costCenters', column, sort)"
			>
				<template #col_name="{ row }">
					<button
						class="hover:text-blue-500 hover:underline"
						@click="buttonCostCenterClick(row)"
					>
						{{ row.name }}
					</button>
				</template>
				<template #actions-list="{ row }">
					<button
						class="flex items-center mb-1 p-1 pr-2 rounded w-full whitespace-nowrap hover:bg-[#E5F0FB] last:mb-0"

						@click="dataTableCostCenter_deleteClick(row.id)"
					>
						<svg
							class="inline mr-1 w-4 h-4"
							fill="currentColor"
							viewBox="0 0 20 20"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fill-rule="evenodd"
								d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
								clip-rule="evenodd"
							/>
						</svg>
						<span class="text-sm">Excluir</span>
					</button>
				</template>
			</DataTable>
		</div>

		<div class="bg-white mt-5 p-3 rounded">
			<div class="border-b border-blue-500/25 flex items-center justify-between pb-2">
				<h2 class="font-semibold text-blue-500/75">
					Categorias de Entradas
				</h2>
				<button
					class="btn btn--primary h-auto min-h-0 normal-case !pl-2 !pr-3 !py-1 rounded text-white"
					title="Adicionar uma nova categoria de entrada"
					@click="buttonAddCategoryClick('income')"
				>
					<svg
						class="inline w-6 h-6"
						fill="none"
						stroke="currentColor"
						viewBox="0 0 24 24"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-width="2"
							d="M12 6v6m0 0v6m0-6h6m-6 0H6"
						/>
					</svg>
					<span>Adicionar</span>
				</button>
			</div>
			<DataTable
				id="data_table_finance_settings_income_categories"

				class="mt-2"
				:actions-column-show="true"
				:columns="dataTableIncomeCategories.columns"
				:data="dataTableIncomeCategories.data"
				:data-raw="dataTableIncomeCategories.dataRaw"
				:filters="{}"
				:options="dataTableIncomeCategories.options"
				:loading-data="requesting.loadIncomeCategories"
				:search-and-filters-show="false"
				:config-button-show="false"
				:max-data-length="dataTableIncomeCategories.total_count"

				@search-changed="dataTableIncomeCategories_searchChanged"
				@saved-configuration="dataTableIncomeCategories_savedConfiguration"
				@td-click="dataTableIncomeCategories_tdClick"
				@next-page="dataTableIncomeCategories_nextPage"
				@go-to-page="dataTableIncomeCategories_goToPage"
				@order-apply="(column, sort) => orderApply('incomes', column, sort)"
			>
				<template #col_name="{ row }">
					<button
						class="hover:text-blue-500 hover:underline"
						@click="buttonCategoryClick('expense', row)"
					>
						{{ row.name }}
					</button>
				</template>
				<template #actions-list="{ row }">
					<button
						class="flex items-center mb-1 p-1 pr-2 rounded w-full whitespace-nowrap hover:bg-[#E5F0FB] last:mb-0"

						@click="dataTableIncomeCategories_deleteClick(row.id)"
					>
						<svg
							class="inline mr-1 w-4 h-4"
							fill="currentColor"
							viewBox="0 0 20 20"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fill-rule="evenodd"
								d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
								clip-rule="evenodd"
							/>
						</svg>
						<span class="text-sm">Excluir</span>
					</button>
				</template>
			</DataTable>
		</div>

		<div class="bg-white mt-5 p-3 rounded">
			<div class="border-b border-blue-500/25 flex items-center justify-between pb-2">
				<h2 class="font-semibold text-blue-500/75">
					Categorias de Saídas
				</h2>
				<button
					class="btn btn--primary h-auto min-h-0 normal-case !pl-2 !pr-3 !py-1 rounded text-white"
					title="Adicionar uma nova categoria de saída"
					@click="buttonAddCategoryClick('expense')"
				>
					<svg
						class="inline w-6 h-6"
						fill="none"
						stroke="currentColor"
						viewBox="0 0 24 24"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-width="2"
							d="M12 6v6m0 0v6m0-6h6m-6 0H6"
						/>
					</svg>
					<span>Adicionar</span>
				</button>
			</div>
			<DataTable
				id="data_table_finance_settings_expense_categories"

				class="mt-2"
				:actions-column-show="true"
				:columns="dataTableExpenseCategories.columns"
				:data="dataTableExpenseCategories.data"
				:data-raw="dataTableExpenseCategories.dataRaw"
				:filters="{}"
				:options="dataTableExpenseCategories.options"
				:loading-data="requesting.loadExpenseCategories"
				:search-and-filters-show="false"
				:config-button-show="false"
				:max-data-length="dataTableExpenseCategories.total_count"

				@search-changed="dataTableExpenseCategories_searchChanged"
				@saved-configuration="dataTableExpenseCategories_savedConfiguration"
				@td-click="dataTableExpenseCategories_tdClick"
				@next-page="dataTableExpenseCategories_nextPage"
				@go-to-page="dataTableExpenseCategories_goToPage"
				@order-apply="(column, sort) => orderApply('expenses', column, sort)"
			>
				<template #col_name="{ row }">
					<button
						class="hover:text-blue-500 hover:underline"
						@click="buttonCategoryClick('expense', row)"
					>
						{{ row.name }}
					</button>
				</template>
				<template #actions-list="{ row }">
					<button
						class="flex items-center mb-1 p-1 pr-2 rounded w-full whitespace-nowrap hover:bg-[#E5F0FB] last:mb-0"

						@click="dataTableExpenseCategories_deleteClick(row.id)"
					>
						<svg
							class="inline mr-1 w-4 h-4"
							fill="currentColor"
							viewBox="0 0 20 20"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fill-rule="evenodd"
								d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
								clip-rule="evenodd"
							/>
						</svg>
						<span class="text-sm">Excluir</span>
					</button>
				</template>
			</DataTable>
		</div>

		<ModalCategory
			ref="modalCategory"

			@category-created="modalCategory_categoryCreated"
		/>

		<ModalCostCenter
			ref="modalCostCenter"

			@cost-center-created="modalCostCenter_costCenterCreated"
		/>

		<DefaultSeeds ref="defaultSeeds" />

	</section>
</template>

<style lang="scss" scoped>
:deep(.component-data-table) {
	.container-pagination-row.mb-1 {
		display: none;
	}

	.component-data-table-basic {
		tbody {
			tr {
				td {
					font-size: 0.75em;
				}
			}
		}
	}
}

</style>../../../../services/API/financial/costCenters../../../../services/API/financial/incomeCategories../../../../services/API/financial/expenseCategories