import Dexie from 'dexie';

const db = new Dexie('cj_vjs');

const version = 1;

db.version(version).stores({
	log: '++id, message, date_time',
	session: '++key, value',
	settings: '++id, key, value, type, feature',
});

export default db;