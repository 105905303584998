<script lang="ts">
import {
	defineComponent, PropType
} from 'vue';

type DropdownPosition = {
	left: string;
	top: string;
}

export default defineComponent({
	props: {
		triggerElement: {
			type: Object as PropType<Element>
		},
	},
	data() {
		return {
			show: false,
		};
	},
	computed: {

		dropdownPosition(): DropdownPosition | null {
			if (!this.triggerElement) return null;

			const boundingClientRect = this.triggerElement.getBoundingClientRect();

			return {
				left: `${boundingClientRect.x + boundingClientRect.width}px`,
				top: `${boundingClientRect.y}px`,
			};
		},

	},
	methods: {

		buttonClick(): void {
			this.show = false;
		},

		clickOutside(): void {
			if (this.show) {
				this.show = false;
			}
		},

		triggerClick(e: Event): void {
			this.show = !this.show;
			e.stopPropagation();
		},

	},
	watch: {
		triggerElement: {
			deep: true,
			flush: 'sync',
			handler(value): void {
				value.addEventListener('click', this.triggerClick);
			}
		}
	},
	unmounted() {
		this.triggerElement?.removeEventListener('click', this.triggerClick);
	},
});
</script>
<template>
	<div
		v-if="triggerElement && dropdownPosition"
		v-show="show"
		class="sidebar-dropdown"
		:style="{ left: dropdownPosition.left, top: dropdownPosition.top }"
		v-click-outside="clickOutside"
	>
		<slot />
	</div>
</template>
<style lang="scss" scoped>
@keyframes sidebarDropdown {
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
}

.sidebar-dropdown {
    background-color: #2E333E;
    color: #fff;
    padding: 20px;
    position: absolute;
    z-index: 1000;

    animation-duration: 0.15s;
    animation-fill-mode: forwards;
    animation-name: sidebarDropdown;
    animation-timing-function: ease;

    transform-origin: top left;

    :slotted(div) {
            font-size: 0.775rem;
    }

    :slotted(a) {
        display: block;
        padding: 3px 0;

        &:hover {
            span {
                text-decoration: underline;
            }
        }

        svg {
            display: inline-block;
            fill: #fff;
            height: 16px;
            transform: rotate(-90deg);
            vertical-align: middle;
        }

        span {
            font-size: 0.775rem;
            font-weight: 500;
            vertical-align: middle;
        }
    }

}
</style>