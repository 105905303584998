<script lang="ts">
import Calendar, {CalendarActiveView} from '../../../../components/Calendar/CalendarComponent.vue';
import DataTable from '../../../../components/DataTable/DataTable.vue';
import SelectField from '../../../../components/Form/SelectField.vue';
import Loading from '../../../../components/Loading/LoadingComponent.vue';
import TagsDisplay from '../../../../components/Tags/TagsDisplay.vue';

import ModalTask from './ModalTask.vue';
import ModalBasic from '../../../../components/Modal/ModalBasic.vue';

import serviceTasks from '../../../../services/API/crm/tasks';
import serviceUsers from '../../../../services/users';

import {
	mapState, mapActions
} from 'pinia';
import { useAccountStore } from '../../../../stores/account';
import { useTasksStore } from '../../../../stores/models/crm/tasks';
import { useContactsStore } from '../../../../stores/models/crm/contacts';

import {
	defineComponent, inject
} from 'vue';
import {
	EventBus, FilterItem, Filters, Helpers, Lodash, Luxon, Task, User
} from '../../../../models/crm';
import { RouteLocationNormalized } from 'vue-router';

export default defineComponent({
	components: {
		Calendar,
		DataTable,
		Loading,
		SelectField,
		TagsDisplay,

		ModalTask,
		ModalBasic
	},
	provide() {
		return {
			filters: this.filters,
		};
	},
	beforeRouteUpdate(to, _from, next) {
		this.setViewModeFromUrlParam(to);
		if (to.query.mode === 'calendar' && !this.isRequestingTasks) {
			this.loadTasks(true, true);
		}

		next();
	},
	beforeRouteEnter(to, _from, next) {
		next(
			vm => {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				(vm as any).setViewModeFromUrlParam(to);

				if ((to.name === 'create-task')||(to.name === 'create-task-without-due-date')||(to.name === 'specific-task')) {
					(vm.$refs.modalTask as typeof ModalTask).show();
				}
			}
		);
	},
	data() {
		return {
			eventBus: inject<EventBus>('eventBus'),
			luxon: inject<Luxon>('luxon'),
			lodash: inject<Lodash>('lodash'),
			helpers: inject<Helpers>('helpers'),

			dataTableTasks: {
				columns: [
					{
						key: 'title', label: 'Título', initial: true, required: true
					},
					{
						key: 'is_late', label: '', initial: true, required: true
					},
					{
						key: 'due_at', label: 'Entrega', initial: true
					},
					{
						key: 'done', label: 'Status', initial: true
					},
					{
						key: 'contacts', label: 'Contatos', initial: true
					},
					{
						key: 'legal_case_id', label: 'Caso', initial: true
					},
					{
						key: 'tags', label: 'Tags', initial: true
					},
					{
						key: 'assignees', label: 'Responsáveis', initial: true
					},

					{
						key: 'created_at', label: 'Criada em'
					},
					{
						key: 'updated_at', label: 'Atualizada em'
					},
					{
						key: 'done_at', label: 'Concluída em'
					},
				],
				data: [] as Task[],
				dataRaw: [] as Task[],
				options: {
					columnCssClass: {
						'title': 'text-[rgb(0,123,255)] td-link w-1/3',
						'due_at': 'text-center',
						'done': 'text-center whitespace-nowrap',
						'contacts': 'align-top',
						'legal_case_id': 'align-top',
						'tags': 'align-top',
						'assignees': 'align-top',
					},
					initial: {
						orderBy: {
							column: 'due_at',
							sort: 'desc',
						},
					},
					orderableColumns: [
						'title',
						'due_at',
					],
					searchableColumns: [
						'title',
					],
				},
				total_count: 100,
			},

			filters: {
				model: {
					due_at: '',
					groupBy: '',
					done: '',
					assignees: [],
				} as Record<string, string | string[]>,
				applied: {},
				labels: {
					due_at: 'Data de entrega',
					groupBy: 'Agrupar por',
					done: 'Status',
					assignees: 'Responsáveis',

					no_group_by: 'Não agrupar',
					by_case: 'Caso',

					future_dates: 'Somente datas futuras',
					past_dates: 'Somente datas passadas',
					today: 'Somente hoje',

					true: 'Concluída',
					false: 'Pendente'
				},
				query: ''
			} as Filters,

			filtersListDueDate: [
				{
					label: 'Somente datas futuras', value: 'future_dates'
				},
				{
					label: 'Somente datas passadas', value: 'past_dates'
				},
				{
					label: 'Somente hoje', value: 'today'
				},
			],

			filtersListStatus: [
				{
					label: 'Concluída', value: 'true'
				},
				{
					label: 'Pendente', value: 'false'
				},
			],

			filtersListAssignees: [] as string[],

			forceStringGroupBy: '' as string,

			order: {
				column: 'due_at',
				sort: 'desc'
			},

			requesting: {
				deleteTask: false,
				loadTasks: false,
				pagination: false,
				createClientFromLead: false,
				getAssigneesList: false
			},

			searchAndFilters: {
				search: '',
			},

			viewMode: 'list',

			clientIdToCreateFromLead: '' as string | number,

			groupByOptions: [ {
				label: 'Não agrupar',
				value: ''
			}, {
				label: 'Caso',
				value: 'by_case'
			} ],

			assigneesList: {
				hasMoreThanTenAssignees: false,

				searchStr: '',

				list: [] as string[],
			}
		};
	},
	computed: {
		...mapState(useAccountStore, [ 'user' ]),
		...mapState(useTasksStore, [
			'tasksList',
			'maxTasks',
			'isRequestingTasks',
			'searchedTasks',
			'searchMaxTasks',
		]),

		dataAsEvents() {
			return this.dataTableTasks.dataRaw.map(
				item => {
					return {
						customData: {
							task: item,
						},
						start: this.luxon?.fromISO(item.due_at || '').toFormat('yyyy-MM-dd HH:mm'),
						end: this.luxon?.fromISO(item.due_at || '').plus({ minutes: 30 }).toFormat('yyyy-MM-dd HH:mm'),
						title: item.title,
						class: item.done
							? 'cal-success'
							: ((this.luxon?.fromISO(item.due_at || '').diffNow().toMillis()
								&& this.luxon?.fromISO(item.due_at || '').diffNow().toMillis() < 0)
								? 'cal-error'
								: 'cal-warning'
							),
						content: `
							<svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"
								class="mx-auto ${item.done
		? 'fill-green-600'
		: ((this.luxon?.fromISO(item.due_at || '').diffNow().toMillis()
															&& this.luxon?.fromISO(item.due_at || '').diffNow().toMillis() < 0)
			? 'fill-red-600'
			: 'fill-yellow-600'
		)}"
							>
								<path
									d="${item.done
		? 'm421-298 283-283-46-45-237 237-120-120-45 45 165 166Zm59 218q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-156t86-127Q252-817 325-848.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82-31.5 155T763-197.5q-54 54.5-127 86T480-80Zm0-60q142 0 241-99.5T820-480q0-142-99-241t-241-99q-141 0-240.5 99T140-480q0 141 99.5 240.5T480-140Zm0-340Z'
		: ((this.luxon?.fromISO(item.due_at || '').diffNow().toMillis()
															&& this.luxon?.fromISO(item.due_at || '').diffNow().toMillis() < 0)
			? 'M479-82q-74 0-139.5-28t-114-76.5q-48.5-48.5-77-114T120-440.733q0-74.733 28.5-140T225.5-695q48.5-49 114-77T479-800q74 0 139.5 28T733-695q49 49 77 114.267t28 140Q838-366 810-300.5t-77 114Q684-138 618.5-110T479-82Zm0-357Zm121 161 42-42-130-130v-190h-60v214l148 148ZM214-867l42 42L92-667l-42-42 164-158Zm530 0 164 158-42 42-164-158 42-42ZM479.043-142Q604-142 691-229.043t87-212Q778-566 690.957-653t-212-87Q354-740 267-652.957t-87 212Q180-316 267.043-229t212 87Z'
			: 'M266.118-430Q287-430 301.5-444.618q14.5-14.617 14.5-35.5Q316-501 301.382-515.5q-14.617-14.5-35.5-14.5Q245-530 230.5-515.382q-14.5 14.617-14.5 35.5Q216-459 230.618-444.5q14.617 14.5 35.5 14.5Zm214 0Q501-430 515.5-444.618q14.5-14.617 14.5-35.5Q530-501 515.382-515.5q-14.617-14.5-35.5-14.5Q459-530 444.5-515.382q-14.5 14.617-14.5 35.5Q430-459 444.618-444.5q14.617 14.5 35.5 14.5Zm213 0Q714-430 728.5-444.618q14.5-14.617 14.5-35.5Q743-501 728.382-515.5q-14.617-14.5-35.5-14.5Q672-530 657.5-515.382q-14.5 14.617-14.5 35.5Q643-459 657.618-444.5q14.617 14.5 35.5 14.5ZM480.266-80q-82.734 0-155.5-31.5t-127.266-86q-54.5-54.5-86-127.341Q80-397.681 80-480.5q0-82.819 31.5-155.659Q143-709 197.5-763t127.341-85.5Q397.681-880 480.5-880q82.819 0 155.659 31.5Q709-817 763-763t85.5 127Q880-563 880-480.266q0 82.734-31.5 155.5T763-197.684q-54 54.316-127 86Q563-80 480.266-80Zm.234-60Q622-140 721-239.5t99-241Q820-622 721.188-721 622.375-820 480-820q-141 0-240.5 98.812Q140-622.375 140-480q0 141 99.5 240.5t241 99.5Zm-.5-340Z'
		)}""
								/>
							</svg>`,
					};
				}
			);
		},

		dataGrouped(): Task[][] {
			const data = this.dataTableTasks.dataRaw;

			const groupedByObject = {} as Record<string, {
				data: Task[];
				id: string | number;
				name: string;
			}>;

			// Create objects (groups) for each case
			this.helpers?.getUniqueArrayValues(data.map(item => item.legal_case_id)).forEach(
				item => {
					groupedByObject[item] = {
						data: [],
						id: item,
						name: '',
					};
				}
			);

			// Fill groups with rows
			data.forEach(
				row => {
					const caseObj = row.legal_case;

					if (!groupedByObject[caseObj?.id || '']) return;

					(groupedByObject[caseObj?.id || ''] as {
						data: Task[];
						id: string | number;
						name: string;
					}).name = caseObj?.name || '';
					(groupedByObject[caseObj?.id || ''] as {
						data: Task[];
						id: string | number;
						name: string;
					}).data.push(row);
				}
			);

			return Object.values(groupedByObject).map(item => item.data);
		},

		dataTableTasksData(): Task[][] {
			const data = this.dataTableTasks.dataRaw;

			if (!this.isGroupBySelected) return [ data ];
			return this.dataGrouped;
		},

		isGroupBySelected(): boolean {
			const filtersApplied = this.filters.applied;
			return !!filtersApplied.groupBy;
		},

		isViewModeCalendar(): boolean {
			return this.viewMode === 'calendar';
		},

		isViewModeList(): boolean {
			return this.viewMode === 'list';
		},

		modalTask_inData(): { assignees: User[] | null } {
			const assignees = this.user ? [ this.user ] : null;

			return {
				assignees,
			};
		},

		urlParamId(): string {
			return typeof this.$route.params.id === 'string' ? this.$route.params.id : '';
		},

		perPage(): number {
			const preferenceId = 'ui:data_table_tasks';
			const userPreferences = this.user?.preferences;
			const specificPreference = userPreferences && userPreferences[preferenceId];

			return (specificPreference?.recordsPerPage as number | undefined) || 25;
		}

	},
	watch: {
		user: {
			deep: true,
			immediate: true,
			handler(newUser): void {
				if (newUser && newUser.preferences) {
					if (this.dataTableTasks.dataRaw.length === 0) {
						if (this.$route.query.mode === 'calendar') {
							this.loadTasks(true, true);
							return;
						}
						this.loadTasks();
					}
				}
			}
		},
		'filters.model.groupBy'(newValue): void {
			this.forceStringGroupBy = newValue as string;
		},
		forceStringGroupBy(newValue): void {
			this.filters.model.groupBy = newValue;
		},
		'assigneesList.searchStr'(newValue): void {
			const filteredAssigneesList =
				this.assigneesList.list.filter((item: string) => {
					return item.toLowerCase().includes(newValue.toLowerCase());
				});

			this.filtersListAssignees = filteredAssigneesList.slice(0, 10);
		}
	},
	async beforeMount() {
		await this.setFiltersListAssignees();
	},
	methods: {
		...mapActions(useTasksStore, [
			'deleteTask',
			'paginatedLoadTasks',
			'searchTasksByName',
			'useNormalTasksList',
		]),

		...mapActions(useContactsStore, [
			'createClientFromLead'
		]),

		async callDeleteTask(id: string | number): Promise<void> {
			this.eventBus?.emit('BlockingOverlay/show', {
				text: 'Aguarde, excluindo tarefa'
			});

			try {
				this.requesting.deleteTask = true;

				await this.deleteTask(id);

				this.dataTableTasks.data = this.dataTableTasks.dataRaw = this.tasksList;
				this.dataTableTasks.total_count = this.maxTasks;

				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Tarefa excluída com sucesso',
						type: 'success'
					}
				);

				this.requesting.deleteTask = false;
			} catch (err) {
				console.error(err);

				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Erro ao excluir tarefa',
						type: 'error'
					}
				);
			} finally {
				this.eventBus?.emit('BlockingOverlay/hide');
			}
		},

		async setFiltersListAssignees(): Promise<void> {
			this.requesting.getAssigneesList = true;

			const {data} = await serviceUsers.getWithTask();

			const assigneesList = data.users
				.map(item => item.name)
				.reduce((acc, curr) => {
					if (!acc.includes(curr))
						acc.push(curr);
					return acc;
				}, [] as string[]);

			this.assigneesList.list = assigneesList;

			if (assigneesList.length > 10) {
				this.assigneesList.hasMoreThanTenAssignees = true;
			}

			this.filtersListAssignees = assigneesList.slice(0, 10);

			this.requesting.getAssigneesList = false;
		},

		async filtersApply(): Promise<void> {
			const filtersApplied = this.filters.applied = {...this.filters.model};
			const assignees = filtersApplied.assignees as string[];
			filtersApplied.assignees =
				assignees?.map((item: string) => item.toLowerCase()).join(',');

			let filter = '';
			for (const key of Object.keys(filtersApplied)) {
				if (filtersApplied[key]?.length) {
					filter += `&${key}=${(filtersApplied[key] as string).toLowerCase()}`; }
			}
			this.filters.query = filter;

			await this.reloadTasks('Erro ao aplicar o filtro');
		},

		async loadTasks(forceReload = false, useLoadAll = false): Promise<void> {
			try {
				this.requesting.loadTasks = true;

				if (this.tasksList.length > 0 && !forceReload) {
					this.dataTableTasks.data = this.dataTableTasks.dataRaw = this.tasksList;
					this.dataTableTasks.total_count = this.maxTasks;

					this.requesting.loadTasks = false;

					return;
				}

				const orderBy = `${this.order.column}:${this.order.sort}`;
				if (!useLoadAll) {
					await this.paginatedLoadTasks({
						perPage: this.perPage,
						page: 1,
						filters: this.filters.query,
						orderBy
					});
				} else {
					await this.paginatedLoadTasks({
						perPage: 9999,
						page: 1,
						filters: '',
						orderBy
					});
				}

				this.dataTableTasks.data = this.dataTableTasks.dataRaw = this.tasksList;
				this.dataTableTasks.total_count = this.maxTasks;

				this.requesting.loadTasks = false;
			} catch (err) {
				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Erro ao carregar tarefas',
						type: 'error'
					}
				);
			}
		},

		setViewModeFromUrlParam(toRoute: RouteLocationNormalized): void {
			if (toRoute.query.mode === 'calendar') {
				this.viewMode = 'calendar';
			} else {
				this.viewMode = 'list';
			}
		},

		isLate(task: Task): boolean {
			if (!this.luxon?.fromISO(task.due_at || '')?.toMillis()) {
				if (!Number.isNaN(Date.parse(task.due_at || ''))) {
					return Date.parse(task.due_at || '') < Date.now();
				}
				return false;
			}

			return this.luxon.fromISO(task.due_at || '').toMillis() < this.luxon.now().toMillis();
		},

		async orderApply(column: string, sort: string): Promise<void> {
			this.order = {
				column, sort
			};

			await this.reloadTasks('Erro ao fazer a ordenação');
		},

		async reloadTasks(errorMessage: string): Promise<void> {
			this.requesting.pagination = true;

			const orderBy = `${this.order.column}:${this.order.sort}`;

			try {
				if (this.searchAndFilters.search) {
					await this.searchTasksByName({
						name: this.searchAndFilters.search,
						perPage: this.perPage,
						page: 1,
						orderBy,
						filters: this.filters.query
					});

					this.dataTableTasks.data = this.dataTableTasks.dataRaw = [];

					if (this.maxTasks > this.dataTableTasks.dataRaw.length) {
						this.dataTableTasks.data = this.dataTableTasks.dataRaw = this.searchedTasks;
						this.dataTableTasks.total_count = this.searchMaxTasks;

						return;
					}
				} else {
					await this.paginatedLoadTasks({
						perPage: this.perPage,
						page: 1,
						orderBy,
						filters: this.filters.query
					});

					this.dataTableTasks.data = this.dataTableTasks.dataRaw = [];
					this.dataTableTasks.total_count = 0;

					if (this.maxTasks > this.dataTableTasks.dataRaw.length) {
						this.dataTableTasks.data = this.dataTableTasks.dataRaw = this.tasksList;
						this.dataTableTasks.total_count = this.maxTasks;

						return;
					}
				}
			} catch (err) {
				console.error(err);
				this.eventBus?.emit(
					'Toast/add',
					{
						content: errorMessage,
						type: 'error'
					}
				);
			} finally {
				this.requesting.pagination = false;
			}
		},

		// Events

		buttonCreateClick(): void {
			this.$router.push({
				name: 'create-task-without-due-date',
				query: this.$route.query,
			});
		},

		buttonTabViewModeClick(_e: Event, mode: string | undefined): void {
			const currentRoute = this.$route;

			const query = {
				...currentRoute.query,
				mode,
			};

			if (mode !== 'list') {
				query.mode = mode;
			} else {
				delete query.mode;
			}

			this.$router.push({
				path: currentRoute.path,
				query,
			});
		},

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		calendar_onEventClick({ vuecalEvent }: { vuecalEvent: any }): void {
			const {customData} = vuecalEvent;
			const { task } = customData;

			this.$router.push({
				name: 'specific-task',
				params: {
					id: task.id,
				},
				query: this.$route.query,
			});
		},


		calendar_onCellClick(cellDateTime: Date, activeView : CalendarActiveView): void {
			cellDateTime.setMinutes(0);

			if (activeView == 'month') {
				// Set time to 17:00 BRT
				cellDateTime.setHours(17);
				cellDateTime.setUTCSeconds(0);
				cellDateTime.setUTCMilliseconds(0);
			}

			const ISOCellDateTime = cellDateTime.toISOString();

			this.$router.push({
				name: 'create-task',
				query: this.$route.query,
				params: {newTaskDueAt: ISOCellDateTime}
			});
		},

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		async calendar_onEventDrop(customData: any, date: any): Promise<void> {
			const {task} : {task: Task} = customData;

			if (task) {
				this.requesting.pagination = true;

				task.due_at = date.toISOString();

				await serviceTasks.update({
					data: task,
					id: task.id,
				});

				this.reloadTasks('Erro ao atualizar a tarefa');
			}
		},

		dataTableTasks_deleteClick(_e: Event, id: string | number): void {
			this.callDeleteTask(id);
		},

		dataTableTasks_filtersApply(): void {
			this.filtersApply();
		},

		dataTableTasks_filtersClear(): void {
			Object.entries(this.filters.model).forEach(
				([ key, value ]) => {
					this.filters.model[key] = Array.isArray(value) ? [] : '';
				}
			);

			this.filtersApply();
		},

		dataTableTasks_filtersItemRemove(filterItem
			: [string, string | string[] | FilterItem<string | boolean>]
		): void {
			const [ key, value ] = filterItem;

			this.filters.model[key] = Array.isArray(value) ? [] : '';

			this.filtersApply();
		},

		dataTableTasks_savedConfiguration(): void {
			this.loadTasks(true);
		},

		dataTableTasks_tdClick(row: Task): void {
			this.$router.push({
				name: 'specific-task',
				params: {
					id: row.id,
				},
				query: this.$route.query,
			});
		},

		dataTableTasks_orderApply(column: string, sort: string): void {
			this.orderApply(column, sort);
		},

		modalTask_taskCreated(): void {
			this.loadTasks(true);
		},

		modalTask_taskUpdated(): void {
			this.loadTasks(true);
		},

		async dataTableTasksSearchAndFilters_searchInput(value: string): Promise<void> {
			this.searchAndFilters.search = value;

			await this.searchTasks(value);
		},

		async addTasksToData(nextPage: number): Promise<void> {
			if (
				this.maxTasks > this.perPage * (nextPage - 1)
					&& this.dataTableTasks.dataRaw.length === this.perPage * (nextPage - 1)
			) {
				this.requesting.pagination = true;

				const orderBy = `${this.order.column}:${this.order.sort}`;

				if (this.searchAndFilters.search) {
					try {
						await this.searchTasksByName({
							name: this.searchAndFilters.search,
							perPage: this.perPage,
							page: nextPage,
							filters: this.filters.query,
							orderBy
						});

						if (nextPage === 1) {
							this.dataTableTasks.data = this.dataTableTasks.dataRaw = [];
						}

						if (this.maxTasks > this.dataTableTasks.dataRaw.length) {
							this.dataTableTasks.data = this.dataTableTasks.dataRaw = this.searchedTasks;
							this.dataTableTasks.total_count = this.searchMaxTasks;

							return;
						}

						this.eventBus?.emit(
							'Toast/add',
							{
								content: 'Página máxima atingida',
								type: 'error'
							}
						);
					} catch (err) {
						console.error(err);
						this.eventBus?.emit(
							'Toast/add',
							{
								content: 'Erro ao fazer a paginação',
								type: 'error'
							}
						);
					} finally {
						this.requesting.pagination = false;
					}

					return;
				}

				try {
					await this.paginatedLoadTasks({
						perPage: this.perPage,
						page: nextPage,
						filters: this.filters.query,
						orderBy
					});

					this.useNormalTasksList();

					if (nextPage === 1) {
						this.dataTableTasks.data = this.dataTableTasks.dataRaw = [];
					}

					if (this.maxTasks > this.dataTableTasks.dataRaw.length) {
						this.dataTableTasks.data = this.dataTableTasks.dataRaw = this.tasksList;

						return;
					}

					this.eventBus?.emit(
						'Toast/add',
						{
							content: 'Página máxima atingida',
							type: 'error'
						}
					);
				} catch (err) {
					console.error(err);

					this.eventBus?.emit(
						'Toast/add',
						{
							content: 'Erro ao fazer a paginação',
							type: 'error'
						}
					);
				} finally {
					this.requesting.pagination = false;
				}
			}
		},
		async sequentialTasksAddition(page: number): Promise<void> {
			const oldPage = Math.ceil(this.dataTableTasks.dataRaw.length / this.perPage);
			if (page <= oldPage || this.dataTableTasks.dataRaw.length % this.perPage !== 0) {
				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Erro ao fazer a paginação',
						type: 'error'
					}
				);

				return;
			}

			for (let i = oldPage + 1;i <= page;++i) {
				await this.addTasksToData(i);
			}
		},

		async searchTasks(searchStr: string): Promise<void> {
			if (searchStr.length === 0) {
				await this.loadTasks();

				this.useNormalTasksList();

				this.searchAndFilters.search = searchStr;

				return;
			}

			this.searchAndFilters.search = searchStr;
			const orderBy = `${this.order.column}:${this.order.sort}`;

			if (this.user?.office_id) {
				try {
					await this.searchTasksByName({
						name: searchStr,
						perPage: this.perPage,
						page: 1,
						filters: this.filters.query,
						orderBy
					});

					if (this.searchAndFilters.search === searchStr) {
						this.dataTableTasks.data = this.dataTableTasks.dataRaw = this.searchedTasks;
						this.dataTableTasks.total_count = this.searchMaxTasks;
					}
				} catch (err) {
					console.error(err);
					this.eventBus?.emit(
						'Toast/add',
						{
							content: 'Erro ao pesquisar contato',
							type: 'error'
						}
					);
				}
			}
		},
		toggleConfirmChangeToClientModal(
			shouldOpen: boolean,
			clientId:string | number | null = null
		): void {
			if (clientId) {
				this.clientIdToCreateFromLead = clientId;
			}

			if (shouldOpen) {
				(this.$refs.modalChangeToClient as typeof ModalBasic).show();
				return;
			}

			(this.$refs.modalChangeToClient as typeof ModalBasic).hide();
		},
		async callCreateClientFromLead(): Promise<void> {
			if (!this.user?.office_id) return;

			const clientLimitErrorMessage = 'Limite de cota atingido e não foi possível atualizar seu plano. Entre em contato com nosso suporte';

			this.requesting.createClientFromLead = true;

			this.eventBus?.emit('BlockingOverlay/show', {
				text: 'Aguarde, transformando lead em cliente',
			});

			try {
				if (!this.clientIdToCreateFromLead) {
					throw new Error('Não foi possível identificar o contato');
				}

				await this.createClientFromLead(this.user.office_id, this.clientIdToCreateFromLead);

				this.eventBus?.emit('BlockingOverlay/hide');

				this.requesting.createClientFromLead = false;

				this.toggleConfirmChangeToClientModal(false);

				(this.$refs.modalTask as typeof ModalTask).save();
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (err: any) {
				console.error(err);

				let stringifiedErrors = undefined;

				if (err?.response?.data?.errors) {
					stringifiedErrors = '';

					for (const errorKey in err.response.data.errors) {
						stringifiedErrors = `${stringifiedErrors} ${errorKey}: ${err.response.data.errors[errorKey]},`;
					}
				}

				if (err.message === clientLimitErrorMessage) {
					stringifiedErrors = err.message;
				}

				this.eventBus?.emit(
					'Toast/add',
					{
						content: stringifiedErrors || 'Erro ao transformar lead em cliente. Entre em contato com o suporte',
						type: 'error'
					}
				);

				this.eventBus?.emit('BlockingOverlay/hide');

				this.requesting.createClientFromLead = false;

				this.toggleConfirmChangeToClientModal(false);

				return;
			}
		},
		attachCalendarTitles(): void {
			document.querySelectorAll('.cal-success').forEach(el => {
				el.setAttribute('title', 'Tarefa concluída');
			});
			document.querySelectorAll('.cal-error').forEach(el => {
				el.setAttribute('title', 'Tarefa atrasada');
			});
			document.querySelectorAll('.cal-warning').forEach(el => {
				el.setAttribute('title', 'Tarefa pendente');
			});
		}

	},
});
</script>

<template>
	<section class="feature">
		<div class="pb-4">
			<router-link
				:to="{ name: 'crm-panel' }"
				class="text-gray-500 font-semibold text-xs hover:underline mb-2"
			>
				Gestão
			</router-link>
			<svg
				class="inline h-4 -rotate-90 text-gray-400"
				fill="currentColor"
				viewBox="0 0 20 20"
				xmlns="http://www.w3.org/2000/svg"
			><path
				fill-rule="evenodd"
				d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
				clip-rule="evenodd"
			/></svg>
			<span class="text-blue-500 font-semibold text-xs">Tarefas</span>
		</div>
		<div class="flex items-center justify-between">
			<div class="flex-1 flex items-center gap-4">
				<h1>Tarefas</h1>
				<button
					v-if="!requesting.loadTasks"
					class="btn btn--primary h-auto min-h-0 normal-case !px-2 !py-1 rounded text-white"
					@click="buttonCreateClick"
				>
					<svg
						class="inline w-6 h-6"
						fill="none"
						stroke="currentColor"
						viewBox="0 0 24 24"
						xmlns="http://www.w3.org/2000/svg"
					><path
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="2"
						d="M12 6v6m0 0v6m0-6h6m-6 0H6"
					/></svg>
					<span>Nova tarefa</span>
				</button>
			</div>
			<div class="flex-1">
				<fieldset
					:class="['text-right', { 'opacity-50': requesting.loadTasks }]"
					:disabled="requesting.loadTasks"
				>
					<button
						:class="[
							'btn btn--primary rounded-md',
							{ 'btn--ghost': !isViewModeList }]
						"
						@click="buttonTabViewModeClick($event, 'list')"
					>
						<svg
							class="inline h-6 w-6"
							fill="none"
							stroke="currentColor"
							stroke-width="1.5"
							viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg"
							aria-hidden="true"
						>
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
							/>
						</svg>
						<span class="ml-2">Modo Lista</span>
					</button>
					<button
						:class="['btn btn--primary ml-2 rounded-md', { 'btn--ghost': !isViewModeCalendar }]"
						@click="buttonTabViewModeClick($event, 'calendar')"
					>
						<svg
							class="inline h-6 w-6"
							fill="none"
							stroke="currentColor"
							stroke-width="1.5"
							viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg"
							aria-hidden="true"
						>
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
							/>
						</svg>
						<span class="ml-2">Modo Calendário</span>
					</button>
				</fieldset>
			</div>
		</div>

		<Loading
			v-if="requesting.loadTasks"
			class="opacity-75 py-14 text-center"
			icon-css-class="h-10 w-10 mr-3"
			text-css-class="text-2xl"
		/>
		<template v-else>

			<template v-if="viewMode === 'list'">

				<template
					v-for="data in dataTableTasksData"
					:key="`data-table-${dataTableTasksData.findIndex(el => el ===data)}`"
				>

					<DataTable
						id="data_table_tasks"
						:actions-column-show="true"
						:class="['mt-4', { 'mb-9': isGroupBySelected }]"
						:columns="dataTableTasks.columns"
						:data="isGroupBySelected ? data : dataTableTasks.data"
						:data-raw="isGroupBySelected? data : dataTableTasks.dataRaw"
						:filters="filters"
						:options="dataTableTasks.options"
						:max-data-length="isGroupBySelected
							? data.length
							: dataTableTasks.total_count
						"
						:loading-data="requesting.pagination || requesting.loadTasks"
						:search-requisition="dataTableTasksSearchAndFilters_searchInput"

						@filters-apply="dataTableTasks_filtersApply"
						@filters-clear="dataTableTasks_filtersClear"
						@filters-item-remove="dataTableTasks_filtersItemRemove"
						@search-changed="dataTableTasksSearchAndFilters_searchInput"
						@saved-configuration="dataTableTasks_savedConfiguration"
						@td-click="dataTableTasks_tdClick"
						@next-page="addTasksToData"
						@go-to-page="sequentialTasksAddition"
						@order-apply="dataTableTasks_orderApply"
					>
						<template #title>
							<template v-if="isGroupBySelected">
								<span class="text-md font-medium mb-2">
									<strong>Caso:</strong> <em>{{ data[0]?.legal_case?.name }}</em>
								</span>
							</template>
						</template>
						<template #filters>
							<div class="filter-item">
								<label
									for="filter_selectType"
									class="font-bold mb-4"
								>Agrupar por</label>
								<div>
									<SelectField
										v-model="forceStringGroupBy"
										class="w-full"
										label=""
										:options="groupByOptions"
									/>
								</div>
							</div>

							<div class="mt-5 filter-item">
								<label
									for="filter_selectTasks"
									class="font-bold mb-4"
								>Data de entrega</label>
								<div class="border border-blue-500 p-3 rounded">
									<div
										v-for="item in filtersListDueDate"
										:key="`filter_duedate_${item.value}`"
										class="form-control"
									>
										<label>
											<input
												v-model="filters.model.due_at"
												type="radio"
												class="radio radio-sm radio-primary align-middle"
												name="radioFilterDueDate"
												:value="item.value"
											>
											<span class="align-middle label-text ml-2">{{ item.label }}</span>
										</label>
									</div>
								</div>
							</div>

							<div class="mt-5 filter-item">
								<label
									for="filter_selectStatus"
									class="font-bold mb-4"
								>Status</label>
								<div class="border border-blue-500 p-3 rounded">
									<div
										v-for="item in filtersListStatus"
										:key="`filter_duedate_${item.value}`"
										class="form-control"
									>
										<label>
											<input
												v-model="filters.model.done"
												type="radio"
												class="radio radio-sm radio-primary align-middle"
												name="radioFilterStatus"
												:value="item.value"
											>
											<span class="align-middle label-text ml-2">{{ item.label }}</span>
										</label>
									</div>
								</div>
							</div>

							<div class="mt-5 filter-item">
								<div class="flex flex-col mb-2">
									<label
										for="filter_selectAssignees"
										class="font-bold mb-1"
									>Responsáveis</label>
									<input
										v-if="assigneesList.hasMoreThanTenAssignees"
										v-model="assigneesList.searchStr"
										type="text"
										placeholder="Digite para pesquisar"
										class="border border-blue-500 rounded flex-1 outline-none p-2 text-xs font-semibold"
									>
								</div>
								<div
									v-if="requesting.getAssigneesList"
									class="border border-blue-500 p-3 rounded"
								>
									<span>Carregando responsáveis...</span>

								</div>
								<div
									v-else
									class="border border-blue-500 p-3 rounded"
								>
									<div
										v-for="item in filtersListAssignees"
										:key="`filter_assignees_${item}`"
										class="form-control"
									>
										<label>
											<input
												v-model="filters.model.assignees"
												type="checkbox"
												class="checkbox-sm checkbox checkbox-primary align-middle"
												name="checkboxFilterAssignees"
												:value="item"
											>
											<span class="align-middle label-text ml-2">{{ item }}</span>
										</label>
									</div>
								</div>
							</div>

						</template>
						<template #actions-list="{ row }">
							<button
								class="flex items-center mb-1 p-1 pr-2 rounded-t-md w-full whitespace-nowrap hover:bg-[#E5F0FB] last:mb-0 border-b border-gray-300"
								@click="dataTableTasks_tdClick(row)"
							>
								<svg
									class="inline mr-1 w-4 h-4"
									fill="currentColor"
									viewBox="0 0 24 24"
									xmlns="http://www.w3.org/2000/svg"
									aria-hidden="true"
								>
									<path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32L19.513 8.2z" />
								</svg>
								<p class="text-sm">
									Editar
								</p>
							</button>
							<button
								class="flex items-center mb-1 p-1 pr-2 w-full rounded whitespace-nowrap hover:bg-[#E5F0FB] last:mb-0"
								@click="dataTableTasks_deleteClick($event, row.id)"
							>
								<svg
									class="inline mr-1 w-4 h-4"
									fill="currentColor"
									viewBox="0 0 20 20"
									xmlns="http://www.w3.org/2000/svg"
								><path
									fill-rule="evenodd"
									d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
									clip-rule="evenodd"
								/></svg>
								<p class="text-sm">
									Excluir
								</p>
							</button>
						</template>

						<template #col_is_late="{ row }">
							<span
								v-if="isLate(row) && !row.done"
								v-tippy="
									helpers?.getTippy(
										'A entrega desta tarefa está atrasada',
										{ placement: 'top-end' }
									)
								"
								class="flex justify-center items-center"
							>
								<svg
									class="highlight-on-hover fill-red-600"
									height="14px"
									width="14px"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 -960 960 960"
								>
									<path
										d="M92-120q-9 0-15.652-4.125Q69.696-128.25 66-135q-4.167-6.6-4.583-14.3Q61-157 66-165l388-670q5-8 11.5-11.5T480-850q8 0 14.5 3.5T506-835l388 670q5 8 4.583 15.7-.416 7.7-4.583 14.3-3.696 6.75-10.348 10.875Q877-120 868-120H92Zm392.175-117q12.825 0 21.325-8.675 8.5-8.676 8.5-21.5 0-12.825-8.675-21.325-8.676-8.5-21.5-8.5-12.825 0-21.325 8.675-8.5 8.676-8.5 21.5 0 12.825 8.675 21.325 8.676 8.5 21.5 8.5Zm0-111q12.825 0 21.325-8.625T514-378v-164q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625-12.825 0-21.325 8.625T454-542v164q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625Z"
									/>
								</svg>
							</span>
						</template>

						<template #col_due_at="{ row }">
							<span
								:title="luxon?.fromISO(row.due_at).toFormat('dd/MM/yyyy HH:mm')"
								class="whitespace-nowrap"
							>
								{{ luxon?.fromISO(row.due_at).toFormat('dd/MM/yyyy HH:mm') }}
							</span>
						</template>
						<template #col_done="{ row }">
							<span :title="row.done ? 'Concluída' : 'Pendente'">
								<svg
									:class="['inline h-6 w-6', { 'fill-green-500': row.done }, { 'fill-gray-100': !row.done }]"
									fill="currentColor"
									viewBox="0 0 20 20"
									xmlns="http://www.w3.org/2000/svg"
									aria-hidden="true"
								>
									<path
										clip-rule="evenodd"
										fill-rule="evenodd"
										d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
									/>
								</svg>
							</span>
						</template>
						<template #col_contacts="{ row }">
							<TagsDisplay
								:data="row.contacts"
								label="name"
								label-css-class="text-xs"
								:max-items="2"
							/>
						</template>
						<template #col_legal_case_id="{ row }">
							{{ row.legal_case.name }}
						</template>
						<template #col_tags="{ row }">
							<TagsDisplay
								:data="row.tags"
								label="title"
								label-css-class="text-xs"
								:max-items="2"
							/>
						</template>
						<template #col_assignees="{ row }">
							<TagsDisplay
								:data="row.assignees"
								label="name"
								label-css-class="text-xs"
								:max-items="2"
							/>
						</template>
						<template #col_created_at="{ row }">
							<span :title="luxon?.fromISO(row.created_at).toFormat('dd/MM/yyyy HH:mm')">
								{{ luxon?.fromISO(row.created_at).toFormat('dd/MM/yyyy') }}
							</span>
						</template>
						<template #col_updated_at="{ row }">
							<span :title="luxon?.fromISO(row.updated_at).toFormat('dd/MM/yyyy HH:mm')">
								{{ luxon?.fromISO(row.updated_at).toFormat('dd/MM/yyyy') }}
							</span>
						</template>
						<template #col_done_at="{ row }">
							<span
								v-if="row.done_at"
								:title="luxon?.fromISO(row.done_at).toFormat('dd/MM/yyyy HH:mm')"
							>
								{{ luxon?.fromISO(row.done_at).toFormat('dd/MM/yyyy') }}
							</span>
						</template>
					</DataTable>

				</template>

			</template>

			<template v-if="viewMode === 'calendar'">
				<Calendar
					class="mt-4"
					:events="dataAsEvents"

					@on-event-click="calendar_onEventClick"
					@on-cell-click="calendar_onCellClick"
					@on-event-drop="calendar_onEventDrop"
					@loaded="attachCalendarTitles"
				/>
			</template>

		</template>

		<ModalTask
			ref="modalTask"

			:back-route="{ name: 'tasks' }"
			:in-data="modalTask_inData"
			:task-id="urlParamId"
			:tasks-list="dataTableTasks.dataRaw"
			@task-created="modalTask_taskCreated"
			@task-updated="modalTask_taskUpdated"
			@open-change-to-client-modal="(clientId: string | number) =>
				toggleConfirmChangeToClientModal(true, clientId)
			"
		/>

		<ModalBasic
			ref="modalChangeToClient"
			:dismissable="!requesting.createClientFromLead"
		>
			<template #content>
				<div class="p-4 flex flex-col">
					<p class="font-bold text-lg mb-2">
						Um dos contatos adicionados é um Lead.
					</p>
					<p class="mb-1">
						Adicionar uma tarefa irá transformar esse lead em um cliente e também estará disponível para fazer cálculos.
					</p>
				</div>
			</template>
			<template #footer>
				<div class="flex-1 flex overflow-hidden rounded-b-md">
					<button
						class="flex-1 bg-gray-200 font-medium p-4 text-gray-500 hover:bg-gray-300 active:bg-gray-200"
						@click="toggleConfirmChangeToClientModal(false)"
					>
						CANCELAR
					</button>
					<button
						class="flex-1 bg-blue-500 font-medium p-4 text-white hover:bg-blue6400 active:bg-blue-500"
						@click="callCreateClientFromLead"
					>
						CONCORDO E DESEJO CONTINUAR
					</button>
				</div>
			</template>
		</ModalBasic>

	</section>
</template>

<style lang="scss">
	.highlight-on-hover :hover {
		filter: sepia(20%);
	}

	.cal-success {
		background-color: theme('colors.green.100') !important;
		border-color: theme('colors.green.400') !important;
	}

	.cal-error {
		background-color: theme('colors.red.100') !important;
		border-color: theme('colors.red.400') !important;
	}

	.cal-warning {
		background-color: theme('colors.yellow.100') !important;
		border-color: theme('colors.yellow.400') !important;
	}
</style>import { log } from 'console';
import { log } from 'console';
import { log } from 'console';
import { includes } from 'lodash';
import { filter } from 'lodash';
import { log } from 'console';
import { filter } from 'lodash';
import { toLower } from 'lodash';

