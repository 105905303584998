/**
 * Routes - CRM
 */

import LayoutCrm from '../../features/CRM/layouts/LayoutCrm.vue';

import Contacts from '../../features/CRM/views/Contacts/ContactsView.vue';
import ContactDetails from '../../features/CRM/views/Contacts/ContactDetails.vue';
import Dashboard from '../../features/CRM/views/Dashboard/DashboardView.vue';
import LegalCaseDetails from '../../features/CRM/views/LegalCases/LegalCaseDetails.vue';
import LegalCases from '../../features/CRM/views/LegalCases/LegalCases.vue';
import Tasks from '../../features/CRM/views/Tasks/TasksView.vue';

export default {
	path: '/gestao',
	redirect: '/gestao/painel',
	component: LayoutCrm,
	children: [ {
		path: 'painel',
		name: 'crm-panel',
		component: Dashboard,
	},
	{
		path: 'casos',
		name: 'legal-cases',
		component: LegalCases,
	},
	{
		path: 'casos/fluxos',
		name: 'funnels',
		component: LegalCases,
	},
	{
		path: 'casos/fluxos/criar',
		name: 'create-funnel',
		component: LegalCases,
	},
	{
		path: 'casos/fluxos/:id',
		name: 'specific-funnel',
		component: LegalCases,
	},
	{
		path: 'casos/fluxos/:id/editar',
		name: 'edit-funnel',
		component: LegalCases,
	},
	{
		path: 'caso/criar',
		name: 'create-legal-case',
		component: LegalCases,
	},
	{
		path: 'caso/:id',
		name: 'specific-legal-case',
		component: LegalCases,
	},
	{
		path: 'caso/detalhes/:id',
		name: 'legal-case-details',
		component: LegalCaseDetails,
	},
	{
		path: 'caso/detalhes/:id/editar',
		name: 'edit-legal-case',
		component: LegalCaseDetails,
	},
	{
		path: 'caso/detalhes/:id/adicionar-anexo',
		name: 'add-attachment-to-case',
		component: LegalCaseDetails,
	},
	{
		path: 'caso/detalhes/:id/adicionar-anotacao',
		name: 'add-note-to-case',
		component: LegalCaseDetails,
	},
	{
		path: 'caso/detalhes/:id/atualizar-anotacao/:noteId',
		name: 'update-case-note',
		component: LegalCaseDetails,
	},
	{
		path: 'caso/detalhes/:id/adicionar-tarefa',
		name: 'add-task-to-case',
		component: LegalCaseDetails,
	},
	{
		path: 'contatos',
		name: 'contacts',
		component: Contacts,
	},
	{
		path: 'contato/criar',
		name: 'create-contact',
		component: Contacts,
	},
	{
		path: 'contato/:id',
		name: 'specific-contact',
		component: ContactDetails,
	},

	{
		path: 'contato/:id/editar',
		name: 'edit-contact',
		component: ContactDetails,
	},
	{
		path: 'contato/:id/adicionar-anotacao',
		name: 'add-note-to-contact',
		component: ContactDetails,
	},
	{
		path: 'contato/:id/atualizar-anotacao/:noteId',
		name: 'update-contact-note',
		component: ContactDetails,
	},
	{
		path: 'tarefas',
		name: 'tasks',
		component: Tasks,
	},
	{
		path: 'tarefa/criar/:newTaskDueAt',
		name: 'create-task',
		component: Tasks,
	},
	{
		path: 'tarefa/criar',
		name: 'create-task-without-due-date',
		component: Tasks,
	},
	{
		path: 'tarefa/:id',
		name: 'specific-task',
		component: Tasks,
	},
	]
};
