<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
<template>
	<table class="component-data-table-basic">
		<thead>
			<slot name="thead" />
		</thead>
		<tbody>
			<slot name="tbody" />
		</tbody>
		<tfoot>
			<slot name="tfoot" />
		</tfoot>
	</table>
</template>
<style lang="scss" scoped></style>