/**
 * Routes - Finance
 */

import LayoutFinance from '../../features/Finance/layouts/LayoutFinance.vue';

import Dashboard from '../../features/Finance/views/Dashboard/DashboardView.vue';
import FirstAccess from '../../features/Finance/views/FirstAccess/FirstAccessView.vue';
import IncomesExpenses from '../../features/Finance/views/IncomesExpenses/IncomesExpensesView.vue';
import IncomesExpensesAdd from '../../features/Finance/views/IncomesExpenses/IncomesExpensesAddView.vue';
import Reports from '../../features/Finance/views/Reports/ReportsView.vue';
import Settings from '../../features/Finance/views/Settings/SettingsView.vue';

export default {
	path: '/financas',
	redirect: '/financas/painel',
	component: LayoutFinance,
	children: [
		{
			path: 'primeiro-acesso',
			name: 'first-access',
			component: FirstAccess,
		},
		{
			path: 'painel',
			name: 'finance-panel',
			component: Dashboard,
		},
		{
			path: 'entrada/:entryId',
			name: 'income-details',
			component: IncomesExpenses,
		},
		{
			path: 'entradas',
			name: 'incomes',
			component: IncomesExpenses,
		},
		{
			path: 'entradas/adicionar',
			name: 'add-income',
			component: IncomesExpensesAdd,
		},
		{
			path: 'saida/:entryId',
			name: 'expense-details',
			component: IncomesExpenses,
		},
		{
			path: 'saidas',
			name: 'expenses',
			component: IncomesExpenses,
		},
		{
			path: 'saidas/adicionar',
			name: 'add-expense',
			component: IncomesExpensesAdd,
		},
		{
			path: 'relatorios',
			name: 'reports',
			component: Reports,
		},
		{
			path: 'configuracoes',
			name: 'settings',
			component: Settings,
		}
	]
};
