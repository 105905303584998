<script lang="ts">
import {
	DefineComponent,
	defineComponent,
	inject,
} from 'vue';
import { mapActions } from 'pinia';

import { EventBus } from '../../../models/crm';
import { CostCenter } from '../../../models/financial';

import { useCategoriesStore } from '../../../stores/models/financial/categories';

import BlockingOverlay from '../../../components/BlockingOverlay/BlockingOverlay.vue';
import FormValidator from '../../../components/Form/FormValidator.vue';
import InputText from '../../../components/Form/InputText.vue';
import ModalBasic from '../../../components/Modal/ModalBasic.vue';

export default defineComponent({
	emits: [
		'cost-center-created',
	],
	components: {
		BlockingOverlay,
		FormValidator,
		InputText,
		ModalBasic,
	},
	data() {
		return {

			eventBus: inject<EventBus>('eventBus'),

			model: {
				description: '',
				name: '',
			},

			requesting: {
				save: false,
			},

			costCenterToEdit: null as CostCenter | null,

		};
	},
	methods: {
		...mapActions(useCategoriesStore, [ 'createCostCenter', 'editCostCenter' ]),

		hide(): void {
			(this.$refs.modal as typeof ModalBasic).hide();
		},

		resetModel(): void {
			this.model.description = '';
			this.model.name = '';
		},

		async save(): Promise<void> {
			const validateResult = (this.$refs.formValidator as typeof FormValidator).validate();
			const { isValid } = validateResult;

			if (isValid) {
				this.requesting.save = false;

				this.eventBus?.emit('BlockingOverlay/show', {
					text: 'Aguarde, criando Centro de Custo/Receita'
				});

				try {
					let response = false;

					if (this.costCenterToEdit?.id) {
						response = await this.editCostCenter(
							this.costCenterToEdit.id,
							this.model,
						);
					} else {
						response = await this.createCostCenter(this.model);
					}

					if (!response) throw new Error('Falha ao criar Centro de Custo/Receita');

					(this.$refs.modal as typeof ModalBasic).hide();

					this.eventBus?.emit(
						'Toast/add',
						{
							content: 'Centro de Custo/Receita criado com sucesso',
							type: 'success'
						}
					);
				} catch (err) {
					console.error(err);

					this.eventBus?.emit(
						'Toast/add',
						{
							content: 'Falha ao criar Centro de Custo/Receita',
							type: 'error'
						}
					);
				}

				this.$emit('cost-center-created');
			}

			this.eventBus?.emit('BlockingOverlay/hide');
		},

		show(): void {
			(this.$refs.formValidator as typeof FormValidator).reset();
			this.resetModel();

			this.model.name = this.costCenterToEdit?.name || '';
			this.model.description = this.costCenterToEdit?.description || '';

			(this.$refs.modal as typeof ModalBasic).show();
		},

		edit(costCenter: CostCenter): void {
			this.costCenterToEdit = costCenter;

			this.show();
		},

		// Events

		buttonCancelClick(): void {
			this.hide();
		},

		buttonSaveClick(): void {
			this.save();
		},

		modal_hide(): void {
			this.resetModel();
			this.costCenterToEdit = null;
		},

	}
});
</script>

<template>
	<ModalBasic
		ref="modal"

		size="lg"
		:title="costCenterToEdit?.id
			? 'Editar Centro de Custo/Receita'
			: 'Novo Centro de Custo/Receita'
		"

		@hide="modal_hide"
	>
		<template #content>
			<FormValidator
				ref="formValidator"
				:refs="($refs as DefineComponent)"
				:validation-summary-show="true"
				validation-summary-class="mb-4 mx-4 p-3"
			>
				<template
					field="inputName"
					label="Nome"
					message="Preenchimento obrigatório"
					:invalidIf="() => !model.name"
				/>
			</FormValidator>

			<div class="mb-4 px-4">
				<InputText
					ref="inputName"
					v-model="model.name"

					label="Nome"
					required
				/>
			</div>
			<div class="mb-4 px-4">
				<InputText v-model="model.description"
					ref="inputDescription"
					label="Descrição"
				/>
			</div>
		</template>
		<template #footer>
			<fieldset class="flex-1 flex overflow-hidden rounded-b-md">
				<button
					class="flex-1 bg-gray-200 font-medium p-4 text-gray-500 hover:bg-gray-300 active:bg-gray-200"
					@click="buttonCancelClick"
				>
					CANCELAR
				</button>
				<button
					class="flex-1 bg-primary font-medium p-4 text-white hover:bg-blue-600 active:bg-primary"
					@click="buttonSaveClick"
				>
					SALVAR
				</button>
			</fieldset>
		</template>
	</ModalBasic>

	<Teleport to="body">
		<BlockingOverlay />
	</Teleport>

</template>

<style lang="scss" scoped></style>../../../services/API/financial/costCenters