<script lang="ts">
import {
	PropType, defineComponent, inject
} from 'vue';
import DOMPurify from 'dompurify';
import {
	mapActions, mapState
} from 'pinia';

import {
	BreadCrumbItem,
	CaseNoteHistoryItem,
	ContactNote,
	DataType,
	EventBus,
	EventHistoryItem,
	FilterItem,
	Funnel,
	Helpers,
	LegalCaseAttachment,
	LegalCaseNote,
	LegalCaseVersion,
	Contact,
	Lodash,
	Luxon,
	InfoItem,
	Tab,
	Task,
	ReceivedEmail,
	Attachment,
} from '../../../../models/crm';

import { useAccountStore } from '../../../../stores/account';
import { useContactsStore } from '../../../../stores/models/crm/contacts';
import { useFunnelsStore } from '../../../../stores/models/crm/funnels';
import { useLegalCasesStore } from '../../../../stores/models/crm/legalCases';

import serviceLegalCases from '../../../../services/API/crm/legalCases';
import serviceContacts from '../../../../services/API/crm/contacts';
import serviceTasks from '../../../../services/API/crm/tasks';

import Loading from '../../../../components/Loading/LoadingComponent.vue';
import TagsDisplay from '../../../../components/Tags/TagsDisplay.vue';
import FiltersButtonsGroup from '../../components/LegalCaseDetails/FiltersButtonsGroup.vue';
import HistoryItem from '../LegalCaseDetails/HistoryItem.vue';
import InfoTooltip from '../../../../components/Form/InfoTooltip.vue';

import ModalTask from '../../views/Tasks/ModalTask.vue';
import ModalBasic from '../../../../components/Modal/ModalBasic.vue';
import ReceivedEmailModal from '../Contacts/ReceivedEmailModal.vue';
import QuickNotes from '../Contacts/QuickNotes.vue';
const tabsToShow = (dataType: DataType): Set<Tab> => {
	if (dataType === DataType.LEGAL_CASE) {
		return new Set([
			Tab.HISTORY,
			Tab.TASKS,
		]);
	}

	if (dataType === DataType.CONTACT) {
		return new Set([
			Tab.NOTES,
			Tab.CALCULATIONS,
			Tab.LEGAL_CASES,
			Tab.CJMAIL,
			Tab.ATTACHMENTS,
			// Tab.FINANCE,
		]);
	}

	return new Set([
		Tab.HISTORY,
		Tab.TASKS,
		Tab.LEGAL_CASES,
		Tab.NOTES,
		Tab.CALCULATIONS,
		Tab.CJMAIL,
		Tab.ATTACHMENTS,
		// Tab.FINANCE,
	]);
};

export default defineComponent({
	name: 'DetailsView',
	components: {
		Loading,
		TagsDisplay,
		FiltersButtonsGroup,
		HistoryItem,
		ModalTask,
		ModalBasic,
		QuickNotes,
		ReceivedEmailModal,
		InfoTooltip,
	},
	props: {
		dataType: {
			type: String as PropType<DataType>,
			required: true,
		},
		isLoading: {
			type: Boolean,
			required: true,
		},
		breadcrumbs: {
			type: Array as () => BreadCrumbItem[],
			required: true,
		},
		basicInfoItems: {
			type: Array as () => InfoItem[],
			required: true,
		},
		moreInfoItems: {
			type: Array as () => InfoItem[],
			required: true,
		},
		allTasks: {
			type: Array as PropType<Task[]>,
			default: () => [],
		},
		caseNotes: {
			type: Array as PropType<LegalCaseNote[]>,
			default: () => [],
		},
		contact: {
			type: Object as PropType<Contact>,
			default: () => ({}),
		},
		versions: {
			type: Array as PropType<LegalCaseVersion[]>,
			default: () => [],
		},
		attachments: {
			type: Array as PropType<LegalCaseAttachment[]>,
			default: () => [],
		},
	},
	emits: [
		'btn-edit-click',
		'btn-delete-click',
		'info-item-click',
		'should-update-info',
		'notes-updated',
		'btn-add-note-click',
		'btn-add-task-click',
		'btn-add-attachment-click',
		'btn-remove-attachment-click',
		'btn-edit-note-click',
	],
	data() {
		return {
			isMoreInfoOpen: false as boolean,

			tabsShown: tabsToShow(this.dataType) as Set<Tab>,

			currentTab: Array.from(tabsToShow(this.dataType))[0] || Tab.ATTACHMENTS as Tab,

			eventHistoryItems: [] as EventHistoryItem[],

			taskToEditId: 0 as string | number | undefined,

			emailToDisplay: {} as ReceivedEmail,

			clientIdToCreateFromLead: '' as string | number,

			config: {
				task: {
					nextToDueDateNotification: 5, // days before
				},
			},

			filters: {
				tasks: {
					items: [
						{
							label: 'Tudo', value: 'all'
						},
						{
							label: 'Atrasadas', value: 'delayed'
						},
						{
							label: 'Entrega próxima', value: 'next_to_due'
						},
					],
					model: 'all',
				},
				events: {
					items: [
						{
							label: 'Tudo', value: 'all'
						},
						{
							label: 'Anotações', value: 'notes'
						},
						{
							label: 'Tarefas', value: 'tasks'
						},
						// { label: 'Cálculos' , value: 'calc' },
						{
							label: 'Anexos', value: 'attachments'
						},
						{
							label: 'Alterações', value: 'logs'
						},
					],
					model: 'all',
				},
			} as Record<string, Record<string, string | FilterItem<string>[]>>,

			requesting: {
				createClientFromLead: false
			},

			lodash: inject<Lodash>('lodash'),
			luxon: inject<Luxon>('luxon'),
			eventBus: inject<EventBus>('eventBus'),
			helpers: inject<Helpers>('helpers'),
		};
	},
	computed: {
		...mapState(useAccountStore, [ 'user', 'officeUsers', 'office' ]),
		...mapState(useFunnelsStore, [ 'funnelsList' ]),
		...mapState(useLegalCasesStore, [ 'legalCasesList' ]),

		openTasksFiltered(): Task[] {
			if (this.allTasks.length) {
				const openTasks = this.allTasks.filter(item => item.done === false);

				const openTasksDateDesc = this.lodash?.orderBy(openTasks, [ 'due_at' ], [ 'desc' ]);

				const filterValue = this.filters.tasks?.model;

				let openTasksFiltered = openTasksDateDesc;

				if (filterValue === 'delayed') {
					openTasksFiltered = openTasksFiltered
						?.filter(item => this.getTaskDueAtStatus(item.due_at || '')?.isDelayed);
				}

				if (filterValue === 'next_to_due') {
					openTasksFiltered = openTasksFiltered
						?.filter(item => this.getTaskDueAtStatus(item.due_at || '')?.isNextToDueDate);
				}

				return openTasksFiltered || [];
			}

			return [];
		},

		urlParamId(): string {
			return typeof this.$route.params.id === 'string' ? this.$route.params.id : '';
		},

		noteToEdit(): LegalCaseNote | undefined {
			const noteId = typeof this.$route.params.noteId === 'string' ? this.$route.params.noteId : undefined;

			if (!noteId) return;

			return this.caseNotes?.find(item => item.id === Number(noteId));
		},

		eventHistoryItemsFiltered(): EventHistoryItem[] {
			const {eventHistoryItems} = this;

			const filterValue = this.filters.events?.model;

			if (filterValue === 'notes') {
				return eventHistoryItems
					.filter(item => item.type === 'note');
			}

			if (filterValue === 'tasks') {
				return eventHistoryItems.filter(item => item.type === 'task');
			}

			if (filterValue === 'attachments') {
				return eventHistoryItems.filter(item => item.type === 'attachment');
			}

			if (filterValue === 'logs') {
				return eventHistoryItems.filter(item => item.type === 'log');
			}

			// if (filterValue === 'calc') { /* empty */ }

			return eventHistoryItems;
		},
	},
	watch: {
		versions: {
			deep: true,
			immediate: true,
			handler(newValue?: LegalCaseVersion[], oldValue?: LegalCaseVersion[]) {
				if (newValue && newValue !== oldValue) {
					this.setEventHistoryItems();
				}
			},
		},
	},
	methods: {
		...mapActions(useContactsStore, [ 'createClientFromLead' ]),

		// Calls

		async callCreateClientFromLead(): Promise<void> {
			if (!this.user?.office_id) return;

			const clientLimitErrorMessage = 'Limite de cota atingido e não foi possível atualizar seu plano. Entre em contato com nosso suporte';

			this.requesting.createClientFromLead = true;

			this.eventBus?.emit('BlockingOverlay/show', {
				text: 'Aguarde, transformando lead em cliente',
			});

			try {
				if (!this.clientIdToCreateFromLead) {
					throw new Error('Não foi possível identificar o contato');
				}

				await this.createClientFromLead(this.user.office_id, this.clientIdToCreateFromLead);

				this.eventBus?.emit('BlockingOverlay/hide');

				this.requesting.createClientFromLead = false;

				this.toggleConfirmChangeToClientModal(false);

				(this.$refs.modalTask as typeof ModalTask).save();
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (err: any) {
				console.error(err);

				let stringifiedErrors = undefined;

				if (err?.response?.data?.errors) {
					stringifiedErrors = '';

					for (const errorKey in err.response.data.errors) {
						stringifiedErrors = `${stringifiedErrors} ${errorKey}: ${err.response.data.errors[errorKey]},`;
					}
				}

				if (err.message === clientLimitErrorMessage) {
					stringifiedErrors = err.message;
				}

				this.eventBus?.emit(
					'Toast/add',
					{
						content: stringifiedErrors || 'Erro ao transformar lead em cliente. Entre em contato com o suporte',
						type: 'error'
					}
				);

				this.eventBus?.emit('BlockingOverlay/hide');

				this.requesting.createClientFromLead = false;

				this.toggleConfirmChangeToClientModal(false);

				return;
			}
		},

		async removeEventHistoryItem(itemData: EventHistoryItem): Promise<void> {
			const {
				id: itemId, type
			} = itemData;

			let serviceToUse;

			switch (this.dataType) {
			case DataType.LEGAL_CASE:
				serviceToUse = serviceLegalCases;
				break;
			case DataType.CONTACT:
				serviceToUse = serviceContacts;
				break;
			case DataType.TASK:
				serviceToUse = serviceTasks;
				break;
			default:
				break;
			}

			const typeId = this.urlParamId || 0;

			let overlayText;
			let successText;
			let errorText;
			let serviceFunction;
			const serviceFunctionParams = {
				legalCaseId: this.dataType === DataType.LEGAL_CASE
					? typeId
					: 0,
				attachmentId: 0,
				noteId: 0,
				contactId: this.dataType === DataType.CONTACT
					? typeId
					: 0,
			};

			if (type === 'attachment') {
				overlayText = 'Aguarde, excluindo anexo';
				successText = 'Anexo excluído com sucesso';
				errorText = 'Falha ao excluir anexo';
				serviceFunction = (serviceToUse === serviceTasks)
					? undefined
					: (serviceToUse as (typeof serviceLegalCases) | (typeof serviceContacts))
						?.attachmentDelete;
				serviceFunctionParams.attachmentId = itemId;
			}

			if (type === 'note') {
				overlayText = 'Aguarde, excluindo anotação';
				successText = 'Anotação excluída com sucesso';
				errorText = 'Falha ao excluir anotação';
				serviceFunction =
					(serviceToUse === serviceTasks)
						? undefined
						: (serviceToUse as (typeof serviceLegalCases) | (typeof serviceContacts))
							?.noteDelete;
				serviceFunctionParams.noteId = itemId;
			}

			if (!serviceFunction) return;

			this.eventBus?.emit('BlockingOverlay/show', {
				text: overlayText,
			});


			try {
				if (serviceFunctionParams) await serviceFunction(serviceFunctionParams);

				this.eventBus?.emit(
					'Toast/add',
					{
						content: successText,
						type: 'success'
					}
				);

				this.$emit('should-update-info');
			} catch (err) {
				console.error(err);

				this.eventBus?.emit(
					'Toast/add',
					{
						content: errorText,
						type: 'error'
					}
				);

				return;
			} finally {
				this.eventBus?.emit('BlockingOverlay/hide');
			}
		},

		setEventHistoryItems(): void {
			const items = [] as EventHistoryItem[];

			this.attachments.forEach(item => {
				const intermediateItem = {
					...item,
					title: item.name,
					date_time: item.created_at,
					type: 'attachment'
				};

				items.push(intermediateItem);
			});

			// Log/Changes
			this.versions?.forEach(item => {
				const changesetItems = Object.entries(item.changeset);

				const elTitleContainer = document.createElement('div');
				elTitleContainer.classList.add('log-title-container');

				changesetItems.forEach(changesetItem => {
					const elItem = document.createElement('div');
					elItem.classList.add('log-changeset-row');

					const [ key, changes ] = changesetItem;

					const [ changeFrom, changeTo ] = changes.map((item) => {
						if (key === 'Tipo' && item) {
							const formatted = this.getCaseTypeText(item);
							return formatted || item;
						}

						if (key === 'Ativo?') {
							return item.length > 0 ? item : 'Não';
						}

						if (key === 'Editor' && item) {
							const formatted = this.officeUsers
								?.find((user) => `${user.id}` == item);

							return formatted?.name || item;
						}

						if (key === 'Criador' && item) {
							const formatted = this.officeUsers
								?.find((user) => `${user.id}` == item);

							return formatted?.name || item;
						}

						if (key === 'Escritório' && item) {
							return this.office.id == Number(item)
								? this.office.name
								: item;
						}

						return item;
					});

					elItem.innerHTML = `<span class=log-key>${key} </span><span class="log-changes">${changeFrom} → ${changeTo} </span>`;
					elTitleContainer.append(elItem);
				});

				const intermediateItem = {
					...item,
					date_time: this.luxon?.fromMillis(item.timestamp).toISO() || '',
					type: 'log',
					title: elTitleContainer.outerHTML,
				};


				items.push(intermediateItem);
			});

			this.caseNotes?.forEach(item => {
				const intermediateItem = {
					...item,
					date_time: item.created_at,
					type: 'note',
					title: item.content || '',
				};
				items.push(intermediateItem);
			});

			this.allTasks?.forEach(item => {
				const intermediateItem = {
					...item,
					date_time: item.created_at,
					type: 'task',
				};

				// if (item.done === true) {

				items.push(intermediateItem);

				// }
			});

			this.eventHistoryItems = this.lodash?.orderBy(items, [ 'date_time' ], [ 'desc' ]) || items;
		},

		// Events

		emitEditClick() {
			this.$emit('btn-edit-click');
		},

		emitDeleteClick() {
			this.$emit('btn-delete-click');
		},

		emitInfoItemClick(label: string, id: number | string) {
			this.$emit('info-item-click', {
				name: label, id
			});
		},

		toggleMoreInfo() {
			this.isMoreInfoOpen = !this.isMoreInfoOpen;
		},

		changeTab(tab: Tab) {
			this.currentTab = tab;
		},

		filtersClick({
			item, section
		}: {
			item: FilterItem<string>,
			section: string
		}): void {
			const intermediate = this.filters && this.filters[section];

			if (intermediate) {
				this.filters[section] = {
					...intermediate,
					model: item.value
				};
			}
		},

		openTaskModal(taskId?: string | number): void {
			this.taskToEditId = taskId;

			(this.$refs.modalTask as typeof ModalTask).show(taskId);
		},

		toggleConfirmChangeToClientModal(
			shouldOpen: boolean,
			clientId:string | number | null = null
		): void {
			if (clientId) {
				this.clientIdToCreateFromLead = clientId;
			}

			if (shouldOpen) {
				(this.$refs.modalChangeToClient as typeof ModalBasic).show();
				return;
			}

			(this.$refs.modalChangeToClient as typeof ModalBasic).hide();
		},

		buttonAddNoteClick(): void {
			this.$emit('btn-add-note-click');
		},

		buttonAddTaskClick(): void {
			this.$emit('btn-add-task-click');
		},

		buttonAddAttachmentClick(event: Event): void {
			const target = event.currentTarget as HTMLInputElement;
			this.$emit('btn-add-attachment-click', target.files || []);
		},

		buttonRemoveAttachmentClick(attachmentId: number): void {
			this.$emit('btn-remove-attachment-click', attachmentId);
		},

		buttonEditNoteClick(historyItem: CaseNoteHistoryItem | ContactNote): void {
			this.$emit('btn-edit-note-click', historyItem);
		},

		// eslint-disable-next-line @typescript-eslint/no-empty-function
		buttonAddCalcClick(): void {

		},

		quickNotes_updated(): void {
			this.$emit('notes-updated');
		},

		openEmailModal(email: ReceivedEmail): void {
			this.emailToDisplay = email;
			(this.$refs.receivedEmailModal as typeof ReceivedEmailModal).show();
		},

		async clickedCopyToClipboard(text: string): Promise<void> {
			let copySuccess = false;

			try {
				await navigator.clipboard.writeText(text);
				copySuccess = true;
			} catch (err) {
				const textArea = document.createElement('textarea');
				textArea.value = text;
				document.body.appendChild(textArea);
				textArea.focus();
				textArea.select();

				try {
					copySuccess = document.execCommand('copy');
				} catch (err) {
					console.error(err);
				} finally {
					document.body.removeChild(textArea);
				}
			}

			if (copySuccess) {
				this.eventBus?.emit('Toast/add', {
					content: 'Texto copiado com sucesso!',
					type: 'success',
				});
			} else {
				this.eventBus?.emit('Toast/add', {
					content: 'Falha ao copiar texto',
					type: 'error',
				});
			}
		},


		// Data transformation

		sanitizeData(data: string | number | undefined): string | number | undefined {
			if (typeof data === 'string') {
				const sanitizedData = DOMPurify.sanitize(data, {
					ALLOWED_TAGS: [ 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'ol', 'li', 'br', 'a', 'div', 'svg', 'span', 'path' ],
					ALLOWED_ATTR: [ 'class', 'title', 'href', 'tabindex', 'rel', 'target', 'viewBox', 'fill', 'd' ]
				});
				return sanitizedData;
			}

			return data;
		},

		getTaskDueAtStatus(taskDateIso: string)
			: { isDelayed: boolean, isNextToDueDate: boolean } | undefined {
			const now = this.luxon?.fromMillis(Date.now());
			const dueAt = this.luxon?.fromISO(taskDateIso);

			if (!now || !dueAt) return;

			const { days } = now.diff(dueAt, 'days').toObject();

			const ret = {
				isDelayed: false,
				isNextToDueDate: false,
			};

			if (days && days > 0) {
				ret.isDelayed = true;
			}

			if (days && days <= 0 && days > (this.config.task.nextToDueDateNotification * -1)) {
				ret.isNextToDueDate = true;
			}

			return ret;
		},

		getTaskColorBarCss(taskDateIso: string): string {
			const dueAtStatus = this.getTaskDueAtStatus(taskDateIso);

			if (!dueAtStatus) return '';

			const {
				isDelayed, isNextToDueDate
			} = dueAtStatus;

			if (isDelayed) {
				return 'bg-red-600';
			}

			if (isNextToDueDate) {
				return 'bg-yellow-500';
			}

			return '';
		},

		getTaskDueAtFlagTitle(taskDateIso: string): string {
			const dueAtStatus = this.getTaskDueAtStatus(taskDateIso);

			if (!dueAtStatus) return '';

			const {
				isDelayed, isNextToDueDate
			} = dueAtStatus;

			if (isDelayed) {
				return 'Atrasada';
			}

			if (isNextToDueDate) {
				return 'Próxima da entrega';
			}

			return '';
		},

		getCaseTypeText(id: string | number | undefined): string {
			const ret = '';

			if (this.funnelsList) {
				const foundType = this.funnelsList.find((funnel: Funnel) => funnel.id === id);

				if (foundType) {
					return foundType.name;
				} else {
					return ret;
				}
			} else {
				return ret;
			}
		},

		getTanslatedTabName(tab: Tab): string {
			switch (tab) {
			case Tab.HISTORY:
				return 'Atualizações';
			case Tab.TASKS:
				return 'Tarefas';
			case Tab.CALCULATIONS:
				return 'Cálculos';
			case Tab.ATTACHMENTS:
				return 'Documentos';
			case Tab.FINANCE:
				return 'Finanças';
			case Tab.NOTES:
				return 'Anotações';
			case Tab.LEGAL_CASES:
				return 'Casos';
			case Tab.CJMAIL:
				return 'CJMail';
			default:
				return '';
			}
		},

		// Modals

		modalTask_taskCreated(): void {
			this.$emit('should-update-info');
		},

		modalTask_taskUpdated(): void {
			this.$emit('should-update-info');
		},

		resetTaskToEdit(): void {
			this.taskToEditId = 0;
		},

		historyItemEvents_buttonDeleteClick(_e:Event, itemData: EventHistoryItem): void {
			this.removeEventHistoryItem(itemData);
		},

		async deleteContactNote(note: ContactNote): Promise<void> {
			if (!this.contact) return;

			this.eventBus?.emit('BlockingOverlay/show', {
				text: 'Aguarde, excluindo anotação'
			});

			try {
				await serviceContacts.noteDelete({
					contactId: this.contact.id,
					noteId: note.id,
				});

				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Anotação excluída com sucesso',
						type: 'success'
					}
				);

				this.$emit('notes-updated');
			} catch (err) {
				console.error(err);

				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Falha ao excluir anotação',
						type: 'error'
					}
				);
			} finally {
				this.eventBus?.emit('BlockingOverlay/hide');
			}
		},


		// This method allows downloading attachments from the server, considering that the browser does not allow direct download of files from other domains
		async generateAndTriggerDownload(attachment: Attachment): Promise<void> {
			try {
				const response = await fetch(attachment.url);
				if (!response.ok) {
					console.error(response);
					this.eventBus?.emit('Toast/add', {
						content: 'Erro ao baixar o anexo',
						type: 'error',
					});
				}
				const blob = await response.blob();
				const objectUrl = URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.style.display = 'none';
				a.href = objectUrl;
				a.download = attachment.name;
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
				URL.revokeObjectURL(objectUrl);
			} catch (error) {
				console.error(error);
				this.eventBus?.emit('Toast/add', {
					content: 'Erro ao baixar o anexo',
					type: 'error',
				});
			}
		}

	},
});
</script>

<template>
	<section class="flex flex-col p-0 feature">
		<Loading
			v-if="isLoading"
			class="opacity-75 py-14 text-center"
			icon-css-class="h-10 w-10 mr-3"
			text-css-class="text-2xl"
		/>
		<template v-else>
			<!-- Header -->
			<div class="flex flex-col bg-white border-b px-4 shadow-lg">
				<div class="py-4">
					<template v-for="(breadcrumb, index) in breadcrumbs">
						<router-link
							v-if="breadcrumb.to"
							:key="`breadcrumb-${index}-link`"
							:to="breadcrumb.to"
							class="text-gray-500 font-semibold text-xs hover:underline"
						>
							{{ breadcrumb.label }}
						</router-link>
						<span
							v-else
							:key="`breadcrumb-${index}-span`"
							class="text-[#3F87F5] font-semibold text-xs"
						>{{ breadcrumb.label }}</span>
						<svg
							v-if="index < breadcrumbs.length - 1"
							:key="`breadcrumb-${index}-arrow`"
							class="inline h-4 -rotate-90 text-gray-400"
							fill="currentColor"
							viewBox="0 0 20 20"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fill-rule="evenodd"
								d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
								clip-rule="evenodd"
							/>
						</svg>
					</template>
				</div>
				<div class="flex items-center justify-between border-b border-gray-300 pb-4">
					<div class="m-0 p-0 ">
						<span class="font-semibold text-xl"><slot name="page-title" /></span>
					</div>
					<div class="flex gap-2">
						<slot name="header-buttons" />
						<button
							:title="dataType === 'legal_case'
								? 'Editar caso'
								: (dataType === 'contact'
									? 'Editar contato'
									: 'Editar tarefa'
								)
							"
							class="btn btn--primary h-auto min-h-0 normal-case !px-2 !py-0.5 rounded text-white"
							@click="emitEditClick"
						>
							<svg
								class="inline w-6 h-6"
								fill="currentColor"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
								aria-hidden="true"
							>
								<path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32L19.513 8.2z" />
							</svg>
						</button>
						<button
							:title="dataType === 'legal_case'
								? 'Excluir caso'
								: (dataType === 'contact'
									? 'Excluir contato'
									: 'Excluir tarefa'
								)
							"
							class="btn btn--red h-auto min-h-0 normal-case !px-2 !py-0.5 rounded text-white"
							@click="emitDeleteClick"
						>
							<svg
								class="inline w-6 h-6"
								fill="currentColor"
								viewBox="0 0 20 20"
								xmlns="http://www.w3.org/2000/svg"
								aria-hidden="true"
							><path
								fill-rule="evenodd"
								d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
								clip-rule="evenodd"
							/></svg>
						</button>
					</div>
				</div>
				<template
					v-for="(item, index) in basicInfoItems"
				>
					<div
						v-if="(typeof item.value === 'string') && item.isHtml"
						:key="`basic-info-item-${item.label}-${index}-string-html`"
						class="flex flex-col my-2 gap-2 rounded"
					>
						<div
							class="flex items
							-center gap-2 flex-wrap"
						>
							<div class="block font-semibold text-sm">
								{{ item.label }}:
							</div>
							<div
								class="text-sm flex"
								v-html="sanitizeData(item.value)"
							/>
						</div>
					</div>

					<div
						v-if="(typeof item.value === 'string') && !item.isHtml"
						:key="`basic-info-item-${item.label}-${index}-string`"
						class="flex flex-col my-2 gap-2 rounded"
					>
						<div
							class="flex items-center gap-2 flex-wrap"
						>
							<div class="block font-semibold text-sm">
								{{ item.label }}:
							</div>
							<div class="text-sm">
								{{ item.value }}
							</div>
						</div>
					</div>
					<div
						v-if="(Array.isArray(item.value))"
						:key="`basic-info-item-${item.label}-${index}-array`"
						class="flex flex-col my-3 gap-2 rounded"
					>
						<div
							class="flex items-center gap-2 flex-wrap"
						>
							<div class="block font-semibold text-sm">
								{{ item.label }}:
							</div>
							<template
								v-if="item.value.length > 0 && item.value[0]?.id && !!item.value[0]?.type"
							>
								<div
									v-for="(entry, index) in item.value"
									:key="`basic-info-item-${item.label}-entry-${index}`"
								>
									<div class="font-semibold text-sm">
										<button
											v-if="entry.id"
											class="text-[#3F87F5] underline"
											@click="emitInfoItemClick(entry.name, entry.id)"
										>
											{{ entry.name }}
										</button>
									</div>
								</div>
							</template>
							<template
								v-if="item.value.length > 0 && (!item.value[0]?.id || !item.value[0]?.type)"
							>
								<TagsDisplay
									class="inline-flex"
									:data="item.value || []"
									label="name"
									item-css-class="!bg-transparent !border-0 !px-2"
									label-css-class="text-sm !max-w-full"
									:max-items="3"
								/>
							</template>
						</div>
					</div>
				</template>
				<div class="flex flex-col mb-3 gap-2 flex-wrap">
					<div class="flex items-center gap-2 mt-3">
						<div class="block font-semibold text-sm">
							Mais detalhes:
						</div>
						<button
							:title="isMoreInfoOpen ? 'Ocultar detalhes' : 'Mostrar detalhes'"
							class="text-primary w-6 h-6 hover:text-[rgb(90,170,255)] active:text-primary font-semibold text-xs"
							@click.stop="toggleMoreInfo"
						>
							<svg
								v-if="!isMoreInfoOpen"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 -960 960 960"
								fill="currentColor"
								class="w-full h-full"
							>
								<path d="M440-280h80v-160h160v-80H520v-160h-80v160H280v80h160v160Zm40 200q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
							</svg>
							<svg
								v-else
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 -960 960 960"
								fill="currentColor"
								class="w-full h-full"
							>
								<path d="M280-440h400v-80H280v80ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
							</svg>
						</button>
					</div>
					<div
						v-if="isMoreInfoOpen"
						class="flex flex-col gap-1 rounded border-2 p-2 border-gray-400 ml-5"
					>
						<template v-for="(item, index) in moreInfoItems">
							<div
								v-if="!item.isHtml && typeof item.value === 'string'"
								:key="`more-info-item-${item.label}-${index}-string`"
								class="flex font-semibold text-sm gap-2 items-center"
							>
								<div class="block font-semibold text-gray-500 text-xs">
									{{ item.label }}:
								</div>
								<div class="text-sm">
									{{ item.value }}
								</div>
							</div>
							<div
								v-if="item.isHtml && typeof item.value === 'string'"
								:key="`more-info-item-${item.label}-${index}-html`"
								class="flex font-semibold text-sm gap-2 items-center"
							>
								<div class="block font-semibold text-gray-500 text-xs">
									{{ item.label }}:
								</div>
								<div
									class="text-xs"
									v-html="sanitizeData(item.value)"
								/>
							</div>
							<div
								v-if="(Array.isArray(item.value))"
								:key="`more-info-item-${item.label}-${index}-array`"
								class="flex flex-col my-3 gap-2 rounded"
							>
								<div
									class="flex items-center gap-2 flex-wrap"
								>
									<div class="block font-semibold text-sm">
										{{ item.label }}:
									</div>
									<template
										v-if="item.value.length > 0 && item.value[0]?.id"
									>
										<div
											v-for="(entry, entryIndex) in item.value"
											:key="`more-info-item-${item.label}-${index}-entry-${entryIndex}`"
										>
											<div class="font-semibold text-sm">
												<button
													v-if="entry.id"
													class="text-[#3F87F5] underline"
													@click="emitInfoItemClick(entry.name, entry.id)"
												>
													{{ entry.name }}
												</button>
											</div>
										</div>
									</template>
									<template
										v-if="item.value.length > 0 && !item.value[0]?.id"
									>
										<TagsDisplay
											class="inline-flex"
											:data="item.value || []"
											label="name"
											item-css-class="!bg-transparent !border-0 !px-2"
											label-css-class="text-sm !max-w-full"
											:max-items="3"
										/>
									</template>
								</div>
							</div>
						</template>
					</div>
				</div>
				<slot name="under-basic-info" />

				<hr class="opacity-50">

				<!-- Tabs -->
				<div class="flex items-center justify-start">
					<button
						v-for="tab in Array.from(tabsShown)"
						:key="`tab-${tab}`"
						:class="[
							'border-[#3F87F5] px-4 py-2 text-gray-400 hover:text-gray-500 hover:bg-gray-100 text-lg',
							{ 'text-[#3F87F5] border-b border-b-2 font-semibold': tab === currentTab }
						]"
						@click="changeTab(tab)"
					>
						{{ getTanslatedTabName(tab) }}
					</button>
				</div>
			</div>

			<!-- Cols wrapper -->
			<div class="flex flex-1">

				<!-- Main content -->
				<div class="bg-white border-l border-r flex-1 overflow-auto p-4 flex flex-col gap-8 min-h-80">

					<!-- Planned/Open -->
					<div v-if="currentTab === 'tasks'">
						<FiltersButtonsGroup
							class="mb-2"
							:items="((filters.tasks?.items || []) as FilterItem<string>[])"
							:selected-value="((filters.tasks?.model || '') as string)"
							section="tasks"

							@clicked-filter-button="filtersClick"
						/>
						<div
							v-if="openTasksFiltered.length < 1"
							class="border border-gray-200 p-2 rounded text-xs"
						>
							Nenhuma tarefa para os filtros selecionados
						</div>
						<HistoryItem
							v-for="(task, index) in openTasksFiltered"
							:key="`task-${index}`"

							:color-bar-css-class="getTaskColorBarCss(task.due_at || '')"
							:item-data="task"
							clickable

							@clicked-history-item="openTaskModal(task.id)"
						>
							<template #info="{ itemData }">
								<div class="font-semibold leading-none">
									{{ itemData.title }}
								</div>
								<div
									v-if="itemData.description"
									class="leading-none"
								>
									<span class="font-semibold text-xs">Descrição:</span>
									<span
										class="ml-1 text-xs"
										v-html="sanitizeData(itemData.description)"
									/>
								</div>
								<div
									v-if="itemData.assignees[0]?.name"
									class="leading-none"
								>
									<span class="font-semibold text-xs">Responsável:</span>
									<span class="ml-1 text-xs">{{ itemData.assignees[0].name }}</span>
								</div>
								<div class="leading-none">
									<span class="font-semibold text-xs">Entrega:</span>
									<span class="ml-1 text-xs">
										{{ luxon?.fromISO(itemData.due_at).toFormat('dd/MM/yyyy') }}
									</span>
								</div>
								<div v-if="itemData.tags.length > 0">
									<hr class="my-1 opacity-50">
									<div class="font-semibold inline-block text-xs">
										Tags:
									</div>
									<TagsDisplay
										class="inline-flex ml-1"
										:data="itemData.tags"
										label="title"
										label-css-class="text-xs"
										item-css-class="!px-1 !py-0"
										:max-items="3"
									/>
								</div>
							</template>
							<template #flags="{ itemData }">
								<span
									:class="[
										'h-6 inline-flex items-center justify-center rounded-full w-6',
										{ 'bg-yellow-500': getTaskDueAtStatus(itemData.due_at || '')?.isNextToDueDate },
										{ 'bg-red-600': getTaskDueAtStatus(itemData.due_at || '')?.isDelayed }
									]"
									:title="getTaskDueAtFlagTitle(itemData.due_at)"
								>
									<svg
										class="fill-white h-5 inline w-5"
										fill="currentColor"
										viewBox="0 0 24 24"
										xmlns="http://www.w3.org/2000/svg"
										aria-hidden="true"
									>
										<path
											clip-rule="evenodd"
											fill-rule="evenodd"
											d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 6a.75.75 0 00-1.5 0v6c0 .414.336.75.75.75h4.5a.75.75 0 000-1.5h-3.75V6z"
										/>
									</svg>
								</span>
							</template>
						</HistoryItem>
					</div>

					<!-- Completed -->
					<div v-if="currentTab === 'history'">
						<FiltersButtonsGroup
							class="mb-2"
							:items="((filters.events?.items || []) as FilterItem<string>[])"
							:selected-value="((filters.events?.model || '') as string)"
							section="events"

							@clicked-filter-button="filtersClick"
						/>
						<div
							v-if="eventHistoryItemsFiltered.length < 1"
							class="border border-gray-200 p-2 rounded text-xs"
						>
							Nenhum item para os filtros selecionados
						</div>
						<HistoryItem
							v-for="(historyItem, index) in eventHistoryItemsFiltered"
							:key="`history-item-${index}`"
							:item-data="historyItem"
							:clickable="historyItem.type === 'task'"

							@clicked-history-item="
								historyItem.type === 'task'
									? openTaskModal(historyItem.id)
									: () => {}
							"
						>
							<template #type-icon="{ itemData }">
								<span
									v-if="itemData.type === 'attachment'"
									title="Anexo"
								>
									<svg
										class="h-5 w-5"
										fill="currentColor"
										viewBox="0 0 20 20"
										xmlns="http://www.w3.org/2000/svg"
										aria-hidden="true"
									>
										<path
											clip-rule="evenodd"
											fill-rule="evenodd"
											d="M15.621 4.379a3 3 0 00-4.242 0l-7 7a3 3 0 004.241 4.243h.001l.497-.5a.75.75 0 011.064 1.057l-.498.501-.002.002a4.5 4.5 0 01-6.364-6.364l7-7a4.5 4.5 0 016.368 6.36l-3.455 3.553A2.625 2.625 0 119.52 9.52l3.45-3.451a.75.75 0 111.061 1.06l-3.45 3.451a1.125 1.125 0 001.587 1.595l3.454-3.553a3 3 0 000-4.242z"
										/>
									</svg>
								</span>
								<span
									v-if="itemData.type === 'log'"
									title="Alteração"
								>
									<svg
										class="h-5 w-5"
										fill="currentColor"
										viewBox="0 0 20 20"
										xmlns="http://www.w3.org/2000/svg"
										aria-hidden="true"
									>
										<path
											clip-rule="evenodd"
											fill-rule="evenodd"
											d="M13.2 2.24a.75.75 0 00.04 1.06l2.1 1.95H6.75a.75.75 0 000 1.5h8.59l-2.1 1.95a.75.75 0 101.02 1.1l3.5-3.25a.75.75 0 000-1.1l-3.5-3.25a.75.75 0 00-1.06.04zm-6.4 8a.75.75 0 00-1.06-.04l-3.5 3.25a.75.75 0 000 1.1l3.5 3.25a.75.75 0 101.02-1.1l-2.1-1.95h8.59a.75.75 0 000-1.5H4.66l2.1-1.95a.75.75 0 00.04-1.06z"
										/>
									</svg>
								</span>
								<span
									v-if="itemData.type === 'note'"
									title="Anotação"
								>
									<svg
										class="h-5 w-5"
										fill="currentColor"
										viewBox="0 0 20 20"
										xmlns="http://www.w3.org/2000/svg"
										aria-hidden="true"
									>
										<path
											clip-rule="evenodd"
											fill-rule="evenodd"
											d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm7 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
										/>
									</svg>
								</span>
								<span
									v-if="itemData.type === 'task'"
									title="Tarefa"
								>
									<svg
										class="h-5 w-5"
										fill="currentColor"
										viewBox="0 0 20 20"
										xmlns="http://www.w3.org/2000/svg"
										aria-hidden="true"
									>
										<path
											clip-rule="evenodd"
											fill-rule="evenodd"
											d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
										/>
									</svg>
								</span>
							</template>
							<template #info="{ itemData }">
								<component
									:is="itemData.type === 'attachment' ? 'a' : 'div'"
									:class="[
										'font-semibold text-slate-700 text-sm',
										{ '!text-[#3F87F5] underline': itemData.type === 'attachment' },
										{ 'py-2': itemData.type === 'log' },
									]"


									:href="itemData.type === 'attachment' ? itemData.url : false"
									:target="itemData.type === 'attachment' ? '_blank' : false"
								>
									<span v-html="sanitizeData(itemData.title)" />
								</component>
								<div class="leading-none">
									<span class="font-semibold text-xs">
										<span v-if="itemData.type === 'task'">Criada em:</span>
										<span v-else-if="itemData.type === 'attachment'">Adicionado em:</span>
										<span v-else-if="itemData.type === 'log'">Alterações em:</span>
										<span v-else-if="itemData.type === 'note'">Adicionada em:</span>
										<span v-else>Data:</span>
									</span>
									<span class="ml-1 text-xs">
										{{ luxon?.fromISO(itemData.date_time).toFormat('dd/MM/yyyy HH:mm') }}
									</span>
								</div>
								<div
									v-if="itemData.type === 'task' && itemData.done"
									class="leading-none"
								>
									<span class="font-semibold text-xs">
										<span>Concluída em:</span>
									</span>
									<span class="ml-1 text-xs">
										{{ luxon?.fromISO(itemData.done_at).toFormat('dd/MM/yyyy HH:mm') }}
									</span>
								</div>
								<div v-if="itemData.type === 'task' && itemData.tags.length > 0">
									<hr class="my-1 opacity-50">
									<div>
										<div class="font-semibold inline-block text-xs">
											Tags:
										</div>
										<TagsDisplay
											class="inline-flex ml-1"
											:data="itemData.tags"
											label="title"
											label-css-class="text-xs"
											item-css-class="!px-1 !py-0"
											:max-items="3"
										/>
									</div>
								</div>
							</template>
							<template
								v-if="['attachment', 'note'].includes(historyItem.type)"
								#actions="{ itemData }"
							>
								<li v-if="itemData && itemData.type === 'note'">
									<button @click="buttonEditNoteClick(itemData)">
										<svg
											class="inline w-6 h-6"
											fill="currentColor"
											viewBox="0 0 24 24"
											xmlns="http://www.w3.org/2000/svg"
											aria-hidden="true"
										>
											<path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32L19.513 8.2z" />
										</svg>
										<span class="text-sm">Editar</span>
									</button>
								</li>
								<li>
									<button @click="historyItemEvents_buttonDeleteClick($event, itemData)">
										<svg
											class="inline w-5 h-5"
											fill="currentColor"
											viewBox="0 0 20 20"
											xmlns="http://www.w3.org/2000/svg"
										><path
											fill-rule="evenodd"
											d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
											clip-rule="evenodd"
										/></svg>
										<span class="text-sm">Excluir</span>
									</button>
								</li>
							</template>
						</HistoryItem>
					</div>
					<div v-if="currentTab === 'notes'">
						<div class="flex flex-col">
							<div
								:class="[
									{'flex flex-col gap-4 items-center p-4 text-gray-400': !contact.notes?.length, 'self-end mb-2': contact.notes?.length
									}]"
							>
								<button
									class="btn btn--primary"
									@click="buttonAddNoteClick"
								>
									Adicionar anotação
								</button>
								<span v-if="!contact.notes?.length">Nenhuma anotação para esse contato.</span>
							</div>
							<HistoryItem
								v-for="(note, index) in contact.notes"
								:key="`note-${index}`"
								:item-data="note"
							>
								<template #type-icon>
									<span
										title="Anotação"
									>
										<svg
											class="h-5 w-5"
											fill="currentColor"
											viewBox="0 0 20 20"
											xmlns="http://www.w3.org/2000/svg"
											aria-hidden="true"
										>
											<path
												clip-rule="evenodd"
												fill-rule="evenodd"
												d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm7 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
											/>
										</svg>
									</span>

								</template>
								<template #info>
									<div

										class="font-semibold text-slate-700 text-sm"
									>
										<span v-html="sanitizeData(note.content)" />
										<div class="leading-none">
											<span class="font-semibold text-xs">

												<span>Adicionada em:</span>
											</span>
											<span class="ml-1 text-xs">
												{{ luxon?.fromISO(note.created_at).toFormat('dd/MM/yyyy HH:mm') }}
											</span>
										</div>
									</div>
								</template>
								<template
									#actions
								>
									<li>
										<button @click="buttonEditNoteClick(note)">
											<svg
												class="inline w-6 h-6"
												fill="currentColor"
												viewBox="0 0 24 24"
												xmlns="http://www.w3.org/2000/svg"
												aria-hidden="true"
											>
												<path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32L19.513 8.2z" />
											</svg>
											<span class="text-sm">Editar</span>
										</button>
									</li>
									<li>
										<button @click="deleteContactNote(note)">
											<svg
												class="inline w-5 h-5"
												fill="currentColor"
												viewBox="0 0 20 20"
												xmlns="http://www.w3.org/2000/svg"
											><path
												fill-rule="evenodd"
												d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
												clip-rule="evenodd"
											/></svg>
											<span class="text-sm">Excluir</span>
										</button>
									</li>
								</template>
							</HistoryItem>
						</div>

					</div>
					<div v-if="currentTab === 'calculations'">
						<div class="flex flex-col">
							<div
								:class="[
									{'flex flex-col gap-4 items-center p-4 text-gray-400': !contact.calculations?.length, 'self-end mb-2': contact.calculations?.length
									}]"
							>
								<a
									href="/novo-calculo/todos"
									class="btn btn--primary"
								>
									Novo cálculo
								</a>
								<span v-if="!contact.calculations?.length">Nenhum cálculo para esse contato.</span>
							</div>
							<div
								v-if="contact.calculations?.length"
								class="flex flex-col"
							>

								<div class="p-4 bg-gray-400 text-white text-left text-md font-semibold uppercase tracking-wide">
									Histórico de Cálculos
								</div>
								<div
									v-for="(calculationItem) in contact.calculations.slice(0,5)"
									:key="`calculation-${calculationItem.id}`"
									class="bg-gray-200 border mb-1 p-2"
								>
									<div class="text-gray-500">
										<span class="inline-block">
											{{ calculationItem.calculation_kind_human }}
										</span>
										<span class="inline-block ml-1">-</span>
										<span class="ml-1">
											{{ luxon?.fromISO(calculationItem.created_at).toFormat("dd/MM/yyyy") }}
										</span>
									</div>
									<div class="font-semibold my-2">
										{{ calculationItem.name }}
									</div>
									<a
										class="inline-block text-blue-500 no-underline"
										:href="calculationItem.url"
										target="_blank"
									>
										Abrir cálculo
										<span class="text-icon">
											<svg viewBox="0 0 24 24">
												<path
													d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z"
													fill="currentColor"
												/>
											</svg>
										</span>
									</a>

								</div>
								<div
									v-if="contact.calculations.length > 5"
									class="text-gray-400 text-md"
								>
									<a
										:href="`/calculos?busca=${contact.name}`"
										class="text-blue-500 no-underline"
									>
										Ver todos os cálculos ({{ contact.calculations.length }})
									</a>
								</div>
							</div>
						</div>
					</div>
					<div v-if="currentTab === 'legal_cases'">
						<div class="flex flex-col">
							<div
								:class="[
									{'flex flex-col gap-4 items-center p-4 text-gray-400': !contact.legal_cases?.length, 'self-end mb-2 mr-4': contact.legal_cases?.length
									}]"
							>
								<router-link
									:to="{name: 'create-legal-case'}"
									class="btn btn--primary"
								>
									Novo caso

								</router-link>
								<span v-if="!contact.legal_cases?.length">Nenhum caso para esse contato.</span>
							</div>
							<div
								v-if="contact.legal_cases?.length"
								class="flex flex-col items-center justify-center mx-auto px-2 sm:px-4 gap-2 w-full"
							>
								<div class="w-full overflow-x-auto sm:overflow-x-visible">
									<table class="min-w-full divide-gray-200 text-xs sm:text-sm">
										<thead class="sticky">
											<tr>
												<th
													scope="col"
													class="p-5 bg-gray-400 text-white text-left text-md font-semibold uppercase tracking-wide"
													colspan="6"
												>
													Casos deste contato
												</th>
											</tr>
											<tr>
												<th
													scope="col"
													class="px-6 text-gray-400 font-semibold py-3 text-left text-sm uppercase tracking-wide"
												>
													Nome do caso
												</th>
												<th
													scope="col"
													class="px-6 text-gray-400 font-semibold py-3 text-left text-sm uppercase tracking-wide"
												>
													Fluxo de casos
												</th>
												<th
													scope="col"
													class="px-6 text-gray-400 font-semibold py-3 text-left text-sm uppercase tracking-wide"
												>
													Etapa do caso
												</th>
												<th
													scope="col"
													class="px-6 text-gray-400 font-semibold py-3 text-left text-sm uppercase tracking-wide"
												>
													Pendências
												</th>
												<th
													scope="col"
													class="px-6 text-gray-400 font-semibold py-3 text-left text-sm uppercase tracking-wide"
												>
													Última movimentação
												</th>
											</tr>
										</thead>
										<tbody class="bg-white divide-gray-200">
											<tr
												v-for="(legalCase) in contact.legal_cases"
												:key="`legal-case-${legalCase.id}`"
											>
												<td class="px-6 py-4 whitespace-nowrap">
													<router-link
														:to="{name: 'legal-case-details', params: { id: legalCase.id }}"
														class="text-blue-600 hover:text-blue-900"
														title="Clique para abrir o caso"
													>
														{{ legalCase.name }}
													</router-link>
												</td>
												<td class="px-6 py-4">
													<router-link
														v-if="legalCase.funnel?.id"
														:to="{name: 'specific-funnel', params: { id: legalCase.funnel.id }}"
														class="text-blue-600 hover:text-blue-900"
													>
														{{ legalCase.funnel?.name }}
													</router-link>
													<span v-else>--</span>
												</td>
												<td class="px-6 py-4">
													{{ legalCase.funnel_steps?.map((funnel_step) => funnel_step.name).join(', ') }}
												</td>
												<td class="px-6 py-4">
													{{ legalCase.pending_tasks || 0 }}
												</td>
												<td class="px-6 py-4">
													{{ luxon?.fromISO(legalCase.updated_at || '').toFormat('dd/MM/yyyy') }}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
					<div v-if="currentTab === 'cjmail'">
						<div class="p-6">
							<p class="mb-2">
								Os endereços de e-mail abaixo são gerados automaticamente pela
								<strong>Extensão CJ-Mail</strong>.
								Eles são endereços únicos para este contato e os e-mails
								enviados para qualquer um deles vão chegar aqui no perfil deste contato.
							</p>
							<a
								href="https://ajuda.calculojuridico.com.br/pt-BR/articles/5914417-extensao-cj-mail-como-usar"
								target="_blank"
								class="text-primary underline"
							>
								Saiba mais - Tutorial completo
							</a>
							<div class="mt-4 flex flex-col">
								<div class="flex items-center">
									<span class="text-gray-400 font-semibold">CJ Mail</span>
									<InfoTooltip
										content="<p>Obs: Os emails enviados podem demorar alguns
                        minutos para aparecer no perfil do contato. Você recebe uma
                        notificação dentro do CJ quando chegar.
                        </p><p>Obs 2: Os emails aparecem aqui dentro do perfil do
                        contato, visíveis pelos usuários do seu escritório com
                        acesso a este perfil.
                        </p>"
									/>
								</div>
								<div class="flex flex-col font-semibold w-fit">
									<div
										class="value flex text-gray-700 normal-case cursor-pointer hover:text-gray-500"
										title="Clique para copiar"
										@click="clickedCopyToClipboard(`${contact.hash_id}@cjmail.com.br`)"
									>
										{{ `${contact.hash_id}@cjmail.com.br` }}
										<div class="h-4 w-4 ml-2">
											<svg viewBox="0 0 24 24"><path d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z" />
											</svg>
										</div>
									</div>
									<div class="text-xs text-gray-600">
										ou
									</div>
									<div
										class="value flex text-gray-700 normal-case cursor-pointer hover:text-gray-500"
										title="Clique para copiar"
										@click="clickedCopyToClipboard(`${contact.hash_id}@cjmailbox.com`)"
									>
										{{ `${contact.hash_id}@cjmailbox.com` }}
										<div class="h-4 w-4 ml-2">
											<svg viewBox="0 0 24 24"><path d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z" />
											</svg>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="flex flex-col">
							<div class="p-4 bg-gray-400 text-white text-left text-md font-semibold uppercase tracking-wide">
								Emails do Contato
							</div>
							<div
								v-for="(email) in contact.received_emails"
								:key="`email-${email.id}`"
							>
								<div
									class="flex flex-col py-4 px-4 mb-0 break-words cursor-pointer border border-solid border-t-0 border-l-0 border-r-0 border-b border-gray-300 hover:bg-gray-200"
									@click="openEmailModal(email)"
								>
									<div class="flex justify-between text-gray-600 text-sm font-semibold">
										<div>{{ email.sender }}</div>
										<div class="text-xs font-normal">
											{{ luxon?.fromISO(email.created_at).toFormat('dd/MM/yyyy HH:mm') }}
										</div>
									</div><span class="text-xs truncate">{{ email.subject }}</span>
								</div>
							</div>
							<div v-if="!contact.received_emails?.length">
								<div class="p-2 text-left text-gray-400 text-sm">
									Nenhum email recebido.
								</div>
							</div>

						</div>

					</div>
					<div v-if="currentTab === 'attachments'">
						<div
							class="flex flex-col items-center justify-center mx-auto px-2 sm:px-4 gap-2 w-full"
						>
							<div
								:class="[
									{'flex flex-col gap-4 items-center p-4 text-gray-400': !contact.attachments?.length, 'self-end': contact.attachments?.length
									}]"
							>
								<button>
									<label>
										<input
											type="file"
											class="hidden"
											@change="buttonAddAttachmentClick"
										>
										<span class="btn btn--primary">Adicionar documento</span>
									</label>
								</button>
								<span v-if="!contact.attachments?.length">Nenhum documento para esse contato.</span>
							</div>
							<div
								v-if="contact.cnis || contact.concession_letter"
								class="self-start border border-blue-500 bg-blue-200 p-6 pt-2 w-full"
							>
								<div class="mb-2 font-semibold">
									Documentos vinculados a esse contato
								</div>
								<div class="flex justify-between ml-6">
									<ul class="list-disc">
										<li v-if="contact.cnis">
											CNIS
										</li>
										<li v-if="contact.concession_letter">
											Carta de concessão
										</li>
									</ul>
									<a
										:href="`/analise-previdenciaria-automatica?cliente=${contact.slug}&pagina=1`"
										class="btn btn--primary !p-2 mb-3"
									>Acessar análise previdenciária rápida</a>
								</div>
							</div>
							<div
								v-if="contact.attachments?.length"
								class="w-full overflow-x-auto sm:overflow-x-visible"
							>
								<table class="min-w-full divide-gray-200 text-xs sm:text-sm">
									<thead class="sticky">
										<tr>
											<th
												scope="col"
												class="p-5 bg-gray-400 text-white text-left text-md font-semibold uppercase tracking-wide"
												colspan="6"
											>
												Documentos e arquivos deste contato
											</th>
										</tr>
										<tr>
											<th
												scope="col"
												class="px-6 text-gray-400 font-semibold py-3 text-left text-sm uppercase tracking-wide"
											>
												Nome
											</th>
											<th
												scope="col"
												class="px-6 text-gray-400 font-semibold py-3 text-left text-sm uppercase tracking-wide"
											>
												Criado por
											</th>
											<th
												scope="col"
												class="px-6 text-gray-400 font-semibold py-3 text-left text-sm uppercase tracking-wide"
											>
												Data de criação
											</th>
											<th
												scope="col"
												class="px-6 text-gray-400 font-semibold py-3 text-left text-sm uppercase tracking-wide"
											>
												Ações
											</th>
										</tr>
									</thead>
									<tbody class="bg-white divide-gray-200">
										<tr
											v-for="(attachment) in contact.attachments"
											:key="`attachment-${attachment.id}`"
										>
											<td class="px-6 py-4">
												<a
													:href="attachment.url"
													class="text-blue-600 hover:text-blue-900"
												>
													{{ attachment.name }}
												</a>
											</td>
											<td class="px-6 py-4">
												{{ attachment.creator_name }}
											</td>
											<td class="px-6 py-4">
												{{ luxon?.fromISO(attachment.created_at).toFormat('dd/MM/yyyy') }}
											</td>
											<td class="px-6 py-4 flex gap-4">
												<button
													target="_blank"
													title="Baixar/abrir arquivo"
													@click="generateAndTriggerDownload(attachment)"
												>
													<svg
														data-v-e4d22c0e=""
														class="inline w-5 h-5 hover:text-primary"
														fill="currentColor"
														viewBox="0 0 20 20"
														xmlns="http://www.w3.org/2000/svg"
													><path
														data-v-e4d22c0e=""
														fill-rule="evenodd"
														d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
														clip-rule="evenodd"
													/></svg>
												</button>
												<button
													title="Deletar arquivo"
													class="text-red-600 font-semibold"
													@click="buttonRemoveAttachmentClick(attachment.id)"
												>
													<svg
														class="inline w-5 h-5"
														fill="currentColor"
														viewBox="0 0 20 20"
														xmlns="http://www.w3.org/2000/svg"
													><path
														fill-rule="evenodd"
														d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
														clip-rule="evenodd"
													/></svg>
												</button>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>

				<!-- Shortcuts sidebar -->
				<div
					v-if="dataType === 'legal_case'"
					class="bg-gray-100 flex flex-col items-center justify-start pt-4 w-14 container-shortcuts"
				>

					<button
						v-tippy="helpers?.getTippy('Adicionar anotação', { placement: 'left' })"
						class="bg-white p-1.5 rounded-full shadow-md"

						@click="buttonAddNoteClick"
					>
						<svg
							class="color-white h-6 opacity-75 w-6"
							viewBox="0 0 24 24"
							fill="currentColor"
							xmlns="http://www.w3.org/2000/svg"
						><path
							d="M6 6C6 5.44772 6.44772 5 7 5H17C17.5523 5 18 5.44772 18 6C18 6.55228 17.5523 7 17 7H7C6.44771 7 6 6.55228 6 6Z"
							fill="currentColor"
						/><path
							d="M6 10C6 9.44771 6.44772 9 7 9H17C17.5523 9 18 9.44771 18 10C18 10.5523 17.5523 11 17 11H7C6.44771 11 6 10.5523 6 10Z"
							fill="currentColor"
						/><path
							d="M7 13C6.44772 13 6 13.4477 6 14C6 14.5523 6.44771 15 7 15H17C17.5523 15 18 14.5523 18 14C18 13.4477 17.5523 13 17 13H7Z"
							fill="currentColor"
						/><path
							d="M6 18C6 17.4477 6.44772 17 7 17H11C11.5523 17 12 17.4477 12 18C12 18.5523 11.5523 19 11 19H7C6.44772 19 6 18.5523 6 18Z"
							fill="currentColor"
						/><path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M2 4C2 2.34315 3.34315 1 5 1H19C20.6569 1 22 2.34315 22 4V20C22 21.6569 20.6569 23 19 23H5C3.34315 23 2 21.6569 2 20V4ZM5 3H19C19.5523 3 20 3.44771 20 4V20C20 20.5523 19.5523 21 19 21H5C4.44772 21 4 20.5523 4 20V4C4 3.44772 4.44771 3 5 3Z"
							fill="currentColor"
						/></svg>
					</button>

					<button
						v-tippy="helpers?.getTippy('Adicionar tarefa', { placement: 'left' })"
						class="bg-white mt-3 p-1.5 rounded-full shadow-md"

						@click="buttonAddTaskClick"
					>
						<svg
							class="h-6 opacity-75 w-6"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						><path
							d="M15 6H3V8H15V6Z"
							fill="currentColor"
						/><path
							d="M15 10H3V12H15V10Z"
							fill="currentColor"
						/><path
							d="M3 14H11V16H3V14Z"
							fill="currentColor"
						/><path
							d="M11.9905 15.025L13.4049 13.6106L15.526 15.7321L19.7687 11.4895L21.1829 12.9037L15.526 18.5606L11.9905 15.025Z"
							fill="currentColor"
						/></svg>
					</button>

					<button
						v-tippy="helpers?.getTippy('Adicionar anexo', { placement: 'left' })"
						class="bg-white mt-3 p-1.5 rounded-full shadow-md"

						@click="buttonAddAttachmentClick"
					>
						<svg
							class="h-6 opacity-75 w-6"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						><path
							d="M14 0C16.7614 0 19 2.23858 19 5V17C19 20.866 15.866 24 12 24C8.13401 24 5 20.866 5 17V9H7V17C7 19.7614 9.23858 22 12 22C14.7614 22 17 19.7614 17 17V5C17 3.34315 15.6569 2 14 2C12.3431 2 11 3.34315 11 5V17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17V6H15V17C15 18.6569 13.6569 20 12 20C10.3431 20 9 18.6569 9 17V5C9 2.23858 11.2386 0 14 0Z"
							fill="currentColor"
						/></svg>
					</button>

					<button
						v-tippy="helpers?.getTippy('Adicionar cálculo (em breve)', { placement: 'left' })"
						class="bg-white mt-3 opacity-25 p-1.5 rounded-full shadow-md"

						@click="buttonAddCalcClick"
					>
						<svg
							class="h-6 opacity-75 w-6"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						><path
							d="M17 5H7V7H17V5Z"
							fill="currentColor"
						/><path
							d="M7 9H9V11H7V9Z"
							fill="currentColor"
						/><path
							d="M9 13H7V15H9V13Z"
							fill="currentColor"
						/><path
							d="M7 17H9V19H7V17Z"
							fill="currentColor"
						/><path
							d="M13 9H11V11H13V9Z"
							fill="currentColor"
						/><path
							d="M11 13H13V15H11V13Z"
							fill="currentColor"
						/><path
							d="M13 17H11V19H13V17Z"
							fill="currentColor"
						/><path
							d="M15 9H17V11H15V9Z"
							fill="currentColor"
						/><path
							d="M17 13H15V19H17V13Z"
							fill="currentColor"
						/><path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M3 3C3 1.89543 3.89543 1 5 1H19C20.1046 1 21 1.89543 21 3V21C21 22.1046 20.1046 23 19 23H5C3.89543 23 3 22.1046 3 21V3ZM5 3H19V21H5V3Z"
							fill="currentColor"
						/></svg>
					</button>

					<div
						class="font-semibold mt-[80px] opacity-25 -rotate-90 text-base shortcuts-label"
					>
						ADICIONAR
					</div>

				</div>
			</div>
		</template>

		<ModalTask
			ref="modalTask"

			:back-route="{name: 'legal-case-details', params: { id: urlParamId }}"
			:task-id="taskToEditId"
			:tasks-list="openTasksFiltered"

			@task-created="modalTask_taskCreated"
			@task-updated="modalTask_taskUpdated"
			@close-modal="resetTaskToEdit"
			@open-change-to-client-modal="(clientId: string | number) =>
				toggleConfirmChangeToClientModal(true, clientId)
			"
		/>

		<ModalBasic
			ref="modalChangeToClient"
			:dismissable="!requesting.createClientFromLead"
		>
			<template #content>
				<div class="p-4 flex flex-col">
					<p class="font-bold text-lg mb-2">
						Um dos contatos adicionados é um Lead.
					</p>
					<p class="mb-1">
						Adicionar uma tarefa irá transformar esse lead em um cliente e também estará disponível para fazer cálculos.
					</p>
				</div>
			</template>
			<template #footer>
				<div class="flex-1 flex overflow-hidden rounded-b-md">
					<button
						class="flex-1 bg-gray-200 font-medium p-4 text-gray-500 hover:bg-gray-300 active:bg-gray-200"
						@click="toggleConfirmChangeToClientModal(false)"
					>
						CANCELAR
					</button>
					<button
						class="flex-1 bg-[#3F87F5] font-medium p-4 text-white hover:bg-blue-400 active:bg-[#3F87F5]"
						@click="callCreateClientFromLead"
					>
						CONCORDO E DESEJO CONTINUAR
					</button>
				</div>
			</template>
		</ModalBasic>
		<ReceivedEmailModal
			v-if="emailToDisplay"
			ref="receivedEmailModal"
			:email="emailToDisplay"
		/>
	</section>
</template>
