import { DateTime } from 'luxon';
import { TinyEmitter } from 'tiny-emitter';
import helpers from '../helpers';
import { OutputData } from '@editorjs/editorjs';
import lodash from 'lodash';

// Basics

export type BasicObject = {
  name: string;
};

export type PersonWithId = BasicObject & {
  readonly id: number;
};

export type OfficeWithId = BasicObject & {
  readonly id: number;
};

export type TinyAssignee = PersonWithId & {
  avatar_url: string;
  preferences?: Record<string, unknown>;
};

export type Assignee = TinyAssignee &
  User & {
    active: boolean;
    account_type: string;
    billing_admin: boolean;
    birth_date?: string;
    email?: string;
    gender?: string;
    username?: string;
    viewed_welcome_video?: boolean;
    receives_lead_notifications?: boolean;
    last_access_at?: string;
    last_sign_in_at?: string;
    created_at: string;
    updated_at: string;
  };

export type AxiosErrorResponse = {
  data: {
    errors: {
      [key: string]: string[];
    };
  };
  status?: number;
};

// Account
export type AccountResponse = {
  user: User;
  office: Office;
  users: User[];
  notifications: Notification[];
  next_plan: Plan;
};

export type Notification = {
  id: number | string;
  message: string;
  read: boolean;
  url?: string;
};

export type Plan = {
  id: number | string;
  level?: string;
  max_calculations: number;
  max_clients: number;
  max_users: number;
  name: string;
  periodicity: string;
  reference?: string;
  value?: string;
  value_metric_count?: string;
};

export type CheckoutOptions = {
  completed: boolean;
  discount_token?: string;
  is_new_checkout: boolean;
  plan_id?: number;
  referral_link?: string;
  show_unfinished_warning: boolean;
  user_cpf?: string;
  user_phone?: string;
};

export type Office = {
  readonly id: number;
  readonly vindi_customer_id: number;
  accepted_referral_terms_of_usage: boolean;
  active_users_count: number;
  checkout_options: CheckoutOptions;
  has_scheduled_account_cancellation: boolean;
  has_scheduled_plan_change: boolean;
  finances_seeded: boolean;
  lead_cnis_mandatory: boolean;
  lead_cpf_mandatory: boolean;
  lead_email_mandatory: boolean;
  lead_form_url: string;
  lead_phone_mandatory: boolean;
  name: string;
  outstanding_since?: string;
  overdue_subscription_forced_cancellation: boolean;
  payment_status: string;
  reports_logo_url: string;
  welcome_survey_submitted: boolean;
  created_at: string;
  updated_at?: string;
};

export type PlanResponse = {
  office: Office;
  plan: Plan;
};

// Attachments

export type File = {
  readonly id: string | number;
  filename: string;
  fileSize: string | number;
  fileType: string;
  fileExtension: string;
};

export type LegalCaseAttachment = Attachment & {
  readonly legal_case_id: number;
  client_id?: number;
};

export type Attachment = {
  readonly id: number;
  description?: string;
  name: string;
  url: string;
  created_at: string;
  creator_name?: string;
  updated_at?: string;
};

export type Cnis = {
  readonly id: number;
  client_id: number;
  content?: string;
  creator: PersonWithId;
  editor?: PersonWithId;
  filename?: string;
  name: string;
  office_id: number;
  updated_at?: string;
  created_at: string;
}

export type ConcessionLetter = {
  readonly id: number;
  client_id: number;
  content?: string;
  creator: PersonWithId;
  editor?: PersonWithId;
  filename?: string;
  name: string;
  office_id: number;
  updated_at?: string;
  created_at: string;
  parser_version: number;
  type: string;
  client_name?: string;
  cpf?: string;
  nit?: string;
  benefit_number?: string;
  request_date?: string;
  concession_date?: string;
  dib?: string;
  benefit_name?: string;
  has_secondary_rmi?: boolean;
  can_apply_rules_pre_ec103?: boolean;
  is_acquired_right?: boolean;
  rmi?: string;
  previdenciary_factor?: string;
  life_expectation?: string;
  age?: string;
  contribution_time?: string;
  benefit_salary?: string;
  salaries_average?: string;
  coeficient?: string;
  doc_date?: string;
  doc_code?: string;
  death_pension_data?: string;
}

// Received Emails

export type ReceivedEmail = {
  readonly id: number;
  client_name: string;
  sender: string;
  subject: string;
  body: string;
  created_at: string;
};

// Legal Case

export type LegalCaseNote = {
  readonly id: number;
  legal_case_id: number;
  content?: string;
  editorjs_object?: OutputData;
  creator_id: number;
  editor_id?: number;
  created_at: string;
  updated_at: string;
};

type ChangeSetEntries =
  | 'Nome'
  | 'Título'
  | 'Número do caso'
  | 'Ativo?'
  | 'Tipo'
  | 'Descrição'
  | 'Criação'
  | 'Atualização'
  | 'Criador'
  | 'Editor'
  | 'Escritório';

export type VersionChangeSet = Record<ChangeSetEntries, [string, string]>;

export type LegalCaseVersion = {
  readonly id: number;
  timestamp: number;
  user: string | number;
  event: string;
  changeset: VersionChangeSet;
};

export type LegalCaseToSend = {
  assignee_ids?: number[] | string[];
  case_number?: string;
  case_type: string;
  contact_ids?: number[] | string[];
  description?: string;
  funnel_step_ids?: number[] | string[];
  is_active: boolean;
  name: string;
  user_id: string | number;
};

export type LegalCase = LegalCaseToSend & {
  readonly id: number;
  case_notes?: LegalCaseNote[];
  assignees?: Assignee[];
  attachments?: LegalCaseAttachment[];
  contacts?: Contact[];
  funnel?: Funnel;
  funnel_steps?: FunnelStep[];
  tasks?: Task[];
  pending_tasks?: number;
  office_id: number;
  versions?: LegalCaseVersion[];
  created_at: string;
  updated_at?: string;
};

export type LegalCasePaginationResponse = PaginationResponse & {
  legal_cases: LegalCase[];
};

export type FiltersButtonsGroupItem = {
  label: string;
  value: string;
};

export type FilterButtonsGroupSingle = {
  items: FiltersButtonsGroupItem[];
  model: string;
};

export type FiltersButtonsGroup = {
  planned: FilterButtonsGroupSingle;
  events: FilterButtonsGroupSingle;
};

// Funnels

export type FunnelStepIntermediate = {
  funnel_id?: number;
  name: string;
  office_id?: number;
  order?: number;
  id?: number;
};

export type FunnelStepToSend = FunnelStepIntermediate & {
  funnel_id: number;
  name: string;
  office_id?: number;
  order?: number;
  id?: number;
};

export type FunnelStep = FunnelStepToSend & {
  readonly id: number;
  legal_cases?: LegalCase[];
  office?: OfficeWithId;
  created_at?: string;
  updated_at?: string;
  order: number;
};

export type FunnelToSend = {
  description?: string;
  is_main: boolean;
  name: string;
};

export type Funnel = FunnelToSend & {
  readonly id: number;
  active_cases_count: number;
  creator: PersonWithId;
  funnel_steps: FunnelStep[];
  office: OfficeWithId;
  office_id: number;
  created_at: string;
  updated_at: string;
};

export type FunnelsPaginationResponse = PaginationResponse & {
  funnels: Funnel[];
};

export type FunnelSubstituteOptions = {
  funnelId: number | string;
  stepId: number | string;
};

// Notes

export type ContactNote = {
  readonly id: number;
  client_id: number;
  content?: string;
  editorjs_object?: OutputData;
  creator: BasicObject;
  creator_id: number;
  editor_id?: number;
  created_at: string;
  updated_at: string;
};

// Contacts

export const enum ContactType {
  // eslint-disable-next-line no-unused-vars
  CLIENT = 'Client',
  // eslint-disable-next-line no-unused-vars
  LEAD = 'Lead',
}

export const enum EntityType {
  // eslint-disable-next-line no-unused-vars
  PERSON = 'person',
  // eslint-disable-next-line no-unused-vars
  COMPANY = 'company',
}

export const enum Gender {
  // eslint-disable-next-line no-unused-vars
  MALE = 'male',
  // eslint-disable-next-line no-unused-vars
  FEMALE = 'female',
  // eslint-disable-next-line no-unused-vars
  NOT_DEFINED = '',
}

export const enum MaritalStatus {
    // eslint-disable-next-line no-unused-vars
    SINGLE = 'single',
    // eslint-disable-next-line no-unused-vars
    STABLE_UNION = 'stable_union',
    // eslint-disable-next-line no-unused-vars
    MARRIED = 'married',
    // eslint-disable-next-line no-unused-vars
    DIVORCED = 'divorced',
    // eslint-disable-next-line no-unused-vars
    SEPARATED = 'separated',
    // eslint-disable-next-line no-unused-vars
    WIDOWED = 'widowed',
    // eslint-disable-next-line no-unused-vars
    NOT_INFORMED = 'not_informed',
}

export type Address = {
  city: string;
  district: string;
  extra_info: string;
  number: string | number;
  state: string;
  street: string;
  zip_code: string;
};

export type AddressResponse = {
  cep?: string;
  logradouro: string;
  complemento?: string;
  bairro: string;
  localidade: string;
  uf: string;
};

export type Email = {
  is_main: boolean;
  email: string;
  decription?: string;
};

export type Phone = {
  is_main: boolean;
  area_code: string;
  number: string;
  description?: string;
};

export type ContactToSend = {
  address?: Address;
  birth_date: string;
  name: string;
  gender: Gender;
  cpf?: string;
  nationality?: string;
  marital_status?: MaritalStatus;
  occupation?: string;
  cnpj?: string;
  corporate_name?: string;
  business_sector?: string;
  responsible_party_id?: string | number;
  tax_residence?: {state: string; city: string}
  emails?: Email[];
  entity_type: EntityType;
  phones?: Phone[];
  rg?: string;
};

export type Contact = ContactToSend & {
  readonly id: number;
  hash_id: string;
  email?: string;
  lead_note?: string;
  notes: ContactNote[];
  office: OfficeWithId;
  office_id: number;
  phone?: string;
  phone2?: string;
  referrer?: string;
  slug?: string;
  type: ContactType;
  created_at: string;
  updated_at: string;
  attachments?: Attachment[];
  calculations?: Calculation[];
  received_emails?: ReceivedEmail[];
  legal_cases: LegalCase[];
  cnis?: Cnis;
  concession_letter?: ConcessionLetter;
  responsible_party_name?: string;
};

export type ContactPaginationResponse = PaginationResponse & {
  contacts: Contact[];
};

export type Client = {
  readonly id: number;
  name: string;
  birth_date: string;
  cnpj?: string;
  cpf?: string;
  readonly creator_id: number;
  editor_id: number;
  email?: string;
  entity_type: EntityType;
  gender: Gender;
  phone?: string;
  rg?: string;
  slug?: string;
  nationality: string;
  marital_status: MaritalStatus;
  occupation: '';
  updated_at: string;
  readonly created_at: string;
};

export type Facets = {
  creator_id: [number, number][];
};

export type ClientsMetadata = {
  facets: Facets;
  leads_count: number;
  page_number: number;
  page_size: number;
  total_count: number;
  total_pages: number;
};

export type ClientsPreview = {
  clients: Client[];
  meta: ClientsMetadata;
};

// Calculations

export type Calculation = {
  readonly id: number | string;
  calculation_kind_human?: string;
  client_id: number | string;
  created_at: string;
  creator_id: number | string;
  deleted: boolean;
  editor_id?: number | string;
  kind_type?: string;
  name: string;
  type?: string;
  updated_at?: string;
  url: string;
};

// Tags

export type TagToSend = {
  title: string;
};

export type TinyTag = TagToSend & {
  id?: number | null;
  office_id: number;
};

export type Tag = TinyTag & {
  readonly id: number;
  office: OfficeWithId;
  creator: PersonWithId;
  created_at: string;
  updated_at?: string;
};

export type TagPaginationResponse = PaginationResponse & {
  tags: Tag[];
};

// Tasks

export type TaskToSend = {
  assignee_ids?: string[] | number[];
  contact_ids?: string[] | number[];
  description?: string;
  done?: boolean;
  done_at?: string;
  due_at?: string;
  legal_case_id: string | number;
  office_id: string | number;
  tag_ids?: string[] | number[];
  title: string;
};

export type Task = TaskToSend & {
  readonly id: number;
  done: boolean;
  creator?: PersonWithId;
  editor?: PersonWithId;
  legal_case?: LegalCase;
  office?: OfficeWithId;
  assignees: TinyAssignee[];
  contacts?: Contact[];
  tags: Tag[];
  created_at: string;
  updated_at?: string;
};

export type TaskPaginationResponse = PaginationResponse & {
  tasks: Task[];
};

// DataTable

export type DataTableColumn = {
  key: string;
  label: string;
  initial?: boolean;
  required?: boolean;
};

// Input

export type MaskaValues = {
  masked: string;
  unmasked: string;
  completed: boolean;
};

// Filters

export type FilterItem<T> = {
  label: string;
  tooltip?: string;
  value: T;
};

export type FilterLabels = Record<string, string>;

export type SingleComplexFilter = Record<
  string,
  string | string[] | FilterItem<string | boolean>[]
>;

export type Filters = {
  model: SingleComplexFilter;
  applied: SingleComplexFilter;
  labels?: FilterLabels;
  query: string;
};

// History Items

export type AttachmentHistoryItem = LegalCaseAttachment & {
  title: string;
  date_time: string;
  type: string;
};

export type VersionHistoryItem = LegalCaseVersion & {
  title: string;
  date_time: string;
  type: string;
};

export type CaseNoteHistoryItem = LegalCaseNote & {
  title: string;
  date_time: string;
  type: string;
};

export type TaskHistoryItem = Task & {
  title: string;
  date_time: string;
  type: string;
};

export type EventHistoryItem =
  AttachmentHistoryItem
  | VersionHistoryItem
  | CaseNoteHistoryItem
  | TaskHistoryItem;

// Validation
export type Error = {
  label: string;
  message: string;
};

export type Errors = Record<string | number, Error>;

export type ValidationResult = {
  isValid: boolean;
  errors: Errors;
};

// Search

// eslint-disable-next-line no-unused-vars
export enum ResultList {
  // eslint-disable-next-line no-unused-vars
  CONTACTS = 'contact',
  // eslint-disable-next-line no-unused-vars
  LEGAL_CASES = 'legalCase',
}

export type Result = ContactResult | LegalCaseResult;

export type ContactResult = {
  name: string;
  id: number;
  cpf?: string;
  type: ContactType;
  emails?: string[];
  slug?: string;
};

export type LegalCaseResult = {
  name: string;
  id: number;
  case_number?: string;
  case_type: string;
  description?: string;
};

export type SearchResult = {
  contact: ContactResult[];
  legalCase: LegalCaseResult[];
};

export type SearchResponse = {
  contacts: ContactResult[];
  legal_cases: LegalCaseResult[];
};

// Details View

export const enum DataType {
	CONTACT = 'contact',
	TASK = 'task',
	LEGAL_CASE = 'legal_case',
}

export const enum Tab {
	HISTORY = 'history',
	TASKS = 'tasks',
	LEGAL_CASES = 'legal_cases',
	NOTES = 'notes',
	CALCULATIONS = 'calculations',
	CJMAIL = 'cjmail',
	ATTACHMENTS = 'attachments',
	FINANCE = 'finance',
}

export type BreadCrumbItem = {
  label: string;
  to?: string;
};

export type SingleValue = {
  name: string;
  id?: number | string;
  type?: string;
}

export type InfoItem = {
  label: string;
  value: string | SingleValue[];
  isHtml: boolean;
};

// Toast

export type Toast = {
  content?: string;
  showCloseButton?: boolean;
  type?: string;
  uuid?: string;
  autoHide?: boolean;
  autoHideDelay?: number;
};

// Dashboard Counts

export type Counts = {
  active_legal_cases_count: number;
  active_tasks_count: number;
  contacts_count: number;
  funnels_count: number;
};

// Requisitions

export type PaginationResponse = {
  page_number: number;
  per_page: number;
  total_count: number;
};

// User

export type SinglePreference = Record<
  string,
  string | string[] | number | boolean
>;

export type UserPreferences = Record<string | number, SinglePreference>;

export type User = UserPreferences & {
  readonly id: number | string;
  office_id?: number | string;
  account_type: string;
  avatar_url?: string;
  billing_admin: boolean;
  birth_date?: string;
  calculation_metadata_read?: unknown[];
  calculations_table_page_size?: number;
  clients_table_fields?: string[];
  clients_table_page_size?: number;
  created_at: string;
  email: string;
  gender?: string;
  intercom_user_hash?: string;
  name: string;
  sign_in_count?: number;
  updated_at?: string;
  username?: string;
  viewed_welcome_video?: boolean;
  preferences?: UserPreferences;
};

export type CRMPreferencesToSend = {
  user_preferences: {
    with_tasks_notifications: boolean;
  };
};

export type CRMPreferences = CRMPreferencesToSend & {
  id: number;
  office_id: number;
};

// Editor JS

export type EditorJSOutput = {
  html: string;
  raw: OutputData;
};

// For modules without standard typing
export type EventBus = TinyEmitter;

export type Helpers = typeof helpers;

export type Luxon = typeof DateTime;

export type Lodash = typeof lodash;
