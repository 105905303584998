import {
	AddressResponse, Counts
} from './../../../models/crm';
import axios, { AxiosResponse } from 'axios';

export default {

	async getAddressByZipCode(zipCode: string): Promise<AxiosResponse<AddressResponse>> {
		const url = `https://viacep.com.br/ws/${zipCode}/json/`;

		return await axios.get(url, {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			transformRequest: [ function(data, headers: any): any {
				if (headers?.common) {
					delete headers.common['Authorization'];
					delete headers.common['X-CJ-Office-Id'];
				}

				return data;
			} ],
		});
	},

	async getDashboardCounts(officeId: string | number): Promise<AxiosResponse<Counts>> {
		const path = `/offices/${officeId}/crm_counts.json`;

		return await axios.get(`${path}`);
	},

};
