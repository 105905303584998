<script lang="ts">
import {
	defineComponent, PropType
} from 'vue';

type Option = {
	value: string | number;
	label: string;
	disabled?: boolean;
}

export default defineComponent({
	props: {
		disabled: {
			default: false,
			type: Boolean,
		},
		label: {
			type: String,
		},
		modelValue: [ String, Number, Object, Boolean ],
		options: {
			type: Array as PropType<Option[]>,
			required: true,
		},
		required: {
			default: false,
			type: Boolean,
		},
	},
	emits: [
		'update:modelValue',
	],
	methods: {
		checkAndSetDefaultOption(): void {
			const hasOptions = !!this.$el.querySelector('select').options.length;
			if (!this.modelValue && hasOptions) {
				this.$el.querySelector('select').options[0].selected = 'selected';

				this.$emit('update:modelValue', this.options[0]?.value);
			}
		},

		openSelect(): void {
			(this.$refs.selectTag as HTMLSelectElement).focus();
		},

		// Events

		selectChange(e: Event): void {
			this.$emit('update:modelValue', (<HTMLInputElement>e.currentTarget).value);
		},
	},
	mounted() {
		this.checkAndSetDefaultOption();
	},
	updated() {
		this.checkAndSetDefaultOption();
	},
});
</script>
<template>
	<div class="component-form-control-select border border-[#DCDDDF] rounded relative"
		@click="openSelect"
	>
		<label :for="`formControlSelect_${label}`"
			class="block font-semibold text-xs absolute left-[8px] pointer-events-none right-0 flex"
			:class="[disabled? 'text-gray-800': 'text-gray-500',
				!label ? 'items-end justify-end top-[calc(50%-4px)]' : 'items-center justify-between top-[8px]'
			]"
		>
			<div v-if="!!label"
				class="m-0 p-0"
			>
				<span>{{ label }}</span>
				<span v-if="required"
					class="ml-1 text-red-600"
				>*</span>
			</div>
			<svg xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 14 8"
				height="8"
				width="11"
				:class="label?.length ? 'mr-5' : 'mr-3'"
			>
				<path d="M1 1l6 6 6-6"
					fill="none"
					stroke="currentColor"
					stroke-width="2.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</svg>
		</label>
		<select
			ref="selectTag"
			class="bg-white font-semibold text-[12px] outline-none px-2 rounded w-full appearance-none"
			:class="[
				disabled && 'bg-[rgb(235,235,228)] text-gray-900',
				!label ? 'py-2' : 'pb-1 pt-7'
			]"
			:disabled="disabled"
			:id="`formControlSelect_${label}`"
			:value="modelValue"
			@change="selectChange"
		>
			<slot v-if="$slots.default" />
			<template v-else>
				<option
					v-for="option in options"
					:key="option.value"
					:disabled="option.disabled"
					:value="option.value"
					class="text-base"
				>
					{{ option.label }}
				</option>
			</template>
		</select>
	</div>
</template>
<style lang="scss" scoped>
.component-form-control-select {

    &.disabled {
        opacity: 0.5;
    }

    &.validation-error {
        select {
            border: 1px solid;
            border-color: #dc3545;
        }
    }
}
</style>
