
<script lang="ts">
import {
	defineComponent, inject
} from 'vue';
import { DateTime } from 'luxon';

import {
	EventBus, Helpers, Lodash, Luxon
} from '../../../../models/crm';
import {
	ReceivedEntryBlock
} from '../../../../models/financial';

import Chart from '../../../../components/Chart/ChartComponent.vue';
import DatePicker from '../../../../components/Form/DatePicker.vue';
import SelectField from '../../../../components/Form/SelectField.vue';

import serviceExpenses from '../../../../services/API/financial/expenses';
import serviceIncomes from '../../../../services/API/financial/incomes';
import { ChartData } from 'chart.js';

export default defineComponent({
	components: {
		Chart,
		DatePicker,
		SelectField,
	},
	data() {
		return {

			eventBus: inject<EventBus>('eventBus'),
			helpers: inject<Helpers>('helpers'),
			lodash: inject<Lodash>('lodash'),
			luxon: inject<Luxon>('luxon'),

			filter: {
				model: {
					date: '',
					paid: 1,
				},
				query: ''
			},

			selectPaidOptionsList: [
				{
					label: 'Realizadas', value: 1
				},
				{
					label: 'Previstas', value: -1
				},
				{
					label: 'Todas', value: 0
				},
			],

			incomes: [] as ReceivedEntryBlock[],
			expenses: [] as ReceivedEntryBlock[],

			incomesNum: [] as number[],
			expensesNegative: [] as number[],

			chartData: {
				data: {
					labels: [] as number[],
					datasets: [
						{
							data: [] as number[],
							backgroundColor: [
								'rgba(0, 204, 51, 0.7)',
							],
							label: 'Entradas',
							datalabels: {
								color: 'rgb(20,20,20)',
								anchor: 'end',
								align: 'end',
								offset: 1,
							},
						},
						{
							data: [] as number[],
							backgroundColor: [
								'rgba(255, 51, 51, 0.7)',
							],
							label: 'Saídas',
							datalabels: {
								color: 'rgb(20,20,20)',
								anchor: 'start',
								align: 'start',
								offset: 1,
							},
						},
					],
				} as unknown as ChartData<'bar'>,
				options: {
					animation: {
						duration: 0,
					},
					maintainAspectRatio: false,
					plugins: {
						legend: {
							labels: {
								font: {
									size: 14,
									weight: 'bold',
								},
							},
						},
					},
					responsive: true,
					scales: {
						x: {
							stacked: true,
							border: {
								display: false,
							},
							grid: {
								drawBorder: false,
								// eslint-disable-next-line @typescript-eslint/no-explicit-any
								color: (_context: any): string => {
									return 'rgba(240,240,240)';
								},
							},
							title: {
								display: true,
								text: 'Dias',
								font: {
									size: 14,
									weight: 'bold',
								},
							},
						},
						y: {
							beginAtZero: true,
							ticks: {
								display: false,
								beginAtZero: true,
							},
							grid: {
								drawBorder: false,
								// eslint-disable-next-line @typescript-eslint/no-explicit-any
								color: (context: any): string => {
									if (context.tick.value === 0) {
										return 'rgba(160,160,160)';
									}

									return 'rgba(240,240,240,0)';
								},
							},
						}
					},
				},
			},

			show: true,

			requesting: {
				loadEntries: false,
			},

		};
	},
	watch: {
		async filterDate(
			newValue?: DateTime,
			oldValue?: DateTime
		): Promise<void> {
			if (!newValue?.month
				|| (oldValue && isNaN(oldValue?.month))
				|| newValue.month === oldValue?.month
			) return;

			await this.filtersApply();
		},

		async 'filter.model.paid'(newValue, oldValue): Promise<void> {
			if (newValue !== oldValue) {
				await this.filtersApply();
			}
		},
	},
	computed: {
		filterDate(): DateTime | undefined {
			return this.luxon?.fromISO(this.filter.model.date);
		},

		hasData(): boolean {
			return this.incomes.length > 0 || this.expensesNegative.length > 0;
		},
	},
	methods: {
		setInitialFilters(): void {
			this.filter.model.date = this.luxon?.now().toISO() || new Date().toISOString();
		},

		// Events

		buttonTitleClick(): void {
			this.show = !this.show;
		},

		async loadEntries(): Promise<void> {
			try {
				this.requesting.loadEntries = true;

				const {data: expensesData} = await serviceExpenses.getAll(
					9999,
					1,
					'description:asc',
					this.filter.query,
				);

				this.expenses = expensesData.expenses;

				const {data: incomesData} = await serviceIncomes.getAll(
					9999,
					1,
					'description:asc',
					this.filter.query,
				);

				this.incomes = incomesData.incomes;

				this.incomesNum = this.incomes
					.filter(item => {
						const dueDateMonth = this.luxon?.fromISO(item.due_date).month;
						const filterDateMonth = this.filterDate?.month;

						if (!filterDateMonth || !dueDateMonth) return false;

						return dueDateMonth === filterDateMonth;
					})
					.map(item => item.amount);

				this.expensesNegative = this.expenses
					.filter(item => {
						const dueDateMonth = this.luxon?.fromISO(item.due_date).month;
						const filterDateMonth = this.filterDate?.month;

						if (!filterDateMonth || !dueDateMonth) return false;

						return dueDateMonth === filterDateMonth;
					})
					.map(item => item.amount * -1);

				const currentMonthNumOfDays = this.filterDate?.daysInMonth;

				const arrDays = (currentMonthNumOfDays
					&& Array.from({ length: currentMonthNumOfDays }, (_item, index) => index + 1)
				) || [];

				const newChartData = { ...this.chartData };

				newChartData.data.labels = arrDays;

				if (newChartData.data.datasets[0] && newChartData.data.datasets[1]) {
					newChartData.data.datasets[0].data = this.incomesNum;
					newChartData.data.datasets[1].data = this.expensesNegative;
				}

				this.chartData = this.lodash?.cloneDeep(newChartData) || {...newChartData};
			} catch (err) {
				console.error(err);

				this.eventBus?.emit('Toast/add', {
					content: 'Erro ao carregar entradas/saídas',
					type: 'error'
				});
			} finally {
				this.requesting.loadEntries = false;
			}
		},

		async filtersApply(): Promise<void> {
			const firstDay = this.filterDate?.startOf('month').toISODate();
			const lastDay = this.filterDate?.endOf('month').toISODate();

			const paidFilterNum = Number(this.filter.model.paid);

			const paidFilter = paidFilterNum === 1
				? '&paid=true'
				: (paidFilterNum === -1
					? '&unpaid=true'
					: ''
				);

			this.filter.query = `&due_at_from=${firstDay}&due_at_to=${lastDay}${paidFilter}`;

			await this.loadEntries();
		}

	},
	async beforeMount() {
		this.setInitialFilters();
		await this.filtersApply();
	},
});
</script>

<template>
	<button
		:class="['bg-blue-500/10 block border border-blue-500 flex font-medium items-center justify-between mt-5 p-3 rounded-tl rounded-tr w-full', { 'rounded': !show }]"

		@click="buttonTitleClick"
	>
		<div class="flex items-center gap-[24px]">
			<span class="font-semibold text-blue-700">Gráfico - Entradas x Saídas por mês</span>
			<SelectField
				v-model="filter.model.paid"
				:options="selectPaidOptionsList"
				required
				class="min-w-[120px] border-blue-500"

				@click.stop=""
			/>
		</div>
		<span :class="{ 'rotate-180': show }">
			<svg class="fill-blue-700 h-6 w-6"
				fill="currentColor"
				viewBox="0 0 20 20"
				xmlns="http://www.w3.org/2000/svg"
				aria-hidden="true"
			>
				<path clip-rule="evenodd"
					fill-rule="evenodd"
					d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
				/>
			</svg>
		</span>
	</button>
	<div
		v-if="show"
		class="border-b border-l border-r border-blue-500 mb-5 rounded-bl rounded-br"
	>
		<div class="bg-white/50 flex items-center justify-center p-3 rounded-tl rounded-tr">

			<div class=" flex items-center">
				<div class="font-semibold text-gray-500 text-xs">Mês/Ano</div>
				<div class="ml-3 w-60">
					<DatePicker
						v-model="filter.model.date"

						:close-on-auto-apply="true"
						:enable-time-picker="false"
						format="MMM/yyyy"
						:keep-action-row="false"
						:month-picker="true"
						:clearable="false"
					/>
				</div>
			</div>

		</div>
		<div class="bg-white rounded-bl rounded-br p-6">
			<div v-if="requesting.loadEntries"
				class="flex items-center justify-center h-96"
			>
				<span class="text-lg text-primary">Carregando...</span>
			</div>
			<div v-else-if="!hasData"
				class="flex items-center justify-center h-96"
			>
				<span class="font-thin text-xl">Não há dados para o mês selecionado</span>
			</div>
			<div v-else
				class="h-96"
			>
				<Chart :data="chartData.data"
					:options="chartData.options"
				/>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
:deep(.form-control-datepicker) {
    input {
        text-transform: capitalize;
    }
}
</style>