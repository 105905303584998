import { PlanResponse } from '../models/crm';
import axios, { AxiosResponse } from 'axios';

export default {

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	async getPlan(officeId: string | number): Promise<AxiosResponse<PlanResponse, any>> {
		const path = `/offices/${officeId}?include=plan`;

		return await axios.get(path);
	},

	async getAllPlans(officeId: string | number): Promise<AxiosResponse> {
		const path = `/offices/${officeId}?include=plans`;

		return await axios.get(path);
	},

	async changePlan(newPlanId: string | number): Promise<AxiosResponse> {
		const path = '/plan_changes';

		return await axios.post(path, {
			plan_change: {
				new_plan_id: newPlanId,
				proceed_with_automatic_reactivation: true
			}
		});
	},

};