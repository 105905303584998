/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosResponse } from 'axios';
import {
	TagToSend, Tag, TagPaginationResponse
} from '../../../models/crm';
const pageParams = '&per_page=999999&page=1';

export default {

	create(data: TagToSend): Promise<AxiosResponse<Tag, any>> {
		const path = '/tags.json';

		return axios.post(`${path}`, data);
	},

	getAll(): Promise<AxiosResponse<TagPaginationResponse, any>> {
		const path = `/tags.json?${pageParams}`;

		return axios.get(`${path}`);
	},

	searchByTag({ tag }: {
		tag: string
	}): Promise<AxiosResponse<TagPaginationResponse, any>> {
		const path = `/tags.json?search=${tag}${pageParams}`;

		return axios.get(`${path}`);
	},

};