import {
	Attachment,
	File,
	PaginationResponse
} from './crm';

// ENTRY BLOCK

export type EntryBlockModel = {
    amount: string;
    category: ReceivedObject[];
    contact: ReceivedObject[];
    costCenter: ReceivedObject[];
    description: string;
    dueDate: string;
    legalCase: ReceivedObject[];
    note: string;
    paymentDate: string;
    attachments: {
        files: FinancialAttachment[];
    };
};

export type EntryBlock = {
    model: EntryBlockModel;
    uuid: string;
}

export type RepeatModel = {
    model: EntryBlockModel;
    months: number;
}

export type EntryBlockToSend = {
    amount: number;
    contact_id?: number;
    cost_center_id?: number;
    description: string;
    due_date: string;
    financial_category_id?: number;
    legal_case_id?: number;
    payment_date?: string;
    note?: string;
    attachments_attributes?: EntryBlockFile[];
};

type ReceivedObject = {
    id: number;
    name: string;
}

export type NextTransaction = {
    readonly id: number;
    amount: number;
    due_date: string;
    description: string;
}

export type ReceivedEntryBlock = NextTransaction & {
    contact_id?: number;
    contact?: ReceivedObject;
    cost_center_id?: number;
    cost_center?: ReceivedObject;
    income_category?: ReceivedObject;
    expense_category?: ReceivedObject;
    financial_category_id?: number;
    legal_case_id?: number;
    legal_case?: ReceivedObject;
    payment_date?: string;
    note: string;
    office_id: number;
    office: ReceivedObject;
    created_at: string;
    creator: ReceivedObject;
    updated_at?: string;
    editor?: ReceivedObject;
    attachments?: { attachment: Attachment }[]
};

export const enum EntryBlockType {
    // eslint-disable-next-line no-unused-vars
    INCOMES = 'incomes',
    // eslint-disable-next-line no-unused-vars
    EXPENSES = 'expenses',
}

export type AllEntryBlocksResponse = Record<EntryBlockType, ReceivedEntryBlock[]>;

export type ReceivedEntryBlockPaginationResponse =
    PaginationResponse
        & AllEntryBlocksResponse
        & { next_transactions: NextTransaction[] };

export type ExpensesInData = {
    loadedExpensesRaw: ReceivedEntryBlock[];
    loadedIncomesRaw: ReceivedEntryBlock[];
    loadedExpensesWithPaymentDate: ReceivedEntryBlock[];
    loadedIncomesWithPaymentDate: ReceivedEntryBlock[];
}

export type EntryBlockData = {
    incomes?: EntryBlockToSend[];
    expenses?: EntryBlockToSend[];
};


// ATTACHMENT

export type FinancialAttachment = File & {
    file: string | Blob;
    filename: string;
}

export type EntryBlockFile = {
    description: string;
    file: string | Blob;
    name: string;
};

// INCOME CATEGORY

export type Category = {
  readonly id: number;
  readonly office_id: number;
  name: string;
  description?: string;
  created_at: string;
  updated_at?: string;
  office: {
    readonly id: number;
    name: string;
  };
  creator?: {
    readonly id: number;
    name: string;
  };
  editor?: {
    readonly id: number;
    name: string;
  };
}

export type CategoriesResponse = PaginationResponse & Record<'income_categories'|'expense_categories', Category[]>;

export type CategoryToSend = {
    name: string;
    description: string;
}

// COST CENTER

export type CostCenter = Category;


export type CostCenterResponse = PaginationResponse & {
    cost_centers: CostCenter[];
};

export type CostCenterToSend = {
    name: string;
    description: string;
}
// DATA CONSOLIDATED

export type SingleDataConsolidated = {
    categories: CategoriesResult[];
    cost_centers: CostCentersResult[];
};

export type DataConsolidated = Record<string, SingleDataConsolidated>;

// DASHBOARD

export type DashboardSumParams = {
    yearmonth?: string;
    transactionType?: 'categories' | 'cost_centers';
}

export type DashboardAmounts = {
    expenses_sum: number;
    incomes_sum: number;
    incomes_count: number;
    expenses_count: number;
}

export type DashboardTransactions = {
    count: {
        Income?: number;
        Expense?: number;
    }
    amount: {
        Income?: number;
        Expense?: number;
    };
}

export type DashboardMonthsSummary = {
    transactions_due: DashboardTransactions;
    transactions_paid: DashboardTransactions;
    transactions_unpaid_due: DashboardTransactions;
    transactions_unpaid_overdue: DashboardTransactions;
}

export type Summary = {
    yearmonth: string;
    cost_center: string;
    expenses_sum: number;
    incomes_sum: number;
}

export type CostCentersResult = {
    cost_center: string;
    expenses_sum: number;
    incomes_sum: number;
}

export type CategoriesResult = {
    category: string;
    expenses_sum: number;
    incomes_sum: number;
}

export type CostCentersSumResponse = {
    yearmonth: string;
    transaction_type: 'cost_centers';
    target_date: 'due_date' | 'payment_date';
    cost_centers: CostCentersResult[];
}

export type CategoriesSumResponse = {
    yearmonth: string;
    transaction_type: 'categories';
    target_date: 'due_date' | 'payment_date';
    categories: CategoriesResult[];
}

// Filters

export type ItemFilterGroupByType = {
    label: string;
    value: 'categories' | 'cost_centers';
}

// Seed Defaults

export type SeedDefaultsResponse = {
    error?: string;
}