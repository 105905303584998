<script lang="ts">
import lodash from 'lodash';
import { DateTime as luxon } from 'luxon';

import TinyEmitter from 'tiny-emitter';

import db from './db/db';

import { defineComponent } from 'vue';

import helpers from './helpers';

import 'tippy.js/dist/tippy.css';
import ToastPopup from './components/Toast/ToastPopup.vue';

const envVars = import.meta.env;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const eventBus = new TinyEmitter();

export default defineComponent({
	provide() {
		return {
			db,
			envVars,
			eventBus,
			helpers,
			lodash,
			luxon,
		};
	},
	components: {
		ToastPopup,
	},
});
</script>
<template>
	<router-view />
	<ToastPopup />
</template>
<style lang="scss">
.feature {
    flex: 1;
    flex-direction: column;

    background-color: #efefef;
    display: flex;
    min-height: 0;
    min-width: 0;
    overflow-y: auto;
    padding: 30px 40px;

    h1 {
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 1.1;
        color: #3b424d;
    }
}

.tippy-content {
    color: #fff;
    font-weight: 400;
}
</style>