<script lang="ts">
import {
	mapState, mapActions
} from 'pinia';
import { useGlobalStore } from '../../stores/global';
import {
	defineComponent, inject
} from 'vue';

export default defineComponent({
	data() {
		return {
			envVars: inject<Record<string, string>>('envVars')
		};
	},
	props: {
		collapsed: {
			type: Boolean,
			default: false
		}
	},
	computed: {

		...mapState(useGlobalStore, [ 'sidebar' ]),

		isDevEnv(): boolean {
			return this.envVars?.VITE_APP_ENV === 'development';
		},

	},
	methods: {
		...mapActions(useGlobalStore, [ 'setSidebarShow' ]),

		buttonMobileMenuCloseClick(): void {
			this.setSidebarShow(false);
		},

	}
});
</script>

<template>
	<a class="flex"
		href="/"
		id="logo-cj"
		tabindex="0"
		:class="{ 'collapsed': collapsed }"
	>
		<svg class="symbol"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 19.66 26"
		><path d="M2.38688 8.90786V19.2986L19.6586 0.350459V26L13.338 15.0521C12.5852 14.8824 12.0378 14.2095 12.0378 13.4236C12.0378 12.5016 12.7856 11.7539 13.7074 11.7539C14.6294 11.7539 15.3771 12.5014 15.3771 13.4236C15.3771 13.5345 15.3661 13.6451 15.3443 13.7538L17.2719 17.0921V6.7015L0 25.6495V0L6.32063 10.9479C7.07343 11.1175 7.62081 11.7905 7.62081 12.5764C7.62081 13.4984 6.87308 14.2461 5.95124 14.2461C5.02911 14.2461 4.28153 13.4984 4.28153 12.5764C4.28153 12.4655 4.29256 12.3551 4.31433 12.2464L2.38688 8.90786Z"
			style="fill:#3F87F5;"
		/></svg>
		<svg v-if="isDevEnv"
			class="types homolog fill-white hover:fill-[#3F87F5]"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 111.42 26.34"
		><g><path d="M7.85,1v3.05c-.38-.42-.76-.73-1.14-.93-.38-.2-.79-.3-1.22-.3-.8,0-1.45,.27-1.95,.82-.5,.55-.74,1.26-.74,2.14,0,.82,.25,1.5,.76,2.04,.51,.54,1.15,.81,1.93,.81,.43,0,.84-.1,1.22-.3,.38-.2,.76-.51,1.14-.94v3.05c-.44,.21-.88,.38-1.32,.48-.44,.11-.88,.16-1.33,.16-.56,0-1.07-.07-1.55-.2-.47-.13-.91-.34-1.3-.61-.76-.51-1.35-1.16-1.75-1.93-.4-.78-.6-1.66-.6-2.63,0-.78,.13-1.49,.38-2.14,.25-.64,.64-1.22,1.15-1.74,.48-.5,1.03-.87,1.64-1.12,.61-.25,1.28-.37,2.03-.37,.45,0,.89,.05,1.33,.16,.44,.11,.88,.27,1.32,.48Z"
			fill="inherit"
		/><path d="M14.63,7.3c0-.55-.15-.99-.44-1.31-.29-.32-.69-.48-1.2-.48s-.91,.16-1.2,.48c-.29,.32-.44,.76-.44,1.31s.15,.98,.44,1.3c.29,.32,.69,.47,1.2,.47s.91-.16,1.2-.47c.29-.32,.44-.75,.44-1.3Zm2.19-3.52v7.06h-2.29v-1.15c-.33,.49-.69,.85-1.1,1.08-.41,.23-.89,.34-1.44,.34-.94,0-1.69-.34-2.26-1.03-.56-.69-.84-1.61-.84-2.77s.3-2.07,.89-2.75c.59-.68,1.38-1.02,2.38-1.02,.53,0,.99,.11,1.37,.34,.38,.23,.71,.57,.99,1.05v-1.16h2.3ZM15.07,.37l-2.16,2.45h-1.08L13.11,.37h1.96Z"
			fill="inherit"
		/><path d="M21.26,0h-2.49V10.84h2.49V0Z"
			fill="inherit"
		/><path d="M28.23,6.1c-.24-.21-.47-.37-.7-.47-.22-.1-.46-.15-.71-.15-.49,0-.89,.16-1.18,.49-.29,.33-.44,.77-.44,1.33s.15,.97,.46,1.29c.3,.32,.71,.48,1.22,.48,.25,0,.49-.05,.71-.15,.22-.1,.43-.26,.63-.47v2.16c-.3,.17-.6,.29-.89,.37-.3,.08-.61,.12-.92,.12-1.09,0-1.97-.35-2.64-1.05-.68-.7-1.01-1.62-1.01-2.75s.34-2.01,1.03-2.71c.69-.7,1.57-1.05,2.65-1.05,.36,0,.68,.04,.97,.11,.29,.07,.56,.18,.82,.34v2.11Z"
			fill="inherit"
		/><path d="M37.01,3.78v3.6c0,.8-.06,1.39-.19,1.77-.13,.38-.34,.73-.65,1.02-.31,.31-.7,.55-1.17,.7-.47,.16-1.01,.23-1.64,.23s-1.17-.08-1.63-.23c-.47-.15-.86-.39-1.19-.7-.3-.29-.52-.63-.64-1.02-.13-.39-.19-.98-.19-1.78V3.78h2.38v3.77c0,.59,.1,1.02,.29,1.28,.2,.26,.51,.39,.95,.39s.76-.13,.95-.39c.19-.26,.29-.69,.29-1.29V3.78h2.43Z"
			fill="inherit"
		/><path d="M41.36,0h-2.49V10.84h2.49V0Z"
			fill="inherit"
		/><path d="M48.27,7.32c0-.52-.13-.94-.4-1.24-.26-.3-.62-.46-1.08-.46s-.82,.15-1.08,.46c-.26,.3-.39,.72-.39,1.24s.13,.94,.39,1.24c.26,.3,.62,.45,1.08,.45s.82-.15,1.08-.45c.26-.3,.39-.72,.39-1.24Zm2.48,0c0,.51-.1,.99-.3,1.45-.2,.46-.48,.87-.86,1.23-.38,.36-.81,.64-1.28,.83-.47,.19-.98,.28-1.51,.28s-1.05-.09-1.52-.28c-.47-.19-.9-.46-1.27-.83-.37-.36-.66-.76-.85-1.22-.2-.46-.29-.95-.29-1.46s.1-1,.29-1.46c.19-.46,.48-.87,.86-1.23,.37-.36,.79-.63,1.26-.81,.47-.18,.98-.27,1.53-.27s1.05,.09,1.52,.27c.47,.18,.89,.45,1.26,.81,.38,.37,.67,.78,.87,1.24,.2,.46,.29,.94,.29,1.45Z"
			fill="inherit"
		/><path d="M58.85,.6h2.78V7.25c0,.72-.05,1.28-.14,1.66-.09,.39-.25,.73-.46,1.03-.28,.39-.63,.68-1.05,.88-.42,.2-.88,.3-1.4,.3-.64,0-1.22-.14-1.72-.41-.5-.27-.94-.68-1.29-1.22l1.7-1.55c.04,.3,.14,.54,.29,.7,.16,.16,.35,.25,.58,.25,.26,0,.45-.15,.55-.44,.1-.29,.15-1.03,.15-2.2V.6h0Z"
			fill="inherit"
		/><path d="M70.76,3.78v3.6c0,.8-.06,1.39-.19,1.77-.13,.38-.34,.73-.65,1.02-.31,.31-.7,.55-1.17,.7-.47,.16-1.01,.23-1.64,.23s-1.17-.08-1.63-.23c-.47-.15-.86-.39-1.19-.7-.3-.29-.52-.63-.64-1.02-.13-.39-.19-.98-.19-1.78V3.78h2.38v3.77c0,.59,.1,1.02,.29,1.28,.2,.26,.51,.39,.95,.39s.76-.13,.95-.39c.19-.26,.29-.69,.29-1.29V3.78h2.43Z"
			fill="inherit"
		/><path d="M72.56,10.84V3.78h2.35v1.66c.24-.61,.57-1.06,.99-1.36,.42-.3,.93-.46,1.52-.46,.1,0,.2,0,.3,0,.1,0,.2,.02,.3,.03l-.24,2.26c-.15-.05-.3-.09-.44-.11-.15-.02-.28-.03-.42-.03-.6,0-1.07,.19-1.4,.56-.33,.37-.49,.89-.49,1.57v2.92h-2.48Z"
			fill="inherit"
		/><path d="M79.02,10.84V3.78h2.49v7.06h-2.49ZM82.28,.37l-2.16,2.45h-1.08l1.27-2.45h1.96Z"
			fill="inherit"
		/><path d="M88.8,7.32c0-.55-.15-.98-.44-1.3-.29-.32-.69-.47-1.19-.47s-.9,.16-1.19,.47c-.29,.32-.43,.75-.43,1.3s.14,.99,.43,1.3c.29,.32,.68,.47,1.19,.47s.9-.16,1.19-.47c.29-.32,.44-.75,.44-1.3Zm2.21-7.32V10.84h-2.29v-1.15c-.32,.47-.67,.83-1.07,1.06-.4,.24-.83,.35-1.32,.35-.99,0-1.78-.34-2.37-1.03-.59-.68-.88-1.61-.88-2.77s.29-2.03,.88-2.72c.58-.69,1.35-1.04,2.29-1.04,.6,0,1.08,.11,1.46,.32s.71,.57,1,1.08c-.03-.17-.05-.36-.06-.57-.01-.21-.02-.44-.02-.69V0h2.39Z"
			fill="inherit"
		/><path d="M92.81,1.57c0-.38,.13-.7,.4-.96,.27-.26,.59-.4,.97-.4s.72,.13,.98,.39c.26,.26,.4,.58,.4,.97s-.13,.71-.4,.98c-.27,.27-.59,.41-.97,.41s-.7-.14-.97-.41c-.27-.27-.41-.6-.41-.98Zm.13,9.26V3.78h2.49v7.06h-2.49Z"
			fill="inherit"
		/><path d="M102.4,6.1c-.24-.21-.47-.37-.7-.47-.22-.1-.46-.15-.71-.15-.49,0-.89,.16-1.18,.49-.29,.33-.44,.77-.44,1.33s.15,.97,.46,1.29c.3,.32,.71,.48,1.22,.48,.25,0,.49-.05,.71-.15,.22-.1,.43-.26,.63-.47v2.16c-.3,.17-.6,.29-.9,.37-.3,.08-.61,.12-.92,.12-1.09,0-1.97-.35-2.64-1.05-.68-.7-1.01-1.62-1.01-2.75s.34-2.01,1.03-2.71c.69-.7,1.57-1.05,2.65-1.05,.36,0,.68,.04,.97,.11,.29,.07,.56,.18,.82,.34v2.11h0Z"
			fill="inherit"
		/><path d="M108.94,7.32c0-.52-.13-.94-.4-1.24-.26-.3-.62-.46-1.08-.46s-.82,.15-1.08,.46c-.26,.3-.39,.72-.39,1.24s.13,.94,.39,1.24c.26,.3,.62,.45,1.08,.45s.82-.15,1.08-.45c.26-.3,.39-.72,.39-1.24Zm2.48,0c0,.51-.1,.99-.3,1.45-.2,.46-.48,.87-.86,1.23-.38,.36-.81,.64-1.28,.83-.48,.19-.98,.28-1.51,.28s-1.05-.09-1.52-.28c-.47-.19-.9-.46-1.27-.83-.37-.36-.66-.76-.85-1.22-.2-.46-.29-.95-.29-1.46s.1-1,.29-1.46c.19-.46,.48-.87,.86-1.23,.37-.36,.79-.63,1.26-.81,.47-.18,.98-.27,1.53-.27s1.06,.09,1.53,.27c.47,.18,.89,.45,1.26,.81,.39,.37,.67,.78,.87,1.24,.2,.46,.29,.94,.29,1.45Z"
			fill="inherit"
		/></g><g style="isolation:isolate;"><path d="M6.09,23.55v-3.31H2.29v3.31H.39V15.16h1.9v3.5h3.8v-3.5h1.9v8.4h-1.9Z"
			fill="inherit"
		/><path d="M16.17,20.33c0,1.97-1.39,3.3-3.45,3.3s-3.47-1.33-3.47-3.3,1.39-3.29,3.47-3.29,3.45,1.31,3.45,3.29Zm-5.06,.02c0,1.06,.65,1.78,1.61,1.78s1.61-.72,1.61-1.78-.66-1.78-1.61-1.78-1.61,.72-1.61,1.78Z"
			fill="inherit"
		/><path d="M28.26,19.5v4.05h-1.83v-3.51c0-.83-.48-1.32-1.25-1.32-.89,.01-1.43,.71-1.43,1.64v3.19h-1.83v-3.51c0-.83-.48-1.32-1.24-1.32-.9,.01-1.44,.71-1.44,1.64v3.19h-1.85v-6.45h1.85v1.12c.46-.79,1.21-1.18,2.18-1.19,1.09,0,1.87,.55,2.18,1.49,.42-1,1.24-1.48,2.33-1.49,1.43,0,2.33,.96,2.33,2.47Z"
			fill="inherit"
		/><path d="M36.23,20.33c0,1.97-1.39,3.3-3.45,3.3s-3.47-1.33-3.47-3.3,1.39-3.29,3.47-3.29,3.45,1.31,3.45,3.29Zm-5.06,.02c0,1.06,.65,1.78,1.61,1.78s1.61-.72,1.61-1.78-.66-1.78-1.61-1.78-1.61,.72-1.61,1.78Z"
			fill="inherit"
		/><path d="M37.52,23.55V14.65h1.85v8.9h-1.85Z"
			fill="inherit"
		/><path d="M47.58,20.33c0,1.97-1.39,3.3-3.45,3.3s-3.47-1.33-3.47-3.3,1.39-3.29,3.47-3.29,3.45,1.31,3.45,3.29Zm-5.06,.02c0,1.06,.65,1.78,1.61,1.78s1.61-.72,1.61-1.78-.66-1.78-1.61-1.78-1.61,.72-1.61,1.78Z"
			fill="inherit"
		/><path d="M54.86,22.95c0,1.83-1.38,3-3.44,3-1.09,0-2.06-.35-2.81-.85l.68-1.29c.59,.42,1.27,.65,2,.65,1.06,0,1.73-.56,1.73-1.44v-.73c-.43,.6-1.09,.94-1.94,.94-1.67,0-2.82-1.25-2.82-3.12s1.13-3.06,2.77-3.06c.88-.01,1.55,.34,1.99,.94v-.88h1.83v5.85Zm-1.83-2.81c0-1-.61-1.68-1.51-1.68s-1.51,.7-1.52,1.68c.01,.98,.61,1.67,1.52,1.67s1.51-.68,1.51-1.67Z"
			fill="inherit"
		/><path d="M60.29,22.8c-.43,.54-1.13,.84-2.03,.84-1.38,0-2.22-.84-2.22-2.01s.88-1.93,2.46-1.94h1.78v-.12c0-.68-.46-1.08-1.34-1.08-.58,0-1.25,.19-1.92,.56l-.58-1.27c.96-.48,1.79-.74,2.91-.74,1.73,0,2.72,.86,2.73,2.31v4.21s-1.8,0-1.8,0v-.76Zm-.01-1.45v-.58h-1.45c-.71,0-1.05,.22-1.05,.73s.38,.82,1.01,.82c.78,0,1.39-.42,1.5-.97Z"
			fill="inherit"
		/><path d="M69.31,22.2c-.46,.77-1.25,1.26-2.28,1.39l-.29,.56c.49,.13,.82,.48,.82,.97,0,.74-.59,1.21-1.42,1.21-.38,0-.8-.14-1.12-.36l.35-.8c.17,.14,.41,.24,.62,.24,.32,0,.52-.22,.52-.46s-.19-.43-.55-.43c-.12,0-.29,.02-.43,.05l.46-.97c-1.7-.19-2.83-1.45-2.83-3.25,0-1.98,1.33-3.31,3.31-3.31,1.24,0,2.22,.49,2.79,1.36l-1.25,.9c-.35-.43-.84-.7-1.48-.68-.9,0-1.54,.7-1.54,1.73s.64,1.75,1.54,1.75c.66,0,1.17-.25,1.5-.76l1.27,.86Z"
			fill="inherit"
		/><path d="M74.03,22.8c-.43,.54-1.13,.84-2.03,.84-1.38,0-2.22-.84-2.22-2.01s.88-1.93,2.46-1.94h1.78v-.12c0-.68-.46-1.08-1.34-1.08-.58,0-1.25,.19-1.92,.56l-.58-1.27c.96-.48,1.79-.74,2.91-.74,1.73,0,2.72,.86,2.73,2.31v4.21s-1.8,0-1.8,0v-.76Zm-2-6.7h-1.14c0-1.21,.4-1.81,1.2-1.81,.7,0,1.04,.6,1.4,.6,.26,0,.4-.18,.4-.53h1.14c0,1.2-.4,1.79-1.2,1.79-.73,0-1.03-.59-1.4-.59-.26,0-.4,.18-.4,.54Zm1.99,5.25v-.58h-1.45c-.71,0-1.06,.22-1.06,.73s.38,.82,1.01,.82c.78,0,1.39-.42,1.5-.97Z"
			fill="inherit"
		/><path d="M83.81,20.33c0,1.97-1.39,3.3-3.45,3.3s-3.47-1.33-3.47-3.3,1.39-3.29,3.47-3.29,3.45,1.31,3.45,3.29Zm-5.06,.02c0,1.06,.65,1.78,1.61,1.78s1.61-.72,1.61-1.78-.66-1.78-1.61-1.78-1.61,.72-1.61,1.78Z"
			fill="inherit"
		/></g></svg>
		<svg v-else
			class="types prod fill-white hover:fill-[#3F87F5]"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 111.42 11.11"
		><path d="M7.85,1v3.05c-.38-.42-.76-.73-1.14-.93-.38-.2-.79-.3-1.22-.3-.8,0-1.45,.27-1.95,.82-.5,.55-.74,1.26-.74,2.14,0,.82,.25,1.5,.76,2.04,.51,.54,1.15,.81,1.93,.81,.43,0,.84-.1,1.22-.3,.38-.2,.76-.51,1.14-.94v3.05c-.44,.21-.88,.38-1.32,.48-.44,.11-.88,.16-1.33,.16-.56,0-1.07-.07-1.55-.2-.47-.13-.91-.34-1.3-.61-.76-.51-1.35-1.16-1.75-1.93-.4-.78-.6-1.66-.6-2.63,0-.78,.13-1.49,.38-2.14,.25-.64,.64-1.22,1.15-1.74,.48-.5,1.03-.87,1.64-1.12,.61-.25,1.28-.37,2.03-.37,.45,0,.89,.05,1.33,.16,.44,.11,.88,.27,1.32,.48Z"
			fill="inherit"
		/><path d="M14.63,7.3c0-.55-.15-.99-.44-1.31-.29-.32-.69-.48-1.2-.48s-.91,.16-1.2,.48c-.29,.32-.44,.76-.44,1.31s.15,.98,.44,1.3c.29,.32,.69,.47,1.2,.47s.91-.16,1.2-.47c.29-.32,.44-.75,.44-1.3Zm2.19-3.52v7.06h-2.29v-1.15c-.33,.49-.69,.85-1.1,1.08-.41,.23-.89,.34-1.44,.34-.94,0-1.69-.34-2.26-1.03-.56-.69-.84-1.61-.84-2.77s.3-2.07,.89-2.75c.59-.68,1.38-1.02,2.38-1.02,.53,0,.99,.11,1.37,.34,.38,.23,.71,.57,.99,1.05v-1.16h2.3ZM15.07,.37l-2.16,2.45h-1.08L13.11,.37h1.96Z"
			fill="inherit"
		/><path d="M21.26,0h-2.49V10.84h2.49V0Z"
			fill="inherit"
		/><path d="M28.23,6.1c-.24-.21-.47-.37-.7-.47-.22-.1-.46-.15-.71-.15-.49,0-.89,.16-1.18,.49-.29,.33-.44,.77-.44,1.33s.15,.97,.46,1.29c.3,.32,.71,.48,1.22,.48,.25,0,.49-.05,.71-.15,.22-.1,.43-.26,.63-.47v2.16c-.3,.17-.6,.29-.89,.37-.3,.08-.61,.12-.92,.12-1.09,0-1.97-.35-2.64-1.05-.68-.7-1.01-1.62-1.01-2.75s.34-2.01,1.03-2.71c.69-.7,1.57-1.05,2.65-1.05,.36,0,.68,.04,.97,.11,.29,.07,.56,.18,.82,.34v2.11Z"
			fill="inherit"
		/><path d="M37.01,3.78v3.6c0,.8-.06,1.39-.19,1.77-.13,.38-.34,.73-.65,1.02-.31,.31-.7,.55-1.17,.7-.47,.16-1.01,.23-1.64,.23s-1.17-.08-1.63-.23c-.47-.15-.86-.39-1.19-.7-.3-.29-.52-.63-.64-1.02-.13-.39-.19-.98-.19-1.78V3.78h2.38v3.77c0,.59,.1,1.02,.29,1.28,.2,.26,.51,.39,.95,.39s.76-.13,.95-.39c.19-.26,.29-.69,.29-1.29V3.78h2.43Z"
			fill="inherit"
		/><path d="M41.36,0h-2.49V10.84h2.49V0Z"
			fill="inherit"
		/><path d="M48.27,7.32c0-.52-.13-.94-.4-1.24-.26-.3-.62-.46-1.08-.46s-.82,.15-1.08,.46c-.26,.3-.39,.72-.39,1.24s.13,.94,.39,1.24c.26,.3,.62,.45,1.08,.45s.82-.15,1.08-.45c.26-.3,.39-.72,.39-1.24Zm2.48,0c0,.51-.1,.99-.3,1.45-.2,.46-.48,.87-.86,1.23-.38,.36-.81,.64-1.28,.83-.47,.19-.98,.28-1.51,.28s-1.05-.09-1.52-.28c-.47-.19-.9-.46-1.27-.83-.37-.36-.66-.76-.85-1.22-.2-.46-.29-.95-.29-1.46s.1-1,.29-1.46c.19-.46,.48-.87,.86-1.23,.37-.36,.79-.63,1.26-.81,.47-.18,.98-.27,1.53-.27s1.05,.09,1.52,.27c.47,.18,.89,.45,1.26,.81,.38,.37,.67,.78,.87,1.24,.2,.46,.29,.94,.29,1.45Z"
			fill="inherit"
		/><path d="M58.85,.6h2.78V7.25c0,.72-.05,1.28-.14,1.66-.09,.39-.25,.73-.46,1.03-.28,.39-.63,.68-1.05,.88-.42,.2-.88,.3-1.4,.3-.64,0-1.22-.14-1.72-.41-.5-.27-.94-.68-1.29-1.22l1.7-1.55c.04,.3,.14,.54,.29,.7,.16,.16,.35,.25,.58,.25,.26,0,.45-.15,.55-.44,.1-.29,.15-1.03,.15-2.2V.6h0Z"
			fill="inherit"
		/><path d="M70.76,3.78v3.6c0,.8-.06,1.39-.19,1.77-.13,.38-.34,.73-.65,1.02-.31,.31-.7,.55-1.17,.7-.47,.16-1.01,.23-1.64,.23s-1.17-.08-1.63-.23c-.47-.15-.86-.39-1.19-.7-.3-.29-.52-.63-.64-1.02-.13-.39-.19-.98-.19-1.78V3.78h2.38v3.77c0,.59,.1,1.02,.29,1.28,.2,.26,.51,.39,.95,.39s.76-.13,.95-.39c.19-.26,.29-.69,.29-1.29V3.78h2.43Z"
			fill="inherit"
		/><path d="M72.56,10.84V3.78h2.35v1.66c.24-.61,.57-1.06,.99-1.36,.42-.3,.93-.46,1.52-.46,.1,0,.2,0,.3,0,.1,0,.2,.02,.3,.03l-.24,2.26c-.15-.05-.3-.09-.44-.11-.15-.02-.28-.03-.42-.03-.6,0-1.07,.19-1.4,.56-.33,.37-.49,.89-.49,1.57v2.92h-2.48Z"
			fill="inherit"
		/><path d="M79.02,10.84V3.78h2.49v7.06h-2.49ZM82.28,.37l-2.16,2.45h-1.08l1.27-2.45h1.96Z"
			fill="inherit"
		/><path d="M88.8,7.32c0-.55-.15-.98-.44-1.3-.29-.32-.69-.47-1.19-.47s-.9,.16-1.19,.47c-.29,.32-.43,.75-.43,1.3s.14,.99,.43,1.3c.29,.32,.68,.47,1.19,.47s.9-.16,1.19-.47c.29-.32,.44-.75,.44-1.3Zm2.21-7.32V10.84h-2.29v-1.15c-.32,.47-.67,.83-1.07,1.06-.4,.24-.83,.35-1.32,.35-.99,0-1.78-.34-2.37-1.03-.59-.68-.88-1.61-.88-2.77s.29-2.03,.88-2.72c.58-.69,1.35-1.04,2.29-1.04,.6,0,1.08,.11,1.46,.32s.71,.57,1,1.08c-.03-.17-.05-.36-.06-.57-.01-.21-.02-.44-.02-.69V0h2.39Z"
			fill="inherit"
		/><path d="M92.81,1.57c0-.38,.13-.7,.4-.96,.27-.26,.59-.4,.97-.4s.72,.13,.98,.39c.26,.26,.4,.58,.4,.97s-.13,.71-.4,.98c-.27,.27-.59,.41-.97,.41s-.7-.14-.97-.41c-.27-.27-.41-.6-.41-.98Zm.13,9.26V3.78h2.49v7.06h-2.49Z"
			fill="inherit"
		/><path d="M102.4,6.1c-.24-.21-.47-.37-.7-.47-.22-.1-.46-.15-.71-.15-.49,0-.89,.16-1.18,.49-.29,.33-.44,.77-.44,1.33s.15,.97,.46,1.29c.3,.32,.71,.48,1.22,.48,.25,0,.49-.05,.71-.15,.22-.1,.43-.26,.63-.47v2.16c-.3,.17-.6,.29-.9,.37-.3,.08-.61,.12-.92,.12-1.09,0-1.97-.35-2.64-1.05-.68-.7-1.01-1.62-1.01-2.75s.34-2.01,1.03-2.71c.69-.7,1.57-1.05,2.65-1.05,.36,0,.68,.04,.97,.11,.29,.07,.56,.18,.82,.34v2.11h0Z"
			fill="inherit"
		/><path d="M108.94,7.32c0-.52-.13-.94-.4-1.24-.26-.3-.62-.46-1.08-.46s-.82,.15-1.08,.46c-.26,.3-.39,.72-.39,1.24s.13,.94,.39,1.24c.26,.3,.62,.45,1.08,.45s.82-.15,1.08-.45c.26-.3,.39-.72,.39-1.24Zm2.48,0c0,.51-.1,.99-.3,1.45-.2,.46-.48,.87-.86,1.23-.38,.36-.81,.64-1.28,.83-.48,.19-.98,.28-1.51,.28s-1.05-.09-1.52-.28c-.47-.19-.9-.46-1.27-.83-.37-.36-.66-.76-.85-1.22-.2-.46-.29-.95-.29-1.46s.1-1,.29-1.46c.19-.46,.48-.87,.86-1.23,.37-.36,.79-.63,1.26-.81,.47-.18,.98-.27,1.53-.27s1.06,.09,1.53,.27c.47,.18,.89,.45,1.26,.81,.39,.37,.67,.78,.87,1.24,.2,.46,.29,.94,.29,1.45Z"
			fill="inherit"
		/></svg>
	</a>
</template>

<style lang="scss" scoped>
#logo-cj {
    padding: 12px 15px;

    .symbol {
        height: 26px;
    }

    .types {
        margin-left: 4px;
        width: 111px;
    }
}

.collapsed {
	padding: 12px !important;
}
</style>