<script lang="ts">
import serviceUsers from '../../../../services/users';

import serviceContacts from '../../../../services/API/crm/contacts';
import serviceTags from '../../../../services/API/crm/tags';
import serviceTasks from '../../../../services/API/crm/tasks';

import BlockingOverlay from '../../../../components/BlockingOverlay/BlockingOverlay.vue';
import Datepicker from '../../../../components/Form/DatePicker.vue';
import FormValidator from '../../../../components/Form/FormValidator.vue';
import InputText from '../../../../components/Form/InputText.vue';
import Loading from '../../../../components/Loading/LoadingComponent.vue';
import ModalBasic from '../../../../components/Modal/ModalBasic.vue';
import TagsMultiselect from '../../../../components/Tags/TagsMultiselect.vue';
import TagsSmartTagz from '../../../../components/Tags/TagsSmartTagz.vue';

import {
	DefineComponent,
	defineComponent, inject, PropType
} from 'vue';
import {
	Task,
	EventBus,
	Helpers,
	Filters,
	Luxon,
	Tag,
	TinyAssignee,
	Contact,
	LegalCase,
	TinyTag,
	TaskToSend,
	Lodash,
	ContactType,
	User
} from '../../../../models/crm';
import {
	mapState, mapActions
} from 'pinia';
import { RouteLocationRaw } from 'vue-router';

import { useAccountStore } from '../../../../stores/account';
import { useLegalCasesStore } from '../../../../stores/models/crm/legalCases';
import { useContactsStore } from '../../../../stores/models/crm/contacts';


export default defineComponent({
	components: {
		BlockingOverlay,
		Datepicker,
		FormValidator,
		InputText,
		Loading,
		ModalBasic,
		TagsMultiselect,
		TagsSmartTagz,
	},
	props: {
		backRoute: {
			required: true,
			type: Object as PropType<RouteLocationRaw>,
		},
		inData: {
			type: Object,
		},
		taskId: {
			default: null, type: [ String, Number ]
		},
		tasksList: {
			default: () => [],
			type: Array as PropType<Task[]>
		},
	},
	emits: [
		'task-created',
		'task-updated',
		'close-modal',
		'open-change-to-client-modal'
	],
	data() {
		return {

			eventBus: inject<EventBus>('eventBus'),
			luxon: inject<Luxon>('luxon'),
			lodash: inject<Lodash>('lodash'),
			helpers: inject<Helpers>('helpers'),

			currentTaskSearchStr: '' as string,

			filters: inject<Filters>('filters'),

			loadedTask: null as Task | null,

			model: {
				assignees: [] as TinyAssignee[],
				legal_case: [] as LegalCase[],
				contacts: [] as Contact[],
				description: '',
				due_at_date: '',
				due_at_time: '',
				done: false,
				done_at: '' as string | null,
				title: '',
			},

			requesting: {
				loadTags: false,
				loadTask: false,
				save: false,
			},

			tags: {
				fromTask: [] as Tag[],
				loadedAll: [] as Tag[],
				model: [] as string[],
			},
		};
	},
	computed: {
		...mapState(useAccountStore, [ 'user' ]),
		...mapState(useContactsStore, [ 'searchedContacts' ]),
		...mapState(useLegalCasesStore, [ 'searchedLegalCases' ]),

		isEditing(): boolean {
			return !!this.taskId;
		},

		officeId(): number {
			if (!this.user?.office_id) return 0;

			const parsedOfficeId = !Number.isInteger(this.user.office_id)
				? parseInt(this.user.office_id as string)
				: (this.user.office_id as number);

			return parsedOfficeId;
		},

		tagsModelFull(): TinyTag[] {
			const allTags = this.tags.loadedAll;
			const tags = this.tags.model;

			const ret: TinyTag[] = [];

			tags.forEach(
				item => {
					const foundItem = allTags.find(findItem => {
						return findItem.title === item;
					});

					if (foundItem) {
						ret.push(foundItem);
					} else {
						ret.push({
							id: null,
							office_id: this.officeId,
							title: item,
						});
					}
				}
			);

			return ret;
		},

	},
	watch: {

		inData: {
			deep: true,
			handler: function(): void {
				this.setInitialFields();
			},
		},
		'$route.params': {
			deep: true,
			handler: function(params): void {
				const newValue = params.newTaskDueAt || '';

				this.setDueAtValue(newValue);
			}
		},
	},
	methods: {
		...mapActions(useContactsStore, [ 'searchContactsByName' ]),
		...mapActions(useLegalCasesStore, [ 'searchLegalCasesByName' ]),

		async createTags(tags: TinyTag[]): Promise<{ createdTags: Tag[], oldTags: TinyTag[] }> {
			const createdTags = [] as Tag[];
			const oldTags = tags.filter(item => item.id); // Tags that were already saved in DB
			const newTags = tags.filter(item => !item.id); // New added tags

			const promises = [];

			for (let i = 0;i < newTags.length;i++) {
				const tag = newTags[i];

				if (!tag) break;

				promises.push(
					serviceTags
						.create({ title: tag.title })
						.then(({data}) => {
							createdTags.push(data);
						})
						.catch((error) => console.error(error))
				);
			}

			await Promise.all(promises);

			return {
				createdTags,
				oldTags,
			};
		},

		async loadTags(): Promise<void> {
			this.requesting.loadTags = true;

			try {
				const response = await serviceTags.getAll();

				const { data } = response;

				this.tags.loadedAll = data.tags;
			} catch (err) {
				console.error(err);
			}

			this.requesting.loadTags = false;
		},

		async loadTask(defaultTaskId = null, forceReload = false): Promise<void> {
			const taskId = this.taskId || defaultTaskId || '';

			if (!taskId) return;

			this.requesting.loadTask = true;

			try {
				let task: Task;

				const notParsed = sessionStorage.getItem(`task-${taskId}`);

				const storedTask = notParsed && JSON.parse(notParsed);

				if (storedTask && !forceReload) {
					task = storedTask;
				} else {
					const { data } = await serviceTasks.getById(taskId);

					sessionStorage.setItem(`task-${taskId}`, JSON.stringify(data));

					task = data;
				}

				this.loadedTask = task;

				this.model.title = task.title || '';
				this.model.description = task.description || '';
				this.model.done = task.done || false;
				this.model.legal_case = task.legal_case? [ task.legal_case ] : [];

				this.model.due_at_date = task.due_at || '';
				this.model.due_at_time = task.due_at || '';

				this.model.contacts = task.contacts || [];
				this.model.assignees = task.assignees || [];

				this.tags.fromTask = task.tags;
				this.tags.model = task.tags.map(item => item.title);
			} catch (err) {
				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Falha ao carregar tarefa',
						type: 'error'
					}
				);
			}

			this.requesting.loadTask = false;
		},

		resetModel(): void {
			this.model.legal_case = [];
			this.model.description = '';
			this.model.due_at_date = '';
			this.model.due_at_time = '';
			this.model.done = false;
			this.model.title = '';

			this.model.contacts = [];
			this.model.assignees = [];

			this.tags.fromTask = [];
			this.tags.loadedAll = [];
			this.tags.model = [];
		},


		async save(): Promise<void> {
			for (const contact of this.model.contacts) {
				if (contact.type === ContactType.LEAD) {
					const { data } = await serviceContacts.getById(contact.id);

					this.model.contacts.map(contactToMap => {
						if (contactToMap.id === data.id) {
							return data;
						}

						return contactToMap;
					});

					if (data.type === ContactType.LEAD) {
						this.$emit('open-change-to-client-modal', contact.id);

						return;
					}
				}
			}

			const validateResult = (this.$refs.formValidatorModalTask as typeof FormValidator).validate();
			const { isValid } = validateResult;

			const {model} = this;

			const [ selectedLegalCase ] = model.legal_case;

			let {taskId} = this;

			if (isValid) {
				this.eventBus?.emit('BlockingOverlay/show', {
					text: 'Aguarde, atualizando a tarefa'
				});

				this.requesting.save = true;

				const tags = await this.createTags(this.tagsModelFull);
				const tag_ids = tags.oldTags.map(item => item.id || null)
					.concat(tags.createdTags.map(item => item.id || null));

				model.done_at = model.done ? (this.luxon?.fromMillis(Date.now()).toISO() || null) : null;

				const payload = {
					description: model.description,
					done: model.done,
					done_at: model.done_at,
					due_at: model.due_at_date?.slice(0, 10) + model.due_at_time?.slice(10),
					legal_case_id: selectedLegalCase?.id || '',
					office_id: this.officeId,
					title: model.title,

					contact_ids: model.contacts.map(item => item.id),
					assignee_ids: model.assignees.map(item => item.id),
					tag_ids,
				} as TaskToSend;

				if (this.isEditing) {
					try {
						await serviceTasks.update({
							data: payload,
							id: taskId,
						});

						await this.loadTask(null, true);

						(this.$refs.modal as typeof ModalBasic).hide();

						this.eventBus?.emit(
							'Toast/add',
							{
								content: 'Tarefa atualizada com sucesso',
								type: 'success'
							}
						);
					} catch (err) {
						console.error(err);

						this.eventBus?.emit(
							'Toast/add',
							{
								content: 'Falha ao atualizar tarefa',
								type: 'error'
							}
						);
					}

					this.$emit('task-updated');
				} else {
					this.eventBus?.emit('BlockingOverlay/show', {
						text: 'Aguarde, criando a tarefa'
					});

					try {
						const createTaskResponse = await serviceTasks.create(payload);
						taskId = createTaskResponse.data.id;

						(this.$refs.modal as typeof ModalBasic).hide();

						this.eventBus?.emit(
							'Toast/add',
							{
								content: 'Tarefa criada com sucesso',
								type: 'success'
							}
						);

						this.$emit('task-created');
					} catch (err) {
						console.error(err);

						this.eventBus?.emit(
							'Toast/add',
							{
								content: 'Falha ao criar tarefa',
								type: 'error'
							}
						);
					}
				}

				this.eventBus?.emit('BlockingOverlay/hide');

				this.requesting.save = false;
			} else {
				(this.$refs.modal as typeof ModalBasic).$refs.modalContent.scrollTo({
					behavior: 'smooth',
					left: 0,
					top: 0,
				});
			}
		},

		setInitialFields(): void {
			const {inData} = this;

			if (inData) {
				if (inData.assignees) {
					this.model.assignees = inData.assignees;
				}

				if (inData.legal_case) {
					const { legal_case } = inData;

					if (!legal_case) return;

					const {
						assignees, contacts
					} = legal_case;

					this.model.legal_case = [ legal_case ];

					if (assignees) {
						this.model.assignees = assignees;
					}

					if (contacts) {
						this.model.contacts = contacts;
					}
				}
			}
		},

		setDueAtValue(value: string): void {
			if (value) {
				this.$nextTick (() => {
					this.model.due_at_date = value;
					this.model.due_at_time = value;
				});
			}
		},

		async show(defaultTaskId = null): Promise<void> {
			this.loadTags();

			this.resetModel();

			if (!!defaultTaskId || (this.isEditing && this.taskId)) {
				await this.loadTask(defaultTaskId);
			}

			if (!defaultTaskId && !this.isEditing) {
				this.setInitialFields();
			}

			(this.$refs.modal as typeof ModalBasic).show();
		},

		tagsChanged(tags: string[]): void {
			this.tags.model = tags;
		},

		tagsMultiselectAsyncAssignees_queryFunction(query: string): Promise<User[]> {
			return new Promise(
				(resolve, reject) => {
					serviceUsers
						.searchByName({
							name: query,
						})
						.then(({data}) => resolve(data.users))
						.catch((err) => {
							console.error(err);
							reject(err);
						});
				}
			);
		},

		async tagsMultiselectAsyncLegalCase_queryFunction(query: string): Promise<LegalCase[]> {
			try {
				this.currentTaskSearchStr = query;
				const contactIds = this.model.contacts.map(item => item.id);
				const contactFilter = contactIds.length > 0 ? `&contacts=${contactIds.join(',')}` : '';
				await new Promise((resolve) => {
					this.lodash?.debounce(async() => {
						if (this.currentTaskSearchStr === query) {
							await this.searchLegalCasesByName(query, 'name:asc', `&mode=light${contactFilter}`);
						}

						resolve(this.searchedLegalCases);
					}, 300)();
				});

				if (this.searchedLegalCases.length === 0) {
					await this.searchLegalCasesByName(query, 'name:asc', '&mode=light');
				}

				return this.searchedLegalCases;
			} catch (err) {
				console.error(err);
				throw err;
			}
		},

		async tagsMultiselectAsyncContacts_queryFunction(query: string): Promise<Contact[]> {
			try {
				const legalCaseFilter = this.model.legal_case[0] ? `&legal_case=${this.model.legal_case[0].id}`: '';
				await this.searchContactsByName({
					name: query, perPage: 25, page: 1, orderBy: 'name:asc', filters: legalCaseFilter
				});

				if (this.searchedContacts.length === 0) {
					await this.searchContactsByName({
						name: query, perPage: 25, page: 1, orderBy: 'name:asc', filters: ''
					});
				}

				return this.searchedContacts;
			} catch (err) {
				console.error(err);
				throw err;
			}
		},

		tagsMultiselectAsyncTags_queryFunction(query: string): Promise<Tag[]> {
			return new Promise(
				(resolve, reject) => {
					serviceTags
						.searchByTag({
							tag: query,
						})
						.then(({data}) => resolve(data.tags))
						.catch((err) => {
							console.error(err);
							reject(err);
						});
				}
			);
		},

		// Events

		buttonCancelClick(): void {
			(this.$refs.modal as typeof ModalBasic).hide();
		},

		buttonSaveClick(): void {
			this.save();
		},

		modal_hide(): void {
			this.$emit('close-modal');

			if (typeof this.backRoute === 'string') {
				this.$router.push({
					path: this.backRoute,
					query: this.$route.query,
				});

				return;
			}

			this.$router.push({
				...this.backRoute,
				query: this.$route.query,
			});
		},

	},
});
</script>
<template>
	<ModalBasic
		ref="modal"
		:dismissable="!requesting.loadTask"
		:size="requesting.loadTask ? 'md' : 'lg'"
		:title="isEditing ? 'Editar tarefa' : 'Nova tarefa'"
		:is-create-modal="!isEditing"
		:is-edit-modal="isEditing"
		@hide="modal_hide"
	>
		<template #content>
			<template v-if="requesting.loadTask">
				<Loading class="opacity-50 py-14 text-center" />
			</template>
			<template v-else>

				<FormValidator
					ref="formValidatorModalTask"
					:refs="($refs as DefineComponent)"
					:validation-summary-show="true"
					validation-summary-class="mb-4 mx-4 p-3"
				>
					<template
						field="datePickerDueDate"
						label="Data de entrega"
						message="Preenchimento obrigatório"
						:invalidIf="() => model.due_at_date === ''"
					/>
					<template
						field="datePickerDueTime"
						label="Horário de entrega"
						message="Preenchimento obrigatório"
						:invalidIf="() => model.due_at_time === ''"
					/>
					<template
						field="inputTitle"
						label="Nome / Título da tarefa"
						message="Preenchimento obrigatório"
						:invalidIf="() => model.title === ''"
					/>
					<template
						field="tagsMultiselectAsyncAssignees"
						label="Responsáveis"
						message="Necessário adicionar pelo menos um responsável"
						:invalidIf="() => model.assignees.length < 1"
					/>
					<template
						field="tagsMultiselectAsyncContacts"
						label="Contatos"
						message="Necessário adicionar pelo menos um contato"
						:invalidIf="() => model.contacts.length < 1"
					/>
					<template
						field="tagsMultiselectAsyncLegalCase"
						label="Caso"
						message="Necessário adicionar um caso"
						:invalidIf="() => model.legal_case.length < 1"
					/>
				</FormValidator>

				<div class="flex justify-between mb-4 px-4">

					<div class="flex">
						<div class="block font-semibold mb-1 text-gray-500 text-xs w-[48%] mr-2">
							<div class="mb-1">
								<span>Data de entrega</span>
								<span class="ml-1 text-red-600">*</span>
							</div>
							<Datepicker
								ref="datePickerDueDate"
								v-model="model.due_at_date"
								:enable-time-picker="false"
								:format="'dd/MM/yyyy'"
							/>
						</div>

						<div class="block font-semibold mb-1 text-gray-500 text-xs w-[48%]">
							<div class="mb-1">
								<span>Horário de entrega</span>
								<span class="ml-1 text-red-600">*</span>
							</div>
							<Datepicker
								ref="datePickerDueTime"
								v-model="model.due_at_time"
								:format="'HH:mm'"
								:time-picker="true"
								:placeholder="'Selecione a hora'"
							/>
						</div>

					</div>

					<div
						:class="[
							'flex align-center border py-1 px-2 rounded text-center w-auto',
							{ 'border-success bg-emerald-100': model.done }
						]"
					>
						<label class="label cursor-pointer margin-auto">
							<span
								:class="['label-text mr-3', { 'font-bold': !model.done }]"
							>
								Pendente
							</span>
							<input
								v-model="model.done"
								type="checkbox"
								:class="[
									'toggle',
									{ 'toggle-success': model.done }
								]"
								:checked="model.done"
							>
							<span
								:class="[
									'label-text ml-3',
									{ 'font-bold text-emerald-600': model.done },
									{ 'opacity-50': !model.done }
								]"
							>
								Concluída
							</span>
						</label>
					</div>

				</div>

				<hr class="my-5">

				<div class="mb-4 px-4">
					<InputText
						ref="inputTitle"
						v-model="model.title"
						class="w-full"
						label="Nome / Título da tarefa"
						required
					/>
				</div>

				<div class="flex gap-4 mb-4 px-4">
					<div class="flex-1">
						<div class="block font-semibold mb-1 text-gray-500 text-xs">
							<span>Responsáveis</span>
							<span class="ml-1 text-red-600">*</span>
						</div>
						<TagsMultiselect
							ref="tagsMultiselectAsyncAssignees"
							v-model="model.assignees"
							async
							label="name"
							:query-function="tagsMultiselectAsyncAssignees_queryFunction"
						>
							<template #tagIcon>
								<span
									:class="'bg-[#9FBCDA] rounded-full w-6 h-6'"
									class="flex items-center justify-center"
								>
									<svg
										class="inline fill-[#E5F0FB] w-5 h-5"
										fill="currentColor"
										viewBox="0 0 20 20"
										xmlns="http://www.w3.org/2000/svg"
										aria-hidden="true"
									>
										<path
											d="M10 8a3 3 0 100-6 3 3 0 000 6zM3.465 14.493a1.23 1.23 0 00.41 1.412A9.957 9.957 0 0010 18c2.31 0 4.438-.784 6.131-2.1.43-.333.604-.903.408-1.41a7.002 7.002 0 00-13.074.003z"
										/>
									</svg>
								</span>
							</template>
						</TagsMultiselect>
					</div>
					<div class="flex-1">
						<div class="block font-semibold mb-1 text-gray-500 text-xs">
							<span>Contatos</span>
							<span class="ml-1 text-red-600">*</span>
						</div>
						<TagsMultiselect
							ref="tagsMultiselectAsyncContacts"
							v-model="model.contacts"
							async
							label="name"
							:query-function="tagsMultiselectAsyncContacts_queryFunction"
						>
							<template #tagIcon>
								<span
									class="bg-[#9FBCDA] rounded-full w-6 h-6"
									:class="'flex items-center justify-center'"
								>
									<svg
										class="inline fill-[#E5F0FB] w-5 h-5"
										fill="currentColor"
										viewBox="0 0 20 20"
										xmlns="http://www.w3.org/2000/svg"
										aria-hidden="true"
									>
										<path
											clip-rule="evenodd"
											fill-rule="evenodd"
											d="M1 6a3 3 0 013-3h12a3 3 0 013 3v8a3 3 0 01-3 3H4a3 3 0 01-3-3V6zm4 1.5a2 2 0 114 0 2 2 0 01-4 0zm2 3a4 4 0 00-3.665 2.395.75.75 0 00.416 1A8.98 8.98 0 007 14.5a8.98 8.98 0 003.249-.604.75.75 0 00.416-1.001A4.001 4.001 0 007 10.5zm5-3.75a.75.75 0 01.75-.75h2.5a.75.75 0 010 1.5h-2.5a.75.75 0 01-.75-.75zm0 6.5a.75.75 0 01.75-.75h2.5a.75.75 0 010 1.5h-2.5a.75.75 0 01-.75-.75zm.75-4a.75.75 0 000 1.5h2.5a.75.75 0 000-1.5h-2.5z"
										/>
									</svg>
								</span>
							</template>
						</TagsMultiselect>
					</div>
				</div>

				<div class="mb-4 px-4">
					<div class="block font-semibold mb-1 text-gray-500 text-xs">
						<span>Caso</span>
						<span class="ml-1 text-red-600">*</span>
					</div>
					<TagsMultiselect
						ref="tagsMultiselectAsyncLegalCase"
						v-model="model.legal_case"
						async
						label="name"
						:single="true"
						:query-function="tagsMultiselectAsyncLegalCase_queryFunction"
					>
						<template #maxElements>
							<span class="inline-flex items-center text-red-500 text-sm">
								<svg
									class="inline h-5 w-5"
									fill="currentColor"
									viewBox="0 0 24 24"
									xmlns="http://www.w3.org/2000/svg"
									aria-hidden="true"
								>
									<path
										clip-rule="evenodd"
										fill-rule="evenodd"
										d="M6.72 5.66l11.62 11.62A8.25 8.25 0 006.72 5.66zm10.56 12.68L5.66 6.72a8.25 8.25 0 0011.62 11.62zM5.105 5.106c3.807-3.808 9.98-3.808 13.788 0 3.808 3.807 3.808 9.98 0 13.788-3.807 3.808-9.98 3.808-13.788 0-3.808-3.807-3.808-9.98 0-13.788z"
									/>
								</svg>
								<span class="ml-1">Você só pode selecionar um caso por tarefa</span>
							</span>
						</template>
						<template #tagIcon>
							<span
								class="bg-[#9FBCDA] rounded-full w-6 h-6"
								:class="'flex items-center justify-center'"
							>
								<svg
									class="inline fill-[#E5F0FB] w-5 h-5"
									fill="currentColor"
									viewBox="0 0 20 20"
									xmlns="http://www.w3.org/2000/svg"
									aria-hidden="true"
								>
									<path
										clip-rule="evenodd"
										fill-rule="evenodd"
										d="M10 2a.75.75 0 01.75.75v.258a33.186 33.186 0 016.668.83.75.75 0 01-.336 1.461 31.28 31.28 0 00-1.103-.232l1.702 7.545a.75.75 0 01-.387.832A4.981 4.981 0 0115 14c-.825 0-1.606-.2-2.294-.556a.75.75 0 01-.387-.832l1.77-7.849a31.743 31.743 0 00-3.339-.254v11.505a20.01 20.01 0 013.78.501.75.75 0 11-.339 1.462A18.558 18.558 0 0010 17.5c-1.442 0-2.845.165-4.191.477a.75.75 0 01-.338-1.462 20.01 20.01 0 013.779-.501V4.509c-1.129.026-2.243.112-3.34.254l1.771 7.85a.75.75 0 01-.387.83A4.98 4.98 0 015 14a4.98 4.98 0 01-2.294-.556.75.75 0 01-.387-.832L4.02 5.067c-.37.07-.738.148-1.103.232a.75.75 0 01-.336-1.462 32.845 32.845 0 016.668-.829V2.75A.75.75 0 0110 2zM5 7.543L3.92 12.33a3.499 3.499 0 002.16 0L5 7.543zm10 0l-1.08 4.787a3.498 3.498 0 002.16 0L15 7.543z"
									/>
								</svg>
							</span>
						</template>
					</TagsMultiselect>
				</div>

				<div class="mb-4 px-4">
					<div class="block font-semibold mb-1 text-gray-500 text-xs">
						Tags
					</div>
					<TagsSmartTagz
						:autocomplete-data="tags.loadedAll"
						:initial-tags="tags.fromTask"
						@tags-changed="tagsChanged"
					/>
				</div>

				<div class="mb-4 px-4">
					<InputText
						ref="inputDescription"
						v-model="model.description"
						class="w-full"
						label="Descrição da tarefa"
						placeholder="Digite aqui a descrição da tarefa..."
						multiline
					/>
				</div>

			</template>

		</template>
		<template
			v-if="!requesting.loadTask"
			#footer
		>
			<fieldset
				class="flex-1 flex overflow-hidden rounded-b-md"
				:disabled="requesting.loadTask"
			>
				<button
					class="flex-1 bg-gray-200 font-medium p-4 text-gray-500 hover:bg-gray-300 active:bg-gray-200"
					@click="buttonCancelClick"
				>
					CANCELAR
				</button>
				<button
					class="flex-1 bg-primary font-medium p-4 text-white hover:bg-blue-600 active:bg-primary"
					@click="buttonSaveClick"
				>
					SALVAR
				</button>
			</fieldset>
		</template>
	</ModalBasic>
	<Teleport to="body">
		<BlockingOverlay />
	</Teleport>
</template>
<style lang="scss" scoped></style>
