<script lang="ts">
import {
	defineComponent, inject, PropType
} from 'vue';
import {
	EventBus, Funnel, Lodash
} from '../../../../models/crm';
import Loading from '../../../../components/Loading/LoadingComponent.vue';
import {
	mapActions, mapState
} from 'pinia';
import { useAccountStore } from '../../../../stores/account';
import { useFunnelsStore } from '../../../../stores/models/crm/funnels';

export default defineComponent({
	components: {
		Loading,
	},
	props: {
		selectedFunnel: {
			required: true,
			type: Object as PropType<Funnel>
		},
	},
	emits: [ 'funnel-selected' ],
	data() {
		return {
			openFunnelDropdown: false as boolean,
			perPage: 25 as number,
			page: 1 as number,

			searchStr: '' as string,

			requesting: {
				loadFunnels: false as boolean,
				searchFunnels: false as boolean,
			},

			eventBus: inject<EventBus>('eventBus'),
			lodash: inject<Lodash>('lodash'),
		};
	},
	computed: {
		...mapState(useAccountStore, [ 'user' ]),
		...mapState(useFunnelsStore, [ 'funnelsList', 'isRequestingFunnels', 'searchedFunnels' ]),

		shouldShowSearchResult(): boolean {
			return this.searchStr.length > 0;
		},
	},
	watch: {
		searchStr(newValue, oldValue) {
			if (newValue?.length === 0 || newValue === oldValue) {
				return;
			}

			this.requesting.searchFunnels = true;

			this.lodash?.debounce(() => this.callSearch(newValue), 600)();
		}
	},
	methods: {
		...mapActions(useFunnelsStore, [ 'paginatedLoadFunnels', 'searchFunnelsByName' ]),

		async callSearch(previousSearchStr : string): Promise<void> {
			if (previousSearchStr === this.searchStr) {
				try {
					await this.searchFunnelsByName({
						name: previousSearchStr,
						page: 1,
						perPage: this.perPage,
						orderBy: 'name:asc',
						filters: '',
					});
				} catch (e) {
					console.error(e);

					this.eventBus?.emit(
						'Toast/add',
						{
							content: 'Erro ao pesquisar fluxos de casos',
							type: 'error'
						}
					);
				} finally {
					this.requesting.searchFunnels = false;
				}
			}
		},

		async loadNextFunnels(): Promise<void> {
			try {
				if (this.shouldShowSearchResult) {
					this.requesting.searchFunnels = true;

					this.page = Math.ceil(this.searchedFunnels.length / this.perPage);

					if (this.perPage * this.page > this.searchedFunnels.length) {
						throw new Error('No more funnels to load');
					}

					await this.searchFunnelsByName({
						name: this.searchStr,
						page: this.page + 1,
						perPage: this.perPage,
						orderBy: 'name:asc',
						filters: '',
					});

					this.requesting.searchFunnels = false;

					return;
				}

				this.requesting.loadFunnels = true;

				this.page = Math.ceil(this.funnelsList.length / this.perPage);

				if (this.perPage * this.page > this.funnelsList.length) {
					throw new Error('No more funnels to load');
				}

				await this.paginatedLoadFunnels({
					page: this.page + 1,
					perPage: this.perPage,
					orderBy: 'name:asc',
					filters: '',
				});

				this.requesting.loadFunnels = false;
			} catch (err) {
				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Erro ao carregar fluxos de casos',
						type: 'error'
					}
				);

				this.requesting.loadFunnels = false;
				this.requesting.searchFunnels = false;
			}
		},

		userScrolled(event: Event): void {
			const target = event.target as HTMLElement;

			if (target.scrollHeight - target.scrollTop === target.clientHeight) {
				if (target.scrollHeight === target.clientHeight) return;
				this.loadNextFunnels();
			}
		},

		buttonSelectFunnelClick() {
			this.openFunnelDropdown = !this.openFunnelDropdown;
		},

		selectFunnel(funnel: Funnel) {
			this.$emit('funnel-selected', funnel);
			this.openFunnelDropdown = false;
		},

		getFunnelStepsAsString(funnel: Funnel): string {
			try {
				return funnel.funnel_steps.map(item => item.name).join(', ');
			} catch (err) {
				return '';
			}
		},
	}

});
</script>
<template>
	<div class="relative flex flex-column p-0 m-0">
		<button
			id="btn-open-funnel-dropdown"
			class="btn btn--primary !h-[38px] min-h-0"
			style="border-top-right-radius: 0; border-bottom-right-radius: 0;"
			@click="buttonSelectFunnelClick"
		>
			<span
				class="ml-1 overflow-hidden text-ellipsis"
				style="max-width: min(400px, 20dvw);"
			>
				{{ selectedFunnel.name }}
			</span>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 14 8"
				class="ml-1 inline w-4 h-4"
				aria-hidden="true"
			>
				<path
					d="M1 1l6 6 6-6"
					fill="none"
					stroke="currentColor"
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</svg>
		</button>
		<div
			v-if="openFunnelDropdown"
			v-click-outside="() => openFunnelDropdown = false"
			class="py-4 px-4 max-h-[400px] min-w-[300px] max-w-[400px] bg-white border rounded shadow-md overflow-y-auto absolute top-[100%] left-0 flex flex-col gap-3"
			@scroll="userScrolled($event)"
		>
			<div class="flex flex-1 relative">
				<input
					id="npt-search-funnel"
					v-model="searchStr"
					type="text"
					placeholder="Digite para buscar"
					autocomplete="off"
					class="z-20 outline-0 w-full text-sm font-medium text-[#3B424D] border border-[#A6AAAD] border-solid rounded-[20px] h-10 pl-10 items-center hover:border-gray-500 focus:border-[#3F87F5]"
				>
				<div class="absolute z-30 left-[10px] top-[10px] h-[20px] w-[20px] flex justify-center items-center rounded-full">
					<svg
						viewBox="0 0 17 17"
						fill="#A6AAAD"
					>
						<path
							d="M16.1306 13.7224L12.6547 10.2466C12.6363 10.2281 12.6146 10.2152 12.5953 10.198C13.2792 9.1605 13.6784 7.91859 13.6784 6.58304C13.6784 2.95001 10.7333 0.00488281 7.10031 0.00488281C3.46734 0.00488281 0.522217 2.95001 0.522217 6.58298C0.522217 10.2159 3.46728 13.1611 7.10025 13.1611C8.43586 13.1611 9.6777 12.7619 10.7152 12.0779C10.7324 12.0972 10.7452 12.1189 10.7637 12.1373L14.2397 15.6133C14.7619 16.1354 15.6084 16.1354 16.1306 15.6133C16.6527 15.0911 16.6527 14.2446 16.1306 13.7224ZM7.10031 10.8807C4.7267 10.8807 2.80257 8.95653 2.80257 6.58298C2.80257 4.20936 4.72676 2.28524 7.10031 2.28524C9.4738 2.28524 11.398 4.20943 11.398 6.58298C11.398 8.95653 9.4738 10.8807 7.10031 10.8807Z"
						/>
					</svg>
				</div>
			</div>
			<Loading v-if="funnelsList.length === 0" />
			<template v-else>
				<button
					v-for="(funnel, index) in (shouldShowSearchResult ? searchedFunnels : funnelsList)"
					:key="`funnel-${index}`"
					class="flex border border-blue-500 p-2 rounded text-left text-white w-full"
					@click="selectFunnel(funnel)"
				>
					<svg
						:class="['fill-blue-500 inline h-6 w-6', { 'opacity-0': funnel.id !== selectedFunnel.id }]"
						fill="currentColor"
						viewBox="0 0 20 20"
						xmlns="http://www.w3.org/2000/svg"
						aria-hidden="true"
					>
						<path
							clip-rule="evenodd"
							fill-rule="evenodd"
							d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
						/>
					</svg>
					<div class="pl-2 w-full flex flex-col text-blue-500">
						<span class="max-w-[90%] overflow-hidden text-ellipsis">
							{{ funnel.name }}
						</span>
						<span class="opacity-50 text-xs">
							{{ getFunnelStepsAsString(funnel) }}
						</span>
					</div>
				</button>
				<div
					v-if="requesting.loadFunnels || requesting.searchFunnels"
					class="w-full m-0 p-0 flex items-center justify-center"
				>
					<svg
						aria-hidden="true"
						class="inline w-5 h-5 text-gray-200 animate-spin fill-blue-500"
						viewBox="0 0 100 101"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
							fill="currentColor"
						/>
						<path
							d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
							fill="currentFill"
						/>
					</svg>
				</div>
			</template>
		</div>
	</div>
</template>
<style lang="scss" scoped></style>