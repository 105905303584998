<script lang="ts">
import {
	PropType,
	defineComponent, inject
} from 'vue';

import {
	Attachment,
	EventBus, Helpers, Luxon, ValidationResult
} from '../../../../models/crm';

import serviceExpenses from '../../../../services/API/financial/expenses';
import serviceIncomes from '../../../../services/API/financial/incomes';

import Loading from '../../../../components/Loading/LoadingComponent.vue';
import ModalBasic from '../../../../components/Modal/ModalBasic.vue';

import IncomeExpenseAddBlock from '../../components/IncomesExpenses/IncomeExpenseAddBlock.vue';
import {
	EntryBlockModel, EntryBlockToSend, FinancialAttachment
} from '../../../../models/financial';
import { useDashboardStore } from '../../../../stores/models/financial/dashboard';
import { mapActions } from 'pinia';
import { RouteLocationRaw } from 'vue-router';

export default defineComponent({
	components: {
		Loading,
		ModalBasic,

		IncomeExpenseAddBlock,
	},
	props: {

		backRoute: {
			type: Object as PropType<RouteLocationRaw>,
		},

		modalBackRoute: Object as PropType<RouteLocationRaw>,

		entryId: {
			type: [ String, Number ],
			required: true,
		},

		viewDictionary: {
			required: true,
			type: Object,
		},

		viewType: {
			required: true,
			type: String,
		},

	},
	emits: [
		'entry-updated',
	],
	data() {
		return {

			eventBus: inject<EventBus>('eventBus'),
			helpers: inject<Helpers>('helpers'),
			luxon: inject<Luxon>('luxon'),

			loadedAttachments: [] as Attachment[],

			model: {} as EntryBlockModel,

			requesting: {
				loadEntry: true,
				save: false,
			},

		};
	},
	methods: {
		...mapActions(useDashboardStore, [ 'fetchMonthsSummary' ]),

		async attachmentAdd(files: FinancialAttachment[]): Promise<void> {
			const isExpense = this.viewType === 'expense';
			const service = isExpense ? serviceExpenses : serviceIncomes;

			this.eventBus?.emit('BlockingOverlay/show', {
				text: 'Aguarde, adicionando anexos'
			});

			try {
				await service.attachmentAdd(
					Number(this.entryId), files
				);

				await this.attachmentsLoad();

				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Anexo(s) adicionado(s) com sucesso',
						type: 'success'
					}
				);
			} catch (err) {
				console.error(err);

				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Falha ao adicionar anexos',
						type: 'error'
					}
				);

				return;
			} finally {
				this.eventBus?.emit('BlockingOverlay/hide');
			}
		},

		async attachmentRemove(item: Attachment): Promise<void> {
			const isExpense = this.viewType === 'expense';
			const service = isExpense ? serviceExpenses : serviceIncomes;

			this.eventBus?.emit('BlockingOverlay/show', {
				text: 'Aguarde, removendo anexo'
			});

			try {
				await service.attachmentDelete(
					item.id, Number(this.entryId)
				);

				await this.attachmentsLoad();

				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Anexo removido com sucesso',
						type: 'success'
					}
				);
			} catch (err) {
				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Falha ao remover o anexo',
						type: 'error'
					}
				);

				return;
			} finally {
				this.eventBus?.emit('BlockingOverlay/hide');
			}
		},

		async attachmentsLoad(): Promise<void> {
			const isExpense = this.viewType === 'expense';
			const service = isExpense ? serviceExpenses : serviceIncomes;

			const { data } = await service.getAttachmentsByEntryId(Number(this.entryId));
			const { attachments } = data;

			this.loadedAttachments = attachments;
		},

		async loadEntry(entryId: number): Promise<void> {
			try {
				this.requesting.loadEntry = true;

				const isExpense = this.viewType === 'expense';
				const service = isExpense ? serviceExpenses : serviceIncomes;

				const { data } = await service.getById(entryId);

				const formattedAmount = this.helpers?.getFormattedCurrencyFromNumber(Number(data.amount));
				const isoDueDate = this.luxon?.fromISO(data.due_date).toISO()|| new Date().toISOString();
				const isoPaymentDate = (data.payment_date && this.luxon?.fromISO(data.payment_date).toISO())
					|| '';

				this.model.amount = formattedAmount || '';
				this.model.category = isExpense && data.expense_category
					? [ (data.expense_category) ]
					: (data.income_category
						? [ data.income_category ]
						: []
					);
				this.model.contact = data.contact ? [ data.contact ] : [];
				this.model.costCenter = data.cost_center ? [ data.cost_center ] : [];
				this.model.description = data.description;
				this.model.dueDate = isoDueDate;
				this.model.legalCase = data.legal_case ? [ data.legal_case ] : [];
				this.model.note = data.note;
				this.model.paymentDate = isoPaymentDate;

				this.loadedAttachments = data.attachments?.map(item => item.attachment) || [];
			} catch (err) {
				console.error(err);

				this.eventBus?.emit(
					'Toast/add',
					{
						content: `Erro ao carregar ${this.viewDictionary.entry}`,
						type: 'error'
					}
				);
			} finally {
				this.requesting.loadEntry = false;
			}
		},

		hide(): void {
			((this.$refs.modal as typeof ModalBasic) as typeof ModalBasic).hide();
		},

		resetModel(): void {
			this.model = {
				amount: '',
				category: [],
				contact: [],
				costCenter: [],
				description: '',
				dueDate: '',
				legalCase: [],
				note: '',
				paymentDate: '',

				attachments: {
					files: [],
				},
			};
		},

		getPayload(): EntryBlockToSend {
			const {
				amount: formattedAmount,
				category,
				contact,
				costCenter,
				description,
				dueDate,
				legalCase,
				note,
				paymentDate,
			} = this.model;

			const amount = this.helpers?.getNumberFromFormattedCurrency(formattedAmount) || 0;
			const contact_id = (contact[0] && contact[0].id) || undefined;
			const cost_center_id = (costCenter[0] && costCenter[0].id) || undefined;
			const due_date = dueDate;
			const financial_category_id = (category[0] && category[0].id) || undefined;
			const legal_case_id = (legalCase[0] && legalCase[0].id) || undefined;
			const payment_date = paymentDate;

			return {
				amount,
				contact_id,
				cost_center_id,
				description,
				due_date,
				financial_category_id,
				legal_case_id,
				payment_date,
				note,
			};
		},

		async save(): Promise<void> {
			const entryId = Number(this.entryId);
			const isViewTypeIncome = this.viewType === 'income';

			const payload = this.getPayload();

			const service = isViewTypeIncome ? serviceIncomes : serviceExpenses;

			this.requesting.save = true;

			try {
				await service.update(
					payload, entryId
				);

				this.eventBus?.emit(
					'Toast/add',
					{
						content: `${this.viewDictionary.Entry} atualizada com sucesso`,
						type: 'success'
					}
				);

				this.$emit('entry-updated');

				this.requesting.save = false;

				const maybeDate = this.luxon?.now ? this.luxon.now() : new Date();

				await this.fetchMonthsSummary(maybeDate);
			} catch (err) {
				console.error(err);

				this.eventBus?.emit(
					'Toast/add',
					{
						content: `Falha ao atualizar ${this.viewDictionary.entry}`,
						type: 'error'
					}
				);

				this.requesting.save = false;
			}
		},

		async show(): Promise<void> {
			this.resetModel();
			await this.loadEntry(Number(this.entryId));
			(this.$refs.modal as typeof ModalBasic).show();
		},

		validate(): ValidationResult {
			return (this.$refs.incomeExpenseAddBlock as typeof IncomeExpenseAddBlock)
				.validate();
		},

		// Events

		buttonCancelClick(): void {
			this.resetModel();
			(this.$refs.modal as typeof ModalBasic).hide();
		},

		buttonSaveClick(): void {
			const { isValid } = this.validate();
			if (isValid) this.save();
		},

		async incomeExpenseAddBlock_attachmentRemove(item: Attachment): Promise<void> {
			await this.attachmentRemove(item);
		},

		async incomeExpenseAddBlock_attachmentsUpdateFiles(
			files: FinancialAttachment[]
		): Promise<void> {
			if (files.length > 0) await this.attachmentAdd(files);
		},

		incomeExpenseAddBlock_modelUpdate(model: EntryBlockModel): void {
			if (this.$refs.incomeExpenseAddBlock) {
				this.model = model;
			}
		},

		incomeExpenseAddBlock_mounted(): void {
			const isModelEmpty = Object.keys(this.model).length < 1;

			if (!isModelEmpty) {
				(this.$refs.incomeExpenseAddBlock as typeof IncomeExpenseAddBlock
				).setModel(this.model);
			}
		},

		modal_hide(): void {
			if (!this.backRoute) {
				this.$router.back();
			} else {
				this.$router.push(this.backRoute);
			}
		},

	},
});
</script>

<template>
	<ModalBasic
		ref="modal"

		:size="requesting.loadEntry ? 'md' : 'xl'"
		:title="`Visualizar ou editar ${viewDictionary.entry}`"

		@hide="modal_hide"
	>
		<template #content>
			<div class="px-2">
				<Loading
					v-if="requesting.loadEntry"
					class="opacity-50 py-7 text-center"
				/>
				<IncomeExpenseAddBlock
					v-else
					ref="incomeExpenseAddBlock"

					class="mb-4 last:mb-0"
					:dictionary="viewDictionary"
					:loaded-attachments="loadedAttachments"
					:type="viewType"
					:show-side-actions="false"

					show-advanced-options
					:default-back-route="modalBackRoute"

					@attachment-remove="incomeExpenseAddBlock_attachmentRemove"
					@attachments-updatefiles="incomeExpenseAddBlock_attachmentsUpdateFiles"
					@model-update="incomeExpenseAddBlock_modelUpdate"

					@vue:mounted="incomeExpenseAddBlock_mounted"
				/>
			</div>
		</template>
		<template #footer>
			<div class="flex-1 flex overflow-hidden rounded-b-md">
				<button
					class="flex-1 bg-gray-200 font-medium p-4 text-gray-500 hover:bg-gray-300 active:bg-gray-200"
					@click="buttonCancelClick"
				>
					CANCELAR
				</button>
				<button
					class="flex-1 bg-primary font-medium p-4 text-white hover:bg-blue-600 active:bg-primary"
					@click="buttonSaveClick"
				>
					SALVAR
				</button>
			</div>
		</template>
	</ModalBasic>
</template>

<style lang="scss" scoped></style>