<script lang="ts">
import DataTable from '../../../../components/DataTable/DataTable.vue';
import TagsDisplay from '../../../../components/Tags/TagsDisplay.vue';

import {
	defineComponent, inject, PropType
} from 'vue';
import {
	Filters,
	EventBus,
	Luxon,
	LegalCase,
	Task,
	SingleComplexFilter,
	FilterLabels,
	FilterItem,
	DataTableColumn,
	LegalCasePaginationResponse,
	FunnelStep,
} from '../../../../models/crm';
import {
	mapState, mapActions
} from 'pinia';
import { useAccountStore } from '../../../../stores/account';
import { useLegalCasesStore } from '../../../../stores/models/crm/legalCases';

export default defineComponent({
	components: {
		DataTable,
		TagsDisplay,
	},
	provide() {
		return {
			filters: this.filters as Filters,
		};
	},
	props: {
		legalCases: {
			required: true,
			type: Object as PropType<LegalCasePaginationResponse>,
		},
		requestingPagination: {
			required: true,
			type: Boolean,
		},
		selectedFunnel: {
			type: Object as PropType<{label: string; id: string | number;}>,
			default: null,
		}
	},
	emits: [
		'case-deleted',
		'saved-configuration',
		'next-page',
		'go-to-page',
		'search-changed',
		'order-apply',
		'filter-changed'
	],
	data() {
		return {

			dataTableLegalCases: {
				columns: [
					{
						key: 'name', label: 'Nome', initial: true, required: true
					},
					{
						key: 'contacts', label: 'Contatos', initial: true
					},
					{
						key: 'case_type', label: 'Fluxo', initial: true
					},
					{
						key: 'step', label: 'Etapa', initial: true
					},
					{
						key: 'tasks', label: 'Pendências', initial: true
					},
					{
						key: 'updated_at', label: 'Últ. movimentação', initial: true
					},

					{
						key: 'created_at', label: 'Criado em'
					},
					{
						key: 'description', label: 'Descrição'
					},
				] as DataTableColumn[],
				data: [] as LegalCase[],
				options: {
					columnCssClass: {
						'name': 'text-[rgb(0,123,255)] td-link',
						'tasks': 'text-center',
						'updated_at': 'text-center',
					},
					initial: {
						orderBy: {
							column: 'name',
							sort: 'asc',
						},
					},
					orderableColumns: [
						'name',
						'tasks',
						'updated_at',
						'created_at',
					],
					searchableColumns: [
						'name',
					],
				},
			},

			filters: {
				model: {
					funnel: (this.selectedFunnel?.label)
						? this.selectedFunnel
						: '',
					is_active: '',
					tasks: '',
				} as SingleComplexFilter,
				applied: {
					funnel: '',
					is_active: '',
					tasks: '',
				} as SingleComplexFilter,
				labels: {
					funnel: 'Fluxo de casos',
					is_active: 'Status',
					tasks: 'Pendências',

					with_open: 'Com tarefas em aberto',
					more_then_3: 'Com mais de 3 tarefas em aberto',
					without_open: 'Sem tarefas em aberto',
				} as FilterLabels,
				query: ''
			} as Filters,

			filtersListStatus: [
				{
					label: 'Ativo', value: 'true'
				},
				{
					label: 'Arquivado', value: 'false'
				},
			],

			filtersListTasks: [
				{
					label: 'Com tarefas em aberto', value: 'with_open'
				},
				{
					label: 'Com mais de 3 tarefas em aberto', value: 'more_then_3'
				},
				{
					label: 'Sem tarefas em aberto', value: 'without_open'
				},
			],

			requesting: {
				deleteLegalCase: false,
			},

			eventBus: inject<EventBus>('eventBus'),
			luxon: inject<Luxon>('luxon'),

		};
	},
	computed: {
		...mapState(useAccountStore, [ 'user' ]),

		filtersListCategories(): { label: string, value: string}[] {
			const ret = this.legalCases.legal_cases.filter(legalCase =>
				!!legalCase.funnel
			).reduce((acc, legalCase) => {
				const foundEl = acc.find(item => item.value === legalCase.funnel?.name);
				if (!foundEl) {
					return acc.concat([ {
						label: legalCase.funnel?.name || '',
						value: legalCase.funnel?.name || ''
					} ]);
				}

				return acc;
			}, [] as { label: string, value: string}[]);

			return ret;
		},

	},
	watch: {
		legalCases: {
			deep: true,
			handler: function(newState): void {
				this.dataTableLegalCases.data = newState.legal_cases;
			}
		},
	},
	beforeMount() {
		this.dataTableLegalCases.data = this.legalCases.legal_cases;
		this.$nextTick(() => this.selectedFunnel?.label && this.filtersApply());
	},
	methods: {
		...mapActions(useLegalCasesStore, [ 'deleteLegalCase' ]),

		async callDeleteLegalCase(id: string| number): Promise<void> {
			this.eventBus?.emit('BlockingOverlay/show', {
				text: 'Aguarde, excluindo caso'
			});

			try {
				this.requesting.deleteLegalCase = true;

				await this.deleteLegalCase(id);

				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Caso excluído com sucesso',
						type: 'success'
					}
				);

				this.$emit('case-deleted');

				this.requesting.deleteLegalCase = false;
			} catch (err) {
				console.error(err);

				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Erro ao excluir caso',
						type: 'error'
					}
				);
			} finally {
				this.eventBus?.emit('BlockingOverlay/hide');
			}
		},

		async filtersApply(): Promise<void> {
			const filtersApplied = this.filters.applied = {...this.filters.model};

			let filter = '';
			for (const key of Object.keys(filtersApplied)) {
				if (filtersApplied[key]?.length) {
					filter += `&${key}=${filtersApplied[key]}`; }
			}
			this.filters.query = filter;

			this.$emit('filter-changed', this.filters.query);
		},

		getPendingTasksQuantity(tasks: Task[]): number {
			return tasks.filter(item => item.done === false).length;
		},

		// Events

		dataTableLegalCases_deleteClick(_e: Event, id: string | number): void {
			this.callDeleteLegalCase(id);
		},

		dataTableLegalCases_filtersApply(): void {
			this.filtersApply();
		},

		dataTableLegalCases_filtersClear(): void {
			Object.entries(this.filters.model).forEach(
				([ key, value ]) => {
					if (Array.isArray(value)) {
						this.filters.model[key as 'funnel' | 'is_active'] = [];
					} else {
						this.filters.model[key as 'tasks'] = '';
					}
				}
			);

			this.filtersApply();
		},

		dataTableLegalCases_filtersItemRemove(
			filterItem:
				[ key: 'caseType' | 'is_active' | 'tasks', value: string | FilterItem<string>[] | FilterItem<boolean>[]]
		): void {
			if (!Array.isArray(filterItem)) return;

			const [ key, value ] = filterItem;

			if (Array.isArray(value)) {
				this.filters.model[key as 'funnel' | 'is_active'] = [];
			} else {
				this.filters.model[key as 'tasks'] = '';
			}

			this.filtersApply();
		},

		dataTableLegalCases_savedConfiguration(): void {
			this.$emit('saved-configuration');
		},

		dataTableLegalCases_tdClick(row: LegalCase): void {
			this.$router.push({
				name: 'legal-case-details', params: { id: row.id }
			});
		},
		dataTableLegalCases_nextPage(nextPage: number): void {
			this.$emit('next-page', nextPage);
		},
		dataTableLegalCases_goToPage(page: number): void {
			this.$emit('go-to-page', page);
		},
		dataTable_searchChanged(searchStr: string): void {
			this.$emit('search-changed', searchStr);
		},
		dataTable_orderApply(column: string, sort: string): void {
			this.$emit('order-apply', column, sort);
		}
	},
});
</script>
<template>
	<div
		:key="`mode-list-${selectedFunnel?.id}`"
		class="component-legal-cases-mode-list"
	>
		<DataTable
			id="data_table_legal_cases"
			:actions-column-show="true"
			:columns="dataTableLegalCases.columns"
			:data="dataTableLegalCases.data"
			:data-raw="legalCases.legal_cases"
			:max-data-length="legalCases.total_count"
			:loading-data="requestingPagination"
			:filters="filters"
			:options="dataTableLegalCases.options"
			:search-requisition="dataTable_searchChanged"

			@filters-apply="dataTableLegalCases_filtersApply"
			@filters-clear="dataTableLegalCases_filtersClear"
			@filters-item-remove="dataTableLegalCases_filtersItemRemove"
			@saved-configuration="dataTableLegalCases_savedConfiguration"
			@td-click="dataTableLegalCases_tdClick"
			@next-page="dataTableLegalCases_nextPage"
			@go-to-page="dataTableLegalCases_goToPage"
			@search-changed="dataTable_searchChanged"
			@order-apply="dataTable_orderApply"
		>
			<template #filters>

				<div class="filter-item">
					<label
						for="filter_selectFunnel"
						class="font-bold mb-4"
					>Fluxo de casos</label>
					<div class="border border-blue-500 p-3 rounded">
						<div
							v-for="item in filtersListCategories"
							:key="`filter_funnel_${item}`"
							class="form-control"
						>
							<label>
								<input
									v-model="filters.model.funnel"
									type="radio"
									class="radio radio-sm radio-primary align-middle"
									:value="item.value"
								>
								<span class="align-middle label-text ml-2">{{ item.label }}</span>
							</label>
						</div>
					</div>
				</div>

				<div class="mt-5 filter-item">
					<label
						for="filter_selectStatus"
						class="font-bold mb-4"
					>Status</label>
					<div class="border border-blue-500 p-3 rounded">
						<div
							v-for="item in filtersListStatus"
							:key="`filter_is_active_${item}`"
							class="form-control"
						>
							<label>
								<input
									v-model="filters.model.is_active"
									type="radio"
									class="radio radio-sm radio-primary align-middle"
									:value="item.value"
								>
								<span class="align-middle label-text ml-2">{{ item.label }}</span>
							</label>
						</div>
					</div>
				</div>

				<div class="mt-5 filter-item">
					<label
						for="filter_selectTasks"
						class="font-bold mb-4"
					>Pendências</label>
					<div class="border border-blue-500 p-3 rounded">
						<div
							v-for="item in filtersListTasks"
							:key="`filter_tasks_${item}`"
							class="form-control"
						>
							<label>
								<input
									v-model="filters.model.tasks"
									type="radio"
									class="radio radio-sm radio-primary align-middle"
									name="radioFilterTasks"
									:value="item.value"
								>
								<span class="align-middle label-text ml-2">{{ item.label }}</span>
							</label>
						</div>
					</div>
				</div>

			</template>
			<template #actions-list="{ row }">
				<button
					class="flex items-center mb-1 p-1 pr-2 rounded w-full whitespace-nowrap hover:bg-[#E5F0FB] last:mb-0"
					@click="dataTableLegalCases_deleteClick($event, row.id)"
				>
					<svg
						class="inline mr-1 w-4 h-4"
						fill="currentColor"
						viewBox="0 0 20 20"
						xmlns="http://www.w3.org/2000/svg"
					><path
						fill-rule="evenodd"
						d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
						clip-rule="evenodd"
					/></svg>
					<span class="text-sm">Excluir</span>
				</button>
			</template>

			<template #col_tasks="{ row }">
				{{ getPendingTasksQuantity(row.tasks) }}
			</template>
			<template #col_contacts="{ row }">
				<TagsDisplay
					:data="row.contacts"
					label="name"
					label-css-class="text-xs"
					:max-items="3"
				/>
			</template>
			<template #col_case_type="{ row }">
				{{ row?.funnel?.name || ''
				}}
			</template>
			<template #col_step="{ row }">
				{{ (row?.funnel_steps[0] as FunnelStep | undefined)
					?.name || ''
				}}
			</template>
			<template #col_updated_at="{ row }">
				{{ luxon?.fromISO(row.updated_at).toFormat("dd/MM/yyyy HH:mm") }}
			</template>
			<template #col_created_at="{ row }">
				{{ luxon?.fromISO(row.created_at).toFormat("dd/MM/yyyy HH:mm") }}
			</template>
		</DataTable>
	</div>
</template>
<style lang="scss" scoped></style>
