<script lang="ts">
import { defineComponent } from 'vue';
import { mapState } from 'pinia';

import { useAccountStore } from '../../../stores/account';

import SidebarCrm from '../../CRM/components/SidebarCrm.vue';
import { RouteLocationNormalizedLoaded } from 'vue-router';

export default defineComponent({
	components: {
		SidebarCrm,
	},
	computed: {
		...mapState(useAccountStore, [ 'office' ]),

		isOfficeAvailable(): boolean {
			return !!Object.keys(this.office).length;
		},
	},
	watch: {

		'$route': {
			handler(to): void {
				this.checkSeededAndRedirect(to);
			},
		},

		isOfficeAvailable: {
			handler(value): void {
				if (value) this.checkSeededAndRedirect(this.$route);
			},
			immediate: true,
		},

	},
	methods: {
		checkSeededAndRedirect(currentRoute: RouteLocationNormalizedLoaded): void {
			const { finances_seeded } = this.office;

			const isSeeded = !!finances_seeded;
			const isFinance = !!currentRoute.path.match(/\/financas/);
			const isSettings = !!currentRoute.path.match(/\/financas\/configuracoes/);

			/**
			 * Allow only configuration route and first access
			 * if not yet seeded. Force redirect to first acess
			 * for any other route
			 */
			if ((isFinance && !isSettings) && !isSeeded) {
				this.$router.replace({name: 'first-access'});
			}
		},

	},
});
</script>

<template>
	<div
		id="layout-finance"
		class="flex-1 flex min-h-0 min-w-0"
	>
		<sidebar-crm />
		<div
			v-if="isOfficeAvailable"
			class="flex-1 flex flex-col min-h-0 min-w-0"
		>
			<router-view />
		</div>
	</div>
</template>

<style lang="scss" scoped>
#layout-finance {
    height: inherit;
    width: inherit;
}
</style>
