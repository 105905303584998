<script lang="ts">
import DataTableBasic from './DataTableBasic.vue';
import ModalConfiguration from './ModalConfiguration.vue';
import SearchAndFilters from './SearchAndFilters.vue';
import { mapState } from 'pinia';
import { useAccountStore } from '../../stores/account';
import {
	defineComponent, inject, PropType, VueElement
} from 'vue';
import DOMPurify from 'dompurify';

import {
	EventBus,
	Lodash,
	Contact,
	LegalCase,
	Funnel,
	LegalCaseNote,
	Task,
	DataTableColumn,
	SinglePreference
} from '../../models/crm';
import {
	Category, CostCenter, ReceivedEntryBlock
} from '../../models/financial';

type BasicData = Contact | Funnel | Task | LegalCase
	| LegalCaseNote | ReceivedEntryBlock | Category | CostCenter;

export default defineComponent({
	components: {
		DataTableBasic,
		ModalConfiguration,
		SearchAndFilters,
	},
	props: {
		actionsColumnShow: {
			default: false,
			type: Boolean,
		},
		checkboxColumn: {
			default: false,
			type: Boolean,
		},
		columns: {
			type: Array as PropType<DataTableColumn[]>,
		},
		configButtonShow: {
			default: true,
			type: Boolean,
		},
		data: {
			type: Array as PropType<BasicData[]>,
			required: true,
		},
		dataRaw: Array as PropType<BasicData[]>,
		maxDataLength: Number,
		loadingData: Boolean,
		id: {
			type: String,
		},
		options: {
			default: () => ({}),
			type: Object,
		},
		paginationRowTopShow: {
			default: false,
			type: Boolean,
		},
		searchAndFiltersShow: {
			default: true,
			type: Boolean,
		},
		searchFiltersAndOptionsButtonSelectTitle: {
			default: '',
			type: String,
		},
		searchFiltersAndOptionsTitle: {
			default: 'Filtros e opções',
			type: String,
		},
		searchInputPlaceholder: {
			default: 'Digite para pesquisar',
			type: String,
		},
		searchRequisition: {
			type: Function,
			default: null
		},
		searchTitle: {
			default: 'Busca',
			type: String,
		},
	},
	emits: [
		'filters-apply',
		'filters-clear',
		'filters-item-remove',
		'search-changed',
		'td-click',
		'next-page',
		'go-to-page',
		'saved-configuration',
		'order-apply'
	],
	data() {
		return {

			currentOrderBy: {
				column: '' as string,
				sort: 'desc' as 'asc' | 'desc',
			},

			currentPage: 1 as number,

			searchAndFilters: {
				search: '' as string,
			},

			dataFilteredBySearch: this.data as BasicData[],

			requisitionId: 0 as number,

			eventBus: inject<EventBus>('eventBus'),

			lodash: inject<Lodash>('lodash'),

		};
	},
	watch: {
		data: {
			deep: true,
			immediate: true,
			handler(newValue: BasicData[]): void {
				this.dataFilteredBySearch = newValue;
			}
		},
		async 'searchAndFilters.search'(newValue, oldValue): Promise<void> {
			if (!this.searchAndFiltersShow) return;

			this.requisitionId += 1;

			const initialReqId = this.requisitionId;

			this.currentPage = 1;

			if (this.searchRequisition && initialReqId === this.requisitionId && newValue !== oldValue) {
				setTimeout(() => {
					if (initialReqId == this.requisitionId) {
						this.$emit('search-changed', newValue);
					}
				}, 200);


				return;
			}

			const ret = this.data.filter(
				row => {
					return this.options.searchableColumns.some(
						(columnName: string) => {
							const [ column, columnProp ] = columnName.split(':');
							if (column && columnProp) {
								let columnValue = row[column as keyof (typeof row)];

								if (!newValue) return true;

								if (Array.isArray(columnValue)) {
									if (columnProp) {
										// eslint-disable-next-line @typescript-eslint/ban-ts-comment
										// @ts-ignore
										columnValue = columnValue.map(item => item[columnProp as keyof (typeof item)]);
									}

									// eslint-disable-next-line @typescript-eslint/ban-ts-comment
									// @ts-ignore
									return columnValue?.some(
										(item: string | Array<unknown>) => {
											if (typeof item == 'string') {
												this.lodash
													?.deburr(item.toLowerCase())
													.includes(this.lodash.deburr(newValue.toLowerCase()));
											}
										}
									);
								}

								if (columnValue && typeof columnValue == 'string') {
									if (columnValue.length > 0) {
										return this.lodash
											?.deburr(columnValue.toLowerCase())
											.includes(this.lodash.deburr(newValue.toLowerCase()));
									}
								}
							}

							return false;
						}

					);
				}
			);

			this.dataFilteredBySearch = ret;
		}
	},
	computed: {
		...mapState(useAccountStore, [ 'user' ]),

		navigateButtonsToDisplay(): number[] {
			const buttonsToDisplay = [] as number[];

			if (this.currentPage < 6) {
				for (let i = 1;i <= this.currentPage;i++) {
					buttonsToDisplay.push(i);
				}

				if (this.currentPage === this.totalPages) return buttonsToDisplay;

				if (this.totalPages - this.currentPage > 4) {
					buttonsToDisplay.push(this.currentPage + 1);
					buttonsToDisplay.push(this.currentPage + 2);
					buttonsToDisplay.push(0);
					buttonsToDisplay.push(this.totalPages);

					return buttonsToDisplay;
				}


				for (let i = this.currentPage+1;i <= this.totalPages;i++) {
					buttonsToDisplay.push(i);
				}

				return buttonsToDisplay;
			}

			buttonsToDisplay.push(1);
			buttonsToDisplay.push(0);
			buttonsToDisplay.push(this.currentPage - 2);
			buttonsToDisplay.push(this.currentPage - 1);
			buttonsToDisplay.push(this.currentPage);

			if (this.currentPage === this.totalPages) return buttonsToDisplay;

			if (this.totalPages - this.currentPage > 4) {
				buttonsToDisplay.push(this.currentPage + 1);
				buttonsToDisplay.push(this.currentPage + 2);
				buttonsToDisplay.push(0);
				buttonsToDisplay.push(this.totalPages);

				return buttonsToDisplay;
			}


			for (let i = this.currentPage+1;i <= this.totalPages;i++) {
				buttonsToDisplay.push(i);
			}

			return buttonsToDisplay;
		},

		dataOrdered() : BasicData[] {
			return this.dataFilteredBySearch;
		},

		dataCurrentPage() : BasicData[] {
			let startingRecordIndex;

			if (this.itemsPerPage * (this.currentPage - 1) >= this.dataOrdered.length) {
				startingRecordIndex = 0;
			} else {
				startingRecordIndex = this.itemsPerPage * (this.currentPage - 1);
			}

			const endingRecordIndex = startingRecordIndex + this.itemsPerPage;

			return this.dataOrdered
				.slice(startingRecordIndex, endingRecordIndex);
		},

		dataTableUserPreferences(): SinglePreference | null {
			if (this.id) {
				const preferenceId = `ui:${this.id}`;
				const userPreferences = this.user?.preferences;
				return (userPreferences && userPreferences[preferenceId]) || null;
			} else {
				return null;
			}
		},

		itemsPerPage(): number {
			const defaultValue = 25;
			const dtPreferences = this.dataTableUserPreferences;

			if (dtPreferences) {
				return (dtPreferences.recordsPerPage as number) || defaultValue;
			} else {
				return defaultValue;
			}
		},

		pagesLength(): number {
			return Math.ceil((this.dataRaw?.length || this.itemsPerPage) / this.itemsPerPage);
		},

		totalPages(): number {
			if (this.dataRaw && this.data.length < this.dataRaw.length) {
				return Math.ceil((this.data.length || this.itemsPerPage) / this.itemsPerPage);
			}
			return Math.ceil((this.maxDataLength || this.itemsPerPage) / this.itemsPerPage);
		},

		searchableColumns(): string[] {
			return this.options?.searchableColumns.map((key: string) => key.includes(':') ? key.split(':')[1] : key) || [];
		},

		isMaxPage(): boolean {
			return this.maxDataLength && this.dataRaw
				? (this.dataFilteredBySearch.length < this.dataRaw.length
					? (
						this.dataOrdered.length <= this.itemsPerPage * this.currentPage
						&& this.dataOrdered.length > this.itemsPerPage * (this.currentPage - 1)
					)
					: this.maxDataLength <= this.itemsPerPage * this.currentPage
				)
				: (
					this.dataFilteredBySearch.length <= this.itemsPerPage * this.currentPage
					&& this.dataFilteredBySearch.length > this.itemsPerPage * (this.currentPage - 1)
				);
		},
	},
	beforeMount() {
		this.currentOrderBy.column = this.options?.initial?.orderBy?.column || '';
		this.currentOrderBy.sort = this.options?.initial?.orderBy?.sort || '';
	},
	mounted() {
		/**
         * Listen to document click and section.feature scroll
         * to hide any showing actions list dropdown
         */
		document.addEventListener('click', this.documentClick);
		document.querySelector('section.feature')?.addEventListener('scroll', this.sectionFeatureScroll);
	},
	methods: {

		getColumnShowState(column: DataTableColumn): boolean {
			if (!this.dataTableUserPreferences
				|| (this.dataTableUserPreferences.columns as string[] | undefined)?.length === 0
			) {
				return column.initial || false;
			} else {
				return (this.dataTableUserPreferences.columns as string[] | undefined)
					?.includes(column.key)
					|| (column.required || false);
			}
		},

		hideAllActionsListDropdowns(): void {
			const elDropdownAll = document.querySelectorAll('.dropdown-actions-list');

			elDropdownAll.forEach(
				el => {
					el.classList.add('hidden');
					el.classList.remove('block');
				}
			);
		},

		sanitizeData(data: string | number | undefined): string | number | undefined {
			if (typeof data === 'string') {
				const sanitizedData = DOMPurify.sanitize(data, {
					ALLOWED_TAGS: [ 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'ol', 'li' ],
					ALLOWED_ATTR: []
				});
				return sanitizedData;
			}

			return data;
		},

		// Events

		containerDataTableScroll(): void {
			this.hideAllActionsListDropdowns();
		},

		documentClick(): void {
			this.hideAllActionsListDropdowns();
		},

		buttonActionsListDotsClick(_e: Event, row: BasicData): void {
			const buttonRef = `buttonActionsList_${row.id}`;
			const dropdownRef = `dropdownActionsList_${row.id}`;

			const elButton = (this.$refs[buttonRef] as VueElement[])[0];
			const elDropdown = (this.$refs[dropdownRef] as VueElement[])[0];

			if (elButton && elDropdown?.style) {
				const {
					left: buttonLeft,
					top: buttonTop,
				} = elButton.getBoundingClientRect();

				elDropdown.style.left = `${buttonLeft}px`;
				elDropdown.style.top = `${buttonTop}px`;

				setTimeout(
					() => {
						elDropdown.classList.toggle('hidden');
						elDropdown.classList.toggle('block');
					},
					100,
				);
			}
		},

		buttonConfigClick(): void {
			(this.$refs.modalConfiguration as (typeof ModalConfiguration)).show();
		},

		buttonPaginationPreviousClick(): void {
			this.currentPage = this.currentPage - 1;
		},

		buttonPaginationNextClick(): void {
			this.currentPage = this.currentPage + 1;

			this.$emit('next-page', this.currentPage);
		},

		buttonThSortClick(_e: Event, column: string): void {
			if (this.currentOrderBy.column === column) {
				if (this.currentOrderBy.sort === 'asc') {
					this.currentOrderBy.sort = 'desc';
				} else {
					this.currentOrderBy.sort = 'asc';
				}
			} else {
				this.currentOrderBy.column = column;

				this.currentOrderBy.sort = 'asc';
			}

			this.$emit('order-apply', this.currentOrderBy.column, this.currentOrderBy.sort);
		},

		searchAndFilters_searchInput(value: string): void {
			if (!this.searchAndFiltersShow) return;

			this.currentPage = 1;

			this.searchAndFilters.search = value;
		},

		tdClick(_e: Event, row: BasicData, column: DataTableColumn): void {
			if (this.options.columnCssClass
				&& this.options.columnCssClass[column.key]?.includes('td-link')
			) this.$emit('td-click', row);
		},

		sectionFeatureScroll(): void {
			this.hideAllActionsListDropdowns();
		},

		emitFilterAction(action: () => void): void {
			if (!this.searchAndFiltersShow) return;

			this.currentPage = 1;

			action();
		},

		filterRemove(filterItem: unknown): void {
			if (!this.searchAndFiltersShow) return;

			this.currentPage = 1;
			this.$emit('filters-item-remove', filterItem);
		},

		goToPage(page: number): void {
			this.currentPage = page;

			if (this.maxDataLength
				&& this.dataOrdered.length <= (page-1) * this.itemsPerPage
				&& this.maxDataLength > (page-1) * this.itemsPerPage
			) {
				this.$emit('go-to-page', page);
			}
		}
	},
});
</script>
<template>
	<div
		:class="[$attrs.class, 'component-data-table']"
		:data-data-table-id="id"
	>
		<slot name="title" />
		<div
			v-if="searchAndFiltersShow"
			class="flex w-full m-0 p-0"
		>
			<SearchAndFilters
				:columns="columns"
				:filters-and-options-button-select-title="searchFiltersAndOptionsButtonSelectTitle"
				:filters-and-options-title="searchFiltersAndOptionsTitle"
				:input-placeholder="searchInputPlaceholder"
				:search-title="searchTitle"
				:searchable-columns="searchableColumns"
				config-button-show

				@filters-apply="emitFilterAction(() => $emit('filters-apply'))"
				@filters-clear="emitFilterAction(() => $emit('filters-clear'))"
				@filters-item-remove="filterRemove"
				@search-input="searchAndFilters_searchInput"
				@config-button-click="buttonConfigClick"
			>
				<template
					v-if="$slots.filters"
					#filters
				>
					<slot name="filters" />
				</template>
			</SearchAndFilters>
		</div>

		<div
			v-if="data.length === 0 || (dataOrdered.length === 0 && !loadingData)"
			class="bg-white p-2 rounded text-center text-sm no-records"
		>
			<slot name="no-records-content">
				Não há registros para os filtros e opções definidas
			</slot>
		</div>

		<div
			v-if="loadingData"
			class="bg-white p-2 rounded text-center text-sm text-wait-generating"
		>
			<slot name="loading-data">
				<svg
					class="animate-spin inline mr-3 h-5 w-5 text-gray-500"
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 24 24"
				>
					<circle
						class="opacity-25"
						cx="12"
						cy="12"
						r="10"
						stroke="currentColor"
						stroke-width="4"
					/>
					<path
						class="opacity-75"
						fill="currentColor"
						d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
					/>
				</svg>
				<span>Aguarde. Carregando dados!</span>
			</slot>
		</div>

		<div
			v-if="paginationRowTopShow && dataOrdered.length > 0 && !loadingData"
			class="mb-1 mt-2 py-1 rounded container-pagination-row"
		>
			<div class="flex items-center justify-between">
				<div class="flex items-center gap-2 container-data-table-title-config">
					<div class="inline-flex items-center">
						<span class="font-semibold">
							<slot name="title" />
						</span>
						<button
							v-if="configButtonShow"
							class="ml-1 opacity-50"
							title="Configurar a quantidade de registros por página e os campos (colunas) mostrados na tabela"
							@click="buttonConfigClick"
						>
							<svg
								class="w-6 h-6"
								fill="currentColor"
								viewBox="0 0 20 20"
								xmlns="http://www.w3.org/2000/svg"
							><path
								fill-rule="evenodd"
								d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
								clip-rule="evenodd"
							/></svg>
						</button>
					</div>
				</div>
				<div class="container-data-table-custom-actions">
					<slot name="custom-actions" />
				</div>
				<div class="container-data-table-pagination-nav">
					<button
						class="btn btn-sm btn--ghost disabled:opacity-50"
						:disabled="currentPage < 2 || loadingData"
						@click="buttonPaginationPreviousClick"
					>
						<svg
							class="stroke-blue-600 w-5 h-5"
							fill="none"
							stroke="currentColor"
							viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg"
						><path
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-width="2"
							d="M10 19l-7-7m0 0l7-7m-7 7h18"
						/></svg>
					</button>
					<button
						class="btn btn-sm btn--ghost disabled:opacity-50 ml-3"
						:disabled="isMaxPage || loadingData"
						@click="buttonPaginationNextClick"
					>
						<svg
							class="stroke-blue-600 w-5 h-5"
							fill="none"
							stroke="currentColor"
							viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg"
						><path
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-width="2"
							d="M14 5l7 7m0 0l-7 7m7-7H3"
						/></svg>
					</button>
				</div>
			</div>
		</div>

		<div
			v-if="!loadingData"
			class="overflow-visible container-data-table"
			@scroll="containerDataTableScroll"
		>
			<DataTableBasic class="w-full">
				<template #thead>
					<tr v-if="!$slots.thead && dataOrdered.length > 0">
						<th
							v-if="checkboxColumn"
							class="leading-4 py-3"
						>
							<input
								type="checkbox"
								checked
								class="checkbox checkbox-xs mt-1 rounded"
							>
						</th>
						<template
							v-for="column in columns"
							:key="JSON.stringify(column.key)"
						>
							<th
								v-if="getColumnShowState(column)"
								class="leading-4 whitespace-nowrap"
								:class="options?.orderableColumns?.includes(column.key)
									&& column.key === currentOrderBy.column
									&& 'cursor-pointer'
								"
							>
								<div class="flex items-center justify-between w-full">

									<span
										v-if="!options?.orderableColumns?.includes(column.key)"
										class="flex-1 p-3 text-left whitespace-normal"
									>{{ column.label }}</span>
									<button
										v-else
										class="flex-1 p-3 text-left text-[rgb(63,135,245)]"
										@click="buttonThSortClick($event, column.key)"
									>
										<span
											class="align-middle mr-1"
										>{{ column.label }}</span>
										<span
											v-if="column.key === currentOrderBy.column"
											class="align-middle"
										>
											<svg
												v-if="currentOrderBy.sort === 'asc'"
												class="inline w-6 h-6"
												fill="none"
												stroke="currentColor"
												viewBox="0 0 24 24"
												xmlns="http://www.w3.org/2000/svg"
											><path
												stroke-linecap="round"
												stroke-linejoin="round"
												stroke-width="2"
												d="M19 9l-7 7-7-7"
											/></svg>
											<svg
												v-else
												class="inline w-6 h-6"
												fill="none"
												stroke="currentColor"
												viewBox="0 0 24 24"
												xmlns="http://www.w3.org/2000/svg"
											><path
												stroke-linecap="round"
												stroke-linejoin="round"
												stroke-width="2"
												d="M5 15l7-7 7 7"
											/></svg>
										</span>
									</button>

								</div>
							</th>
						</template>
						<th
							v-if="actionsColumnShow"
							class="leading-4 py-3"
						/>
					</tr>
					<slot
						v-else
						name="thead"
					/>
				</template>
				<template #tbody>
					<template v-if="!$slots.tbody">
						<tr
							v-for="row in dataCurrentPage"
							:key="JSON.stringify(row)"
						>
							<td
								v-if="checkboxColumn"
								class="align-middle text-center col-selection"
							>
								<input
									type="checkbox"
									checked
									class="checkbox checkbox-xs mt-1 rounded"
								>
							</td>
							<template
								v-for="column in columns"
								:key="JSON.stringify(`${column.key}_${column.label}`)"
							>
								<td
									v-if="getColumnShowState(column)"
									:data-key="column.key"
									:class="[options.columnCssClass && options.columnCssClass[column.key]]"
									@click="tdClick($event, row, column)"
								>
									<slot
										v-if="$slots['col_' + column.key]"
										:name="`col_${column.key}`"
										:row="row"
									/>
									<span
										v-else
										v-html="sanitizeData(row[column.key as keyof (typeof row)])"
									/>
								</td>
							</template>
							<td
								v-if="actionsColumnShow"
								class="text-center min-w-[40px] w-10 h-full p-0 col-actions cursor-pointer"
								@click="buttonActionsListDotsClick($event, row)"
							>
								<div
									:ref="`buttonActionsList_${row.id}`"
									class="flex items-center justify-center"
								>
									<svg
										height="20"
										width="20"
										fill="none"
										stroke="currentColor"
										viewBox="0 0 24 24"
										xmlns="http://www.w3.org/2000/svg"
									><path
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="2"
										d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
									/></svg>
								</div>
								<div
									:ref="`dropdownActionsList_${row.id}`"
									class="absolute hidden z-50 dropdown-actions-list"
								>
									<div class="absolute bg-white border p-2 right-0 rounded shadow-lg">
										<slot
											name="actions-list"
											:row="row"
										/>
									</div>
								</div>
							</td>
						</tr>
					</template>
					<slot name="tbody" />
				</template>
				<template #tfoot>
					<slot name="tfoot" />
				</template>
			</DataTableBasic>
		</div>

		<div
			v-if="dataOrdered.length > 0 && !loadingData"
			class="mt-[5px] rounded container-pagination-row"
		>
			<div class="flex items-center justify-between">
				<div class="container-data-table-pagination-select text-xs">
					Exibindo de
					<strong class="font-semibold">{{ 1 + (currentPage-1)*itemsPerPage }}</strong>
					a <strong class="font-semibold">{{
						maxDataLength
							? Math.min(maxDataLength, itemsPerPage*currentPage)
							: itemsPerPage * currentPage
					}}</strong>
					de <strong class="font-semibold">{{ maxDataLength }}</strong>
				</div>
				<div class="container-data-table-pagination-nav flex items-center">
					<button
						v-if="currentPage >= 2 && !loadingData"
						class="appearance-none disabled:opacity-50 hover:bg-[#dcdedf] rounded px-[3px] py-[4px]"
						:disabled="currentPage < 2 || loadingData"
						title="Voltar para a página anterior"

						@click="buttonPaginationPreviousClick"
					>
						<svg
							viewBox="0 0 24 24"
							class="fill-[rgb(59,66,77)] w-5 h-5 rotate-90"
						>
							<path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
						</svg>
					</button>
					<template v-for="(page,index) in navigateButtonsToDisplay">
						<button
							v-if="page > 0 && !loadingData"
							:key="`nav-button-${index}`"
							:disabled="loadingData"
							:class="currentPage === page && 'bg-[#c2c4c6] font-semibold'"
							class="appearance-none disabled:opacity-50 hover:bg-[#dcdedf] w-[26px] h-7 rounded px-[3px] py-[4px] text-xs"
							@click="goToPage(page)"
						>
							{{ page }}
						</button>
						<span
							v-if="page === 0 && !loadingData"
							:key="`nav-span-${index}`"
							class="select-none px-[3px] py-[4px] text-xs"
						>
							...
						</span>
					</template>
					<button
						v-if="!isMaxPage && !loadingData"
						class="appearance-none disabled:opacity-50 hover:bg-[#dcdedf] rounded px-[3px] py-[4px]"
						:disabled="isMaxPage || loadingData"
						title="Ir para a próxima página"

						@click="buttonPaginationNextClick"
					>
						<svg
							viewBox="0 0 24 24"
							class="fill-[rgb(59,66,77)] w-5 h-5 -rotate-90"
						>
							<path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
						</svg>
					</button>
				</div>
			</div>
		</div>

	</div>

	<ModalConfiguration
		v-if="columns && id"
		ref="modalConfiguration"

		:columns="columns"
		:data-table-id="id"
		title="Configurações da tabela"

		@saved-configuration="$emit('saved-configuration')"
	/>

</template>
<style lang="scss" scoped>
.component-data-table {
    :deep(.component-data-table-basic) {

        tr {
            border-bottom: 1px solid #EFEFEF;
        }

        thead {
            th {
                background-color: #fff;
                border-right: 0;
                color: rgb(118, 138, 170);
                font-size: 12px;
				line-height: 18px;
				font-weight: 600;
                text-transform: uppercase;

                &:last-child {
                    border-right: none;
                }

                button {
                    text-transform: uppercase;
                }

                svg {
                    height: 16px;
                    width: 16px;
                }

				&.cursor-pointer{
					&:hover{
						background-color:rgba(63,135,245,0.08);
						border-color: rgba(63,135,245,0.08);
					}
					&:focus{
						background-color: rgba(63,135,245,0.08);
						border-color: rgba(63,135,245,0.08);
					}
					&:active{
						background-color: rgba(63,135,245,0.08);
						border-color: rgba(63,135,245,0.08);
					}
				}
			}
        }


        tbody, tfoot {
            tr {

                &:not(.no-records) {
                    .td-link:hover {
						cursor: pointer;
                        background-color: #F8F8F8;
						text-decoration: underline;

                        input {
                            cursor: initial;
                        }
                    }
                }

                &:last-child {
                    border-bottom: none;
                }

                td {
                    background-color: #fff;
                    border-right: none;
					font-weight: 600;
					font-size: 12px;
					line-height: 18px;

                    &:not(.col-actions) {
                        padding: 12px;
                    }

					&.col-actions:hover {
						background-color: #F8F8F8 !important;
					}
                }

            }
        }

    }

}
</style>