<script lang="ts">
import SidebarCrm from '../components/SidebarCrm.vue';
import { defineComponent } from 'vue';

export default defineComponent({
	components: {
		SidebarCrm,
	}
});
</script>

<template>
	<div id="layout-crm"
		class="flex-1 flex min-h-0 min-w-0"
	>
		<sidebar-crm />
		<div class="flex-1 flex flex-col min-h-0 min-w-0">
			<router-view />
		</div>
	</div>
</template>

<style lang="scss" scoped>
#layout-crm {
    height: inherit;
    width: inherit;
}
</style>