<script lang="ts">
import serviceFunnels from '../../../../services/API/crm/funnels';
import serviceLegalCases from '../../../../services/API/crm/legalCases';

import Loading from '../../../../components/Loading/LoadingComponent.vue';
import ModalConfirm from '../../../../components/Modal/ModalConfirm.vue';
import ArchiveButton from '../../components/LegalCaseDetails/ArchiveButton.vue';

import ModalAddAttachment from '../../components/LegalCaseDetails/ModalAddAttachment.vue';
import ModalAddNote from '../../components/LegalCaseDetails/ModalAddNote.vue';
import ModalLegalCase from './ModalLegalCase.vue';

import DetailsView from '../../components/Common/DetailsView.vue';

import {
	defineComponent, inject
} from 'vue';
import {
	EventBus,
	Helpers,
	Lodash,
	Luxon,
	LegalCase,
	FunnelStep,
	Contact,
	Funnel,
	LegalCaseAttachment,
	EventHistoryItem,
	CaseNoteHistoryItem,
	LegalCaseNote,
	DataType,
	InfoItem,
} from '../../../../models/crm';

import {
	mapState, mapActions
} from 'pinia';
import { useAccountStore } from '../../../../stores/account';
import { useFunnelsStore } from '../../../../stores/models/crm/funnels';
import { useLegalCasesStore } from '../../../../stores/models/crm/legalCases';


export default defineComponent({
	components: {
		Loading,
		ModalConfirm,
		ArchiveButton,

		ModalAddAttachment,
		ModalAddNote,
		ModalLegalCase,

		DetailsView,
	},
	provide() {
		return {
			filters: {},
		};
	},
	beforeRouteEnter(to, _from, next) {
		next(
			async vm => {
				if (to.name === 'edit-legal-case') {
					await (vm.$refs.modalLegalCase as typeof ModalLegalCase).show();
				}

				if (to.name === 'add-attachment-to-case') {
					await (vm.$refs.modalAddAttachment as typeof ModalAddAttachment).show();
				}

				if (to.name === 'add-note-to-case') {
					await (vm.$refs.modalAddNote as typeof ModalAddNote).show();
				}

				if (to.name === 'update-case-note') {
					await (vm.$refs.modalEditNote as typeof ModalAddNote).show();
				}

				if (to.name === 'add-task-to-case') {
					await (vm.$refs.detailsView as typeof DetailsView).openTaskModal();
				}
			}
		);
	},
	data() {
		return {
			eventBus: inject<EventBus>('eventBus'),
			lodash: inject<Lodash>('lodash'),
			helpers: inject<Helpers>('helpers'),
			luxon: inject<Luxon>('luxon'),

			attachments: [] as LegalCaseAttachment[],

			dataType: DataType.LEGAL_CASE,

			funnelSteps: null as FunnelStep[] | null,

			loadedCase: null as LegalCase | null,

			detailsData: {
				basicInfoItems: [] as InfoItem[],
				moreInfoItems: [] as InfoItem[],
			},

			requesting: {
				loadAttachments: false,
				loadCase: false,
				loadCaseTypes: false,
				loadFunnelSteps: false,
				setStatus: false,
				loadFunnels: false,
				deleteLegalCase: false,
			},
		};
	},
	computed: {
		...mapState(useAccountStore, [ 'user', 'officeUsers', 'office' ]),
		...mapState(useFunnelsStore, [ 'funnelsList', 'isRequestingFunnels' ]),
		...mapState(useLegalCasesStore, [ 'legalCasesList' ]),

		currentFunnelStep(): FunnelStep | null {
			return (this.loadedCase?.funnel_steps && this.loadedCase?.funnel_steps[0]) || null;
		},

		currentFunnelStepId(): number | string | null {
			return this.currentFunnelStep?.id || null;
		},

		urlParamId(): string {
			return typeof this.$route.params.id === 'string' ? this.$route.params.id : '';
		},

		noteToEdit(): LegalCaseNote | undefined {
			const noteId = typeof this.$route.params.noteId === 'string' ? this.$route.params.noteId : undefined;

			if (!noteId) return;

			return this.loadedCase?.case_notes?.find(item => item.id === Number(noteId));
		},
	},
	watch: {
		user: {
			deep: true,
			immediate: true,
			handler(newValue, oldValue): void {
				if (newValue && newValue !== oldValue) {
					this.loadAttachments();
					this.loadCase();
				}
			}
		},
	},
	methods: {
		...mapActions(useFunnelsStore, [ 'loadFunnels' ]),

		...mapActions(useLegalCasesStore, [ 'loadLegalCases', 'deleteLegalCase' ]),

		changeFunnelStep({
			legal_case: legalCase, step: funnelStep
		}: {
			legal_case: LegalCase,
			step: FunnelStep
		}): Promise<void> {
			return new Promise(
				(resolve, reject) => {
					this.eventBus?.emit('BlockingOverlay/show');

					serviceFunnels.dragLegalCaseToFunnelStep({
						funnelStepId: funnelStep.id,
						legalCaseId: legalCase.id,
					}).then(() => {
						this.eventBus?.emit('BlockingOverlay/hide');

						resolve();
					}).catch(err => {
						console.error(err);

						this.eventBus?.emit(
							'Toast/add',
							{
								content: 'Erro ao alterar etapa do fluxo',
								type: 'error'
							}
						);

						reject();
					});
				}
			);
		},

		getCaseTypeText(id: string | number | undefined): string {
			const ret = '';

			if (this.funnelsList) {
				const foundType = this.funnelsList.find((funnel: Funnel) => funnel.id === id);

				if (foundType) {
					return foundType.name;
				} else {
					return ret;
				}
			} else {
				return ret;
			}
		},

		getFormattedPhone(phone: string): string {
			if (!phone) return '';

			const areaCode = phone.substr(0, 2);
			const number = phone.substr(2);

			const numberFormatted = number.length === 8 ? `${number.slice(0, 4)}-${number.slice(4, 8)}` : `${number.slice(0, 5)}-${number.slice(5, 9)}`;

			return `(${areaCode}) ${numberFormatted}`;
		},

		getMainEmail(row: Contact): string {
			if (row.emails && row.emails.length > 0) {
				const emailObj = row.emails.find(item => item.is_main === true) || row.emails[0];

				return emailObj?.email || '';
			}

			// Legacy field
			if (row.email) return row.email;

			return '';
		},

		getMainPhone(row: Contact): string {
			if (row.phones && row.phones.length > 0) {
				const phoneObj = row.phones.find(item => item.is_main === true) || row.phones[0];

				return (phoneObj?.area_code && phoneObj.number) ? `${phoneObj?.area_code}${phoneObj?.number}`: '';
			}

			// Legacy fields
			if (row.phone) return row.phone;
			if (row.phone2) return row.phone2;

			return '';
		},

		async loadAttachments(forceReload = false): Promise<void> {
			const caseId = this.urlParamId;

			if (!caseId) return;

			this.requesting.loadAttachments = true;

			try {
				const notParsed = sessionStorage.getItem(`legal-case-${caseId}-attachments`);

				const storedCaseAttachment = notParsed && JSON.parse(notParsed);

				if (storedCaseAttachment && !forceReload) {
					this.attachments = storedCaseAttachment.attachments;
				} else {
					const { data } = await serviceLegalCases.attachmentsGetAll(caseId);

					sessionStorage.setItem(`legal-case-${caseId}-attachments`, JSON.stringify(data));

					this.attachments = data.attachments;
				}
			} catch (err) {
				console.error(err);
			}

			this.requesting.loadAttachments = false;
		},

		async loadCase(forceReload = false): Promise<void> {
			const caseId = this.urlParamId;

			if (!caseId) return;

			this.requesting.loadCase = true;

			try {
				const storedCase = this.legalCasesList.find(item => item.id === Number(caseId));

				if (storedCase?.id && !forceReload) {
					this.loadedCase = storedCase;
				} else {
					const { data } = await serviceLegalCases.getById(caseId);

					this.loadedCase = data;
				}

				const moreInfoItems = [];
				if (this.loadedCase?.case_number) moreInfoItems.push({
					label: 'Número do caso',
					value: this.loadedCase?.case_number || '',
					isHtml: false,
				});
				if (this.loadedCase?.funnel?.name) moreInfoItems.push({
					label: 'Fluxo',
					value: this.loadedCase?.funnel?.name || '',
					isHtml: false,
				});
				if (this.currentFunnelStep?.name) moreInfoItems.push({
					label: 'Etapa',
					value: this.currentFunnelStep?.name || '',
					isHtml: false,
				});
				if (this.loadedCase?.description) moreInfoItems.push({
					label: 'Descrição',
					value: this.loadedCase?.description || '',
					isHtml: true,
				});

				this.detailsData = {
					basicInfoItems: [
						{
							label: 'Contatos',
							value: this.loadedCase?.contacts || [],
							isHtml: false,
						},
						{
							label: 'Responsável',
							value: this.loadedCase?.assignees || [],
							isHtml: false,
						}
					],
					moreInfoItems
				};

				if (this.funnelsList.length === 0 && !this.isRequestingFunnels)
					await this.callLoadFunnels(true);

				if (!this.loadedCase?.funnel_steps) return;

				const funnelId = this.loadedCase.funnel_steps.length > 0
					? this.loadedCase.funnel_steps[0]?.funnel_id || 0
					: 0;

				await this.loadFunnelSteps(funnelId);
			} catch (err) {
				console.error(err);

				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Falha ao carregar caso',
						type: 'error'
					}
				);
			} finally {
				this.requesting.loadCase = false;
			}
		},

		async updateInfo(): Promise<void> {
			await this.loadAttachments(true);
			await this.loadCase(true);
		},

		async callLoadFunnels(forceReload = false): Promise<void> {
			try {
				this.requesting.loadFunnels = true;

				if (this.funnelsList.length > 0 && !forceReload) {
					this.requesting.loadFunnels = false;

					return;
				}

				await this.loadFunnels();

				this.requesting.loadFunnels = false;
			} catch (err) {
				console.error(err);
			}
		},

		async loadFunnelSteps(funnelId: string | number): Promise<void> {
			if (!funnelId) return;

			try {
				const {data} = await serviceFunnels.getById(funnelId);

				this.funnelSteps = [ ...data.funnel_steps ].sort((a, b) => a.order - b.order);
			} catch (err) {
				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Erro ao carregar Etapas do Fluxo de Casos',
						type: 'error'
					}
				);
			} finally {
				this.requesting.loadFunnelSteps = false;
			}
		},

		async removeEventHistoryItem(itemData: EventHistoryItem): Promise<void> {
			const {
				id: itemId, type
			} = itemData;

			const legalCaseId = Number(this.urlParamId);

			let overlayText;
			let successText;
			let errorText;
			let serviceFunction;
			const serviceFunctionParams = {
				legalCaseId, attachmentId: 0, noteId: 0
			};

			if (type === 'attachment') {
				overlayText = 'Aguarde, excluindo anexo';
				successText = 'Anexo excluído com sucesso';
				errorText = 'Falha ao excluir anexo';
				serviceFunction = serviceLegalCases.attachmentDelete;
				serviceFunctionParams.attachmentId = itemId;
			}

			if (type === 'note') {
				overlayText = 'Aguarde, excluindo anotação';
				successText = 'Anotação excluída com sucesso';
				errorText = 'Falha ao excluir anotação';
				serviceFunction = serviceLegalCases.noteDelete;
				serviceFunctionParams.noteId = itemId;
			}

			if (!serviceFunction) return;

			this.eventBus?.emit('BlockingOverlay/show', {
				text: overlayText,
			});


			try {
				await serviceFunction(serviceFunctionParams);

				this.eventBus?.emit(
					'Toast/add',
					{
						content: successText,
						type: 'success'
					}
				);

				this.loadAttachments();
				this.loadCase(true);
			} catch (err) {
				console.error(err);

				this.eventBus?.emit(
					'Toast/add',
					{
						content: errorText,
						type: 'error'
					}
				);

				return;
			} finally {
				this.eventBus?.emit('BlockingOverlay/hide');
			}
		},

		async callDeleteLegalCase(id: string| number): Promise<void> {
			this.eventBus?.emit('BlockingOverlay/show', {
				text: 'Aguarde, excluindo caso'
			});

			try {
				this.requesting.deleteLegalCase = true;

				await this.deleteLegalCase(id);

				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Caso excluído com sucesso',
						type: 'success'
					}
				);

				this.$router.push({name: 'legal-cases'});

				this.requesting.deleteLegalCase = false;
			} catch (err) {
				console.error(err);

				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Erro ao excluir caso',
						type: 'error'
					}
				);
			} finally {
				this.eventBus?.emit('BlockingOverlay/hide');
			}
		},

		// Events

		buttonAddNoteClick(): void {
			this.$router.push({
				name: 'add-note-to-case', params: { id: this.urlParamId }
			});
		},

		buttonAddTaskClick(): void {
			this.$router.push({
				name: 'add-task-to-case', params: { id: this.urlParamId }
			});
		},

		buttonAddAttachmentClick(): void {
			this.$router.push({
				name: 'add-attachment-to-case', params: { id: this.urlParamId }
			});
		},

		buttonEditNoteClick(historyItem: CaseNoteHistoryItem): void {
			this.$router.push({
				name: 'update-case-note',
				params: {
					id: this.urlParamId, noteId: historyItem.id
				}
			});
		},

		// eslint-disable-next-line @typescript-eslint/no-empty-function
		buttonAddCalcClick(): void {

		},

		buttonContactNameClick(_e: Event, contact: { name: string, id: number|string}): void {
			const contactId = contact.id;
			this.$router.push({
				name: 'specific-contact', params: { id: contactId }
			});
		},

		buttonEditClick(): void {
			this.$router.push({
				name: 'edit-legal-case', params: { id: this.urlParamId }
			});
		},

		buttonDeleteClick(): void {
			(this.$refs.modalDeleteCase as typeof ModalConfirm).show();
		},

		modalDeleteCase_noClick(): void {
			(this.$refs.modalDeleteCase as typeof ModalConfirm).hide();
		},

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		buttonFunnelStepClick(_e: Event, inData: Record<string, any>): void {
			(this.$refs.modalConfirmChangeFunnelStep as typeof ModalConfirm).show(inData);
		},

		async toggleStatus_checkedChange(): Promise<void> {
			const newStatus = this.loadedCase?.is_active || false;

			try {
				this.requesting.setStatus = true;

				await serviceLegalCases.setStatus({
					id: this.urlParamId,
					status: !newStatus,
				});
			} catch (err) {
				console.error(err);
			} finally {
				this.requesting.setStatus = false;

				this.loadCase(true);
				(this.$refs.modalConfirmArchiveCase as typeof ModalConfirm).hide();
			}
		},

		modalAddAttachment_attachmentCreated(): void {
			this.loadAttachments(true);
			this.loadCase(true);
		},

		modalAddNote_noteCreated(): void {
			this.loadCase(true);
		},

		modalAddNote_noteUpdated(): void {
			this.loadCase(true);
		},

		modalConfirmChangeFunnelStep_noClick(): void {
			(this.$refs.modalConfirmChangeFunnelStep as typeof ModalConfirm).hide();
		},

		async modalConfirmChangeFunnelStep_yesClick(data : {
			legal_case: LegalCase,
			step: FunnelStep
		}): Promise<void> {
			await this.changeFunnelStep(data);
			(this.$refs.modalConfirmChangeFunnelStep as typeof ModalConfirm).hide();
			this.loadCase(true);
		},

		openConfirmArchiveCaseModal(): void {
			(this.$refs.modalConfirmArchiveCase as typeof ModalConfirm).show();
		},

		modalConfirmArchiveCase_noClick(): void {
			(this.$refs.modalConfirmArchiveCase as typeof ModalConfirm).hide();
		},

		modalLegalCase_caseUpdated(): void {
			this.loadCase(true);
		},
	},
});
</script>
<template>
	<details-view
		ref="detailsView"
		:data-type="dataType"
		:is-loading="!loadedCase || requesting.loadCase"
		:breadcrumbs="[
			{ label: 'Gestão', to: '/crm' },
			{ label: 'Casos', to: '/crm/casos' },
			{ label: loadedCase?.name || 'Carregando...' }
		]"
		:basic-info-items="detailsData.basicInfoItems"
		:more-info-items="detailsData.moreInfoItems"
		:all-tasks="loadedCase?.tasks || []"
		:case-notes="loadedCase?.case_notes || []"
		:versions="loadedCase?.versions || []"
		:attachments="attachments"
		@btn-edit-click="buttonEditClick"
		@btn-delete-click="buttonDeleteClick"
		@btn-add-note-click="buttonAddNoteClick"
		@btn-add-task-click="buttonAddTaskClick"
		@btn-add-attachment-click="buttonAddAttachmentClick"
		@btn-edit-note-click="buttonEditNoteClick"
		@info-item-click="buttonContactNameClick"
		@should-update-info="updateInfo"
	>
		<template #page-title>
			{{ loadedCase?.name }}
		</template>
		<template #header-buttons>
			<ArchiveButton
				v-if="loadedCase"
				:checked="loadedCase.is_active"
				:disabled="requesting.setStatus"

				@checked-change="openConfirmArchiveCaseModal"
			/>
		</template>
		<template #under-basic-info>
			<hr class="mb-3 opacity-50">

			<!-- Case type + Funnel Step -->
			<Loading
				v-if="requesting.loadFunnelSteps"
				class="py-2 text-center"
				icon-css-class="h-8 w-8 mr-3"
				text="Carregando etapas"
			/>
			<div
				v-if="!requesting.loadFunnelSteps && funnelSteps"
				class="flex gap-3 items-center justify-start mb-3 overflow-x-auto"
			>
				<span>{{ loadedCase?.funnel?.name }}</span>
				<span>➔</span>
				<span class="inline-flex text-center container-funnel-steps">
					<button
						v-for="(step, index) in funnelSteps"
						:key="`funnel-steps-${index}`"
						:class="['funnel-step', { active: step.id === currentFunnelStepId }]"
						:title="step.name"

						@click="buttonFunnelStepClick($event, { legal_case: loadedCase, step })"
					>
						{{ step.name }}
					</button>
				</span>
			</div>
		</template>
	</details-view>

	<ModalLegalCase
		ref="modalLegalCase"
		:back-route="{name: 'legal-case-details', params: {id: urlParamId}}"
		:case-id="urlParamId"

		@case-updated="modalLegalCase_caseUpdated"
	/>

	<ModalAddNote
		ref="modalAddNote"
		:entity-id="urlParamId"
		note-type="legalCase"

		@note-created="modalAddNote_noteCreated"
	/>

	<ModalAddNote
		ref="modalEditNote"
		:entity-id="urlParamId"
		is-edit
		:note="noteToEdit"
		note-type="legalCase"

		@note-updated="modalAddNote_noteUpdated"
	/>

	<ModalAddAttachment
		ref="modalAddAttachment"
		:legal-case-id="urlParamId"

		@attachment-created="modalAddAttachment_attachmentCreated"
	/>

	<ModalConfirm
		ref="modalConfirmChangeFunnelStep"
		:overlay-click-dismiss="true"
		size="sm"
		title="Mudar etapa do fluxo"

		@no-click="modalConfirmChangeFunnelStep_noClick"
		@yes-click="modalConfirmChangeFunnelStep_yesClick"
	>
		<template #content>
			<div class="p-4">
				Tem certeza que deseja mover o caso para outra etapa?
			</div>
		</template>
	</ModalConfirm>

	<ModalConfirm
		ref="modalConfirmArchiveCase"
		size="md"
		:title="loadedCase?.is_active ? 'Arquivar caso' : 'Desarquivar caso'"

		@no-click="modalConfirmArchiveCase_noClick"
		@yes-click="toggleStatus_checkedChange"
	>
		<template #content>
			<div class="p-4">
				<div
					v-if="loadedCase?.is_active"
					class="text-center"
				>
					<h2 class="p-2 font-semibold text-lg">
						Você tem certeza que deseja arquivar esse Caso?
					</h2>

					<p class="p-2">
						Caso queira desarquivar no futuro, basta clicar novamente neste botão.
					</p>
				</div>
				<p
					v-else
					class="text-center"
				>
					Tem certeza que deseja desarquivar este caso?
				</p>
			</div>
		</template>
	</ModalConfirm>

	<ModalConfirm
		ref="modalDeleteCase"
		size="md"
		title="Excluir Caso"

		@no-click="modalDeleteCase_noClick"
		@yes-click="callDeleteLegalCase(urlParamId)"
	>
		<template #content>
			<div class="p-4">
				<p class="text-center m-0">
					Tem certeza que deseja excluir este caso?
				</p>
			</div>
		</template>
	</ModalConfirm>
</template>
<style lang="scss" scoped>
$high-percent: calc(100% - 0.5rem);
$low-percent: calc(0% + 0.5rem);
.container-funnel-steps {
	.funnel-step {
        -webkit-clip-path: polygon($high-percent 0%, 100% 50%, $high-percent 100%, 0% 100%, $low-percent 50%, 0% 0%);
        clip-path: polygon($high-percent 0%, 100% 50%, $high-percent 100%, 0% 100%, $low-percent 50%, 0% 0%);

        background-color: #ddd;
        display: inline-block;
        font-size: 0.8rem;
        margin-left: -4px;
        opacity: 0.5;
        overflow: hidden;
        padding: 0.3rem 1.25rem 0.3rem 0.75rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        min-width: 7rem;
		max-width: 13rem;

        &:first-child {
            -webkit-clip-path: polygon(0% 0%, $high-percent 0, 100% 50%, $high-percent 100%, 0% 100%);
            clip-path: polygon(0% 0%, $high-percent 0, 100% 50%, $high-percent 100%, 0% 100%);

            border-radius: 5px 0 0 5px;
            margin-left: initial;
        }

        &.active {
            background-color: theme('colors.green.600');
            color: #fff;
            font-weight: 500;
            opacity: 1;
        }
    }
}

.container-shortcuts {
    .shortcuts-label {
        writing-mode: vertical-lr;
    }
}

:deep(.component-history-item) {
    .log-title-container {
        background-color: #f0f0f0;
        border-radius: 4px;
        padding: 0.5rem;

        .log-changeset-row {
            &:not(:last-child) {
                margin-bottom: 0.5rem;
            }
            .log-key {
                background-color: #fff;
                border-radius: 4px;
                display: inline-block;
                margin-right: 0.25rem;
                padding: 0.25rem 0.5rem;
            }

            .log-changes {
                background-color: #f0f0f0;
                border-radius: 4px;
                display: inline-block;
                padding: 0.25rem 0.5rem;

                * {
                    display: inline-block;
                }
            }
        }


    }
}

</style>
