<script lang="ts">
import {
	defineComponent, inject
} from 'vue';

import {
	EventBus, FilterItem, Helpers, Lodash, Luxon
} from '../../../../models/crm';

import {
	mapActions, mapState
} from 'pinia';

import DataTable from '../../../../components/DataTable/DataTable.vue';
import DatePicker from '../../../../components/Form/DatePicker.vue';
import InputText from '../../../../components/Form/InputText.vue';
import Loading from '../../../../components/Loading/LoadingComponent.vue';
import SelectField from '../../../../components/Form/SelectField.vue';
import TagsDisplay from '../../../../components/Tags/TagsDisplay.vue';
import ModalNextEntries from './ModalNextEntries.vue';
import ModalIncomeExpense from '../../views/IncomesExpenses/ModalIncomeExpense.vue';

import {
	Category,
	EntryBlockType, NextTransaction, ReceivedEntryBlock
} from '../../../../models/financial';
import { useAccountStore } from '../../../../stores/account';
import { useEntriesStore } from '../../../../stores/models/financial/entries';
import { useDashboardStore } from '../../../../stores/models/financial/dashboard';
import { useCategoriesStore } from '../../../../stores/models/financial/categories';
import { DateTime } from 'luxon';
import { RouteLocationRaw } from 'vue-router';

export default defineComponent({
	components: {
		DataTable,
		DatePicker,
		InputText,
		Loading,
		SelectField,
		TagsDisplay,
		ModalIncomeExpense,
		ModalNextEntries
	},
	provide() {
		return {
			filters: this.filters,
		};
	},
	data() {
		return {

			eventBus: inject<EventBus>('eventBus'),
			helpers: inject<Helpers>('helpers'),
			lodash: inject<Lodash>('lodash'),
			luxon: inject<Luxon>('luxon'),

			// Injects
			backRoute: inject<RouteLocationRaw>('backRoute'),
			isViewTypeExpense: inject<boolean>('isViewTypeExpense'),
			isViewTypeIncome: inject<boolean>('isViewTypeIncome'),
			viewDictionary: inject<Record<string, string>>('viewDictionary'),
			viewType: inject<'income'|'expense'>('viewType'),

			dataTableEntries: {
				columns: [
					{
						key: 'description', label: 'Descrição', initial: true, required: true
					},
					{
						key: 'amount', label: 'Valor', initial: true, required: true
					},
					{
						key: 'due_date', label: 'Data base', initial: true, required: true
					},
					{
						key: 'payment_date', label: 'Data do pagamento', initial: true, required: true
					},
					{
						key: 'category', label: 'Categoria', initial: true
					},
					{
						key: 'cost_center', label: 'Centro de Custo', initial: true
					},
					{
						key: 'legal_case', label: 'Caso', initial: true
					},

					// {
					// 	key: 'contact_id', label: 'Contato'
					// },
					// {
					// 	key: 'note', label: 'Anotação'
					// },
					// {
					// 	key: 'invoice_file', label: 'Boleto'
					// },
					// {
					// 	key: 'attachments', label: 'Anexos'
					// },
				],
				data: [] as ReceivedEntryBlock[],
				dataRaw: [] as ReceivedEntryBlock[],
				options: {
					columnCssClass: {
						'description': 'text-blue-600 hover:underline w-1/3 td-link',
						'due_date': 'text-center',
					},
					initial: {
						orderBy: {
							column: 'description',
							sort: 'asc',
						},
					},
					orderableColumns: [
						'amount',
						'description',
					],
					searchableColumns: [
						'description',
					],
				},
				total_count: 100
			},

			order: {
				column: 'description',
				sort: 'asc'
			},

			filters: {
				model: {
					category_id: undefined as string | undefined,
					cost_center_id: undefined as string | undefined,
					due_at_from: '',
					due_at_to: '',
					amount_from: '',
				},
				applied: {},
				labels: {
					category_id: 'Categoria',
					cost_center_id: 'Centro de custo',
					due_at_from: 'Data base - a partir de',
					due_at_to: 'Data base - até',
					amount_from: 'Valor - a partir de',
				},
				query: ''
			},

			searchStr: '' as string,

			summary: {
				allNextEntries: [] as NextTransaction[],
				nextDate: '' as string,
				quantity: 0 as number,
				sum: 0 as number,
			},

			requesting: {
				deleteEntry: false,
				loadCategories: false,
				loadCostCenters: false,
				loadEntries: false,
				pagination: false,
				summary: false,
			},

		};
	},
	computed: {

		...mapState(useEntriesStore, [
			'entries',
			'maxEntries',
			'searchMaxEntries',
			'searchedEntries',
			'nextTransactions'
		]),

		...mapState(useAccountStore, [ 'user' ]),

		...mapState(useCategoriesStore, [
			'income_categories',
			'expense_categories',
			'cost_centers'
		]),
		...mapState(useDashboardStore, [ 'months_summary' ]),

		loadedCategories(): Category[] {
			return this.isViewTypeExpense
				? this.expense_categories
				: this.income_categories;
		},

		selectCategoriesOptionsList(): FilterItem<number>[] {
			return [ {
				label: 'Selecione uma Categoria',
				value: -1,
			} ].concat(this.loadedCategories.map(item => ({
				label: item.name,
				value: item.id,
			})));
		},

		selectCostCentersOptionsList(): FilterItem<number>[] {
			return [ {
				label: 'Selecione um Centro de Custo',
				value: -1,
			} ].concat(this.cost_centers.map(category => ({
				label: category.name,
				value: category.id,
			})));
		},

		urlParamEntryId(): string | undefined {
			return (this.$route.params.entryId as string | undefined);
		},

		entryType(): EntryBlockType {
			return this.isViewTypeExpense ? EntryBlockType.EXPENSES : EntryBlockType.INCOMES;
		},

		perPage(): number {
			const preferenceId = `ui:data_table_finance_${this.entryType.replace('s', '')}`;
			const userPreferences = this.user?.preferences;
			const specificPreference = userPreferences && userPreferences[preferenceId];

			return (specificPreference?.recordsPerPage as number | undefined) || 25;
		},

		currentRoute(): string {
			return this.$route.path;
		},

		currentDate(): DateTime | Date {
			return this.luxon?.now ? this.luxon.now() : new Date();
		},

		currentMonthNextEntries(): NextTransaction[] {
			return this.summary.allNextEntries.filter(entry => {
				const entryDate = this.luxon?.fromISO(entry.due_date);

				if (!entryDate?.isValid) return false;

				const currentMonth = (this.currentDate instanceof Date)
					? this.currentDate.getMonth()
					: this.currentDate.month;

				return entryDate.month === currentMonth;
			});
		}
	},
	watch: {
		entries: {
			deep: true,
			async handler(newValue, oldValue): Promise<void> {
				if (Array.isArray(newValue[this.entryType]) && Array.isArray(oldValue[this.entryType])
					&& newValue[this.entryType] !== oldValue[this.entryType]
					&& (newValue[this.entryType].length > 0 || oldValue[this.entryType].length > 0)
				) {
					await this.loadEntries();
				}
			}
		},

		async viewType(newValue, oldValue): Promise<void> {
			if (newValue && oldValue && newValue !== oldValue) {
				await this.initComponent();
			}
		},

		'$route.path'(newValue, oldValue): void {
			if (newValue && oldValue && newValue !== oldValue) {
				this.openDetailsModalFromUrl();
			}
		}
	},
	async beforeMount() {
		await this.initComponent();
	},
	mounted() {
		this.openDetailsModalFromUrl();
	},
	methods: {
		...mapActions(useEntriesStore, [
			'deleteEntry',
			'paginatedLoadEntries',
			'searchEntriesByDescription',
			'useNormalEntriesList',
		]),

		...mapActions(useDashboardStore, [ 'fetchMonthsSummary', 'getMonthsSummary' ]),

		...mapActions(useCategoriesStore, [
			'fetchIncomeCategories',
			'fetchExpenseCategories',
			'fetchCostCenters',
		]),

		async deleteById(id: number): Promise<void> {
			try {
				this.eventBus?.emit('BlockingOverlay/show');

				await this.deleteEntry(this.entryType, id);

				this.eventBus?.emit(
					'Toast/add',
					{
						content: `${this.viewDictionary?.Entry || 'Entrada'} excluída com sucesso`,
						type: 'success'
					}
				);

				await this.loadEntries();

				this.eventBus?.emit('BlockingOverlay/hide');

				this.requesting.summary = true;

				await this.fetchMonthsSummary(this.currentDate);
				this.loadSummary();

				this.requesting.summary = false;
			} catch (err) {
				console.error(err);

				this.eventBus?.emit(
					'Toast/add',
					{
						content: `Erro ao excluir ${this.viewDictionary?.entry || 'entrada'}`,
						type: 'error'
					}
				);

				this.eventBus?.emit('BlockingOverlay/hide');
			}
		},

		async filtersApply(): Promise<void> {
			const filtersApplied: Record<string, string|undefined>
				= this.filters.applied = {...this.filters.model};
			let filter = '';
			for (const key of Object.keys(filtersApplied)) {
				if (filtersApplied[key]?.length) {
					if (key === 'amount_from') {
						const amount = filtersApplied[key]?.replace('R$ ', '').replace('.', '').replace(',', '.');

						filter += `&${key}=${amount}`;
						continue;
					}
					filter += `&${key}=${filtersApplied[key]}`;
				}
			}

			this.filters.query = filter;

			await this.reloadEntries('Erro ao aplicar o filtro');
		},

		async loadCategories(forceReload = false): Promise<void> {
			this.requesting.loadCategories = true;

			const loadFunction = this.isViewTypeExpense
				? this.fetchExpenseCategories
				: this.fetchIncomeCategories;

			try {
				let response = true;

				if (!this.income_categories || forceReload) {
					response = await loadFunction('');
				}

				if (!response) throw new Error('Not possible to fetch categories');
			} catch (err) {
				console.error(err);
			} finally {
				this.requesting.loadCategories = false;
			}
		},

		async loadCostCenters(forceReload = false): Promise<void> {
			this.requesting.loadCostCenters = true;

			try {
				let response = true;

				if (!this.cost_centers || forceReload) {
					response = await this.fetchCostCenters('');
				}

				if (!response) throw new Error('Not possible to fetch cost centers');
			} catch (err) {
				console.error(err);
			} finally {
				this.requesting.loadCostCenters = false;
			}
		},

		async loadEntries(forceReload = false): Promise<void> {
			this.requesting.loadEntries = true;

			try {
				if (this.entries[this.entryType]
					&& this.entries[this.entryType].length > 0
					&& !forceReload
				) {
					this.dataTableEntries.data = this.dataTableEntries.dataRaw = this.entries[this.entryType];
					this.dataTableEntries.total_count = this.maxEntries[this.entryType];

					this.requesting.loadEntries = false;

					return;
				}

				const orderBy = `${this.order.column}:${this.order.sort}`;

				await this.paginatedLoadEntries(
					this.entryType,
					orderBy,
					this.filters.query,
					true,
					this.perPage,
					1,
				);

				this.dataTableEntries.data = this.dataTableEntries.dataRaw = this.entries[this.entryType];
				this.dataTableEntries.total_count = this.maxEntries[this.entryType];
			} catch (err) {
				console.error(err);

				this.eventBus?.emit(
					'Toast/add',
					{
						content: `Erro ao carregar ${this.viewDictionary?.entries || 'entradas'}`,
						type: 'error'
					}
				);
			} finally {
				this.requesting.loadEntries = false;
			}
		},

		async addEntriesToData(nextPage: number): Promise<void> {
			if (
				this.user?.office_id
				&& this.maxEntries[this.entryType] > this.perPage * (nextPage - 1)
				&& this.dataTableEntries.dataRaw.length === this.perPage * (nextPage - 1)
			) {
				this.requesting.pagination = true;

				const orderBy = `${this.order.column}:${this.order.sort}`;

				if (this.searchStr.length > 0) {
					try {
						await this.searchEntriesByDescription(
							this.entryType,
							this.searchStr,
							this.perPage,
							nextPage,
							orderBy,
							this.filters.query,
						);

						if (nextPage === 1) {
							this.dataTableEntries.data = this.dataTableEntries.dataRaw = [];
						}

						if (
							this.searchMaxEntries[this.entryType] > this.dataTableEntries.dataRaw.length
						) {
							this.dataTableEntries.data
								= this.dataTableEntries.dataRaw
								= this.searchedEntries[this.entryType];

							return;
						}

						this.eventBus?.emit(
							'Toast/add',
							{
								content: 'Página máxima atingida',
								type: 'error'
							}
						);
					} catch (err) {
						console.error(err);

						this.eventBus?.emit(
							'Toast/add',
							{
								content: 'Erro ao fazer a paginação',
								type: 'error'
							}
						);
					} finally {
						this.requesting.pagination = false;
					}

					return;
				}

				try {
					await this.paginatedLoadEntries(
						this.entryType,
						orderBy,
						this.filters.query,
						true,
						this.perPage,
						nextPage,
					);

					this.useNormalEntriesList(this.entryType);

					this.dataTableEntries.data
						= this.dataTableEntries.dataRaw
						= this.entries[this.entryType];

					this.dataTableEntries.total_count = this.maxEntries[this.entryType];
				} catch (err) {
					console.error(err);

					this.eventBus?.emit(
						'Toast/add',
						{
							content: 'Erro ao fazer a paginação',
							type: 'error'
						}
					);
				} finally {
					this.requesting.pagination = false;
				}
			}
		},
		async sequentialEntriesAddition(page: number): Promise<void> {
			const oldPage = Math.ceil(this.dataTableEntries.dataRaw.length / this.perPage);
			if (page <= oldPage || this.dataTableEntries.dataRaw.length % this.perPage !== 0) {
				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Erro ao fazer a paginação',
						type: 'error'
					}
				);

				return;
			}

			for (let i = oldPage + 1;i <= page;++i) {
				await this.addEntriesToData(i);
			}
		},
		async searchEntries(searchStr: string): Promise<void> {
			if (!searchStr) {
				await this.loadEntries();

				this.useNormalEntriesList(this.entryType);

				this.searchStr = searchStr;

				return;
			}

			this.searchStr = searchStr;

			if (this.user?.office_id) {
				try {
					const orderBy = `${this.order.column}:${this.order.sort}`;
					await this.searchEntriesByDescription(
						this.entryType,
						searchStr,
						25,
						1,
						this.filters.query,
						orderBy
					);

					if (this.searchStr === searchStr) {
						this.dataTableEntries.data
							= this.dataTableEntries.dataRaw
							= this.searchedEntries[this.entryType];
						this.dataTableEntries.total_count = this.searchMaxEntries[this.entryType];
					}
				} catch (err) {
					console.error(err);
					this.eventBus?.emit(
						'Toast/add',
						{
							content: 'Erro ao pesquisar contato',
							type: 'error'
						}
					);
				}
			}
		},

		async orderApply(column: string, sort: string): Promise<void> {
			this.order = {
				column, sort
			};

			await this.reloadEntries('Erro ao fazer a ordenação');
		},

		async reloadEntries(errorMessage: string): Promise<void> {
			const orderBy = `${this.order.column}:${this.order.sort}`;

			this.requesting.pagination = true;

			try {
				if (this.searchStr.length > 0) {
					await this.searchEntriesByDescription(
						this.entryType,
						this.searchStr,
						this.perPage,
						1,
						orderBy,
						this.filters.query,
					);

					this.dataTableEntries.data = this.dataTableEntries.dataRaw = [];

					this.dataTableEntries.total_count = 0;

					if (
						this.searchMaxEntries[this.entryType] > this.dataTableEntries.dataRaw.length
					) {
						this.dataTableEntries.data
								= this.dataTableEntries.dataRaw
								= this.searchedEntries[this.entryType];

						this.dataTableEntries.total_count = this.searchMaxEntries[this.entryType];

						return;
					}
				} else {
					this.useNormalEntriesList(this.entryType);

					await this.paginatedLoadEntries(
						this.entryType,
						orderBy,
						this.filters.query,
						true,
						this.perPage,
						1,
					);

					this.dataTableEntries.data
						= this.dataTableEntries.dataRaw
						= this.entries[this.entryType];

					this.dataTableEntries.total_count = this.maxEntries[this.entryType];
				}
			} catch (err) {
				console.error(err);

				this.eventBus?.emit(
					'Toast/add',
					{
						content: errorMessage,
						type: 'error'
					}
				);
			} finally {
				this.requesting.pagination = false;
			}
		},

		loadSummary(): void {
			const monthsSummary = this.getMonthsSummary(this.currentDate);

			if (this.entryType === EntryBlockType.INCOMES) {
				const incomesSum = Number(monthsSummary?.transactions_unpaid_due.amount?.Income || 0)
					+ Number(monthsSummary?.transactions_paid.amount?.Income || 0);

				const incomesCount = Number(monthsSummary?.transactions_unpaid_due.count?.Income || 0)
					+ Number(monthsSummary?.transactions_paid.count?.Income || 0);

				this.summary.sum = incomesSum;
				this.summary.quantity = incomesCount;
				this.summary.allNextEntries = this.nextTransactions.incomes;
			} else {
				const expensesSum = Number(monthsSummary?.transactions_unpaid_due.amount?.Expense || 0)
					+ Number(monthsSummary?.transactions_paid.amount?.Expense|| 0);

				const expensesCount = Number(monthsSummary?.transactions_unpaid_due.count?.Expense || 0)
					+ Number(monthsSummary?.transactions_paid.count?.Expense || 0);

				this.summary.sum = expensesSum;
				this.summary.quantity = expensesCount;
				this.summary.allNextEntries = this.nextTransactions.expenses;
			}

			if (!this.summary.allNextEntries?.length) {
				this.summary.nextDate = `Nenhuma ${this.viewDictionary?.entry || 'entrada'} até o fim do mês`;

				return;
			}

			this.summary.allNextEntries.sort((a, b) => {
				const aDate = this.luxon?.fromISO(a.due_date);
				const bDate = this.luxon?.fromISO(b.due_date);

				if (!aDate?.isValid || !bDate?.isValid) return 0;

				return aDate.diff(bDate).milliseconds;
			});

			const nextFutureDate = this.luxon?.fromISO(this.summary.allNextEntries[0]?.due_date || '');
			const diffDaysRounded = Number(nextFutureDate?.diffNow('days').days.toFixed(0) || 0);

			const daysLeftText =
                    (diffDaysRounded === 0 && ' (Hoje)') ||
                    (diffDaysRounded > 0 ? ` (${diffDaysRounded} ${diffDaysRounded > 1 ? 'dias' : 'dia'})` : '');
			this.summary.nextDate = `${nextFutureDate?.toFormat('dd/MM/yyyy')}${daysLeftText}`;
		},

		async openDetailsModalFromUrl(): Promise<void> {
			await this.$nextTick();

			if (this.urlParamEntryId)
				await (this.$refs.modalIncomeExpense as typeof ModalIncomeExpense).show();
		},

		redirectToEntryDetails(row: ReceivedEntryBlock): void {
			const { id } = row;

			if (this.isViewTypeIncome) {
				this.$router.push({
					name: 'income-details', params: {entryId: id}
				});
				return;
			}

			this.$router.push({
				name: 'expense-details', params: {entryId: id}
			});
			return;
		},

		mapPaymentDateLabel(): void {
			this.dataTableEntries.columns.map(column => {
				if (column.key === 'payment_date') {
					column.label = this.isViewTypeIncome ? 'Data do recebimento' : 'Data do pagamento';
				}
			});
		},

		async initComponent(): Promise<void> {
			await this.loadCategories();
			await this.loadCostCenters();
			await this.loadEntries(true);

			this.requesting.summary = true;

			if (Object.keys(this.months_summary).length === 0
			) {
				await this.fetchMonthsSummary(this.currentDate);
			}
			this.loadSummary();

			this.requesting.summary = false;

			this.mapPaymentDateLabel();
		},

		// Events

		buttonSummaryNextEntryClick(): void {
			(this.$refs.modalNextEntries as typeof ModalNextEntries).show();
		},

		dataTableEntries_deleteClick(id: number): void {
			this.deleteById(id);
		},

		dataTableEntries_filtersApply(): void {
			this.filtersApply();
		},

		dataTableEntries_filtersClear(): void {
			this.filters.model.cost_center_id = undefined;
			this.filters.model.category_id = undefined;
			this.filters.model.amount_from = '';
			this.filters.model.due_at_from = '';
			this.filters.model.due_at_to = '';

			this.filtersApply();
		},

		async dataTableEntries_filtersItemRemove(filterItem
			: [string, string | FilterItem<number> | undefined]
		): Promise<void> {
			const [ key, value ] = filterItem;
			// eslint-disable-next-line @typescript-eslint/no-explicit-any

			if (typeof value === 'string') {
				(this.filters.model[key as keyof (typeof this.filters.model)] as string) = '';
				await this.filtersApply();

				return;
			}

			(this.filters.model[
				key as keyof (typeof this.filters.model)
			] as FilterItem<number> | undefined)
				= undefined;
			await this.filtersApply();
		},

		dataTableEntries_orderApply(column: string, sort: string): void {
			this.orderApply(column, sort);
		},

		dataTableEntries_savedConfiguration(): void {
			this.loadEntries(true);
		},

		dataTableEntries_tdClick(row: ReceivedEntryBlock): void {
			this.redirectToEntryDetails(row);
		},

		async modalIncomeExpense_entryUpdated(): Promise<void> {
			(this.$refs.modalIncomeExpense as typeof ModalIncomeExpense).hide();
			await this.loadEntries(true);

			this.requesting.summary = true;

			await this.fetchMonthsSummary(this.currentDate);
			this.loadSummary();

			this.requesting.summary = false;
		},
	},
});
</script>

<template>

	<Loading
		v-if="
			requesting.deleteEntry || requesting.loadEntries
				|| requesting.loadCategories || requesting.loadCostCenters
		"

		class="opacity-75 py-14 text-center"
		icon-css-class="h-10 w-10 mr-3"
		text-css-class="text-2xl"
	/>
	<template v-else>

		<div class="bg-white/50 p-3 rounded">
			<template v-if="requesting.summary">
				<Loading />
			</template>
			<div
				v-else
				class="flex"
			>
				<div
					class="w-1/4"
					:title="`Soma de todas as ${viewDictionary?.entries || 'entradas'} previstas para o mês atual`"
				>
					<div class="font-semibold text-xs">
						Total previsto para o mês atual
					</div>
					<div
						:class="[
							'text-xl',
							{ 'text-red-500': isViewTypeExpense }, { 'text-green-600': isViewTypeIncome }
						]"
					>
						<span>{{ isViewTypeExpense ? '-' : '+' }}</span>
						<span class="ml-1">
							{{ helpers?.getFormattedCurrencyFromNumber(summary.sum) }}
						</span>
					</div>
				</div>
				<div
					class="border-l pl-5 w-1/4"
					:title="`Quantidade de ${viewDictionary?.entries || 'entradas'} do mês atual`"
				>
					<div class="font-semibold text-xs">
						{{ viewDictionary?.Entries || 'Entradas' }} do mês atual
					</div>
					<div class="text-xl">
						{{ summary.quantity }}
					</div>
				</div>
				<div class="border-l pl-5 w-2/4">
					<div class="font-semibold text-xs">
						Próximas {{ viewDictionary?.entries || 'entradas' }} do mês
					</div>
					<div class="font-thin text-xl">
						<span class="inline-flex items-center">
							<span>{{ summary.nextDate }}</span>
							<button
								v-if="summary.allNextEntries.length > 0"

								class="ml-2"
								:title="`Ver ou editar detalhes da próxima ${viewDictionary?.entry || 'entrada' }`"

								@click="buttonSummaryNextEntryClick"
							>
								<svg
									class="h-6 stroke-blue-500 w-6"
									fill="none"
									stroke="currentColor"
									stroke-width="1.5"
									viewBox="0 0 24 24"
									xmlns="http://www.w3.org/2000/svg"
									aria-hidden="true"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
									/>
								</svg>
							</button>
						</span>
					</div>
				</div>
			</div>
		</div>

		<DataTable
			:id="`data_table_finance_${entryType.replace('s', '')}`"
			ref="dataTableEntries"
			:actions-column-show="true"
			class="mt-4"
			:columns="dataTableEntries.columns"
			:data="dataTableEntries.data"
			:data-raw="dataTableEntries.dataRaw"
			:filters="filters"
			:options="dataTableEntries.options"
			:max-data-length="dataTableEntries.total_count"
			:loading-data="requesting.loadEntries || requesting.pagination"
			:search-requisition="searchEntries"
			search-input-placeholder="Pesquisar por descrição"

			@filters-apply="dataTableEntries_filtersApply"
			@filters-clear="dataTableEntries_filtersClear"
			@filters-item-remove="dataTableEntries_filtersItemRemove"
			@search-changed="searchEntries"
			@saved-configuration="dataTableEntries_savedConfiguration"
			@td-click="dataTableEntries_tdClick"
			@next-page="addEntriesToData"
			@go-to-page="sequentialEntriesAddition"
			@order-apply="dataTableEntries_orderApply"
		>
			<template #filters>

				<div class="filter-item mb-5">
					<label
						for="filter_selectCostCenter"
						class="font-bold mb-4"
					>Centro de Custo</label>
					<div>
						<SelectField
							v-model="filters.model.cost_center_id"
							class="w-full"
							label=""
							:options="selectCostCentersOptionsList"
						/>
					</div>
				</div>

				<div class="filter-item mb-5">
					<label
						for="filter_selectCostCenter"
						class="font-bold mb-4"
					>Categoria</label>
					<div>
						<SelectField
							v-model="filters.model.category_id"
							class="w-full"
							label=""
							:options="selectCategoriesOptionsList"
						/>
					</div>
				</div>

				<div class="filter-item mb-5">
					<label
						for=""
						class="font-bold mb-4"
					>Data base - a partir de</label>
					<div>
						<DatePicker
							v-model="filters.model.due_at_from"

							:arrow-navigation="true"
							:close-on-auto-apply="true"
							:enable-time-picker="false"
							format="dd/MM/yyyy"
							:keep-action-row="false"
						/>
					</div>
				</div>

				<div class="filter-item mb-5">
					<label
						for=""
						class="font-bold mb-4"
					>Data base - até</label>
					<div>
						<DatePicker
							v-model="filters.model.due_at_to"

							:arrow-navigation="true"
							:close-on-auto-apply="true"
							:enable-time-picker="false"
							format="dd/MM/yyyy"
							:keep-action-row="false"
						/>
					</div>
				</div>

				<div class="filter-item">
					<label
						for=""
						class="font-bold mb-4"
					>Valor - a partir de</label>
					<div>
						<InputText
							v-model="filters.model.amount_from"

							label=""
							placeholder="R$ 0,00"
							type="money"
						/>
					</div>
				</div>

			</template>
			<template #actions-list="{ row }">
				<button
					class="flex items-center mb-1 p-1 pr-2 rounded w-full whitespace-nowrap hover:bg-[#E5F0FB] last:mb-0"

					@click="dataTableEntries_deleteClick(row.id)"
				>
					<svg
						class="inline mr-1 w-4 h-4"
						fill="currentColor"
						viewBox="0 0 20 20"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fill-rule="evenodd"
							d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
							clip-rule="evenodd"
						/>
					</svg>
					<span class="text-sm">Excluir</span>
				</button>
			</template>
			<template #col_description="{ row }">
				<span
					:title="`Ver ou editar detalhes desta ${viewDictionary?.entry || 'entrada'}`"
					class="whitespace-nowrap"
				>
					{{ row.description ? row.description : 'Sem descrição' }}
				</span>
			</template>
			<template #col_amount="{ row }">
				<span
					:class="[
						{ 'text-red-500': isViewTypeExpense },
						{ 'text-green-600': isViewTypeIncome }
					]"
				>
					<span>{{ isViewTypeExpense ? '-' : '+' }}</span>
					<span class="ml-1">{{ helpers?.getFormattedCurrencyFromNumber(row.amount) }}</span>
				</span>
			</template>
			<template #col_due_date="{ row }">
				<span :title="luxon?.fromISO(row.due_date).toFormat('dd/MM/yyyy')">
					{{ luxon?.fromISO(row.due_date).toFormat('dd/MM/yyyy') }}
				</span>
			</template>
			<template #col_category="{ row }">
				<TagsDisplay
					v-if="isViewTypeExpense && row['expense_category'] && row['expense_category'].name"
					:data="[row['expense_category']]"
					label="name"
					label-css-class="text-xs"
					:max-items="1"
				/>
				<TagsDisplay
					v-if="isViewTypeIncome && row['income_category'] && row['income_category'].name"
					:data="[row['income_category']]"
					label="name"
					label-css-class="text-xs"
					:max-items="1"
				/>
			</template>
			<template #col_cost_center="{ row }">
				<TagsDisplay
					v-if="row.cost_center && row.cost_center.name"

					:data="[row.cost_center]"
					label="name"
					label-css-class="text-xs"
					:max-items="1"
				/>
			</template>
			<template #col_payment_date="{ row }">
				<span
					v-if="row.payment_date"
					:title="luxon?.fromISO(row.payment_date).toFormat('dd/MM/yyyy HH:mm')"
				>
					{{ luxon?.fromISO(row.payment_date).toFormat('dd/MM/yyyy') }}
				</span>
			</template>
			<template #col_legal_case="{ row }">
				<TagsDisplay
					v-if="row.legal_case && row.legal_case.name"
					:data="[row.legal_case]"
					label="name"
					label-css-class="text-xs"
					:max-items="1"
				/>
			</template>
			<template #col_attachments="{ row }">
				<span v-if="row.attachments && row.attachments.length > 0">
					{{ row.attachments.length }} anexo(s)
				</span>
				<span v-else>Sem anexos</span>
			</template>
		</DataTable>
	</template>

	<ModalIncomeExpense
		v-if="viewDictionary && viewType && urlParamEntryId"
		ref="modalIncomeExpense"

		:back-route="backRoute"
		:modal-back-route="currentRoute"
		:entry-id="urlParamEntryId"
		:view-dictionary="viewDictionary"
		:view-type="viewType"

		@entry-updated="modalIncomeExpense_entryUpdated"
	/>

	<ModalNextEntries
		v-if="currentMonthNextEntries.length > 0"
		ref="modalNextEntries"

		:next-entries="currentMonthNextEntries"
		:entry-type="viewType || 'income'"
	/>
</template>

<style lang="scss" scoped>
.container-filters-slot {
	.form-control-datepicker {
		:deep() {
			input {
				font-family: 'Montserrat', sans-serif;
			}
		}
	}
}
</style>