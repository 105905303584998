import axios from 'axios';
import storage from './services/storage';

const {
	VITE_API_URL,
} =
import.meta.env;

let accessToken = localStorage.getItem('cj-access-token') || storage.getItem('localStorage', 'access-token');
if (accessToken) {
	accessToken = accessToken.replace(/"/g, '');
}

const setDefaults = (): void => {
	axios.defaults.baseURL = VITE_API_URL;
	axios.defaults.headers.common = {
		... axios.defaults.headers.common,
		'Content-Type': 'application/json',
		'Accept': 'application/json',
	};

	if (accessToken) {
		axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
	}
};

export default {
	setDefaults,
};