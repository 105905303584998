/**
 * CRM - Store
 */

import { defineStore } from 'pinia';
import storage from '../services/storage';

export type CRMState = {
	sidebar: {
		isCollapsed: boolean;
	}
}

export const useCRMStore = defineStore('crm', {
	state: (): CRMState => ({
		sidebar: {
			isCollapsed: false,
		},
	}),
	actions: {
		setSidebarCollapse(value: boolean): void {
			this.sidebar.isCollapsed = value;

			storage.setItem('localStorage', 'crm/ui/sidebar/is_collapsed', value);
		},

		toggleSidebarCollapse(): void {
			this.sidebar.isCollapsed = !this.sidebar.isCollapsed;

			storage.setItem('localStorage', 'crm/ui/sidebar/is_collapsed', this.sidebar.isCollapsed);
		},
	},
});