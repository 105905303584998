<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
	props: {
		checked: {
			default: false,
			type: Boolean,
		},
		cssClassDisable: {
			default: 'border-error bg-red-100',
			type: String,
		},
		disabled: {
			default: false,
			type: Boolean,
		},
	},
	emits: [
		'checked-change',
	],
	data() {
		return {
			model: false as boolean,
		};
	},
	methods: {

		toggleState(): void {
			if (this.disabled) return;
			this.$emit('checked-change');
		},

	},
	beforeMount() {
		if (this.checked) {
			this.model = this.checked;
		}
	}
});
</script>
<template>
	<div
		:class="[
			'flex align-center border py-0 px-1 rounded text-center w-auto',
			model ? '' : cssClassDisable, {'opacity-50': disabled}
		]"
	>
		<label class="label cursor-pointer margin-auto">
			<button
				:title="model ? 'Clique para arquivar' : 'Clique para ativar'"
				:class="model ? 'text-gray-500' : 'text-red-600'"
				@click.stop.prevent="toggleState"
			>
				<svg v-if="model"
					xmlns="http://www.w3.org/2000/svg"
					height="24"
					viewBox="0 -960 960 960"
					width="24"
					fill="currentColor"
				><path d="m480-240 160-160-56-56-64 64v-168h-80v168l-64-64-56 56 160 160ZM200-640v440h560v-440H200Zm0 520q-33 0-56.5-23.5T120-200v-499q0-14 4.5-27t13.5-24l50-61q11-14 27.5-21.5T250-840h460q18 0 34.5 7.5T772-811l50 61q9 11 13.5 24t4.5 27v499q0 33-23.5 56.5T760-120H200Zm16-600h528l-34-40H250l-34 40Zm264 300Z" />
				</svg>
				<svg
					v-else
					xmlns="http://www.w3.org/2000/svg"
					height="24"
					viewBox="0 -960 960 960"
					width="24"
					fill="currentColor"
				><path d="M480-560 320-400l56 56 64-64v168h80v-168l64 64 56-56-160-160Zm-280-80v440h560v-440H200Zm0 520q-33 0-56.5-23.5T120-200v-499q0-14 4.5-27t13.5-24l50-61q11-14 27.5-21.5T250-840h460q18 0 34.5 7.5T772-811l50 61q9 11 13.5 24t4.5 27v499q0 33-23.5 56.5T760-120H200Zm16-600h528l-34-40H250l-34 40Zm264 300Z" />
				</svg>
			</button>
		</label>
	</div>
</template>
<style lang="scss" scoped></style>
