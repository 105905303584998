import {
	CostCenter,
	CostCenterResponse, CostCenterToSend
} from '../../../models/financial';
import axios, { AxiosResponse } from 'axios';

const pageParams = '&per_page=999999&page=1';

export default {

	create(data: CostCenterToSend): Promise<AxiosResponse<CostCenter>> {
		const path = '/finances/cost_centers.json';
		return axios.post(`${path}`, data);
	},

	update(id: number, data: CostCenterToSend): Promise<AxiosResponse<CostCenter>> {
		const path = `/finances/cost_centers/${id}.json`;
		return axios.put(path, data);
	},

	delete(id: number): Promise<AxiosResponse<null|undefined>> {
		const path = `/finances/cost_centers/${id}.json`;
		return axios.delete(path, {});
	},

	getAll(orderBy: string): Promise<AxiosResponse<CostCenterResponse>> {
		const path = `/finances/cost_centers.json?${ orderBy.length ? `order=${orderBy}` : '' }${pageParams}`;
		return axios.get(path);
	},

	searchByName(name: string, perPage = 50, nextPage = 1)
		: Promise<AxiosResponse<CostCenterResponse>> {
		const path = `/finances/cost_centers.json?search=${name}&per_page=${perPage}&page=${nextPage}`;
		return axios.get(path);
	},

};