<script lang="ts">
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import _ from 'lodash';
import { PDFDocument } from 'pdf-lib';
import { defineComponent } from 'vue';


export default defineComponent({
	data() {
		return {
			clickedLinks: [] as number[],

			docs: [] as PDFDocument[],

			docsAsBytes: [] as Uint8Array[],

			docsLinks: [] as string[],

			error: {
				message: '',
				show: false,
			},

			file: {
				fileReader: new FileReader(),
				inputField: {} as Blob,
				base64: '' as string | ArrayBuffer,
			},

			finishedGenerating: false,

			isGenerating: false,

			libs: {
				JSZip,
			},

			modalSelectPreset: {
				show: false,
			},

			model: {
				description: 'Meu arquivo PDF',
				intervals: [
					[ '', '' ],
				],
				namePrefix: 'meu_arquivo_pdf_',
				maxPagesPerFile: 2,
				maxSize: 512,
				maxSizeUnit: 'kb',
				splitBy: 'max_size',
			},

			presets: {} as Record<string, string>,
		};
	},
	computed: {

		isInputDataValid(): boolean {
			let counter = 0;

			if (!this.file.inputField) {
				counter++;
			}

			if (!this.file.base64) {
				counter++;
			}

			if (this.model.splitBy === 'max_size' && !this.model.maxSize) {
				counter++;
			}

			if (this.model.splitBy === 'pages_per_file' && !this.model.maxPagesPerFile) {
				counter++;
			}

			return counter === 0;
		},

		parsedPresets() {
			const ret = _
				.toPairs(this.presets)
				.map(
					item => {
						return {
							...this.getParsedPreset(item[1]),
							key: item[0]
						};
					}
				);

			return ret;
		},

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		parsedPresetsOrderedByDescription(): any[] {
			const ret = _.orderBy(this.parsedPresets, [ 'description' ], [ 'asc' ]);

			return ret;
		},
		maxSizeInBytes(): number {
			const {
				maxSize, maxSizeUnit
			} = this.model;
			const sizeInBytes = maxSizeUnit === 'kb' ? maxSize * 1024 : maxSize * 1024 * 1024;
			// adiciona uma margem pra lidar com imprecisões que fazem o tamanho do arquivo ficar maior que o limite
			const securityMargin = 0.85;
			return Math.round(sizeInBytes * securityMargin);
		},
	},
	methods: {

		async createAndAddNewDoc(): Promise<PDFDocument> {
			const newDoc: PDFDocument = await PDFDocument.create({});

			this.docs.push(newDoc);

			return newDoc;
		},

		createAndDownloadZipFile(): void {
			const zip = new JSZip();

			this.docsAsBytes.forEach(
				(item, index) => {
					zip.file(`${this.model.namePrefix}${index + 1}.pdf`, item);
				}
			);

			zip
				.generateAsync({ type: 'blob' })
				.then(
					(blob) => {
						saveAs(blob, `${this.model.namePrefix}_${Date.now()}.zip`);
					},
					(err) => {
						alert(err);
					}
				);
		},

		async generate(): Promise<void> {
			const {splitBy} = this.model;

			const getRange = (start: number, stop: number, step: number): number[] =>
				Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));

			try {
				this.isGenerating = true;

				const pdfOriginal = await PDFDocument.load(this.file.base64); // The original pdf
				// const pdfInitial = await pdfOriginal.copy(); // The pdf to get pages from
				const pages = pdfOriginal.getPages();

				let currentDoc = await this.createAndAddNewDoc();

				if (splitBy === 'pages_interval') {
					const validIntervals = this.getValidIntervals(this.model.intervals);

					for (let i = 0;i < validIntervals.length;i++) {
						if (i > 0) {
							currentDoc = await this.createAndAddNewDoc();
						}

						if (!currentDoc) continue;

						const intermediateArray = validIntervals[i];

						if (!intermediateArray) continue;

						if (!intermediateArray[1] || !intermediateArray[0]) continue;

						const copiedPage =
							await currentDoc.copyPages(
								pdfOriginal,
								getRange(intermediateArray[0] - 1,
									intermediateArray[1] - 1, 1)
							);

						for (let j = 0;j < copiedPage.length;j++) {
							currentDoc.addPage(copiedPage[j]);
						}
					}
				} else {
					for (let i = 0;i < pages.length;i++) {
						const index = i;

						let isBelowMax;

						if (!currentDoc) continue;

						if (splitBy === 'max_size') {
							isBelowMax = await this.isCurrentDocSizeLessThanMax(currentDoc);
						} else {
							isBelowMax = await this.hasCurrentDocLessPagesThanMax(currentDoc);
						}

						if (!isBelowMax) {
							currentDoc = await this.createAndAddNewDoc();
						}

						if (!currentDoc) continue;

						const copiedPage = await currentDoc.copyPages(pdfOriginal, [ index ]);

						currentDoc.addPage(copiedPage[0]);
					}
				}

				for (let i = 0;i < this.docs.length;i++) {
					const doc = this.docs[i];

					if (!doc) continue;

					const docBase64 = await doc.saveAsBase64({});
					const docBytes = await doc.save();

					this.docsAsBytes.push(docBytes);
					this.docsLinks.push(`data:application/pdf;base64,${ docBase64}`);
				}

				this.finishedGenerating = true;

				this.isGenerating = false;
			} catch (err) {
				console.error(err);
				this.error.message = 'Ocorreu um erro na geração dos arquivos. Possíveis causas: Seu arquivo original pode estar criptografado; Seu arquivo original pode estar protegido por senha; O intervalo de páginas pode não ser válido.';
				this.error.show = true;
			}
		},

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		getParsedPreset(value: string): any {
			return JSON.parse(atob(value));
		},

		getValidIntervals(intervals: (number | string)[][]): number[][] {
			return (intervals.filter(
				item => {
					if (item[0] && item[1] && Number.isInteger(item[0]) && Number.isInteger(item[1])) {
						if (item[0] <= item[1]) {
							return true;
						}
					}
					return false;
				}
			)) as number[][];
		},

		async isCurrentDocSizeLessThanMax(doc: PDFDocument): Promise<boolean> {
			const docSave = await doc.save();
			return BigInt(docSave.byteLength) < BigInt(this.maxSizeInBytes);
		},

		async hasCurrentDocLessPagesThanMax(doc: PDFDocument): Promise<boolean> {
			const newDocSave = await doc.save();
			const newDocResult = await PDFDocument.load(newDocSave);

			const {maxPagesPerFile} = this.model;

			return newDocResult.getPageCount() < maxPagesPerFile;
		},

		loadPresets(): void {
			const lsPresets = _.pickBy(localStorage, (_v, k) => k.indexOf('cj-vjs/pdf_splitter/preset/') >= 0);

			this.presets = lsPresets;
		},

		reset(): void {
			this.clickedLinks = [];

			this.docs = [];

			this.docsAsBytes = [];

			this.docsLinks = [];

			this.error.message = '';
			this.error.show = false;

			this.file = {
				fileReader: new FileReader(),
				inputField: {} as Blob,
				base64: '',
			};

			this.finishedGenerating = false;

			this.isGenerating = false;

			this.model.description = 'Meu arquivo PDF';
			this.model.intervals = [ [ '', '' ] ];
			this.model.namePrefix = 'meu_arquivo_pdf_';
			this.model.maxPagesPerFile = 2;
			this.model.maxSize = 512;
			this.model.maxSizeUnit = 'kb';
			this.model.splitBy = 'max_size';

			this.presets = {};
		},

		savePreset(): void {
			let modelCopy = _.cloneDeep(this.model);

			modelCopy = {
				description: modelCopy.description,
				namePrefix: modelCopy.namePrefix,
				maxPagesPerFile: modelCopy.maxPagesPerFile,
				maxSize: modelCopy.maxSize,
				maxSizeUnit: modelCopy.maxSizeUnit,
				splitBy: modelCopy.splitBy,
			} as typeof modelCopy;

			localStorage.setItem(`cj-vjs/pdf_splitter/preset/${ btoa(modelCopy.description)}`, btoa(JSON.stringify(modelCopy)));
		},

		removePreset(key: string): void {
			localStorage.removeItem(key);
		},

		// Events

		buttonDownloadZipFile(): void {
			this.createAndDownloadZipFile();
		},

		buttonGenerateClick(): void {
			this.generate();
		},

		buttonIntervalAddClick(): void {
			this.model.intervals.push([ '', '' ]);
		},

		buttonIntervalRemoveClick(_e: Event, index: number): void {
			this.model.intervals.splice(index, 1);
		},

		buttonSaveConfigAndGenerateClick(): void {
			this.savePreset();
			this.generate();
		},

		buttonLoadPresetClick(): void {
			this.loadPresets();
			this.modalSelectPreset.show = true;
		},

		buttonModalSelectPresetClose(): void {
			this.modalSelectPreset.show = false;
		},

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		buttonModalSelectPresetItemClick(_e: Event, item: any): void {
			this.model.description = item.description;
			this.model.namePrefix = item.namePrefix;
			this.model.maxPagesPerFile = item.maxPagesPerFile;
			this.model.maxSize = item.maxSize;
			this.model.maxSizeUnit = item.maxSizeUnit;
			this.model.splitBy = item.splitBy;

			this.modalSelectPreset.show = false;
		},

		buttonModalSelectPresetItemDeleteClick(_e: Event, key: string): void {
			this.removePreset(key);

			this.loadPresets();
		},

		buttonRestartClick(): void {
			this.reset();
		},

		inputFileChange(e: Event): void {
			const files = (<HTMLInputElement>e.target)?.files || [];

			this.file.inputField = files[0] || ({} as Blob);

			this.file.fileReader.onload = (eventOnload): void => {
				const base64 = eventOnload.target?.result || '';

				this.file.base64 = base64;
			};

			this.file.fileReader.readAsDataURL(this.file.inputField);
		},

		linkItemDocClick(_e: Event, index: number): void {
			if (!this.clickedLinks.includes(index)) {
				this.clickedLinks.push(index);
			}
		},

	},
	mounted() {
		this.loadPresets();
	},
});
</script>

<template>
	<section :class="['flex-1 feature', { 'is-generating': isGenerating }]">

		<h1 class="mb-4">Divisor de PDF</h1>

		<div v-if="error.show"
			class="alert alert-error mb-4 text-sm"
		>
			<div>
				<svg xmlns="http://www.w3.org/2000/svg"
					class="stroke-current flex-shrink-0 h-6 w-6"
					fill="none"
					viewBox="0 0 24 24"
				><path stroke-linecap="round"
					stroke-linejoin="round"
					stroke-width="2"
					d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
				/></svg>
				<span>{{ error.message }}</span>
			</div>
		</div>

		<template v-if="!finishedGenerating">
			<div class="container mb-2">
				<button type="button"
					class="btn btn-sm btn--primary rounded-full"
					@click="buttonLoadPresetClick"
				>
					Carregar configuração
				</button>
				<hr class="mb-2 mt-4">
			</div>
			<div class="container lg:grid lg:grid-cols-2 lg:gap-4 mb-3">
				<div>
					<div class="card bg-base-100 shadow-xl w-full">
						<div class="card-body">
							<div class="mb-2">
								<div class="form-control">
									<label for="inputDescription"
										class="form-label"
									><b>Descrição</b></label>
									<input v-model="model.description"
										type="text"
										class="input input-bordered"
										maxlength="100"
									>
								</div>
							</div>
							<div class="mb-4">
								<div class="form-control">
									<label for="inputNamePrefix"
										class="form-label"
									><b>Padrão de nome do arquivo</b></label>
									<input v-model="model.namePrefix"
										type="text"
										class="input input-bordered"
										maxlength="50"
									>
									<div class="mt-2 form-text">O padrão de nome (prefixo) que será dado aos arquivos de resultado.</div>
								</div>
							</div>
						</div>
					</div>
					<div class="card bg-base-100 mt-4 shadow-xl w-full">
						<div class="card-body">
							<div class="mb-2">
								<label class="form-label"><b>Dividir por</b></label>
								<div>
									<select class="input input-bordered w-full"
										id="selectSplitBy"
										v-model="model.splitBy"
									>
										<option value="max_size">Tamanho máximo</option>
										<option value="pages_per_file">Número de páginas por arquivo</option>
										<option value="pages_interval">Intervalo de páginas</option>
									</select>
								</div>
								<hr class="my-4">
								<template v-if="model.splitBy === 'max_size'">
									<div class="form-control flex-row">
										<input v-model.number="model.maxSize"
											type="number"
											class="input input-bordered inline w-36"
											id="inputMaxSize"
											maxlength="4"
											size="10"
										>
										<select v-model="model.maxSizeUnit"
											class="input input-bordered inline ml-2"
										>
											<option value="kb"
												selected
											>
												kB
											</option>
											<option value="mb">MB</option>
										</select>
									</div>
									<div class="mt-2 form-text">O tamanho máximo que cada arquivo de resultado pode conter.</div>
								</template>
								<template v-if="model.splitBy === 'pages_per_file'">
									<div class="form-inline">
										<div class="form-control">
											<input v-model.number="model.maxPagesPerFile"
												type="number"
												class="inline-block input input-bordered w-36"
												id="inputMaxPagesPerFile"
												size="4"
											>
										</div>
									</div>
									<div class="mt-2 form-text">A quantidade de páginas que cada arquivo de resultado deve conter.</div>
								</template>
								<template v-if="model.splitBy === 'pages_interval'">
									<div v-for="(_item, index) in model.intervals"
										:key="index"
										class="flex items-center mb-2 whitespace-nowrap"
									>
										<span class="mr-2">Página</span>
										<input v-model.number="(model.intervals[index] as string[])[0]"
											type="number"
											class="inline-block input input-bordered mr-2 w-16 lg:w-24 input-page-interval"
											min="1"
											size="4"
										>
										<span class="mr-2">até</span>
										<input v-model.number="(model.intervals[index] as string[])[1]"
											type="number"
											class="inline-block input input-bordered w-16 lg:w-24 input-page-interval"
											min="1"
											size="4"
										>
										<button v-if="index > 0"
											class="btn btn-link no-underline text-xl"
											@click="buttonIntervalRemoveClick($event, index)"
										>
											&times;
										</button>
									</div>
									<div>
										<button class="btn btn-link p-0"
											@click="buttonIntervalAddClick"
										>
											+ intervalo
										</button>
									</div>
								</template>
							</div>
						</div>
					</div>
				</div>
				<div class="mt-4 lg:mt-0 relative">
					<div class="custom-file">
						<input type="file"
							accept="application/pdf"
							class="h-100 custom-file-input"
							id="inputFile"
							@change="inputFileChange"
						>
						<label class="h-100 custom-file-label"
							for="customFile"
						>Selecionar arquivo</label>
					</div>
					<div class="container-fake-fileinput">
						<div class="container-icon">
							<svg fill="currentColor"
								viewBox="0 0 20 20"
								xmlns="http://www.w3.org/2000/svg"
							><path fill-rule="evenodd"
								d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z"
								clip-rule="evenodd"
							/></svg>
						</div>
						<div class="container-label-select-file">Selecione seu arquivo PDF</div>
						<div v-if="file.inputField"
							class="p-2 container-selected-file"
						>
							<div><b>Arquivo selecionado:</b></div>
							<div>{{ (file.inputField as any)?.name || file.inputField.text }}</div>
						</div>
					</div>
				</div>
			</div>

			<div v-if="!isGenerating"
				class="container mt-6 text-center"
			>
				<button type="button"
					class="px-5 btn btn--primary mb-3 lg:mb-0 lg:mr-3 w-full lg:w-fit"
					:disabled="!isInputDataValid"
					@click="buttonSaveConfigAndGenerateClick"
				>
					<span>Salvar configuração e gerar arquivos</span>
				</button>
				<button type="button"
					class="px-5 btn btn--primary w-full lg:w-fit"
					:disabled="!isInputDataValid"
					@click="buttonGenerateClick"
				>
					<span>Somente gerar arquivos</span>
				</button>
			</div>
			<div v-else
				class="p-6 text-center text-wait-generating"
			>
				<svg class="animate-spin inline mr-3 h-5 w-5 text-gray-500"
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 24 24"
				>
					<circle class="opacity-25"
						cx="12"
						cy="12"
						r="10"
						stroke="currentColor"
						stroke-width="4"
					/>
					<path class="opacity-75"
						fill="currentColor"
						d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
					/>
				</svg>
				<span>Aguarde. Gerando arquivos!</span>
			</div>
		</template>

		<div v-else
			class="container mb-3 container-result-files"
		>

			<button type="button"
				class="btn btn-sm btn--primary mb-3 rounded-full"
				@click="buttonRestartClick"
			>
				Recomeçar
			</button>

			<table class="mt-2 table table-compact w-full lg:w-1/2">
				<tbody>
					<tr>
						<th>Descrição:</th>
						<td class="whitespace-normal">{{ model.description }}</td>
					</tr>
					<tr>
						<th class="whitespace-normal">Padrão de nome do arquivo:</th>
						<td class="whitespace-normal">{{ model.namePrefix }}</td>
					</tr>
					<tr>
						<th>Dividir por:</th>
						<td class="whitespace-normal">
							<span v-if="model.splitBy === 'max_size'">Tamanho máximo ({{ model.maxSize }}{{ model.maxSizeUnit === 'kb' ? 'kB' : 'MB' }})</span>
							<span v-if="model.splitBy === 'pages_per_file'">Número de páginas por arquivo ({{ model.maxPagesPerFile }})</span>
							<span v-if="model.splitBy === 'pages_interval'">Intervalo de páginas</span>
							<ul v-if="model.splitBy === 'pages_interval'"
								class="mt-2"
							>
								<li
									v-for="(item, index) in getValidIntervals(model.intervals)"
									:key="`interval-${index}`"
								>
									{{ item[0] }} - {{ item[1] }}
								</li>
							</ul>
						</td>
					</tr>
				</tbody>
			</table>

			<template v-if="docsLinks.length > 1">

				<div class="my-4">
					<button class="btn btn--primary btn--ghost mb-2 px-1 py-3 text-left w-full lg:w-1/2"
						@click="buttonDownloadZipFile"
					>
						<svg class="w-6 h-6"
							fill="currentColor"
							viewBox="0 0 20 20"
							xmlns="http://www.w3.org/2000/svg"
						><path fill-rule="evenodd"
							d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
							clip-rule="evenodd"
						/></svg>
						<span>Download completo - arquivo .zip</span>
					</button>
				</div>

				<hr class="my-4">

			</template>

			<h4 v-if="docsLinks.length > 1"
				class="mb-3"
			>
				<b>Arquivos individuais ({{docsLinks.length}})</b>
			</h4>
			<h4 v-else
				class="mb-3"
			>
				<b>Arquivo de resultado</b>
			</h4>

			<div v-for="(item, index) in docsLinks"
				:key="index"
			>
				<a :class="['btn mb-2 p-1 text-left w-full lg:w-1/2', { 'btn--ghost': !clickedLinks.includes(index), 'btn-success': clickedLinks.includes(index) }]"
					:download="`${model.namePrefix}${index+1}.pdf`"
					:href="item"
					target="_blank"
					@click="linkItemDocClick($event, index)"
				>
					<svg class="w-6 h-6"
						fill="currentColor"
						viewBox="0 0 20 20"
						xmlns="http://www.w3.org/2000/svg"
					><path fill-rule="evenodd"
						d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
						clip-rule="evenodd"
					/></svg>
					<span>{{ model.namePrefix }}{{ index + 1 }}.pdf</span>
				</a>
			</div>

		</div>

		<Teleport to="body">
			<div v-if="modalSelectPreset.show"
				class="modal-custom modal-select-preset"
				tabindex="-1"
			>
				<div class="mx-4 w-full lg:w-1/3 modal-content">
					<div class="modal-header">
						<h5 class="modal-title">Carregar configuração</h5>
						<button class="button-close"
							@click="buttonModalSelectPresetClose"
						>
							<span>&times;</span>
						</button>
					</div>
					<div class="modal-body overflow-auto p-0">
						<div class="h-25 p-3"
							v-if="parsedPresetsOrderedByDescription.length < 1"
						>
							Você ainda não tem configurações salvas
						</div>
						<table class="table table-sm w-full"
							v-else
						>
							<tbody>
								<tr v-for="item in parsedPresetsOrderedByDescription"
									:key="item.key"
								>
									<td class="p-0">
										<button type="button"
											class="btn btn-link justify-start p-0"
											@click="buttonModalSelectPresetItemClick($event, item)"
										>
											{{ item.description }}
										</button>
									</td>
									<td class="align-middle p-0 text-right">
										<button type="button"
											class="p-0 text-red-700"
											@click="buttonModalSelectPresetItemDeleteClick($event, item.key)"
										>
											<svg class="w-6 h-6"
												fill="currentColor"
												viewBox="0 0 20 20"
												xmlns="http://www.w3.org/2000/svg"
											><path fill-rule="evenodd"
												d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
												clip-rule="evenodd"
											/></svg>
										</button>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</Teleport>

	</section>
</template>

<style lang="scss" scoped>
.feature.is-generating {
    pointer-events: none;
}

.form-control {
    margin-bottom: 0;
}

.form-text {
    font-size: 0.775em;
    line-height: 115%;
}

.form-inline .input input-bordered.input-max-size {
    width: 8em;
}

.input input-bordered.input-page-interval {
    width: 4.5em;
}

.custom-file {
    display: inline-block;
    height: 100%;
    margin-bottom: 0;
    opacity: 0;
    position: relative;
    width: 100%;
    z-index: 2;

    * {
        cursor: pointer;
    }

    input {
        height: inherit;
        margin: 0;
        opacity: 0;
        position: relative;
        width: 100%;
        z-index: 2;
    }

    label {
        background-color: #fff;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        color: #495057;
        font-weight: 400;
        height: inherit;
        left: 0;
        line-height: 1.5;
        padding: 0.375rem 0.75rem;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
    }
}

.container-fake-fileinput {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(62, 135, 245, 0.05);
    border: 3px dotted #3E87F5;
    border-radius: 10px;
    color: #3E87F5;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;

    .container-icon {
        text-align: center;
        width: 100%;

        svg {
            display: inline-block;
            height: 6em;
        }
    }

    .container-label-select-file {
        font-size: 1.25em;
        text-align: center;
        width: 100%;
    }

    .container-selected-file {
        border-top: 1px dotted #3E87F5;
        font-size: 0.9em;
        margin-top: 1em;
        padding: 1em 0;
        text-align: center;
        width: 100%;
    }
}

.container-result-files a svg,
.container-result-files button svg {
    height: 1.75em;
}

.container-result-files a span,
.container-result-files button span {
    vertical-align: middle;
}

.modal-select-preset .modal-body {
    max-height: 280px;
}

.text-wait-generating {
    font-size: 1.25em;
    opacity: 0.75;
}

@media (min-width: 576px) {}

@media (max-width: 767px) {
    .custom-file * {
        min-height: 260px;
    }
}

@media (min-width: 768px) {}

@media (max-width: 991px) {}

@media (min-width: 992px) {}

@media (min-width: 1200px) {}
</style>
