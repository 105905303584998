/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosResponse} from 'axios';

import {
	LegalCaseToSend, LegalCase, LegalCasePaginationResponse, LegalCaseAttachment
} from '../../../models/crm';

const pageParams = '&per_page=999999&page=1';

export default {

	create(data: LegalCaseToSend): Promise<AxiosResponse<LegalCase, any>> {
		const path = '/legal_cases.json';

		return axios.post(path, data);
	},

	delete(id: string|number): Promise<AxiosResponse<null|undefined>> {
		const path = `/legal_cases/${id}.json`;

		return axios.delete(path, {});
	},

	getAll(perPage = 25, page = 1, orderBy: string, filters: string)
		: Promise<AxiosResponse<LegalCasePaginationResponse, any>> {
		const path = `/legal_cases.json?include=funnel_steps&per_page=${perPage}&page=${page}&order=${orderBy}${filters}`;

		return axios.get(path);
	},

	getById(id: string|number): Promise<AxiosResponse<LegalCase, any>> {
		const path = `/legal_cases/${id}.json?include=funnel_steps,versions,attachments${pageParams}`;

		return axios.get(path);
	},

	getCaseTypesAll(): Promise<AxiosResponse<any>> {
		const path = `/legal_cases/case_types.json?${pageParams}`;

		return axios.get(path);
	},

	searchByName({
		perPage, page, name, orderBy, filters
	}: {
		perPage: number;
		page: number;
		name: string;
		orderBy: string;
		filters: string
	}): Promise<AxiosResponse<LegalCasePaginationResponse, any>> {
		let path = '';

		if (name.length === 0) {
			path = `/legal_cases.json?per_page=${perPage}&page=${page}&include=funnel_steps&order=${orderBy}${filters}`;
		} else {
			path = `/legal_cases.json?search=${name}&per_page=${perPage}&page=${page}&include=funnel_steps&order=${orderBy}${filters}`;
		}

		return axios.get(path);
	},

	setStatus({
		status, id
	}: {
		status: boolean,
		id: string | number
	}): Promise<AxiosResponse<any>> {
		const path = `/legal_cases/${id}.json`;

		return axios.patch(path, {
			is_active: status,
		});
	},

	update({
		data, id
	}: {
		data: LegalCaseToSend,
		id: string | number
	}): Promise<AxiosResponse<LegalCase, any>> {
		const path = `/legal_cases/${id}.json`;

		return axios.patch(path, data);
	},

	// Notes

	async noteCreate({
		content, legalCaseId: legal_case_id
	}: {
		content: string,
		legalCaseId: string | number
	}): Promise<AxiosResponse<any>> {
		const path = '/case_notes.json';

		return axios.post(path, {
			content, legal_case_id
		});
	},

	async noteUpdate({
		noteId, content, legalCaseId: legal_case_id
	}: {
		noteId: string | number,
		content: string,
		legalCaseId: string | number
	}): Promise<AxiosResponse<any>> {
		const path = `/case_notes/${noteId}.json`;

		return axios.put(path, {
			content, legal_case_id
		});
	},

	async noteDelete({
		legalCaseId, noteId
	}: {
		legalCaseId: string | number,
		noteId: string | number,
	}): Promise<AxiosResponse<any>> {
		const path = `/case_notes/${noteId}.json`;

		return axios.delete(path, {});
	},

	// Attachments

	async attachmentAdd({
		legalCaseId, attachments
	}: {
		legalCaseId: string | number,
		attachments: { file: string, filename: string}[],
	}): Promise<AxiosResponse<any> | void> {
		const path = `/legal_cases/${legalCaseId}/attachments.json`;
		const attachment = attachments[0];
		if (!attachment) return;

		const formData = new FormData();
		formData.append('file', attachment.file);
		formData.append('name', attachment.filename);

		return axios.post(path, formData, {
			headers: {
				'content-type': 'multipart/form-data',
			}
		});
	},

	async attachmentDelete({
		attachmentId, legalCaseId
	}: {
		attachmentId: string | number,
		legalCaseId: string | number,
	}): Promise<AxiosResponse<any>> {
		const path = `/legal_cases/${legalCaseId}/attachments/${attachmentId}.json`;

		return axios.delete(path, {});
	},

	async attachmentsGetAll(id: string | number)
		: Promise<AxiosResponse<{ attachments: LegalCaseAttachment[]}>> {
		const path = `/legal_cases/${id}/attachments.json`;

		return axios.get(path);
	},

};
