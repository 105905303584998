<script lang="ts">
import { v4 as uuidv4 } from 'uuid';

import EditorJS, { OutputData } from '@editorjs/editorjs';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import { defineComponent } from 'vue';

import editorJsHtml from 'editorjs-html';
import { BlockMutationEvent } from '@editorjs/editorjs/types/events/block';

type Editor = {
	id?: string | number;
	instance?: EditorJS
}

export default defineComponent({
	props: {
		classHolder: {
			default: '',
			type: String,
		},
		config: {
			default: () => {},
			type: Object,
		},
		inData: {
			default: '',
			type: String,
		},
		placeholder: {
			type: String,
		},
	},
	emits: [
		'change',
	],
	data() {
		return {

			editor: {} as Editor,

			uuid: null as string | null,

		};
	},
	methods: {

		clear(): void {
			if (this.editor.instance && this.editor.instance.clear) {
				this.editor.instance.clear();
			}
		},

		init(): void {
			this.editor = {
				id: this.editor.id || undefined,
			};

			const editor = document.querySelector(`#${this.editor.id}`);

			if (editor) {
				editor.innerHTML = '';
			}

			this.editor.instance = new EditorJS({
				holder: this.editor.id? `${this.editor.id}` : undefined,
				inlineToolbar: true,
				placeholder: this.placeholder || 'Digite aqui',
				tools: {
					header: {
						class: Header,
					},
					list: {
						class: List
					},
				},
				i18n: {
					/**
					 * @type {I18nDictionary}
					 */
					messages: {
						ui: {
							'inlineToolbar': {
								'converter': {
									'Convert to': 'Converter para'
								}
							},
							'toolbar': {
								'toolbox': {
									'Add': 'Adicionar'
								}
							}
						},
						toolNames: {
							'Text': 'Texto',
							'Heading': 'Título',
							'List': 'Lista',
							'Warning': 'Aviso',
							'Checklist': 'Checklist',
							'Quote': 'Citação',
							'Code': 'Código',
							'Delimiter': 'Delimitador',
							'Raw HTML': 'HTML puro',
							'Table': 'Tabela',
							'Link': 'Link',
							'Marker': 'Marcador',
							'Bold': 'Negrito',
							'Italic': 'Itálico',
							'InlineCode': 'Código em linha',
						},
						tools: {
							'warning': {
								'Title': 'Título',
								'Message': 'Mensagem',
							},
							'link': {
								'Add a link': 'Adicionar um link'
							},
						},
						blockTunes: {
							'delete': {
								'Delete': 'Deletar'
							},
							'moveUp': {
								'Move up': 'Mover para cima'
							},
							'moveDown': {
								'Move down': 'Mover para baixo'
							}
						},
					}
				},
				onReady: (): void => {
					if (this.inData) {
						if (typeof this.inData === 'string') {
							this.renderFromHtml(this.inData);

							return;
						}
					}
				},
				onChange: async(_api, event: BlockMutationEvent): Promise<void> => {
					if (event.type !== 'block-added') {
						this.$emit('change', await this.getOutput());
					}
				},
				...this.config,
			});
		},

		async getOutput(): Promise<{ html: string, raw: OutputData }> {
			const htmlParser = editorJsHtml();

			const editorJsObject = await this.editor.instance?.save();

			if (editorJsObject === undefined) {
				return {
					html: '',
					raw: { blocks: []},
				};
			}

			const output = {
				html: htmlParser.parse(editorJsObject).join(''),
				raw: editorJsObject,
			};

			return output;
		},

		renderFromHtml(html: string): void {
			this.editor.instance?.blocks.renderFromHTML(html);
		},
	},
	watch: {
		inData: {
			immediate: true,
			handler(newValue, oldValue): void {
				if (newValue === undefined || oldValue === undefined) {
					if (!this.uuid) this.uuid = uuidv4();

					this.editor.id = `editorjs-${this.uuid}`;
					const initialId = `${this.editor.id}`;

					setTimeout(() => {
						if (initialId === `${this.editor.id}`) {
							this.init();
						}
					}, 1000);

					return;
				}

				if (newValue?.length && newValue !== oldValue) {
					this.init();
				}
			},
		},
	},
});
</script>
<template>
	<div class="component-editorjs">
		<div v-if="editor.id"
			:id="`${editor.id}`"
			:class="classHolder"
		/>
	</div>
</template>
<style lang="scss" scoped></style>