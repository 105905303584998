<script lang="ts">
import VueCal from 'vue-cal';
import 'vue-cal/dist/vuecal.css';
import { defineComponent } from 'vue';

export type CalendarActiveView = 'day' | 'month'

export default defineComponent({
	components: {
		VueCal,
	},
	props: {
		events: {
			required: true, type: Array
		},
	},
	emits: [
		'on-event-click',
		'on-cell-click',
		'on-event-drop',
		'loaded'
	],
	data() {
		return {
			activeView: 'month' as CalendarActiveView
		};
	},
	mounted() {
		this.$emit('loaded');
	},
	updated() {
		this.$emit('loaded');
	},
	methods: {

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		onEventClick(vuecalEvent: any, e: Event): void {
			this.$emit('on-event-click', {
				e,
				vuecalEvent,
			});
		},

		onCellClick(cellDateTime: Date): void {
			const {activeView} = this;
			this.$emit('on-cell-click',
				cellDateTime,
				activeView
			);
		},

		onEventDrop({
			originalEvent, newDate, oldDate
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		}: {originalEvent: any, newDate: Date, oldDate: Date}) {
			const date = new Date(newDate);

			if (this.activeView === 'month') {
				date.setHours(oldDate.getHours());
				date.setMinutes(oldDate.getMinutes());
				newDate.setHours(oldDate.getHours());
				newDate.setMinutes(oldDate.getMinutes());
			} else {
				date.setHours(newDate.getHours());
				date.setMinutes(newDate.getMinutes());
			}

			this.$emit('on-event-drop', originalEvent.customData, date);
		}

	}
});
</script>
<template>
	<VueCal
		v-model:active-view="activeView"
		class="bg-white rounded-md"
		:disable-views="['years', 'year', 'week']"
		:events="events"
		events-on-month-view="long"
		locale="pt-br"
		:on-event-click="onEventClick"
		:editable-events="{ drag: true }"
		@cell-click="onCellClick"
		@event-drop="onEventDrop"
	/>
</template>
<style lang="scss" scoped>
.vuecal {
    :deep() {
        .vuecal__menu,
        .vuecal__cell-events-count {
            background-color: transparent;
            padding: 0.5rem;
        }

        .vuecal__title-bar {
            background-color: #ccc;
            font-size: 1.15rem;
            font-weight: 600;
            padding: 0.75rem 0;
        }

        .vuecal__flex {
            cursor: pointer;
        }

        .vuecal__cell-events {
            padding: 0.5rem;
        }

        .vuecal__view-btn {
            font-weight: bold;
        }

        .vuecal__event {
            background-color: #DBEDFF;
            border: 1px solid #3F87F5;;
            box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);
            color: #000;
            cursor: pointer;
            height: auto !important;
            margin-bottom: 2px;
            padding: 0.35rem;

            * {
                font-size: 0.75rem;
            }

            .vuecal__event-title {
                font-weight: bold;
            }

            .vuecal__event-time {
                font-weight: 500;
                span {
                    display: none;
                }
            }
        }

        .day-view {

            .vuecal__event {
                display: flex;
                gap: 6px;
            }

        }

        .month-view {

            .vuecal__event-title {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }

        }

    }

}
</style>
