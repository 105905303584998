<script lang="ts">
import objectHash from 'object-hash';

import serviceContacts from '../../../../services/API/crm/contacts';
import serviceMain from '../../../../services/API/crm/index';

import BlockingOverlay from '../../../../components/BlockingOverlay/BlockingOverlay.vue';
import FileUploadFilePond from '../../../../components/FileUpload/FileUploadFilePond.vue';
import FormValidator from '../../../../components/Form/FormValidator.vue';
import InputText from '../../../../components/Form/InputText.vue';
import SelectField from '../../../../components/Form/SelectField.vue';
import SingleSearchSelect from '../../../../components/SearchSelect/SingleSearchSelectComponent.vue';
import RadioField from '../../../../components/Form/RadioField.vue';
import Loading from '../../../../components/Loading/LoadingComponent.vue';
import ModalBasic from '../../../../components/Modal/ModalBasic.vue';

import QuickNotes from '../../components/Contacts/QuickNotes.vue';

import { mapActions } from 'pinia';
import { useContactsStore } from '../../../../stores/models/crm/contacts';
import {
	defineComponent, PropType, inject, DefineComponent
} from 'vue';
import {
	EventBus,
	Contact,
	Helpers,
	Luxon,
	Filters,
	ContactNote,
	File,
	Attachment,
	Calculation,
	Email,
	Phone,
	Gender,
	EntityType,
	MaritalStatus
} from '../../../../models/crm';
import axios, { AxiosError } from 'axios';
import {
	AxiosErrorResponse, Lodash
} from '../../../../models/crm';
import { RouteLocationRaw } from 'vue-router';


type BackupForm = {
	model: {
		name: string;
		address: {
			city: string;
			district: string;
			extra_info: string;
			number: string;
			state: string;
			street: string;
			zip_code: string;
		};
		cpf: string;
		rg: string;
		gender: Gender,
		birth_date: string;
		nationality: string;
		marital_status: MaritalStatus;
		occupation: string;
		cnpj: string;
		corporate_name: string;
		business_sector: string;
		tax_residence: {
			state: string;
			city: string;
		};
		phoneAddModel: string;
		emailAddModel: string;
	},
	responsible_party: {
		id: string | number;
		name: string;
	} | null;
	contactEmails: Email[],
	contactPhones: Phone[],
	attachments: {
		files: File[],
		loadedFiles: Attachment[],
	},
	entity_type: EntityType,

}

export default defineComponent({
	components: {
		BlockingOverlay,
		FileUploadFilePond,
		InputText,
		SelectField,
		SingleSearchSelect,
		RadioField,
		FormValidator,
		Loading,
		ModalBasic,
		QuickNotes,
	},
	props: {
		backRoute: {
			type: Object as PropType<RouteLocationRaw>,
		},
		contactId: {
			default: null,
			type: [ String, Number ],
		},
		contactsList: {
			default: () => [],
			type: Array as PropType<Contact[]>,
		},
		keepSameRoute: {
			default: false,
			type: Boolean,
		},
	},
	emits: [
		'contact-created',
		'contact-updated',
		'open-change-to-client-modal',
		'open-exceed-clients-limit-modal'

	],
	data() {
		return {

			eventBus: inject<EventBus>('eventBus'),
			luxon: inject<Luxon>('luxon'),
			lodash: inject<Lodash>('lodash'),
			helpers: inject<Helpers>('helpers'),
			envVars: inject<Record<string, string>>('envVars'),
			filters: inject<Filters>('filters'),

			attachments: {
				files: [] as File[],
				loadedFiles: [] as Attachment[],
			},

			contactEmails: [] as Email[],

			contactPhones: [] as Phone[],

			contactsTagsMultiselect: [],

			entity_type: EntityType.PERSON,

			loadedContact: null as Contact | null,

			responsible_party: null as {id: string | number; name: string} | null,

			showMoreDetails: false as boolean,

			model: {
				name: '',

				address: {
					city: '',
					district: '',
					extra_info: '',
					number: '',
					state: '',
					street: '',
					zip_code: '',
				},

				cpf: '',
				rg: '',
				gender: Gender.NOT_DEFINED as Gender,
				birth_date: '',
				nationality: '',
				marital_status: MaritalStatus.NOT_INFORMED as MaritalStatus,
				occupation: '',

				cnpj: '',
				corporate_name: '',
				business_sector: '',
				tax_residence: {
					state: '',
					city: '',
				},

				phoneAddModel: '',
				emailAddModel: '',
			},

			backupForm: null as BackupForm | null,

			notes: [] as ContactNote[],

			relatedContacts: [] as Contact[],

			calculations: [] as Calculation[],

			requesting: {
				loadAttachments: false,
				loadCalculations: false,
				loadContact: false,
				loadNotes: false,
				loadRelatedContacts: false,
				loadAddress: false,
				save: false,
				setMainEmail: false,
				setMainPhone: false,
			},

			radioTypeOptionsList: [
				{
					value: EntityType.PERSON, label: 'Pessoa Física'
				},
				{
					value: EntityType.COMPANY, label: 'Pessoa Jurídica'
				},
			],

			radioGenderOptionsList: [
				{
					value: Gender.FEMALE, label: 'Fem.'
				},
				{
					value: Gender.MALE, label: 'Masc.'
				},
			],

			selectUfOptionsList: [
				{
					value: '', label: ''
				},
				{
					value: 'AC', label: 'Acre'
				},
				{
					value: 'AL', label: 'Alagoas'
				},
				{
					value: 'AP', label: 'Amapá'
				},
				{
					value: 'AM', label: 'Amazonas'
				},
				{
					value: 'BA', label: 'Bahia'
				},
				{
					value: 'CE', label: 'Ceará'
				},
				{
					value: 'DF', label: 'Distrito Federal'
				},
				{
					value: 'ES', label: 'Espírito Santo'
				},
				{
					value: 'GO', label: 'Goiás'
				},
				{
					value: 'MA', label: 'Maranhão'
				},
				{
					value: 'MS', label: 'Mato Grosso do Sul'
				},
				{
					value: 'MT', label: 'Mato Grosso'
				},
				{
					value: 'MG', label: 'Minas Gerais'
				},
				{
					value: 'PA', label: 'Pará'
				},
				{
					value: 'PB', label: 'Paraíba'
				},
				{
					value: 'PR', label: 'Paraná'
				},
				{
					value: 'PE', label: 'Pernambuco'
				},
				{
					value: 'PI', label: 'Piauí'
				},
				{
					value: 'RJ', label: 'Rio de Janeiro'
				},
				{
					value: 'RN', label: 'Rio Grande do Norte'
				},
				{
					value: 'RS', label: 'Rio Grande do Sul'
				},
				{
					value: 'RO', label: 'Rondônia'
				},
				{
					value: 'RR', label: 'Roraima'
				},
				{
					value: 'SC', label: 'Santa Catarina'
				},
				{
					value: 'SP', label: 'São Paulo'
				},
				{
					value: 'SE', label: 'Sergipe'
				},
				{
					value: 'TO', label: 'Tocantins'
				},
			],

			selectMaritalStatusOptionsList: [
				{
					value: MaritalStatus.NOT_INFORMED, label: 'Não informado'
				},
				{
					value: MaritalStatus.SINGLE, label: 'Solteiro(a)'
				},
				{
					value: MaritalStatus.STABLE_UNION, label: 'União estável'
				},
				{
					value: MaritalStatus.MARRIED, label: 'Casado(a)'
				},
				{
					value: MaritalStatus.DIVORCED, label: 'Divorciado(a)'
				},
				{
					value: MaritalStatus.SEPARATED, label: 'Separado(a)'
				},
				{
					value: MaritalStatus.WIDOWED, label: 'Viúvo(a)'
				},
			],

		};
	},
	computed: {

		hasAttachments(): boolean {
			return this.attachments.loadedFiles.length > 0;
		},

		isAddressFilled(): boolean {
			return !!(this.model.address.city
				&& this.model.address.state
				&& !this.requesting.loadAddress);
		},

		isContactTypePf(): boolean {
			return this.entity_type === EntityType.PERSON;
		},

		isContactTypePj(): boolean {
			return this.entity_type === EntityType.COMPANY;
		},

		isEditing(): boolean {
			return !!this.contactId;
		},

		isCreatingPjResponsibleParty(): boolean {
			return this.backupForm !== null;
		},

		keepSexAndBirthDateImmutable(): boolean {
			return this.isEditing && this.loadedContact?.type === 'Client';
		},

		liveContact(): Contact | undefined {
			return this.contactsList.find(contact => contact.id == this.contactId);
		},

		contactAge(): string {
			const { birth_date } = this.model;

			if (!birth_date || birth_date.length < 10) return '';

			const birthDate = this.luxon?.fromFormat(birth_date, 'dd/MM/yyyy');

			if (!birthDate) return '';

			const age = this.luxon?.now().diff(birthDate, 'years').years;
			const ageInFullYears = age ? Math.floor(age).toString() : '';

			return ageInFullYears;
		},

	},
	watch: {
		'entity_type'(newValue, oldValue): void {
			if (newValue !== oldValue) {
				(this.$refs.formValidatorModalContact as typeof FormValidator)?.reset();
			}
		},
	},
	methods: {
		...mapActions(useContactsStore, [ 'loadContacts', 'getContactAttachments' ]),

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		async addAttachments(files: File[]): Promise<void> {
			const { contactId } = this;

			this.eventBus?.emit('BlockingOverlay/show', {
				text: 'Aguarde, adicionando anexos'
			});

			try {
				await serviceContacts.attachmentAdd({
					contactId, attachments: files
				});

				await this.loadAttachments();

				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Anexo(s) adicionado(s) com sucesso',
						type: 'success'
					}
				);
			} catch (err) {
				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Falha ao adicionar anexos',
						type: 'error'
					}
				);
			} finally {
				this.eventBus?.emit('BlockingOverlay/hide');
			}
		},

		addEmail(email: string) {
			const findResult = this.contactEmails.find(item => item.email === email);

			if (!findResult) {
				this.contactEmails.push({
					email,
					is_main: this.contactEmails.length < 1,
				});
			} else {
				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'O e-mail digitado já foi cadastrado anteriormente',
						type: 'warning'
					}
				);
			}
		},

		addPhone(phone: string) {
			const {
				area_code, number, is_main
			} = this.phoneFromString(this.contactPhones.length < 1, phone);

			const findResult = this.contactPhones.find(item => {
				return item.area_code === area_code && item.number === number;
			});

			if (!findResult) {
				if (area_code && number) {
					this.contactPhones.push({
						area_code,
						number,
						is_main: is_main,
					});
				}
			} else {
				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'O telefone digitado já foi cadastrado anteriormente',
						type: 'warning'
					}
				);
			}
		},

		phoneFromString(isMain: boolean, phoneNumber: string): Phone {
			const phoneCommaSeparated = phoneNumber.replace(/[()-]/g, '').replace(' ', ',');
			const phoneSplit = phoneCommaSeparated.split(',');

			const area_code = phoneSplit[0] || '';
			const number = phoneSplit[1] || '';
			return {
				area_code,
				number,
				is_main: isMain,
			};
		},

		clearModelAddressBaseFields() {
			this.model.address.city = '';
			this.model.address.district = '';
			this.model.address.state = '';
			this.model.address.street = '';
		},

		clearModelAddress() {
			this.model.address.zip_code = '';
			this.model.address.city = '';
			this.model.address.district = '';
			this.model.address.state = '';
			this.model.address.street = '';
			this.model.address.number = '';
			this.model.address.extra_info = '';
		},

		async getAddress() {
			const { zip_code } = this.model.address;

			if (!zip_code || !zip_code.match(/\d{5}-\d{3}/)) return;

			const cleanZipCode = zip_code.replace('-', '');

			this.clearModelAddressBaseFields();

			try {
				const response = await serviceMain.getAddressByZipCode(cleanZipCode);

				const addressReturned = response.data;

				this.requesting.loadAddress = false;

				this.model.address.city = addressReturned.localidade;
				this.model.address.district = addressReturned.bairro;
				this.model.address.state = addressReturned.uf;
				this.model.address.street = addressReturned.logradouro;
			} catch (err) {
				console.error(err);
			}
		},

		getCreateCalculationUrl(): string {
			const contact = this.loadedContact;
			if (!contact?.slug) return '';

			const baseUrl = this.envVars?.VITE_CJ_WEB_APP_URL;

			return `${baseUrl}novo-calculo/${contact.slug}/todos`;
		},

		hide() {
			this.resetModel();

			(this.$refs.modal as typeof ModalBasic).hide();
		},

		async loadAttachments(forceReload = true): Promise<void> {
			if (!this.contactId) return;

			this.requesting.loadAttachments = true;

			if (!forceReload
				&& this.liveContact?.attachments
				&& this.liveContact.attachments.length > 0
			) {
				const { attachments } = this.liveContact;

				this.attachments.files = [];

				this.attachments.loadedFiles = attachments;

				this.requesting.loadAttachments = false;

				return;
			}

			try {
				const attachments = await this.getContactAttachments(this.contactId);

				this.attachments.files = [];

				this.attachments.loadedFiles = attachments;

				this.requesting.loadAttachments = false;
			} catch (e) {
				console.error(e);

				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Falha ao carregar anexos',
						type: 'error'
					}
				);
			}
		},

		async loadCalculations(): Promise<void> {
			if (!this.contactId) return;

			const { contactId } = this;

			this.requesting.loadCalculations = true;

			try {
				const notParsed = sessionStorage.getItem(`contact-${this.contactId}-calculations`);

				const storedContactCalculations = notParsed && JSON.parse(notParsed);

				if (storedContactCalculations) {
					const { calculations } = storedContactCalculations;

					this.calculations = calculations;
				} else {
					const response = await serviceContacts.getCalculationsByContactId(contactId);

					sessionStorage.setItem(`contact-${this.contactId}-calculations`, JSON.stringify(response.data));

					const { calculations } = response.data;

					this.calculations = calculations;
				}
			} catch (err) {
				console.error(err);

				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Falha ao carregar cálculos',
						type: 'error'
					}
				);
			} finally {
				this.requesting.loadCalculations = false;
			}
		},

		async loadContact(): Promise<void> {
			this.requesting.loadContact = true;

			try {
				const { contactId } = this;


				const response = await serviceContacts.getById(contactId);


				const contactData = response.data;


				this.loadedContact = contactData;

				this.entity_type = contactData.entity_type || '';

				this.model.name = contactData.name || '';

				this.model.cpf = contactData.cpf || '';
				this.model.rg = contactData.rg || '';
				this.model.gender = contactData.gender || Gender.NOT_DEFINED;
				this.model.nationality = contactData.nationality || '';
				this.model.marital_status = contactData.marital_status || MaritalStatus.NOT_INFORMED;
				this.model.occupation = contactData.occupation || '';

				this.model.cnpj = contactData.cnpj || '';
				this.model.corporate_name = contactData.corporate_name || '';
				this.model.business_sector = contactData.business_sector || '';
				this.model.tax_residence = contactData.tax_residence || {
					state: '',
					city: '',
				};
				this.responsible_party = {
					id: contactData.responsible_party_id || '',
					name: contactData.responsible_party_name || ''
				};

				if (contactData.address) {
					this.model.address.zip_code = contactData.address?.zip_code || '';
					this.model.address.street = contactData.address?.street || '';
					this.model.address.number = `${contactData.address?.number}` || '';
					this.model.address.extra_info = contactData.address?.extra_info || '';
					this.model.address.district = contactData.address?.district || '';
					this.model.address.city = contactData.address?.city || '';
					this.model.address.state = contactData.address?.state || '';
				}

				if (contactData.birth_date) {
					this.model.birth_date = this.luxon?.fromISO(contactData.birth_date).toFormat('dd/MM/yyyy') || '';
				}

				this.contactEmails = contactData.emails || [];

				const phoneFromString = (isMain: boolean, phoneNumber: string) : Phone => {
					return {
						area_code: phoneNumber.substring(0, 2),
						number: phoneNumber.substring(2),
						is_main: isMain,
					};
				};

				const phone1 = contactData.phone ? phoneFromString(true, contactData.phone) : null;

				const phone2 = contactData.phone2 ? phoneFromString(false, contactData.phone2) : null;

				const phones = [ phone1 || [], ...(contactData.phones || []), phone2 || [] ].flat() || [];

				this.contactPhones = this.lodash?.uniqBy(phones, 'number') || [];

				this.showMoreDetails = true;

				await this.loadNotes(false);

				await this.loadAttachments(false);

				await this.loadCalculations();

				await this.loadRelatedContacts();
			} catch (err) {
				console.error(err);

				this.hide();

				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Falha ao carregar contato',
						type: 'error'
					}
				);
			}


			this.requesting.loadContact = false;
		},

		async loadNotes(forceReload = true): Promise<void> {
			const { contactId } = this;

			this.requesting.loadNotes = true;

			try {
				const notParsed = sessionStorage.getItem(`contact-${this.contactId}-notes`);

				const storedNotes: ContactNote[] | undefined | null = notParsed && JSON.parse(notParsed);

				if (storedNotes && !forceReload) {
					this.notes = storedNotes;
				} else {
					const response = await serviceContacts.notesGetByContactId({ contactId });
					const { notes } = response.data;

					sessionStorage.setItem(`contact-${this.contactId}-notes`, JSON.stringify(notes));

					this.notes = notes;
				}
			} catch (err) {
				console.error(err);

				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Falha ao carregar anotações',
						type: 'error'
					}
				);
			} finally {
				this.requesting.loadNotes = false;
			}

			return;
		},

		async loadRelatedContacts(): Promise<void> {
			const { contactId } = this;

			this.requesting.loadRelatedContacts = true;

			try {
				const response = await serviceContacts.getRelatedContacts(contactId);

				this.relatedContacts = response.data.related_contacts;
			} catch (err) {
				console.error(err);

				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Falha ao carregar contatos relacionados',
						type: 'error'
					}
				);
			} finally {
				this.requesting.loadRelatedContacts = false;
			}
		},

		quickNotes_noteCreated(): void {
			this.loadNotes();
		},

		quickNotes_noteDeleted(): void {
			this.loadNotes();
		},

		quickNotes_noteUpdated(): void {
			this.loadNotes();
		},

		async removeAttachment(item: { id: string | number; }): Promise<void> {
			const { contactId } = this;

			this.eventBus?.emit('BlockingOverlay/show', {
				text: 'Aguarde, removendo anexo'
			});

			try {
				await serviceContacts.attachmentDelete({
					attachmentId: item.id,
					contactId,
				});

				await this.loadAttachments();

				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Anexo removido com sucesso',
						type: 'success'
					}
				);
			} catch (err) {
				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Falha ao remover o anexo',
						type: 'error'
					}
				);

				return;
			}

			this.eventBus?.emit('BlockingOverlay/hide');
		},

		removeEmail(item: Email): void {
			const findIndexResult = this.contactEmails
				.findIndex(findItem => findItem.email === item.email);

			if (findIndexResult >= 0) {
				this.contactEmails.splice(findIndexResult, 1);
			}
		},

		removePhone(item: Phone): void {
			const findIndexResult = this.contactPhones.findIndex(findItem => {
				return findItem.area_code === item.area_code && findItem.number === item.number;
			});

			if (findIndexResult >= 0) {
				this.contactPhones.splice(findIndexResult, 1);
			}
		},

		resetModel(): void {
			this.entity_type = EntityType.PERSON;

			this.responsible_party = null;

			this.showMoreDetails = false;

			this.clearModelAddress();

			this.model = {
				name: '',

				address: {
					city: '',
					district: '',
					extra_info: '',
					number: '',
					state: '',
					street: '',
					zip_code: '',
				},

				cpf: '',
				rg: '',
				gender: Gender.NOT_DEFINED,
				birth_date: '',
				nationality: '',
				marital_status: MaritalStatus.NOT_INFORMED,
				occupation: '',

				cnpj: '',
				corporate_name: '',
				business_sector: '',
				tax_residence: {
					state: '',
					city: '',
				},
				phoneAddModel: '',
				emailAddModel: '',
			};
		},

		async save(): Promise<void> {
			const { contactId } = this;

			const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
			const hasUnaddedEmail =
					this.contactEmails.length < 1 && emailRegex.test(this.model.emailAddModel);
			const hasUnaddedPhone = this.model.phoneAddModel.length > 9 && this.contactPhones.length < 1;
			const emails = hasUnaddedEmail ?
				[ {
					email: this.model.emailAddModel, is_main: true
				} ]
				: this.contactEmails;

			const phones = hasUnaddedPhone
				? [ this.phoneFromString(true, this.model.phoneAddModel) ]
				: this.contactPhones;

			const responsible_party_id = this.responsible_party?.id;

			const { entity_type } = this;

			const payload = {
				entity_type,

				...this.model,

				emails,
				phones,
				responsible_party_id
			};

			this.requesting.save = true;

			if (this.isEditing) {
				this.eventBus?.emit('BlockingOverlay/show', {
					text: 'Aguarde, atualizando contato'
				});

				try {
					if (payload.gender === Gender.NOT_DEFINED && payload.cpf.length !== 0) throw new Error('Não foi possível identificar o gênero do contato');

					const {data} = await serviceContacts.updateContact({
						data: payload,
						id: contactId,
					});

					this.responsible_party = {
						id: data.id,
						name: data.name
					};

					!this.isCreatingPjResponsibleParty && (this.$refs.modal as typeof ModalBasic).hide();

					this.eventBus?.emit(
						'Toast/add',
						{
							content: 'Contato atualizado com sucesso',
							type: 'success'
						}
					);

					this.$emit('contact-updated', data);
				} catch (err) {
					console.error(err);
					this.setErrors(err);
				}
			} else {
				this.eventBus?.emit('BlockingOverlay/show', {
					text: 'Aguarde, criando o contato'
				});

				try {
					if (payload.gender === Gender.NOT_DEFINED && payload.cpf.length !== 0) throw new Error('Não foi possível identificar o gênero do contato');

					const contact = await serviceContacts.createContact(payload);
					if (this.isCreatingPjResponsibleParty) {
						this.responsible_party = {
							id: contact.id,
							name: contact.name
						};
					}

					!this.isCreatingPjResponsibleParty && (this.$refs.modal as typeof ModalBasic).hide();

					this.eventBus?.emit(
						'Toast/add',
						{
							content: 'Contato criado com sucesso',
							type: 'success'
						}
					);

					this.$emit('contact-created', !this.isCreatingPjResponsibleParty && contact.id);
				} catch (err) {
					console.error(err);

					if (axios.isAxiosError(err) && err.response) {
						const axiosError = err as AxiosError;
						const response = axiosError.response as AxiosErrorResponse;

						// Handle client limit error
						if (response.status === 402) {
							this.eventBus?.emit('BlockingOverlay/hide');
							this.requesting.save = false;
							this.$emit('open-exceed-clients-limit-modal');
						}

						// Handle other Axios errors
						const fieldsWithError = Object.entries(response.data.errors).map(([ key, value ]) => {
							return [ key, (value[0] ?? 'Falha ao criar/atualizar contato') ];
						});

						if (fieldsWithError.length > 0) {
							const formValidator = this.$refs.formValidatorModalContact as typeof FormValidator;


							fieldsWithError.forEach(fieldWithError => {
								const fieldName = fieldWithError[0];
								const pickNumberedFieldNumber = (fieldNameToPick: string): number => {
									const secondPart = fieldNameToPick.split('[')[1] || '';
									const number = Number(secondPart[0] || 0);

									if (Number.isNaN(number)) return 0;

									return number;
								};

								const defaultName = fieldName?.includes('email')
									? `Email ${pickNumberedFieldNumber(fieldName) + 1}`
									: undefined;
								formValidator.setFieldError(fieldName, fieldWithError[1], defaultName);
							});
							this.goToModalTop();
						}
					} else {
						// Handle non-Axios errors
						this.eventBus?.emit('Toast/add', {
							content: 'Falha ao criar/atualizar contato',
							type: 'error'
						});
					}
				}
			}

			this.eventBus?.emit('BlockingOverlay/hide');

			this.requesting.save = false;
		},

		async setMainEmail(contact: Contact, email: Email): Promise<void> {
			this.requesting.setMainEmail = true;

			this.eventBus?.emit('BlockingOverlay/show', {
				text: 'Aguarde, definindo e-mail principal'
			});

			try {
				await serviceContacts.setMainEmail({
					contact: contact, email: email
				});

				await this.loadContact();
			} catch (err) {
				console.error(err);
			}

			this.eventBus?.emit('BlockingOverlay/hide');

			this.requesting.setMainEmail = false;
		},

		async setMainPhone(contact: Contact, phone: Phone) {
			this.requesting.setMainPhone = true;

			this.eventBus?.emit('BlockingOverlay/show', {
				text: 'Aguarde, definindo telefone principal'
			});

			try {
				await serviceContacts.setMainPhone({
					contact: contact, phone: phone
				});

				await this.loadContact();
			} catch (err) {
				console.error(err);
			}

			this.eventBus?.emit('BlockingOverlay/hide');

			this.requesting.setMainPhone = false;
		},

		show(): void {
			this.resetModel();

			if (this.contactId) {
				this.loadContact();
			}

			(this.$refs.modal as typeof ModalBasic).show();
		},

		// Events

		async attachments_updatefiles(files: File[]): Promise<void> {
			const hashFiles = objectHash(getFilesArrayAsString(files));
			const hashAttachmentsFiles = objectHash(getFilesArrayAsString(this.attachments.files));

			if (hashFiles !== hashAttachmentsFiles) {
				this.attachments.files = files;

				await this.addAttachments(files);
			}

			function getFilesArrayAsString(arr: File[]): string {
				return JSON.stringify(
					arr.map((item: File) =>
						`${item.filename}:${item.fileSize}:${item.fileType}:${item.fileExtension}${item.id}`
					));
			}
		},

		buttonAttachmentRemoveClick(_e: MouseEvent, item: Attachment): void {
			this.removeAttachment(item);
		},

		buttonCancelClick(): void {
			if (this.isCreatingPjResponsibleParty && this.backupForm) {
				this.model = this.backupForm.model;
				this.entity_type = this.backupForm.entity_type;
				this.contactPhones = this.backupForm.contactPhones;
				this.contactEmails = this.backupForm.contactEmails;
				this.attachments = this.backupForm.attachments;
				this.showMoreDetails = true;

				this.backupForm = null;
				return;
			}
			(this.$refs.modal as typeof ModalBasic).hide();
		},

		buttonClearAddressReturnedClick(): void {
			this.clearModelAddress();
		},

		toggleShowMoreDetails(): void {
			this.showMoreDetails = !this.showMoreDetails;
		},

		buttonEmailAddClick(): void {
			this.addEmail(this.model.emailAddModel);

			this.model.emailAddModel = '';
		},

		buttonEmailRemoveClick(_e: MouseEvent,
			email: Email
		): void {
			this.removeEmail(email);
		},

		buttonEmailSetMainClick(_e: MouseEvent,
			contact: Contact,
			email: Email
		): void {
			if (this.isEditing && !email.is_main) {
				this.setMainEmail(contact, email);
			}
		},

		buttonPhoneAddClick(): void {
			this.addPhone(this.model.phoneAddModel);

			this.model.phoneAddModel = '';
		},

		buttonPhoneRemoveClick(_e: MouseEvent,
			item: Phone
		): void {
			this.removePhone(item);
		},

		buttonPhoneSetMainClick(_e: MouseEvent, contact: Contact | null, phone: Phone): void {
			if (!contact) {
				this.contactPhones = this.contactPhones.map(item => {
					if (item.number === phone.number) {
						return {
							...item, is_main: !item.is_main
						};
					}
					return item;
				});
				return;
			}
			if (this.isEditing && !phone.is_main) {
				this.setMainPhone(contact, phone);
			}
		},

		async buttonSaveClick(): Promise<void> {
			const validateResult = (this.$refs.formValidatorModalContact as typeof FormValidator)
				.validate();
			const { isValid } = validateResult;

			if (isValid) {
				await this.save();
				if (this.isCreatingPjResponsibleParty && this.backupForm) {
					this.model = this.backupForm.model;
					this.entity_type = this.backupForm.entity_type;
					this.contactPhones = this.backupForm.contactPhones;
					this.contactEmails = this.backupForm.contactEmails;
					this.attachments = this.backupForm.attachments;
					this.showMoreDetails = true;

					this.backupForm = null;
				}
			} else {
				this.goToModalTop();
			}
		},

		buttonTabClick(_e: MouseEvent, type: string): void {
			this.entity_type = type as EntityType;

			(this.$refs.formValidatorModalContact as typeof FormValidator)?.reset();
		},

		buttonZipCodeOkClick(): void {
			if (this.model.address.zip_code.length === 9) {
				this.requesting.loadAddress = true;
				this.getAddress();
			} else if (this.isAddressFilled) {
				this.clearModelAddressBaseFields();
			}
		},

		tagsMultiselectAsyncContact_queryFunction(query: string): Promise<Contact[]> {
			return new Promise(
				(resolve, reject) => {
					serviceContacts
						.searchByName({
							name: query, orderBy: 'name:asc', filters: ''
						})
						.then(({data}) => resolve(data.contacts))
						.catch((err) => {
							console.error(err);
							reject(err);
						});
				}
			);
		},

		clickedAddResponsibleParty(): void {
			this.backupForm = {
				model: this.model,
				entity_type: this.entity_type,
				contactPhones: this.contactPhones,
				contactEmails: this.contactEmails,
				attachments: this.attachments,
				responsible_party: null,
			};

			this.resetModel();
			this.attachments.files = [];
			this.attachments.loadedFiles = [];
			this.contactEmails = [];
			this.contactPhones = [];

			this.goToModalTop();
		},

		modal_hide(): void {
			this.loadedContact = null;

			this.attachments.files = [];
			this.attachments.loadedFiles = [];

			this.contactEmails = [];

			this.contactPhones = [];

			if (this.keepSameRoute) return;

			if (!this.backRoute) {
				this.$router.back();
			} else {
				this.$router.push(this.backRoute);
			}
		},

		goToModalTop(): void {
			(this.$refs.modal as typeof ModalBasic).$refs.modalContent.scrollTo({
				behavior: 'smooth',
				left: 0,
				top: 0,
			});
		},

		isValidCnpj(cnpj: string) {
			const cleanCnpj = cnpj.replace(/\D/g, '');

			const cnpjInts = cleanCnpj.split('').map(Number);

			if (cnpjInts.every((digit) => digit === 0)) return false;

			function calcDV(digits: number[], baseInts: number[]): number {
				let sum = 0;
				for (let i = 0;i < digits.length;i++) {
					sum += (digits[i] || 0) * (baseInts[i] || 0);
				}
				const remainder = sum % 11;
				return remainder < 2 ? 0 : 11 - remainder;
			}

			const baseIntsForDV1 = [ 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2 ];
			const baseIntsForDV2 = [ 6, ...baseIntsForDV1 ];

			const calcDV1 = calcDV(cnpjInts.slice(0, 12), baseIntsForDV1);
			const calcDV2 = calcDV(cnpjInts.slice(0, 13), baseIntsForDV2);

			const actualDV1 = parseInt(cleanCnpj.charAt(12), 10);
			const actualDV2 = parseInt(cleanCnpj.charAt(13), 10);

			return calcDV1 === actualDV1 && calcDV2 === actualDV2;
		},
		cnpjError(): string {
			const cnpjNumber = this.model.cnpj.replace(/\D/g, '');
			if (cnpjNumber.length === 0) return 'Por favor preencha o CNPJ';
			if (cnpjNumber.length < 14) return 'CNPJ incompleto';
			return 'CNPJ inválido';
		},
		setErrors(err: Error | AxiosError | unknown) {
			if (axios.isAxiosError(err) && err.response) {
				const axiosError = err as AxiosError;
				const response = axiosError.response as AxiosErrorResponse;

				// Handle client limit error
				if (response.status === 402) {
					this.eventBus?.emit('BlockingOverlay/hide');
					this.requesting.save = false;
					this.$emit('open-exceed-clients-limit-modal');
				}

				// Handle other Axios errors
				const fieldsWithError = Object.keys(response.data.errors);
				const fieldErrorMessage = response
					.data.errors?.[Object.keys(response.data.errors)[0] || '']?.[0] ?? 'Falha ao criar/atualizar contato';
				if (fieldsWithError.length > 0) {
					const formValidator = this.$refs.formValidatorModalContact as typeof FormValidator;
					fieldsWithError.forEach(fieldWithError => {
						formValidator.setFieldError(fieldWithError, fieldErrorMessage);
					});
					this.goToModalTop();
				}
			} else {
				// Handle non-Axios errors
				this.eventBus?.emit('Toast/add', {
					content: 'Falha ao criar/atualizar contato',
					type: 'error'
				});
			}
		}

	},
});
</script>
<template>
	<ModalBasic
		ref="modal"
		:size="isEditing ? 'xl' : 'lg'"
		:title="isEditing ? 'Editar contato' : 'Novo contato'"
		:extra-content-classes="isEditing ? 'mb-4 flex' : ''"
		:is-create-modal="!isEditing"
		:is-edit-modal="isEditing"
		@hide="modal_hide"
	>
		<template #content>

			<template v-if="requesting.loadContact">
				<Loading class="opacity-50 py-14 text-center" />
			</template>
			<template v-else>

				<hr>
				<!-- Left column -->
				<div
					class="px-4 pt-4 mb-4 overflow-x-hidden"
					:class="[isEditing && 'w-3/5']"
				>

					<FormValidator
						ref="formValidatorModalContact"
						:refs="($refs as Record<string, DefineComponent>)"
						:validation-summary-show="true"
						validation-summary-class="mb-4 p-3"
					>
						<template
							field="name"
							:label="isContactTypePf ? 'Nome' : 'Nome Fantasia'"
							message="Preenchimento obrigatório"
							:invalidIf="() => model.name === ''"
						/>

						<template
							field="cpf"
							label="CPF"
							message="Formato inválido"
							:enableIf="() => isContactTypePf && model.cpf !== ''"
							:invalidIf="() => model.cpf.match(/\d{3}\.\d{3}\.\d{3}-\d{2}/) === null
							"
						/>
						<template
							field="birth_date"
							label="Data de nascimento"
							:message="() => model.birth_date ? 'Formato inválido' : 'Preenchimento obrigatório'"
							:enableIf="() => isContactTypePf"
							:invalidIf="() => model.birth_date.match(/\d{2}\/\d{2}\/\d{4}/) === null || !model.birth_date
							"
						/>
						<template
							field="confirm_gender"
							label="Sexo"
							message="Preenchimento obrigatório"
							:enableIf="() => isContactTypePf"
							:invalidIf="() => ((model.gender !== 'male') && (model.gender !== 'female'))
							"
						/>

						<template
							field="cnpj"
							label="CNPJ"
							:message="() => cnpjError()"
							placeholder="__.___.___/____-__"
							:enableIf="() => isContactTypePj"
							:invalidIf="() => !isValidCnpj(model.cnpj)"
						/>
						<template
							field="corporate_name"
							label="Razão Social"
							message="Preenchimento obrigatório"
							:enableIf="() => isContactTypePj"
							:invalidIf="() => model.corporate_name === ''"
						/>

						<template
							v-for="(email, index) in contactEmails"
							:key="email + index"
						>
							<template
								:field="`emails[${index}].email`"
								:label="`E-mail ${index + 1}`"
								message="Formato inválido"
								:enableIf="() => contactEmails.length > 0"
								:invalidIf="() => !email.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)"
							/>
						</template>

						<template
							field="zip_code"
							label="CEP"
							message="Formato inválido"
							:enableIf="() => model.address.zip_code.length > 0"
							:invalidIf="() => model.address.zip_code.match(/\d{5}-\d{3}/) === null
							"
							@keyup.enter="buttonZipCodeOkClick"
						/>

						<template
							field="address_number"
							label="Endereço / Número"
							message="Preenchimento obrigatório"
							:enableIf="() => isAddressFilled"
							:invalidIf="() => model.address.number === '' && model.address.street !== ''"
						/>
					</FormValidator>
					<div
						v-if="isEditing"
						class="modal__alert"
					>
						<span class="font-semibold text-[#3f87f5]">Atenção</span>
						a edição destes dados atualizará todos os cálculos já realizados para este contato.
					</div>
					<div
						v-show="isCreatingPjResponsibleParty"
						class="modal__alert modal__alert--warning"
					>
						Cadastre os dados do Administrador/Responsável e clique em 'Continuar' para finalizar o cadastro da pessoa jurídica.
					</div>
					<RadioField
						ref="entity_type"
						v-model="entity_type"
						:options="radioTypeOptionsList"
						:disabled="isCreatingPjResponsibleParty"
						label="Tipo"
						required
						class="w-full mb-4"
					/>

					<div class="mb-4">
						<InputText
							ref="name"
							v-model="model.name"
							:label="isContactTypePf ? 'Nome completo' : 'Nome Fantasia'"
							required
						/>
					</div>

					<div v-show="isContactTypePf">
						<div class="flex flex-row flex-wrap ml-[-9px] mr-[-9px] mt-0 mb-2">
							<InputText
								ref="cpf"
								v-model="model.cpf"
								class="mx-[9px] flex-[1.75_0_0]"
								placeholder="___.___.___-__"
								:custom-mask="{ mask: '###.###.###-##' }"
								label="CPF"
							/>
							<InputText ref="rg"
								v-model="model.rg"
								class="mx-[9px] flex-[1_0_0]"
								label="RG"
							/>
						</div>
						<div :class="[keepSexAndBirthDateImmutable ? 'flex flex-row flex-wrap m-0 py-4 border border-2 rounded-t' : 'flex flex-row flex-wrap ml-[-9px] mr-[-9px] mt-0 mb-2 py-4']">
							<RadioField
								ref="confirm_gender"
								v-model="model.gender"
								class="mx-[9px] flex-[0.815_0_0]"
								label="Sexo"
								:disabled="keepSexAndBirthDateImmutable"
								:options="radioGenderOptionsList"
								required
							/>
							<div class="flex flex-[0.5_0_0] items-center">
								<InputText
									ref="birth_date"
									v-model="model.birth_date"
									class="mx-[9px] flex-[1_0_0]"
									:custom-mask="{ mask: '##/##/####' }"
									:disabled="keepSexAndBirthDateImmutable"
									label="Data de nasc."
									title="Data de nascimento"
									placeholder="__/__/____"
									required
								/>
								<div
									class="client-editor-modal__calc-year w-24 font-semibold text-sm"
								>
									{{ contactAge ? `${contactAge} anos` : '' }}
								</div>
							</div>
						</div>
						<div
							v-if="keepSexAndBirthDateImmutable"
							class="mt-0 mb-4 border border-2 bg-gray-200 rounded-b text-xs pl-2"
						>
							<span>Para garantir a segurança dos resultados, não é possível alterar os dados de sexo e data de nascimento após criar cálculos.
								Caso o preenchimento desses dados esteja incorreto, entre em contato com o nosso suporte.</span>
						</div>
					</div>

					<div v-show="isContactTypePj">
						<div class="flex gap-4 mb-4">
							<InputText
								ref="cnpj"
								v-model="model.cnpj"
								class="w-full"
								:custom-mask="{ mask: '##.###.###/####-##' }"
								label="CNPJ"
								required
							/>
							<InputText
								ref="corporate_name"
								v-model="model.corporate_name"
								class="w-full"
								label="Razão Social"
								required
							/>
						</div>
					</div>

					<div class="flex gap-2 items-center text-sm">
						<span class="font-medium">Mais detalhes:</span>
						<button
							class="h-4 w-4 text-primary self"
							@click="toggleShowMoreDetails"
						>
							<svg
								v-if="!showMoreDetails"
								fill="currentColor"
								viewBox="0 0 24 24"
							><circle
								cx="12.001"
								cy="12.005"
								fill="none"
								r="10.5"
								stroke="currentColor"
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="2"
							/><line
								fill="none"
								stroke="currentColor"
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="2"
								x1="7.001"
								x2="17.001"
								y1="12.005"
								y2="12.005"
							/><line
								fill="none"
								stroke="currentColor"
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="2"
								x1="12.001"
								x2="12.001"
								y1="7.005"
								y2="17.005"
							/></svg>
							<svg
								v-else
								fill="currentColor"
								viewBox="0 0 24 24"
							><circle
								cx="12.001"
								cy="12.005"
								fill="none"
								r="10.5"
								stroke="currentColor"
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="2"
							/><line
								fill="none"
								stroke="currentColor"
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="2"
								x1="7.001"
								x2="17.001"
								y1="12.005"
								y2="12.005"
							/></svg>
						</button>
					</div>

					<div v-show="isContactTypePf && showMoreDetails">
						<div class="flex flex-row flex-wrap ml-[-9px] mr-[-9px] mb-2">
							<InputText
								ref="nationality"
								v-model="model.nationality"
								class="mx-[9px] mt-4 flex-[1_0_0]"
								label="Nacionalidade"
							/>
							<SelectField
								ref="marital_status"
								v-model="model.marital_status"
								class="mx-[9px] mt-4 flex-[1_0_0]"
								label="Estado civil"
								:options="selectMaritalStatusOptionsList"
							/>
						</div>
						<div class="flex flex-row flex-wrap ml-[-9px] mr-[-9px] mb-2">
							<InputText
								ref="occupation"
								v-model="model.occupation"
								class="mx-[9px] flex-[0.49_0_0]"
								label="Profissão"
							/>
						</div>
					</div>

					<div v-show="isContactTypePj && showMoreDetails">
						<div class="flex flex-row flex-wrap ml-[-9px] mr-[-9px] mb-2">
							<InputText
								ref="business_sector"
								v-model="model.business_sector"
								class="mx-[9px] mt-4 flex-[1_0_0]"
								label="Ramo de atividade da empresa"
							/>
						</div>
						<h2 class="block font-semibold mb-1 mt-4 text-gray-500">
							Domicílio Tributário
						</h2>
						<div class="flex flex-row flex-wrap ml-[-9px] mr-[-9px] mb-2">
							<SelectField
								ref="tax_residence_state"
								v-model="model.tax_residence.state"
								class="mx-[9px] flex-[1_0_0]"
								label="Estado"
								:options="selectUfOptionsList"
							/>
							<InputText
								ref="tax_residence_city"
								v-model="model.tax_residence.city"
								class="mx-[9px] flex-[1_0_0]"
								label="Cidade"
							/>
						</div>
						<div class="flex flex-col gap-1 w-3/5">
							<SingleSearchSelect
								ref="responsible_party"
								v-model="responsible_party"
								input-label="Administrador / Responsável"
								placeholder="Digite para buscar um contato"
								label="name"
								:query-function="tagsMultiselectAsyncContact_queryFunction"
								async
							/>
							<button
								class="mt-0 text-xs text-right text-primary font-medium"
								title="Criar contato"
								@click="clickedAddResponsibleParty"
							>
								Criar contato
							</button>
						</div>
					</div>

					<div class="flex gap-4 mb-4 mt-4">

						<div>
							<h2 class="block font-semibold mb-1 text-gray-500">
								Telefones
							</h2>
							<div class="flex items-center">
								<InputText
									v-model="model.phoneAddModel"
									:custom-mask="{ mask: ['(##) ####-####', '(##) #####-####'] }"
									class="w-full"
									label="Adicionar telefone"
									type="phone"
									placeholder="(__) ____-____"
								/>
								<button
									id="btn-add-phone"
									class="ml-1"
									@click="buttonPhoneAddClick"
								>
									<svg
										class="fill-primary w-8 h-8"
										fill="currentColor"
										viewBox="0 0 20 20"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fill-rule="evenodd"
											d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
											clip-rule="evenodd"
										/>
									</svg>
								</button>
							</div>
							<div class="container-phones-items">
								<div
									v-for="(item, index) in contactPhones"
									:key="`contact-${index}`"
									class="bg-blue-100 my-2 p-2 rounded w-full"
									:class="'flex items-center justify-between'"
								>
									<span class="flex-1">
										{{ `(${item.area_code}) ${helpers?.getFormattedPhoneNumber(item.number)}` }}
									</span>
									<button
										class="mr-2"
										:title="item.is_main
											? 'Número principal (favorito)'
											: 'Definir este como principal (favorito)'
										"
										@click="buttonPhoneSetMainClick($event, loadedContact, item)"
									>
										<svg
											:class="['w-5 h-5', item.is_main ? 'fill-amber-400' : 'fill-white']"
											fill="currentColor"
											viewBox="0 0 20 20"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
											/>
										</svg>
									</button>
									<button
										class="text-[#eb5757]"
										@click="buttonPhoneRemoveClick($event, item)"
									>
										<svg
											class="h-5 w-5"
											fill="currentColor"
											viewBox="0 0 24 24"
										>
											<path d="M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19M8.46,11.88L9.87,10.47L12,12.59L14.12,10.47L15.53,11.88L13.41,14L15.53,16.12L14.12,17.53L12,15.41L9.88,17.53L8.47,16.12L10.59,14L8.46,11.88M15.5,4L14.5,3H9.5L8.5,4H5V6H19V4H15.5Z" />
										</svg>
									</button>
								</div>
							</div>
						</div>

						<div>
							<h2 class="block font-semibold mb-1 text-gray-500">
								E-mails
							</h2>
							<div class="flex items-center">
								<InputText
									v-model="model.emailAddModel"
									class="w-full"
									label="Adicionar e-mail"
								/>
								<button
									id="btn-add-email"
									class="ml-1"
									@click="buttonEmailAddClick"
								>
									<svg
										class="fill-primary w-8 h-8"
										fill="currentColor"
										viewBox="0 0 20 20"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fill-rule="evenodd"
											d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
											clip-rule="evenodd"
										/>
									</svg>
								</button>
							</div>
							<div class="container-emails-items">
								<div
									v-for="(item, index) in contactEmails"
									:key="`email-${index}`"
									class="bg-blue-100 flex items-center justify-between my-2 p-2 rounded w-full"
								>
									<span
										class="flex-1 overflow-hidden text-ellipsis"
										:title="item.email"
									>
										{{ item.email }}
									</span>
									<button
										v-if="isEditing && loadedContact"
										class="mr-2"
										:title="item.is_main
											? 'E-mail principal (favorito)'
											: 'Definir este como principal (favorito)'
										"
										@click="buttonEmailSetMainClick($event, loadedContact, item)"
									>
										<svg
											:class="[
												'w-5 h-5',
												item.is_main ? 'fill-amber-400' : 'fill-white'
											]"
											fill="currentColor"
											viewBox="0 0 20 20"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
											/>
										</svg>
									</button>
									<button class="text-[#eb5757]"
										@click="buttonEmailRemoveClick($event, item)"
									>
										<svg class="h-5 w-5"
											fill="currentColor"
											viewBox="0 0 24 24"
										>
											<path
												fill-rule="evenodd"
												d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
												clip-rule="evenodd"
											/>
										</svg>
									</button>
								</div>
							</div>
						</div>

					</div>

					<div class="mb-4 gap">

						<h2 class="block font-semibold mb-1 text-gray-500">
							{{ isContactTypePj ? 'Endereço da Sede' : 'Endereço' }}
						</h2>

						<div class="flex items-center mb-4 gap-2">
							<InputText
								ref="zip_code"
								v-model="model.address.zip_code"
								class=""
								label="CEP"
								placeholder="_____-___"
								:custom-mask="{ mask: '#####-###' }"
								@input="buttonZipCodeOkClick"
							/>
							<div
								v-if="model.address.zip_code.length === 9 && !requesting.loadAddress && !isAddressFilled"
								class="text-xs text-red-500 font-medium"
							>
								CEP não encontrado
							</div>
							<div
								v-if="requesting.loadAddress"
								class="flex items-center justify-center text-sm h-[100px]"
							>
								<svg
									viewBox="25 25 50 50"
									class="w-6 h-6 origin-center spinner"
									fill="#3F87F5"
								>
									<circle
										cx="50"
										cy="50"
										r="20"
									/>
								</svg>
							</div>
							<div
								v-if="isAddressFilled"
								class="flex gap-2 mt-4 mb-4 text-blue-400 font-medium"
							>
								<div>
									<div>
										<span v-if="model.address.street">
											{{ model.address.street }}
										</span>
										<span v-if="model.address.district">
											- {{ model.address.district }}
										</span>
									</div>
									<div>
										<span v-if="model.address.city">
											{{ model.address.city }}
										</span>
										<span v-if="model.address.state">
											/{{ model.address.state }}
										</span>
									</div>
								</div>
							</div>
						</div>

						<div class="flex gap-x-8 mb-4">
							<InputText
								ref="address_number"
								v-model="model.address.number"
								class=""
								label="Número"
								type="number"
								:disabled="!isAddressFilled"
								:required="!!model.address.street"
							/>
							<InputText
								v-model="model.address.extra_info"
								class=""
								:disabled="!isAddressFilled"
								label="Complemento"
							/>
						</div>

					</div>

					<div
						v-if="loadedContact && relatedContacts.length > 0"
						class="mb-4"
					>
						<h2 class="block font-semibold mb-1 text-gray-500">
							Contatos relacionados
						</h2>
						<div class="border border-blue-200 p-2 rounded-md">
							<div
								v-for="(contact, index) in relatedContacts"
								:key="`related-${index}`"
							>
								<router-link
									:to="{name: 'specific-contact', params: {id: contact.id}}"
									class="flex-inline items-center"
								>
									<svg
										class="fill-blue-500 inline h-5 w-5"
										fill="currentColor"
										viewBox="0 0 24 24"
										xmlns="http://www.w3.org/2000/svg"
										aria-hidden="true"
									>
										<path
											clip-rule="evenodd"
											fill-rule="evenodd"
											d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
										/>
									</svg>
									<span class="ml-1 text-blue-500 text-sm hover:border-b hover:border-blue-500">
										{{ contact.name }}
									</span>
								</router-link>
							</div>
						</div>
					</div>

				</div>
				<div
					v-if="isEditing"
					class="bg-gray-100 border-l p-4 w-2/5 mt-4"
				>

					<template v-if="!loadedContact">

						<div class="mb-4">
							<div class="block font-semibold mb-1 text-gray-500 text-xs">
								Anotações
							</div>
							<div class="bg-white border p-2 text-sm">
								Você poderá adicionar anotações após salvar o contato.
							</div>
						</div>

						<div>
							<div class="block font-semibold mb-1 text-gray-500 text-xs">
								Anexos
							</div>
							<div class="bg-white border p-2 text-sm">
								Você poderá adicionar anexos após salvar o contato.
							</div>
						</div>

					</template>
					<template v-else>

						<Loading
							v-if="requesting.loadNotes"
							class="opacity-50 py-7 text-center"
							text="Carregando anotações"
						/>
						<QuickNotes
							v-else
							:contact-id="contactId"
							:notes="notes"
							@note-created="quickNotes_noteCreated"
							@note-deleted="quickNotes_noteDeleted"
							@note-updated="quickNotes_noteUpdated"
						/>

						<hr class="my-4">

						<div class="mb-8 container-attachments">
							<div class="block font-semibold mb-1 text-gray-500 text-xs">
								Anexos
							</div>
							<div class="bg-white border p-2">

								<div v-if="requesting.loadAttachments">
									<Loading
										class="opacity-50 py-7 text-center"
										text="Carregando anexos"
									/>
								</div>
								<div v-else>
									<FileUploadFilePond @updatefiles="attachments_updatefiles" />
								</div>

								<div
									v-if="hasAttachments"
									class="pt-2"
								>
									<hr class="mb-2">
									<table class="w-full">
										<tbody>
											<tr
												v-for="(attachment, index) in attachments.loadedFiles"
												:key="`loaded-${index}`"
												class="odd:bg-gray-200"
											>
												<td
													class="align-center border-b-0 max-w-0 overflow-hidden px-2 py-2 text-ellipsis whitespace-nowrap"
												>
													<span
														class="font-medium text-xs"
														:title="attachment.name"
													>
														{{ attachment.name }}
													</span>
												</td>
												<td class="align-center border-b-0 py-2 text-center w-7">
													<a
														:href="attachment.url"
														target="_blank"
													>
														<svg
															class="inline w-5 h-5"
															fill="currentColor"
															viewBox="0 0 20 20"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																fill-rule="evenodd"
																d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
																clip-rule="evenodd"
															/>
														</svg>
													</a>
												</td>
												<td class="align-center border-b-0 py-2 text-center w-7">
													<button
														title="Excluir"
														@click="buttonAttachmentRemoveClick($event, attachment)"
													>
														<svg
															class="inline w-5 h-5"
															fill="currentColor"
															viewBox="0 0 20 20"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																fill-rule="evenodd"
																d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
																clip-rule="evenodd"
															/>
														</svg>
													</button>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>

						<template v-if="loadedContact.type == 'Client'">

							<hr class="my-4">

							<div class="mb-8 container-calculations">

								<div
									class="font-semibold mb-2 text-gray-500 text-xs"
									:class="'flex items-center justify-between'"
								>
									<span>Cálculos</span>
									<a
										class="bg-blue-500 ml-1 px-1.5 py-0.5 rounded text-white text-[11px]"
										:href="getCreateCalculationUrl()"
										target="_blank"
									>
										+ ADICIONAR
									</a>
								</div>
								<template v-if="calculations.length > 0">
									<div
										v-for="(calculationItem, index) in calculations"
										:key="`calculation-${index}`"
										class="bg-white border mb-1 p-2"
									>
										<div class="text-gray-500 text-xs">
											<span class="inline-block">
												{{ calculationItem.calculation_kind_human }}
											</span>
											<span class="inline-block ml-1">-</span>
											<span class="ml-1">
												{{ luxon?.fromISO(calculationItem.created_at).toFormat("dd/MM/yyyy") }}
											</span>
										</div>
										<a
											class="inline-block text-blue-500 text-sm underline"
											:href="calculationItem.url"
											target="_blank"
										>
											{{ calculationItem.name }}
										</a>
									</div>
								</template>

							</div>

						</template>

						<template v-if="loadedContact.type == 'Lead'">

							<hr class="my-4">

							<div class="mb-8 container-calculations">

								<div
									class="font-semibold mb-2 text-gray-500 text-xs"
									:class="'flex items-center justify-between'"
								>
									<span>Cálculos</span>
									<button
										class="bg-blue-500 ml-1 px-1.5 py-0.5 rounded text-white text-[11px]"
										@click.stop="$emit('open-change-to-client-modal')"
									>
										+ ADICIONAR
									</button>
								</div>

							</div>

						</template>


					</template>

				</div>


			</template>

		</template>
		<template
			v-if="!requesting.loadContact"
			#footer
		>
			<fieldset
				class="flex-1 flex overflow-hidden rounded-b-md"
				:disabled="requesting.loadContact"
			>
				<button
					class="flex-1 bg-gray-200 font-medium p-4 text-gray-500 hover:bg-gray-300 active:bg-gray-200"
					@click="buttonCancelClick"
				>
					{{ isCreatingPjResponsibleParty ? 'VOLTAR' : 'CANCELAR' }}
				</button>
				<button
					class="flex-1 bg-primary font-medium p-4 text-white hover:bg-blue-600 active:bg-primary"
					@click="buttonSaveClick"
				>
					{{ isCreatingPjResponsibleParty ? 'CONTINUAR' : 'SALVAR' }}
				</button>
			</fieldset>
		</template>
	</ModalBasic>

	<Teleport to="body">
		<BlockingOverlay />
	</Teleport>
</template>
<style lang="scss" scoped>
fieldset {
	&:disabled {
		opacity: 0.6;
	}
.field-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 0;
  margin-left: -9px;
  margin-right: -9px;

  &>* {
    margin-top: 1.5em;
    margin-left: 9px;
    margin-right: 9px;
  }
}
}

.container-attachments {
	:deep(.filepond--wrapper) {

		.filepond--root {
			font-family: inherit;
		}

		.filepond--credits {
			text-align: center;
			width: 100%;
		}

		.filepond--panel-root {
			background-color: transparent;
			border: 2px dashed #ddd;
		}

		.filepond--drop-label {
			label {
				font-size: 0.9rem;
				font-weight: 500;
				opacity: 0.75;
			}
		}
	}
}

.modal__alert {
	background-color: #efefef;
    color: #3b424d;
    font-size: 12px;
    padding: 10px 20px;
    margin-right: -20px;
    margin-left: -20px;
    font-weight: 500;
    margin-bottom: 1em;
}

.modal__alert--warning {
	background-color: #fdbe50;
	font-size: 14px;
}
.client-editor-modal__calc-year {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 80px;
    flex: 0 0 80px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-weight: 600;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0;
    font-size: .875rem;
}
</style>
