<script lang="ts">
import FiltersChain from './FiltersChain.vue';

import { DataTableColumn } from '../../models/crm';
import {
	defineComponent, PropType
} from 'vue';

export default defineComponent({
	props: {
		columns: {
			type: Array as PropType<DataTableColumn[]>,
		},
		searchableColumns: {
			type: Object,
		},
		configButtonShow: {
			required: true,
			type: Boolean,
		},
		inputPlaceholder: {
			type: String,
			default: 'Procurar',
		},
	},
	emits: [
		'filters-apply',
		'filters-clear',
		'filters-item-remove',
		'search-input',
		'config-button-click'
	],
	components: {
		FiltersChain,
	},
	data() {
		return {

			search: {
				input: '' as string,
			},

		};
	},
	computed: {

		searchableColumnsWithLabel(): DataTableColumn[] {
			const {columns} = this;
			const searchableColumns = this.searchableColumns?.map((item: string) => item.split(':')[0]);

			const ret = columns?.filter(
				(item) => {
					const {key} = item;
					return searchableColumns.includes(key);
				}
			);

			return ret || [];
		},

		textSearchableColumns(): string {
			return this.searchableColumnsWithLabel?.map(item => item.label).join(', ');
		},

	},
	methods: {

	},
	watch: {

		'search.input'(): void {
			this.$emit('search-input', this.search.input);
		},

	},
});
</script>
<template>
	<div class="flex w-full max-w-full gap-[10px] mb-[10px]">
		<div class="flex-auto">
			<div class="bg-white p-0 rounded flex items-center px-2">
				<svg viewBox="0 0 17 17"
					class="fill-[rgb(118,138,170)] mr-[7px] w-[17px] h-[17px] my-[9px]"
				>
					<path
						d="M16.1306 13.7224L12.6547 10.2466C12.6363 10.2281 12.6146 10.2152 12.5953 10.198C13.2792 9.1605 13.6784 7.91859 13.6784 6.58304C13.6784 2.95001 10.7333 0.00488281 7.10031 0.00488281C3.46734 0.00488281 0.522217 2.95001 0.522217 6.58298C0.522217 10.2159 3.46728 13.1611 7.10025 13.1611C8.43586 13.1611 9.6777 12.7619 10.7152 12.0779C10.7324 12.0972 10.7452 12.1189 10.7637 12.1373L14.2397 15.6133C14.7619 16.1354 15.6084 16.1354 16.1306 15.6133C16.6527 15.0911 16.6527 14.2446 16.1306 13.7224ZM7.10031 10.8807C4.7267 10.8807 2.80257 8.95653 2.80257 6.58298C2.80257 4.20936 4.72676 2.28524 7.10031 2.28524C9.4738 2.28524 11.398 4.20943 11.398 6.58298C11.398 8.95653 9.4738 10.8807 7.10031 10.8807Z"
					/>
				</svg>
				<input v-model="search.input"
					type="text"
					:placeholder="inputPlaceholder"
					class="border-0 rounded flex-1 outline-none p-0 text-xs font-semibold h-[35px]"
				>
			</div>
		</div>
		<div v-if="$parent?.$slots.filters || $slots.filters"
			class="shrink-0"
		>
			<FiltersChain
				@filters-apply="$emit('filters-apply')"
				@filters-clear="$emit('filters-clear')"
				@filters-item-remove="filterItem => { $emit('filters-item-remove', filterItem) }"
			>
				<template #filters>
					<slot name="filters" />
				</template>
			</FiltersChain>
		</div>
		<div
			class="bg-white p-0 rounded min-w-[35px] w-[35px] h-[35px] shrink-0 text-[#ced0d2] hover:bg-[#d6d6d6] hover:text-[#212529]"
		>
			<button v-if="configButtonShow"
				class="w-full h-full flex items-center justify-center"
				title="Configurar"
				@click="$emit('config-button-click')"
			>
				<svg
					viewBox="0 0 24 24"
					fill="currentColor"
					class="w-6 h-6"
				>
					<path d="M12,15.5A3.5,3.5 0 0,1 8.5,12A3.5,3.5 0 0,1 12,8.5A3.5,3.5 0 0,1 15.5,12A3.5,3.5 0 0,1 12,15.5M19.43,12.97C19.47,12.65 19.5,12.33 19.5,12C19.5,11.67 19.47,11.34 19.43,11L21.54,9.37C21.73,9.22 21.78,8.95 21.66,8.73L19.66,5.27C19.54,5.05 19.27,4.96 19.05,5.05L16.56,6.05C16.04,5.66 15.5,5.32 14.87,5.07L14.5,2.42C14.46,2.18 14.25,2 14,2H10C9.75,2 9.54,2.18 9.5,2.42L9.13,5.07C8.5,5.32 7.96,5.66 7.44,6.05L4.95,5.05C4.73,4.96 4.46,5.05 4.34,5.27L2.34,8.73C2.21,8.95 2.27,9.22 2.46,9.37L4.57,11C4.53,11.34 4.5,11.67 4.5,12C4.5,12.33 4.53,12.65 4.57,12.97L2.46,14.63C2.27,14.78 2.21,15.05 2.34,15.27L4.34,18.73C4.46,18.95 4.73,19.03 4.95,18.95L7.44,17.94C7.96,18.34 8.5,18.68 9.13,18.93L9.5,21.58C9.54,21.82 9.75,22 10,22H14C14.25,22 14.46,21.82 14.5,21.58L14.87,18.93C15.5,18.67 16.04,18.34 16.56,17.94L19.05,18.95C19.27,19.03 19.54,18.95 19.66,18.73L21.66,15.27C21.78,15.05 21.73,14.78 21.54,14.63L19.43,12.97Z" />
				</svg>
			</button>
		</div>
	</div>
</template>
<style lang="scss" scoped>
</style>