<script lang="ts">
import {
	EventBus,
	Helpers,
} from '../../../models/crm';
import {
	defineComponent, inject
} from 'vue';
import {
	Store,
	mapState
} from 'pinia';
import { useAccountStore } from '../../../stores/account';
import {
	useCRMStore, CRMState
} from '../../../stores/crm';

import storage from '../../../services/storage';

export default defineComponent ({
	data() {
		return {
			store: {} as Store<'crm', CRMState, {
				sidebar(state: CRMState): {
						isCollapsed: boolean;
					};
				}, {
				setSidebarCollapse(value: boolean): void;
				toggleSidebarCollapse(): void;
			}>,
			eventBus: inject<EventBus>('eventBus'),
			helpers: inject<Helpers>('helpers'),
			isFinanceModuleOpen: false,
		};
	},
	computed: {
		...mapState(useCRMStore, [ 'sidebar' ]),
		...mapState(useAccountStore, [ 'user' ]),
	},
	mounted() {
		const crmStore = useCRMStore();
		this.store = crmStore;

		this.setSidebarStateInitial();

		this.isFinanceModuleOpen = this.$route.fullPath.includes('financas');
	},
	methods: {
		getActiveClass(name: string): boolean {
			return this.$route.name === name;
		},

		setSidebarStateInitial(): void {
			this.store.setSidebarCollapse(storage.getItem('localStorage', 'crm/ui/sidebar/is_collapsed') === 'true');
		},

		// Events

		buttonCollapseClick(): void {
			this.store.toggleSidebarCollapse();
		},

		toggleFinanceSidebar(): void {
			this.isFinanceModuleOpen = !this.isFinanceModuleOpen;
		}

	},
});
</script>

<template>
	<div
		id="sidebar-crm"
		:class="{ collapsed: sidebar.isCollapsed }"
	>
		<div class="container-inner-wrapper">
			<div>
				<div class="mb-2 mt-4 sidebar-title">
					<span>Gestão</span>
				</div>
				<nav>
					<router-link
						v-tippy="helpers?.getTippy('Painel')"
						:to="{name: 'crm-panel'}"
						:class="{ active: getActiveClass('crm-panel') }"
					>
						<div class="icon">
							<img
								src="/img/icons/icon-dashboard.svg"
								alt=""
							>
						</div>
						<div class="button-label">
							<div>Painel</div>
						</div>
					</router-link>
					<router-link
						v-tippy="helpers?.getTippy('Contatos')"
						:to="{name: 'contacts'}"
						:class="{ active: getActiveClass('contacts') }"
					>
						<div class="icon">
							<img
								src="/img/icons/icon-contacts.svg"
								alt=""
							>
						</div>
						<div class="button-label">
							<div>Contatos</div>
						</div>
					</router-link>
					<router-link
						v-tippy="helpers?.getTippy('Casos')"
						:to="{name: 'legal-cases'}"
						:class="{ active: getActiveClass('legal-cases') }"
					>
						<div class="icon">
							<img
								src="/img/icons/icon-cases.svg"
								alt=""
							>
						</div>
						<div class="button-label">
							<div>Casos</div>
						</div>
					</router-link>
					<router-link
						v-tippy="helpers?.getTippy('Tarefas')"
						:to="{name: 'tasks'}"
						:class="{ active: getActiveClass('tasks') }"
					>
						<div class="icon">
							<img
								src="/img/icons/icon-tasks.svg"
								alt=""
							>
						</div>
						<div class="button-label">
							<div>Tarefas</div>
						</div>
					</router-link>
					<button
						v-tippy="helpers?.getTippy('Finanças - Gestão Financeira')"
						@click="toggleFinanceSidebar"
					>
						<span class="icon icon-finance">
							<img
								src="/img/icons/icon-finance.svg"
								alt=""
							>
						</span>
						<template v-if="!sidebar.isCollapsed">
							<div class="button-label menu-item-new">
								<div>Finanças</div>

							</div>
							<div
								v-if="!isFinanceModuleOpen"
								class="h-6 w-6 ml-14 mr-0"
							>
								<svg viewBox="0 0 24 24">
									<path
										fill="rgb(51, 51, 51)"
										d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"
									/>
								</svg>

							</div>
							<div
								v-else
								class="h-6 w-6 ml-14 mr-0"
							>
								<svg viewBox="0 0 24 24">
									<path
										fill="rgb(51, 51, 51)"
										d="M12 11.12L7.41 15.71L6 14.29L12 8.29L18 14.29L16.59 15.71L12 11.12Z"
									/>
								</svg>
							</div>
						</template>
					</button>
					<nav
						v-show="isFinanceModuleOpen"
						class="ml-4"
					>
						<router-link
							v-tippy="helpers?.getTippy('Painel')"
							:to="{name: 'finance-panel'}"
							:class="{ active: getActiveClass('finance-panel') }"
						>
							<div class="icon">
								<img
									src="/img/icons/icon-dashboard.svg"
									alt=""
								>
							</div>
							<div class="button-label">
								<div>Painel</div>
							</div>
						</router-link>
						<router-link
							v-tippy="helpers?.getTippy('Entradas - Cadastrar e visualizar os valores a receber')"
							:to="{name: 'incomes'}"
							:class="{ active: getActiveClass('incomes') }"
						>
							<div class="icon">
								<img
									src="/img/icons/icon-finance-incomes.svg"
									alt=""
								>
							</div>
							<div class="button-label">
								<div>Entradas</div>
							</div>
						</router-link>
						<router-link
							v-tippy="helpers?.getTippy('Saídas - Cadastrar e visualizar os valores a pagar')"
							:to="{name: 'expenses'}"
							:class="{ active: getActiveClass('expenses') }"
						>
							<div class="icon">
								<img
									src="/img/icons/icon-finance-expenses.svg"
									alt=""
								>
							</div>
							<div class="button-label">
								<div>Saídas</div>
							</div>
						</router-link>
						<router-link
							v-tippy="helpers?.getTippy('Relatórios - Movimento de Caixa e Gráfico de entradas e saídas por mês')"
							:to="{name: 'reports'}"
							:class="{ active: getActiveClass('reports') }"
						>
							<div class="icon">
								<img
									src="/img/icons/icon-finance-reports.svg"
									alt=""
								>
							</div>
							<div class="button-label">
								<div>Relatórios</div>
							</div>
						</router-link>
						<router-link
							v-tippy="helpers?.getTippy('Configurações de Centros de Custos, Entradas e Saídas')"
							:to="{name: 'settings'}"
							:class="{ active: getActiveClass('settings') }"
						>
							<div class="icon">
								<img
									src="/img/icons/icon-finance-settings.svg"
									alt=""
								>
							</div>
							<div class="button-label">
								<div>Configurações</div>
							</div>
						</router-link>
					</nav>
				</nav>
			</div>
			<div class="my-3 container-button-collapse">
				<button @click="buttonCollapseClick">
					<span>
						<svg
							class="w-6 h-6"
							fill="currentColor"
							viewBox="0 0 20 20"
							xmlns="http://www.w3.org/2000/svg"
						><path
							fill-rule="evenodd"
							d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
							clip-rule="evenodd"
						/></svg>
					</span>
				</button>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
$sidebar-collapsed-width: 70px;
$sidebar-width: 190px;

#sidebar-crm {
    display: flex;

    background-color: #B5D0E8;
    width: $sidebar-width;
    z-index: 1;

    &.collapsed {
        width: $sidebar-collapsed-width;

        nav {
            a, button {
                width: 100%;

                .icon {
                    width: $sidebar-collapsed-width;
                }
            }
        }

        .container-inner-wrapper {
            min-width: 0;
        }

        .button-label {
            margin-left: 0;
            opacity: 0;
            overflow: hidden;
            width: 0;
        }

        .sidebar-title {
            justify-content: center;

            padding: 0;
            width: $sidebar-collapsed-width;
        }

        .container-button-collapse {
            width: $sidebar-collapsed-width;

            button {
                span {
                    transform: rotate(0deg);
                }
            }
        }

        .tooltip:before, .tooltip:after {
            display: inline-block;
        }
    }

    nav {
        a, button {
            display: flex;
            align-items: center;

            font-size: 12px;
            font-weight: 500;
            font-weight: 500;
            line-height: 135%;
            padding: 12px;
            width: 100%;

            &:hover {
                background-color: #CBE5FF;
            }

            &.active {
                background-color: #CBE5FF;
                border-right: 5px solid #3f87f5;
            }

            .icon {
                display: flex;
                align-items: center;
                justify-content: center;

                height: 24px;
                width: 24px;

                img {
                    display: inline-block;
                    max-width: 24px;
                }
            }
			.icon-finance {
				height: 26px;
				width: 26px;
			}
        }
    }

    .container-inner-wrapper {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        min-width: $sidebar-width;
    }

    .button-label {
        margin-left: 10px;
        opacity: 1;
    }

	.menu-item-new .main-title::after {
      @apply px-1 py-0.5 mx-2 text-white rounded-sm;

      content: "Novo";
      font-size: 9px;
      background-color: #007bff;
    }

    .sidebar-title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        font-weight: 600;
        font-size: 12px;
        padding: 0 12px;
    }

    .container-button-collapse {
        display: flex;
        justify-content: flex-end;

        width: $sidebar-width;

        button {
            border: 1px solid #3C424C;
            border-right: none;
            border-radius: 3px 0 0 3px;
            display: inline-flex;
            opacity: 0.5;
            padding: 0.25em 0;

            span {
                transform: rotate(180deg);

                svg {
                    fill: #3C424C;
                    height: 20px;
                    width: 20px;
                }
            }
        }
    }
}
</style>
