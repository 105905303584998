import { defineStore } from 'pinia';
import {
	Attachment,
	Calculation,
	ClientsPreview, Contact
} from '../../../models/crm';
import serviceContacts from '../../../services/API/crm/contacts';
import servicePlan from '../../../services/plan';
import { useAccountStore } from '../../account';

type ContactsState = {
	contactsList: Contact[];
    maxContacts: number;
	isRequestingContacts: boolean;
	searchedContacts: Contact[];
	searchMaxContacts: number;
	clientsPreview: ClientsPreview;
}

export const useContactsStore = defineStore('contacts', {
	state: (): ContactsState => ({
		contactsList: [] as Contact[],
		maxContacts: 100,
		isRequestingContacts: false,

		searchedContacts: [] as Contact[],
		searchMaxContacts: 0,

		clientsPreview: {} as ClientsPreview,
	}),
	actions: {
		setContactsList(value: Contact[]): void {
			this.contactsList = value;
		},

		setMaxContacts(value: number): void {
			this.maxContacts = value;
		},

		addContactsToList(value: Contact[]): void {
			this.contactsList = this.contactsList.concat(value);
		},

		async deleteContact(contactId: number | string): Promise<void> {
			await serviceContacts.delete(contactId);

			const index = this.contactsList.findIndex(contact => contact.id == contactId);

			if (index === -1) return;

			this.contactsList.splice(index, 1);
		},

		async loadContacts(): Promise<void> {
			this.isRequestingContacts = true;

			const { data } = await serviceContacts.getAll(9999, 1, 'name:asc', '');

			this.contactsList = data.contacts.map(contact => {
				const mappedContact: Contact = {
					...contact,
					attachments: []
				};

				return mappedContact;
			});

			this.isRequestingContacts = false;

			this.maxContacts = data.contacts.length;
		},

		async paginatedLoadContacts({
			perPage = 50, page = 1, orderBy, filters
		} : {perPage: number, page: number, orderBy: string, filters: string}): Promise<void> {
			this.isRequestingContacts = true;

			const { data: mainData } = await serviceContacts.getAll(perPage, page, orderBy, filters);

			this.maxContacts = mainData.total_count;

			if (this.contactsList.length === 0) {
				this.contactsList = mainData.contacts.map(contact => {
					const mappedContact: Contact = {
						...contact,
						attachments: []
					};

					return mappedContact;
				});

				this.isRequestingContacts = false;

				return;
			}

			if (this.contactsList.length === perPage * (page - 1)) {
				this.contactsList = this.contactsList.concat(
					mainData.contacts.map(contact => {
						const mappedContact: Contact = {
							...contact,
							attachments: []
						};

						return mappedContact;
					})
				);

				this.isRequestingContacts = false;

				return;
			}

			if (this.contactsList.length > perPage * (page - 1)) {
				if (page === 1) {
					this.contactsList = mainData.contacts.map(contact => {
						const mappedContact: Contact = {
							...contact,
							attachments: []
						};

						return mappedContact;
					});

					this.isRequestingContacts = false;

					return;
				}

				const { data: stepData } =
					await serviceContacts.getAll(perPage * page, 1, orderBy, filters);

				this.contactsList = stepData.contacts.map(contact => {
					const mappedContact: Contact = {
						...contact,
						attachments: []
					};

					return mappedContact;
				});

				this.isRequestingContacts = false;

				return;
			}

			throw new Error('Impossível paginar! Provavelmente a aplicação se encontra na página final');
		},
		async searchContactsByName({
			name, perPage = 50, page = 1, orderBy, filters
		}: {
				name: string;
                perPage: number;
                page: number;
				orderBy: string;
				filters: string;
            }
		): Promise<void> {
			const { data: mainData } = await serviceContacts.searchByName({
				name, orderBy, filters
			}, perPage, page);

			if (name.length > 0) {
				this.searchMaxContacts = mainData.total_count;
			} else {
				this.searchMaxContacts = 0;
			}

			if (this.searchedContacts.length === 0) {
				this.searchedContacts = mainData.contacts.map(contact => {
					const mappedContact: Contact = {
						...contact,
						attachments: []
					};

					return mappedContact;
				});

				return;
			}

			if (this.searchedContacts.length === perPage * (page - 1)) {
				this.searchedContacts = this.searchedContacts.concat(
					mainData.contacts.map(contact => {
						const mappedContact: Contact = {
							...contact,
							attachments: []
						};

						return mappedContact;
					})
				);

				return;
			}

			if (this.searchedContacts.length > perPage * (page - 1)) {
				if (page === 1) {
					this.searchedContacts = mainData.contacts.map(contact => {
						const mappedContact: Contact = {
							...contact,
							attachments: []
						};

						return mappedContact;
					});

					return;
				}

				const { data: stepData } = await serviceContacts.searchByName({
					name, orderBy, filters
				}, perPage * page, 1);

				this.searchedContacts = stepData.contacts.map(contact => {
					const mappedContact: Contact = {
						...contact,
						attachments: []
					};

					return mappedContact;
				});

				return;
			}

			throw new Error('Impossível pesquisar! Provavelmente a aplicação se encontra na página final');
		},

		useNormalContactsList(): void {
			this.searchMaxContacts = 0;
			this.searchedContacts = [];
		},

		async getAllClientsPreview(): Promise<void> {
			const {data} = await serviceContacts.getAllClientsPreview();

			this.clientsPreview = data;
		},

		async createClientFromLead(officeId: string | number, contactId: string | number): Promise<void> {
			const planStore = useAccountStore();
			const clientLimitErrorMessage = 'Limite de cota atingido e não foi possível atualizar seu plano. Entre em contato com nosso suporte';

			if (!this.clientsPreview?.meta) await this.getAllClientsPreview();

			if (!planStore.currentPlan?.max_clients) await planStore.getPlan(officeId);

			if (planStore.currentPlan.max_clients > 0
					&& this.clientsPreview.meta.total_count >= planStore.currentPlan.max_clients
			) {
				if (planStore.nextPlan) {
					await servicePlan.changePlan(planStore.nextPlan.id);
				} else {
					throw new Error(clientLimitErrorMessage);
				}
			}

			await serviceContacts.createClientFromLead(
				contactId,
				officeId
			);

			await this.getAllClientsPreview();

			await this.paginatedLoadContacts({
				page: 1, perPage: 25, orderBy: 'name:asc', filters: ''
			});
		},

		async getContactAttachments(id: string | number): Promise<Attachment[]> {
			const { data } = await serviceContacts.getAttachmentsByContactId(id);

			this.contactsList = this.contactsList.map(contact => contact.id == id
				? {
					...contact, attachments: data.attachments
				}
				: contact
			);

			return data.attachments;
		},

		async getContactCalculations(id: string | number): Promise<Calculation[]> {
			const { data } = await serviceContacts.getCalculationsByContactId(id);

			this.contactsList = (this.contactsList as Contact[]).map(contact => contact.id == id
				? {
					...contact, calculations: data.calculations
				}
				: contact
			);

			return data.calculations;
		},

		async getContactById(id: string | number, options?:
			{withAttachments?: boolean, withReceivedEmails?: boolean}): Promise<Contact> {
			const { data } = await serviceContacts.getById(id);

			if (options?.withAttachments) {
				try {
					const attachments = await this.getContactAttachments(id);
					data.attachments = attachments;
				} catch (error) {
					console.error(error);
					data.attachments = [];
				}
			}

			if (options?.withReceivedEmails) {
				try {
					const receivedEmails = await serviceContacts.getReceivedEmails(id);
					data.received_emails = receivedEmails;
				} catch (error) {
					console.error(error);
					data.received_emails = [];
				}
			}

			this.contactsList = this.contactsList.map(contact => contact.id == id
				? {
					...contact, ...data
				}
				: contact
			);

			return data;
		}
	},
});
