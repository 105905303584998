<script lang="ts">
import { SmartTagz } from 'smart-tagz';
import 'smart-tagz/dist/smart-tagz.css';
import {
	defineComponent, PropType
} from 'vue';
import { Tag } from '../../models/crm';

export default defineComponent({
	props: {
		placeholder: {
			default: 'Digite aqui para adicionar a tag...',
			type: String,
		},
		initialTags: {
			default: () => [],
			type: Array as PropType<Tag[]>,
		},
		autocompleteData: {
			type: Array as PropType<Tag[]>,
		},
	},
	emits: [
		'tags-changed',
	],
	components: {
		SmartTagz,
	},
	data() {
		return {

			classNames: {
				'tag_name': 'font-semibold',
			},

			theme: {
				primary: '#3E87F5',
				tagTextColor: '#fff',
			},

		};
	},
	computed: {

		defaultTags(): string[] {
			return this.getTagsTitleOnly(this.initialTags);
		},

		key(): string {
			// base64 key from tag inputs to recycle component and avoid stale data
			return btoa(JSON.stringify(this.defaultTags) + JSON.stringify(this.sources));
		},

		sources(): string[] {
			return this.autocompleteData?.map(item => item.title) || [];
		},

	},
	methods: {

		getTagsTitleOnly(tags: Tag[]): string[] {
			return tags.map(item => item.title);
		},

		// Events

		onChanged(tags: Tag[]): void {
			this.$emit('tags-changed', tags);
		},

	},
	beforeMount() {
		this.$emit('tags-changed', this.defaultTags);
	},
});
</script>
<template>
	<div class="component-tags-smart-tagz border border-[#DCDDDF]">
		<smart-tagz
			autosuggest
			editable
			:key="key"
			:allow-duplicates="false"
			:class-names="classNames"
			:default-tags="defaultTags"
			:input-placeholder="placeholder"
			:max-tags="20"
			:sources="sources"
			:on-changed="onChanged"
			:theme="theme"
		/>
		<hr class="my-3">
		<div class="opacity-75 text-xs">
			Para adicionar uma tag, escreva o nome da tag e aperte Enter.
			Para editar, clique duas vezes.
			Você pode adicionar até 20 tags.
		</div>
	</div>
</template>
<style lang="scss" scoped>
.component-tags-smart-tagz {
    background-color: #F3F4F6;
    border-radius: 6px;
    padding: 0.75rem;
}


.tags-main {
    padding: 0;
}

:deep() {
    .tags-container {
        gap: 8px;

        .tag-container {
            border-radius: 100px;
            margin: 0;
            padding: 0.25rem 0.25rem 0.25rem 0.5rem;
            .tag-name {
                font-size: 12px;
				line-height: 18px;
            }
        }

        .input-wrapper {
            margin: 0;

            input {
                background-color: #fff;
                border-radius: 3px;
                border: 1px solid #DCDDDF;
                font-size: 12px;
				line-height: 18px;
				font-weight: 600;
                padding: 0.35rem;
                width: 17rem;
            }
        }
    }
}
</style>