import {
	Category, CategoryToSend, CostCenter,
} from './../../../models/financial';
import { defineStore } from 'pinia';
import serviceIncomeCategories from '../../../services/API/financial/incomeCategories';
import serviceExpenseCategories from '../../../services/API/financial/expenseCategories';
import serviceCostCenters from '../../../services/API/financial/costCenters';

export type CategoriesState = {
	income_categories: Category[];
    expense_categories: Category[];
    cost_centers: CostCenter[];
	maxIncomeCategories: number;
	maxExpenseCategories: number;
	maxCostCenters: number;
}

export const useCategoriesStore = defineStore('categories', {
	state: (): CategoriesState => ({
		income_categories: [],
		expense_categories: [],
		cost_centers: [],
		maxIncomeCategories: 0,
		maxExpenseCategories: 0,
		maxCostCenters: 0,
	}),
	actions: {
		async fetchIncomeCategories(orderBy: string): Promise<boolean> {
			const response = await serviceIncomeCategories.getAll(orderBy);

			if (response.status !== 200) {
				return false;
			}

			this.income_categories = response.data.income_categories;
			this.maxIncomeCategories = response.data.total_count;

			return true;
		},
		async fetchExpenseCategories(orderBy: string): Promise<boolean> {
			const response = await serviceExpenseCategories.getAll(orderBy);

			if (response.status !== 200) {
				return false;
			}

			this.expense_categories = response.data.expense_categories;
			this.maxExpenseCategories = response.data.total_count;

			return true;
		},

		async fetchCostCenters(orderBy: string): Promise<boolean> {
			const response = await serviceCostCenters.getAll(orderBy);

			if (response.status !== 200) {
				return false;
			}

			this.cost_centers = response.data.cost_centers;
			this.maxCostCenters = response.data.total_count;

			return true;
		},

		async deleteIncomeCategory(id: number): Promise<boolean> {
			const response = await serviceIncomeCategories.delete(id);

			if (response.status !== 204) {
				return false;
			}

			const index = this.income_categories.findIndex(entry => entry.id === id);

			if (index === -1) return false;

			this.income_categories.splice(index, 1);

			return true;
		},

		async deleteExpenseCategory(id: number): Promise<boolean> {
			const response = await serviceExpenseCategories.delete(id);

			if (response.status !== 204) {
				return false;
			}

			const index = this.expense_categories.findIndex(entry => entry.id === id);

			if (index === -1) return false;

			this.expense_categories.splice(index, 1);

			return true;
		},

		async deleteCostCenter(id: number): Promise<boolean> {
			const response = await serviceCostCenters.delete(id);

			if (response.status !== 204) {
				return false;
			}

			const index = this.cost_centers.findIndex(entry => entry.id === id);

			if (index === -1) return false;

			this.cost_centers.splice(index, 1);

			return true;
		},

		async createIncomeCategory(data: CategoryToSend): Promise<boolean> {
			const response = await serviceIncomeCategories.create(data);

			if (response.status !== 200) {
				return false;
			}

			this.income_categories.push(response.data);

			return true;
		},

		async createExpenseCategory(data: CategoryToSend): Promise<boolean> {
			const response = await serviceExpenseCategories.create(data);

			if (response.status !== 200) {
				return false;
			}

			this.expense_categories.push(response.data);

			return true;
		},

		async createCostCenter(data: CategoryToSend): Promise<boolean> {
			const response = await serviceCostCenters.create(data);

			if (response.status !== 200) {
				return false;
			}

			this.cost_centers.push(response.data);

			return true;
		},

		async editIncomeCategory(id: number, data: CategoryToSend): Promise<boolean> {
			const response = await serviceIncomeCategories.update(id, data);

			if (response.status !== 200) {
				return false;
			}

			this.income_categories = this.income_categories.map(category => {
				if (category.id === id) {
					return response.data;
				}
				return category;
			});

			return true;
		},

		async editExpenseCategory(id: number, data: CategoryToSend): Promise<boolean> {
			const response = await serviceExpenseCategories.update(id, data);

			if (response.status !== 200) {
				return false;
			}

			this.expense_categories = this.expense_categories.map(category => {
				if (category.id === id) {
					return response.data;
				}
				return category;
			});

			return true;
		},

		async editCostCenter(id: number, data: CategoryToSend): Promise<boolean> {
			const response = await serviceCostCenters.update(id, data);

			if (response.status !== 200) {
				return false;
			}

			this.cost_centers = this.cost_centers.map(costCenter => {
				if (costCenter.id === id) {
					return response.data;
				}
				return costCenter;
			});

			return true;
		},
	},
});