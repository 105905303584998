<script lang="ts">
import {
	vMaska, MaskInputOptions
} from 'maska';
import {
	defineComponent, PropType
} from 'vue';

import InfoTooltip from '../../components/Form/InfoTooltip.vue';
import { MaskaValues } from '../../models/crm';

type InputAttributes = {
	id: string;
	class: string;
	type: string;
}

export default defineComponent({
	props: {
		disabled: {
			default: false,
			type: Boolean,
		},
		label: {
			required: true,
			type: String,
		},
		customId: {
			type: String,
		},
		customMask: {
			default: () => ({}),
			type: Object as PropType<MaskInputOptions>,
		},
		infoTooltip: {
			type: String,
		},
		modelValue: String,
		multiline: {
			default: false,
			type: Boolean,
		},
		multilineRows: {
			default: 3,
			type: Number,
		},
		placeholder: {
			type: String,
		},
		required: {
			default: false,
			type: Boolean,
		},
		type: {
			type: String,
		},
		autofocus: {
			default: false,
			type: Boolean,
		},
	},
	emits: [
		'blur',
		'update:modelValue',
		'maska-value',
	],
	directives: {
		maska: vMaska,
	},
	components: {
		InfoTooltip,
	},
	data() {
		return {

			inputCssClasses: '' as string,

			valueMaska: {
				masked: '',
				unmasked: '',
				completed: false,
			} as MaskaValues,

		};
	},
	computed: {

		inputAttributes(): InputAttributes {
			return {
				id: `form-control-input_${this.customId || this.normalizeStr(this.label)}`,
				class: 'flex-1 outline-none pb-[6px] px-2 w-full text-xs leading-[18px] font-semibold',
				type: 'text',
			};
		},

		maskOptions(): MaskInputOptions {
			if (Object.keys(this.customMask).length > 0) return this.customMask;
			return this.predefinedMask;
		},

		mustApplyMaska(): boolean {
			return !!this.customMask || !!this.predefinedMask;
		},

		predefinedMask(): MaskInputOptions {
			const ret = {} as MaskInputOptions;

			if (this.type === 'money') {
				ret.mask = 'D.DD#,##';
				ret.tokens = {
					D: {
						pattern: /[0-9]/,
						repeated: true,
					},
				};
				ret.reversed = true;
				ret.preProcess = (value): string => {
					return value.replace('R$ ', '');
				};
				ret.postProcess = (value): string => {
					if (value) {
						return `R$ ${value.replace(/^\./, '').replace(/^,/, '')}`;
					}
					return value;
				};

				return ret;
			}

			if (this.type === 'decimal') {
				ret.mask = 'D.DD#,##';
				ret.tokens = {
					D: {
						pattern: /[0-9]/,
						repeated: true,
					}
				};
				ret.reversed = true;

				return ret;
			}

			if (this.type === 'phone') {
				ret.mask = [
					'(##) ####-####',
					'(##) #####-####',
				];

				return ret;
			}

			if (this.type === 'number') {
				ret.mask = 'D';
				ret.tokens = {
					D: {
						pattern: /[0-9]/,
						repeated: true,
					}
				};

				return ret;
			}

			return ret;
		},

	},
	methods: {

		normalizeStr(str: string): string {
			return str.toLocaleLowerCase().replace(/\s/g, '-').normalize('NFD').replace(/[\u0300-\u036f]/g, '');
		},

		inputFieldBlur(): void {
			this.$emit('blur', this.valueMaska);
		},

		inputFieldInput(e:Event): void {
			this.$emit('update:modelValue', (<HTMLInputElement>e.target)?.value);

			this.$emit('maska-value', this.valueMaska);
		},

	},
});
</script>
<template>
	<div
		:class="[
			'border rounded overflow-hidden form-control-input-text border-[#DCDDDF]',
			{ 'bg-gray-200 disabled': disabled }
		]"
	>
		<label
			:for="`form-control-input_${customId || normalizeStr(label)}`"
			class="flex font-semibold px-2 pt-2 text-gray-500 text-xs"
		>
			<span>{{ label }}</span>
			<span v-if="required"
				class="ml-1 text-red-600"
			>*</span>
			<InfoTooltip v-if="infoTooltip"
				content="infoTooltip"
			/>
		</label>

		<textarea
			v-if="multiline"
			:id="inputAttributes.id"
			:class="inputAttributes.class"
			:disabled="disabled"
			:placeholder="placeholder"
			:rows="multilineRows"
			:value="modelValue"
			@input="inputFieldInput"
		/>
		<template v-else>
			<!-- NOT multiline (textarea) -->
			<template v-if="mustApplyMaska">
				<input
					v-maska:[maskOptions]="valueMaska"

					:id="inputAttributes.id"
					:class="inputAttributes.class"
					:disabled="disabled"
					:placeholder="placeholder"
					:value="modelValue"
					:type="inputAttributes.type"
					:autofocus="autofocus"
					@blur="inputFieldBlur"
					@input="inputFieldInput"
				>
			</template>
			<template v-else>
				<input
					:id="inputAttributes.id"
					:class="inputAttributes.class"
					:disabled="disabled"
					:placeholder="placeholder"
					:value="modelValue"
					:type="inputAttributes.type"
					:autofocus="autofocus"
					@blur="inputFieldBlur"
					@input="inputFieldInput"
				>
			</template>
		</template>

	</div>
</template>
<style lang="scss" scoped>

.form-control-input-text {

    &.disabled {
        opacity: 0.5;
    }

    &.validation-error {
        border-color: #dc3545;
    }

    input {
        background-color: transparent;
    }
}
</style>
