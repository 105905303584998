<script lang="ts">
import {
	PropType, defineComponent, inject
} from 'vue';
import EditorJs from '../../../../components/Editor/EditorJs.vue';
import ModalBasic from '../../../../components/Modal/ModalBasic.vue';
import {
	EventBus, LegalCaseNote, ContactNote
} from '../../../../models/crm';
import serviceLegalCases from '../../../../services/API/crm/legalCases';
import serviceContacts from '../../../../services/API/crm/contacts';

export default defineComponent({
	components: {
		EditorJs,
		ModalBasic,
	},
	props: {
		entityId: {
			required: true,
			type: [ Number, String ],
		},
		isEdit: {
			default: false,
			type: Boolean,
		},
		note: Object as PropType<LegalCaseNote | ContactNote>,
		noteType: {
			required: true,
			type: String as () => 'legalCase' | 'contact',
		},
	},
	emits: [
		'note-created',
		'note-updated'
	],
	data() {
		return {
			inData: '' as string,
			eventBus: inject<EventBus>('eventBus'),

		};
	},
	watch: {
		note: {
			deep: true,
			immediate: true,
			handler(newValue?: LegalCaseNote | ContactNote, oldValue?: LegalCaseNote | ContactNote) {
				if (newValue?.content && newValue?.content !== oldValue?.content) {
					this.inData = newValue.content || '';
				}
			},
		},
	},
	methods: {
		async save(): Promise<void> {
			const entityId = Number(this.entityId);
			const { html: content } = await (this.$refs.editor as typeof EditorJs).getOutput();

			this.eventBus?.emit('BlockingOverlay/show', {
				text: 'Aguarde, salvando anotação',
			});

			try {
				if (this.noteType === 'legalCase') {
					if (this.isEdit && this.note?.id) {
						await serviceLegalCases.noteUpdate({
							noteId: this.note.id,
							content,
							legalCaseId: entityId,
						});
					} else {
						await serviceLegalCases.noteCreate({
							content,
							legalCaseId: entityId,
						});
					}
				} else if (this.noteType === 'contact') {
					if (this.isEdit && this.note?.id) {
						await serviceContacts.noteUpdate({
							noteId: this.note.id,
							content,
							contactId: entityId,
						});
					} else {
						await serviceContacts.noteCreate({
							content,
							contactId: entityId,
						});
					}
				}

				this.eventBus?.emit('Toast/add', {
					content: this.isEdit ? 'Anotação atualizada com sucesso' : 'Anotação criada com sucesso',
					type: 'success',
				});

				(this.$refs.modal as typeof ModalBasic).hide();

				this.$emit(this.isEdit ? 'note-updated' : 'note-created');
			} catch (err) {
				console.error(err);

				this.eventBus?.emit('Toast/add', {
					content: 'Falha ao salvar anotação',
					type: 'error',
				});
			} finally {
				this.eventBus?.emit('BlockingOverlay/hide');
			}
		},

		show(): void {
			if (!this.isEdit) {
				(this.$refs.editor as typeof EditorJs).clear();
			}
			(this.$refs.modal as typeof ModalBasic).show();
		},

		buttonCancelClick(): void {
			(this.$refs.modal as typeof ModalBasic).hide();
		},

		buttonSaveClick(): void {
			this.save();
		},

		modal_hide(): void {
			this.$router.back();
		},
	},
});
</script>

<template>
	<ModalBasic
		ref="modal"
		size="md"
		:title="isEdit ? 'Editar anotação' :'Adicionar anotação'"
		@hide="modal_hide"
	>
		<template #content>
			<div class="p-4">
				<div class="border py-1 px-2 rounded">
					<EditorJs
						ref="editor"
						:in-data="inData"
						class-holder="min-h-[200px]"
						placeholder="Texto da anotação..."
					/>
				</div>
			</div>
		</template>
		<template #footer>
			<div class="flex-1 flex overflow-hidden rounded-b-md">
				<button
					class="flex-1 bg-gray-200 font-medium p-4 text-gray-500 hover:bg-gray-300 active:bg-gray-200"
					@click="buttonCancelClick"
				>
					CANCELAR
				</button>
				<button
					class="flex-1 bg-primary font-medium p-4 text-white hover:bg-blue-600 active:bg-primary"
					@click="buttonSaveClick"
				>
					SALVAR
				</button>
			</div>
		</template>
	</ModalBasic>
</template>

<style lang="scss" scoped>
:deep(.codex-editor) {
    min-height: 10rem;

    .codex-editor__redactor {
        padding-bottom: 0 !important;
    }
}
</style>
