<script lang="ts">
import {
	defineComponent, inject
} from 'vue';
import {
	EventBus, Helpers, Lodash, Luxon, User
} from '../../../../models/crm';
import {
	mapActions, mapState
} from 'pinia';

import serviceExpenses from '../../../../services/API/financial/expenses';
import serviceIncomes from '../../../../services/API/financial/incomes';

import {
	Category,
	CostCenter,
	EntryBlock, EntryBlockData, EntryBlockFile,
	EntryBlockModel, EntryBlockToSend, FinancialAttachment, RepeatModel
} from '../../../../models/financial';

import { useAccountStore } from '../../../../stores/account';
import { useDashboardStore } from '../../../../stores/models/financial/dashboard';
import { useCategoriesStore } from '../../../../stores/models/financial/categories';

import InfoTooltip from '../../../../components/Form/InfoTooltip.vue';
import IncomeExpenseAddBlock from '../../components/IncomesExpenses/IncomeExpenseAddBlock.vue';
import ModalContact from '../../../CRM/views/Contacts/ModalContact.vue';
import ModalLegalCase from '../../../CRM/views/LegalCases/ModalLegalCase.vue';
import ModalCategory from '../../components/ModalCategory.vue';
import ModalCostCenter from '../../components/ModalCostCenter.vue';
import { RouteLocationRaw } from 'vue-router';

export default defineComponent({
	components: {
		IncomeExpenseAddBlock,
		InfoTooltip,
		ModalCategory,
		ModalContact,
		ModalCostCenter,
		ModalLegalCase,
	},
	provide() {
		return {
			filters: {},
		};
	},
	data() {
		return {

			eventBus: inject<EventBus>('eventBus'),
			helpers: inject<Helpers>('helpers'),
			lodash: inject<Lodash>('lodash'),
			luxon: inject<Luxon>('luxon'),

			entryBlocks: [] as EntryBlock[],
			entryBlocksFiles: {} as Record<string, FinancialAttachment[]>,

			requesting: {
				loadCategories: false,
				loadCostCenters: false,
				saveEntries: false,
			},

		};
	},
	computed: {
		...mapState(useAccountStore, [ 'user' ]),
		...mapState(useCategoriesStore, [ 'expense_categories', 'income_categories', 'cost_centers' ]),

		loadedCategories(): Category[] {
			if (this.viewType === 'expense') {
				return this.expense_categories;
			}

			return this.income_categories;
		},

		loadedCostCenters(): CostCenter[] {
			return this.cost_centers;
		},

		addedEntriesQuantity(): number {
			return Object.keys(this.entryBlocks).length;
		},

		isValid(): boolean {
			return false;
		},

		viewDictionary(): Record<string, string> {
			const dictionary = {
				entry: 'entrada',
				entries: 'entradas',
				Entries: 'Entradas',
				oppositeEntry: 'saída',
				viewTooltip: '<p>Nesta tela você pode cadastrar valores que estão previstos para recebimento, assim como lançar valores já recebidos, para registro e organização da sua gestão financeira.</p><p>Para cadastro de valores de entradas previstas, basta preencher apenas a “Data do Vencimento”, com uma data futura, e deixar para preencher a “Data do Recebimento” apenas quando você efetivamente receber esses valores. Isso ajuda você a ter uma previsão de caixa e programar suas contas.</p><p>Para cadastro de um valor de entrada já recebido, preencha a “Data de Vencimento” e também o campo de “Data do recebimento”, para que esse valor já seja registrado no seu caixa como recebido. Isso ajuda você a acompanhar como está a saúde financeira do escritório e seu saldo atual.</p>',
			};

			if (this.viewType === 'expense') {
				dictionary.entry = 'saída';
				dictionary.entries = 'saídas';
				dictionary.Entries = 'Saídas';
				dictionary.oppositeEntry = 'entrada';
				dictionary.viewTooltip = '<p>Nesta tela você pode cadastrar valores que estão previstos para pagamento, assim como lançar valores já pagos, para registro e organização da sua gestão financeira.</p><p>Para cadastro de valores de saídas previstas, basta preencher apenas a “Data do Vencimento”, com uma data futura, e deixar para preencher a “Data do Pagamento” apenas quando você efetivamente pagar esses valores. Isso ajuda você a ter uma previsão de caixa e programar suas contas.</p><p>Para cadastro de um valor de saída já pago, preencha a “Data de Vencimento” e também o campo de “Data do Pagamento”, para que esse valor já seja registrado no seu caixa como pago. Isso ajuda você a acompanhar como está a saúde financeira do escritório e seu saldo atual.</p>';
			}

			return dictionary;
		},

		backRoute(): RouteLocationRaw {
			if (this.viewType === 'income') {
				return { name: 'add-income' };
			}
			return { name: 'add-expense' };
		},

		modalLegalCase_inData(): { assignees: User[]} {
			const assignees = this.user?.id ? [ this.user ] : [];

			return {
				assignees,
			};
		},

		viewType(): string {
			const currentRoute = this.$route;
			const { path } = currentRoute;
			const isExpense = !!path.match(/\/saidas\//);

			return isExpense ? 'expense' : 'income';
		},

	},
	watch: {

		async show(): Promise<void> {
			await this.loadCategories();
			await this.loadCostCenters();
		},

	},
	methods: {
		...mapActions(useDashboardStore, [ 'fetchMonthsSummary' ]),
		...mapActions(useCategoriesStore, [ 'fetchIncomeCategories', 'fetchExpenseCategories', 'fetchCostCenters' ]),

		addEntryBlock(model?: EntryBlockModel, blockIndex?: number): string | undefined {
			const uuid = this.helpers?.getUuid();

			if (!uuid) return;

			const emptyModel: EntryBlockModel = {
				amount: '',
				category: [],
				contact: [],
				costCenter: [],
				description: '',
				dueDate: '',
				legalCase: [],
				note: '',
				paymentDate: '',

				attachments: {
					files: [],
				},
			};

			if (!model) {
				model = emptyModel;
			}

			if (!blockIndex) blockIndex = this.entryBlocks.length;

			this.entryBlocks.splice(blockIndex, 0, {
				model,
				uuid,
			});

			return uuid;
		},

		async loadCategories(forceReload = false): Promise<void> {
			const {viewType} = this;

			try {
				this.requesting.loadCategories = true;

				let response = true;

				if (viewType === 'expense' && (!this.expense_categories.length || forceReload)) {
					response = await this.fetchExpenseCategories('');
				} else if (viewType === 'income' && (!this.income_categories.length || forceReload)) {
					response = await this.fetchIncomeCategories('');
				}

				if (!response) throw new Error('Not possible to fetch Categories');
			} catch (err) {
				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Erro ao carregar categorias',
						type: 'error'
					}
				);
			}

			this.requesting.loadCategories = false;
		},

		async loadCostCenters(forceReload = false): Promise<void> {
			try {
				this.requesting.loadCostCenters = true;

				let response = true;

				if (!this.cost_centers.length || forceReload) {
					response = await this.fetchCostCenters('');
				}

				if (!response) throw new Error('Not possible to fetch Cost Centers');
			} catch (err) {
				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Erro ao carregar centros de custo',
						type: 'error'
					}
				);
			}

			this.requesting.loadCostCenters = false;
		},

		getPayload(): EntryBlockData {
			const isExpense = this.viewType === 'expense';
			const payloadKey = isExpense ? 'expenses': 'incomes';

			return {
				[payloadKey]: this.entryBlocks.map(block => {
					const { uuid } = block;

					const {
						amount: formattedAmount,
						category,
						contact,
						costCenter,
						description,
						dueDate,
						legalCase,
						note,
						paymentDate,
					} = block.model;

					const amount = this.helpers?.getNumberFromFormattedCurrency(formattedAmount) || 0;
					const contact_id = (contact[0] && contact[0].id);
					const cost_center_id = (costCenter[0] && costCenter[0].id);
					const due_date = dueDate;
					const financial_category_id = (category[0] && category[0].id);
					const legal_case_id = (legalCase[0] && legalCase[0].id);
					const payment_date = paymentDate;

					let attachments_attributes = [] as EntryBlockFile[];

					if (this.entryBlocksFiles[uuid]) {
						attachments_attributes = this.entryBlocksFiles[uuid]?.map(item => {
							return {
								description: '',
								file: item.file,
								name: item.filename,
							};
						}) || [];
					}

					const entryBlockToSend: EntryBlockToSend = {
						amount,
						contact_id,
						cost_center_id,
						description,
						due_date,
						financial_category_id,
						legal_case_id,
						payment_date,
						note,
						attachments_attributes,
					};

					return entryBlockToSend;
				}),
			};
		},

		async saveEntries(): Promise<void> {
			const isViewTypeIncome = this.viewType === 'income';

			const payload = this.getPayload();

			const service = isViewTypeIncome ? serviceIncomes : serviceExpenses;

			this.requesting.saveEntries = true;

			try {
				await service.createMultiple(payload);

				this.eventBus?.emit(
					'Toast/add',
					{
						content: `${this.viewDictionary.Entries} criadas com sucesso`,
						type: 'success'
					}
				);

				this.$router.push({name: isViewTypeIncome? 'incomes': 'expenses'});

				this.requesting.saveEntries = false;

				const maybeDate = this.luxon?.now ? this.luxon.now() : new Date();

				await this.fetchMonthsSummary(maybeDate);
			} catch (err) {
				console.error(err);

				this.eventBus?.emit(
					'Toast/add',
					{
						content: `Falha ao criar ${this.viewDictionary.entries}`,
						type: 'error'
					}
				);

				this.requesting.saveEntries = false;
			}
		},

		setEntryBlockModel(refId: string, model: EntryBlockModel, isRepeating: boolean): void {
			const specificEntryBlock = (this.$refs[`entryBlock_${refId}`] as typeof IncomeExpenseAddBlock);
			if (specificEntryBlock && specificEntryBlock[0]) {
				specificEntryBlock[0]?.setModel(model, isRepeating);
			}
		},

		validateEntries(): boolean {
			let validationCounter = 0;

			const entryBlocks: [string, typeof IncomeExpenseAddBlock[0]] = Object.entries(this.$refs).filter(([ key ]) => key.match(/^entryBlock_.*/)) as [string, typeof IncomeExpenseAddBlock[0]];

			entryBlocks.forEach(([ _key, value ]) => {
				const entryBlock = value[0];
				if (entryBlock) {
					entryBlock.resetValidation();
					const { isValid } = entryBlock.validate();
					if (!isValid) validationCounter++;
				}
			});

			return validationCounter < 1;
		},

		// Events

		buttonSaveClick(): void {
			const isValid = this.validateEntries();
			if (isValid) this.saveEntries();
		},

		buttonSwitchToOppositeEntryClick(): void {
			if (this.viewType === 'expense') {
				this.$router.push({name: 'add-expense'});
				return;
			}

			this.$router.push({name: 'add-income'});
		},

		entryBlock_attachmentsUpdateFiles(entry: EntryBlock, files: FinancialAttachment[]): void {
			const {
				uuid
			} = entry;


			this.entryBlocksFiles[uuid] = files;
		},

		entryBlock_delete(_model: EntryBlockModel, blockUuid: string): void {
			const index = this.entryBlocks.findIndex(item => item.uuid === blockUuid);
			this.entryBlocks.splice(index, 1);
		},

		entryBlock_duplicate(model: EntryBlockModel, blockIndex: number, _blockUuid: string): void {
			const clonedModel = this.lodash?.cloneDeep(model);

			if (!clonedModel) return;

			// Set empty files values
			clonedModel.attachments = { files: [] };

			const newBlockUuid = this.addEntryBlock(clonedModel, blockIndex);

			if (!newBlockUuid) return;

			this.$nextTick(
				() => {
					this.setEntryBlockModel(newBlockUuid, clonedModel, true);
				}
			);
		},

		async entryBlock_repeat(
			obj: RepeatModel,
			blockIndex: number,
			blockUuid: string
		): Promise<void> {
			const {
				model, months
			} = obj;

			const totalMonths = months;

			const capitalizedEntryWord = (this.viewDictionary.entry || 'entrada').charAt(0).toUpperCase()
				+ (this.viewDictionary.entry || 'entrada').slice(1);

			const clonedModel = this.lodash?.cloneDeep(model);

			if (!clonedModel) return;

			const cleanDescription = (this.getCleanDescription(clonedModel.description))
				|| capitalizedEntryWord;

			clonedModel.description = `${cleanDescription} - 1 de ${totalMonths}`;

			this.setEntryBlockModel(blockUuid, clonedModel, true);

			for (let i = 1;i <= months - 1;i++) {
				const clonedModel = this.lodash?.cloneDeep(model);

				if (!clonedModel) continue;

				const cleanDescription = (this.getCleanDescription(clonedModel.description))
					|| capitalizedEntryWord;

				clonedModel.description = `${cleanDescription} - ${i + 1} de ${totalMonths}`;

				clonedModel.dueDate = this.luxon?.fromISO(model.dueDate).plus({ months: i }).toISO()
					|| model.dueDate;

				// Set empty files values
				clonedModel.attachments = { files: [] };

				const newBlockUuid = this.addEntryBlock(clonedModel, blockIndex + i);

				if (!newBlockUuid) continue;

				this.$nextTick(
					() => {
						this.setEntryBlockModel(newBlockUuid, clonedModel, true);
					}
				);
			}
		},
		getCleanDescription(description: string): string {
			return description.replace(/ - .* de .*/, '');
		},

		entryBlock_modelUpdate(model: EntryBlockModel, blockUuid: string): void {
			const index = this.entryBlocks.findIndex(item => item.uuid === blockUuid);

			if (!this.entryBlocks[index]) return;

			(this.entryBlocks[index] as EntryBlock).model = model;
		},

		buttonAddBlockClick(): void {
			this.addEntryBlock();
		},

		eventAddCategory(): void {
			(this.$refs.modalCategory as typeof ModalCategory).show(this.viewType);
		},

		eventAddContact(): void {
			(this.$refs.modalContact as typeof ModalContact).show();
		},

		eventAddCostCenter(): void {
			(this.$refs.modalCostCenter as typeof ModalCostCenter).show(this.viewType);
		},

		eventAddLegalCase(): void {
			(this.$refs.modalLegalCase as typeof ModalLegalCase).show();
		},

	},
	async beforeMount() {
		this.addEntryBlock();
		await this.loadCategories();
		await this.loadCostCenters();
	},
});
</script>

<template>
	<section class="feature">
		<div class="flex justify-between mb-3">
			<div class="flex">
				<h1>Adicionar {{ viewDictionary.entries }}</h1>

				<InfoTooltip
					:content="viewDictionary.viewTooltip"
					sizing-class="!h-5 !w-5"
				/>
			</div>
			<div>
				<button
					v-if="!requesting.saveEntries"
					class="btn btn--primary h-auto min-h-0 normal-case !px-3 !py-2 rounded text-white"
					@click="buttonSwitchToOppositeEntryClick"
				>
					<span>Trocar para {{ viewDictionary.oppositeEntry }}</span>
				</button>
			</div>
		</div>

		<div class="container-entry-blocks relative">
			<TransitionGroup
				name="entry-blocks"
				tag="ul"
			>
				<li
					v-for="(entry, index) in entryBlocks"
					:key="entry.uuid"
					class="bg-transparent p-0 mb-4 last:mb-0"
				>
					<IncomeExpenseAddBlock
						:ref="`entryBlock_${entry.uuid}`"

						:attachments-allow-multiple="true"
						:categories="loadedCategories"
						:cost-centers="loadedCostCenters"
						:dictionary="viewDictionary"
						:type="viewType"
						:is-multiple-save="true"

						@attachments-updatefiles="(files) => entryBlock_attachmentsUpdateFiles(entry, files)"
						@delete="entryBlock_delete($event, entry.uuid)"
						@duplicate="entryBlock_duplicate($event, index, entry.uuid)"
						@repeat="entryBlock_repeat($event, index, entry.uuid)"
						@model-update="entryBlock_modelUpdate($event, entry.uuid)"
						@open-modal-category="eventAddCategory"
						@open-modal-contact="eventAddContact"
						@open-modal-cost-center="eventAddCostCenter"
						@open-modal-legal-case="eventAddLegalCase"
					/>
				</li>
			</TransitionGroup>
		</div>

		<div class="my-4">
			<button
				class="border border-blue-500 inline-flex items-center px-5 py-2 rounded text-blue-500"
				:title="`Adicionar ${viewDictionary.entry}`"

				@click="buttonAddBlockClick"
			>
				<svg
					class="inline w-6 h-6"
					fill="none"
					stroke="currentColor"
					viewBox="0 0 24 24"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="3"
						d="M12 6v6m0 0v6m0-6h6m-6 0H6"
					/>
				</svg>
			</button>
		</div>

		<div class="bg-white p-4 rounded">
			<button
				class="btn btn--primary !px-10"

				@click="buttonSaveClick"
			>
				<span>Salvar</span>
				<span class="ml-1.5">{{ addedEntriesQuantity }}</span>
				<span class="ml-1.5">{{ addedEntriesQuantity === 1 ? viewDictionary.entry : viewDictionary.entries }}</span>
			</button>
		</div>

	</section>

	<ModalLegalCase
		ref="modalLegalCase"
		:back-route="backRoute"
		:in-data="modalLegalCase_inData"
	/>

	<ModalContact
		ref="modalContact"
		:back-route="backRoute"
	/>

	<ModalCategory
		ref="modalCategory"
	/>

	<ModalCostCenter
		ref="modalCostCenter"
	/>
</template>

<style lang="scss" scoped>
	.entry-blocks-move,
	.entry-blocks-enter-active,
	.entry-blocks-leave-active {
		transition: all 0.5s ease;
	}
	.entry-blocks-enter-from,
	.entry-blocks-leave-to {
		opacity: 0;
		transform: translateX(30px);
	}

	.entry-blocks-leave-active {
		position: absolute;
	}
</style>