import { defineStore } from 'pinia';
import {
	EntryBlockType,
	ReceivedEntryBlock,
	NextTransaction
} from '../../../models/financial';
import serviceIncomes from '../../../services/API/financial/incomes';
import serviceExpenses from '../../../services/API/financial/expenses';

type EntriesState = {
	entries: Record<EntryBlockType, ReceivedEntryBlock[]>;
    maxEntries: Record<EntryBlockType, number>;
	isRequestingEntries: Record<EntryBlockType, boolean>;
	searchedEntries: Record<EntryBlockType, ReceivedEntryBlock[]>;
	searchMaxEntries: Record<EntryBlockType, number>;
	nextTransactions: Record<EntryBlockType, NextTransaction[]>;
}

export const useEntriesStore = defineStore('entries', {
	state: (): EntriesState => ({
		entries: {
			incomes: [],
			expenses: [],
		},

		maxEntries: {
			incomes: 100,
			expenses: 100,
		},

		isRequestingEntries: {
			incomes: false,
			expenses: false,
		},

		searchedEntries: {
			incomes: [],
			expenses: [],
		},

		searchMaxEntries: {
			incomes: 0,
			expenses: 0,
		},

		nextTransactions: {
			incomes: [],
			expenses: [],
		},
	}),
	actions: {
		setEntriesList(entryType: EntryBlockType, value: ReceivedEntryBlock[]): void {
			this.entries[entryType] = value;
		},

		setMaxEntries(entryType: EntryBlockType, value: number): void {
			this.maxEntries[entryType] = value;
		},

		addEntriesToList(entryType: EntryBlockType, value: ReceivedEntryBlock[]): void {
			this.entries[entryType] = this.entries[entryType].concat(value);
		},

		async deleteEntry(entryType: EntryBlockType, entryId: number): Promise<void> {
			const serviceEntries = entryType === 'incomes' ? serviceIncomes : serviceExpenses;

			await serviceEntries.delete(entryId);

			const index = this.entries[entryType].findIndex(entry => entry.id === entryId);

			if (index === -1) return;

			this.entries[entryType].splice(index, 1);
		},

		async loadEntries(entryType: EntryBlockType, useNextTransactions = false): Promise<void> {
			const serviceEntries = entryType === 'incomes' ? serviceIncomes : serviceExpenses;

			this.isRequestingEntries[entryType] = true;

			const { data } = await serviceEntries.getAll(9999, 1, 'description:asc', '', useNextTransactions);

			this.nextTransactions = {
				... this.nextTransactions, [entryType]: data.next_transactions
			};

			this.entries[entryType] = data[entryType].map(entry => {
				const mappedEntry: ReceivedEntryBlock = {
					...entry,
					attachments: []
				};

				return mappedEntry;
			});

			this.isRequestingEntries[entryType] = false;

			this.maxEntries[entryType] = data[entryType].length;
		},

		async paginatedLoadEntries(
			entryType: EntryBlockType,
			orderBy: string,
			filters: string,
			useNextTransactions = false,
			perPage = 50,
			page = 1,
		): Promise<void> {
			const serviceEntries = entryType === 'incomes' ? serviceIncomes : serviceExpenses;

			this.isRequestingEntries[entryType] = true;

			const { data: mainData } =
				await serviceEntries.getAll(perPage, page, orderBy, filters, useNextTransactions);

			this.nextTransactions = {
				... this.nextTransactions, [entryType]: mainData.next_transactions
			};

			this.maxEntries[entryType] = mainData.total_count;

			if (this.entries[entryType].length === 0) {
				this.entries[entryType] = mainData[entryType].map(entry => {
					const mappedEntry: ReceivedEntryBlock = {
						...entry,
						attachments: []
					};

					return mappedEntry;
				});

				this.isRequestingEntries[entryType] = false;

				return;
			}

			if (this.entries[entryType].length === perPage * (page - 1)) {
				this.entries[entryType] = this.entries[entryType].concat(
					mainData[entryType].map(entry => {
						const mappedEntry: ReceivedEntryBlock = {
							...entry,
							attachments: []
						};

						return mappedEntry;
					})
				);

				this.isRequestingEntries[entryType] = false;

				return;
			}

			if (this.entries[entryType].length > perPage * (page - 1)) {
				if (page === 1) {
					this.entries[entryType] = mainData[entryType].map(entry => {
						const mappedEntry: ReceivedEntryBlock = {
							...entry,
							attachments: []
						};

						return mappedEntry;
					});

					this.isRequestingEntries[entryType] = false;

					return;
				}

				const { data: stepData } =
					await serviceEntries.getAll(perPage * page, 1, orderBy, filters, useNextTransactions);

				this.nextTransactions = {
					... this.nextTransactions, [entryType]: stepData.next_transactions
				};

				this.entries[entryType] = stepData[entryType].map(entry => {
					const mappedEntry: ReceivedEntryBlock = {
						...entry,
						attachments: []
					};

					return mappedEntry;
				});

				this.isRequestingEntries[entryType] = false;

				return;
			}

			throw new Error('Impossível paginar! Provavelmente a aplicação se encontra na página final');
		},
		async searchEntriesByDescription(
			entryType: EntryBlockType,
			description: string,
			perPage = 50,
			page = 1,
			orderBy: string,
			filters: string
		): Promise<void> {
			const serviceEntries = entryType === 'incomes' ? serviceIncomes : serviceExpenses;

			const { data: mainData } = await serviceEntries.searchByDescription(
				description,
				perPage,
				page,
				orderBy,
				filters
			);

			if (description.length > 0) {
				this.searchMaxEntries[entryType] = mainData.total_count;
			} else {
				this.searchMaxEntries[entryType] = 0;
			}

			if (this.searchedEntries[entryType].length === 0) {
				this.searchedEntries[entryType] = mainData[entryType].map(entry => {
					const mappedEntry: ReceivedEntryBlock = {
						...entry,
						attachments: []
					};

					return mappedEntry;
				});

				return;
			}

			if (this.searchedEntries[entryType].length === perPage * (page - 1)) {
				this.searchedEntries[entryType] = this.searchedEntries[entryType].concat(
					mainData[entryType].map(entry => {
						const mappedEntry: ReceivedEntryBlock = {
							...entry,
							attachments: []
						};

						return mappedEntry;
					})
				);

				return;
			}

			if (this.searchedEntries[entryType].length > perPage * (page - 1)) {
				if (page === 1) {
					this.searchedEntries[entryType] = mainData[entryType].map(entry => {
						const mappedEntry: ReceivedEntryBlock = {
							...entry,
							attachments: []
						};

						return mappedEntry;
					});

					return;
				}

				const { data: stepData } = await serviceEntries.searchByDescription(
					description,
					perPage * page,
					1,
					orderBy,
					filters
				);

				this.searchedEntries[entryType] = stepData[entryType].map(entry => {
					const mappedEntry: ReceivedEntryBlock = {
						...entry,
						attachments: []
					};

					return mappedEntry;
				});

				return;
			}

			throw new Error('Impossível pesquisar! Provavelmente a aplicação se encontra na página final');
		},

		useNormalEntriesList(entryType: EntryBlockType): void {
			this.searchMaxEntries[entryType] = 0;
			this.searchedEntries[entryType] = [];
		},

	},
});