import {
	createRouter, createWebHistory
} from 'vue-router';

// Services
import storage from '../services/storage';

// Features routes
import FeaturesRoutes from './modules/featuresRoutes';

// Views
import Login from '../views/LoginView.vue';
import Logout from '../views/LogoutView.vue';

const routerHistory = createWebHistory();

const router = createRouter({
	history: routerHistory,
	routes: [ {
		path: '/logout',
		component: Logout,
	},
	{
		path: '/login',
		component: Login,
	},
	FeaturesRoutes, /* <-- FeatureRoutes here */
	{
		path: '/:catchAll(.*)',
		redirect: '/',
	}
	],
});

router.beforeEach((to, from) => {
	if (to.meta.requiresAuth) {
		const storageAccessToken = localStorage.getItem('cj-access-token') || storage.getItem('localStorage', 'access-token');
		if (!storageAccessToken) {
			const nextRoute = to.fullPath;
			nextRoute && storage.setItem('sessionStorage', 'next_route', nextRoute);
			return '/login';
		}
	}
});

export default router;
