<script lang="ts">
import serviceUsers from '../../../../services/users';

import serviceLegalCases from '../../../../services/API/crm/legalCases';

import BlockingOverlay from '../../../../components/BlockingOverlay/BlockingOverlay.vue';
import EditorJs from '../../../../components/Editor/EditorJs.vue';
import FormValidator from '../../../../components/Form/FormValidator.vue';
import InputText from '../../../../components/Form/InputText.vue';
import SelectField from '../../../../components/Form/SelectField.vue';
import Loading from '../../../../components/Loading/LoadingComponent.vue';
import ModalBasic from '../../../../components/Modal/ModalBasic.vue';
import TagsMultiselect from '../../../../components/Tags/TagsMultiselect.vue';

import {
	mapState, mapActions
} from 'pinia';
import { useAccountStore } from '../../../../stores/account';
import { useFunnelsStore } from '../../../../stores/models/crm/funnels';
import { useContactsStore } from '../../../../stores/models/crm/contacts';
import { useLegalCasesStore } from '../../../../stores/models/crm/legalCases';

import {
	DefineComponent,
	PropType,
	defineComponent, inject
} from 'vue';
import {
	EventBus, Lodash, LegalCase, Funnel, Assignee, Contact, User
} from '../../../../models/crm';
import { RouteLocationRaw } from 'vue-router';

export default defineComponent({
	components: {
		BlockingOverlay,
		EditorJs,
		InputText,
		SelectField,
		FormValidator,
		Loading,
		ModalBasic,
		TagsMultiselect,
	},
	props: {
		backRoute: {
			required: true,
			type: Object as PropType<RouteLocationRaw>,
		},
		caseId: [ String, Number ],
		inData: {
			type: Object,
		},
	},
	emits: [
		'case-created',
		'case-updated',
	],
	data() {
		return {

			eventBus: inject<EventBus>('eventBus'),
			lodash: inject<Lodash>('lodash'),

			loadedCase: null as LegalCase | null,

			caseTypes: null as Record<string, string> | null,

			model: {
				assignees: [] as Assignee[],
				case_number: '' as string,
				category: '' as string | number,
				case_type: '' as string,
				contacts: [] as Contact[],
				description: '' as string,
				is_active: true,
				name: '',
				funnel_step: '' as string | number,
			},

			requesting: {
				createDefaultFunnels: false,
				defineMainFunnel: false,
				loadCase: false,
				loadFunnels: false,
				loadCaseTypes: false,
				save: false,
			},

			selectStatusOptionsList: [
				{
					value: '', label: 'Selecione'
				},
				{
					value: 'Ativo', label: 'Ativo'
				},
				{
					value: 'Inativo', label: 'Inativo'
				},
			],
		};
	},
	computed: {
		...mapState(useAccountStore, [ 'user' ]),
		...mapState(useFunnelsStore, [ 'funnelsList', 'isRequestingFunnels' ]),
		...mapState(useContactsStore, [ 'searchedContacts' ]),
		...mapState(useLegalCasesStore, [ 'legalCasesList' ]),

		isEditing(): boolean {
			return !!this.caseId;
		},

		selectCategoryOptionsList(): { label: string, value: number}[] {
			if (this.funnelsList) {
				return this.funnelsList.map((funnel: Funnel) => ({
					label: funnel.name
					, value: funnel.id
				}));
			}

			return [];
		},

		officeId(): string | number {
			return this.user?.office_id || 0;
		},

		selectCaseTypeOptionsList(): { label: string, value: string}[] {
			if (!this.caseTypes) return [
				{
					label: 'Carregando...', value: ''
				},
			];

			const selectOptions = Object.keys(this.caseTypes).map(key => ({
				label: (this.caseTypes as Record<string, string>)[key] || '',
				value: key,
			}));

			const selectOptionsOrdered = this.lodash?.orderBy(selectOptions, [ 'label', 'asc' ]) || selectOptions;

			return selectOptionsOrdered.concat(
				[ {
					label: 'Selecione', value: ''
				} ]
			);
		},

		selectStepOptionsList(): { label: string, value: string | number}[] {
			const selectOptions = [] as { label: string, value: string | number}[];
			if (!this.selectedCategory) {
				return [
					{
						label: 'Selecione o fluxo de casos', value: ''
					},
				];
			} else {
				const funnelSteps = this.selectedCategory.funnel_steps;

				[ ...funnelSteps.sort((a, b) => a.order - b.order) ].forEach(
					step => {
						selectOptions.push({
							label: `${step.name}`, value: step.id
						});
					}
				);
				return selectOptions;
			}
		},

		selectedCategory(): Funnel | null {
			if (!this.funnelsList || !this.model.category) return null;

			const foundItem = this.funnelsList.find((item: Funnel) => {
				return item.id === Number(this.model.category);
			});

			return foundItem || null;
		},

	},
	watch: {

		inData: {
			deep: true,
			handler(newValue): void {
				if (newValue) {
					this.setInitialFields();
				}
			},
		},

		selectStepOptionsList(newOptions) {
			this.model.funnel_step = newOptions[0]?.value || '';
		},

	},
	methods: {
		...mapActions(useFunnelsStore, [ 'defineMainFunnel', 'loadFunnels', 'createDefaultFunnels', 'addLegalCaseToFunnelSteps' ]),
		...mapActions(useContactsStore, [ 'searchContactsByName' ]),
		...mapActions(useLegalCasesStore, [ 'addLegalCasesToList' ]),

		async loadCase(): Promise<void> {
			const {caseId} = this;

			if (!caseId) return;

			this.requesting.loadCase = true;

			try {
				this.eventBus?.emit('BlockingOverlay/show', {
					text: 'Aguarde, consultando o caso'
				});

				let legalCase = this.legalCasesList.find(item => item.id == caseId);

				if (!legalCase) {
					const { data } = await serviceLegalCases.getById(caseId);

					legalCase = data as LegalCase;
				}

				this.loadedCase = (legalCase as LegalCase);

				this.model.assignees = legalCase.assignees || [];
				this.model.contacts = legalCase.contacts || [];
				this.model.is_active = legalCase.is_active === undefined? true : legalCase.is_active;
				this.model.category = legalCase.funnel?.id || '';
				this.model.name = legalCase.name || '';
				this.model.case_number = legalCase.case_number || '';
				this.model.funnel_step = (legalCase.funnel_steps && legalCase.funnel_steps[0]?.id) || 0;
				this.model.case_type = legalCase.case_type || '';

				this.model.description = legalCase.description || '';
			} catch (err) {
				console.error(err);

				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Falha ao carregar caso',
						type: 'error'
					}
				);
			} finally {
				this.requesting.loadCase = false;
				this.eventBus?.emit('BlockingOverlay/hide');
			}
		},

		async callLoadFunnels(forceReload = false): Promise<void> {
			try {
				this.requesting.loadFunnels = true;

				if (this.funnelsList.length > 0 && !forceReload) {
					this.requesting.loadFunnels = false;

					return;
				}

				await this.loadFunnels();

				this.requesting.loadFunnels = false;
			} catch (err) {
				console.error(err);
			}
		},

		resetModel(): void {
			this.model.assignees = [];
			this.model.category = '';
			this.model.case_type = '';
			this.model.contacts = [];
			this.model.description = '';
			this.model.is_active = true;
			this.model.name = '';
			this.model.case_number = '';
			this.model.funnel_step = 0;
		},

		async save(): Promise<void> {
			if (!this.user?.id) {
				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Usuário não encontrado',
						type: 'error'
					}
				);

				return;
			}

			const validateResult = (this.$refs.formValidatorModalCase as typeof FormValidator).validate();
			const { isValid } = validateResult;

			const user_id = this.user.id;
			let newCaseId = null;

			const {
				case_number,
				is_active,
				name,
				case_type,
			} = this.model;

			const assignee_ids = this.model.assignees.map(item => Number(item.id));
			const contact_ids = this.model.contacts.map(item => Number(item.id));
			const funnel_step_ids = [ Number(this.model.funnel_step) ];

			let description = { html: '' as string };
			try {
				description = await (this.$refs.editorDescription as typeof EditorJs).getOutput();
			} catch (e) {
				console.error(e);

				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Erro ao capturar descrição',
						type: 'error'
					}
				);

				return;
			}

			const payload = {
				assignee_ids,
				case_number,
				case_type,
				contact_ids,
				funnel_step_ids,
				is_active,
				name,
				user_id,
				description: description.html,
			};

			if (isValid) {
				this.requesting.save = true;

				if (this.isEditing && this.caseId) {
					this.eventBus?.emit('BlockingOverlay/show', {
						text: 'Aguarde, atualizando o caso'
					});

					try {
						await serviceLegalCases.update({
							data: payload,
							id: this.caseId,
						});
						(this.$refs.modal as typeof ModalBasic).hide();

						this.eventBus?.emit(
							'Toast/add',
							{
								content: 'Caso atualizado com sucesso',
								type: 'success'
							}
						);
					} catch (err) {
						console.error(err);

						this.eventBus?.emit(
							'Toast/add',
							{
								content: 'Falha ao atualizar caso',
								type: 'error'
							}
						);
					}

					this.$emit('case-updated');
				} else {
					this.eventBus?.emit('BlockingOverlay/show', {
						text: 'Aguarde, criando o caso'
					});

					try {
						const {data} = await serviceLegalCases.create(payload);
						newCaseId = data.id;

						(this.$refs.modal as typeof ModalBasic).hide();

						this.eventBus?.emit(
							'Toast/add',
							{
								content: 'Caso criado com sucesso',
								type: 'success'
							}
						);

						const caseWithFunnelStepsIds = {
							...data,
							funnel_step_ids: funnel_step_ids,
						};

						this.addLegalCasesToList([ data ]);
						this.addLegalCaseToFunnelSteps(caseWithFunnelStepsIds, data.funnel?.id || '');

						this.$emit('case-created');
					} catch (err) {
						console.error(err);

						this.eventBus?.emit(
							'Toast/add',
							{
								content: 'Falha ao criar caso',
								type: 'error'
							}
						);
					}
				}

				this.eventBus?.emit('BlockingOverlay/hide');

				this.requesting.save = false;
				this.$router.push({
					name: 'legal-case-details', params: {id: newCaseId}
				});
			} else {
				(this.$refs.modal as typeof ModalBasic).$refs.modalContent.scrollTo({
					behavior: 'smooth',
					left: 0,
					top: 0,
				});
			}
		},

		setInitialFields(): void {
			const {inData} = this;

			if (inData) {
				const { assignees } = inData;

				if (!assignees) return;

				if (assignees) {
					this.model.assignees = assignees;
				}
			}
		},

		async show(): Promise<void> {
			this.resetModel();

			if (this.funnelsList.length === 0 && !this.isRequestingFunnels) await this.callLoadFunnels();

			if (this.caseId) {
				await this.loadCase();
			}

			if (!this.isEditing) {
				this.setInitialFields();
			}

			(this.$refs.modal as typeof ModalBasic).show();

			if (!this.funnelsList || this.funnelsList.length === 0) {
				await this.callCreateDefaultFunnels();
			}
			const mainFunnel = this.funnelsList.find((funnel) => funnel.is_main);
			this.model.category = mainFunnel?.id || '';
			this.model.funnel_step = mainFunnel?.funnel_steps[0]?.id || '';
		},

		tagsMultiselectAsyncAssignees_queryFunction(query: string): Promise<User[]> {
			return new Promise(
				(resolve, reject) => {
					serviceUsers.searchByName({
						name: query,
					}).then(({data}) => {
						resolve(data.users);
					}).catch(err => {
						console.error(err);
						reject(err);
					});
				}
			);
		},

		async tagsMultiselectAsyncContacts_queryFunction(query: string): Promise<Contact[]> {
			try {
				await this.searchContactsByName({
					name: query,
					perPage: 25,
					page: 1,
					orderBy: 'name:asc',
					filters: ''
				});

				return this.searchedContacts;
			} catch (err) {
				console.error(err);
				throw err;
			}
		},

		async callDefineMainFunnel(id: string | number): Promise<void> {
			this.eventBus?.emit('BlockingOverlay/show', {
				text: 'Aguarde, definindo fluxo de caso principal'
			});

			try {
				this.requesting.defineMainFunnel = true;

				await this.defineMainFunnel(id);

				this.requesting.defineMainFunnel = false;
			} catch (err) {
				console.error(err);

				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Erro ao definir fluxo de caso principal',
						type: 'error'
					}
				);
			} finally {
				this.eventBus?.emit('BlockingOverlay/hide');
			}
		},

		async callCreateDefaultFunnels(): Promise<void> {
			if (!this.officeId) return;

			this.eventBus?.emit('BlockingOverlay/show', {
				text: 'Aguarde, criando fluxos de casos'
			});

			try {
				this.requesting.createDefaultFunnels = true;

				await this.createDefaultFunnels(this.officeId);

				this.requesting.createDefaultFunnels = false;
			} catch (err) {
				console.error(err);

				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Erro ao criar fluxos de casos',
						type: 'error'
					}
				);
			} finally {
				this.eventBus?.emit('BlockingOverlay/hide');
			}
		},


		// Events

		buttonCancelClick(): void {
			(this.$refs.modal as typeof ModalBasic).hide();
		},

		buttonSaveClick(): void {
			this.save();
		},

		modal_hide(): void {
			if (typeof this.backRoute === 'string') {
				this.$router.push({
					path: this.backRoute,
					query: this.$route.query,
				});

				return;
			}

			this.$router.push({
				...this.backRoute,
				query: this.$route.query,
			});
		},

	},
});
</script>
<template>
	<ModalBasic
		ref="modal"
		:dismissable="!requesting.loadCase"
		size="md"
		:title="isEditing ? 'Editar caso' : 'Novo caso'"
		:is-create-modal="!isEditing"
		:is-edit-modal="isEditing"
		@hide="modal_hide"
	>
		<template #content>

			<template v-if="requesting.loadCase">
				<Loading class="opacity-50 py-14 text-center" />
			</template>
			<template v-else>

				<FormValidator
					ref="formValidatorModalCase"
					:refs="($refs as DefineComponent)"
					:validation-summary-show="true"
					validation-summary-class="mb-4 mx-4 p-3"
				>
					<template
						field="inputName"
						label="Nome"
						message="Preenchimento obrigatório"
						:invalidIf="() => !model.name"
					/>
					<template
						field="selectType"
						label="Fluxo"
						message="Preenchimento obrigatório"
						:invalidIf="() => !model.category"
					/>
					<template
						field="selectStep"
						label="Etapa"
						message="Preenchimento obrigatório"
						:invalidIf="() => !model.funnel_step"
					/>
				</FormValidator>

				<div class="flex-1 mb-4 px-4">
					<InputText
						ref="inputName"
						v-model="model.name"
						label="Nome"
						:required="true"
					/>
				</div>

				<div class="flex justify-between gap-4 mb-4 px-4">

					<div class="flex gap-4 flex-1">
						<InputText
							ref="inputCaseNumber"
							v-model="model.case_number"

							class="flex-1"
							label="N.º do caso"
						/>
					</div>

				</div>

				<div class="px-4">

					<div class="flex gap-4 mt-4">

						<div class="flex-1">
							<SelectField
								ref="selectType"
								v-model="model.category"
								:options="selectCategoryOptionsList"
								label="Fluxo"
								required
								class="w-full"
							/>
						</div>

						<div class="flex-1">
							<SelectField
								ref="selectStep"
								v-model="model.funnel_step"
								:options="selectStepOptionsList"
								:disabled="!model.category"
								label="Etapa"
								required
								class="w-full"
							/>
						</div>

					</div>

					<div
						v-if="funnelsList && funnelsList.length < 1 && !requesting.createDefaultFunnels"
						class="mt-2 bg-orange-400 text-gray-800 text-sm p-4 flex flex-col"
					>
						<p class="font-semibold">
							Não há fluxos de casos criados.
						</p>
						<p>
							Você só poderá selecionar o fluxo de casos após ter criado pelo menos um.
						</p>
						<p>
							<button
								class="btn btn-sm btn--primary disabled:opacity-75"
								:disabled="requesting.createDefaultFunnels || requesting.defineMainFunnel"
								@click.stop.prevent="callCreateDefaultFunnels
								"
							>
								Clique aqui
							</button> para criar fluxos de casos padrões.
						</p>
					</div>

					<div class="w-full mt-4">
						<h2 class="block font-semibold mb-1 text-gray-500 text-xs">
							Contatos
						</h2>
						<div>
							<TagsMultiselect
								ref="tagsMultiselectAsyncContacts"
								v-model="model.contacts"
								async
								label="name"
								:query-function="tagsMultiselectAsyncContacts_queryFunction"
							>
								<template #tagIcon>
									<span class="flex items-center justify-center bg-[#9FBCDA] rounded-full w-6 h-6">
										<svg
											class="inline fill-[#E5F0FB] w-5 h-5"
											fill="currentColor"
											viewBox="0 0 20 20"
											xmlns="http://www.w3.org/2000/svg"
											aria-hidden="true"
										>
											<path
												clip-rule="evenodd"
												fill-rule="evenodd"
												d="M1 6a3 3 0 013-3h12a3 3 0 013 3v8a3 3 0 01-3 3H4a3 3 0 01-3-3V6zm4 1.5a2 2 0 114 0 2 2 0 01-4 0zm2 3a4 4 0 00-3.665 2.395.75.75 0 00.416 1A8.98 8.98 0 007 14.5a8.98 8.98 0 003.249-.604.75.75 0 00.416-1.001A4.001 4.001 0 007 10.5zm5-3.75a.75.75 0 01.75-.75h2.5a.75.75 0 010 1.5h-2.5a.75.75 0 01-.75-.75zm0 6.5a.75.75 0 01.75-.75h2.5a.75.75 0 010 1.5h-2.5a.75.75 0 01-.75-.75zm.75-4a.75.75 0 000 1.5h2.5a.75.75 0 000-1.5h-2.5z"
											/>
										</svg>
									</span>
								</template>
							</TagsMultiselect>
						</div>
					</div>

					<div class="w-full mt-4">
						<h2 class="block font-semibold mb-1 text-gray-500 text-xs">
							Responsável
						</h2>
						<div>
							<TagsMultiselect
								ref="tagsMultiselectAsyncAssignees"
								v-model="model.assignees"
								async
								label="name"
								:single="true"
								:query-function="tagsMultiselectAsyncAssignees_queryFunction"
							>
								<template #maxElements>
									<span class="inline-flex items-center text-red-500 text-sm">
										<svg
											class="inline h-5 w-5"
											fill="currentColor"
											viewBox="0 0 24 24"
											xmlns="http://www.w3.org/2000/svg"
											aria-hidden="true"
										>
											<path
												clip-rule="evenodd"
												fill-rule="evenodd"
												d="M6.72 5.66l11.62 11.62A8.25 8.25 0 006.72 5.66zm10.56 12.68L5.66 6.72a8.25 8.25 0 0011.62 11.62zM5.105 5.106c3.807-3.808 9.98-3.808 13.788 0 3.808 3.807 3.808 9.98 0 13.788-3.807 3.808-9.98 3.808-13.788 0-3.808-3.807-3.808-9.98 0-13.788z"
											/>
										</svg>
										<span class="ml-1 whitespace-pre-line">Você só pode selecionar um responsável por caso</span>
									</span>
								</template>
								<template #tagIcon>
									<span
										class="flex items-center justify-center bg-[#9FBCDA] rounded-full w-6 h-6"
									>
										<svg
											class="inline fill-[#E5F0FB] w-5 h-5"
											fill="currentColor"
											viewBox="0 0 20 20"
											xmlns="http://www.w3.org/2000/svg"
											aria-hidden="true"
										>
											<path d="M10 8a3 3 0 100-6 3 3 0 000 6zM3.465 14.493a1.23 1.23 0 00.41 1.412A9.957 9.957 0 0010 18c2.31 0 4.438-.784 6.131-2.1.43-.333.604-.903.408-1.41a7.002 7.002 0 00-13.074.003z" />
										</svg>
									</span>
								</template>
							</TagsMultiselect>
						</div>
					</div>

					<div class="mb-4 mt-4 container-editor">

						<h2 class="block font-semibold mb-1 text-gray-500">
							Descrição do caso
						</h2>

						<EditorJs
							ref="editorDescription"
							class="p-0 max-h-[170px] rounded"
							class-holder="bg-white border drop-shadow-lg m-auto max-w-[800px] max-h-[170px] overflow-y-auto py-3 px-3"
							:in-data="model.description"
						/>

					</div>

				</div>

			</template>

		</template>
		<template
			v-if="!requesting.loadCase"
			#footer
		>
			<div class="flex-1 flex overflow-hidden rounded-b-md">
				<button
					class="flex-1 bg-gray-200 font-medium p-4 text-gray-500 hover:bg-gray-300 active:bg-gray-200"
					@click="buttonCancelClick"
				>
					CANCELAR
				</button>
				<button
					class="flex-1 bg-primary font-medium p-4 text-white hover:bg-blue-600 active:bg-primary"
					@click="buttonSaveClick"
				>
					SALVAR
				</button>
			</div>
		</template>
	</ModalBasic>
	<Teleport to="body">
		<BlockingOverlay />
	</Teleport>
</template>
<style lang="scss" scoped></style>
