import {
	Funnel, LegalCase
} from '../../../models/crm';
import serviceFunnels from '../../../services/API/crm/funnels';
import { defineStore } from 'pinia';

type FunnelsState = {
	funnelsList: Funnel[];
    maxFunnels: number;
	isRequestingFunnels: boolean;
	searchedFunnels: Funnel[];
	searchMaxFunnels: number;
}

export const useFunnelsStore = defineStore('funnels', {
	state: (): FunnelsState => ({
		funnelsList: [] as Funnel[],
		maxFunnels: 100,
		isRequestingFunnels: false,

		searchedFunnels: [] as Funnel[],
		searchMaxFunnels: 0,
	}),
	actions: {
		setFunnelsList(value: Funnel[]): void {
			this.funnelsList = value;
		},

		updateFunnel(funnel: Funnel): void {
			const index = this.funnelsList.findIndex(item => item.id == funnel.id);

			if (index === -1) return;

			this.funnelsList[index] = funnel;
		},

		setMaxFunnels(value: number): void {
			this.maxFunnels = value;
		},

		addFunnelsToList(value: Funnel[]): void {
			this.funnelsList = this.funnelsList.concat(value);
		},

		async deleteFunnel(
			funnelId: number | string,
			substituteStepId?: string | number
		): Promise<void> {
			await serviceFunnels.delete(funnelId, substituteStepId);

			const index = this.funnelsList.findIndex(funnel => funnel.id == funnelId);

			if (index === -1) return;

			this.funnelsList.splice(index, 1);
		},

		async createDefaultFunnels(officeId: number | string): Promise<void> {
			await serviceFunnels.createDefaultFunnels({ officeId });

			this.isRequestingFunnels = true;

			const { data } = await serviceFunnels.getAll(1, 9999, 'name:asc', '');

			const funnelProspection = data.funnels.find(item => item.name === 'Prospecção');

			if (funnelProspection) {
				await serviceFunnels.setMain(funnelProspection.id);

				const { data: newData } = await serviceFunnels.getAll(1, 9999, 'name:asc', '');

				this.funnelsList = newData.funnels;

				this.isRequestingFunnels = false;

				this.maxFunnels = newData.funnels.length;

				return;
			}

			this.funnelsList = data.funnels;

			this.isRequestingFunnels = false;

			this.maxFunnels = data.funnels.length;
		},

		async defineMainFunnel(funnelId: number | string): Promise<void> {
			await serviceFunnels.setMain(funnelId);

			this.isRequestingFunnels = true;

			const { data } = await serviceFunnels.getAll(1, 9999, 'name:asc', '');

			this.funnelsList = data.funnels;

			this.isRequestingFunnels = false;

			this.maxFunnels = data.funnels.length;
		},

		async loadFunnels(): Promise<void> {
			this.isRequestingFunnels = true;

			const { data } = await serviceFunnels.getAll(1, 9999, 'name:asc', '');

			this.funnelsList = data.funnels;

			this.isRequestingFunnels = false;

			this.maxFunnels = data.funnels.length;
		},

		async paginatedLoadFunnels({
			perPage, page, orderBy, filters = ''
		}: {
                perPage: number;
                page: number;
				orderBy: string;
				filters: string;
            }
		): Promise<void> {
			this.isRequestingFunnels = true;

			const { data: mainData } = await serviceFunnels.getAll(page, perPage, orderBy, filters);

			this.maxFunnels = mainData.total_count;

			if (this.funnelsList.length === 0) {
				this.funnelsList = mainData.funnels;

				this.isRequestingFunnels = false;

				return;
			}

			if (this.funnelsList.length === perPage * (page - 1)) {
				this.funnelsList = this.funnelsList.concat(mainData.funnels);

				this.isRequestingFunnels = false;

				return;
			}

			if (this.funnelsList.length > perPage * (page - 1)) {
				if (page === 1) {
					this.funnelsList = mainData.funnels;

					this.isRequestingFunnels = false;

					return;
				}

				const { data: stepData } = await serviceFunnels.getAll(1, perPage * page, orderBy, filters);

				this.funnelsList = stepData.funnels;

				this.isRequestingFunnels = false;

				return;
			}

			throw new Error('Impossível paginar! Provavelmente a aplicação se encontra na página final');
		},

		async searchFunnelsByName({
			name, perPage = 50, page = 1, orderBy, filters
		}: {
				name: string;
                perPage: number;
                page: number;
				orderBy: string;
				filters: string;
            }
		): Promise<void> {
			const { data: mainData } = await serviceFunnels.searchByName({
				name, orderBy, filters
			}, perPage, page);

			if (name.length > 0) {
				this.searchMaxFunnels = mainData.total_count;
			} else {
				this.searchMaxFunnels = 0;
			}

			if (this.searchedFunnels.length === 0) {
				this.searchedFunnels = mainData.funnels;

				return;
			}

			if (this.searchedFunnels.length === perPage * (page - 1)) {
				this.searchedFunnels = this.searchedFunnels.concat(
					mainData.funnels
				);

				return;
			}

			if (this.searchedFunnels.length > perPage * (page - 1)) {
				if (page === 1) {
					this.searchedFunnels = mainData.funnels;

					return;
				}

				const { data: stepData } = await serviceFunnels.searchByName({
					name, orderBy, filters
				}, perPage * page, 1);

				this.searchedFunnels = stepData.funnels;

				return;
			}

			throw new Error('Impossível pesquisar! Provavelmente a aplicação se encontra na página final');
		},

		useNormalFunnelsList(): void {
			this.searchMaxFunnels = 0;
			this.searchedFunnels = [];
		},

		addLegalCaseToFunnelSteps(legalCase: LegalCase, funnelId: number | string): void {
			const funnelIndex = this.funnelsList.findIndex(funnel => funnel.id == funnelId);
			const funnelStepIndexes = legalCase.funnel_step_ids?.map(stepId => this.funnelsList[funnelIndex]?.funnel_steps.findIndex(step => step.id == stepId));

			funnelStepIndexes?.forEach(stepIndex => {
				if (stepIndex !== undefined) {
					this.funnelsList[funnelIndex]?.funnel_steps[stepIndex]?.legal_cases?.push(legalCase);
				}
			});
		}
	},
});
