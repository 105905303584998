<script lang="ts">
import {
	defineComponent, PropType
} from 'vue';
import { FiltersButtonsGroupItem } from '../../../../models/crm';

export default defineComponent({
	props: {
		items: {
			required: true,
			type: Array as PropType<FiltersButtonsGroupItem[]>,
		},
		selectedValue: {
			required: true,
			type: String,
		},
		section: {
			required: true,
			type: String,
		},
	},
	emits: [
		'clicked-filter-button',
	],
	methods: {

		// Events

		onClick(_e: Event, item: FiltersButtonsGroupItem): void {
			const {section} = this;

			this.$emit('clicked-filter-button', {
				item, section
			});
		}

	}
});
</script>
<template>
	<div class="flex gap-2">
		<button
			v-for="(item, index) in items"
			:key="`item-${index}`"
			:class="[
				'border-b border-blue-500 px-1.5 py-0.5 rounded text-blue-500 text-sm',
				{ 'border-b-2 font-semibold': selectedValue === item.value }
			]"
			@click.stop="onClick($event, item)"
		>
			{{ item.label }}
		</button>
	</div>
</template>
<style lang="scss" scoped></style>