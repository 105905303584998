<script lang="ts">
import { FilterItem } from '../../models/crm';
import {
	PropType, defineComponent
} from 'vue';
export default defineComponent({
	props: {
		buttonClass: String,
		items: {
			required: true,
			type: Array as PropType<FilterItem<string>[]>,
		},
		selectedValue: {
			required: true,
			type: String,
		},
	},
	emits: [
		'clicked-filter-button',
	],
	methods: {

		// Events

		click(item: FilterItem<string>): void {
			this.$emit('clicked-filter-button', item);
		}

	}
});
</script>
<template>
	<div class="flex gap-2">
		<button
			v-for="(item, index) in items"
			:key="`item-${index}`"
			:class="[
				'border border-blue-500 px-1.5 py-0.5 rounded text-blue-500 text-sm',
				{ 'border-2 font-semibold': selectedValue === item.value },
				buttonClass,
			]"
			:title="item.tooltip ? item.tooltip : ''"

			@click="click(item)"
		>
			{{ item.label }}
		</button>
	</div>
</template>
<style lang="scss" scoped></style>