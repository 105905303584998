<script lang="ts">
import {
	mapActions, mapState
} from 'pinia';
import { useAccountStore } from '../../stores/account';

import auth from '../../services/auth';

import TopbarDropdown from './TopbarDropdown.vue';
import Search from './SearchComponent.vue';
import account from '../../services/account';

import {
	defineComponent, inject
} from 'vue';
import {
	Helpers,
	EventBus,
	CRMPreferencesToSend,
	Notification,
} from '../../models/crm';
import ModalBasic from '../Modal/ModalBasic.vue';

export default defineComponent({
	components: {
		TopbarDropdown,
		ModalBasic,
		Search,
	},
	data() {
		return {
			preferences: {
				isTasksNotificationsEnabled: true,
			},

			intercom: {
				isOpen: false,
			},

			dropdown: {
				showNotifications: false,
				showOptions: false,
			},

			requesting: {
				crmPreferences: false,
			},

			envVars: inject<Record<string, string>>('envVars'),
			eventBus: inject<EventBus>('eventBus'),
			helpers: inject<Helpers>('helpers'),
			pollingInterval: null as ReturnType<typeof setInterval> | null,
		};
	},
	mounted() {
		this.startPolling();
	},
	beforeUnmount() {
		if (this.pollingInterval) {
			clearInterval(this.pollingInterval);
		}
	},
	computed: {
		...mapState(useAccountStore, [ 'notifications', 'user', 'crmPreferences', 'office' ]),

		unreadNotificationsCount(): number {
			return this.notifications?.filter((notification) => !notification.read)
				.length || 0;
		},
	},
	watch: {
		crmPreferences: {
			deep: true,
			immediate: true,
			async handler(newValue): Promise<void> {
				if (
					this.user?.id &&
					!newValue?.user_preferences &&
					!this.requesting.crmPreferences
				) {
					try {
						this.requesting.crmPreferences = true;

						await this.getCRMPreferences(this.user?.id);

						this.requesting.crmPreferences = false;
					} catch (e) {
						console.error(e);

						this.eventBus?.emit('Toast/add', {
							content: 'Erro ao consultar preferências do usuário',
							type: 'error',
						});
					}

					return;
				}

				if (newValue?.user_preferences) {
					this.preferences.isTasksNotificationsEnabled =
						newValue.user_preferences.with_tasks_notifications;
				}
			},
		},
	},
	methods: {
		...mapActions(useAccountStore, [
			'getCRMPreferences',
			'updateCRMPreferences',
			'updateNotificationRead',
		]),

		openPreferencesModal(): void {
			(this.$refs.modalPreferences as typeof ModalBasic)?.show();
		},

		closePreferencesModal(): void {
			(this.$refs.modalPreferences as typeof ModalBasic)?.hide();
		},

		savePreferences(): void {
			this.eventBus?.emit('BlockingOverlay/show', {
				text: 'Aguarde, salvando preferências',
			});

			if (!this.user?.id) {
				this.eventBus?.emit('Toast/add', {
					content: 'Erro ao consultar informações do usuário',
					type: 'error',
				});

				this.eventBus?.emit('BlockingOverlay/hide');

				return;
			}
			try {
				const newPreferences: CRMPreferencesToSend = {
					user_preferences: {
						with_tasks_notifications:
							this.preferences.isTasksNotificationsEnabled,
					},
				};

				this.requesting.crmPreferences = true;

				this.updateCRMPreferences(this.user.id, newPreferences);

				this.eventBus?.emit('Toast/add', {
					content: 'Preferências salvas com sucesso',
					type: 'success',
				});

				this.requesting.crmPreferences = false;

				(this.$refs.modalPreferences as typeof ModalBasic)?.hide();
			} catch (e) {
				console.error(e);

				this.eventBus?.emit('Toast/add', {
					content: 'Erro ao atualizar preferências do usuário',
					type: 'error',
				});
			} finally {
				this.eventBus?.emit('BlockingOverlay/hide');
			}
		},

		async clickedNotification(notification: Notification): Promise<void> {
			this.eventBus?.emit('BlockingOverlay/show', {
				text: 'Aguarde, atualizando status da notificação',
			});

			if (!this.user?.office_id) {
				this.eventBus?.emit('Toast/add', {
					content: 'Não foi possível acessar o id do escritório',
					type: 'error',
				});

				this.eventBus?.emit('BlockingOverlay/hide');

				return;
			}

			try {
				await this.updateNotificationRead(
					true,
					this.user.office_id,
					notification.id
				);
			} catch (e) {
				console.error(e);

				this.eventBus?.emit('Toast/add', {
					content: 'Erro ao comunicar que a notificação foi lida',
					type: 'error',
				});
			} finally {
				this.eventBus?.emit('BlockingOverlay/hide');

				window.open(notification.url || '#', '_self');
			}
		},

		closeNotifications(): void {
			this.dropdown.showNotifications = false;
		},

		closeOptions(): void {
			this.dropdown.showOptions = false;
		},

		toggleNotifications(): void {
			this.dropdown.showNotifications = !this.dropdown.showNotifications;

			(this.$refs.dropdownNotifications as typeof TopbarDropdown)
				?.toggleShow(this.dropdown.showNotifications);
		},

		toggleOptions(): void {
			this.dropdown.showOptions = !this.dropdown.showOptions;

			(this.$refs.dropdownOptions as typeof TopbarDropdown)
				?.toggleShow(this.dropdown.showOptions);
		},

		buttonIntercomClick(): void {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			window.Intercom('boot', {
				app_id: this.envVars?.VITE_INTERCOM_ID,
			});

			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			window.Intercom('onShow', () => this.toggleIntercomOpen(true));
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			window.Intercom('onHide', () => this.toggleIntercomOpen(false));

			if (this.intercom.isOpen) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				window.Intercom('hide');
				return;
			}

			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			window.Intercom('show');
		},

		toggleIntercomOpen(value = false): void {
			this.intercom.isOpen = value;
		},

		goToAllNotifications(): void {
			window.open(`${this.envVars?.VITE_CJ_WEB_APP_URL || '/'}notificacoes`, '_self');
		},

		goToNextRetirementAlerts(): void {
			window.open(`${this.envVars?.VITE_CJ_WEB_APP_URL || '/'}proximas-aposentadorias`, '_self');
		},

		buttonFastAnalysisClick(): void {
			const path = `${this.envVars?.VITE_CJ_WEB_APP_URL}analise-previdenciaria-automatica`;

			window.open(path, '_blank')?.focus();
		},

		buttonInviteAFriendClick(): void {
			const path = `${this.envVars?.VITE_CJ_WEB_APP_URL}convide-um-amigo`;

			window.open(path, '_blank')?.focus();
		},

		buttonLogoutClick(): void {
			auth.signout().finally(() => {
				this.$router.replace('/login');
			});
		},
		startPolling(): void {
			this.fetchAndUpdateNotifications();

			this.pollingInterval = setInterval(() => {
				this.fetchAndUpdateNotifications();
			}, 300000);
		},

		async fetchAndUpdateNotifications(): Promise<void> {
			if (!this.office?.id) return;
			try {
				const {data} = await account.getNotifications(this.office.id);
				const accountStore = useAccountStore();
				accountStore.setNotifications(data.notifications);
			} catch (error) {
				console.error('Error fetching notifications:', error);
			}
		},
	},
});
</script>
<template :key="topbar">
	<header class="flex items-center gap-2"
		id="topbar"
	>
		<Search />
		<div class="flex items-center container-buttons">
			<button v-tippy="helpers?.getTippy('Analisador Rápido do CNIS', {
					placement: 'bottom-end',
				})
				"
				@click="buttonFastAnalysisClick"
			>
				<div class="intercom-button__badge"
					:class="{ 'intercom-button__badge--show': true}"
				/>
				<div class="intercom-button__icon">
					<img src="/img/icons/icon-cnis.svg"
						alt=""
					>
				</div>
			</button>
			<button class="ml-2"
				v-tippy="helpers?.getTippy(
					'Convide um amigo para usar o CJ e vocês dois ganham 💰',
					{ placement: 'bottom-end' }
				)
				"
				@click="buttonInviteAFriendClick"
			>
				<div class="intercom-button__icon">
					<img src="/img/icons/icon-invite-a-friend.svg"
						alt=""
					>
				</div>
			</button>
			<button class="ml-2"
				:class="{'intercom-button--opened': intercom.isOpen}"
				v-tippy="helpers?.getTippy('Tirar dúvidas ou falar com o atendente', {
					placement: 'bottom-end',
				})
				"
				@click="buttonIntercomClick"
			>
				<div class="intercom-button__badge"
					:class="{ 'intercom-button__badge--show': false}"
				/>
				<div class="intercom-button__icon-close">
					<svg viewBox="0 0 24 24">
						<path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
					</svg>
				</div>
				<div class="intercom-button__icon">
					<img src="/img/icons/icon-intercom.svg"
						alt=""
					>
				</div>
			</button>
			<button
				ref="buttonNotifications"
				class="ml-2"
				:class="{'intercom-button--opened': dropdown.showNotifications}"
				v-tippy="helpers?.getTippy('Notificações', { placement: 'bottom-end' })"
				@click.stop="toggleNotifications"
			>
				<div class="intercom-button__icon-close">
					<svg viewBox="0 0 24 24">
						<path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
					</svg>
				</div>
				<div class="intercom-button__icon">
					<img src="/img/icons/icon-notifications.svg"
						alt=""
					>
				</div>
				<span v-if="unreadNotificationsCount > 0"
					class="badge badge--red absolute top-0 right-0 !text-[12px]"
				>
					{{ unreadNotificationsCount }}
				</span>
			</button>
		</div>
		<button
			ref="buttonUser"
			class="inline-flex items-center px-2 button-user"
			@click.stop="toggleOptions"
		>
			<template v-if="user">
				<span class="border user-avatar">
					<img class="inline"
						:src="user.avatar_url"
						alt=""
					>
				</span>
				<span class="hidden lg:block lg:min-w-[8rem] ml-2 name">{{
					user.name
				}}</span>
				<svg class="inline ml-2 icon-arrow"
					fill="currentColor"
					viewBox="0 0 20 20"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path fill-rule="evenodd"
						d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
						clip-rule="evenodd"
					/>
				</svg>
			</template>
		</button>
	</header>
	<Teleport to="body">
		<TopbarDropdown
			ref="dropdownNotifications"
			class="topbar-dropdown-notifications"
			:trigger-element="(($refs.buttonNotifications) as HTMLButtonElement)"
			:width="400"
			@close="closeNotifications"
		>
			<div class="font-medium px-[15px] text-xl notification-header">
				Notificações
			</div>
			<hr class="dropdown-divider">
			<template v-if="notifications && notifications.length > 0">
				<div class="max-h-[300px] overflow-auto container-items">
					<button v-for="item in notifications"
						:key="item.id"
						class="notification-item"
						:title="`[${item.read ? 'Lido' : 'Não lido'}] ${item.message}`"
						@click.stop="clickedNotification(item)"
					>
						<span class="notification-icon">
							<svg v-if="item.read"
								viewBox="0 0 24 24"
								fill="currentColor"
							>
								<path d="M0 0h24v24H0V0z"
									fill="none"
								/>
								<path
									d="M21.99 8c0-.72-.37-1.35-.94-1.7L12 1 2.95 6.3C2.38 6.65 2 7.28 2 8v10c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2l-.01-10zm-2 0v.01L12 13 4 8l8-4.68L19.99 8zM4 18v-7.66l8 5.02 7.99-4.99L20 18H4z"
								/>
							</svg>
							<svg v-else
								viewBox="0 0 24 24"
								fill="currentColor"
							>
								<path d="M0 0h24v24H0V0z"
									fill="none"
								/>
								<path
									d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6zm-2 0l-8 4.99L4 6h16zm0 12H4V8l8 5 8-5v10z"
								/>
							</svg>
						</span>
						<span class="overflow-hidden flex-1 text-[12px] leading-[18px] font-medium whitespace-normal">
							{{ item.message }}
						</span>
					</button>
				</div>
			</template>
			<template v-else>
				<div class="flex-1 flex flex-col justify-between p-4">
					<div class="flex-1 flex items-center justify-center text-7xl">📭</div>
					<div class="text-center">
						<div class="font-medium">Nenhuma novidade por aqui</div>
						<div class="font-semibold text-xs opacity-50">
							Quando algo novo surgir, te avisaremos!
						</div>
					</div>
				</div>
			</template>
			<div class="flex flex-col gap-2 mt-4 items-center">
				<button class="btn btn--primary font-medium normal-case btn--ghost"
					@click.stop="goToAllNotifications"
				>
					Ver todas notificações
				</button>
				<button class="btn btn--primary font-medium normal-case"
					@click.stop="goToNextRetirementAlerts"
				>
					Ver próximos alertas de aposentadoria
				</button>
			</div>
		</TopbarDropdown>
	</Teleport>
	<Teleport to="body">
		<TopbarDropdown
			ref="dropdownOptions"
			class="topbar-dropdown-user"
			small-padding
			:trigger-element="(($refs.buttonUser) as HTMLButtonElement)"
			:width="220"
			@close="closeOptions"
		>
			<a class="flex gap-[15px] pl-[29px] py-[10px] pr-[20px] items-center cursor-pointer hover:bg-[#3f87f5] hover:text-white"
				:href="`${envVars?.VITE_CJ_WEB_APP_URL}minha-conta/dados`"
			>
				<span class="container-icon">
					<svg width="22"
						height="22"
						viewBox="0 0 19 19"
						fill="currentColor"
					>
						<path
							d="M8.98992 10.9351C11.9865 10.9351 14.4574 8.46419 14.4574 5.46757C14.4574 2.47087 11.9865 4.61069e-07 8.98992 4.61069e-07C5.99326 4.61069e-07 3.57495 2.41831 3.57495 5.41497C3.57499 8.41159 5.99326 10.9351 8.98992 10.9351ZM8.98992 0.998897C11.4082 0.998897 13.406 2.99666 13.406 5.41497C13.406 7.83328 11.4082 9.83104 8.98992 9.83104C6.57161 9.83104 4.57385 7.83328 4.57385 5.41497C4.57385 2.99666 6.57161 0.998897 8.98992 0.998897Z"
						/>
						<path
							d="M11.5133 11.4608H6.46639C2.89149 11.4608 0 14.3523 0 17.9272V18.4743C0 18.7898 0.210292 19 0.525678 19H17.5066C17.822 19 18.0322 18.7897 18.0322 18.4743V17.9272C18.0323 14.3523 15.1408 11.4608 11.5133 11.4608ZM16.9283 17.9486H1.05146V17.9272C1.05146 14.9305 3.46977 12.5122 6.46643 12.5122H11.5134C14.51 12.5122 16.9283 14.9305 16.9283 17.9272V17.9486H16.9283Z"
						/>
					</svg>
				</span>
				<span class="font-semibold text-xs">Meu Acesso</span>
			</a>
			<a class="flex gap-[15px] pl-[29px] py-[10px] pr-[20px] items-center cursor-pointer hover:bg-[#3f87f5] hover:text-white"
				:href="`${envVars?.VITE_CJ_WEB_APP_URL}escritorio`"
			>
				<span class="container-icon">
					<svg width="22"
						height="22"
						viewBox="0 0 25 25"
						fill="currentColor"
					>
						<path
							d="M19.97 6.53499H18.206C17.576 6.53499 17.063 6.02199 17.063 5.39099V4.35099C17.063 3.72099 17.576 3.20799 18.206 3.20799H19.97C20.6 3.20799 21.114 3.72099 21.114 4.35099V5.39099C21.113 6.02199 20.6 6.53499 19.97 6.53499ZM18.206 4.27599C18.165 4.27599 18.131 4.30999 18.131 4.35099V5.39099C18.131 5.43299 18.165 5.46599 18.206 5.46599H19.97C20.011 5.46599 20.045 5.43199 20.045 5.39099V4.35099C20.045 4.30899 20.011 4.27599 19.97 4.27599H18.206Z"
						/>
						<path
							d="M11.814 9.2H10.05C9.41998 9.2 8.90698 8.687 8.90698 8.056V7.016C8.90698 6.386 9.41998 5.873 10.05 5.873H11.814C12.444 5.873 12.957 6.386 12.957 7.016V8.056C12.958 8.688 12.445 9.2 11.814 9.2ZM10.051 6.941C10.009 6.941 9.97598 6.975 9.97598 7.016V8.056C9.97598 8.098 10.01 8.131 10.051 8.131H11.815C11.856 8.131 11.89 8.097 11.89 8.056V7.016C11.89 6.974 11.856 6.941 11.815 6.941H10.051Z"
						/>
						<path
							d="M6.55297 9.2H4.78997C4.15997 9.2 3.64697 8.687 3.64697 8.056V7.016C3.64697 6.386 4.15997 5.873 4.78997 5.873H6.55297C7.18297 5.873 7.69597 6.386 7.69597 7.016V8.056C7.69597 8.688 7.18397 9.2 6.55297 9.2ZM4.78997 6.941C4.74797 6.941 4.71497 6.975 4.71497 7.016V8.056C4.71497 8.098 4.74897 8.131 4.78997 8.131H6.55297C6.59497 8.131 6.62797 8.097 6.62797 8.056V7.016C6.62797 6.974 6.59397 6.941 6.55297 6.941H4.78997Z"
						/>
						<path
							d="M11.814 15.462H10.05C9.41998 15.462 8.90698 14.949 8.90698 14.319V13.278C8.90698 12.648 9.41998 12.135 10.05 12.135H11.814C12.444 12.135 12.957 12.648 12.957 13.278V14.319C12.958 14.949 12.445 15.462 11.814 15.462ZM10.051 13.204C10.009 13.204 9.97598 13.238 9.97598 13.279V14.32C9.97598 14.362 10.01 14.395 10.051 14.395H11.815C11.856 14.395 11.89 14.361 11.89 14.32V13.279C11.89 13.238 11.856 13.204 11.815 13.204H10.051Z"
						/>
						<path
							d="M6.55297 15.462H4.78997C4.15997 15.462 3.64697 14.949 3.64697 14.319V13.278C3.64697 12.648 4.15997 12.135 4.78997 12.135H6.55297C7.18297 12.135 7.69597 12.648 7.69597 13.278V14.319C7.69597 14.949 7.18397 15.462 6.55297 15.462ZM4.78997 13.204C4.74797 13.204 4.71497 13.238 4.71497 13.279V14.32C4.71497 14.362 4.74897 14.395 4.78997 14.395H6.55297C6.59497 14.395 6.62797 14.361 6.62797 14.32V13.279C6.62797 13.238 6.59397 13.204 6.55297 13.204H4.78997Z"
						/>
						<path
							d="M19.97 13.542H18.206C17.576 13.542 17.063 13.029 17.063 12.399V11.359C17.063 10.729 17.576 10.216 18.206 10.216H19.97C20.6 10.216 21.114 10.729 21.114 11.359V12.399C21.113 13.029 20.6 13.542 19.97 13.542ZM18.206 11.284C18.165 11.284 18.131 11.318 18.131 11.359V12.399C18.131 12.441 18.165 12.474 18.206 12.474H19.97C20.011 12.474 20.045 12.44 20.045 12.399V11.359C20.045 11.317 20.011 11.284 19.97 11.284H18.206Z"
						/>
						<path
							d="M19.97 20.559H18.206C17.576 20.559 17.063 20.046 17.063 19.416V18.375C17.063 17.745 17.576 17.232 18.206 17.232H19.97C20.6 17.232 21.114 17.745 21.114 18.375V19.416C21.113 20.046 20.6 20.559 19.97 20.559ZM18.206 18.301C18.165 18.301 18.131 18.335 18.131 18.376V19.417C18.131 19.459 18.165 19.492 18.206 19.492H19.97C20.011 19.492 20.045 19.459 20.045 19.417V18.376C20.045 18.335 20.011 18.301 19.97 18.301H18.206Z"
						/>
						<path
							d="M15.4369 24.223H1.11694V4.90699C1.11694 3.96999 1.87894 3.20799 2.81594 3.20799H13.7379C14.6749 3.20799 15.4369 3.96999 15.4369 4.90699V24.223ZM2.18494 23.155H14.3699V4.90699C14.3699 4.55899 14.0869 4.27599 13.7389 4.27599H2.81494C2.46694 4.27599 2.18394 4.55899 2.18394 4.90699V23.155H2.18494Z"
						/>
						<path
							d="M23.7371 24.223H14.9031V23.156H22.6701V1.699C22.6701 1.351 22.3871 1.068 22.0391 1.068H9.46609C9.11809 1.068 8.83509 1.351 8.83509 1.699V3.742H7.76709V1.699C7.76709 0.762 8.52909 0 9.46609 0H22.0391C22.9761 0 23.7371 0.762 23.7371 1.699V24.223Z"
						/>
						<path
							d="M10.7009 24.223H5.90088V19.509C5.90088 18.977 6.33388 18.544 6.86588 18.544H9.73588C10.2679 18.544 10.7009 18.977 10.7009 19.509V24.223ZM6.96888 23.155H9.63288V19.611H6.96888V23.155Z"
						/>
						<path
							d="M24.467 24.223H0.534C0.239 24.223 0 23.984 0 23.689C0 23.394 0.239 23.155 0.534 23.155H24.467C24.761 23.155 25 23.394 25 23.689C25 23.984 24.761 24.223 24.467 24.223Z"
						/>
					</svg>
				</span>
				<span class="font-semibold text-xs">Escritório e Usuários</span>
			</a>
			<a class="flex gap-[15px] pl-[29px] py-[10px] pr-[20px] items-center cursor-pointer hover:bg-[#3f87f5] hover:text-white"
				:href="`${envVars?.VITE_CJ_WEB_APP_URL}assinatura/fatura`"
			>
				<span class="container-icon">
					<svg width="22"
						height="22"
						viewBox="0 0 26 17"
						fill="currentColor"
					>
						<path
							d="M22.8437 1.00399e-07H2.65622C1.19212 1.00399e-07 0 1.19212 0 2.6563V14.3438C0 15.8079 1.19212 17 2.65622 17H22.8437C24.3078 17 25.4999 15.8079 25.4999 14.3438V2.6563C25.4999 1.19212 24.3078 1.00399e-07 22.8437 1.00399e-07ZM24.4374 14.3437C24.4374 15.2224 23.7224 15.9375 22.8437 15.9375H2.65622C1.77751 15.9375 1.06248 15.2224 1.06248 14.3437V2.6563C1.06248 1.77759 1.77751 1.06255 2.65622 1.06255H22.8437C23.7224 1.06255 24.4374 1.77759 24.4374 2.6563V14.3438H24.4374V14.3437Z"
						/>
						<path
							d="M24.9686 3.18752H0.531264C0.238028 3.18752 0 3.42552 0 3.71878V6.90626C0 7.19953 0.238028 7.43753 0.531264 7.43753H24.9687C25.262 7.43753 25.5 7.1995 25.5 6.90626V3.71878C25.4999 3.42552 25.2619 3.18752 24.9686 3.18752ZM24.4374 6.375H1.06248V4.24999H24.4374V6.375H24.4374Z"
						/>
						<path
							d="M7.96875 10.625H3.71876C3.42553 10.625 3.1875 10.8631 3.1875 11.1563C3.1875 11.4495 3.42553 11.6875 3.71876 11.6875H7.96875C8.26199 11.6875 8.50002 11.4495 8.50002 11.1562C8.50002 10.863 8.26199 10.625 7.96875 10.625Z"
						/>
						<path
							d="M11.1562 12.7499H3.71876C3.42553 12.7499 3.1875 12.988 3.1875 13.2812C3.1875 13.5745 3.42553 13.8125 3.71876 13.8125H11.1562C11.4495 13.8125 11.6875 13.5745 11.6875 13.2812C11.6875 12.988 11.4495 12.7499 11.1562 12.7499Z"
						/>
						<path
							d="M17.4674 14.1825C16.0917 14.1825 14.9724 13.0632 14.9724 11.6875C14.9724 10.3117 16.0917 9.19247 17.4674 9.19247C18.2818 9.19247 19.0469 9.59186 19.5139 10.2608L19.7193 10.5551L19.5139 10.8494C19.3416 11.0962 19.2505 11.386 19.2505 11.6874C19.2505 11.9889 19.3416 12.2787 19.5139 12.5256L19.7193 12.8198L19.5139 13.1141C19.0469 13.7831 18.2818 14.1825 17.4674 14.1825ZM17.4674 10.2205C16.6585 10.2205 16.0005 10.8786 16.0005 11.6874C16.0005 12.4963 16.6586 13.1544 17.4674 13.1544C17.8406 13.1544 18.1964 13.0115 18.4653 12.7619C18.3056 12.4283 18.2224 12.0631 18.2224 11.6874C18.2224 11.3117 18.3056 10.9467 18.4653 10.613C18.1964 10.3634 17.8406 10.2205 17.4674 10.2205Z"
						/>
						<path
							d="M20.7174 14.1825C19.3417 14.1825 18.2224 13.0632 18.2224 11.6875C18.2224 10.3117 19.3417 9.19247 20.7174 9.19247C22.0932 9.19247 23.2124 10.3117 23.2124 11.6875C23.2124 13.0632 22.0932 14.1825 20.7174 14.1825ZM20.7174 10.2205C19.9085 10.2205 19.2505 10.8786 19.2505 11.6874C19.2505 12.4963 19.9086 13.1544 20.7174 13.1544C21.5262 13.1544 22.1843 12.4963 22.1843 11.6874C22.1843 10.8786 21.5263 10.2205 20.7174 10.2205Z"
						/>
					</svg>
				</span>
				<span class="font-semibold text-xs">Assinatura</span>
			</a>
			<button
				class="flex gap-[15px] pl-[29px] py-[10px] pr-[20px] mb-[8px] w-full items-center cursor-pointer hover:bg-[#3f87f5] hover:text-white"
				@click.stop="openPreferencesModal"
			>
				<svg viewBox="0 0 478.7 478.7"
					width="22"
					height="22"
					fill="currentColor"
				>
					<path
						d="M454.2 189.1l-33.6-5.7c-3.5-11.3-8-22.2-13.5-32.6l19.8-27.7a29.5 29.5 0 00-3.2-38.1l-29.8-29.8a29.4 29.4 0 00-38-3.2l-27.8 19.8a187.8 187.8 0 00-33.8-13.9l-5.6-33.2A29.5 29.5 0 00259.5 0h-42.1a29.5 29.5 0 00-29.2 24.7l-5.8 34a185 185 0 00-32.5 13.7l-27.5-19.8a29.4 29.4 0 00-38.1 3.2L54.4 85.6a29.6 29.6 0 00-3.2 38.1l20 28.1a185.3 185.3 0 00-13.3 32.7l-33.2 5.6A29.5 29.5 0 000 219.3v42.1a29.5 29.5 0 0024.7 29.2l34 5.8a185 185 0 0013.7 32.5l-19.7 27.4a29.5 29.5 0 003.2 38.1l29.8 29.8a29.4 29.4 0 0038 3.2l28.1-20c10.1 5.3 20.7 9.6 31.6 13L189 454a29.5 29.5 0 0029.2 24.7h42.2a29.5 29.5 0 0029.2-24.7l5.7-33.6c11.3-3.5 22.2-8 32.6-13.5l27.7 19.8a29.4 29.4 0 0038.1-3.2l29.8-29.8a29.6 29.6 0 003.2-38.1l-19.8-27.8a188.4 188.4 0 0013.5-32.6l33.6-5.6a29.5 29.5 0 0024.7-29.2v-42.1a29.2 29.2 0 00-24.5-29.2zm-2.3 71.3c0 1.3-.9 2.4-2.2 2.6l-42 7c-5.3.9-9.5 4.8-10.8 9.9a160 160 0 01-17.4 41.9 13.5 13.5 0 00.6 14.7l24.7 34.8c.7 1 .6 2.5-.3 3.4l-29.8 29.8c-.7.7-1.4.8-1.9.8-.6 0-1.1-.2-1.5-.5l-34.7-24.7a13.5 13.5 0 00-14.7-.6 160 160 0 01-41.9 17.4 13.3 13.3 0 00-9.9 10.8l-7.1 42a2.6 2.6 0 01-2.6 2.2h-42.1c-1.3 0-2.4-.9-2.6-2.2l-7-42c-.9-5.3-4.8-9.5-9.9-10.8a166.2 166.2 0 01-41-16.8 13.8 13.8 0 00-6.8-1.8c-2.7 0-5.5.8-7.8 2.5l-35 24.9a3 3 0 01-1.5.5c-.4 0-1.2-.1-1.9-.8L75 375.6c-.9-.9-1-2.3-.3-3.4l24.6-34.5c3.1-4.4 3.3-10.2.6-14.8-7.8-13-13.8-27.1-17.6-41.8-1.4-5.1-5.6-9-10.8-9.9L29.2 264a2.6 2.6 0 01-2.2-2.6v-42.1c0-1.3.9-2.4 2.2-2.6l41.7-7c5.3-.9 9.6-4.8 10.9-10a160 160 0 0117.1-42 13.4 13.4 0 00-.7-14.6l-24.9-35c-.7-1-.6-2.5.3-3.4l29.8-29.8c.7-.7 1.4-.8 1.9-.8.6 0 1.1.2 1.5.5l34.5 24.6a13.7 13.7 0 0014.8.6c13-7.8 27.1-13.8 41.8-17.6 5.1-1.4 9-5.6 9.9-10.8l7.2-42.3a2.6 2.6 0 012.6-2.2h42.1c1.3 0 2.4.9 2.6 2.2l7 41.7c.9 5.3 4.8 9.6 10 10.9 15.1 3.8 29.5 9.7 42.9 17.6a13.5 13.5 0 0014.7-.6l34.5-24.8a3 3 0 011.5-.5c.4 0 1.2.1 1.9.8l29.8 29.8c.9.9 1 2.3.3 3.4l-24.7 34.7a13.5 13.5 0 00-.6 14.7 160 160 0 0117.4 41.9c1.3 5.2 5.6 9.1 10.8 9.9l42 7.1c1.3.2 2.2 1.3 2.2 2.6v42.1h-.1z"
					/>
					<path
						d="M239.4 136a103.4 103.4 0 10.1 206.7 103.4 103.4 0 00-.1-206.7zm0 179.6a76.4 76.4 0 11.1-152.7 76.4 76.4 0 01-.1 152.7z"
					/>
				</svg>
				<span class="font-semibold text-xs">Preferências</span>
			</button>
			<hr>
			<button
				class="flex gap-[15px] pl-[29px] py-[10px] pr-[20px] mt-[8px] w-full items-center cursor-pointer hover:bg-[#3f87f5] hover:text-white"
				@click="buttonLogoutClick"
			>
				<span class="container-icon">
					<svg width="22"
						height="22"
						viewBox="0 0 17 18"
						fill="currentColor"
					>
						<path
							d="M16.5598 9.39866C16.5635 9.39498 16.5635 9.39498 16.5672 9.39131C16.5782 9.37661 16.5892 9.36559 16.5965 9.35089C16.6002 9.34722 16.6002 9.34354 16.6039 9.33987C16.6112 9.32517 16.6223 9.31047 16.6296 9.29578C16.6296 9.2921 16.6333 9.28843 16.6333 9.28843C16.6406 9.27373 16.648 9.25904 16.6553 9.24066C16.6553 9.23699 16.6553 9.23699 16.659 9.23332C16.6664 9.21862 16.67 9.20025 16.6774 9.18188C16.6774 9.1782 16.6774 9.17453 16.6811 9.17453C16.6847 9.15616 16.6921 9.14146 16.6921 9.12309C16.6921 9.11574 16.6921 9.11207 16.6958 9.10472C16.6994 9.09002 16.6994 9.07533 16.7031 9.06063C16.7068 9.03858 16.7068 9.02021 16.7068 8.99817C16.7068 8.97612 16.7068 8.95775 16.7031 8.9357C16.7031 8.92101 16.6994 8.90631 16.6958 8.89161C16.6958 8.88427 16.6958 8.88059 16.6921 8.87324C16.6884 8.85487 16.6847 8.84017 16.6811 8.8218C16.6811 8.81813 16.6811 8.81446 16.6774 8.81446C16.6737 8.79608 16.6664 8.78139 16.659 8.76302C16.659 8.75934 16.659 8.75934 16.6553 8.75567C16.648 8.74097 16.6406 8.7226 16.6333 8.7079C16.6333 8.70423 16.6296 8.70055 16.6296 8.70055C16.6223 8.68586 16.6149 8.67116 16.6039 8.65646C16.6002 8.65279 16.6002 8.64912 16.5965 8.64544C16.5855 8.63074 16.5782 8.61605 16.5672 8.60502C16.5635 8.60135 16.5635 8.60135 16.5598 8.59768C16.5451 8.58298 16.5341 8.56461 16.5157 8.54991L12.8819 4.91978C12.6357 4.67361 12.2352 4.67361 11.9891 4.91978C11.7429 5.16596 11.7429 5.56645 11.9891 5.81262L14.5463 8.36987H4.37977C4.03072 8.36987 3.7478 8.65279 3.7478 8.99817C3.7478 9.34722 4.03072 9.63013 4.37977 9.63013H14.55L12.0111 12.169C11.7649 12.4152 11.7649 12.8157 12.0111 13.0619C12.1324 13.1831 12.294 13.2456 12.4557 13.2456C12.6174 13.2456 12.779 13.1831 12.9003 13.0619L16.512 9.4501C16.5304 9.42805 16.5451 9.41335 16.5598 9.39866Z"
						/>
						<path
							d="M3.4207 1.26026H8.1237C8.47275 1.26026 8.75566 0.977342 8.75566 0.631966C8.75566 0.282915 8.47275 0 8.1237 0H3.4207C1.53582 0 1.4016e-08 1.53582 1.4016e-08 3.4207V14.5793C1.4016e-08 16.4642 1.53582 18 3.4207 18H8.04654C8.39559 18 8.6785 17.7171 8.6785 17.3717C8.6785 17.0227 8.39559 16.7397 8.04654 16.7397H3.4207C2.23025 16.7397 1.26026 15.7697 1.26026 14.5793V3.4207C1.26393 2.22658 2.23025 1.26026 3.4207 1.26026Z"
						/>
					</svg>
				</span>
				<span class="font-semibold text-xs">Sair</span>
			</button>
		</TopbarDropdown>
	</Teleport>

	<ModalBasic ref="modalPreferences">
		<template #content>
			<div class="p-4 flex flex-col">
				<div class="flex justify-between items-center pb-4 border-b border-[#e5e7eb]">
					<div class="flex m-0 p-0 items-center">
						<span class="w-[35px] h-[35px]">
							<svg viewBox="0 -960 960 960"
								fill="#3F87F5"
							>
								<path
									d="m388-80-20-126q-19-7-40-19t-37-25l-118 54-93-164 108-79q-2-9-2.5-20.5T185-480q0-9 .5-20.5T188-521L80-600l93-164 118 54q16-13 37-25t40-18l20-127h184l20 126q19 7 40.5 18.5T669-710l118-54 93 164-108 77q2 10 2.5 21.5t.5 21.5q0 10-.5 21t-2.5 21l108 78-93 164-118-54q-16 13-36.5 25.5T592-206L572-80H388Zm92-270q54 0 92-38t38-92q0-54-38-92t-92-38q-54 0-92 38t-38 92q0 54 38 92t92 38Z"
								/>
							</svg>
						</span>
						<h1 class="ml-2 font-bold text-2xl">Preferências</h1>
					</div>
					<button class="w-6 h-6"
						type="button"
						@click.stop="closePreferencesModal"
					>
						<svg data-v-1a86fb19=""
							fill="currentColor"
							viewBox="0 0 20 20"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path data-v-1a86fb19=""
								fill-rule="evenodd"
								d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
								clip-rule="evenodd"
							/>
						</svg>
					</button>
				</div>
				<div class="flex mt-4 p-0 justify-between">
					<span class="text-sm font-semibold">
						Deseja habilitar as notificações sobre Tarefas?
					</span>
					<input class="toggle toggle-success"
						type="checkbox"
						v-model="preferences.isTasksNotificationsEnabled"
					>
				</div>
			</div>
		</template>
		<template #footer>
			<div class="flex-1 flex overflow-hidden rounded-b-md">
				<button class="flex-1 bg-gray-200 text-base p-4 text-gray-600 hover:bg-gray-300 active:bg-gray-200"
					@click.stop="closePreferencesModal"
				>
					CANCELAR
				</button>
				<button class="flex-1 bg-primary text-base p-4 text-white hover:bg-blue-600 active:bg-primary"
					@click.stop="savePreferences"
				>
					SALVAR
				</button>
			</div>
		</template>
	</ModalBasic>
</template>
<style lang="scss">
$primary : #3f87f5;
$red : #eb5757;

#topbar {
	background-color: #fff;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
	height: 50px;

	.container-buttons {
		display: flex;
		gap: 8px;
		flex-direction: row;
        align-items: center;
        justify-content: space-around;

        .dropdown .rounded-layer {
          margin: 0;
        }

		button {
			display: flex;
			line-height: 1;
			width: 37px;
			height: 37px;
			border-radius: 50%;
			background: $primary;
			border: none;
			padding: 0;
			margin: 0;
			transition: background-color .15s ease-in-out;
			position: relative;

			&:not(:disabled):focus,
			&:not(:disabled):hover {
				outline: none;
				background: #0f69f2;
				box-shadow: 0 0 0 2px rgba(63, 135, 245, 0.5);
			}

			&:not(:disabled):active {
				background: $primary;
				outline: none;
				box-shadow: 0 0 0 2px rgba(63, 135, 245, 1);
			}
		}
	}

	.intercom-button__badge {
		content: '';
		width: 12px;
		height: 12px;
		background: $red;
		border-radius: 50%;
		position: absolute;
		border: 1px solid white;
		top: -2px;
		right: -2px;
		transform: scale(0);
		transition: transform .1s ease-in-out;
	}

	.intercom-button__badge--show {
		transform: scale(1);
	}

	.badge {
		background-color: #C2C4C6;
		border-radius: 15px;
		padding: 2px 7px;
		color: #ffffff;
		font-size: 10px;
		line-height: normal;
		font-weight: 600;
		white-space: nowrap;
		vertical-align: text-top;
	}

	.badge--red {
		background-color: $red;
	}

	.intercom-button__icon,
	.intercom-button__icon-close {
		transition: transform .1s ease-in-out;
		position: absolute;
		top: 7px;
		left: 7px;

		svg, img {
			width: 23px;
			height: 23px;
			fill: white;
		}
	}

	.intercom-button__icon {
		transform: scale(1);
	}

	.intercom-button__icon-close {
		transform: scale(0);
	}

	.intercom-button--opened {
		.intercom-button__icon {
			transform: scale(0);
		}

		.intercom-button__icon-close {
			transform: scale(1);
		}
	}

	.button-user {
		&:hover {
			.user-avatar {
				box-shadow: 0 0 0 2px #3f87f580;
			}
		}

		.user-avatar {
			display: inline-flex;

			background-color: #fff;
			border-radius: 100px;
			height: 36px;
			overflow: hidden;
			width: 36px;

			img {
				max-height: fit-content;
				max-width: fit-content;
			}
		}

		.name {
			font-weight: 600;
			font-size: 12px;
			color: #3b424d;
		}

		.icon-arrow {
			height: 20px;
		}
	}
}

.topbar-dropdown {

	:deep(a),
	:deep(button) {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		width: 100%;

		&:hover {
			background-color: $primary;
			color: #fff;

			img {
				filter: invert(1);
			}
		}

		img {
			display: inline-block;
			height: 22px;
			width: 22px;
		}

		.container-items {
			width: inherit;
		}
	}
}

.topbar-dropdown-user {

	:deep(a),
	:deep(button) {
		&:hover {
			svg {
				fill: #fff;
			}
		}

		.container-icon {
			display: inline-flex;
			justify-content: center;

			width: 26px;

			svg {
				max-height: 22px;
				max-width: 22px;
			}
		}
	}
}

.notification-list {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0;
    padding: 0;
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
}

.notification-item {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 15px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 15px;
    cursor: pointer;
    font-size: 12px;
    color: #3b424d;
    text-decoration: none;
    background: transparent;
    border: none;

	&:hover {
		outline: none;
		background-color: $primary;
		color: #fff;
	}
}

.notification-icon {
	svg {
		width: 22px;
		height: 22px;
		fill: currentColor;
		max-width: none;
	}
}

.dropdown-divider {
	height: 0;
    margin: .5rem 0;
    overflow: hidden;
    border-top: .0625rem solid #efefef;
}
</style>
