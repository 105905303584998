<script lang="ts">
import account from '../services/account';
import auth from '../services/auth';
import users from '../services/users';

import Sidebar from '../components/Sidebar/SidebarComponent.vue';
import Topbar from '../components/Topbar/TopbarComponent.vue';

import { defineComponent } from 'vue';
import {
	mapState, mapActions
} from 'pinia';
import { useAccountStore } from '../stores/account';

export default defineComponent({
	components: {
		Sidebar,
		Topbar,
	},
	computed: {
		...mapState(useAccountStore, [ 'user' ]),
		userId() {
			return this.user?.id;
		},
	},
	methods: {
		...mapActions(useAccountStore, [ 'setAccount', 'setUserPreferences' ]),

		callSetAccount(): Promise<void> {
			return new Promise(
				(resolve, reject) => {
					account.getAccount().then(({data}) => {
						try {
							this.setAccount(data);
							resolve();
						} catch (err) {
							console.error(err);

							// Force logout on error
							auth
								.signout()
								.finally(
									() => {
										this.$router.replace('/login');
									}
								);

							reject();
						}
					}).catch((err) => {
						console.error(err);

						// Force logout on error
						auth
							.signout()
							.finally(
								() => {
									this.$router.replace('/login');
								}
							);

						reject();
					});
				}
			);
		},

		async callSetUserPreferences(): Promise<void> {
			if (!this.userId) return;
			const { data } = await users.getById(this.userId);
			const { user } = data;

			const preferences = user?.preferences || {};

			this.setUserPreferences(preferences);
		},

	},
	created() {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		window.Intercom('boot', {
			app_id: import.meta.env.VITE_INTERCOM_ID,
			hide_default_launcher: true,
		});
	},
	async beforeMount() {
		await this.callSetAccount();
		this.callSetUserPreferences();
	},
});
</script>

<template>
	<div id="layout-authenticated-area"
		class="flex"
	>
		<sidebar />
		<div class="flex-1 flex flex-col min-h-0 min-w-0">
			<topbar />
			<router-view />
		</div>
	</div>
</template>

<style lang="scss" scoped>
#layout-authenticated-area {
    height: inherit;
    width: inherit;

    :deep(#topbar) {
        z-index: 20;
    }
}
</style>