import {
	CategoriesResponse, Category, CategoryToSend
} from '../../../models/financial';
import axios, { AxiosResponse } from 'axios';

const pageParams = '&per_page=999999&page=1';

export default {

	create(data: CategoryToSend): Promise<AxiosResponse<Category>> {
		const path = '/finances/income_categories.json';
		return axios.post(path, data);
	},

	update(id: number, data: CategoryToSend): Promise<AxiosResponse<Category>> {
		const path = `/finances/income_categories/${id}.json`;
		return axios.put(path, data);
	},

	delete(id: number): Promise<AxiosResponse<null|undefined>> {
		const path = `/finances/income_categories/${id}.json`;
		return axios.delete(path, {});
	},

	getAll(orderBy: string): Promise<AxiosResponse<CategoriesResponse>> {
		const path = `/finances/income_categories.json?${ orderBy.length ? `order=${orderBy}` : '' }${pageParams}`;
		return axios.get(path);
	},

	searchByName(name: string, perPage = 50, nextPage = 1)
		: Promise<AxiosResponse<CategoriesResponse>> {
		const path = `/finances/income_categories.json?search=${name}&per_page=${perPage}&page=${nextPage}`;
		return axios.get(path);
	},

};