import axios, { AxiosResponse } from 'axios';

import storage from './storage';

const defaultHeaders = {
	'Accept': 'application/json',
	'Content-Type': 'application/json',
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const authSuccessActions = (response: AxiosResponse<any, any>): void => {
	let accessToken = response.data.access_token;

	axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

	if (accessToken) {
		accessToken = `"${ accessToken }"`;
	}
	localStorage.setItem('cj-access-token', accessToken);
};

const signin = async({
	login, password
}: {
	login: string;
	password: string;
}): Promise<AxiosResponse> => {
	const url = '/users/sign_in';

	const data = {
		user: {
			login,
			password,
		}
	};

	const options = {
		headers: defaultHeaders,
	};

	const response = await axios.post(url, data, options);

	try {
		authSuccessActions(response);
	} catch (err) { /* empty */ }

	return response;
};

const signinGoogleOauth = ({
	envVars, gapi
}: {
	envVars: Record<string, string>,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	gapi: any,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
}): Promise<any> => {
	const callbackUrl = '/users/auth/google_oauth2/callback';

	return new Promise(
		(resolve, reject) => {
			const authorize = (): void => {
				gapi.auth2.authorize({
					client_id: envVars.VITE_GOOGLE_CLIENT_ID,
					cookie_policy: 'single_host_origin',
					scope: 'email profile',
					response_type: 'code'
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				}, (response: any) => {
					if (response && !response.error) {
						axios
							.post(
								callbackUrl,
								response, {
									headers: {
										'X-Requested-With': 'XMLHttpRequest'
									},
								}
							)
							.then(
								response => {
									authSuccessActions(response);
									resolve(response);
								}
							)
							.catch(
								err => {
									reject(err);
								}
							);
					} else {
						reject(response.error);
					}
				});
			};

			if (gapi.auth2) {
				authorize();
			} else {
				gapi.load('auth2', authorize);
			}
		}
	);
};

const signout = (): Promise<void> => {
	const url = '/users/sign_out';

	let accessToken = localStorage.getItem('cj-access-token') || storage.getItem('localStorage', 'access-token');
	if (accessToken) {
		accessToken = accessToken.replace(/"/g, '');
	}

	const options = {
		headers: {
			...defaultHeaders,
			'Authorization': `Bearer ${accessToken}`,
		}
	};

	if (!accessToken) {
		return Promise.reject(new Error('Tried to logout without cj-access-token'));
	}

	return axios.delete(url, options)
		.then(
			response => {
				localStorage.removeItem('cj-access-token');
				delete axios.defaults.headers.common['Authorization'];
			}
		);
};

export default {
	signin,
	signinGoogleOauth,
	signout,
};
