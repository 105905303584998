import { SeedDefaultsResponse } from '../../../models/financial';
import axios, { AxiosResponse } from 'axios';

const modulePath = '/finances';

export default {

	/**
     * Creates the default common cost centers and categories
     * to favor finance module kickstart
     *
     * @returns {Promise}
     */
	async seedDefaults(): Promise<AxiosResponse<SeedDefaultsResponse>> {
		const path = `${modulePath}/dashboard/seed_defaults.json`;
		return axios.post(path, {});
	},

};
