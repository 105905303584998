<script lang="ts">
import {
	defineComponent, inject
} from 'vue';
import {
	Filters, FilterItem
} from '../../models/crm';

export default defineComponent({
	props: {
		buttonSelectTitle: {
			type: String,
			default: 'Aplicar filtros à tabela'
		},
		filtersAndOptionsTitle: {
			type: String,
			default: 'Filtros e opções'
		},
	},
	emits: [
		'filters-apply',
		'filters-clear',
		'filters-item-remove',
	],
	data() {
		return {

			drawer: {
				show: false as boolean,
			},
			filters: inject<Filters>('filters'),

		};
	},
	computed: {
		numberAppliedFilters(): number {
			return Object.values((this.filters as Filters | undefined)?.applied || {})
				.filter(el => {
					if (!el) return false;
					return Object.keys(el).length > 0;
				})
				.length;
		}
	},
	methods: {

		close(): void {
			this.drawer.show = false;
		},

		// Events
		buttonAddFilterClick(): void {
			this.drawer.show = true;
		},

		buttonFilterItemRemoveClick(
			_e: Event,
			filterItem: [ key: string,
				value: string | string[] | FilterItem<string | boolean>[]
			]
		): void {
			this.$emit('filters-item-remove', filterItem);
		},

		buttonFiltersClearClick(): void {
			this.$emit('filters-clear');
		},

		drawer_buttonCloseClick(): void {
			this.close();
		},

		drawer_buttonApplyClick(): void {
			this.$emit('filters-apply');

			this.close();
		},

	},
});
</script>
<template>
	<div class="max-w-md component-filters-chain">
		<div class="inline-flex container-filters-items h-[35px]">
			<button
				class="inline-flex btn btn-sm !py-[initial] !px-4 btn--primary button-select-filters h-full"
				:style="numberAppliedFilters > 0
					&& 'border-top-right-radius: 0; border-bottom-right-radius: 0;'
				"
				:title="buttonSelectTitle"

				@click="buttonAddFilterClick"
			>
				<span
					class="flex items-center justify-center bg-gray-200 w-5 h-5 rounded-[50%] text-sm text-[rgb(51,51,51)] font-semibold"
				>
					{{ numberAppliedFilters }}
				</span>
				<span class="text-xs px-4 font-semibold">Filtros</span>
			</button>
			<button
				v-if="numberAppliedFilters > 0"
				class="flex items-center justify-center border-0 !h-full !px-1.5 btn btn--red"
				style="border-top-left-radius: 0; border-bottom-left-radius: 0;"
				title="Limpar filtros"
				@click.stop="buttonFiltersClearClick"
			>
				<svg
					class="w-4 h-4"
					fill="currentColor"
					viewBox="0 0 20 20"
					xmlns="http://www.w3.org/2000/svg"
				><path
					fill-rule="evenodd"
					d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
					clip-rule="evenodd"
				/></svg>
			</button>
		</div>
	</div>
	<Teleport to="body">
		<div
			:class="[
				'fixed h-screen left-0 top-0 w-screen drawer-wrapper',
				drawer.show ? 'z-[1000]' : 'z-[-1]'
			]"
		>
			<div class="drawer drawer-end">
				<input
					id="drawer-filters"
					v-model="drawer.show"
					type="checkbox"
					class="drawer-toggle"
				>
				<div class="drawer-side">
					<label
						for="drawer-filters"
						class="drawer-overlay"
					/>
					<div class="menu p-4 w-1/3 bg-base-100 text-base-content">

						<div class="flex justify-between drawer-header">
							<div class="font-bold text-lg">
								{{ filtersAndOptionsTitle }}
							</div>
							<button @click="drawer_buttonCloseClick">
								<svg
									class="w-6 h-6"
									fill="none"
									stroke="currentColor"
									viewBox="0 0 24 24"
									xmlns="http://www.w3.org/2000/svg"
								><path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M6 18L18 6M6 6l12 12"
								/></svg>
							</button>
						</div>

						<hr class="my-4">

						<div class="mb-3 text-right">
							<button
								class="btn btn-link h-auto min-h-0 p-0"
								@click="buttonFiltersClearClick"
							>
								Limpar filtros
							</button>
						</div>

						<div class="container-filters-slot">
							<slot name="filters" />
						</div>

						<hr class="mb-4 mt-6">

						<div class="container-button">
							<button
								class="btn btn--primary rounded w-full"
								@click="drawer_buttonApplyClick"
							>
								Aplicar
							</button>
						</div>

					</div>
				</div>
			</div>
		</div>
	</Teleport>
</template>
<style lang="scss" scoped>
.component-filters-chain {

    .button-select-filters {
        svg {
            height: 16px;
            width: 16px;
        }
    }

}
</style>