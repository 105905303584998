import { defineStore } from 'pinia';
import { Task } from '../../../models/crm';
import serviceTasks from '../../../services/API/crm/tasks';

type TasksState = {
	tasksList: Task[];
    maxTasks: number;
	isRequestingTasks: boolean;

	searchedTasks: Task[];
	searchMaxTasks: number;
}

export const useTasksStore = defineStore('tasks', {
	state: (): TasksState => ({
		tasksList: [] as Task[],
		maxTasks: 100,
		isRequestingTasks: false,

		searchedTasks: [] as Task[],
		searchMaxTasks: 0,
	}),
	actions: {
		setTasksList(value: Task[]): void {
			this.tasksList = value;
		},

		setMaxTasks(value: number): void {
			this.maxTasks = value;
		},

		addTasksToList(value: Task[]): void {
			this.tasksList = this.tasksList.concat(value);
		},

		async deleteTask(taskId: number | string): Promise<void> {
			await serviceTasks.delete(taskId);

			const index = this.tasksList.findIndex(task => task.id == taskId);

			if (index === -1) return;

			this.tasksList.splice(index, 1);
		},

		async loadTasks(): Promise<void> {
			this.isRequestingTasks = true;

			const { data } = await serviceTasks.getAll(9999, 1, 'due_at:desc', '');

			this.tasksList = data.tasks;

			this.isRequestingTasks = false;

			this.maxTasks = data.tasks.length;
		},

		async paginatedLoadTasks({
			perPage, page, orderBy, filters
		}: {
                perPage: number;
                page: number;
				orderBy: string;
				filters: string
            }
		): Promise<void> {
			this.isRequestingTasks = true;

			const { data: mainData } = await serviceTasks.getAll(perPage, page, orderBy, filters);

			this.maxTasks = mainData.total_count;

			if (this.tasksList.length === 0) {
				this.tasksList = mainData.tasks;

				this.isRequestingTasks = false;

				return;
			}

			if (this.tasksList.length === perPage * (page - 1)) {
				this.tasksList = this.tasksList.concat(mainData.tasks);

				this.isRequestingTasks = false;

				return;
			}

			if (this.tasksList.length > perPage * (page - 1)) {
				if (page === 1) {
					this.tasksList = mainData.tasks;

					this.isRequestingTasks = false;

					return;
				}

				const { data: stepData } =
					await serviceTasks.getAll(perPage * page, 1, orderBy, filters);

				this.tasksList = stepData.tasks;

				this.isRequestingTasks = false;

				return;
			}

			throw new Error('Impossível paginar! Provavelmente a aplicação se encontra na página final');
		},

		async searchTasksByName({
			name, perPage = 50, page = 1, orderBy, filters = ''
		}: {
				name: string;
                perPage: number;
                page: number;
				orderBy: string;
				filters: string
            }
		): Promise<void> {
			const { data: mainData } =
				await serviceTasks.searchByName(name, perPage, page, orderBy, filters);

			if (name.length > 0) {
				this.searchMaxTasks = mainData.total_count;
			} else {
				this.searchMaxTasks = 0;
			}

			if (this.searchedTasks.length === 0) {
				this.searchedTasks = mainData.tasks;

				return;
			}

			if (this.searchedTasks.length === perPage * (page - 1)) {
				this.searchedTasks = this.searchedTasks.concat(mainData.tasks);

				return;
			}

			if (this.searchedTasks.length > perPage * (page - 1)) {
				if (page === 1) {
					this.searchedTasks = mainData.tasks;

					return;
				}

				const { data: stepData } =
					await serviceTasks.searchByName(name, perPage * page, 1, orderBy, filters);

				this.searchedTasks = stepData.tasks;

				return;
			}

			throw new Error('Impossível pesquisar! Provavelmente a aplicação se encontra na página final');
		},

		useNormalTasksList(): void {
			this.searchMaxTasks = 0;
			this.searchedTasks = [];
		}
	},
});