<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
	props: {
		content: {
			type: String,
		},
		sizingClass: {
			type: String,
			default: '!h-4 !w-4',
		},
	},
	data() {
		return {
			clicked: false,
		};
	},
	computed: {
		tippyProps() {
			return {
				content: `<div class="flex flex-col gap-2 text-[12px] leading-[18px] font-medium">${this.content}</div>`,
				theme: 'light-cj',
				arrow: false,
				offset: [ 0, 2 ],
				interactive: true,
				allowHTML: true,
				trigger: 'mouseenter click',
				onTrigger: (): void => this.setClicked(true),
				onHide: (): void => this.setClicked(false),
			};
		}
	},
	methods: {
		setClicked(clicked: boolean): void {
			this.clicked = clicked;
		}
	}
});
</script>

<template>
	<div
		class="component-info-tooltip ml-1 leading-[16px] flex items-center justify-center cursor-pointer"
		:class="`${sizingClass}${clicked
			? ' m-0 p-0 text-primary'
			: ' text-gray-400'
		}`"
		v-tippy="tippyProps"
	>
		<svg
			:class="`inline ${sizingClass}`"
			fill="currentColor"
			viewBox="0 0 20 20"
			xmlns="http://www.w3.org/2000/svg"
			aria-hidden="true"
		>
			<path clip-rule="evenodd"
				fill-rule="evenodd"
				d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z"
			/>
		</svg>
	</div>
</template>

<style lang="scss">

$primary: theme('colors.primary');

.tippy-box[data-theme~='light-cj'] > .tippy-content {
  border: 1px solid $primary;
  border-radius: 4px;
  background-color: white;
  color: rgb(51,51,51);
}
</style>