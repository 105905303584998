<script lang="ts">
import serviceCrm from '../../../../services/API/crm/index';

import Loading from '../../../../components/Loading/LoadingComponent.vue';
import {
	mapState, mapActions
} from 'pinia';
import { useFunnelsStore } from '../../../../stores/models/crm/funnels';
import { useAccountStore } from '../../../../stores/account';

import {
	defineComponent, inject
} from 'vue';
import {
	EventBus, Counts, Funnel
} from '../../../../models/crm';

export default defineComponent({
	components: {
		Loading,
	},
	data() {
		return {

			counts: null as Counts | null,

			requesting: {
				loadCounts: false,
				loadFunnels: false,
			},

			eventBus: inject<EventBus>('eventBus'),
			envVars: inject<Record<string, string>>('envVars'),

		};
	},
	computed: {
		...mapState(useAccountStore, [ 'user' ]),
		...mapState(useFunnelsStore, [ 'funnelsList', 'isRequestingFunnels' ]),

		officeId(): string | number | undefined {
			return this.user?.office_id;
		},

		reorganizedFunnelsList(): Funnel[] {
			const funnelsCopy = this.funnelsList?.slice() || [];
			funnelsCopy?.sort((a, b) =>
				a.is_main
					? -1
					: (b.is_main
						? 1
						: (b.active_cases_count - a.active_cases_count)
					)
			);

			return funnelsCopy;
		}

	},
	watch: {
		user: {
			deep: true,
			immediate: true,
			async handler(value, oldValue): Promise<void> {
				if (value?.office_id && value != oldValue) {
					await this.loadCounts();
					if (!this.isRequestingFunnels) await this.loadFunnels();
				}
			}
		},
	},
	methods: {
		...mapActions(useFunnelsStore, [ 'paginatedLoadFunnels' ]),

		async loadCounts(): Promise<void> {
			try {
				const {officeId} = this;
				if (!officeId) {
					this.eventBus?.emit(
						'Toast/add',
						{
							content: 'Erro ao carregar contadores',
							type: 'error'
						}
					);

					return;
				}

				this.requesting.loadCounts = true;

				const { data } = await serviceCrm.getDashboardCounts(officeId);

				this.counts = data;

				this.requesting.loadCounts = false;
			} catch (err) {
				console.error(err);

				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Erro ao carregar contadores',
						type: 'error'
					}
				);
			}
		},

		async loadFunnels(): Promise<void> {
			try {
				this.requesting.loadFunnels = true;

				await this.paginatedLoadFunnels({
					page: 1, perPage: 25, orderBy: 'active_cases_counts:desc', filters: ''
				});

				this.requesting.loadFunnels = false;
			} catch (err) {
				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Erro ao carregar fluxos de casos',
						type: 'error'
					}
				);
			}
		},

		// Events

		buttonSupportClick(): void {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			window.Intercom('boot', {
				app_id: this.envVars?.VITE_INTERCOM_ID,
			});

			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			window.Intercom('show');
		},

	},
});
</script>

<template>
	<section class="feature">

		<h1 class="mt-1">
			Gestão de Contatos, Casos e Tarefas
		</h1>

		<div class="mt-5 text-2xl">
			Bem-vindo à parte de Gestão do Cálculo Jurídico!
		</div>

		<div class="leading-6 mt-2 opacity-50 text-xl w-1/2">
			Este é o módulo que te ajuda com a gestão dos seus clientes e da sua rotina jurídica. Estamos adicionando novidades aqui para facilitar sua rotina.
		</div>

		<div class="grid grid-cols-4 gap-5 mt-8">

			<div class="bg-base-100 card rounded-md w-full">
				<div class="card-body p-4">
					<div class="font-semibold text-lg text-center">
						Contatos
					</div>
					<hr>
					<Loading
						v-if="!counts || requesting.loadCounts"
						class="py-10 text-center"
						icon-css-class="h-11 w-11"
						text-css-class="hidden"
					/>
					<div
						v-else-if="counts"
						class="font-light my-7 text-7xl text-center"
					>
						{{ counts.contacts_count }}
					</div>
					<router-link
						class="btn btn--primary btn--ghost !uppercase"
						:to="{ name: 'contacts' }"
					>
						Ir para Contatos
					</router-link>
				</div>
			</div>

			<div class="bg-base-100 card rounded-md w-full">
				<div class="card-body p-4">
					<div class="font-semibold text-lg text-center">
						Casos ativos
					</div>
					<hr>
					<Loading
						v-if="!counts || requesting.loadCounts"
						class="py-10 text-center"
						icon-css-class="h-11 w-11"
						text-css-class="hidden"
					/>
					<div
						v-else-if="counts"
						class="font-light my-7 text-7xl text-center"
					>
						{{ counts.active_legal_cases_count }}
					</div>
					<router-link
						class="btn btn--ghost btn--primary !uppercase"
						:to="{ name: 'legal-cases' }"
					>
						Ir para Casos
					</router-link>
				</div>
			</div>

			<div class="bg-base-100 card rounded-md w-full">
				<div class="card-body p-4">
					<div class="font-semibold text-lg text-center">
						Tarefas pendentes
					</div>
					<hr>
					<Loading
						v-if="!counts || requesting.loadCounts"
						class="py-10 text-center"
						icon-css-class="h-11 w-11"
						text-css-class="hidden"
					/>
					<div
						v-else-if="counts"
						class="font-light my-7 text-7xl text-center"
					>
						{{ counts.active_tasks_count }}
					</div>
					<router-link
						class="btn btn--ghost btn--primary !uppercase"
						:to="{name: 'tasks'}"
					>
						Ir para Tarefas
					</router-link>
				</div>
			</div>

			<div class="bg-base-100 card rounded-md w-full">
				<div class="card-body p-4">
					<div class="font-semibold text-lg text-center">
						Fluxos de Casos
					</div>
					<hr>
					<Loading
						v-if="!counts || requesting.loadCounts"
						class="py-10 text-center"
						icon-css-class="h-11 w-11"
						text-css-class="hidden"
					/>
					<div
						v-else-if="counts"
						class="font-light my-7 text-7xl text-center"
					>
						{{ counts.funnels_count }}
					</div>
					<router-link
						class="btn btn--ghost btn--primary !uppercase"
						:to="{name: 'funnels'}"
					>
						Ir para Fluxos de Casos
					</router-link>
				</div>
			</div>

		</div>

		<div class="mt-5">
			<h2 class="font-semibold mb-3 text-xl">
				Visualizar casos (modo fluxo de casos)
			</h2>

			<Loading
				v-if="requesting.loadFunnels"
				class="border border-gray-300 py-5 rounded text-center"
				icon-css-class="h-8 w-8 mr-2"
				text-css-class="opacity-50 text-xl"
			/>
			<div
				v-else
				class="container-funnel-links"
			>
				<router-link
					v-for="(funnel, index) in reorganizedFunnelsList"
					:key="`funnel-${index}`"
					:to="{name: 'specific-funnel', params: {id: funnel.id}}"
					class="bg-white block font-medium mb-2 p-2 rounded text-blue-500 hover:underline"
				>
					<span class="ml-2">{{ funnel.name }}</span>
				</router-link>
			</div>
		</div>

		<div class="mt-10 text-center">
			<button
				class="btn btn--ghost btn--primary button-talk-to-support"
				@click="buttonSupportClick"
			>
				<svg viewBox="0 0 28 32"><path d="M28,32 C28,32 23.2863266,30.1450667 19.4727818,28.6592 L3.43749107,28.6592 C1.53921989,28.6592 0,27.0272 0,25.0144 L0,3.6448 C0,1.632 1.53921989,0 3.43749107,0 L24.5615088,0 C26.45978,0 27.9989999,1.632 27.9989999,3.6448 L27.9989999,22.0490667 L28,22.0490667 L28,32 Z M23.8614088,20.0181333 C23.5309223,19.6105242 22.9540812,19.5633836 22.5692242,19.9125333 C22.5392199,19.9392 19.5537934,22.5941333 13.9989999,22.5941333 C8.51321617,22.5941333 5.48178311,19.9584 5.4277754,19.9104 C5.04295119,19.5629428 4.46760991,19.6105095 4.13759108,20.0170667 C3.97913051,20.2124916 3.9004494,20.4673395 3.91904357,20.7249415 C3.93763774,20.9825435 4.05196575,21.2215447 4.23660523,21.3888 C4.37862552,21.5168 7.77411059,24.5386667 13.9989999,24.5386667 C20.2248893,24.5386667 23.6203743,21.5168 23.7623946,21.3888 C23.9467342,21.2215726 24.0608642,20.9827905 24.0794539,20.7254507 C24.0980436,20.4681109 24.0195551,20.2135019 23.8614088,20.0181333 Z" /></svg>
				<span class="ml-3">Falar com o atendimento do CJ</span>
			</button>
		</div>

	</section>
</template>

<style lang="scss" scoped>
section.feature {
    background-image: url('/img/bg-crm.png');
    background-repeat: no-repeat;
    background-position: top right;
}

.button-talk-to-support {

    &:hover {
        svg {
            fill: #fff;
        }
    }

    svg {
        fill: #0B63E9;
        height: 21px;
    }
}
</style>