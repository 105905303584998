<script lang="ts">
import serviceUsers from '../../services/users';

import SelectField from '../Form/SelectField.vue';
import ModalBasic from '../Modal/ModalBasic.vue';
import {
	defineComponent, inject, PropType
} from 'vue';
import {
	EventBus, Lodash, DataTableColumn, SinglePreference, UserPreferences
} from '../../models/crm';

import {
	mapState, mapActions
} from 'pinia';
import { useAccountStore } from '../../stores/account';

export default defineComponent({
	props: {
		columns: {
			required: true,
			type: Array as PropType<DataTableColumn[]>,
		},
		dataTableId: {
			required: true,
			type: String,
		},
	},
	emits: [
		'saved-configuration',
	],
	components: {
		ModalBasic,
		SelectField,
	},
	data() {
		return {

			model: {
				columns: [] as string[],
				recordsPerPage: 25 as number,
			},

			eventBus: inject<EventBus>('eventBus'),

			lodash: inject<Lodash>('lodash'),

			recordsOption: [
				{
					value: 10, label: '10'
				}, {
					value: 25, label: '25'
				}, {
					value: 50, label: '50'
				}, {
					value: 100, label: '100'
				}
			] as {
				value: number;
				label: string;
			}[],

		};
	},
	watch: {

		user: {
			deep: true,
			handler: function(value): void {
				if (value) {
					this.setModel();
				}
			},
		}

	},
	computed: {
		...mapState(useAccountStore, [ 'user' ]),

		columnsOrdered(): DataTableColumn[] {
			const filteredColumns = this.columns.filter(column => column.label.length > 0);

			return this.lodash?.orderBy(filteredColumns, [ 'label' ], [ 'asc' ]) || filteredColumns;
		},

		currentDataTablePreferences(): SinglePreference | null {
			if (this.dataTableId) {
				const preferenceId = `ui:${this.dataTableId}`;
				const {user} = this;
				const userPreferences = user && user.preferences;
				return userPreferences && userPreferences[preferenceId] || null;
			} else {
				return null;
			}
		},

	},
	methods: {
		...mapActions(useAccountStore, [ 'setUserPreferences' ]),

		isColumnRequired(column: DataTableColumn): boolean {
			return !!column.required;
		},

		isColumnEnabled(column: DataTableColumn): boolean {
			return this.model.columns.includes(column.key);
		},

		hide(): void {
			(this.$refs.modal as typeof ModalBasic).hide();
		},

		setModel(): void {
			const currentPreferences = (this.currentDataTablePreferences as Record<string, string[]>);

			this.setColumnsOnModel(currentPreferences);
			this.setRecordsPerPageOnModel(currentPreferences);
		},

		setColumnsOnModel(currentPreferences: Record<string, string[]>): void {
			const {columns} = this;
			const hasColumnsSet = currentPreferences && currentPreferences.columns;

			if (hasColumnsSet) {
				if (!currentPreferences.columns) return;
				this.model.columns = currentPreferences.columns
					.concat(columns.filter(item => item.required).map(item => item.key));
			} else {
				this.model.columns = columns
					.filter(item => item.required || item.initial).map(item => item.key);
			}
		},

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		setRecordsPerPageOnModel(currentPreferences: Record<string, any>): void {
			if (!currentPreferences) return;

			if (currentPreferences.recordsPerPage) {
				const { recordsPerPage } = currentPreferences;

				this.model.recordsPerPage = recordsPerPage;
			}
		},

		async callSetUserPreferences(): Promise<void> {
			return new Promise(
				(resolve, reject) => {
					if (!this.user?.id) return reject();

					const userId = this.user.id;
					serviceUsers.getById(userId).then(({data}) => {
						const { user } = data;

						const preferences = user?.preferences || {};

						this.setUserPreferences(preferences);

						resolve();
					}).catch((err) => {
						console.error(err);

						reject();
					});
				}
			);
		},

		show(): void {
			(this.$refs.modal as typeof ModalBasic).show();
		},

		// Events
		buttonCancelClick(): void {
			this.hide();
		},

		async buttonSaveClick(): Promise<void> {
			if (!this.user?.id) return;

			const {dataTableId} = this;
			const userId = this.user.id;

			const data = {
				preferences: (this.user.preferences && {
					...this.user.preferences,
				} || {}) as UserPreferences,
			};

			data.preferences[`ui:${dataTableId}`] = this.model;

			this.eventBus?.emit('BlockingOverlay/show');


			try {
				await serviceUsers.updateUserPreferences({
					data, userId
				});

				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Configurações salvas com sucesso',
						type: 'success'
					}
				);

				(this.$refs.modal as typeof ModalBasic).hide();

				await this.callSetUserPreferences();

				this.$emit('saved-configuration');
			} catch (err) {
				console.error(err);
			} finally {
				this.eventBus?.emit('BlockingOverlay/hide');
			}
		},

		toggleChange(e: Event, column: DataTableColumn): void {
			const {checked} = (<HTMLInputElement>e.currentTarget);

			if (!checked) {
				this.model.columns = this.model.columns.filter(item => item !== column.key);
			} else {
				this.model.columns.push(column.key);
			}
		},

	},
	beforeMount() {
		this.setModel();
	},
});
</script>
<template>
	<ModalBasic ref="modal">
		<template #header />
		<template #content>
			<div class="p-4">

				<div>
					<SelectField v-model.number="model.recordsPerPage"
						class="w-full"
						label="Registros por página"
						:options="recordsOption"
					>
						<option :value="10">10</option>
						<option :value="25">25</option>
						<option :value="50">50</option>
						<option :value="100">100</option>
					</SelectField>
				</div>

				<hr class="my-3">

				<div>
					<div class="font-semibold opacity-75 text-xs">CAMPOS MOSTRADOS NA TABELA</div>
					<div class="opacity-75 text-xs">Dica: Use o mínimo de campos necessário para você trabalhar com eficiência.</div>
					<div class="border max-h-40 mt-3 overflow-auto rounded">
						<div
							v-for="(column, index) in columnsOrdered"
							:key="`column-${index}`"
							class="odd:bg-gray-100 flex items-center justify-between p-2"
						>
							<span :class="['text-sm', { 'font-bold': isColumnRequired(column) }]">
								{{ column.label }}
							</span>
							<input
								:class="['toggle', { '!toggle-success': isColumnEnabled(column) }]"
								:checked="isColumnEnabled(column)"
								:disabled="isColumnRequired(column)"
								type="checkbox"

								@change="toggleChange($event, column)"
							>
						</div>
					</div>
				</div>


			</div>
		</template>
		<template #footer>
			<div class="flex-1 flex overflow-hidden rounded-b-md">
				<button class="flex-1 bg-gray-200 font-medium p-4 text-gray-500 hover:bg-gray-300 active:bg-gray-200"
					@click="buttonCancelClick"
				>
					CANCELAR
				</button>
				<button class="flex-1 bg-primary font-medium p-4 text-white hover:bg-blue-600 active:bg-primary"
					@click="buttonSaveClick"
				>
					SALVAR
				</button>
			</div>
		</template>
	</ModalBasic>
</template>
<style lang="scss" scoped></style>
