<script lang="ts">
import DataTable from '../../../../components/DataTable/DataTable.vue';
import Loading from '../../../../components/Loading/LoadingComponent.vue';

import ModalBasic from '../../../../components/Modal/ModalBasic.vue';
import ModalContact from './ModalContact.vue';

import {
	mapActions, mapState
} from 'pinia';
import { useAccountStore } from '../../../../stores/account';
import { useContactsStore } from '../../../../stores/models/crm/contacts';

import {
	defineComponent, inject
} from 'vue';
import {
	EventBus, Luxon, Attachment, Contact, Filters, SingleComplexFilter, DataTableColumn, Calculation
} from '../../../../models/crm';

export default defineComponent({
	components: {
		DataTable,
		Loading,

		ModalContact,
		ModalBasic,
	},
	provide() {
		return {
			filters: this.filters as Filters,
		};
	},
	beforeRouteEnter(to, _from, next) {
		next(
			vm => {
				if ((to.name === 'specific-contact')||(to.name === 'create-contact')) {
					(vm.$refs.modalContact as typeof ModalContact).show();
				}
			}
		);
	},
	beforeRouteUpdate(to, _from, next) {
		if ((to.name === 'specific-contact')||(to.name === 'create-contact')) {
			(this.$refs.modalContact as typeof ModalContact).hide();
			setTimeout(
				() => {
					this.$router.push(to);
				},
				250
			);
		}
		next();
	},
	data() {
		return {

			envVars: inject<Record<string, string>>('envVars'),
			eventBus: inject<EventBus>('eventBus'),
			luxon: inject<Luxon>('luxon'),

			dataTableContacts: {
				columns: [
					{
						key: 'name', label: 'Nome', initial: true, required: true
					},
					{
						key: 'entity_type', label: 'Tipo', initial: true
					},
					{
						key: 'phone', label: 'Telefone', initial: true
					},
					{
						key: 'email', label: 'E-mail', initial: true
					},
					{
						key: 'birth_date', label: 'Data de nascimento'
					},
					{
						key: 'cnpj', label: 'CNPJ'
					},
					{
						key: 'corporate_name', label: 'Razão social'
					},
					{
						key: 'cpf', label: 'CPF'
					},
					{
						key: 'gender', label: 'Sexo'
					},
					{
						key: 'rg', label: 'RG'
					},
					{
						key: 'created_at', label: 'Criado em'
					},
					{
						key: 'updated_at', label: 'Última atualização'
					},
				] as DataTableColumn[],
				data: [] as Contact[],
				dataRaw: [] as Contact[],
				options: {
					columnCssClass: {
						'name': 'whitespace-nowrap text-[rgb(0,123,255)] td-link',
						'phone': 'whitespace-nowrap',
						'created_at': 'whitespace-nowrap',
						'updated_at': 'whitespace-nowrap',
					},
					initial: {
						orderBy: {
							column: 'name',
							sort: 'asc',
						},
					},
					orderableColumns: [
						'name',
						'created_at',
						'updated_at',
					],
					searchableColumns: [
						'name',
					],
				},
				total_count: 100
			},

			filters: {
				model: {
					entity_type: [] as string[],
					type: '',
				},
				applied: {
					entity_type: [] as string[],
					type: ''
				} as SingleComplexFilter,
				labels: {
					entity_type: 'Tipo',
					person: 'PF',
					company: 'PJ',

					type: 'Categoria',
					Client: 'Cliente',
					Lead: 'Lead'
				},
				query: ''
			} as Filters,

			order: {
				column: 'name',
				sort: 'asc'
			},

			requesting: {
				deleteContact: false,
				loadContacts: false,
				pagination: false,
				createClientFromLead: false,
			},

			searchStr: '' as string,

			contactIdToDelete: null as string | number | null,
			confirmDeleteContact: false as boolean,
		};
	},
	computed: {

		urlParamId(): string {
			return typeof this.$route.params.id == 'string' ? this.$route.params.id : '';
		},

		...mapState(useContactsStore, [
			'contactsList',
			'maxContacts',
			'searchMaxContacts',
			'searchedContacts',
		]),

		...mapState(useAccountStore, [ 'user' ]),

		perPage(): number {
			const preferenceId = 'ui:data_table_contacts';
			const userPreferences = this.user?.preferences;
			const specificPreference = userPreferences && userPreferences[preferenceId];

			return (specificPreference?.recordsPerPage as number | undefined) || 25;
		}
	},
	watch: {
		user: {
			deep: true,
			immediate: true,
			async handler(newState, oldState): Promise<void> {
				if (newState && newState !== oldState) {
					await this.loadContacts();
				}
			}
		},
		contactsList: {
			deep: true,
			async handler(newValue, oldValue): Promise<void> {
				if (newValue !== oldValue && (newValue.length > 0 || oldValue.length > 0)) {
					await this.loadContacts();
				}
			}
		},
	},
	methods: {
		...mapActions(useContactsStore, [
			'deleteContact',
			'paginatedLoadContacts',
			'searchContactsByName',
			'useNormalContactsList',
			'createClientFromLead',
			'getContactAttachments',
			'getContactCalculations'
		]),

		async callDeleteContact(id: string | number): Promise<void> {
			this.eventBus?.emit('BlockingOverlay/show', {
				text: 'Aguarde, excluindo contato'
			});

			try {
				this.requesting.deleteContact = true;

				await this.deleteContact(id);

				await this.reloadContacts('Erro ao excluir contato');

				(this.$refs.dataTableContacts as typeof DataTable).goToPage(1);

				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Contato excluído com sucesso',
						type: 'success'
					}
				);

				this.requesting.deleteContact = false;
			} catch (err) {
				console.error(err);

				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Erro ao excluir contato',
						type: 'error'
					}
				);
			} finally {
				this.eventBus?.emit('BlockingOverlay/hide');
			}
		},

		async filtersApply(): Promise<void> {
			const filtersApplied = this.filters.applied = {...this.filters.model};
			let filter = '';
			for (const key of Object.keys(filtersApplied)) {
				if (filtersApplied[key]?.length) {
					filter += `&${key}=${filtersApplied[key]}`; }
			}

			this.filters.query = filter;

			await this.reloadContacts('Erro ao aplicar o filtro');
		},

		getFormattedPhone(phone: string): string {
			if (!phone) return '';

			const areaCode = phone.substring(0, 2);
			const number = phone.substring(2);

			const numberFormatted = number.length === 8 ? `${number.slice(0, 4)}-${number.slice(4, 8)}` : `${number.slice(0, 5)}-${number.slice(5, 9)}`;

			return `(${areaCode}) ${numberFormatted}`;
		},

		getGenderText(gender: string): string {
			let ret = '';
			switch (gender) {
			case 'male':
				ret = 'Masculino';
				break;
			case 'female':
				ret = 'Feminino';
				break;
			}
			return ret;
		},

		getMainEmail(row: Contact): string {
			if (row?.emails && row.emails.length > 0) {
				const emailObj = row.emails.find(item => item.is_main === true) || row.emails[0];

				return emailObj?.email || '';
			}

			// Legacy field
			if (row.email) return row.email;

			return '';
		},

		getMainPhone(row: Contact): string {
			if (row?.phones && row.phones?.length > 0) {
				const phoneObj = row.phones.find(item => item.is_main === true) || row.phones[0];

				if (!phoneObj?.area_code || !phoneObj.number) return '';

				return `${phoneObj.area_code}${phoneObj.number}`;
			}

			// Legacy fields
			if (row.phone) return row.phone;
			if (row.phone2) return row.phone2;

			return '';
		},

		getWhatsappUrl(phone: string): string {
			return `https://wa.me/55${phone}`;
		},

		async loadContacts(forceReload = false): Promise<void> {
			try {
				this.requesting.loadContacts = true;

				if (this.contactsList.length > 0 && !forceReload) {
					this.dataTableContacts.data = this.dataTableContacts.dataRaw = this.contactsList;
					this.dataTableContacts.total_count = this.maxContacts;

					this.requesting.loadContacts = false;

					return;
				}

				const orderBy = `${this.order.column}:${this.order.sort}`;

				await this.paginatedLoadContacts({
					perPage: this.perPage, page: 1, filters: this.filters.query, orderBy
				});

				this.dataTableContacts.data = this.dataTableContacts.dataRaw = this.contactsList;
				this.dataTableContacts.total_count = this.maxContacts;
			} catch (err) {
				console.error(err);

				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Erro ao carregar contatos',
						type: 'error'
					}
				);
			} finally {
				this.requesting.loadContacts = false;
			}
		},

		async orderApply(column: string, sort: string): Promise<void> {
			this.order = {
				column, sort
			};

			await this.reloadContacts('Erro ao fazer a ordenação');
		},

		async reloadContacts(errorMessage: string): Promise<void> {
			const orderBy = `${this.order.column}:${this.order.sort}`;

			this.requesting.pagination = true;

			try {
				if (this.searchStr.length > 0) {
					await this.searchContactsByName({
						perPage: this.perPage,
						page: 1,
						name: this.searchStr,
						filters: this.filters.query,
						orderBy
					});

					this.dataTableContacts.data = this.dataTableContacts.dataRaw = [];

					this.dataTableContacts.total_count = 0;

					if (
						this.searchMaxContacts > this.dataTableContacts.dataRaw.length
					) {
						this.dataTableContacts.data
								= this.dataTableContacts.dataRaw
								= this.searchedContacts;

						this.dataTableContacts.total_count = this.searchMaxContacts;

						return;
					}
				} else {
					this.useNormalContactsList();

					await this.paginatedLoadContacts({
						perPage: this.perPage,
						page: 1,
						orderBy,
						filters: this.filters.query
					});

					this.dataTableContacts.data
						= this.dataTableContacts.dataRaw
						= this.contactsList;

					this.dataTableContacts.total_count = this.maxContacts;
				}
			} catch (err) {
				console.error(err);

				this.eventBus?.emit(
					'Toast/add',
					{
						content: errorMessage,
						type: 'error'
					}
				);
			} finally {
				this.requesting.pagination = false;
			}
		},

		resetIdToDelete(): void {
			this.contactIdToDelete = null;
			(this.$refs.modalDeleteClient as typeof ModalBasic)?.hide();
		},

		// Events

		buttonCreateClick(): void {
			this.$router.push({
				name: 'create-contact'
			});
		},

		async clickDeleteWithAttachment(id: string | number): Promise<void> {
			(this.$refs.modalDeleteClient as typeof ModalBasic).hide();

			await this.callDeleteContact(id);
		},

		async dataTableContacts_deleteClick(_e: Event, id: string | number): Promise<void> {
			let hasError = false;
			let attachments: Attachment[] | undefined;
			let calculations: Calculation[] | undefined;
			const maybeContact = (this.dataTableContacts.data as Contact[])
				.find(contact => contact.id == id);
			if (!maybeContact) {
				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Erro ao identificar contato',
						type: 'error'
					}
				);

				return;
			}

			try {
				this.eventBus?.emit('BlockingOverlay/show', {
					text: 'Aguarde, checando se o contato possui anexos ou cálculos'
				});

				if (!maybeContact.attachments || maybeContact.attachments.length === 0) {
					attachments = await this.getContactAttachments(id);
				}

				if (!maybeContact.calculations || maybeContact.calculations.length === 0) {
					calculations = await this.getContactCalculations(id);
				}

				this.eventBus?.emit('BlockingOverlay/hide');
			} catch (err) {
				hasError = true;

				console.error(err);

				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Erro ao pesquisar anexos do contato',
						type: 'error'
					}
				);

				await this.callDeleteContact(id);
			}

			if (!hasError) {
				if (
					(attachments && attachments.length > 0)
						|| (calculations && calculations.length > 0)
				) {
					this.contactIdToDelete = id;
					(this.$refs.modalDeleteClient as typeof ModalBasic).show();

					return;
				}

				await this.callDeleteContact(id);
			}
		},

		dataTableContacts_filtersApply(): void {
			this.filtersApply();
		},

		dataTableContacts_filtersClear(): void {
			Object.entries(this.filters.model).forEach(
				([ key, _value ]) => {
					this.filters.model[key as 'entity_type'] = [];
				}
			);

			this.filtersApply();
		},

		dataTableContacts_filtersItemRemove(filterItem: [ key: 'entity_type', value: string[]]): void {
			const [ key, _value ] = filterItem;

			this.filters.model[key] = [];

			this.filtersApply();
		},

		async callCreateClientFromLead(): Promise<void> {
			if (!this.user?.office_id) return;

			const clientLimitErrorMessage = 'Limite de cota atingido e não foi possível atualizar seu plano. Entre em contato com nosso suporte';

			this.requesting.createClientFromLead = true;

			this.eventBus?.emit('BlockingOverlay/show', {
				text: 'Aguarde, transformando lead em cliente',
			});

			try {
				if (!this.urlParamId) {
					throw new Error('Não foi possível identificar o contato');
				}

				await this.createClientFromLead(this.user.office_id, this.urlParamId);

				this.eventBus?.emit('BlockingOverlay/hide');

				this.requesting.createClientFromLead = false;
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (err: any) {
				console.error(err);

				let stringifiedErrors: string | undefined = undefined;

				if (err?.response?.data?.errors) {
					stringifiedErrors = '';

					for (const errorKey in err.response.data.errors) {
						stringifiedErrors = `${stringifiedErrors} ${errorKey}: ${err.response.data.errors[errorKey]},`;
					}
				}

				if (err.message === clientLimitErrorMessage) {
					stringifiedErrors = err.message;
				}

				this.eventBus?.emit(
					'Toast/add',
					{
						content: stringifiedErrors || 'Erro ao transformar lead em cliente. Entre em contato com o suporte',
						type: 'error'
					}
				);

				this.eventBus?.emit('BlockingOverlay/hide');

				this.requesting.createClientFromLead = false;

				this.toggleConfirmChangeToClientModal(false);

				return;
			}
			const contact = this.dataTableContacts.dataRaw
				.find((contact) => `${contact.id}` === this.urlParamId);

			// redirects to app's new calculation menu
			if (contact?.slug) window.open(this.getCreateCalculationUrl(contact.slug), '_blank');

			this.toggleConfirmChangeToClientModal(false);

			(this.$refs.modalContact as typeof ModalContact).hide();
		},

		getCreateCalculationUrl(slug: string): string {
			const baseUrl = this.envVars?.VITE_CJ_WEB_APP_URL;

			return `${baseUrl}/novo-calculo/${slug}/todos`;
		},

		toggleConfirmChangeToClientModal(shouldOpen: boolean): void {
			if (shouldOpen) {
				(this.$refs.modalChangeToClient as typeof ModalBasic).show();
				return;
			}

			(this.$refs.modalChangeToClient as typeof ModalBasic).hide();
		},

		toggleExceededClientsLimitModal(shouldOpen: boolean): void {
			if (shouldOpen) {
				(this.$refs.modalExceededClientsLimit as typeof ModalBasic).show();
				(this.$refs.modalContact as typeof ModalBasic).hide();
				return;
			}

			(this.$refs.modalExceededClientsLimit as typeof ModalBasic).hide();
		},

		dataTableContacts_savedConfiguration(): void {
			this.loadContacts(true);
		},

		dataTableContacts_tdClick(row: Contact): void {
			this.$router.push({
				name: 'specific-contact', params: { id: row.id }
			});
		},

		dataTableContacts_orderApply(column: string, sort: string): void {
			this.orderApply(column, sort);
		},

		async modalContact_contactCreated(id?: number): Promise<void> {
			await this.loadContacts(true);
			if (id) {
				this.$router.push({
					name: 'specific-contact', params: { id }
				});
			}
		},

		modalContact_contactUpdated(): void {
			this.loadContacts(true);
		},

		async addContactsToData(nextPage: number): Promise<void> {
			if (
				this.user?.office_id
				&& this.maxContacts > this.perPage * (nextPage - 1)
				&& this.dataTableContacts.dataRaw.length === this.perPage * (nextPage - 1)
			) {
				this.requesting.pagination = true;

				const orderBy = `${this.order.column}:${this.order.sort}`;

				if (this.searchStr.length > 0) {
					try {
						await this.searchContactsByName({
							name: this.searchStr,
							perPage: this.perPage,
							page: nextPage,
							filters: this.filters.query,
							orderBy
						});

						if (nextPage === 1) {
							this.dataTableContacts.data = this.dataTableContacts.dataRaw = [];
						}

						if (
							this.searchMaxContacts > this.dataTableContacts.dataRaw.length
						) {
							this.dataTableContacts.data
								= this.dataTableContacts.dataRaw
								= this.searchedContacts;

							return;
						}

						this.eventBus?.emit(
							'Toast/add',
							{
								content: 'Página máxima atingida',
								type: 'error'
							}
						);
					} catch (err) {
						console.error(err);

						this.eventBus?.emit(
							'Toast/add',
							{
								content: 'Erro ao fazer a paginação',
								type: 'error'
							}
						);
					} finally {
						this.requesting.pagination = false;
					}

					return;
				}

				try {
					await this.paginatedLoadContacts({
						perPage: this.perPage,
						page: nextPage,
						filters: this.filters.query,
						orderBy
					});

					this.useNormalContactsList();
				} catch (err) {
					console.error(err);

					this.eventBus?.emit(
						'Toast/add',
						{
							content: 'Erro ao fazer a paginação',
							type: 'error'
						}
					);
				} finally {
					this.requesting.pagination = false;
				}
			}
		},
		async sequentialContactsAddition(page: number): Promise<void> {
			const oldPage = Math.ceil(this.dataTableContacts.dataRaw.length / this.perPage);
			if (page <= oldPage || this.dataTableContacts.dataRaw.length % this.perPage !== 0) {
				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Erro ao fazer a paginação',
						type: 'error'
					}
				);

				return;
			}

			for (let i = oldPage + 1;i <= page;++i) {
				await this.addContactsToData(i);
			}
		},
		async searchContacts(searchStr: string): Promise<void> {
			if (!searchStr) {
				await this.loadContacts();

				this.useNormalContactsList();

				this.searchStr = searchStr;

				return;
			}

			this.searchStr = searchStr;

			if (this.user?.office_id) {
				try {
					const orderBy = `${this.order.column}:${this.order.sort}`;
					await this.searchContactsByName({
						name: searchStr,
						perPage: 25,
						page: 1,
						filters: this.filters.query,
						orderBy
					});

					if (this.searchStr === searchStr) {
						this.dataTableContacts.data = this.dataTableContacts.dataRaw = this.searchedContacts;
						this.dataTableContacts.total_count = this.searchMaxContacts;
					}
				} catch (err) {
					console.error(err);
					this.eventBus?.emit(
						'Toast/add',
						{
							content: 'Erro ao pesquisar contato',
							type: 'error'
						}
					);
				}
			}
		},
	},
});
</script>

<template>
	<section class="feature">
		<div class="pb-4">
			<router-link
				:to="{ name: 'crm-panel' }"
				class="text-gray-500 font-semibold text-xs hover:underline mb-2"
			>
				Gestão
			</router-link>
			<svg
				class="inline h-4 -rotate-90 text-gray-400"
				fill="currentColor"
				viewBox="0 0 20 20"
				xmlns="http://www.w3.org/2000/svg"
			><path
				fill-rule="evenodd"
				d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
				clip-rule="evenodd"
			/></svg>
			<span class="text-blue-500 font-semibold text-xs">Contatos</span>
		</div>
		<div class="flex items-center gap-4">
			<h1>Contatos</h1>
			<button
				v-if="!requesting.loadContacts"
				class="btn btn--primary h-auto min-h-0 normal-case !px-2 !py-1 rounded text-white"
				@click="buttonCreateClick"
			>
				<svg
					class="w-6 h-6"
					fill="none"
					stroke="currentColor"
					viewBox="0 0 24 24"
					xmlns="http://www.w3.org/2000/svg"
				><path
					stroke-linecap="round"
					stroke-linejoin="round"
					stroke-width="2"
					d="M12 6v6m0 0v6m0-6h6m-6 0H6"
				/></svg>
				<span>Novo contato</span>
			</button>
		</div>

		<Loading
			v-if="requesting.loadContacts"
			class="opacity-75 py-14 text-center"
			icon-css-class="h-10 w-10 mr-3"
			text-css-class="text-2xl"
		/>
		<template v-else>

			<DataTable
				id="data_table_contacts"
				ref="dataTableContacts"
				:actions-column-show="true"
				class="mt-4"
				:columns="dataTableContacts.columns"
				:data="dataTableContacts.data"
				:data-raw="dataTableContacts.dataRaw"
				:filters="filters"
				:options="dataTableContacts.options"
				:max-data-length="dataTableContacts.total_count"
				:loading-data="requesting.pagination || requesting.loadContacts"
				:search-requisition="searchContacts"


				@filters-apply="dataTableContacts_filtersApply"
				@filters-clear="dataTableContacts_filtersClear"
				@filters-item-remove="dataTableContacts_filtersItemRemove"
				@search-changed="searchContacts"
				@saved-configuration="dataTableContacts_savedConfiguration"
				@td-click="dataTableContacts_tdClick"
				@next-page="addContactsToData"
				@go-to-page="sequentialContactsAddition"
				@order-apply="dataTableContacts_orderApply"
			>
				<template #filters>
					<div class="filter-item">
						<label
							for="filter_selectType"
							class="font-bold mb-4"
						>Tipo</label>
						<div class="border border-blue-500 p-3 rounded">
							<div class="form-control">
								<label>
									<input
										v-model="filters.model.entity_type"
										type="radio"
										class="radio radio-sm radio-primary align-middle"
										value="person"
									>
									<span class="align-middle label-text ml-2">Pessoa Física (PF)</span>
								</label>
							</div>
							<div class="form-control">
								<label>
									<input
										v-model="filters.model.entity_type"
										type="radio"
										class="radio radio-sm radio-primary align-middle"
										value="company"
									>
									<span class="align-middle label-text ml-2">Pessoa Jurídica (PJ)</span>
								</label>
							</div>
						</div>
					</div>

					<div class="mt-5 filter-item">
						<label
							for="filter_selectTasks"
							class="font-bold mb-4"
						>Categoria</label>
						<div class="border border-blue-500 p-3 rounded">
							<div class="form-control">
								<label>
									<input
										v-model="filters.model.type"
										type="radio"
										class="radio radio-sm radio-primary align-middle"
										name="radioFilterDueDate"
										value="Client"
									>
									<span class="align-middle label-text ml-2">Cliente</span>
								</label>
							</div>

							<div class="form-control">
								<label>
									<input
										v-model="filters.model.type"
										type="radio"
										class="radio radio-sm radio-primary align-middle"
										name="radioFilterDueDate"
										value="Lead"
									>
									<span class="align-middle label-text ml-2">Lead</span>
								</label>
							</div>
						</div>
					</div>

				</template>
				<template #actions-list="{ row }">
					<button
						class="flex items-center mb-1 p-1 pr-2 rounded w-full whitespace-nowrap hover:bg-[#E5F0FB] last:mb-0"
						@click="dataTableContacts_deleteClick($event, row.id)"
					>
						<svg
							class="inline mr-1 w-4 h-4"
							fill="currentColor"
							viewBox="0 0 20 20"
							xmlns="http://www.w3.org/2000/svg"
						><path
							fill-rule="evenodd"
							d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
							clip-rule="evenodd"
						/></svg>
						<span class="text-sm">Excluir</span>
					</button>
				</template>

				<template #col_entity_type="{ row }">
					<span
						v-if="row.entity_type === 'person'"
						title="Pessoa Física"
					><svg
						class="inline w-5 h-5"
						fill="currentColor"
						viewBox="0 0 20 20"
						xmlns="http://www.w3.org/2000/svg"
					><path
						fill-rule="evenodd"
						d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
						clip-rule="evenodd"
					/></svg></span>
					<span
						v-if="row.entity_type === 'company'"
						title="Pessoa Jurídica"
					><svg
						id="co_x5F_work"
						class="inline w-5 h-5"
						style="enable-background:new 0 0 40 40;"
						version="1.1"
						viewBox="0 0 40 40"
						width="40px"
						xml:space="preserve"
						xmlns="http://www.w3.org/2000/svg"
						xmlns:xlink="http://www.w3.org/1999/xlink"
					><path
						d="M10.975,17.991l6.54-5.721c0.266-0.233,0.621-0.285,0.943-0.14  c0.322,0.147,0.516,0.449,0.516,0.802v5.059l6.539-5.721c0.266-0.233,0.621-0.285,0.943-0.14c0.322,0.147,0.516,0.449,0.516,0.802  v5.059l6.539-5.721c0.266-0.233,0.621-0.285,0.943-0.14c0.322,0.146,0.516,0.449,0.516,0.802v20.855c0,1.211-0.986,2.2-2.199,2.2  H6.177c-1.211,0-2.199-0.989-2.199-2.2V6.194c0-1.212,0.987-2.2,2.199-2.2h2.599c1.212,0,2.199,0.988,2.199,2.2V17.991z   M26.971,23.991v5.998h4v-5.998H26.971L26.971,23.991z M10.975,23.991v5.998h3.999v-5.998H10.975L10.975,23.991z M18.973,23.991  v5.998h4v-5.998H18.973z"
						style="fill-rule:evenodd;clip-rule:evenodd;"
					/></svg></span>
				</template>
				<template #col_email="{ row }">
					<span v-if="getMainEmail(row)">
						{{ getMainEmail(row) }}
					</span>
				</template>
				<template #col_phone="{ row }">
					<span v-if="getMainPhone(row)">
						<span>{{ getFormattedPhone(getMainPhone(row)) }}</span>
						<a
							class="ml-1"
							:href="getWhatsappUrl(getMainPhone(row))"
							target="_blank"
							@click.stop=""
						>
							<svg
								class="inline fill-green-500 h-5 w-5"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 448 512"
							>
								<path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" />
							</svg>
						</a>
					</span>
				</template>
				<template #col_birth_date="{ row }">
					{{ luxon?.fromSQL(row.birth_date).toFormat('dd/MM/yyyy') }}
				</template>
				<template #col_gender="{ row }">
					{{ getGenderText(row.gender) }}
				</template>
				<template #col_created_at="{ row }">
					{{ luxon?.fromISO(row.created_at).toFormat('dd/MM/yyyy HH:mm') }}
				</template>
				<template #col_updated_at="{ row }">
					{{ luxon?.fromISO(row.updated_at).toFormat('dd/MM/yyyy HH:mm') }}
				</template>
			</DataTable>

		</template>

		<ModalContact
			ref="modalContact"
			:contact-id="urlParamId"
			:contacts-list="dataTableContacts.dataRaw"
			@contact-created="modalContact_contactCreated"
			@contact-updated="modalContact_contactUpdated"
			@open-change-to-client-modal="toggleConfirmChangeToClientModal(true)"
			@open-exceed-clients-limit-modal="toggleExceededClientsLimitModal(true)"
		/>

		<ModalBasic
			ref="modalChangeToClient"
			:dismissable="!requesting.createClientFromLead"
		>
			<template #content>
				<div class="p-4 flex flex-col">
					<p class="font-bold text-lg mb-2">
						Atenção!
					</p>
					<p class="mb-1">
						Adicionar um cálculo irá transformar esse lead em um cliente.
					</p>
					<p class="mb-1">
						Assim, esse novo cliente contará para a cota do seu plano contratado.
					</p>
					<p class="text-red-600">
						Caso suas cotas estejam no limite do seu plano, o seu plano será automaticamente atualizado para um nível acima.
					</p>
				</div>
			</template>
			<template #footer>
				<div class="flex-1 flex overflow-hidden rounded-b-md">
					<button
						class="flex-1 bg-gray-200 font-medium p-4 text-gray-600 hover:bg-gray-300 active:bg-gray-200"
						@click="toggleConfirmChangeToClientModal(false)"
					>
						CANCELAR
					</button>
					<button
						class="flex-1 bg-blue-500 font-medium p-4 text-white hover:bg-blue-600 active:bg-blue-500"
						@click="callCreateClientFromLead"
					>
						CONCORDO E DESEJO CONTINUAR
					</button>
				</div>
			</template>
		</ModalBasic>

		<ModalBasic
			ref="modalExceededClientsLimit"
		>
			<template #content>
				<div class="p-10 flex flex-col text-gray-700 font-semibold">
					<p class="font-bold text-lg mb-4 text-gray-600">
						Você excedeu o número de contatos para o seu plano
					</p>
					<p class="mb-4">
						Mas não se preocupe! Você pode <span class="text-blue-500 font-bold">criar mais contatos fazendo um upgrade.</span>
					</p>
					<p
						v-if="user.billing_admin"
						class="mb-4"
					>
						Basta ir até a página de planos e <span class="text-blue-500 font-bold">escolher o plano que melhor se encaixa</span> para você.
					</p>
					<p v-else>
						<span class="text-blue-500 font-bold">Entre em contato com o administrador</span> para que ele possa fazer o upgrade.
					</p>
				</div>
			</template>
			<template #footer>
				<div class="flex-1 flex overflow-hidden rounded-b-md">
					<button
						class="flex-1 bg-gray-200 font-medium p-4 text-gray-600 hover:bg-gray-300 active:bg-gray-200"
						@click="toggleExceededClientsLimitModal(false)"
					>
						CANCELAR
					</button>
					<a
						v-if="user.billing_admin"
						class="flex-1 bg-blue-500 font-medium p-4 text-white hover:bg-blue-600 active:bg-blue-500"
						:href="`${envVars?.VITE_CJ_WEB_APP_URL}assinatura/plano`"
					>
						IR À PÁGINA DE PLANOS
					</a>

				</div>
			</template>
		</ModalBasic>

		<ModalBasic
			ref="modalDeleteClient"
			:dismissable="!requesting.deleteContact"
			size="lg"

			@vue:before-unmount="resetIdToDelete()"
		>
			<template #content>
				<div class="p-10 flex flex-col items-center">
					<h1 class="mb-1 font-semibold text-md text-center">
						VOCÊ REALMENTE DESEJA EXCLUIR
						<br>
						O CONTATO
						<span class="italic">{{ contactsList.find(contact => contact.id == contactIdToDelete)?.name }}</span>
					</h1>
					<div class="mb-4 mt-6 text-left text-black w-full">
						<p class="text-left font-semibold mb-3">
							Atenção!
						</p>
						<p class="mb-3">
							Essa é uma ação <span class="font-semibold">irreversível</span>.
						</p>
						<p class="mb-3">
							Ao excluir um contato, todas as informações cadastrais, arquivos anexos, anotações e cálculos atrelados a ele <span class="font-semibold">serão excluídos de forma definitiva</span>.
						</p>
						<p class="mb-3">
							Além disso, não será possível visualizar e receber os e-mails vinculados ao CJ-Mail do contato, bem como não será possível acessar contas nas quais o e-mail do CJ-Mail estiver cadastrado.
						</p>
						<p>Deseja continuar?</p>
					</div>
					<label class="inline-flex">
						<input
							v-model="confirmDeleteContact"
							type="checkbox"
							class="checkbox-sm !rounded"
						>
						<span class="label-text ml-2 font-semibold">Estou ciente, quero excluir o contato, seus dados, documentos e seus cálculos.<span class="text-red-500">*</span></span>
					</label>
				</div>
			</template>
			<template #footer>
				<div class="flex-1 flex overflow-hidden rounded-b-md">
					<button
						class="flex-1 bg-gray-200 text-base p-4 text-gray-600 hover:bg-gray-300 active:bg-gray-200"
						@click="resetIdToDelete()"
					>
						CANCELAR
					</button>
					<button
						:class="['flex-1 bg-red-500 text-base p-4 text-white hover:bg-red-600 active:bg-red-500', !confirmDeleteContact && 'opacity-50 cursor-not-allowed']"
						:disabled="!confirmDeleteContact"
						@click="contactIdToDelete && clickDeleteWithAttachment(contactIdToDelete)"
					>
						EXCLUIR
					</button>
				</div>
			</template>
		</ModalBasic>
	</section>
</template>

<style lang="scss" scoped></style>
