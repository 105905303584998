// Layouts
import LayoutAuthenticatedArea from '../../layouts/LayoutAuthenticatedArea.vue';

// Features routes
import CRM from './crmRoutes';
import Finance from './financeRoutes';
import PdfSplitter from './pdfSplitterRoutes';

/**
 * Keep path empty so it works as a wrapper only and allows to
 * use nested routes for features
 */
export default {
	path: '',
	redirect: '/gestao/painel',
	component: LayoutAuthenticatedArea,
	meta: {
		requiresAuth: true,
	},
	children: [
		CRM,
		Finance,
		PdfSplitter,
	],
};