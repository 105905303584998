<script lang="ts">
import ModalBasic from '../../components/Modal/ModalBasic.vue';
import { defineComponent } from 'vue';

export default defineComponent({
	props: {
		size: {
			default: 'md',
			type: String,
		},
		title: {
			required: true,
			type: String,
		},
	},
	emits: [
		'no-click',
		'yes-click',
	],
	components: {
		ModalBasic,
	},
	data() {
		return {
			inData: {},
		};
	},
	computed: {},
	methods: {

		hide(): void {
			(this.$refs.modal as typeof ModalBasic).hide();
		},

		resetInData(): void {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			this.inData = {} as Record<string, any>;
		},

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		show(inData: Record<string, any>): void {
			this.inData = inData;
			(this.$refs.modal as typeof ModalBasic).show();
		},

		// Events

		buttonNoClick(): void {
			this.$emit('no-click', this.inData);
			this.resetInData();
		},

		buttonYesClick(): void {
			this.$emit('yes-click', this.inData);
			this.resetInData();
		},

	},
});
</script>
<template>
	<ModalBasic
		ref="modal"
		:size="size"
		:title="title"
	>
		<template #content>
			<slot name="content" />
		</template>
		<template #footer>
			<div class="p-4 text-right">
				<button class="bg-green-600 px-6 py-2 rounded text-white"
					@click="buttonYesClick"
				>
					Sim
				</button>
				<button class="bg-red-500 ml-2 px-6 py-2 rounded text-white"
					@click="buttonNoClick"
				>
					Não
				</button>
			</div>
		</template>
	</ModalBasic>
</template>
<style lang="scss" scoped></style>