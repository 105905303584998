import { DateTime } from 'luxon';
import {
	DashboardAmounts, DashboardMonthsSummary
} from './../../../models/financial';
import { defineStore } from 'pinia';
import serviceDashboard from '../../../services/API/financial/dashboard';

export type DashboardState = {
	amounts: {
        paid: Record<number, DashboardAmounts>;
        unpaid: Record<number, DashboardAmounts>;
        expired: Record<number, DashboardAmounts>;
    };

    months_summary: Record<number, DashboardMonthsSummary>;
}

const getYearMonth = (currentDate: DateTime | Date): number => {
	// check if currentDate is DateTime or Date
	if (currentDate instanceof Date) {
		return Number(DateTime.fromJSDate(currentDate).toFormat('yyyyMM'));
	}

	return Number(currentDate.toFormat('yyyyMM'));
};

export const useDashboardStore = defineStore('dashboard', {
	state: (): DashboardState => ({
		amounts: {
			paid: {},
			unpaid: {},
			expired: {},
		},
		months_summary: {},
	}),
	actions: {
		async fetchAmounts(currentDate: DateTime | Date, paid?: boolean): Promise<boolean> {
			const yearmonth = getYearMonth(currentDate);

			if (isNaN(yearmonth)) {
				return false;
			}

			const response = await serviceDashboard.getDashboardAmounts(yearmonth, paid);

			if (response.status !== 200) {
				return false;
			}

			if (paid) {
				this.amounts.paid[yearmonth] = response.data;

				return true;
			}

			if (paid === false) {
				this.amounts.unpaid[yearmonth] = response.data;

				return true;
			}

			this.amounts.expired[yearmonth] = response.data;

			return true;
		},
		getAmounts(currentDate: DateTime | Date, paid?: boolean): DashboardAmounts | undefined {
			const yearmonth = getYearMonth(currentDate);

			if (isNaN(yearmonth)) {
				return undefined;
			}

			if (paid) {
				return this.amounts.paid[yearmonth];
			}

			if (paid === false) {
				return this.amounts.unpaid[yearmonth];
			}

			return this.amounts.expired[yearmonth];
		},
		async fetchMonthsSummary(currentDate: DateTime | Date): Promise<boolean> {
			const yearmonth = getYearMonth(currentDate);

			if (isNaN(yearmonth)) {
				return false;
			}

			const response = await serviceDashboard.getDashboardMonthsSummary(yearmonth);

			if (response.status !== 200) {
				return false;
			}

			this.months_summary[yearmonth] = response.data;

			return true;
		},
		getMonthsSummary(currentDate: DateTime | Date): DashboardMonthsSummary | undefined {
			const yearmonth = getYearMonth(currentDate);

			if (isNaN(yearmonth)) {
				return undefined;
			}

			return this.months_summary[yearmonth];
		}
	},
});