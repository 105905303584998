import {
	DashboardSumParams, Summary, CategoriesSumResponse,
	CostCentersSumResponse, DashboardAmounts, DashboardMonthsSummary
} from './../../../models/financial';
import axios, { AxiosResponse } from 'axios';

export default {

	getDashboardCounts(): Promise<AxiosResponse<Summary>> {
		const path = '/finances/dashboard/summary.json?target_date=due_date';
		return axios.get(path);
	},

	getDashboardAmounts(yearmonth: number, paid?: boolean): Promise<AxiosResponse<DashboardAmounts>> {
		const path = `/finances/dashboard/amounts?due_yearmonth${yearmonth}${paid === undefined ? '&overdue=true' : `&${paid? 'paid=true' : 'unpaid=true'}`}`;
		return axios.get(path);
	},

	getDashboardMonthsSummary(yearmonth?: number): Promise<AxiosResponse<DashboardMonthsSummary>> {
		const yearmonthQuery = yearmonth ? `?yearmonth=${yearmonth}` : '';

		const path = `/finances/dashboard/month_summary${yearmonthQuery}`;
		return axios.get(path);
	},

	getDashboardCategoriesSum(params: DashboardSumParams)
		: Promise<AxiosResponse<CategoriesSumResponse>> {
		let path = '/finances/dashboard/categories.json?';
		path += 'target_date=due_date';

		if (params.transactionType) {
			path += `&transaction_type=${params.transactionType}`;
		}

		if (params.yearmonth) {
			path += `&yearmonth=${params.yearmonth}`;
		}

		return axios.get(path);
	},

	getDashboardCostCentersSum(params: DashboardSumParams)
		: Promise<AxiosResponse<CostCentersSumResponse>> {
		let path = '/finances/dashboard/cost_centers.json?';
		path += 'target_date=due_date';

		if (params.transactionType) {
			path += `&transaction_type=${params.transactionType}`;
		}

		if (params.yearmonth) {
			path += `&yearmonth=${params.yearmonth}`;
		}

		return axios.get(path);
	},

};
