<script lang="ts">
import {
	PropType,
	defineComponent,
	inject,
} from 'vue';

import {
	EventBus, Helpers, Luxon
} from '../../../../models/crm';

import BlockingOverlay from '../../../../components/BlockingOverlay/BlockingOverlay.vue';
import ModalBasic from '../../../../components/Modal/ModalBasic.vue';
import { NextTransaction } from '../../../../models/financial';

export default defineComponent({
	components: {
		BlockingOverlay,
		ModalBasic,
	},
	props: {
		nextEntries: {
			type: Array as PropType<NextTransaction[]>,
			required: true,
		},

		entryType: {
			type: String as PropType<'income' | 'expense'>,
			required: true,
		},
	},
	data() {
		return {

			eventBus: inject<EventBus>('eventBus'),
			helpers: inject<Helpers>('helpers'),
			luxon: inject<Luxon>('luxon'),

			requesting: {
				save: false,
			},
		};
	},
	methods: {
		hide(): void {
			(this.$refs.modal as typeof ModalBasic).hide();
		},

		show(): void {
			(this.$refs.modal as typeof ModalBasic).show();
		},

		openEntryModal(entryId: number): void {
			this.hide();

			if (this.entryType === 'income') {
				this.$router.push({
					name: 'ENTRY_INCOME_DETAIL',
					params: {
						entryId,
					},
				});


				return;
			}

			this.$router.push({
				name: 'ENTRY_EXPENSE_DETAIL',
				params: {
					entryId,
				},
			});
		},

		// Events

		buttonHideClick(): void {
			this.hide();
		},
	}
});
</script>

<template>
	<ModalBasic
		ref="modal"

		overlay-click-dismiss
		size="md"
		title="Próximas entradas do mês"
	>
		<template #content>
			<div class="m-0 p-0 max-h-[243px]">
				<table class="w-full text-center">
					<thead class="border-b sticky top-0 bg-white">
						<tr class="text-xs leading-[18px] text-[rgb(118,138,170)] font-semibold">
							<th class="p-3">
								Descrição
							</th>
							<th class="p-3">
								Valor
							</th>
							<th class="p-3">
								Data do Vencimento
							</th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="entry in nextEntries"
							:key="entry.id"
							class="w-full text-xs font-semibold"
						>
							<td
								class="p-3 cursor-pointer text-primary hover:bg-gray-100 hover:underline"
								@click="openEntryModal(entry.id)"
							>
								{{ entry.description }}
							</td>
							<td
								:class="[
									{ 'text-red-500': entryType === 'expense' },
									{ 'text-green-600': entryType === 'income' }
								]"
								class="p-3"
							>
								<span>{{ entryType === 'expense' ? '-' : '+' }}</span>
								<span class="ml-1">
									{{ helpers?.getFormattedCurrencyFromNumber(entry.amount) }}
								</span>
							</td>
							<td class="p-3">
								<span :title="luxon?.fromISO(entry.due_date).toFormat('dd/MM/yyyy')">
									{{ luxon?.fromISO(entry.due_date).toFormat('dd/MM/yyyy') }}
								</span>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</template>
		<template #footer>
			<fieldset class="flex-1 flex overflow-hidden rounded-b-md">
				<button
					class="flex-1 bg-gray-200 font-medium p-4 text-gray-500 hover:bg-gray-300 active:bg-gray-200"
					@click="buttonHideClick"
				>
					FECHAR
				</button>
			</fieldset>
		</template>
	</ModalBasic>

	<Teleport to="body">
		<BlockingOverlay />
	</Teleport>

</template>

<style lang="scss" scoped></style>