<script lang="ts">
import {
	computed, defineComponent, inject
} from 'vue';
import {
	RouteLocationNormalized, RouteLocationRaw
} from 'vue-router';

import {
	EventBus, Helpers, Lodash, Luxon
} from '../../../../models/crm';

// Sub-view components
import ViewModeList from '../../components/IncomesExpenses/ViewModeList.vue';
import ViewModeSummary from '../../components/IncomesExpenses/ViewModeSummary.vue';

export default defineComponent({
	components: {
		ViewModeList,
		ViewModeSummary,
	},
	provide() {
		return {
			backRoute: computed(() => this.backRoute),
			isViewTypeExpense: computed(() => this.isViewTypeExpense),
			isViewTypeIncome: computed(() => this.isViewTypeIncome),
			viewDictionary: computed(() => this.viewDictionary),
			viewType: computed(() => this.viewType),
		};
	},
	beforeRouteUpdate(to, _from, next) {
		this.setViewModeFromUrlParam(to);
		next();
	},
	beforeRouteEnter(to, _from, next) {
		next(
			async vm => {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				(vm as any).setViewModeFromUrlParam(to);
			}
		);
	},
	data() {
		return {

			eventBus: inject<EventBus>('eventBus'),
			helpers: inject<Helpers>('helpers'),
			lodash: inject<Lodash>('lodash'),
			luxon: inject<Luxon>('luxon'),

			viewMode: 'list',

		};
	},
	computed: {
		backRoute(): RouteLocationRaw {
			if (this.viewType === 'expense') {
				return { name: 'expenses' };
			} else {
				return { name: 'incomes' };
			}
		},

		isViewModeSummary(): boolean {
			return this.viewMode === 'resumo';
		},

		isViewModeList(): boolean {
			return this.viewMode === 'lista';
		},

		isViewTypeExpense(): boolean {
			return this.viewType === 'expense';
		},

		isViewTypeIncome(): boolean {
			return this.viewType === 'income';
		},

		newEntriesButtonTitleAttribute(): string {
			if (this.isViewTypeIncome) {
				return 'Criar um ou vários registros de valores a receber (entradas)';
			} else {
				return 'Criar um ou vários registros de valores a pagar (saídas)';
			}
		},

		viewDictionary(): Record<string, string> {
			const dictionary = {
				entry: 'entrada',
				Entry: 'Entrada',
				entries: 'entradas',
				Entries: 'Entradas',
			};

			if (this.isViewTypeExpense) {
				dictionary.entry = 'saída';
				dictionary.Entry = 'Saída';
				dictionary.entries = 'saídas';
				dictionary.Entries = 'Saídas';
			}

			return dictionary;
		},

		viewType(): 'expense' | 'income' {
			const currentRoute = this.$route;
			const { path } = currentRoute;
			const isExpense = !!path.match(/\/saida[s]?/);

			return isExpense ? 'expense' : 'income';
		},

	},
	methods: {

		setViewModeFromUrlParam(toRoute: RouteLocationNormalized): void {
			if (toRoute.query.modo === 'resumo') {
				this.viewMode = 'resumo';
			} else {
				this.viewMode = 'lista';
			}
		},

		// Events

		buttonCreateClick(): void {
			if (this.isViewTypeIncome) {
				this.$router.push({name: 'add-income'});

				return;
			}

			this.$router.push({name: 'add-expense'});
		},

		buttonTabViewModeClick(mode: 'lista' | 'resumo'): void {
			const currentRoute = this.$route;

			const query = {
				...currentRoute.query,
			};

			if (mode !== 'lista') {
				query.modo = mode;
			} else {
				if (query.modo) delete query.modo;
			}

			this.$router.push({
				path: currentRoute.path,
				query,
			});
		},

	},
});
</script>

<template>
	<section class="feature">

		<div class="flex items-center justify-between mb-4">
			<div class="flex-1 flex items-center gap-4">
				<h1>{{ viewDictionary.Entries }}</h1>
				<button
					class="btn btn--primary h-auto min-h-0 normal-case !px-2 !py-1 rounded text-white"
					:title="newEntriesButtonTitleAttribute"

					@click="buttonCreateClick"
				>
					<svg
						class="inline w-6 h-6"
						fill="none"
						stroke="currentColor"
						viewBox="0 0 24 24"
						xmlns="http://www.w3.org/2000/svg"
					><path
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="2"
						d="M12 6v6m0 0v6m0-6h6m-6 0H6"
					/></svg>
					<span>Novas {{ viewDictionary.entries }}</span>
				</button>
			</div>
			<div>
				<fieldset>
					<button
						:class="[
							'btn btn--primary rounded-md',
							{ 'btn--ghost': !isViewModeList }
						]"
						title="Ver a tela no modo LISTA"

						@click="buttonTabViewModeClick('lista')"
					>
						<svg
							class="inline h-6 w-6"
							fill="none"
							stroke="currentColor"
							stroke-width="1.5"
							viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg"
							aria-hidden="true"
						>
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
							/>
						</svg>
						<span class="ml-2">Lista</span>
					</button>
					<button
						:class="[
							'btn btn--primary ml-2 rounded-md',
							{ 'btn--ghost': !isViewModeSummary }
						]"
						title="Ver a tela no modo RESUMO"

						@click="buttonTabViewModeClick('resumo')"
					>
						<svg
							class="inline h-6 w-6"
							fill="none"
							stroke="currentColor"
							stroke-width="1.5"
							viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg"
							aria-hidden="true"
						>
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								d="M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0020.25 18V6A2.25 2.25 0 0018 3.75H6A2.25 2.25 0 003.75 6v12A2.25 2.25 0 006 20.25z"
							/>
						</svg>
						<span class="ml-2">Resumo</span>
					</button>
				</fieldset>
			</div>
		</div>

		<ViewModeList v-if="isViewModeList" />
		<ViewModeSummary v-if="isViewModeSummary" />

	</section>
</template>

<style lang="scss" scoped>
</style>