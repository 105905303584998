const storageNamespace = 'cj-vjs';

const logPrefix = '[services/storage]:';

/**
 *
 * @param {string} type
 * @param {string} property
 */
const getItem = (type: string, property: string): string | null => {
	try {
		return window[type as 'sessionStorage' | 'localStorage'].getItem(`${storageNamespace}/${property}`);
	} catch (err) {
		console.error(logPrefix, err);
	}

	return null;
};

/**
 *
 * @param {string} type
 * @param {string} property
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const setItem = (type: string, property: string, value: any): void => {
	try {
		window[type as 'sessionStorage' | 'localStorage'].setItem(`${storageNamespace}/${property}`, value);
	} catch (err) {
		console.error(logPrefix, err);
	}
};

/**
 *
 * @param {string} type
 * @param {string} property
 */
const removeItem = (type: string, property: string): void => {
	try {
		window[type as 'sessionStorage' | 'localStorage'].removeItem(`${storageNamespace}/${property}`);
	} catch (err) {
		console.error(logPrefix, err);
	}
};

export default {
	getItem,
	removeItem,
	setItem,
};