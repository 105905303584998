/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosResponse } from 'axios';

import {
	Task, TaskToSend, TaskPaginationResponse
} from '../../../models/crm';

const pageParams = '&per_page=999999&page=1';

export default {
	create(data: TaskToSend): Promise<AxiosResponse<Task, any>> {
		const path = '/tasks.json';

		return axios.post(path, data);
	},

	delete(id: string | number): Promise<AxiosResponse<null|undefined, any>> {
		const path = `/tasks/${id}.json`;

		return axios.delete(path, {});
	},

	getAll(
		perPage: number,
		page: number,
		orderBy: string,
		filters: string
	): Promise<AxiosResponse<TaskPaginationResponse, any>> {
		const path = `/tasks.json?per_page=${perPage}&page=${page}&order=${orderBy}${filters}`;

		return axios.get(path);
	},

	getById(id: string | number): Promise<AxiosResponse<Task, any>> {
		const path = `/tasks/${id}.json?${pageParams}`;

		return axios.get(path);
	},

	update({
		data,
		id,
	}: {
        data: TaskToSend;
        id: string | number;
    }): Promise<AxiosResponse<Task, any>> {
		const path = `/tasks/${id}.json`;

		return axios.patch(path, data);
	},

	searchByName(
		name: string,
		perPage = 50,
		nextPage = 1,
		orderBy: string,
		filters: string
	): Promise<AxiosResponse<TaskPaginationResponse, any>> {
		const path = `/tasks.json?search=${name}&per_page=${perPage}&page=${nextPage}&order=${orderBy}${filters}`;
		return axios.get(path);
	},
};