<script lang="ts">
import {
	defineComponent, inject
} from 'vue';
import {
	Contact,
	EventBus,
	Luxon,
	Gender,
	Phone,
	Address,
	MaritalStatus,
	DataType,
	InfoItem,
	ContactNote,
	EntityType,
	Email,
} from '../../../../models/crm';
import DetailsView from '../../components/Common/DetailsView.vue';
import serviceContacts from '../../../../services/API/crm/contacts';
import ModalContact from './ModalContact.vue';
import ModalBasic from '../../../../components/Modal/ModalBasic.vue';
import ModalAddNote from '../../components/LegalCaseDetails/ModalAddNote.vue';
import {
	mapActions, mapState
} from 'pinia';
import { useContactsStore } from '../../../../stores/models/crm/contacts';

export default defineComponent({
	components: {
		DetailsView,
		ModalContact,
		ModalBasic,
		ModalAddNote,
	},
	provide() {
		return {
			filters: {},
		};
	},
	beforeRouteEnter(to, _from, next) {
		next(async(vm) => {
			if (to.name === 'add-note-to-contact') {
				await (vm.$refs.modalAddNote as typeof ModalAddNote).show();
				return;
			}
			if (to.name === 'update-contact-note') {
				await (vm.$refs.modalEditNote as typeof ModalAddNote).show();
				return;
			}
			if (to.name === 'edit-contact') {
				await (vm.$refs.modalContact as typeof ModalContact).show();
				return;
			}
		});
	},
	data() {
		return {
			DATA_TYPE: DataType.CONTACT,
			eventBus: inject<EventBus>('eventBus'),
			luxon: inject<Luxon>('luxon'),
			contact: undefined as Contact | undefined,
			requesting: {
				loadContact: false,
				deleteContact: false,
				loadNotes: false,
			},
			detailsData: {
				basicInfoItems: [] as InfoItem[],
				moreInfoItems: [] as InfoItem[],
			},
			confirmDeleteContact: false as boolean,
			isMoreInfoOpen: true as boolean,
			tabs: [
				'Anotações',
				'Cálculos',
				'Casos',
				'CjMail',
				'Documentos',
				'Finanças',
			],
			selectedTab: 'Anotações',
		};
	},
	computed: {
		contactAge() {
			if (this.contact?.birth_date) {
				const age = this.luxon
					?.fromISO(this.contact.birth_date)
					.diffNow('years').years;
				const ageInFullYears = age ? Math.abs(Math.floor(age)).toString() : '';
				return ageInFullYears;
			}
			return null;
		},
		noteToEdit() {
			const { noteId } = this.$route.params;

			if (noteId && typeof noteId === 'string') {
				return this.contact?.notes.find(
					(note: ContactNote) => Number(note.id) === Number(noteId)
				);
			}
			return null;
		},
		...mapState(useContactsStore, [ 'contactsList' ]),
	},
	async created() {
		await this.loadContact();
	},
	methods: {
		...mapActions(useContactsStore, [
			'getContactById',
			'deleteContact',
			'getContactAttachments',
		]),
		async loadContact() {
			const contactId = Number(this.$route.params.id);
			const contacts = this.contactsList;
			const storedContact = contacts.find(
				(contact: Contact) => contact.id === contactId
			);
			if (
				storedContact &&
        storedContact.attachments &&
        storedContact.calculations
			) {
				this.contact = storedContact;
				this.setContactDetails(storedContact);
			} else {
				this.requesting.loadContact = true;
				try {
					const contact = await this.getContactById(contactId, {
						withAttachments: true,
						withReceivedEmails: true,
					});

					this.contact = contact;
					this.setContactDetails(contact);
				} catch (error) {
					console.error(error);
					this.eventBus?.emit('Toast/add', {
						content: 'Erro ao carregar contato',
						type: 'error',
					});
				} finally {
					this.requesting.loadContact = false;
				}
			}
		},
		async loadNotes(): Promise<void> {
			if (!this.contact) return;
			const contactId = this.contact.id;

			this.requesting.loadNotes = true;

			try {
				const response = await serviceContacts.notesGetByContactId({
					contactId,
				});
				const { notes } = response.data;

				sessionStorage.setItem(
					`contact-${contactId}-notes`,
					JSON.stringify(notes)
				);

				this.contact.notes = notes;
			} catch (err) {
				console.error(err);

				this.eventBus?.emit('Toast/add', {
					content: 'Falha ao carregar anotações',
					type: 'error',
				});
			} finally {
				this.requesting.loadNotes = false;
			}

			return;
		},
		setContactDetails(contact: Contact) {
			if (contact.entity_type === EntityType.COMPANY) {
				this.detailsData.basicInfoItems = [
					{
						label: 'CNPJ',
						value: contact.cnpj || '--',
						isHtml: false,
					},
					{
						label: 'Razão Social',
						value: contact.corporate_name || '--',
						isHtml: false,
					},
					{
						label: 'Nome Fantasia',
						value: contact.name || '--',
						isHtml: false,
					},
					{
						label: 'Telefone(s)',
						value: contact.phones?.map(this.phoneToHtmlString).join('<div class="mr-1 ml-1">|</div>') || '--',
						isHtml: true,
					},
					{
						label: 'E-mail(s)',
						value: contact.emails?.map(this.emailToHtmlString).join('<div class="mr-1 ml-1">|</div>') || '--',
						isHtml: true,
					},
					{
						label: 'Endereço da sede',
						value: this.formatAddress(contact.address) || '--',
						isHtml: false,
					},
				];
				this.detailsData.moreInfoItems = [
					{
						label: 'Ramo de atividade da empresa',
						value: contact.business_sector || '--',
						isHtml: false,
					},
					{
						label: 'Endereço fiscal',
						value: contact.tax_residence
							? `${contact.tax_residence.city} - ${contact.tax_residence.state}`
							: '--',
						isHtml: false,
					},
					{
						label: 'Administrador/Responsável',
						value: contact.responsible_party_name || '--',
						isHtml: false,
					},
				];
				return;
			}

			this.detailsData.basicInfoItems = [
				...(contact.birth_date
					? [
						{
							label: 'Idade',
							value: `${this.contactAge} anos` || '--',
							isHtml: false,
						},
					]
					: []),
				{
					label: 'CPF',
					value: contact.cpf || '--',
					isHtml: false,
				},
				{
					label: 'RG',
					value: contact.rg || '--',
					isHtml: false,
				},
				{
					label: 'Sexo',
					value: this.formatGender(contact.gender),
					isHtml: false,
				},
				{
					label: 'Data de Nascimento',
					value: this.luxon?.fromISO(contact.birth_date).toFormat('dd/MM/yyyy') || '--',
					isHtml: false,
				},
				{
					label: 'Telefone(s)',
					value: contact?.phones?.map(this.phoneToHtmlString).join('<div class="ml-1 mr-1">|</div>') || '--',
					isHtml: true,
				},
				{
					label: 'E-mail(s)',
					value: contact.emails?.map(this.emailToHtmlString).join('<div class="mr-1 ml-1">|</div>') || '--',
					isHtml: true,
				},
			];

			const leadNote = contact.lead_note
				? [
					{
						label: 'Descrição do caso',
						value: contact.lead_note,
						isHtml: true,
					},
				]
				: [];
			this.detailsData.moreInfoItems = [
				{
					label: 'Nacionalidade',
					value: contact.nationality || '--',
					isHtml: false,
				},
				{
					label: 'Estado civil',
					value: this.formatMaritalStatus(contact.marital_status) || '--',
					isHtml: false,
				},
				{
					label: 'Profissão',
					value: contact.occupation || '--',
					isHtml: false,
				},
				{
					label: 'Endereço',
					value: this.formatAddress(contact.address) || '--',
					isHtml: false,
				},
				...leadNote,
			];
		},
		buttonEditClick() {
			(this.$refs.modalContact as typeof ModalContact).show();
		},
		buttonDeleteClick() {
			if (!this.contact) return;
			const hasAttachments =
        (this.contact.attachments && this.contact.attachments.length > 0) ||
        false;
			const hasCalculations =
        (this.contact.calculations && this.contact.calculations.length > 0) ||
        false;
			if ((hasAttachments || hasCalculations) && !this.confirmDeleteContact) {
				(this.$refs.modalDeleteClient as typeof ModalBasic).show();
				return;
			}
			this.requesting.deleteContact = true;
			this.deleteContact(this.contact.id)
				.then(() => {
					this.eventBus?.emit('Toast/add', {
						content: 'Contato excluído com sucesso',
						type: 'success',
					});
					this.$router.push({name: 'contacts'});
				})
				.catch((error) => {
					console.error(error);
					this.eventBus?.emit('Toast/add', {
						content: 'Erro ao excluir contato',
						type: 'error',
					});
				})
				.finally(() => {
					this.requesting.deleteContact = false;
					this.confirmDeleteContact = false;
				});
		},
		hideDeleteClientModal() {
			(this.$refs.modalDeleteClient as typeof ModalBasic)?.hide();
		},
		modalContact_contactUpdated() {
			this.loadContact();
		},
		clickedAddNote() {
			this.$router.push({
				name: 'add-note-to-contact',
				params: {
					id: this.contact?.id,
				},
			});
		},
		clickedEditNote(note: ContactNote) {
			this.$router.push({
				name: 'update-contact-note',
				params: {
					id: this.contact?.id, noteId: note.id
				}
			});
		},
		modalAddNote_noteUpdated() {
			this.loadNotes();
		},
		formatGender(gender: Gender): string {
			const genderText = {
				[Gender.MALE]: 'Masculino',
				[Gender.FEMALE]: 'Feminino',
				[Gender.NOT_DEFINED]: '',
			};
			return genderText[gender];
		},
		phoneToHtmlString(phone: Phone): string {
			if (!phone.number) return '';

			const fullNumber = `${phone.area_code}${phone.number}`;

			const formattedNumberHtml = (number: string): string => {
				return `<div class="value text-gray-700 whitespace-normal">
								<div>
									<a target="_blank" rel="noopener noreferrer" tabindex="0"
									href="tel:+55-${phone.area_code}${phone.number}"
									title="${phone.is_main ? 'Telefone principal' : 'Telefone'}"
									class="flex items-center text-primary">
										${number}
										<span class="text-yellow h-5 w-5 ${!phone.is_main ? 'hidden' : ''}">
											<svg fill="currentColor" viewBox="0 0 20 20">
											<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
											</svg>
										</span>
									</a>
								</div>
						</div>`;
			};
			if (fullNumber.length === 11) {
				// For 11-digit numbers, capture the first digit separately to insert a space later.
				const formattedNumber = fullNumber.match(/(\d{2})(\d)(\d{4})(\d{4})/);
				const finalNumber = formattedNumber
					? `(${formattedNumber[1]}) ${formattedNumber[2]} ${formattedNumber[3]}-${formattedNumber[4]}`
					: '';
				return formattedNumberHtml(finalNumber);
			} else {
				// For 10-digit numbers, use the original pattern.
				const formattedNumber = fullNumber.match(/(\d{2})(\d{4})(\d{4})/);
				const finalNumber = formattedNumber
					? `(${formattedNumber[1]}) ${formattedNumber[2]}-${formattedNumber[3]}`
					: '';
				return formattedNumberHtml(finalNumber);
			}
		},
		emailToHtmlString(email: Email): string {
			return `<div class="value text-gray-700 whitespace-normal">
						<div>
							<a target="_blank" rel="noopener noreferrer" tabindex="0"
							href="mailto:${email.email}"
							title="${email.is_main ? 'E-mail principal' : 'E-mail'}"
							class="flex items-center">
								${email.email}
								<span class="text-yellow h-5 w-5 ${!email.is_main ? 'hidden' : ''}">
											<svg fill="currentColor" viewBox="0 0 20 20">
											<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
											</svg>
										</span>
							</a>
						</div>
					</div>`;
		},
		formatAddress(address: Address | undefined): string {
			if (!address || !address.zip_code) return '';
			const formattedAddress = [
				address.street ? `${address.street},` : '',
				address.number
					? `${address.number} - `
					: address.street
						? 's/ nº -'
						: '',
				address.district ? `${address.district},` : '',
				address.city ? `${address.city},` : '',
				address.state ? `${address.state},` : '',
				address.zip_code ? `${address.zip_code}` : '',
			].join(' ');

			return formattedAddress;
		},
		formatMaritalStatus(maritalStatus: MaritalStatus | undefined) {
			if (!maritalStatus) return '';
			const maritalStatusText = {
				[MaritalStatus.SINGLE]: 'Solteiro(a)',
				[MaritalStatus.STABLE_UNION]: 'União estável',
				[MaritalStatus.MARRIED]: 'Casado(a)',
				[MaritalStatus.DIVORCED]: 'Divorciado(a)',
				[MaritalStatus.SEPARATED]: 'Separado(a)',
				[MaritalStatus.WIDOWED]: 'Viúvo(a)',
				[MaritalStatus.NOT_INFORMED]: 'Não informado',
			};
			return maritalStatusText[maritalStatus] || '';
		},
		toggleMoreInfo() {
			this.isMoreInfoOpen = !this.isMoreInfoOpen;
		},
		addAttachmentToContact(attachments: File[]) {
			if (!this.contact) return;
			this.eventBus?.emit('BlockingOverlay/show', {
				content: 'Adicionando documento...',
			});
			serviceContacts
				.attachmentAdd({
					contactId: this.contact.id,
					attachments,
				})
				.then(async() => {
					if (!this.contact) return;
					this.contact.attachments = await this.getContactAttachments(
						this.contact.id
					);
					this.eventBus?.emit('Toast/add', {
						content: 'Documento adicionado com sucesso',
						type: 'success',
					});
				})
				.catch((error) => {
					console.error(error);
					this.eventBus?.emit('Toast/add', {
						content: 'Erro ao adicionar documento',
						type: 'error',
					});
				})
				.finally(() => {
					this.eventBus?.emit('BlockingOverlay/hide');
				});
		},
		removeAttachmentFromContact(attachmentId: number) {
			if (!this.contact) return;
			this.eventBus?.emit('BlockingOverlay/show', {
				content: 'Removendo documento...',
			});
			serviceContacts
				.attachmentDelete({
					contactId: this.contact.id,
					attachmentId,
				})
				.then(async() => {
					if (!this.contact) return;
					this.contact.attachments = this.contact.attachments?.filter(
						(attachment) => attachment.id !== attachmentId
					);
					this.eventBus?.emit('Toast/add', {
						content: 'Documento removido com sucesso',
						type: 'success',
					});
				})
				.catch((error) => {
					console.error(error);
					this.eventBus?.emit('Toast/add', {
						content: 'Erro ao remover documento',
						type: 'error',
					});
				})
				.finally(() => {
					this.eventBus?.emit('BlockingOverlay/hide');
				});
		},
	},
});
</script>

<template>
	<details-view
		ref="detailsView"
		:data-type="DATA_TYPE"
		:is-loading="!contact || requesting.loadContact"
		:breadcrumbs="[
			{ label: 'Gestão', to: '/crm' },
			{ label: 'Contatos', to: '/crm/contatos' },
			{ label: contact?.name || 'Carregando...' },
		]"
		:contact="contact"
		:basic-info-items="detailsData.basicInfoItems"
		:more-info-items="detailsData.moreInfoItems"
		@btn-edit-click="buttonEditClick"
		@btn-delete-click="buttonDeleteClick"
		@notes-updated="loadNotes"
		@btn-add-note-click="clickedAddNote"
		@btn-edit-note-click="clickedEditNote"
		@btn-add-attachment-click="addAttachmentToContact"
		@btn-remove-attachment-click="removeAttachmentFromContact"
	>
		<template #page-title>
			<div class="flex">
				<div class="text-gray-500 h-6 w-6 mr-3">
					<svg viewBox="0 0 26 26">
						<path
							d="M2.16667 26C2.16667 26 0 26 0 23.8333C0 21.6667 2.16667 15.1667 13 15.1667C23.8333 15.1667 26 21.6667 26 23.8333C26 26 23.8333 26 23.8333 26H2.16667ZM13 13C14.7239 13 16.3772 12.3152 17.5962 11.0962C18.8152 9.87721 19.5 8.22391 19.5 6.5C19.5 4.77609 18.8152 3.12279 17.5962 1.90381C16.3772 0.68482 14.7239 0 13 0C11.2761 0 9.62279 0.68482 8.40381 1.90381C7.18482 3.12279 6.5 4.77609 6.5 6.5C6.5 8.22391 7.18482 9.87721 8.40381 11.0962C9.62279 12.3152 11.2761 13 13 13Z"
							fill="#878B94"
						/>
					</svg>
				</div>
				{{ contact?.name }}
			</div>
		</template>
	</details-view>
	<ModalContact
		ref="modalContact"
		:contact-id="contact?.id"
		:contacts-list="[]"
		:keep-same-route="true"
		@contact-updated="modalContact_contactUpdated"
	/>
	<ModalBasic
		ref="modalDeleteClient"
		:dismissable="!requesting.deleteContact"
		size="lg"
	>
		<template #content>
			<div class="p-10 flex flex-col items-center">
				<h1 class="mb-1 font-semibold text-md text-center">
					VOCÊ REALMENTE DESEJA EXCLUIR
					<br>
					O CONTATO
					<span class="italic">{{ contact?.name }}?</span>
				</h1>
				<div class="mb-4 mt-6 text-left text-black w-full">
					<p class="text-left font-semibold mb-3">
						Atenção!
					</p>
					<p class="mb-3">
						Essa é uma ação <span class="font-semibold">irreversível</span>.
					</p>
					<p class="mb-3">
						Ao excluir um contato, todas as informações cadastrais, arquivos
						anexos, anotações e cálculos atrelados a ele
						<span class="font-semibold">serão excluídos de forma definitiva</span>.
					</p>
					<p class="mb-3">
						Além disso, não será possível visualizar e receber os e-mails
						vinculados ao CJ-Mail do contato, bem como não será possível acessar
						contas nas quais o e-mail do CJ-Mail estiver cadastrado.
					</p>
					<p>Deseja continuar?</p>
				</div>
				<label class="inline-flex">
					<input
						v-model="confirmDeleteContact"
						type="checkbox"
						class="checkbox-sm !rounded"
					>
					<span class="label-text ml-2 font-semibold">Estou ciente, quero excluir o contato, seus dados, documentos e
						seus cálculos.<span class="text-red-500">*</span></span>
				</label>
			</div>
		</template>
		<template #footer>
			<div class="flex-1 flex overflow-hidden rounded-b-md">
				<button
					class="flex-1 bg-gray-200 text-base p-4 text-gray-600 hover:bg-gray-300 active:bg-gray-200"
					@click="hideDeleteClientModal()"
				>
					CANCELAR
				</button>
				<button
					:class="[
						'flex-1 bg-red-500 text-base p-4 text-white hover:bg-red-600 active:bg-red-500',
						!confirmDeleteContact && 'opacity-50 cursor-not-allowed',
					]"
					:disabled="!confirmDeleteContact"
					@click="buttonDeleteClick()"
				>
					EXCLUIR
				</button>
			</div>
		</template>
	</ModalBasic>
	<ModalAddNote
		v-if="contact"
		ref="modalAddNote"
		:entity-id="contact.id"
		note-type="contact"
		@note-created="modalAddNote_noteUpdated"
	/>

	<ModalAddNote
		v-if="contact && noteToEdit"
		ref="modalEditNote"
		:entity-id="contact.id"
		is-edit
		:note="noteToEdit"
		note-type="contact"
		@note-updated="modalAddNote_noteUpdated"
	/>
</template>
