import { defineStore } from 'pinia';
import storage from '../services/storage';

export type GlobalState = {
	sidebar: {
		isCollapsed: boolean;
		isVisible: boolean;
	}
}

export const useGlobalStore = defineStore('global', {
	state: (): GlobalState => ({
		sidebar: {
			isCollapsed: false,
			isVisible: false,
		},
	}),
	actions: {
		setSidebarShow(value: boolean): void {
			this.sidebar.isVisible = value;
		},
		setSidebarCollapse(value: boolean): void {
			this.sidebar.isCollapsed = value;

			storage.setItem('localStorage', 'global/ui/sidebar/is_collapsed', value);
		},

		toggleSidebarCollapse(): void {
			const isCollapsedInverted = !this.sidebar.isCollapsed;

			storage.setItem('localStorage', 'global/ui/sidebar/is_collapsed', isCollapsedInverted);

			this.sidebar.isCollapsed = isCollapsedInverted;
		},

	},
});