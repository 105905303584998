<script lang="ts">
import objectHash from 'object-hash';
import {
	PropType,
	defineComponent, inject
} from 'vue';
import { mapState } from 'pinia';

import {
	Lodash,
	User,
	Attachment,
	MaskaValues,
	Contact,
	LegalCase,
	ValidationResult
} from '../../../../models/crm';

import { useAccountStore } from '../../../../stores/account';

import serviceContacts from '../../../../services/API/crm/contacts';
import serviceLegalCases from '../../../../services/API/crm/legalCases';

import serviceCostCenters from '../../../../services/API/financial/costCenters';
import serviceExpenseCategories from '../../../../services/API/financial/expenseCategories';
import serviceIncomeCategories from '../../../../services/API/financial/incomeCategories';

import Datepicker from '../../../../components/Form/DatePicker.vue';
import FileUploadFilePond from '../../../../components/FileUpload/FileUploadFilePond.vue';
import FormValidator from '../../../../components/Form/FormValidator.vue';
import InfoTooltip from '../../../../components/Form/InfoTooltip.vue';
import InputText from '../../../../components/Form/InputText.vue';
import ModalBasic from '../../../../components/Modal/ModalBasic.vue';
import TagsMultiselect from '../../../../components/Tags/TagsMultiselect.vue';

import ModalContact from '../../../CRM/views/Contacts/ModalContact.vue';
import ModalLegalCase from '../../../CRM/views/LegalCases/ModalLegalCase.vue';
import ModalCategory from '../../components/ModalCategory.vue';
import ModalCostCenter from '../../components/ModalCostCenter.vue';

import {
	Category,
	CostCenter,
	EntryBlockModel, FinancialAttachment
} from '../../../../models/financial';
import { RouteLocationRaw } from 'vue-router';

export default defineComponent({
	components: {
		Datepicker,
		FileUploadFilePond,
		FormValidator,
		InfoTooltip,
		InputText,
		ModalBasic,
		TagsMultiselect,

		ModalContact,
		ModalLegalCase,

		ModalCategory,
		ModalCostCenter,
	},
	provide() {
		return {
			filters: {},
		};
	},
	props: {

		attachmentsAllowMultiple: {
			default: false,
			type: Boolean,
		},

		dictionary: {
			type: Object as PropType<Record<string, string>>,
			required: true,
		},

		loadedAttachments: {
			default: () => [],
			type: Array as PropType<Attachment[]>,
		},

		showAdvancedOptions: {
			default: false,
			type: Boolean,
		},

		showSideActions: {
			default: true,
			type: Boolean,
		},

		type: {
			required: true,
			type: String,
		},

		isMultipleSave: {
			default: false,
			type: Boolean,
		},

		defaultBackRoute: {
			type: Object as PropType<RouteLocationRaw>,
		}

	},
	emits: [
		'attachment-remove',
		'attachment-description-change',
		'attachments-updatefiles',
		'delete',
		'duplicate',
		'repeat',
		'model-update',
		'open-modal-category',
		'open-modal-contact',
		'open-modal-cost-center',
		'open-modal-legal-case',
	],
	data() {
		return {

			disableAutoFillPaymentDate: false,

			lodash: inject<Lodash>('lodash'),

			modalRepeat: {
				model: {
					months: '',
				}
			},

			model: {
				amount: '',
				category: [],
				contact: [],
				costCenter: [],
				description: '',
				dueDate: '',
				legalCase: [],
				note: '',
				paymentDate: '',

				attachments: {
					files: [],
				},
			} as EntryBlockModel,

			isAdvancedOptionsActive: false,

			refs: {},

		};
	},
	computed: {
		...mapState(useAccountStore, [ 'user' ]),

		backRoute(): RouteLocationRaw {
			if (this.defaultBackRoute) return this.defaultBackRoute;

			if (this.type === 'income') return {name: 'add-income'};

			return {name: 'add-expense'};
		},

		modalLegalCase_inData(): { assignees: User[]} {
			const assignees = this.user?.id ? [ this.user ] : [];

			return {
				assignees,
			};
		},

	},
	watch: {

		loadedAttachments(): void {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			(this.$refs.fileUploadAttachments as any).reset();
		},

		'model.dueDate'(value, oldValue): void {
			if (!value) return;

			/**
             * Sometimes, for unknown reasons, VueDatePicker tries to force update
             * when no changes were made, when the field gets focused. hasDateUpdated
             * has been created to avoid bugs issued by that behavior.
             */
			const hasDateUpdated = oldValue && value.slice(0, 10) !== oldValue.slice(0, 10);
			if (!hasDateUpdated) return;

			/**
             * Only auto open and auto select when not duplicating or repeating
             */
			if (!this.disableAutoFillPaymentDate) {
				this.model.paymentDate = value;

				/**
                 * setTimeout needed because of VueDatePicker delay behavior
                 */
				setTimeout(
					() => {
						(this.$refs.datePickerPaymentDate as typeof Datepicker).setSelect();
						(this.$refs.datePickerPaymentDate as typeof Datepicker).openMenu();
					},
					50
				);
			}

			this.disableAutoFillPaymentDate = false;
		},

		model: {
			deep: true,
			handler: function(value) {
				this.$emit('model-update', value);
			},
			immediate: true,
		},

	},
	beforeMount() {
		if (this.showAdvancedOptions) this.isAdvancedOptionsActive = true;
	},
	mounted() {
		/**
         * Need to define refs afer mounted so added
         * blocks can have FormValidator styles properly applied
         */
		this.refs = this.$refs;

		const inputAmount = this.$refs.inputAmount as typeof InputText;
		window.setTimeout(() => inputAmount.$el.querySelector('input')?.focus({ focusVisible: true }), 100);
	},
	methods: {
		getFilesArrayAsString(arr: FinancialAttachment[]): string {
			return JSON.stringify(
				arr.map((item) => `${item.filename}:${item.fileSize}:${item.fileType}:${item.fileExtension}${item.id}`)
			);
		},

		getDictionaryValue(key: string): string {
			return this.dictionary[key] || '';
		},

		resetValidation(): void {
			(this.$refs.formValidator as typeof FormValidator).reset();
		},

		validate(): ValidationResult {
			return (this.$refs.formValidator as typeof FormValidator).validate();
		},

		// Events

		buttonAddCategoryClick(): void {
			if (this.isMultipleSave) {
				this.$emit('open-modal-category');
				return;
			}

			(this.$refs.modalCategory as typeof ModalCategory).show(this.type);
		},

		buttonAddContactClick(): void {
			if (this.isMultipleSave) {
				this.$emit('open-modal-contact');
				return;
			}

			(this.$refs.modalContact as typeof ModalContact).show();
		},

		buttonAddCostCenterClick(): void {
			if (this.isMultipleSave) {
				this.$emit('open-modal-cost-center');
				return;
			}

			(this.$refs.modalCostCenter as typeof ModalCostCenter).show(this.type);
		},

		async buttonAddLegalCaseClick(): Promise<void> {
			if (this.isMultipleSave) {
				this.$emit('open-modal-legal-case');
				return;
			}

			await (this.$refs.modalLegalCase as typeof ModalLegalCase).show();
		},

		buttonAdvancedOptionsClick(): void {
			this.isAdvancedOptionsActive = !this.isAdvancedOptionsActive;
		},

		buttonAttachmentRemoveClick(item: Attachment): void {
			this.$emit('attachment-remove', item);
		},

		buttonDeleteClick(): void {
			this.$emit('delete', this.model);
		},

		buttonDuplicateClick(): void {
			this.$emit('duplicate', this.model);
		},

		buttonRepeatClick(): void {
			this.modalRepeat.model.months = '';
			(this.$refs.modalRepeat as typeof ModalBasic).show();
		},

		inputAmount_blur(maska: MaskaValues): void {
			const {
				masked, unmasked
			} = maska;

			// If not empty
			if (unmasked) {
				// If mask is not being applied yet
				if (!masked.match(/^[0-9.]+,\d{2}$/)) {
					this.model.amount = `R$ ${unmasked},00`;
				}
			}
		},

		setModel(model: EntryBlockModel, isRepeating = false): void {
			this.model = model;

			isRepeating && this.resetValidation();
		},

		inputTextDescriptionChange(e: Event, attachment: Attachment): void {
			const value = (e.currentTarget as HTMLInputElement | null)?.value;

			if (value === attachment.description) return;

			this.$emit('attachment-description-change', {
				attachment, value
			});
		},

		modalRepeat_buttonOkClick(): void {
			this.$emit('repeat', {
				model: this.model,
				months: Number(this.modalRepeat.model.months),
			});
			(this.$refs.modalRepeat as typeof ModalBasic).hide();
		},

		modalRepeat_buttonCancelClick(): void {
			(this.$refs.modalRepeat as typeof ModalBasic).hide();
		},

		attachments_updatefiles(files: FinancialAttachment[]): void {
			const hashCurrentFiles = objectHash(this.getFilesArrayAsString(files));
			const hashPreviousFiles = objectHash(
				this.getFilesArrayAsString(this.model.attachments.files)
			);

			if (hashCurrentFiles !== hashPreviousFiles) {
				this.model.attachments.files = files;
				this.$emit('attachments-updatefiles', files);
			} else {
				this.model.attachments.files = [];
			}
		},

		tagsMultiselectAsyncCategory_queryFunction(query: string): Promise<Category[]> {
			const serviceCategories = this.type === 'income' ? serviceIncomeCategories : serviceExpenseCategories;
			const categoriesKey = this.type === 'income' ? 'income_categories' : 'expense_categories';

			return new Promise(
				(resolve, reject) => {
					serviceCategories
						.searchByName(query)
						.then(({data}) => {
							resolve(data[categoriesKey]);
						})
						.catch((err) => {
							console.error(err);
							reject(err);
						});
				}
			);
		},

		tagsMultiselectAsyncCostCenter_queryFunction(query: string): Promise<CostCenter[]> {
			return new Promise(
				(resolve, reject) => {
					serviceCostCenters
						.searchByName(query)
						.then(({data}) => {
							resolve(data.cost_centers);
						})
						.catch((err) => {
							console.error(err);
							reject(err);
						});
				}
			);
		},

		tagsMultiselectAsyncContact_queryFunction(query: string): Promise<Contact[]> {
			return new Promise(
				(resolve, reject) => {
					serviceContacts
						.searchByName({
							name: query, orderBy: 'name:asc', filters: ''
						})
						.then(({data}) => resolve(data.contacts))
						.catch((err) => {
							console.error(err);
							reject(err);
						});
				}
			);
		},

		tagsMultiselectAsyncLegalCase_queryFunction(query: string): Promise<LegalCase[]> {
			return new Promise(
				(resolve, reject) => {
					serviceLegalCases
						.searchByName({
							name: query, perPage: 10, page: 1, orderBy: 'name:asc', filters: ''
						})
						.then(({data}) => {
							resolve(data.legal_cases);
						})
						.catch((err) => {
							console.error(err);
							reject(err);
						});
				}
			);
		},

	},
});
</script>

<template>
	<div :class="['bg-white p-3 rounded component-income-expense-add', $attrs.class]">
		<FormValidator
			ref="formValidator"
			:refs="refs"
			:validation-summary-show="true"
			validation-summary-class="mb-4 p-3"
		>
			<template
				field="inputAmount"
				label="Valor"
				message="Preenchimento obrigatório"

				:invalidIf="() => model.amount === ''"
			/>
			<template
				field="inputDescription"
				label="Descrição"
				message="Preenchimento obrigatório"

				:invalidIf="() => model.description === ''"
			/>
			<template
				field="datePickerDueDate"
				label="Data do Vencimento"
				message="Preenchimento obrigatório"

				:invalidIf="() => model.dueDate === ''"
			/>
			<template
				field="tagsMultiselectAsyncCategory"
				label="Categoria"
				message="Preenchimento obrigatório"

				:invalidIf="() => model.category.length < 1"
			/>
			<template
				field="tagsMultiselectAsyncCostCenter"
				label="Centro de Custo"
				message="Preenchimento obrigatório"

				:invalidIf="() => model.costCenter.length < 1"
			/>
		</FormValidator>
		<div class="flex gap-3">
			<div class="flex-1">
				<div class="gap-3 grid grid-cols-3 mb-5">
					<div>
						<InputText
							ref="inputAmount"
							v-model="model.amount"

							:class="['input-text-amount', type]"
							label="Valor"
							placeholder="R$ 0,00"
							type="money"
							required

							@blur="inputAmount_blur"
						/>
					</div>
					<div class="col-span-2">
						<InputText
							ref="inputDescription"
							v-model="model.description"

							label="Descrição"
							:placeholder="`Defina uma identificação para sua ${dictionary.entry}`"
							required
						/>
					</div>
				</div>
				<div class="gap-3 grid grid-cols-3 mb-2">
					<div>
						<div class="flex font-semibold mb-1 text-gray-500 text-xs">
							<span>Data do Vencimento</span>
							<span class="ml-1 text-red-600">*</span>
							<InfoTooltip content="Preencha no futuro para previsões. O CJ vai fazer um relatório de previsões para você." />
						</div>
						<Datepicker
							ref="datePickerDueDate"
							v-model="model.dueDate"

							arrow-navigation
							close-on-auto-apply
							:enable-time-picker="false"
							format="dd/MM/yyyy"
							input-class-name="npt-due-date focus:border-transparent focus:outline focus:outline-2 focus:outline-blue-500 focus:hover:!border-transparent"
							:keep-action-row="false"
						/>
					</div>
					<div>
						<div class="flex font-semibold mb-1 text-gray-500 text-xs">
							<span>Data do {{ type === 'income' ? 'Recebimento' : 'Pagamento' }}</span>
							<InfoTooltip
								content="Deixe em branco para previsões. O CJ vai mostrar nos relatórios as previsões que ainda não foram pagas."
							/>
						</div>
						<Datepicker
							ref="datePickerPaymentDate"
							v-model="model.paymentDate"

							arrow-navigation
							close-on-auto-apply
							:enable-time-picker="false"
							format="dd/MM/yyyy"
							input-class-name="npt-payment-date focus:border-transparent focus:outline focus:outline-2 focus:outline-blue-500 focus:hover:!border-transparent"
							:keep-action-row="false"
						/>
					</div>
					<div>
						<div class="flex font-semibold mb-1 text-gray-500 text-xs">
							<span>Categoria</span>
							<span class="ml-1 text-red-600">*</span>
						</div>
						<div class="flex flex-col gap-1 items-end">
							<TagsMultiselect
								ref="tagsMultiselectAsyncCategory"
								v-model="model.category"

								class="w-full"
								label="name"
								:single="true"
								:query-function="tagsMultiselectAsyncCategory_queryFunction"
								async
							>
								<template #maxElements>
									<span class="inline-flex items-center text-red-500 text-sm">
										<svg
											class="inline h-5 w-5"
											fill="currentColor"
											viewBox="0 0 24 24"
											xmlns="http://www.w3.org/2000/svg"
											aria-hidden="true"
										>
											<path
												clip-rule="evenodd"
												fill-rule="evenodd"
												d="M6.72 5.66l11.62 11.62A8.25 8.25 0 006.72 5.66zm10.56 12.68L5.66 6.72a8.25 8.25 0 0011.62 11.62zM5.105 5.106c3.807-3.808 9.98-3.808 13.788 0 3.808 3.807 3.808 9.98 0 13.788-3.807 3.808-9.98 3.808-13.788 0-3.808-3.807-3.808-9.98 0-13.788z"
											/>
										</svg>
										<span class="ml-1">Você só pode selecionar uma</span>
									</span>
								</template>
								<template #tagIcon />
							</TagsMultiselect>
							<button
								class="m-0 p-0 text-primary hover:underline text-xs"
								title="Criar uma Categoria"
								@click="buttonAddCategoryClick"
							>
								Criar uma Categoria
							</button>
						</div>
					</div>
				</div>
				<div class="gap-3 grid grid-cols-3 mb-2">
					<div>
						<div class="flex font-semibold mb-1 text-gray-500 text-xs">
							<span>Centro de Custo</span>
							<span class="ml-1 text-red-600">*</span>
						</div>
						<div class="flex flex-col items-end gap-1">
							<TagsMultiselect
								ref="tagsMultiselectAsyncCostCenter"
								v-model="model.costCenter"

								class="w-full"
								label="name"
								:single="true"
								:query-function="tagsMultiselectAsyncCostCenter_queryFunction"
								async
							>
								<template #maxElements>
									<span class="inline-flex items-center text-red-500 text-sm">
										<svg
											class="inline h-5 w-5"
											fill="currentColor"
											viewBox="0 0 24 24"
											xmlns="http://www.w3.org/2000/svg"
											aria-hidden="true"
										>
											<path
												clip-rule="evenodd"
												fill-rule="evenodd"
												d="M6.72 5.66l11.62 11.62A8.25 8.25 0 006.72 5.66zm10.56 12.68L5.66 6.72a8.25 8.25 0 0011.62 11.62zM5.105 5.106c3.807-3.808 9.98-3.808 13.788 0 3.808 3.807 3.808 9.98 0 13.788-3.807 3.808-9.98 3.808-13.788 0-3.808-3.807-3.808-9.98 0-13.788z"
											/>
										</svg>
										<span class="ml-1">Você só pode selecionar um</span>
									</span>
								</template>
								<template #tagIcon />
							</TagsMultiselect>
							<button
								class="m-0 p-0 text-primary hover:underline text-xs"
								title="Criar um Centro de Custo"
								@click="buttonAddCostCenterClick"
							>
								Criar um Centro de Custo
							</button>
						</div>
					</div>
					<div>
						<div class="flex font-semibold mb-1 text-gray-500 text-xs">
							Contato/Pagador
						</div>
						<div class="flex flex-col gap-1 items-end">
							<TagsMultiselect
								ref="tagsMultiselectAsyncContact"
								v-model="model.contact"

								class="w-full"
								label="name"
								:single="true"
								:query-function="tagsMultiselectAsyncContact_queryFunction"
								async
							>
								<template #maxElements>
									<span class="inline-flex items-center text-red-500 text-sm">
										<svg
											class="inline h-5 w-5"
											fill="currentColor"
											viewBox="0 0 24 24"
											xmlns="http://www.w3.org/2000/svg"
											aria-hidden="true"
										>
											<path
												clip-rule="evenodd"
												fill-rule="evenodd"
												d="M6.72 5.66l11.62 11.62A8.25 8.25 0 006.72 5.66zm10.56 12.68L5.66 6.72a8.25 8.25 0 0011.62 11.62zM5.105 5.106c3.807-3.808 9.98-3.808 13.788 0 3.808 3.807 3.808 9.98 0 13.788-3.807 3.808-9.98 3.808-13.788 0-3.808-3.807-3.808-9.98 0-13.788z"
											/>
										</svg>
										<span class="ml-1">Você só pode selecionar um</span>
									</span>
								</template>
								<template #tagIcon />
							</TagsMultiselect>
							<button
								class="m-0 p-0 text-primary hover:underline text-xs"
								title="Criar um Contato"
								@click="buttonAddContactClick"
							>
								Criar um Contato
							</button>
						</div>
					</div>
					<div>
						<div class="flex font-semibold mb-1 text-gray-500 text-xs">
							Caso
						</div>
						<div class="flex flex-col gap-1 items-end">
							<TagsMultiselect
								ref="tagsMultiselectAsyncLegalCase"
								v-model="model.legalCase"

								class="w-full"
								label="name"
								:single="true"
								:query-function="tagsMultiselectAsyncLegalCase_queryFunction"
								async
							>
								<template #maxElements>
									<span class="inline-flex items-center text-red-500 text-sm">
										<svg
											class="inline h-5 w-5"
											fill="currentColor"
											viewBox="0 0 24 24"
											xmlns="http://www.w3.org/2000/svg"
											aria-hidden="true"
										>
											<path
												clip-rule="evenodd"
												fill-rule="evenodd"
												d="M6.72 5.66l11.62 11.62A8.25 8.25 0 006.72 5.66zm10.56 12.68L5.66 6.72a8.25 8.25 0 0011.62 11.62zM5.105 5.106c3.807-3.808 9.98-3.808 13.788 0 3.808 3.807 3.808 9.98 0 13.788-3.807 3.808-9.98 3.808-13.788 0-3.808-3.807-3.808-9.98 0-13.788z"
											/>
										</svg>
										<span class="ml-1">Você só pode selecionar um</span>
									</span>
								</template>
								<template #tagIcon />
							</TagsMultiselect>
							<button
								class="m-0 p-0 text-primary hover:underline text-xs"
								title="Criar um Caso"
								@click="buttonAddLegalCaseClick"
							>
								Criar um Caso
							</button>
						</div>
					</div>
				</div>
				<hr class="my-3">
				<div class="flex gap-3">
					<button
						class="font-semibold inline-flex items-center"
						@click="buttonAdvancedOptionsClick"
					>
						<svg
							:class="['inline w-3 h-3', { 'rotate-180': isAdvancedOptionsActive }]"
							fill="none"
							stroke="currentColor"
							stroke-width="4"
							viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg"
							aria-hidden="true"
						>
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								d="M19.5 8.25l-7.5 7.5-7.5-7.5"
							/>
						</svg>
						<span class="ml-1 text-sm">Opções avançadas</span>
					</button>
				</div>
				<Transition>
					<div
						v-if="isAdvancedOptionsActive"
						class="flex gap-3 mt-3"
					>
						<div class="flex-1">
							<InputText
								ref="inputNotes"
								v-model="model.note"
								label="Notas / Observações"
								:multiline-rows="8"
								multiline
							/>
						</div>
						<div class="border-l flex-1 pl-3">
							<div>
								<span
									class="block font-semibold mb-1 text-gray-500 text-xs"
								>Anexos (Nota Fiscal, Boletos, Comprovantes)</span>
								<div
									v-if="loadedAttachments.length"
									class="mb-2"
								>
									<table class="w-full">
										<tbody>
											<tr
												v-for="(attachment, attachmentIndex) in loadedAttachments"
												:key="attachmentIndex"
												class="odd:bg-gray-200"
											>
												<td
													class="align-center border-b-0 max-w-0 overflow-hidden px-2 py-2 text-ellipsis whitespace-nowrap"
												>
													<span class="font-medium text-xs">{{ attachment.name }}</span>
												</td>
												<td class="align-center border-b-0 py-2 text-center w-7">
													<a
														:href="attachment.url"
														target="_blank"
													>
														<svg
															class="inline w-5 h-5"
															fill="currentColor"
															viewBox="0 0 20 20"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																fill-rule="evenodd"
																d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
																clip-rule="evenodd"
															/>
														</svg>
													</a>
												</td>
												<td class="align-center border-b-0 py-2 text-center w-7">
													<button
														title="Excluir"
														@click="buttonAttachmentRemoveClick(attachment)"
													>
														<svg
															class="inline w-5 h-5"
															fill="currentColor"
															viewBox="0 0 20 20"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																fill-rule="evenodd"
																d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
																clip-rule="evenodd"
															/>
														</svg>
													</button>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								<FileUploadFilePond
									ref="fileUploadAttachments"

									:allow-multiple="attachmentsAllowMultiple"

									@updatefiles="attachments_updatefiles"
								/>
							</div>
						</div>
					</div>
				</Transition>
			</div>
			<div
				v-if="showSideActions"
				class="border-l pl-3 w-24 container-side-actions"
			>
				<button
					class="border border-blue-500 inline-flex items-center mb-2 p-1 rounded text-blue-500 text-xs w-full"
					@click="buttonDeleteClick"
				>
					<svg
						class="inline w-4 h-4"
						fill="none"
						stroke="currentColor"
						stroke-width="1.5"
						viewBox="0 0 24 24"
						xmlns="http://www.w3.org/2000/svg"
						aria-hidden="true"
					>
						<path
							stroke-linecap="round"
							stroke-linejoin="round"
							d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
						/>
					</svg>
					<span class="ml-1">Excluir</span>
				</button>
				<button
					class="border border-blue-500 inline-flex items-center mb-2 p-1 rounded text-blue-500 text-xs w-full"
					@click="buttonDuplicateClick"
				>
					<svg
						class="inline w-4 h-4"
						fill="none"
						stroke="currentColor"
						stroke-width="1.5"
						viewBox="0 0 24 24"
						xmlns="http://www.w3.org/2000/svg"
						aria-hidden="true"
					>
						<path
							stroke-linecap="round"
							stroke-linejoin="round"
							d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75"
						/>
					</svg>
					<span class="ml-1">Duplicar</span>
				</button>
				<button
					class="border border-blue-500 inline-flex items-center p-1 rounded text-blue-500 text-xs w-full disabled:opacity-25"
					:disabled="!model.dueDate"
					@click="buttonRepeatClick"
				>
					<svg
						class="inline w-4 h-4"
						fill="none"
						stroke="currentColor"
						stroke-width="1.5"
						viewBox="0 0 24 24"
						xmlns="http://www.w3.org/2000/svg"
						aria-hidden="true"
					>
						<path
							stroke-linecap="round"
							stroke-linejoin="round"
							d="M19.5 12c0-1.232-.046-2.453-.138-3.662a4.006 4.006 0 00-3.7-3.7 48.678 48.678 0 00-7.324 0 4.006 4.006 0 00-3.7 3.7c-.017.22-.032.441-.046.662M19.5 12l3-3m-3 3l-3-3m-12 3c0 1.232.046 2.453.138 3.662a4.006 4.006 0 003.7 3.7 48.656 48.656 0 007.324 0 4.006 4.006 0 003.7-3.7c.017-.22.032-.441.046-.662M4.5 12l3 3m-3-3l-3 3"
						/>
					</svg>
					<span class="ml-1">Repetir</span>
				</button>
			</div>
		</div>
	</div>
	<ModalBasic
		ref="modalRepeat"

		class="modal-repeat"
		:dismissable="true"
		:title="`Repetir ${getDictionaryValue('entry')}`"
	>
		<template #content>
			<div class="px-4">
				<div class="block font-semibold mb-1 text-gray-500 text-xs">
					Repetir por:
				</div>
				<div>
					<InputText
						ref="modalRepeatInputMonths"
						v-model="modalRepeat.model.months"
						label="Meses"
					/>
				</div>
			</div>
			<hr class="my-3">
			<div class="mb-2 px-4">
				<label class="inline-flex">
					<input
						type="checkbox"
						checked
						class="checkbox checkbox-sm !rounded"
					>
					<span class="label-text ml-2">Adicionar número da parcela ao nome da entrada</span>
				</label>
			</div>
			<div class="px-4 text-xs">
				<div class="font-bold mb-1">
					Exemplo:
				</div>
				<div>Descrição da {{ getDictionaryValue('entry') }} - 1 de 3</div>
				<div>Descrição da {{ getDictionaryValue('entry') }} - 2 de 3</div>
				<div>Descrição da {{ getDictionaryValue('entry') }} - 3 de 3</div>
			</div>
		</template>
		<template #footer>
			<div class="flex mt-3 overflow-hidden rounded-bl rounded-br">
				<button
					class="bg-gray-200 flex-1 font-medium py-3 text-gray-400"
					@click="modalRepeat_buttonCancelClick"
				>
					CANCELAR
				</button>
				<button
					class="bg-blue-500 flex-1 font-medium py-3 text-white"
					@click="modalRepeat_buttonOkClick"
				>
					REPETIR
				</button>
			</div>
		</template>
	</ModalBasic>

	<ModalLegalCase
		v-if="!isMultipleSave"
		ref="modalLegalCase"
		:back-route="backRoute"
		:in-data="modalLegalCase_inData"
	/>

	<ModalContact
		v-if="!isMultipleSave"
		ref="modalContact"
		:back-route="backRoute"
	/>

	<ModalCategory
		v-if="!isMultipleSave"
		ref="modalCategory"
	/>

	<ModalCostCenter
		v-if="!isMultipleSave"
		ref="modalCostCenter"
	/>

</template>

<style lang="scss" scoped>
:deep(.component-form-control-select) {
    select {
        padding: 0.5rem;
    }
}

:deep(.modal-repeat) {
    .modal-header {
        span {
            font-size: 1rem;
        }
    }
}

.input-text-amount {
    border: none;
    :deep() {
        label {
            padding: 0;
        }

        input {
            border-bottom: 1px solid #ccc;
            font-size: 1.75rem;
            font-weight: 300;
            padding: 0 0 0.25rem;

            &:focus {
                border-color: theme('colors.blue.500');
            }
        }
    }

    &.expense {
        :deep() {
            input {
                color: theme('colors.red.600');
            }
        }
    }

    &.income {
        :deep() {
            input {
                color: theme('colors.green.600');
            }
        }
    }

}

.v-enter-active,
.v-leave-active {
    transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>
