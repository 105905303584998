/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	EntryBlockData, EntryBlockToSend, ReceivedEntryBlock, ReceivedEntryBlockPaginationResponse
} from './../../../models/financial';
import { FinancialAttachment } from '../../../models/financial';
import axios, { AxiosResponse } from 'axios';
import { serialize } from 'object-to-formdata';
import { Attachment } from '../../../models/crm';

class BaseIncomesExpenses {
	serviceNameId: 'incomes' | 'expenses';
	baseParams: string;

	constructor(serviceNameId: 'incomes' | 'expenses') {
		this.baseParams = '&per_page=999999&page=1';
		this.serviceNameId = serviceNameId;
	}

	attachmentAdd(
		entryId: number, attachments: FinancialAttachment[]
	): Promise<AxiosResponse<any>> {
		const path = `/finances/${this.serviceNameId}/${entryId}/add_attachment.json`;

		const requestHeaders = {
			headers: {
				'content-type': 'multipart/form-data',
			},
		};

		const attachment = attachments[0];
		if (!attachment) {
			throw new Error('Attachment not found');
		}

		// const description = attachment.description || '';
		const description = '';

		const formData = new FormData();
		formData.append('file', attachment.file);
		formData.append('name', attachment?.filename);
		formData.append('description', description);

		return axios.post(path, formData, requestHeaders);
	}

	attachmentDelete(
		attachmentId: number, entryId: number
	): Promise<AxiosResponse<any>> {
		const path = `/finances/${this.serviceNameId}/${entryId}/attachment/${attachmentId}.json`;
		return axios.delete(path, {});
	}

	createMultiple(data: EntryBlockData): Promise<AxiosResponse<any>> {
		const formData = serialize(data);

		const path = `/finances/${this.serviceNameId}/create_multiple.json`;
		return axios.post(`${path}`, formData, {
			headers: {
				'content-type': 'multipart/form-data',
			}
		});
	}

	delete(id: number): Promise<AxiosResponse<null | undefined, any>> {
		const path = `/finances/${this.serviceNameId}/${id}.json`;
		return axios.delete(path, {});
	}

	getAll(perPage = 25, page = 1, orderBy: string, filters: string, useNextTransactions = false)
		: Promise<AxiosResponse<ReceivedEntryBlockPaginationResponse>> {
		const orderByQuery = `&order=${orderBy}`;

		const nextTransaction = useNextTransactions ? ',next_transactions' : '';

		const path = `/finances/${this.serviceNameId}.json?per_page=${perPage}&page=${page}${orderByQuery}${filters}&include=attachments${nextTransaction}`;
		return axios.get(path);
	}

	getAttachmentsByEntryId(id: number): Promise<AxiosResponse<{ attachments: Attachment[] }>> {
		const path = `/finances/${this.serviceNameId}/${id}/attachments.json?${this.baseParams}`;
		return axios.get(path);
	}

	getById(id: number): Promise<AxiosResponse<ReceivedEntryBlock>> {
		const path = `/finances/${this.serviceNameId}/${id}.json?include=attachments`;
		return axios.get(path);
	}

	searchByDescription(
		description: string,
		perPage = 50,
		nextPage = 1,
		orderBy: string,
		filters: string
	): Promise<AxiosResponse<ReceivedEntryBlockPaginationResponse>> {
		const orderByQuery = `&order=${orderBy}`;

		const path = `/finances/${this.serviceNameId}.json?search=${description}&per_page=${perPage}&page=${nextPage}${orderByQuery}${filters}`;
		return axios.get(path);
	}

	// Update a single entry
	update(
		data: EntryBlockToSend, id: number
	): Promise<AxiosResponse<ReceivedEntryBlock>> {
		const formData = serialize(data);

		const path = `/finances/${this.serviceNameId}/${id}.json`;

		return axios.patch(`${path}`, formData, {
			headers: {
				'content-type': 'multipart/form-data',
			}
		});
	}
}

export default BaseIncomesExpenses;