<script lang="ts">
import Multiselect from 'vue-multiselect';

import {
	defineComponent, inject, PropType
} from 'vue';
import { Lodash } from '../../models/crm';

type Item = {
	title?: string,
	name?: string,
};

export default defineComponent({
	props: {
		modelValue: {
			type: Array as PropType<Item[]>
		},
		async: {
			default: false, type: Boolean
		},
		icon: { type: String },
		label: {
			default: 'label', type: String
		},
		openDirection: {
			default: 'bottom', type: String
		},
		queryFunction: {
			type: Function,
			required: true,
		},
		searchable: {
			default: true, type: Boolean
		},
		single: {
			default: false, type: Boolean
		},
		taggable: {
			default: false, type: Boolean
		},
		trackBy: {
			default: 'id', type: String
		},
	},
	emits: [
		'update:modelValue',
	],
	components: {
		Multiselect,
	},
	watch: {

		modelValue(value): void {
			this.value = value;
		},

		value(value): void {
			this.$emit('update:modelValue', value);
		},
	},
	data() {
		return {

			isLoading: false as boolean,
			isOpen: false as boolean,

			options: [] as { title?: string, name?: string }[],

			value: [] as { title?: string, name?: string }[],

			lodash: inject<Lodash>('lodash'),
		};
	},
	computed: {

		isSingleAndMaxReached(): boolean {
			return this.single && this.value.length === 1;
		},

	},
	methods: {
		focus(): void {
			if (!this.isOpen) {
				(this.$refs.tagsMultiselect as typeof Multiselect).activate();
				(this.$refs.tagsMultiselect as typeof Multiselect).$el.focus();
			}
		},

		blur(): void {
			if (this.isOpen) {
				(this.$refs.tagsMultiselect as (typeof Multiselect | null))?.deactivate();
			}
		},

		addTag(newTag: string): void {
			if (!this.taggable) return;

			const tag = {
				title: newTag,
			};

			this.options.push(tag);
			this.value.push(tag);
		},

		async open(): Promise<void> {
			this.isOpen = true;

			if (this.async) {
				this.isLoading = true;

				const response = await this.queryFunction('');

				if (response) {
					this.options = response;
				}

				this.isLoading = false;
			}
		},

		searchChange(query: string): void {
			this.lodash?.debounce(async() => {
				if (this.async) {
					this.isLoading = true;

					const response = await this.queryFunction(query);

					if (response) {
						this.options = response;
					}

					this.isLoading = false;
				}
			}, 200)();
		},

		// eslint-disable-next-line no-unused-vars
		removeItem(removeFunc: (arg0: unknown) => void, item: unknown): void {
			removeFunc(item);

			this.focus();
		}

	},
	beforeMount() {
		if (this.modelValue) {
			this.value = this.modelValue;
		}
	},
});
</script>
<template>
	<span v-click-outside="blur"
		class="component-tags-multiselect"
	>
		<multiselect
			ref="tagsMultiselect"
			v-model="value"

			:clear-on-select="true"
			:hide-selected="true"
			:label="label"
			:limit="20"
			:loading="isLoading"
			:max="single ? 1 : 999"
			:multiple="true"
			:open-direction="openDirection"
			:options="options"
			:options-limit="150"
			:searchable="searchable"
			:taggable="taggable"
			:track-by="trackBy"
			value="modelValue"
			prevent-autofocus

			@open="open"
			@close="isOpen = false"
			@search-change="searchChange"
			@tag="addTag"
			@click.stop="focus"

			deselect-label="Remover"
			deselect-group-label="Remover"
			no-options="A lista está vazia"
			:placeholder="taggable ? 'Digite para procurar ou adicionar' : 'Digite para procurar'"
			select-label="[Enter]"
			select-group-label="[Enter]"
			selected-label="Selecionado"
			tag-placeholder="[Enter]"
		>
			<template #noOptions="{}">
				<span class="text-sm" />
			</template>
			<template #noResult="{}">
				<span class="text-sm">Nenhum item encontrado.</span>
			</template>
			<template #maxElements="{}">
				<slot v-if="$slots.maxElements"
					name="maxElements"
				/>
				<span v-else
					class="text-sm"
				>Você já selecionou o máximo de opções permitidas para este campo.</span>
			</template>
			<template #caret="{ toggle }">
				<svg xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 14 8"
					height="8"
					width="11"
					class="absolute right-3 top-[21px] text-gray-500"
					@click.stop="toggle"
				>
					<path d="M1 1l6 6 6-6"
						fill="none"
						stroke="currentColor"
						stroke-width="2.5"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</svg>
			</template>
			<template #selection="{ values, remove, isOpen }">
				<span
					v-for="(item, index) in values"
					class="inline-flex items-center bg-transparent h-6 px-1 my-auto"
					:class="isOpen && 'bg-gray-100 border border-gray-300 rounded-full'"
					:key="`item-${index}`"
				>
					<span class="font-medium max-w-[12rem] overflow-hidden text-ellipsis text-sm whitespace-nowrap"
						:title="item[label]"
					>{{ `${item[label]}${index === (values.length - 1) ? '' : ','}` }}</span>
					<button v-show="isOpen"
						class="mx-1"
						@click.stop="removeItem(remove, item)"
					>
						<svg class="w-3 h-3"
							fill="currentColor"
							viewBox="0 0 20 20"
							xmlns="http://www.w3.org/2000/svg"
						><path fill-rule="evenodd"
							d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
							clip-rule="evenodd"
						/></svg>
					</button>
				</span>
			</template>
		</multiselect>
	</span>
</template>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss" scoped>

.component-tags-multiselect {
    &.validation-error {
        :deep(.multiselect) {
            .multiselect__tags {
                border-color: #dc3545;
            }
        }
    }

	:deep(.multiselect--active) .multiselect__tags {
		width: auto !important;
	}

    :deep(.multiselect) {
        font-family: inherit;
		width: 100% !important;

		.multiselect__select {
			min-height: 50px;
		}
        .multiselect__tags {
			border-color: #DCDDDF;
			border-radius: 0.25rem;
            display: flex;
            flex-wrap: wrap;
			max-width: 100%;
            gap: 6px;
            padding: 8px;
			padding-right: 24px;
			min-height: 50px;

            .multiselect__input {
                background-color: transparent;
                font-size: 14px;
                margin-bottom: 0;
                padding: 8px 0;
            }

            .multiselect__placeholder {
                margin-bottom: 0;
				display: flex;
				justify-content: flex-start;
				align-items: center;
            }

            .multiselect__single {
                font-size: 0.95rem;
                margin: 0;
                padding: 0.15rem 0;
            }

        }

        .multiselect__content-wrapper {
            box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.1);
			overflow-x: hidden;
            .multiselect__content {
				min-width: auto !important;
				width: 100%;
                .multiselect__element {
					width: 100%;
                    .multiselect__option {
                        font-size: 0.85rem;
						width: 100%;

                        &--highlight {
                            background-color: #4F88ED;
                            &::after {
                                background-color: inherit;
                            }
                        }

						span {
							display: inline-block;
							text-overflow: ellipsis;
							white-space: nowrap;
							overflow: hidden;
							min-width: auto !important;
							width: 100% !important;

							span {
								display: inline-block;
								text-overflow: ellipsis;
								white-space: nowrap;
								overflow: hidden;
								min-width: auto !important;
								width: 100% !important;
							}
						}
                    }
                }
            }
        }
    }
}
</style>
