<script lang="ts">

import account from '../services/account';
import auth from '../services/auth';
import storage from '../services/storage';
import {
	defineComponent, inject
} from 'vue';


export default defineComponent({
	data() {
		return {

			envVars: inject<Record<string, string>>('envVars') || {},

			model: {
				login: '',
				password: '',
			},

			alert: {
				message: '',
				show: false,
			},

			nextRoute: '/' as string,

			requesting: {
				signin: false,
			}

		};
	},
	methods: {

		setAlert(message: string, state: boolean): void {
			this.alert.message = message;
			this.alert.show = state;
		},

		async signin(): Promise<void> {
			const {
				login, password
			} = this.model;

			if (!login || !password) {
				const errorMessage = 'Preencha o login e a senha para prosseguir';

				this.setAlert(errorMessage, true);

				return;
			}

			try {
				this.requesting.signin = true;

				await auth.signin({
					login, password
				});

				const accountResponse = await account.getAccount();

				if (accountResponse.status === 200) {
					this.$router.replace(this.nextRoute);
				}

				this.requesting.signin = false;
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (err: any) {
				this.requesting.signin = false;

				const errorMessage = err.response?.data?.error
					|| err.response.data?.errors?.login[0]
					|| 'Erro ao autenticar';

				this.setAlert(errorMessage, true);

				console.error(err);
			}
		},

		// Events
		buttonAlertCloseClick(): void {
			this.setAlert('', false);
		},

		buttonSigninClick(): void {
			this.signin();
		},

		inputEmailKeyupEnter(): void {
			this.signin();
		},

		inputPasswordKeyupEnter(): void {
			this.signin();
		},

		// Google

		buttonGoogleLoginClick(): void {
			const {envVars} = this;

			auth
				.signinGoogleOauth({
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					// eslint-disable-next-line no-undef
					envVars, gapi
				})
				.then(
					_response => {
						this.$router.replace(this.nextRoute);
					}
				)
				.catch(
					error => {
						try {
							this.setAlert(error.response?.data?.error, true);
						} catch (err) {
							console.error(err);
							console.error(error);
						}
					}
				);
		},

	},
	created() {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		window.Intercom('boot', {
			app_id: import.meta.env.VITE_INTERCOM_ID,
		});


		this.nextRoute = storage.getItem('sessionStorage', 'next_route') || '/';
	},
});
</script>

<template>
	<div class="w-full main-container">
		<div class="container-login-info">
			<h2>NOVIDADE!<br>ALERTA DE APOSENTADORIAS</h2>
			<p>Chega de perder clientes que ainda não têm direito de se aposentar.</p>
			<p>
				Com essa nova ferramenta do CJ, você <b>recebe um aviso quando a aposentadoria do cliente está para
					chegar</b> e consegue entrar em contato com ele rapidinho.
			</p>
			<img class="login-bg2"
				src="/img/login-banner-notification.svg"
			>
		</div>
		<div class="container-form">
			<form method="POST"
				action="javascript:void(0)"
			>
				<svg viewBox="0 0 134 26"
					class="logo"
				>
					<g>
						<path d="M30.3756 8.44753V11.4967C29.9939 11.072 29.6121 10.7618 29.2327 10.5639C28.8534 10.3657 28.4454 10.2656 28.0135 10.2656C27.2118 10.2656 26.5629 10.54 26.0665 11.0863C25.5703 11.6327 25.3221 12.3461 25.3221 13.2265C25.3221 14.0473 25.5751 14.7272 26.0808 15.2665C26.5868 15.8081 27.2309 16.0777 28.0134 16.0777C28.4452 16.0777 28.8532 15.9799 29.2326 15.7818C29.612 15.5861 29.9937 15.2737 30.3755 14.8466V17.8959C29.9341 18.1106 29.4927 18.2728 29.0561 18.3802C28.6171 18.4876 28.1757 18.54 27.7272 18.54C27.1689 18.54 26.6534 18.4733 26.181 18.3421C25.7086 18.2085 25.2742 18.0056 24.8783 17.7361C24.1148 17.2232 23.5327 16.5787 23.1318 15.8011C22.731 15.021 22.5306 14.1454 22.5306 13.1718C22.5306 12.3892 22.6572 11.6781 22.9124 11.0364C23.1654 10.3945 23.5494 9.81478 24.0601 9.2923C24.5444 8.79367 25.0907 8.42145 25.6992 8.1734C26.3077 7.92758 26.9829 7.80356 27.7273 7.80356C28.1758 7.80356 28.6172 7.85603 29.0562 7.96336C29.4929 8.07069 29.9343 8.23287 30.3756 8.44753Z" />
						<path d="M37.1589 14.7464C37.1589 14.1953 37.0132 13.7611 36.7198 13.4389C36.4263 13.1169 36.0255 12.9571 35.5173 12.9571C35.0091 12.9571 34.6107 13.1169 34.3196 13.4367C34.0284 13.7541 33.8829 14.1931 33.8829 14.7466C33.8829 15.2954 34.0284 15.7297 34.3219 16.047C34.6155 16.3642 35.0139 16.5218 35.5173 16.5218C36.0255 16.5218 36.4263 16.3642 36.7198 16.047C37.0134 15.7295 37.1589 15.2953 37.1589 14.7464ZM39.3515 11.2248V18.2824H37.0609V17.1372C36.7342 17.6261 36.3691 17.9842 35.9611 18.2133C35.5531 18.44 35.0735 18.5543 34.5248 18.5543C33.5824 18.5543 32.8308 18.2107 32.2654 17.5237C31.7024 16.8366 31.4208 15.9132 31.4208 14.7583C31.4208 13.606 31.7165 12.6921 32.3059 12.0122C32.8954 11.3347 33.6875 10.9958 34.6848 10.9958C35.2168 10.9958 35.675 11.1103 36.059 11.3371C36.4432 11.5638 36.7725 11.912 37.0468 12.3844V11.2248H39.3515ZM37.605 7.81772L35.4458 10.2657H34.3698L35.6415 7.81772H37.605Z" />
						<path d="M43.7917 7.44547H41.3008V18.2824H43.7917V7.44547Z" />
						<path d="M50.7635 13.544C50.5201 13.3294 50.2886 13.1742 50.0644 13.0762C49.8402 12.9784 49.6063 12.9284 49.3581 12.9284C48.8642 12.9284 48.4705 13.0907 48.1794 13.4175C47.8883 13.7442 47.7428 14.1857 47.7428 14.7464C47.7428 15.2856 47.8954 15.7176 48.1985 16.0396C48.5015 16.3617 48.9071 16.5215 49.4201 16.5215C49.6729 16.5215 49.9092 16.4714 50.1311 16.3711C50.3529 16.2709 50.563 16.1159 50.7635 15.906V18.0651C50.4652 18.2321 50.1669 18.3562 49.8688 18.4349C49.5705 18.5135 49.2628 18.5542 48.9454 18.5542C47.8598 18.5542 46.9794 18.2058 46.3065 17.5092C45.6314 16.8125 45.2949 15.8939 45.2949 14.7582C45.2949 13.6536 45.6385 12.7492 46.3256 12.0478C47.0152 11.3463 47.8956 10.9955 48.9741 10.9955C49.332 10.9955 49.6564 11.0313 49.9451 11.1005C50.2338 11.1697 50.5081 11.2818 50.7635 11.4369V13.544Z" />
						<path d="M59.5388 11.2248V14.8275C59.5388 15.6245 59.4744 16.216 59.3456 16.6003C59.2192 16.9845 59.002 17.3256 58.6991 17.6238C58.3865 17.9364 57.9976 18.1702 57.5324 18.3228C57.0647 18.478 56.5183 18.5543 55.8884 18.5543C55.2633 18.5543 54.7193 18.478 54.2564 18.3228C53.7912 18.1702 53.3975 17.9363 53.0706 17.6238C52.7676 17.3351 52.5529 16.9964 52.4265 16.6075C52.2999 16.2187 52.2379 15.6245 52.2379 14.8276V11.2248H54.6144V14.9898C54.6144 15.5791 54.7121 16.0061 54.9077 16.271C55.1034 16.5336 55.4207 16.6647 55.8597 16.6647C56.3034 16.6647 56.6208 16.5359 56.814 16.2782C57.0072 16.0182 57.1051 15.5888 57.1051 14.9898V11.2248H59.5388Z" />
						<path d="M63.8883 7.44547H61.3973V18.2824H63.8883V7.44547Z" />
						<path d="M70.8029 14.7702C70.8029 14.2454 70.6693 13.8302 70.4044 13.5272C70.1395 13.2241 69.7794 13.0715 69.3283 13.0715C68.8726 13.0715 68.5123 13.2241 68.2474 13.5272C67.9851 13.8302 67.8537 14.2452 67.8537 14.7702C67.8537 15.2975 67.9851 15.7127 68.245 16.0133C68.505 16.314 68.8678 16.4643 69.3283 16.4643C69.784 16.4643 70.1443 16.3138 70.4092 16.0133C70.6716 15.7128 70.8029 15.2977 70.8029 14.7702ZM73.2794 14.7702C73.2794 15.2784 73.1792 15.7627 72.9812 16.2186C72.7831 16.6767 72.4969 17.0869 72.1175 17.4497C71.7356 17.8124 71.3087 18.0891 70.8361 18.2753C70.3614 18.4613 69.8603 18.5543 69.3283 18.5543C68.7866 18.5543 68.281 18.4613 67.8086 18.2753C67.3336 18.0891 66.9113 17.8124 66.5393 17.4497C66.1646 17.0942 65.8807 16.6886 65.6851 16.2305C65.4895 15.7699 65.3917 15.2833 65.3917 14.7702C65.3917 14.2549 65.4895 13.7681 65.6829 13.31C65.876 12.8543 66.16 12.4439 66.5394 12.079C66.9068 11.7211 67.3268 11.449 67.8014 11.2677C68.274 11.0865 68.7821 10.9957 69.3285 10.9957C69.8748 10.9957 70.383 11.0863 70.8531 11.2677C71.3233 11.4492 71.7455 11.7211 72.1177 12.079C72.5017 12.4488 72.7906 12.8616 72.986 13.3173C73.1816 13.773 73.2794 14.2598 73.2794 14.7702Z" />
						<path d="M81.3819 8.04672H84.1592V14.6916C84.1592 15.4122 84.1115 15.968 84.0185 16.3546C83.9254 16.7411 83.7728 17.0822 83.558 17.3805C83.274 17.7672 82.9256 18.0605 82.5106 18.2586C82.0955 18.4566 81.6302 18.5544 81.1149 18.5544C80.4706 18.5544 79.8981 18.416 79.397 18.1416C78.8936 17.8672 78.4618 17.4616 78.1039 16.9222L79.8074 15.3762C79.8504 15.6792 79.9481 15.9132 80.1009 16.0752C80.256 16.2398 80.4493 16.3209 80.6831 16.3209C80.9478 16.3209 81.1316 16.173 81.2319 15.8795C81.3321 15.586 81.3822 14.8512 81.3822 13.6773V8.04672H81.3819Z" />
						<path d="M93.2926 11.2248V14.8275C93.2926 15.6245 93.2281 16.216 93.0993 16.6003C92.9729 16.9845 92.7557 17.3256 92.4528 17.6238C92.1402 17.9364 91.7512 18.1702 91.286 18.3228C90.8184 18.478 90.2719 18.5543 89.6422 18.5543C89.017 18.5543 88.4729 18.478 88.0102 18.3228C87.545 18.1702 87.1513 17.9363 86.8244 17.6238C86.5213 17.3351 86.3065 16.9964 86.1803 16.6075C86.0537 16.2187 85.9917 15.6245 85.9917 14.8276V11.2248H88.368V14.9898C88.368 15.5791 88.4658 16.0061 88.6615 16.271C88.8572 16.5336 89.1745 16.6647 89.6136 16.6647C90.0574 16.6647 90.3746 16.5359 90.5679 16.2782C90.7611 16.0182 90.8589 15.5888 90.8589 14.9898V11.2248H93.2926Z" />
						<path d="M95.094 18.2825V11.2249H97.4442V12.8879C97.6876 12.2794 98.0169 11.8261 98.4367 11.5231C98.8566 11.2201 99.3649 11.0674 99.9614 11.0674C100.059 11.0674 100.159 11.0698 100.26 11.077C100.36 11.0841 100.46 11.0961 100.563 11.1104L100.319 13.3722C100.171 13.3222 100.023 13.2865 99.8803 13.2626C99.7346 13.241 99.5964 13.2291 99.4605 13.2291C98.8568 13.2291 98.3915 13.4153 98.0622 13.785C97.7355 14.1546 97.5708 14.6797 97.5708 15.3596V18.2823H95.094V18.2825Z" />
						<path d="M101.548 18.2824V11.2248H104.039V18.2824H101.548ZM104.812 7.81772L102.653 10.2657H101.576L102.848 7.81772H104.812Z" />
						<path d="M111.335 14.7608C111.335 14.212 111.189 13.7777 110.898 13.4604C110.61 13.143 110.211 12.9856 109.71 12.9856C109.207 12.9856 108.811 13.143 108.522 13.4604C108.233 13.7777 108.09 14.212 108.09 14.7608C108.09 15.3143 108.233 15.7486 108.52 16.0634C108.806 16.3784 109.202 16.5358 109.71 16.5358C110.211 16.5358 110.61 16.3784 110.898 16.061C111.189 15.7438 111.335 15.3095 111.335 14.7608ZM113.544 7.44546V18.2824H111.251V17.1371C110.934 17.6119 110.579 17.9673 110.182 18.2012C109.786 18.4374 109.347 18.5543 108.863 18.5543C107.87 18.5543 107.081 18.2107 106.494 17.526C105.907 16.8414 105.613 15.9179 105.613 14.7584C105.613 13.6346 105.907 12.7256 106.491 12.0338C107.076 11.3418 107.837 10.996 108.777 10.996C109.374 10.996 109.861 11.1033 110.235 11.3181C110.609 11.5329 110.944 11.8931 111.237 12.3965C111.209 12.2295 111.189 12.0387 111.175 11.8263C111.161 11.614 111.154 11.3825 111.154 11.1367V7.44576H113.544V7.44546Z" />
						<path d="M115.336 9.02029C115.336 8.64329 115.47 8.32369 115.739 8.0588C116.007 7.79405 116.329 7.66034 116.708 7.66034C117.099 7.66034 117.426 7.79152 117.689 8.05164C117.954 8.31177 118.085 8.63375 118.085 9.02029C118.085 9.40682 117.951 9.73373 117.681 10.0034C117.414 10.2729 117.09 10.409 116.708 10.409C116.333 10.409 116.011 10.2707 115.742 9.99624C115.472 9.7218 115.336 9.39728 115.336 9.02029ZM115.465 18.2825V11.2249H117.956V18.2825H115.465Z" />
						<path d="M124.928 13.544C124.684 13.3294 124.453 13.1742 124.229 13.0762C124.004 12.9784 123.771 12.9284 123.522 12.9284C123.029 12.9284 122.635 13.0907 122.344 13.4175C122.053 13.7442 121.907 14.1857 121.907 14.7464C121.907 15.2856 122.06 15.7176 122.363 16.0396C122.666 16.3617 123.072 16.5215 123.584 16.5215C123.837 16.5215 124.073 16.4714 124.296 16.3711C124.517 16.2709 124.727 16.1159 124.928 15.906V18.0651C124.63 18.2321 124.331 18.3562 124.033 18.4349C123.735 18.5135 123.427 18.5542 123.11 18.5542C122.024 18.5542 121.144 18.2058 120.471 17.5092C119.796 16.8125 119.459 15.8939 119.459 14.7582C119.459 13.6536 119.803 12.7492 120.49 12.0478C121.179 11.3463 122.06 10.9955 123.138 10.9955C123.496 10.9955 123.821 11.0313 124.11 11.1005C124.398 11.1697 124.673 11.2818 124.928 11.4369V13.544H124.928Z" />
						<path d="M131.47 14.7702C131.47 14.2454 131.336 13.8302 131.071 13.5272C130.806 13.2241 130.446 13.0715 129.995 13.0715C129.539 13.0715 129.179 13.2241 128.914 13.5272C128.652 13.8302 128.521 14.2452 128.521 14.7702C128.521 15.2975 128.652 15.7127 128.912 16.0133C129.172 16.314 129.535 16.4643 129.995 16.4643C130.451 16.4643 130.811 16.3138 131.076 16.0133C131.338 15.7128 131.47 15.2977 131.47 14.7702ZM133.946 14.7702C133.946 15.2784 133.846 15.7627 133.648 16.2186C133.45 16.6767 133.164 17.0869 132.784 17.4497C132.403 17.8124 131.976 18.0891 131.503 18.2753C131.028 18.4613 130.527 18.5543 129.995 18.5543C129.454 18.5543 128.948 18.4613 128.475 18.2753C128.001 18.0891 127.578 17.8124 127.206 17.4497C126.832 17.0942 126.548 16.6886 126.352 16.2305C126.156 15.7699 126.058 15.2833 126.058 14.7702C126.058 14.2549 126.156 13.7681 126.35 13.31C126.543 12.8543 126.827 12.4439 127.206 12.079C127.574 11.7211 127.994 11.449 128.468 11.2677C128.941 11.0865 129.449 10.9957 129.995 10.9957C130.542 10.9957 131.05 11.0863 131.52 11.2677C131.99 11.449 132.412 11.7211 132.784 12.079C133.169 12.4488 133.457 12.8616 133.653 13.3173C133.848 13.773 133.946 14.2598 133.946 14.7702Z" />
					</g>
					<path d="M2.38688 8.90786V19.2986L19.6586 0.350459V26L13.338 15.0521C12.5852 14.8824 12.0378 14.2095 12.0378 13.4236C12.0378 12.5016 12.7856 11.7539 13.7074 11.7539C14.6294 11.7539 15.3771 12.5014 15.3771 13.4236C15.3771 13.5345 15.3661 13.6451 15.3443 13.7538L17.2719 17.0921V6.7015L0 25.6495V0L6.32063 10.9479C7.07343 11.1175 7.62081 11.7905 7.62081 12.5764C7.62081 13.4984 6.87308 14.2461 5.95124 14.2461C5.02911 14.2461 4.28153 13.4984 4.28153 12.5764C4.28153 12.4655 4.29256 12.3551 4.31433 12.2464L2.38688 8.90786Z"
						fill="#3F87F5"
					/>
				</svg>

				<h1>Acesse sua conta</h1>

				<div v-if="alert.show"
					class="alert alert-danger"
				>
					<button type="button"
						title="Fechar"
						@click="buttonAlertCloseClick"
					>
						×
					</button>
					<div id="flash_alert">{{ alert.message }}</div>
				</div>

				<div class="control-group">
					<label>Email</label>
					<div>
						<input
							type="email"
							name="email"
							v-model="model.login"
							autocomplete="on"
							autocompletetype="email"
							autofocus
							class="form-control"
							@keyup.enter="inputEmailKeyupEnter"
						>
					</div>
				</div>

				<div class="control-group">
					<label>Senha</label>
					<div>
						<input type="password"
							name="password"
							v-model="model.password"
							class="form-control"
							@keyup.enter="inputPasswordKeyupEnter"
						>
					</div>
				</div>

				<div class="control-group buttons-forgot-password-and-signin">
					<a :href="`${envVars.VITE_CJ_WEB_APP_URL}/esqueci-senha`">Esqueci minha senha</a>
					<button
						v-if="!requesting.signin"
						type="button"
						class="btn btn--primary btn--round !shadow-none !normal-case"
						@click="buttonSigninClick"
					>
						Entrar
					</button>
					<div
						v-else
						class="btn btn--primary btn--round !shadow-none opacity-[75%]"
					>
						<svg class="animate-spin inline mr-2 h-4 w-4 text-white"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
						>
							<circle class="opacity-25"
								cx="12"
								cy="12"
								r="10"
								stroke="currentColor"
								stroke-width="4"
							/>
							<path class="opacity-75"
								fill="currentColor"
								d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
							/>
						</svg>
					</div>
				</div>

				<div class="control-group buttons-google-and-signup">
					<button type="button"
						class="btn btn--icon btn--google-login"
						@click="buttonGoogleLoginClick"
					>
						<svg viewBox="0 0 533.5 544.3">
							<path d="M533.5 278.4a320 320 0 00-4.7-55.3H272.1v104.8h147a126 126 0 01-54.4 82.7v68h87.7c51.5-47.4 81.1-117.4 81.1-200.2z"
								fill="#4285f4"
							/>
							<path d="M272.1 544.3c73.4 0 135.3-24.1 180.4-65.7l-87.7-68c-24.4 16.6-55.9 26-92.6 26-71 0-131.2-47.9-152.8-112.3H28.9v70.1a272.2 272.2 0 00243.2 149.9z"
								fill="#34a853"
							/>
							<path d="M119.3 324.3a163 163 0 010-104.2V150H28.9a272.4 272.4 0 000 244.4l90.4-70.1z"
								fill="#fbbc04"
							/>
							<path d="M272.1 107.7c38.8-.6 76.3 14 104.4 40.8l77.7-77.7A261.6 261.6 0 00272.1 0C169.2 0 75.1 58 28.9 150l90.4 70.1c21.5-64.5 81.8-112.4 152.8-112.4z"
								fill="#ea4335"
							/>
						</svg>
						<span>Entrar com o Google</span>
					</button>
					<p>
						Não possui conta ainda? <a target="_blank"
							rel="noopener noreferrer"
							tabindex="0"
							:href="`${envVars.VITE_CJ_SITE_URL}/planos-e-precos/`"
						>Clique aqui</a>.
					</p>
				</div>
			</form>

		</div>
	</div>
</template>

<style lang="scss" scoped>
.main-container {
    display: grid;

    @media screen and (max-width: 1023px) {
        grid-template-columns: 1fr;
    }

    @media screen and (min-width: 1024px) {
        grid-template-columns: 1fr minmax(360px, 30vw);
    }

    background-image: url(/img/bg-noise.png);
    background-repeat: repeat;
    min-height: inherit;
    min-width: inherit;

    .container-login-info {
        display: flex;
        align-items: flex-start;
        flex-direction: column;

        background: #0652DD;
        color: #fff;
        max-width: 100vw;
        position: relative;
        padding: 11vh 5vw 0 5vw;
        text-align: left;

        @media screen and (max-width: 1023px) {
            order: 2;
            padding-top: 5vw;
        }

        @media screen and (min-width: 1024px) {
        }

        h2 {
            margin: 0;

            @media screen and (max-width: 1023px) {
                font-size: 2em;
            }

            @media screen and (min-width: 1024px) {
                font-size: calc(32px + 1vw);
                line-height: 2em;
            }
        }

        p {
            font-weight: 200;
            line-height: 150%;

            @media screen and (max-width: 1023px) {
                font-size: 1.25em;
            }

            @media screen and (min-width: 1024px) {
                font-size: calc(6px + 1vw);
                max-width: 25vw;
            }

            b {
                color: #FFF85A;
            }
        }

        img {
            bottom: 0;
            max-width: 35vw;
            position: absolute;
            right: 0;

            @media screen and (max-width: 1023px) {
                display: none;
            }
        }
    }

    .container-form {

        @media screen and (min-width: 1024px) {
            overflow: auto;
            height: 100vh;
        }

        form {
            display: flex;
            flex-direction: column;

            font-size: 14px;
            padding: 0 3.5vw;
            margin-top: 11vh;

            @media screen and (max-width: 1023px) {
                padding: 0 20px;
            }

            .alert {
                padding: 15px;
                margin-bottom: 20px;
                border: 1px solid transparent;
                border-radius: 4px;

                button {
                    appearance: none;
                    background: transparent;
                    border: 0;
                    color: #000;
                    cursor: pointer;
                    filter: alpha(opacity=20);
                    float: right;
                    font-size: 21px;
                    font-weight: bold;
                    line-height: 1;
                    opacity: 0.2;
                    padding: 0;
                    text-shadow: 0 1px 0 #fff;
                }
            }

            .alert-danger {
                color: #a94442;
                background-color: #f2dede;
                border-color: #ebccd1;
            }

            .logo {
                align-self: center;
                fill: #BBBDBF;
                flex: 0 0 auto;
                width: 70%;
            }

            h1 {
                font-size: 20px;
                font-weight: 500;
                line-height: 20px;
                margin: 38px 0;
                text-align: center;
            }

            label {
                display: inline-block;
                font-weight: 700;
                max-width: 100%;
                margin-bottom: 5px;
            }

            a {
                color: #337ab7;
                text-decoration: none;
            }

            .control-group {
                margin-top: 15px;

                .form-control {
                    background-color: #fff;
                    background-image: none;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
                    box-sizing: border-box;
                    color: #555555;
                    display: block;
                    font-size: 14px;
                    height: 34px;
                    line-height: 1.428571429;
                    padding: 6px 12px;
                    width: 100%;

                    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;

                    &:focus {
                        border-color: #66afe9;
                        outline: 0;
                        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08), 0 0 8px rgba(102, 175, 233, 0.6);
                    }
                }
            }

            .buttons-forgot-password-and-signin {
                display: flex;
                align-items: center;
                justify-content: space-between;

                border-bottom: 1px solid #cacfed;
                padding-bottom: 25px;
                margin-bottom: 25px;
            }

            .buttons-google-and-signup {
                border-bottom: 1px solid #cacfed;
                padding-bottom: 25px;
                margin-bottom: 25px;

                p {
                    font-size: 12px;
                    font-weight: 500;
                }
            }
        }

    }
}
</style>
