/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosResponse } from 'axios';

import {
	FunnelToSend,
	FunnelStepToSend,
	Funnel,
	FunnelStep,
	FunnelsPaginationResponse
} from '../../../models/crm';

const pageParams = '&per_page=999999&page=1';

export default {

	create(data: FunnelToSend): Promise<AxiosResponse<Funnel, any>> {
		const path = '/funnels.json';

		return axios.post(path, data);
	},

	delete(
		id: string | number,
		substituteStepId?: string | number
	): Promise<AxiosResponse<null|undefined, any>> {
		let path = `/funnels/${id}.json`;
		if (substituteStepId) {
			path += `?substitute_step_id=${substituteStepId}`;
		}

		return axios.delete(path, {});
	},

	async getAll(page = 1, perPage = 25, orderBy: string, filters: string)
		: Promise<AxiosResponse<FunnelsPaginationResponse, any>>
	{
		const path = `/funnels.json?page=${page}&per_page=${perPage}&include=active_case_counts&order=${orderBy}${filters}`;

		return axios.get(path);
	},

	getById(id: string | number): Promise<AxiosResponse<Funnel, any>> {
		const path = `/funnels/${id}.json?${pageParams}`;

		return axios.get(path);
	},

	async searchByName(
		{
			name, orderBy, filters
		}: {name: string, orderBy: string, filters: string}
		, perPage = 50
		, nextPage = 1
	) : Promise<AxiosResponse<FunnelsPaginationResponse, any>> {
		const orderByQuery = `&order=${orderBy}`;
		const path =
			`/funnels.json?search=${name}&per_page=${perPage}&page=${nextPage}${orderByQuery}${filters}`;

		return axios.get(path);
	},

	update({
		data, id
	}: {
		data: FunnelToSend,
		id: string | number
	}): Promise<AxiosResponse<Funnel, any>> {
		const path = `/funnels/${id}.json`;

		return axios.patch(path, data);
	},

	setMain(id: string | number): Promise<AxiosResponse<{ status: string }, any>> {
		const path = `/funnels/${id}/set_as_main.json`;

		return axios.get(path);
	},

	// Funnel steps

	funnelStepsCreate(data: FunnelStepToSend): Promise<AxiosResponse<FunnelStep, any>> {
		const path = '/funnel_steps.json';

		return axios.post(path, data);
	},

	funnelStepsGetAll(): Promise<AxiosResponse<FunnelStep[], any>> {
		const path = `/funnel_steps.json?include=legal_cases${pageParams}`;

		return axios.get(path);
	},

	funnelStepsUpdate({
		data, id
	}: {
		data: FunnelStepToSend,
		id: string | number
	}): Promise<AxiosResponse<FunnelStep, any>> {
		const path = `/funnel_steps/${id}.json`;

		return axios.patch(path, data);
	},

	funnelStepsDelete(
		id: string | number,
		substituteStepId?: string | number
	): Promise<AxiosResponse<null|undefined, any>> {
		let path = `/funnel_steps/${id}.json`;

		if (substituteStepId) {
			path += `?substitute_step_id=${substituteStepId}`;
		}

		return axios.delete(path, {});
	},

	// Other

	createDefaultFunnels({ officeId }: { officeId: string | number })
		: Promise<AxiosResponse<{ funnels: Funnel[]}, any>>
	{
		const path = '/funnels/create_defaults.json';

		return axios.post(path, { office_id: officeId });
	},

	dragLegalCaseToFunnelStep({
		legalCaseId, funnelStepId, newPosition
	}: {
		legalCaseId: string | number,
		funnelStepId: string | number,
		newPosition: number
	}): Promise<AxiosResponse<FunnelStep, any>> {
		const path = `/funnel_steps/${funnelStepId}/drag_legal_case/${legalCaseId}.json?new_position=${newPosition}`;

		return axios.get(path);
	},
	moveLegalCaseInsideFunnelStep({
		legalCaseId, funnelStepId, newPosition
	}: {
		legalCaseId: string | number,
		funnelStepId: string | number,
		newPosition: number
	}): Promise<AxiosResponse<FunnelStep, any>> {
		const path = `/funnel_steps/${funnelStepId}/update_legal_case_position/${legalCaseId}.json?new_position=${newPosition}`;

		return axios.get(path);
	}

};
