<script lang="ts">
import VueDatepicker from '@vuepic/vue-datepicker';
import { ptBR } from 'date-fns/locale';
import '@vuepic/vue-datepicker/dist/main.css';
import { defineComponent } from 'vue';


export default defineComponent({
	props: {
		arrowNavigation: {
			default: false,
			type: Boolean,
		},

		closeOnAutoApply: {
			default: false,
			type: Boolean,
		},

		enableTimePicker: {
			default: true,
			type: Boolean,
		},

		format: {
			default: 'dd/MM/yyyy HH:mm',
			type: String,
		},

		keepActionRow: {
			default: true,
			type: Boolean,
		},

		inputClassName: {
			type: String,
			default: 'font-normal'
		},

		minDate: {
			type: String,
		},

		modelValue: String,

		monthPicker: {
			default: false,
			type: Boolean,
		},

		yearPicker: {
			default: false,
			type: Boolean,
		},

		timePicker: {
			default: false,
			type: Boolean,
		},

		textInputOptions: {
			default: () => ({
				enterSubmit: true,
				tabSubmit: true,
			}),
			type: Object,
		},

		placeholder: {
			default: 'Selecione a data',
			type: String,
		}
	},
	emits: [
		'blur',
		'focus',
		'text-submit',
		'update:modelValue',
	],
	components: {
		VueDatepicker,
	},
	data() {
		return {

			model: '' as string,

			ptBR,

		};
	},
	methods: {

		getInputElement(): HTMLInputElement | null {
			return (this.$refs.datePicker as typeof VueDatepicker).$el.querySelector('input');
		},

		openMenu(): void {
			(this.$refs.datePicker as typeof VueDatepicker).openMenu();
		},

		setFocus(): void {
			const elInput = this.getInputElement();
			elInput?.focus();
		},

		setSelect(): void {
			const elInput = this.getInputElement();
			elInput?.focus();
			elInput?.select();
		},

		// eslint-disable-next-line no-unused-vars
		handleInput(event: Event, onInput: (evt: Event) => void): void {
			if (!event.target) return;

			const { value } = event.target as HTMLInputElement;

			let newValue = value;

			// Remove all non-numeric characters
			newValue = newValue.replace(/\D|\//gi, '');

			// if newValue is empty, clear input using ref 'date-picker-input' and return
			if (!newValue) {
				const newEvent = {
					...event, target: { ...event.target }
				};

				(newEvent.target as HTMLInputElement).value = '';

				onInput(newEvent);

				(this.$refs['date-picker-input'] as HTMLInputElement).value = '';
				return;
			}

			if (newValue.length > 2 && newValue[2] !== '/') {
				newValue = `${newValue.slice(0, 2)}/${newValue.slice(2)}`;
			}

			if (newValue.length > 5 && newValue[5] !== '/') {
				newValue = `${newValue.slice(0, 5)}/${newValue.slice(5)}`;
			}

			const newEvent = {
				...event, target: { ...event.target }
			};

			(newEvent.target as HTMLInputElement).value = newValue;

			onInput(newEvent);
		},

		// Events

		blur(): void {
			this.$emit('blur');
		},

		focus(): void {
			this.$emit('focus');
		},

		textSubmit(): void {
			this.$emit('text-submit');
		},

		updateModelValue(modelData: string): void {
			this.$emit('update:modelValue', modelData);
		},

	}
});
</script>
<template>
	<VueDatepicker
		ref="datePicker"
		:model-value="modelValue"

		:auto-apply="true"
		:arrow-navigation="arrowNavigation"
		cancel-text="Fechar"
		class="form-control-datepicker"
		:close-on-auto-apply="closeOnAutoApply"
		:day-names="['S', 'T', 'Q', 'Q', 'S', 'S', 'D']"
		:enable-time-picker="enableTimePicker"
		:format="format"
		:hours-increment="-1"
		:minutes-increment="-1"
		:input-class-name="inputClassName"
		calendar-cell-class-name="font-normal"
		menu-class-name="font-normal"
		:keep-action-row="keepActionRow"
		locale="pt-BR"
		:min-date="minDate"
		model-type="format"
		month-name-format="long"
		:month-picker="monthPicker"
		:placeholder="placeholder"
		select-text="OK"
		six-weeks
		text-input
		:text-input-options="textInputOptions"
		:transitions="false"
		utc
		:year-picker="yearPicker"
		:time-picker="timePicker"

		@blur="blur"
		@focus="focus"
		@text-submit="textSubmit"
		@update:modelValue="updateModelValue"
	>
		<template #calendar-icon>
			<button class="border m-1 py-1 px-3 rounded text-sm w-full">
				Selecionar data
			</button>
		</template>
		<template #clock-icon>
			<button class="border m-1 py-1 px-3 rounded text-sm w-full">
				Selecionar hora
			</button>
		</template>
		<template #dp-input="{ value, onInput, onEnter, onTab, onBlur, onKeypress, onPaste }">
			<input
				ref="date-picker-input"
				maxlength="10"
				:placeholder="placeholder"
				class="dp__input dp__input_icon_pad h-[50px] focus:border-transparent focus:outline focus:outline-2 focus:outline-blue-500 focus:hover:!border-transparent"
				:value="value"
				:class="inputClassName"
				@blur="onBlur"
				@input="handleInput($event, onInput)"
				@keypress="onKeypress"
				@paste="onPaste"
				@keydown.enter="onEnter"
				@keydown.tab="onTab"
			>
			<div>
				<svg xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 32 32"
					fill="currentColor"
					aria-hidden="true"
					class="dp__icon dp__input_icon dp__input_icons"
				>
					<path d="M29.333 8c0-2.208-1.792-4-4-4h-18.667c-2.208 0-4 1.792-4 4v18.667c0 2.208 1.792 4 4 4h18.667c2.208 0 4-1.792 4-4v-18.667zM26.667 8v18.667c0 0.736-0.597 1.333-1.333 1.333 0 0-18.667 0-18.667 0-0.736 0-1.333-0.597-1.333-1.333 0 0 0-18.667 0-18.667 0-0.736 0.597-1.333 1.333-1.333 0 0 18.667 0 18.667 0 0.736 0 1.333 0.597 1.333 1.333z" /><path d="M20 2.667v5.333c0 0.736 0.597 1.333 1.333 1.333s1.333-0.597 1.333-1.333v-5.333c0-0.736-0.597-1.333-1.333-1.333s-1.333 0.597-1.333 1.333z" /><path d="M9.333 2.667v5.333c0 0.736 0.597 1.333 1.333 1.333s1.333-0.597 1.333-1.333v-5.333c0-0.736-0.597-1.333-1.333-1.333s-1.333 0.597-1.333 1.333z" /><path d="M4 14.667h24c0.736 0 1.333-0.597 1.333-1.333s-0.597-1.333-1.333-1.333h-24c-0.736 0-1.333 0.597-1.333 1.333s0.597 1.333 1.333 1.333z" />
				</svg>
			</div>
		</template>
	</VueDatepicker>
</template>
<style lang="scss" scoped>
@keyframes validationError {
    from {
        border-color: #e5e7eb;
    }
    to {
        border-color: #dc3545;
    }
}

.form-control-datepicker {

    &.disabled {
        opacity: 0.5;
    }

    input::placeholder {
        font-weight: normal;
    }

    &.validation-error {
        :deep(.dp__input_wrap) {
            input {
                animation-name: validationError;
                animation-duration: 1s;
                animation-direction: alternate;
                animation-iteration-count: infinite;
            }
        }
    }

}
</style>
