import { createApp } from 'vue';
import { createPinia } from 'pinia';

import App from './App.vue';

import vClickOutside from 'click-outside-vue3';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/material.css';
import VueTippy from 'vue-tippy';
import * as Sentry from '@sentry/vue';

const VueTippyOptions = {
	directive: 'tippy',
};

import './styles/main.scss';

import axios from './axios';
import router from './router';

axios.setDefaults();

const app = createApp(App);
const pinia = createPinia();

if (process.env.VITE_APP_ENV === 'production') {
	Sentry.init({
		app,
		dsn: process.env.VITE_SENTRY_DNS,
		environment: process.env.VITE_APP_ENV,
		integrations: [
			new Sentry.BrowserTracing({
				// Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
				tracePropagationTargets: [ process.env.VITE_API_URL || '', 'localhost' ],
				routingInstrumentation: Sentry.vueRouterInstrumentation(router),
			}),
		],
		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0,
		trackComponents: true,
	});
}

app
	.use(pinia)
	.use(VueTippy, VueTippyOptions)
	.use(router)
	.use(vClickOutside)
	.mount('#app');