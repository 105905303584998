<script lang="ts">
import {
	Errors, ValidationResult
} from '../../models/crm';
import {
	defineComponent, PropType, DefineComponent
} from 'vue';

export default defineComponent({
	props: {

		refs: Object as PropType<Record<string|number, DefineComponent>>,

		validationSummaryShow: {
			default: false,
			type: Boolean,
		},

		validationSummaryClass: {
			default: '',
			type: String,
		},

	},
	data() {
		return {

			lastValidateResult: null as ValidationResult | null,

			validationItems: this.$slots.default? this.$slots.default() : [],

		};
	},
	computed: {

		showConditions(): boolean {
			return (this.validationSummaryShow &&
                this.lastValidateResult &&
                !this.lastValidateResult.isValid
			) || false;
		},

	},
	methods: {
		reset(): void {
			this.lastValidateResult = null;

			this.validationItems.forEach(
				item => {
					if (!item.props) return;

					const {
						field: fieldRef
					} = item.props;

					if (!this.refs) return;

					const field = this.refs[fieldRef];

					if (!field) return;

					const el = field?.$el;

					el.classList.remove('validation-error');
					el.removeAttribute('title');
				}
			);
		},

		validate(): ValidationResult {
			const ret = {
				isValid: false,
				errors: {} as Errors,
			};

			this.validationItems.forEach(
				item => {
					let label = '';
					let valueToMatch;

					let fieldValue;
					let fieldEl;

					let isInvalid;

					if (!item.props) return;

					const {
						enableIf,
						invalidIf,

						field: fieldProp,
						label: labelProp,
						pattern: patternProp,
						value: valueProp,
					} = item.props;

					const messageProp = typeof item.props.message === 'function' ?
						item.props.message() :
						item.props.message;

					if (!this.refs) return;

					const field = this.refs[fieldProp];

					if (field) {
						fieldValue = valueProp || field.modelValue || '';
						fieldEl = field.$el;
					}

					if (invalidIf) {
						label = labelProp;
						isInvalid = invalidIf();
					} else if (patternProp && field) {
						label = labelProp || field.label;
						valueToMatch = fieldValue;
						isInvalid = valueToMatch.match(patternProp);
					} else {
						label = labelProp;
						valueToMatch = valueProp || '';
						isInvalid = valueToMatch.match(patternProp);
					}

					const mustEnable = enableIf ? enableIf() === true : true;

					if (mustEnable) {
						if (isInvalid) {
							if (field) {
								fieldEl.classList.add('validation-error');
								fieldEl.setAttribute('title', messageProp);
							}

							ret.errors[(fieldProp || labelProp) as string | number] = {
								label,
								message: messageProp,
							};
						} else {
							if (field) {
								fieldEl.classList.remove('validation-error');
								fieldEl.removeAttribute('title');
							}
						}
					}
				}
			);

			ret.isValid = Object.keys(ret.errors).length === 0;

			this.lastValidateResult = ret;

			return ret;
		},

		setFieldError(fieldName: string, errorMessage: string, defaultLabel?: string): void {
			if (!this.refs) return;

			const fieldComponent = this.refs[fieldName];

			if (fieldComponent) {
				const fieldEl = (fieldComponent as DefineComponent).$el as HTMLElement;

				fieldEl.classList.add('validation-error');
				fieldEl.setAttribute('title', errorMessage);
			}

			if (!this.lastValidateResult) {
				this.lastValidateResult = {
					isValid: false,
					errors: {}
				};
			}

			this.lastValidateResult.isValid = false;
			this.lastValidateResult.errors[fieldName] = {
				label: fieldComponent?.label || defaultLabel || fieldName,
				message: errorMessage
			};
		}

	},
});
</script>
<template>
	<div
		v-if="showConditions"
		:class="['bg-gray-100 rounded-sm', 'container-validation-summary', validationSummaryClass]"
	>
		<div class="font-bold mb-3 text-sm">
			Por favor verifique:
		</div>
		<ul
			v-if="lastValidateResult?.errors"
			class="list-disc list-inside px-4 text-xs"
		>
			<li
				v-for="(value, key) in lastValidateResult.errors"
				:key="`${key}_${value.label}_${value.message}`"
				class="my-2"
			>
				<span
					class="inline-block bg-gray-700 font-semibold mr-1 p-1 px-2 rounded text-white field-id"
				>
					{{ value.label }}
				</span>
				<span class="mr-1">➔</span>
				<span>{{ value.message }}</span>
			</li>
		</ul>
		<div />
	</div>
	<slot />
</template>
<style lang="scss" scoped>
.container-validation-summary {
    li {
        .field-id {
            padding: 0.2em 0.425em;
        }
    }
}
</style>
