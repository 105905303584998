/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosResponse } from 'axios';

import {
	Contact,
	ContactToSend,
	ContactNote,
	ContactPaginationResponse,
	Email,
	Phone,
	ClientsPreview,
	Attachment,
	Calculation,
	ReceivedEmail
} from '../../../models/crm';

const pageParams = '&per_page=50&page=1';

export default {

	// async attachmentAddMultiple({ contactId, attachments }) {

	//     const path = `/contacts/${contactId}/add_attachment.json`;

	//     const formData = new FormData();

	//     attachments.forEach(
	//         (item, index) => {
	//             formData.append(`attachment_${index}`, item.file);
	//         }
	//     );

	// return axios.post(path, formData, {
	//     headers: {
	//         'content-type': 'multipart/form-data',
	//     },
	// });

	// },

	async attachmentAdd({
		contactId, attachments
	}: {
		contactId: string | number,
		attachments: any[]
	}): Promise<AxiosResponse<any>> {
		const path = `/contacts/${contactId}/add_attachment.json`;
		const attachment = attachments[0];

		const fileContent = attachment.file
			? {
				file: attachment.file, name: attachment.filename
			} : {
				file: attachment, name: attachment.name
			};

		const formData = new FormData();
		formData.append('file', fileContent.file);
		formData.append('name', fileContent.name);

		return axios.post(path, formData, {
			headers: {
				'content-type': 'multipart/form-data',
			}
		});
	},

	async attachmentDelete({
		contactId, attachmentId
	}: {
		contactId: string | number,
		attachmentId: string | number
	}): Promise<AxiosResponse<any>> {
		const path = `/contacts/${contactId}/attachment/${attachmentId}.json`;

		return axios.delete(path, {});
	},

	async createContact(data : ContactToSend): Promise<Contact> {
		const path = '/clients.json';

		const {data: axiosData} = await axios.post(path,
			{
				...data, origin: 'crm'
			});
		return axiosData.client;
	},

	async delete(id : string | number): Promise<AxiosResponse<any>> {
		const path = `/contacts/${id}.json`;

		return axios.delete(path, {});
	},

	async getById(id : string | number): Promise<AxiosResponse<Contact, any>> {
		const path = `/contacts/${id}.json?${pageParams}`;

		return axios.get(path);
	},

	async getAttachmentsByContactId(id : string | number)
		: Promise<AxiosResponse<{ attachments: Attachment[] }, any>>
	{
		const path = `/contacts/${id}/attachments.json?${pageParams}`;

		return axios.get(path);
	},

	async getCalculationsByContactId(id : string | number)
		: Promise<AxiosResponse<{ calculations: Calculation[]}, any>>
	{
		const path = `/contacts/${id}/calculations.json?${pageParams}`;

		return axios.get(path);
	},

	async getAll(perPage : number, page : number, orderBy : string, filters: string)
		: Promise<AxiosResponse<ContactPaginationResponse, any>>
	{
		const orderByQuery = `&order=${orderBy}`;
		const path = `/contacts.json?per_page=${perPage}&page=${page}${orderByQuery}${filters}`;

		return axios.get(path);
	},

	async getAllClientsPreview(): Promise<AxiosResponse<ClientsPreview, any>> {
		const path = '/clients';

		return axios.get(path);
	},

	async getRelatedContacts(id: string | number)
		: Promise<AxiosResponse<{ related_contacts: Contact[]}>> {
		const path = `/contacts/${id}/related_contacts.json?${pageParams}`;

		return axios.get(path);
	},

	async notesGetByContactId({ contactId }: { contactId: string|number })
		: Promise<AxiosResponse<{ notes: ContactNote[] }, any>>
	{
		const path = `/contacts/${contactId}/notes.json?include=creator`;

		return axios.get(path);
	},

	async noteCreate({
		contactId, content
	}: {
		contactId: string | number,
		content: string
	}) : Promise<AxiosResponse<ContactNote, any>> {
		const path = `/contacts/${contactId}/notes.json`;

		return axios.post(path, { content });
	},

	async noteDelete({
		contactId, noteId
	}: {
		contactId: string | number,
		noteId: string | number
	}): Promise<AxiosResponse<any>> {
		const path = `/contacts/${contactId}/notes/${noteId}.json`;

		return axios.delete(path, {});
	},

	async noteUpdate({
		contactId, content, noteId
	}: {
		contactId: string | number,
		content: string,
		noteId: string | number
	}) : Promise<AxiosResponse<ContactNote, any>> {
		const path = `/contacts/${contactId}/notes/${noteId}.json`;

		return axios.patch(path, { content });
	},

	async searchByName(
		{
			name, orderBy, filters
		}: {name: string, orderBy: string, filters: string}
		, perPage = 50
		, nextPage = 1
	) : Promise<AxiosResponse<ContactPaginationResponse, any>> {
		const orderByQuery = `&order=${orderBy}`;
		const path =
			`/contacts.json?search=${name}&per_page=${perPage}&page=${nextPage}${orderByQuery}${filters}`;

		return axios.get(path);
	},

	async setMainEmail({
		contact, email
	}: {
		contact: Contact,
		email: Email
	}): Promise<void> {
		const newEmails = contact.emails?.map(emailFromList =>
			(emailFromList.email === email.email)
				? {
					...emailFromList, is_main: true
				}
				: (emailFromList.is_main)
					? {
						...emailFromList, is_main: false
					}
					: emailFromList
		);
		const newContact = {
			...contact,
			emails: newEmails
		};

		this.updateContact({
			data: newContact, id: contact.id
		});
	},

	async setMainPhone({
		contact, phone
	}: {
		contact: Contact,
		phone: Phone
	}): Promise<void> {
		const newPhones = contact.phones?.map(phoneFromList =>
			(phoneFromList.number === phone.number && phoneFromList.area_code === phone.area_code)
				? {
					...phoneFromList, is_main: true
				}
				: (phoneFromList.is_main)
					? {
						...phoneFromList, is_main: false
					}
					: phoneFromList
		);
		const newContact = {
			...contact,
			phones: newPhones
		};

		this.updateContact({
			data: newContact, id: contact.id
		});
	},

	async updateContact({
		data, id
	}: {
		data: ContactToSend,
		id: string | number
	}): Promise<AxiosResponse<Contact, any>> {
		const path = `/contacts/${id}.json`;

		return axios.patch(path, data);
	},

	async createClientFromLead(leadId: string | number, officeId: string | number): Promise<AxiosResponse<any>> {
		const path = `/leads/${leadId}/create_client`;

		return axios.post(path, { 'office_id': officeId });
	},

	async getReceivedEmails(contactId: string | number): Promise<ReceivedEmail[]> {
		const path = `/clients/${contactId}/received_emails`;
		const {data} = await axios.get(path);
		return [ ...data.received_emails.sort(
			(a: ReceivedEmail, b: ReceivedEmail) =>
				new Date(b.created_at).getTime() - new Date(a.created_at).getTime()) ];
	}

};
