<script lang="ts">
import Loading from '../../../../components/Loading/LoadingComponent.vue';
import InfoTooltip from '../../../../components/Form/InfoTooltip.vue';

// LegalCases components
import ViewModeFunnel from '../../components/LegalCases/ViewModeFunnel.vue';
import ViewModeList from '../../components/LegalCases/ViewModeList.vue';

import ModalLegalCase from './ModalLegalCase.vue';
import {
	mapState, mapActions
} from 'pinia';
import { useAccountStore } from '../../../../stores/account';
import { useLegalCasesStore } from '../../../../stores/models/crm/legalCases';

import {
	defineComponent, inject
} from 'vue';
import {
	EventBus, Funnel, LegalCasePaginationResponse, User
} from '../../../../models/crm';

export default defineComponent({
	components: {
		Loading,
		InfoTooltip,

		ViewModeFunnel,
		ViewModeList,

		ModalLegalCase,
	},
	beforeRouteEnter(to, _from, next) {
		next(
			async vm => {
				if ((to.name === 'specific-legal-case')||(to.name === 'create-legal-case')) {
					await (vm.$refs.modalLegalCase as typeof ModalLegalCase).show();
				}
			}
		);
	},
	data() {
		return {
			headerTooltip: '<p>Aqui você pode gerenciar os Casos de seus clientes.</p><div class="flex flex-col"><p class="font-semibold">Casos</p><p>Destinados à gestão de serviços consultivos e processos extrajudiciais</p></div>',

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			caseTypes: {} as Record<string, any>,

			searchStr: '' as string,

			loadedLegalCases: {
				legal_cases: [],
				total_count: 100,
				page_number: 1,
				per_page: 25,
			} as LegalCasePaginationResponse,

			requesting: {
				loadLegalCases: false,
				loadFunnelSteps: false,
				pagination: false,
			},

			order: {
				column: 'name',
				sort: 'asc'
			},

			filtersQuery: '',

			selectedFunnel: undefined as { label: string, id: string | number } | undefined,

			eventBus: inject<EventBus>('eventBus'),
		};
	},

	computed: {
		...mapState(useAccountStore, [ 'user' ]),
		...mapState(useLegalCasesStore, [
			'legalCasesList',
			'isRequestingLegalCases',
			'maxLegalCases',
			'searchedLegalCases',
			'searchMaxLegalCases'
		]),

		perPage(): number {
			const preferenceId = 'ui:data_table_legal_cases';
			const userPreferences = this.user?.preferences;
			const specificPreference = userPreferences && userPreferences[preferenceId]?.recordsPerPage;

			return (specificPreference as number | undefined) || 25;
		},

		isViewModeFunnel(): boolean {
			return this.$route.path.includes('fluxos');
		},

		isViewModeList(): boolean {
			return !this.isViewModeFunnel;
		},

		modalLegalCase_inData(): { assignees: User[]} {
			const assignees = this.user ? [ this.user ] : [];

			return {
				assignees,
			};
		},

		viewModeListLoadingConditions(): boolean {
			const {
				loadLegalCases,
			} = this.requesting;

			return !this.user?.id || loadLegalCases;
		},

		viewModeFunnelLoadingConditions(): boolean {
			const {
				loadLegalCases,
			} = this.requesting;

			return !this.user?.id || loadLegalCases;
		},

		urlParamId(): string {
			return typeof this.$route.params.id == 'string' ? this.$route.params.id : '';
		},

	},
	watch: {
		user: {
			deep: true,
			immediate: true,
			async handler(newUser, oldUser): Promise<void> {
				if (newUser?.preferences
					|| (newUser?.office_id && newUser.office_id != oldUser?.office_id)
				) {
					if (this.loadedLegalCases.legal_cases.length === 0 && !this.isRequestingLegalCases) {
						await this.loadLegalCases();
					}
				}
			}
		},
	},
	methods: {
		...mapActions(useLegalCasesStore, [ 'paginatedLoadLegalCases', 'searchLegalCasesByName' ]),

		async loadLegalCases(forceReload = false): Promise<void> {
			try {
				this.requesting.loadLegalCases = true;

				const orderBy = `${this.order.column}:${this.order.sort}`;

				if (this.legalCasesList.length > 0 && !forceReload) {
					this.loadedLegalCases = {
						...this.loadedLegalCases,
						total_count: this.maxLegalCases,
						legal_cases: this.legalCasesList,
					};

					this.requesting.loadLegalCases = false;
					return;
				}

				await this.paginatedLoadLegalCases({
					perPage: this.perPage,
					page: 1,
					filters: this.filtersQuery,
					orderBy
				});

				this.loadedLegalCases = {
					...this.loadedLegalCases,
					total_count: this.maxLegalCases,
					legal_cases: this.legalCasesList,
				};

				this.requesting.loadLegalCases = false;
			} catch (err) {
				console.error(err);

				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Erro ao carregar casos',
						type: 'error'
					}
				);
			}
		},
		async addLegalCasesToData(nextPage: number): Promise<void> {
			const orderBy = `${this.order.column}:${this.order.sort}`;

			if (this.loadedLegalCases.total_count > this.perPage * (nextPage - 1)
				&& this.loadedLegalCases.legal_cases.length === this.perPage * (nextPage - 1)
			) {
				this.requesting.pagination = true;

				try {
					await this.paginatedLoadLegalCases({
						perPage: this.perPage,
						page: nextPage,
						filters: this.filtersQuery,
						orderBy
					});

					this.loadedLegalCases.total_count = this.maxLegalCases;

					if (nextPage === 1) {
						this.loadedLegalCases = {
							...this.loadedLegalCases,
							legal_cases: []
						};
					}

					if (
						this.maxLegalCases > this.loadedLegalCases.legal_cases.length
					) {
						this.loadedLegalCases = {
							...this.loadedLegalCases,
							legal_cases: this.legalCasesList
						};

						return;
					}

					this.eventBus?.emit(
						'Toast/add',
						{
							content: 'Página máxima atingida',
							type: 'error'
						}
					);
				} catch (err) {
					console.error(err);

					this.eventBus?.emit(
						'Toast/add',
						{
							content: 'Erro ao fazer a paginação',
							type: 'error'
						}
					);
				} finally {
					this.requesting.pagination = false;
				}
			}
		},
		async sequentialCasesAddition(page: number): Promise<void> {
			const oldPage = Math.ceil(this.loadedLegalCases.legal_cases.length / this.perPage);
			if (page <= oldPage || this.loadedLegalCases.legal_cases.length % this.perPage !== 0) {
				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Erro ao fazer a paginação',
						type: 'error'
					}
				);

				return;
			}

			for (let i = oldPage + 1;i <= page;++i) {
				await this.addLegalCasesToData(i);
			}
		},
		async searchLegalCases(searchStr: string): Promise<void> {
			this.searchStr = searchStr;

			if (this.user?.office_id) {
				const orderBy = `${this.order.column}:${this.order.sort}`;

				try {
					if (searchStr.length === 0) {
						await this.paginatedLoadLegalCases({
							perPage: 50,
							page: 1,
							filters: this.filtersQuery,
							orderBy
						});

						if (searchStr === this.searchStr) this.loadedLegalCases = {
							...this.loadedLegalCases,
							legal_cases: this.legalCasesList,
							total_count: this.maxLegalCases
						};

						return;
					}

					await this.searchLegalCasesByName(searchStr, orderBy, this.filtersQuery, 1, 50);
					if (searchStr === this.searchStr) this.loadedLegalCases = {
						...this.loadedLegalCases,
						legal_cases: this.searchedLegalCases,
						total_count: this.searchMaxLegalCases
					};
				} catch (err) {
					console.error(err);
					this.eventBus?.emit(
						'Toast/add',
						{
							content: 'Erro ao pesquisar caso',
							type: 'error'
						}
					);
				}
			}
		},

		async orderApply(column: string, sort: string): Promise<void> {
			this.order = {
				column, sort
			};

			await this.reloadLegalCases('Erro ao fazer a ordenação');
		},

		async filtersApply(filtersQuery: string): Promise<void> {
			this.filtersQuery = filtersQuery;

			await this.reloadLegalCases('Erro ao aplicar o filtro');
		},

		async reloadLegalCases(errorMessage: string): Promise<void> {
			this.requesting.pagination = true;

			const orderBy = `${this.order.column}:${this.order.sort}`;

			try {
				await this.paginatedLoadLegalCases({
					perPage: this.perPage,
					page: 1,
					orderBy,
					filters: this.filtersQuery
				});

				this.loadedLegalCases = {
					...this.loadedLegalCases,
					legal_cases: this.legalCasesList,
					total_count: this.maxLegalCases
				};
			} catch (err) {
				console.error(err);

				this.eventBus?.emit(
					'Toast/add',
					{
						content: errorMessage,
						type: 'error'
					}
				);
			} finally {
				this.requesting.pagination = false;
			}
		},

		// Events

		buttonCreateClick(): void {
			this.$router.push({ name: 'create-legal-case'});
		},

		buttonTabViewModeClick(_e: Event, isList?: boolean): void {
			if (!isList) {
				this.$router.push({ name: 'funnels'});

				return;
			}

			this.selectedFunnel = undefined;

			this.$router.push({ name: 'legal-cases'});
		},

		modalLegalCase_buttonCancelClick(): void {
			(this.$refs.modalLegalCase as typeof ModalLegalCase).show = false;
		},

		async modalLegalCase_caseCreated(): Promise<void> {
			await this.loadLegalCases(true);
		},

		async modalLegalCase_caseUpdated(): Promise<void> {
			await this.loadLegalCases(true);
		},

		viewModeList_caseDeleted(): void {
			this.loadLegalCases();
		},

		viewModeList_savedConfiguration(): void {
			this.loadLegalCases(true);
		},

		viewModeFunnel_selectedFunnel(funnel: Funnel): void {
			this.selectedFunnel = {
				label: funnel.name, id: funnel.id
			};
		}
	},
});
</script>

<template>
	<section class="feature">
		<div class="pb-4">
			<router-link
				:to="{ name: 'crm-panel' }"
				class="text-gray-500 font-semibold text-xs hover:underline mb-2"
			>
				Gestão
			</router-link>
			<svg
				class="inline h-4 -rotate-90 text-gray-400"
				fill="currentColor"
				viewBox="0 0 20 20"
				xmlns="http://www.w3.org/2000/svg"
			><path
				fill-rule="evenodd"
				d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
				clip-rule="evenodd"
			/></svg>
			<span class="text-blue-500 font-semibold text-xs">Casos</span>
		</div>
		<div class="flex items-center justify-between">
			<div class="flex-1 flex items-center gap-4">
				<div class="flex m-0 p-0 items-center">
					<h1>Casos</h1>
					<InfoTooltip
						:content="headerTooltip"
					/>
				</div>
				<button
					v-if="!requesting.loadLegalCases"
					class="btn btn--primary h-auto min-h-0 normal-case !px-2 !py-1 rounded text-white"
					@click="buttonCreateClick"
				>
					<svg
						class="inline w-6 h-6"
						fill="none"
						stroke="currentColor"
						viewBox="0 0 24 24"
						xmlns="http://www.w3.org/2000/svg"
					><path
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="2"
						d="M12 6v6m0 0v6m0-6h6m-6 0H6"
					/></svg>
					<span>Novo</span>
				</button>
			</div>
			<div class="flex-1">
				<fieldset
					:class="['text-right', { 'opacity-50': requesting.loadLegalCases }]"
					:disabled="requesting.loadLegalCases"
				>
					<button
						id="btn-view-mode-list"
						:class="['btn btn--primary rounded-md', { 'btn--ghost': !isViewModeList }]"
						@click="buttonTabViewModeClick($event, true)"
					>
						<svg
							class="inline h-6 w-6"
							fill="none"
							stroke="currentColor"
							stroke-width="1.5"
							viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg"
							aria-hidden="true"
						>
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
							/>
						</svg>
						<span class="ml-2">Modo Lista</span>
					</button>
					<button
						id="btn-view-mode-funnel"
						:class="['btn btn--primary ml-2 rounded-md', { 'btn--ghost': !isViewModeFunnel }]"
						@click="buttonTabViewModeClick($event, false)"
					>
						<svg
							class="inline h-6 w-6"
							fill="none"
							stroke="currentColor"
							stroke-width="1.5"
							viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg"
							aria-hidden="true"
						>
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								d="M9 4.5v15m6-15v15m-10.875 0h15.75c.621 0 1.125-.504 1.125-1.125V5.625c0-.621-.504-1.125-1.125-1.125H4.125C3.504 4.5 3 5.004 3 5.625v12.75c0 .621.504 1.125 1.125 1.125z"
							/>
						</svg>
						<span class="ml-2">Modo Fluxo de Casos</span>
					</button>
				</fieldset>
			</div>
		</div>

		<template v-if="isViewModeList">
			<Loading
				v-if="viewModeListLoadingConditions"
				class="opacity-75 py-14 text-center"
				icon-css-class="h-10 w-10 mr-3"
				text-css-class="text-2xl"
			/>
			<ViewModeList
				v-else
				class="mt-4"
				:legal-cases="loadedLegalCases"
				:requesting-pagination="requesting.pagination"
				:selected-funnel="selectedFunnel"

				@case-deleted="viewModeList_caseDeleted"
				@saved-configuration="viewModeList_savedConfiguration"
				@next-page="addLegalCasesToData"
				@go-to-page="sequentialCasesAddition"
				@search-changed="searchLegalCases"
				@order-apply="orderApply"
				@filter-changed="filtersApply"
			/>
		</template>

		<template v-if="isViewModeFunnel">
			<Loading
				v-if="viewModeFunnelLoadingConditions"
				class="opacity-75 py-14 text-center"
				icon-css-class="h-10 w-10 mr-3"
				text-css-class="text-2xl"
			/>
			<ViewModeFunnel
				v-else
				@selected-funnel="viewModeFunnel_selectedFunnel"
			/>
		</template>

		<ModalLegalCase
			ref="modalLegalCase"
			:back-route="{name: 'legal-cases'}"
			:case-id="urlParamId"
			:in-data="modalLegalCase_inData"

			@case-created="modalLegalCase_caseCreated"
			@case-updated="modalLegalCase_caseUpdated"
		/>

	</section>
</template>

<style lang="scss" scoped>
    .container-editor {
        :deep(.tox-tinymce) {
            border-color: #e5e7eb;
            border-radius: 0;
            border-width: 1px;
        }
    }
</style>
