<script lang="ts">
import {
	defineComponent, inject
} from 'vue';

import {
	EventBus,
	Helpers, Lodash, Luxon
} from '../../../../models/crm';

import serviceDashboard from '../../../../services/API/financial/dashboard';

import Chart from '../../../../components/Chart/ChartComponent.vue';
import FiltersButtonsGroup from '../../../../components/FiltersButtonsGroup/FiltersButtonsGroup.vue';
import Loading from '../../../../components/Loading/LoadingComponent.vue';
import {
	CategoriesResult,
	CategoriesSumResponse,
	CostCentersResult,
	CostCentersSumResponse,
	DataConsolidated, ItemFilterGroupByType, SingleDataConsolidated
} from '../../../../models/financial';
import { ChartData } from 'chart.js';
import { ChartOptions } from 'chart.js';
import { AxiosResponse } from 'axios';

export default defineComponent({
	components: {
		Chart,
		FiltersButtonsGroup,
		Loading,
	},
	data() {
		return {
			helpers: inject<Helpers>('helpers'),
			lodash: inject<Lodash>('lodash'),
			luxon: inject<Luxon>('luxon'),
			eventBus: inject<EventBus>('eventBus'),

			// Injects
			isViewTypeExpense: inject<boolean>('isViewTypeExpense'),
			isViewTypeIncome: inject<boolean>('isViewTypeIncome'),
			viewDictionary: inject<Record<string, string>>('viewDictionary'),
			viewType: inject<'income'|'expense'>('viewType'),

			dataConsolidated: {} as DataConsolidated,

			filterGroupBy: 'categories' as 'categories' | 'cost_centers',

			itemsFilterGroupByType: [
				{
					label: 'Por Categoria', value: 'categories'
				},
				{
					label: 'Por Centro de Custo', value: 'cost_centers'
				},
			] as ItemFilterGroupByType[],

			requesting: {
				loadData: false,
			},
		};
	},
	computed: {
		dataConsolidatedAsArray(): SingleDataConsolidated[] {
			return Object.values(this.dataConsolidated);
		},

		isCategorySelected(): boolean {
			return this.filterGroupBy === 'categories';
		},
	},
	methods: {
		getChartDataAndOptions(
			col: SingleDataConsolidated, filterGroupBy: 'categories' | 'cost_centers', viewType: 'income' | 'expense'
		): { data: ChartData<'bar'>, options: ChartOptions<'bar'>} | undefined {
			const data = this.getFilteredRowsSortedAndFiltered(
				col, filterGroupBy, viewType
			);
			if (!data) return;

			const nameKey = filterGroupBy === 'categories' ? 'category' : 'cost_center';
			const typeKey = viewType === 'income' ? 'incomes_sum' : 'expenses_sum';


			const labels = data.map(item => {
				if (nameKey === 'category') {
					return (item as CategoriesResult).category;
				}
				return (item as CostCentersResult).cost_center;
			});

			const datasets = [
				{
					data: data.map(item => item[typeKey]),
					backgroundColor: viewType === 'income' ? 'rgba(0, 204, 51, 0.6)' : 'rgba(255, 51, 51, 0.6)',
					datalabels: {
						display: false,
					}
				},
			];

			return {
				data: {
					labels,
					datasets,
				},
				options: {
					animation: {
						duration: 0,
					},
					maintainAspectRatio: false,
					plugins: {
						legend: {
							display: false,
						},
					},
					responsive: true,
					scales: {
						x: {
							stacked: true
						},
						y: {
							stacked: true
						},
					},
				},
			};
		},

		getCategoriesDataFromApi(yearmonth: string): Promise<AxiosResponse<CategoriesSumResponse>> {
			return serviceDashboard.getDashboardCategoriesSum({ yearmonth });
		},

		getCostCenterDataFromApi(yearmonth: string): Promise<AxiosResponse<CostCentersSumResponse>> {
			return serviceDashboard.getDashboardCostCentersSum({ yearmonth });
		},

		getFilteredRowsSortedAndFiltered(
			col: SingleDataConsolidated, filterGroupBy: 'categories' | 'cost_centers', viewType: 'income' | 'expense'
		): (CategoriesResult | CostCentersResult)[] | undefined {
			const data = col[filterGroupBy];

			const nameKey = filterGroupBy === 'categories' ? 'category' : 'cost_center';
			const typeKey = viewType === 'income' ? 'incomes_sum' : 'expenses_sum';

			// Only non zero rows are returned
			const dataNonZero = data.filter(item => Number(item[typeKey]) > 0);
			const dataSorted = this.lodash?.sortBy(dataNonZero, [ nameKey ]);

			return dataSorted;
		},

		getMonthSum(
			col: SingleDataConsolidated, filterGroupBy: 'categories' | 'cost_centers', viewType: 'income' | 'expense'
		): number {
			const data = col[filterGroupBy];
			const typeKey = viewType === 'income' ? 'incomes_sum' : 'expenses_sum';
			const values = data.map(item => item[typeKey]).reduce((acc, curr) => acc + Number(curr), 0);
			return values;
		},

		async loadData(): Promise<void> {
			this.requesting.loadData = true;

			try {
				const now = this.luxon?.now();
				if (!now) throw new Error('Luxon not injected');

				const format = 'yyyyMM';
				const currentYearMonth = now.toFormat(format);
				const previousYearMonth = now.minus({ month: 1}).toFormat(format);
				const nextYearMonth = now.plus({ month: 1}).toFormat(format);
				const arrYearmonths = [ previousYearMonth, currentYearMonth, nextYearMonth ];

				for (const yearmonth of arrYearmonths) {
					this.dataConsolidated[yearmonth] = {
						categories: [],
						cost_centers: [],
					};

					await this.getCategoriesDataFromApi(yearmonth).then(
						response => {
							const { data } = response;
							if (!this.dataConsolidated[yearmonth]) return;

							(this.dataConsolidated[yearmonth] as SingleDataConsolidated).categories
								= data.categories;
						}
					);

					await this.getCostCenterDataFromApi(yearmonth).then(
						response => {
							const { data } = response;
							if (!this.dataConsolidated[yearmonth]) return;

							(this.dataConsolidated[yearmonth] as SingleDataConsolidated).cost_centers
								= data.cost_centers;
						}
					);
				}
			} catch (err) {
				console.error(err);

				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Erro ao carregar dados',
						type: 'error'
					}
				);
			} finally {
				this.requesting.loadData = false;
			}
		},

		// Events

		buttonFilterClick(item: ItemFilterGroupByType): void {
			this.filterGroupBy = item.value;
		},
	},
	beforeMount() {
		this.loadData();
	},
});
</script>

<template>

	<hr>

	<Loading
		v-if="requesting.loadData"

		class="opacity-75 py-14 text-center"
		icon-css-class="h-10 w-10 mr-3"
		text-css-class="text-2xl"
	/>
	<template v-else>

		<div class="bg-white/50 flex items-center mt-4 p-3">
			<span class="text-sm">Agrupar por:</span>
			<span class="ml-2">
				<FiltersButtonsGroup
					button-class="!p-1.5 whitespace-nowrap"
					:items="itemsFilterGroupByType"
					:selected-value="filterGroupBy"

					@clicked-filter-button="buttonFilterClick"
				/>
			</span>
		</div>

		<div class="gap-5 grid grid-cols-3 mt-6">

			<div v-for="(col, colIndex) in dataConsolidatedAsArray"
				:key="colIndex"
			>
				<h2 class="font-semibold">
					<span v-if="colIndex === 0">Mês anterior</span>
					<span v-else-if="colIndex === 1">Mês atual</span>
					<span v-else-if="colIndex === 2">Próximo mês</span>
				</h2>
				<div :class="[
					'bg-white flex justify-between mt-2 p-3 rounded text-sm',
					{ 'text-red-500': isViewTypeExpense },
					{ 'text-green-600': isViewTypeIncome }]
				"
				>
					<div class="font-semibold">Total de {{ viewDictionary?.Entries || 'Entradas'}}</div>
					<div>
						{{ viewType && helpers?.getFormattedCurrencyFromNumber(
							getMonthSum(col, filterGroupBy, viewType)
						) }}
					</div>
				</div>
				<div class="bg-white h-64 mt-2 p-4 rounded">
					<Chart v-if="viewType && getChartDataAndOptions(col, filterGroupBy, viewType)?.data"
						:data="
							(viewType
								&& (getChartDataAndOptions(col, filterGroupBy, viewType)?.data as ChartData<'bar'>)
							)"
						:options="
							(viewType && getChartDataAndOptions(col, filterGroupBy, viewType)?.options) || {}
						"
					/>
				</div>
				<div class="bg-white mt-2 rounded">
					<table class="table text-xs w-full">
						<thead>
							<tr>
								<th class="bg-white p-2 text-left text-blue-500">
									<span v-if="isCategorySelected">Categoria</span>
									<span v-else>Centro de Custo</span>
								</th>
								<th class="bg-white p-2 text-right text-blue-500">
									{{ viewDictionary?.Entries || 'Entradas' }}
								</th>
							</tr>
						</thead>
						<tbody v-if="viewType">
							<tr v-for="
									(row, rowIndex) in getFilteredRowsSortedAndFiltered(col, filterGroupBy, viewType)
								"
								:key="rowIndex"
							>
								<td class="p-2 text-left">
									{{ filterGroupBy === 'categories'
										? (row as CategoriesResult).category
										: (row as CostCentersResult).cost_center
									}}
								</td>
								<td class="p-2 text-right">
									<span v-if="isViewTypeExpense">
										{{ helpers?.getFormattedCurrencyFromNumber(row.expenses_sum) }}
									</span>
									<span v-else>{{ helpers?.getFormattedCurrencyFromNumber(row.incomes_sum) }}</span>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>

		</div>

	</template>

</template>

<style lang="scss" scoped>
</style>