<script lang="ts">
import {
	defineComponent, inject
} from 'vue';

import {
	EventBus, Helpers, Luxon
} from '../../../../models/crm';

import Loading from '../../../../components/Loading/LoadingComponent.vue';
import IncomesExpensesList from '../../components/Reports/IncomesExpensesList.vue';
import IncomesExpensesChart from '../../components/Reports/IncomesExpensesChart.vue';


export default defineComponent({
	components: {
		Loading,

		IncomesExpensesList,
		IncomesExpensesChart,
	},
	data() {
		return {

			eventBus: inject<EventBus>('eventBus'),
			helpers: inject<Helpers>('helpers'),
			luxon: inject<Luxon>('luxon'),

			filters: {
				model: {},
				applied: {},
				labels: {},
			},
			requesting: {
				loadEntries: false,
			},

		};
	},
	computed: {
	},
	methods: {
	},
	beforeMount() {
	},
});
</script>

<template>
	<section class="feature">

		<h1 class="mb-4 mt-1">Relatórios</h1>

		<Loading
			v-if="requesting.loadEntries"

			class="opacity-75 py-14 text-center"
			icon-css-class="h-10 w-10 mr-3"
			text-css-class="text-2xl"
		/>
		<template v-else>
			<IncomesExpensesList />
			<IncomesExpensesChart />
		</template>

	</section>
</template>

<style lang="scss" scoped>
:deep(.component-data-table) {
    .component-data-table-basic {
		tbody, tfoot {
            tr {
				&:not(.no-records):hover {
					cursor: default;
					td {
						background-color: #fff;
					}
				}

				.cell-entry-amount {
					padding: 0 !important;
				}
			}
		}
	}
}
</style>