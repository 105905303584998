<script lang="ts">
import {
	defineComponent, inject
} from 'vue';
import {
	EventBus, Helpers, Luxon
} from '../../../../models/crm';

import Chart from '../../../../components/Chart/ChartComponent.vue';
import Loading from '../../../../components/Loading/LoadingComponent.vue';
import InfoTooltip from '../../../../components/Form/InfoTooltip.vue';

import {
	DashboardMonthsSummary, DashboardTransactions
} from '../../../../models/financial';
import { useDashboardStore } from '../../../../stores/models/financial/dashboard';
import {
	mapActions, mapState
} from 'pinia';
import {
	ChartData, ChartOptions
} from 'chart.js';

export default defineComponent({
	components: {
		Chart,
		Loading,
		InfoTooltip
	},
	data() {
		return {

			eventBus: inject<EventBus>('eventBus'),
			luxon: inject<Luxon>('eventBus'),
			helpers: inject<Helpers>('helpers'),
			envVars: inject<Record<string, string>>('envVars'),

			requesting: {
				loadCounts: false,
			},

		};
	},
	computed: {
		...mapState(useDashboardStore, [ 'amounts' ]),

		allCounts(): DashboardTransactions | null {
			return this.getCounts()?.transactions_due || null;
		},

		paidCounts(): DashboardTransactions | null {
			return this.getCounts()?.transactions_paid || null;
		},

		unpaidCounts(): DashboardTransactions | null {
			return this.getCounts()?.transactions_unpaid_due || null;
		},

		expiredCounts(): DashboardTransactions | null {
			return this.getCounts()?.transactions_unpaid_overdue || null;
		},

		allMonthChart(): {data: ChartData<'bar'> | null, options: ChartOptions} {
			return {
				data: this.allCounts && {
					labels: [ 'Entradas', 'Saídas' ],
					datasets: [
						{
							data: [
								this.allCounts?.amount?.Income || 0,
								this.allCounts?.amount?.Expense || 0
							],
							backgroundColor: [
								'rgba(0, 204, 51, 0.6)',
								'rgba(255, 51, 51, 0.6)',
							],
							datalabels: {
								display: false,
							}
						},
					],
				},
				options: {
					plugins: {
						legend: {
							display: false,
						},
					},
				},
			};
		},

		unpaidMonthChart(): {data: ChartData<'bar'> | null, options: ChartOptions} {
			return {
				data: this.unpaidCounts && {
					labels: [ 'Entradas', 'Saídas' ],
					datasets: [
						{
							data: [
								this.unpaidCounts?.amount?.Income || 0,
								this.unpaidCounts?.amount?.Expense || 0
							],
							backgroundColor: [
								'rgba(0, 204, 51, 0.6)',
								'rgba(255, 51, 51, 0.6)',
							],
							datalabels: {
								display: false,
							}
						},
					],
				},
				options: {
					plugins: {
						legend: {
							display: false,
						},
					},
				},
			};
		},

		paidMonthChart(): {data: ChartData<'bar'> | null, options: ChartOptions} {
			return {
				data: this.paidCounts && {
					labels: [ 'Entradas', 'Saídas' ],
					datasets: [
						{
							data: [
								this.paidCounts?.amount?.Income || 0,
								this.paidCounts?.amount?.Expense || 0
							],
							backgroundColor: [
								'rgba(0, 204, 51, 0.6)',
								'rgba(255, 51, 51, 0.6)',
							],
							datalabels: {
								display: false,
							}
						},
					],
				},
				options: {
					plugins: {
						legend: {
							display: false,
						},
					},
				},
			};
		},

		expiredMonthChart(): {data: ChartData<'bar'> | null, options: ChartOptions} {
			return {
				data: this.expiredCounts && {
					labels: [ 'Entradas', 'Saídas' ],
					datasets: [
						{
							data: [
								this.expiredCounts?.amount?.Income || 0,
								this.expiredCounts?.amount?.Expense || 0
							],
							backgroundColor: [
								'rgba(0, 204, 51, 0.6)',
								'rgba(255, 51, 51, 0.6)',
							],
							datalabels: {
								display: false,
							}
						},
					],
				},
				options: {
					plugins: {
						legend: {
							display: false,
						},
					},
				},
			};
		},
	},
	methods: {
		...mapActions(useDashboardStore, [ 'fetchMonthsSummary', 'getMonthsSummary' ]),

		getCounts(): DashboardMonthsSummary | undefined {
			const maybeDate = this.luxon?.now ? this.luxon.now() : new Date();

			if (!maybeDate) {
				return;
			}

			return this.getMonthsSummary(maybeDate);
		},

		// Events

		buttonSupportClick(): void {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			window.Intercom('boot', {
				app_id: this.envVars?.VITE_INTERCOM_ID,
			});

			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			window.Intercom('show');
		},

	},
	async beforeMount() {
		if (this.getCounts()) {
			return;
		}

		const maybeDate = this.luxon?.now ? this.luxon.now() : new Date();

		this.requesting.loadCounts = true;

		await this.fetchMonthsSummary(maybeDate);

		this.requesting.loadCounts = false;
	},
});
</script>

<template>
	<section class="feature">

		<h1 class="mt-1">
			Finanças
		</h1>

		<div class="mt-5 text-2xl">
			Bem-vindo ao CJ Finanças!
		</div>

		<div class="leading-6 mt-2 opacity-50 text-xl w-1/2">
			Finanças é o módulo que te ajuda com a rotina de gestão financeira do escritório.
		</div>

		<div class="m-0 p-0 mb-2 mt-8 flex">
			<span class="leading-6 text-xl font-semibold">
				Total do mês
			</span>
			<InfoTooltip
				content="Todas as entradas e saídas com vencimento neste mês, pagas ou não"
				sizing-class="!h-5 !w-5"
			/>
		</div>

		<div class="grid grid-cols-3 gap-5">

			<div class="bg-base-100 card rounded-md w-full">
				<div class="card-body p-4">
					<div class="text-lg text-center">
						Entradas do mês
					</div>
					<hr>
					<Loading
						v-if="!allCounts || requesting.loadCounts"
						class="py-10 text-center"
						icon-css-class="h-11 w-11"
						text-css-class="hidden"
					/>
					<div
						v-else-if="allCounts"
						class="flex font-light h-48 items-center justify-center text-4xl"
					>
						<span class="text-green-600">
							{{ helpers?.getFormattedCurrencyFromNumber(allCounts.amount?.Income || 0) }}
						</span>
					</div>
					<router-link
						class="btn btn--ghost btn--primary"
						title="Cadastrar e visualizar os valores a receber"
						:to="{name: 'incomes'}"
					>
						Ir para Entradas
					</router-link>
				</div>
			</div>

			<div class="bg-base-100 card rounded-md w-full">
				<div class="card-body p-4">
					<div class="text-lg text-center">
						Saídas do mês
					</div>
					<hr>
					<Loading
						v-if="!allCounts || requesting.loadCounts"
						class="py-10 text-center"
						icon-css-class="h-11 w-11"
						text-css-class="hidden"
					/>
					<div
						v-else-if="allCounts"
						class="flex font-light h-48 items-center justify-center text-4xl"
					>
						<span class="text-red-500">
							{{ helpers?.getFormattedCurrencyFromNumber(allCounts.amount?.Expense || 0) }}
						</span>
					</div>
					<router-link
						class="btn btn--ghost btn--primary"
						title="Cadastrar e visualizar os valores a pagar"
						:to="{name: 'expenses'}"
					>
						Ir para Saídas
					</router-link>
				</div>
			</div>

			<div class="bg-base-100 card rounded-md w-full">
				<div class="card-body p-4">
					<div class="text-lg text-center tracking-tight">
						Entradas do mês x Saídas do mês
					</div>
					<hr>
					<Loading
						v-if="!allCounts || requesting.loadCounts"
						class="py-10 text-center"
						icon-css-class="h-11 w-11"
						text-css-class="hidden"
					/>
					<div
						v-else-if="allCounts && allMonthChart.data"
						class="flex font-light h-48 items-center justify-center"
					>
						<Chart
							:data="allMonthChart.data"
							:options="allMonthChart.options"
						/>
					</div>
					<router-link
						class="btn btn--ghost btn--primary"
						title="Visualizar os relatórios do sistema - Movimento de Caixa e gráfico de entradas e saídas por mês"
						:to="{name: 'reports'}"
					>
						Ir para Relatórios
					</router-link>
				</div>
			</div>
		</div>

		<div class="m-0 p-0 mb-2 mt-8 flex">
			<span class="leading-6 text-xl font-semibold">
				Realizados do mês
			</span>
			<InfoTooltip
				content="Todas as entradas e saídas com vencimento neste mês e pagas (pagas no prazo ou depois)"
				sizing-class="!h-5 !w-5"
			/>
		</div>

		<div class="grid grid-cols-3 gap-5">

			<div class="bg-base-100 card rounded-md w-full">
				<div class="card-body p-4">
					<div class="text-lg text-center">
						Entradas recebidas
					</div>
					<hr>
					<Loading
						v-if="!paidCounts || requesting.loadCounts"
						class="py-10 text-center"
						icon-css-class="h-11 w-11"
						text-css-class="hidden"
					/>
					<div
						v-else-if="paidCounts"
						class="flex font-light h-48 items-center justify-center text-4xl"
					>
						<span class="text-green-600">
							{{ helpers?.getFormattedCurrencyFromNumber(paidCounts.amount?.Income || 0) }}
						</span>
					</div>
					<router-link
						class="btn btn--ghost btn--primary"
						title="Cadastrar e visualizar os valores a receber"
						:to="{name: 'incomes'}"
					>
						Ir para Entradas
					</router-link>
				</div>
			</div>

			<div class="bg-base-100 card rounded-md w-full">
				<div class="card-body p-4">
					<div class="text-lg text-center">
						Saídas pagas
					</div>
					<hr>
					<Loading
						v-if="!paidCounts || requesting.loadCounts"
						class="py-10 text-center"
						icon-css-class="h-11 w-11"
						text-css-class="hidden"
					/>
					<div
						v-else-if="paidCounts"
						class="flex font-light h-48 items-center justify-center text-4xl"
					>
						<span class="text-red-500">
							{{ helpers?.getFormattedCurrencyFromNumber(paidCounts.amount?.Expense || 0) }}
						</span>
					</div>
					<router-link
						class="btn btn--ghost btn--primary"
						title="Cadastrar e visualizar os valores a pagar"
						:to="{name: 'expenses'}"
					>
						Ir para Saídas
					</router-link>
				</div>
			</div>

			<div class="bg-base-100 card rounded-md w-full">
				<div class="card-body p-4">
					<div class="text-lg text-center tracking-tight">
						Entradas recebidas x Saídas pagas
					</div>
					<hr>
					<Loading
						v-if="!paidCounts || requesting.loadCounts"
						class="py-10 text-center"
						icon-css-class="h-11 w-11"
						text-css-class="hidden"
					/>
					<div
						v-else-if="paidCounts && paidMonthChart.data"
						class="flex font-light h-48 items-center justify-center"
					>
						<Chart
							:data="paidMonthChart.data"
							:options="paidMonthChart.options"
						/>
					</div>
					<router-link
						class="btn btn--ghost btn--primary"
						title="Visualizar os relatórios do sistema - Movimento de Caixa e gráfico de entradas e saídas por mês"
						:to="{name: 'reports'}"
					>
						Ir para Relatórios
					</router-link>
				</div>
			</div>
		</div>

		<div class="m-0 p-0 mb-2 mt-8 flex">
			<span class="leading-6 text-xl font-semibold">
				Previsto até o fim do mês
			</span>
			<InfoTooltip
				content="Todas as entradas e saídas com vencimento neste mês que ainda não foram pagas, mas ainda não estão vencidas (data de vencimento não passou)"
				sizing-class="!h-5 !w-5"
			/>
		</div>

		<div class="grid grid-cols-3 gap-5">

			<div class="bg-base-100 card rounded-md w-full">
				<div class="card-body p-4">
					<div class="text-lg text-center">
						Entradas previstas
					</div>
					<hr>
					<Loading
						v-if="!unpaidCounts || requesting.loadCounts"
						class="py-10 text-center"
						icon-css-class="h-11 w-11"
						text-css-class="hidden"
					/>
					<div
						v-else-if="unpaidCounts"
						class="flex font-light h-48 items-center justify-center text-4xl"
					>
						<span class="text-green-600">
							{{ helpers?.getFormattedCurrencyFromNumber(unpaidCounts.amount?.Income || 0) }}
						</span>
					</div>
					<router-link
						class="btn btn--ghost btn--primary"
						title="Cadastrar e visualizar os valores a receber"
						:to="{name: 'incomes'}"
					>
						Ir para Entradas
					</router-link>
				</div>
			</div>

			<div class="bg-base-100 card rounded-md w-full">
				<div class="card-body p-4">
					<div class="text-lg text-center">
						Saídas previstas
					</div>
					<hr>
					<Loading
						v-if="!unpaidCounts || requesting.loadCounts"
						class="py-10 text-center"
						icon-css-class="h-11 w-11"
						text-css-class="hidden"
					/>
					<div
						v-else-if="unpaidCounts"
						class="flex font-light h-48 items-center justify-center text-4xl"
					>
						<span class="text-red-500">
							{{ helpers?.getFormattedCurrencyFromNumber(unpaidCounts.amount?.Expense || 0) }}
						</span>
					</div>
					<router-link
						class="btn btn--ghost btn--primary"
						title="Cadastrar e visualizar os valores a pagar"
						:to="{name: 'expenses'}"
					>
						Ir para Saídas
					</router-link>
				</div>
			</div>

			<div class="bg-base-100 card rounded-md w-full">
				<div class="card-body p-4">
					<div class="text-lg text-center tracking-tight">
						Entradas previstas x Saídas previstas
					</div>
					<hr>
					<Loading
						v-if="!unpaidCounts || requesting.loadCounts"
						class="py-10 text-center"
						icon-css-class="h-11 w-11"
						text-css-class="hidden"
					/>
					<div
						v-else-if="unpaidCounts && unpaidMonthChart.data"
						class="flex font-light h-48 items-center justify-center"
					>
						<Chart
							:data="unpaidMonthChart.data"
							:options="unpaidMonthChart.options"
						/>
					</div>
					<router-link
						class="btn btn--ghost btn--primary"
						title="Visualizar os relatórios do sistema - Movimento de Caixa e gráfico de entradas e saídas por mês"
						:to="{name: 'reports'}"
					>
						Ir para Relatórios
					</router-link>
				</div>
			</div>
		</div>

		<div class="m-0 p-0 mb-2 mt-8 flex">
			<span class="leading-6 text-xl font-semibold">
				Vencidas no mês
			</span>
			<InfoTooltip
				content="Todas as entradas e saídas com vencimento neste mês que ainda não foram pagas e que a data do vencimento já passou"
				sizing-class="!h-5 !w-5"
			/>
		</div>

		<div class="grid grid-cols-3 gap-5">

			<div class="bg-base-100 card rounded-md w-full">
				<div class="card-body p-4">
					<div class="text-lg text-center">
						Entradas vencidas
					</div>
					<hr>
					<Loading
						v-if="!expiredCounts || requesting.loadCounts"
						class="py-10 text-center"
						icon-css-class="h-11 w-11"
						text-css-class="hidden"
					/>
					<div
						v-else-if="expiredCounts"
						class="flex font-light h-48 items-center justify-center text-4xl"
					>
						<span class="text-green-600">
							{{ helpers?.getFormattedCurrencyFromNumber(expiredCounts.amount?.Income || 0) }}
						</span>
					</div>
					<router-link
						class="btn btn--ghost btn--primary"
						title="Cadastrar e visualizar os valores a receber"
						:to="{name: 'incomes'}"
					>
						Ir para Entradas
					</router-link>
				</div>
			</div>

			<div class="bg-base-100 card rounded-md w-full">
				<div class="card-body p-4">
					<div class="text-lg text-center">
						Saídas vencidas
					</div>
					<hr>
					<Loading
						v-if="!expiredCounts || requesting.loadCounts"
						class="py-10 text-center"
						icon-css-class="h-11 w-11"
						text-css-class="hidden"
					/>
					<div
						v-else-if="expiredCounts"
						class="flex font-light h-48 items-center justify-center text-4xl"
					>
						<span class="text-red-500">
							{{ helpers?.getFormattedCurrencyFromNumber(expiredCounts.amount?.Expense || 0) }}
						</span>
					</div>
					<router-link
						class="btn btn--ghost btn--primary"
						title="Cadastrar e visualizar os valores a pagar"
						:to="{name: 'expenses'}"
					>
						Ir para Saídas
					</router-link>
				</div>
			</div>

			<div class="bg-base-100 card rounded-md w-full">
				<div class="card-body p-4">
					<div class="text-lg text-center tracking-tight">
						Entradas vencidas x Saídas vencidas
					</div>
					<hr>
					<Loading
						v-if="!expiredCounts || requesting.loadCounts"
						class="py-10 text-center"
						icon-css-class="h-11 w-11"
						text-css-class="hidden"
					/>
					<div
						v-else-if="expiredCounts && expiredMonthChart.data"
						class="flex font-light h-48 items-center justify-center"
					>
						<Chart
							:data="expiredMonthChart.data"
							:options="expiredMonthChart.options"
						/>
					</div>
					<router-link
						class="btn btn--ghost btn--primary"
						title="Visualizar os relatórios do sistema - Movimento de Caixa e gráfico de entradas e saídas por mês"
						:to="{name: 'reports'}"
					>
						Ir para Relatórios
					</router-link>
				</div>
			</div>

		</div>


		<div class="mt-10 text-center">
			<button
				class="btn btn--ghost btn--primary button-talk-to-support"
				@click="buttonSupportClick"
			>
				<svg viewBox="0 0 28 32">
					<path d="M28,32 C28,32 23.2863266,30.1450667 19.4727818,28.6592 L3.43749107,28.6592 C1.53921989,28.6592 0,27.0272 0,25.0144 L0,3.6448 C0,1.632 1.53921989,0 3.43749107,0 L24.5615088,0 C26.45978,0 27.9989999,1.632 27.9989999,3.6448 L27.9989999,22.0490667 L28,22.0490667 L28,32 Z M23.8614088,20.0181333 C23.5309223,19.6105242 22.9540812,19.5633836 22.5692242,19.9125333 C22.5392199,19.9392 19.5537934,22.5941333 13.9989999,22.5941333 C8.51321617,22.5941333 5.48178311,19.9584 5.4277754,19.9104 C5.04295119,19.5629428 4.46760991,19.6105095 4.13759108,20.0170667 C3.97913051,20.2124916 3.9004494,20.4673395 3.91904357,20.7249415 C3.93763774,20.9825435 4.05196575,21.2215447 4.23660523,21.3888 C4.37862552,21.5168 7.77411059,24.5386667 13.9989999,24.5386667 C20.2248893,24.5386667 23.6203743,21.5168 23.7623946,21.3888 C23.9467342,21.2215726 24.0608642,20.9827905 24.0794539,20.7254507 C24.0980436,20.4681109 24.0195551,20.2135019 23.8614088,20.0181333 Z" />
				</svg>
				<span class="ml-3">Falar com o atendimento do CJ</span>
			</button>
		</div>

	</section>
</template>

<style lang="scss" scoped>
section.feature {
    background-image: url('/img/bg-finance.png');
    background-repeat: no-repeat;
    background-position: top right;
}

.button-talk-to-support {

    &:hover {
        svg {
            fill: #fff;
        }
    }

    svg {
        fill: #0B63E9;
        height: 21px;
    }
}
</style>