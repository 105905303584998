<script lang="ts">
/**
 * This was made as a component:
 * 1. To be shared across different contexts (i.e.: First Access View and Settings View)
 * 2. To carry the entire set of components and features related
 */
import {
	defineComponent, inject
} from 'vue';
import { mapActions } from 'pinia';

import { EventBus } from '../../../models/crm';

import serviceFinance from '../../../services/API/financial/index';

import BlockingOverlay from '../../../components/BlockingOverlay/BlockingOverlay.vue';

import { useAccountStore } from '../../../stores/account';

export default defineComponent({
	components: {
		BlockingOverlay,
	},
	data() {
		return {

			eventBus: inject<EventBus>('eventBus'),

		};
	},
	methods: {
		...mapActions(useAccountStore, [ 'setOfficeFinancesSeeded' ]),

		async createFinanceDefaultSeeds(): Promise<void> {
			this.eventBus?.emit('BlockingOverlay/show', {
				text: 'Aguarde, criando as configurações iniciais'
			});

			try {
				const response = await serviceFinance.seedDefaults();

				/**
                         * Sometimes errors come in 200 responses. Force error throw for
                         * to ensure failure.
                         */
				if (response.data?.error) throw new Error(response.data.error);

				this.setOfficeFinancesSeeded(true);

				this.eventBus?.emit('BlockingOverlay/hide');

				this.$router.replace({name: 'settings'});

				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Configurações iniciais criadas com sucesso',
						type: 'success'
					}
				);
			} catch (err) {
				console.error(err);

				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Falha ao criar configurações iniciais',
						type: 'error'
					}
				);

				this.eventBus?.emit('BlockingOverlay/hide');
			}
		},

	},
});

</script>

<template>
	<Teleport to="body">
		<BlockingOverlay />
	</Teleport>
</template>