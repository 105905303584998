/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosResponse } from 'axios';

const pageParams = '&per_page=999999&page=1';

import {
	UserPreferences, User, CRMPreferences, CRMPreferencesToSend
} from '../models/crm';

export default {

	getById(id: string | number): Promise<AxiosResponse<User, any>> {
		const path = `/users/${id}.json`;

		return axios.get(path);
	},

	getWithTask(): Promise<AxiosResponse<{users: User[]}, any>> {
		const path = '/users/search.json?has_tasks=true';

		return axios.get(path);
	},

	searchByName({ name }: {name: string}): Promise<AxiosResponse<{users: User[]}, any>> {
		const path = `/users/search.json?search=${name}${pageParams}`;

		return axios.get(path);
	},

	update({
		data, id
	}: {
		data: User | UserPreferences,
		id: string | number
	}): Promise<AxiosResponse<User, any>> {
		const path = `/users/${id}.json`;

		return axios.patch(path, data);
	},

	updateUserPreferences({
		data, userId
	}: {
		data: { preferences: UserPreferences},
		userId: string | number
	}): Promise<AxiosResponse<User, any>> {
		const path = `/users/${userId}.json`;

		return axios.patch(path, data);
	},

	getCRMPreferences(id: string | number): Promise<AxiosResponse<CRMPreferences, any>> {
		const path = `/users/${id}/preferences`;

		return axios.get(path);
	},

	putCRMPreferences(
		id: string | number,
		data: CRMPreferencesToSend
	): Promise<AxiosResponse<CRMPreferences, any>> {
		const path = `/users/${id}/preferences`;

		return axios.put(path, data);
	},

	patchNotificationRead(
		isRead: boolean,
		officeId: number | string,
		notificationId: number | string
	): Promise<AxiosResponse<CRMPreferences, any>> {
		const apiUrl = `${import.meta.env.VITE_API_URL}/offices/${officeId}/notifications/${notificationId}`;

		const requestBody = {
			notification: {
				read: isRead
			}
		};

		return axios.patch(apiUrl, requestBody);
	}

};