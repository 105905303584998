<script lang="ts">
import Multiselect from 'vue-multiselect';
import {
	defineComponent, PropType
} from 'vue';

type Item = {
  title?: string,
  name?: string,
};

export default defineComponent({
	props: {
		modelValue: {
			type: Object as PropType<Item | null>
		},
		async: {
			default: false, type: Boolean
		},
		icon: { type: String },
		label: {
			default: 'label', type: String
		},
		openDirection: {
			default: 'bottom', type: String
		},
		queryFunction: {
			type: Function,
			required: true,
		},
		searchable: {
			default: true, type: Boolean
		},
		trackBy: {
			default: 'id', type: String
		},
		inputLabel: {
			default: '', type: String
		},
		placeholder: {
			default: 'Digite para buscar', type: String
		},
		noResultMessage: {
			default: '', type: String
		},
	},
	emits: [
		'update:modelValue',
	],
	components: {
		Multiselect,
	},
	watch: {
		modelValue(value): void {
			this.value = value;
		},
	},
	data() {
		return {
			isLoading: false as boolean,
			isOpen: false as boolean,
			options: [] as Item[],
			value: null as Item | null,
		};
	},
	methods: {
		focus(): void {
			if (!this.isOpen) {
				(this.$refs.singleSearchSelect as typeof Multiselect).activate();
				(this.$refs.singleSearchSelect as typeof Multiselect).$el.focus();
			}
		},
		blur(): void {
			if (this.isOpen) {
				(this.$refs.singleSearchSelect as typeof Multiselect | null)?.deactivate();
			}
		},
		async open(): Promise<void> {
			this.isOpen = true;
			if (this.async) {
				this.isLoading = true;
				const response = await this.queryFunction('');
				if (response) {
					this.options = response;
				}
				this.isLoading = false;
			}
		},
		async searchChange(query: string): Promise<void> {
			if (this.async) {
				this.isLoading = true;
				const response = await this.queryFunction(query);
				if (response) {
					this.options = response;
				}
				this.isLoading = false;
			}
		},
		selectItem(item: Item): void {
			this.value = item;
			this.$emit('update:modelValue', this.value);
			this.focus();
		},
	},
	beforeMount() {
		if (this.modelValue) {
			this.value = this.modelValue;
		}
	},
});
</script>
<template>
	<span v-click-outside="blur"
		class="component-tags-multiselect"
	>
		<label for="singleSearchSelect"
			class="select-label"
		>{{ inputLabel }}</label>
		<multiselect
			ref="singleSearchSelect"
			v-model="value"
			:clear-on-select="true"
			:hide-selected="true"
			:label="label"
			:loading="isLoading"
			:options="options"
			:searchable="searchable"
			:track-by="trackBy"
			open-direction="bottom"
			:placeholder="value?.name || placeholder"
			prevent-autofocus
			@open="open"
			@close="isOpen = false"
			@search-change="searchChange"
			@select="selectItem"
			@click.stop="focus"
			select-label=""
			select-group-label="[Enter]"
			selected-label="Selecionado"
		>
			<template #noResult="{}">
				<span class="text-sm cursor-default"> {{ noResultMessage }} </span>
			</template>
		</multiselect>

	</span>

</template>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss" scoped>

.component-tags-multiselect {
  position: relative;
}

.select-label {
  position: absolute;
  top: 30px;
  left: 0;
  margin-left: 10px;
  font-weight: 600;
  font-size: 12px;
  color: #878b94;
  z-index: 9999;
}

.multiselect {
  margin-top: 20px;
}

.component-tags-multiselect {
    &.validation-error {
        :deep(.multiselect) {
            .multiselect__tags {
                border-color: #dc3545;
            }
        }
    }

	:deep(.multiselect--active) .multiselect__tags {
		width: auto !important;
	}

    :deep(.multiselect) {
        font-family: inherit;
		width: 100% !important;

		.multiselect__select {
			min-height: 50px;
		}
        .multiselect__tags {
			border-color: #DCDDDF;
			border-radius: 0.25rem;
            display: flex;
            flex-wrap: wrap;
			max-width: 100%;
            gap: 6px;
            padding: 8px;
			padding-right: 24px;
			min-height: 50px;

            .multiselect__input {
                background-color: transparent;
                margin-left: 0px;
                margin-bottom: 0;
                padding: 0;
                margin-top: 1.3rem;
                font-size: 12px;
                font-weight: 700;

            }

            .multiselect__placeholder {
                margin-bottom: 0;
				display: flex;
				justify-content: flex-start;
				align-items: center;
                margin-top: 1.3rem;
                font-size: 12px;
                font-weight: 600;
                color: #878b94;
            }

            .multiselect__single {
                font-size: 12px;
                margin-top: 1.3rem;
                margin-bottom: 0px;
                padding: 0;
                font-weight: 700;
            }

        }

        .multiselect__content-wrapper {
            box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.1);
			overflow-x: hidden;
            .multiselect__content {
				min-width: auto !important;
				width: 100%;
                .multiselect__element {
					width: 100%;
                    .multiselect__option {
                        font-size: 0.85rem;
						width: 100%;

                        &--highlight {
                            background-color: #4F88ED;
                            &::after {
                                background-color: inherit;
                            }
                        }

						span {
							display: inline-block;
							text-overflow: ellipsis;
							white-space: nowrap;
							overflow: hidden;
							min-width: auto !important;
							width: 100% !important;

							span {
								display: inline-block;
								text-overflow: ellipsis;
								white-space: nowrap;
								overflow: hidden;
								min-width: auto !important;
								width: 100% !important;
							}
						}
                    }
                }
            }
        }
    }
}
</style>
