<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
	emits: [ 'clicked-history-item' ],
	props: {
		colorBarCssClass: {
			default: '',
			type: String,
		},
		itemData: {
			required: true,
			type: Object,
		},
		clickable: {
			default: false,
			type: Boolean,
		},
	},
});
</script>
<template>
	<div class="flex component-history-item mb-2 select-none items-center"
		:class="{'cursor-pointer': clickable}"

		@click.stop.prevent="clickable && $emit('clicked-history-item')"
	>
		<!-- Id icon -->
		<div v-if="$slots['type-icon']"
			class="mr-2 mb-0 opacity-25 text-center w-8"
		>
			<span class="bg-white border border-gray-500 inline-flex items-center justify-center p-1 rounded-full">
				<slot name="type-icon"
					:item-data="itemData"
				/>
			</span>
		</div>
		<div
			class="border border-gray-200 flex flex-1 justify-between rounded-sm"
			:class="{
				'bg-[rgb(252,252,252)] hover:bg-[rgb(245,245,245)] active:bg-[rgb(252,252,252)]': clickable
			}"
		>
			<!-- Color Bar Id -->
			<div :class="['mr-2 rounded-lt w-1', colorBarCssClass]" />

			<!-- Info -->
			<div class="flex-1 mr-2 py-2 self-start">
				<slot name="info"
					:item-data="itemData"
				/>
			</div>
			<!-- Flags -->
			<div class="flex items-center mr-2 self-center">
				<slot name="flags"
					:item-data="itemData"
				/>
			</div>
			<!-- Action button -->
			<div v-if="$slots.actions"
				class="self-center"
			>
				<div class="dropdown dropdown-left">
					<label class="cursor-pointer"
						tabindex="0"
					>
						<svg class="inline w-6 h-6"
							fill="none"
							stroke="currentColor"
							viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg"
						><path stroke-linecap="round"
							stroke-linejoin="round"
							stroke-width="2"
							d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
						/></svg>
					</label>
					<ul tabindex="0"
						class="dropdown-content menu p-2 shadow-md bg-base-100 rounded w-52"
					>
						<slot name="actions"
							:item-data="itemData"
						/>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>
<style lang="scss" scoped></style>