import {
	SearchResponse
} from '../models/crm';
import axios, { AxiosResponse } from 'axios';

export default {
	search(query: string): Promise<AxiosResponse<SearchResponse>> {
		const path = `/search?query=${query}`;

		return axios.get(path);
	},
};