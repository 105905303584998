<script lang="ts">
import {
	defineComponent, PropType
} from 'vue';

type Data = { name: string } | { title: string};

export default defineComponent({
	props: {
		data: {
			required: true, type: Array as PropType<Data[]>
		},
		label: {
			required: true, type: String as PropType<'name' | 'title'>
		},
		labelCssClass: {
			default: '', type: String
		},
		itemCssClass: {
			default: '', type: String
		},
		maxItems: {
			default: 5, type: Number
		},
	},
	computed: {

		filteredData(): Data[] {
			return this.data.slice(0, this.maxItems);
		},

	}
});
</script>
<template>
	<div class="flex flex-wrap gap-1 component-tags-display">
		<span
			v-for="(item, index) in filteredData"
			:key="`item-${index}`"
			:class="[
				'inline-flex items-center justify-between border border-[#E5F0FB] bg-[#E5F0FB] py-0.5 px-1.5 rounded-full',
				itemCssClass
			]"
		>
			<slot
				v-if="$slots.tagIcon"
				name="tagIcon"
			/>
			<span
				:class="[
					'font-medium max-w-[8rem] overflow-hidden text-ellipsis text-sm whitespace-nowrap',
					labelCssClass
				]"
				:title="`${item[label as keyof (typeof item)]}`"
			>
				{{ item[label as keyof (typeof item)] }}
			</span>
		</span>
		<span
			v-if="data.length > maxItems"
			class="inline-flex items-center justify-between bg-white border border-[#E5F0FB] py-0.5 px-1.5 rounded-full"
		>
			<span
				:class="[
					'font-medium max-w-[8rem] overflow-hidden text-ellipsis text-sm whitespace-nowrap',
					labelCssClass
				]"
			>
				+{{ data.length - maxItems }}
			</span>
		</span>
	</div>
</template>
<style lang="scss" scoped></style>