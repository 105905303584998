<script lang="ts">
import objectHash from 'object-hash';

import serviceLegalCases from '../../../../services/API/crm/legalCases';

import FileUploadFilePond from '../../../../components/FileUpload/FileUploadFilePond.vue';
import ModalBasic from '../../../../components/Modal/ModalBasic.vue';
import {
	defineComponent, inject
} from 'vue';
import {
	EventBus, File
} from '../../../../models/crm';

export default defineComponent({
	components: {
		FileUploadFilePond,
		ModalBasic,
	},
	props: {
		legalCaseId: {
			required: true,
			type: [ Number, String ]
		},
	},
	emits: [
		'attachment-created',
	],
	data() {
		return {

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			files: [] as any[],

			eventBus: inject<EventBus>('eventBus'),

		};
	},
	methods: {

		resetModel(): void {
			this.files = [];
		},

		async save(): Promise<void> {
			if (this.files.length > 0) {
				const legalCaseId = Number(this.legalCaseId);

				this.eventBus?.emit('BlockingOverlay/show', {
					text: 'Aguarde, adicionando anexo'
				});

				try {
					await serviceLegalCases.attachmentAdd({
						legalCaseId, attachments: this.files
					});

					this.eventBus?.emit(
						'Toast/add',
						{
							content: 'Anexo adicionado com sucesso',
							type: 'success'
						}
					);

					this.$emit('attachment-created');

					this.resetModel();

					(this.$refs.modal as typeof ModalBasic).hide();
				} catch (err) {
					console.error(err);

					this.eventBus?.emit(
						'Toast/add',
						{
							content: 'Falha ao adicionar anexo',
							type: 'error'
						}
					);

					return;
				} finally {
					this.eventBus?.emit('BlockingOverlay/hide');
				}

				return;
			}

			this.eventBus?.emit(
				'Toast/add',
				{
					content: 'Não há anexo a ser salvo',
					type: 'error'
				}
			);
		},

		show(): void {
			this.resetModel();
			(this.$refs.modal as typeof ModalBasic).show();
		},

		// Events

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		async attachments_updatefiles(files: File[]): Promise<void> {
			const hashFiles = objectHash(getFilesArrayAsString(files));
			const hashAttachmentsFiles = objectHash(getFilesArrayAsString(this.files));

			if (hashFiles !== hashAttachmentsFiles) {
				this.files = files;
			}

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			function getFilesArrayAsString(arr: File[]): string {
				return JSON.stringify(arr.map(item => `${item.filename}:${item.fileSize}:${item.fileType}:${item.fileExtension}${item.id}`));
			}
		},

		buttonCancelClick(): void {
			(this.$refs.modal as typeof ModalBasic).hide();
		},

		buttonSaveClick(): void {
			this.save();
		},

		modal_hide(): void {
			this.$router.push({
				name: 'legal-case-details', params: { id: this.$route.params.id }
			});
		},

	}
});
</script>
<template>
	<ModalBasic
		ref="modal"
		size="lg"
		title="Adicionar anexo"

		@hide="modal_hide"
	>
		<template #content>
			<div class="p-4">
				<FileUploadFilePond
					label-idle="Arraste seu arquivo ou <span class=&quot;filepond--label-action&quot;>clique aqui</span>"
					:initial-files="files"

					@updatefiles="attachments_updatefiles"
				/>
			</div>
		</template>
		<template #footer>
			<div class="flex-1 flex overflow-hidden rounded-b-md">
				<button
					class="flex-1 bg-gray-200 font-medium p-4 text-gray-500 hover:bg-gray-300 active:bg-gray-200"
					@click="buttonCancelClick"
				>
					CANCELAR
				</button>
				<button
					class="flex-1 bg-primary font-medium p-4 text-white hover:bg-blue-600 active:bg-primary"
					@click="buttonSaveClick"
				>
					SALVAR
				</button>
			</div>
		</template>
	</ModalBasic>
</template>
<style lang="scss" scoped>
:deep(.filepond--wrapper) {

    .filepond--root {
        font-family: inherit;
    }

    .filepond--credits {
        text-align: center;
        width: 100%;
    }

    .filepond--panel-root {
        background-color: transparent;
        border: 2px dashed #ddd;
    }

    .filepond--drop-label {
        label {
            font-weight: 500;
            opacity: 0.75;
        }
    }
}
</style>