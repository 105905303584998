
<script lang="ts">
import {
	defineComponent, inject
} from 'vue';
import {
	mapActions, mapState
} from 'pinia';

import {
	DataTableColumn,
	EventBus, FilterItem, Helpers, Luxon
} from '../../../../models/crm';
import {
	EntryBlockType,
	ReceivedEntryBlock
} from '../../../../models/financial';

import DataTable from '../../../../components/DataTable/DataTable.vue';
import DatePicker from '../../../../components/Form/DatePicker.vue';
import SelectField from '../../../../components/Form/SelectField.vue';
import FiltersButtonsGroup from '../../../../components/FiltersButtonsGroup/FiltersButtonsGroup.vue';
import Loading from '../../../../components/Loading/LoadingComponent.vue';

import { useEntriesStore } from '../../../../stores/models/financial/entries';
import { useAccountStore } from '../../../../stores/account';


export default defineComponent({
	components: {
		DataTable,
		DatePicker,
		FiltersButtonsGroup,
		SelectField,
		Loading,
	},
	provide() {
		return {
			filters: this.filters,
		};
	},
	data() {
		return {

			eventBus: inject<EventBus>('eventBus'),
			helpers: inject<Helpers>('helpers'),
			luxon: inject<Luxon>('luxon'),

			dataTableEntries: {
				columns: [] as DataTableColumn[],
				data: [] as ReceivedEntryBlock[],
				dataRaw: [] as ReceivedEntryBlock[],
				options: {
					columnCssClass: {
						'amount_income': 'cell-entry-amount',
						'amount_expense': 'cell-entry-amount',
					},
					initial: {
						orderBy: {
							column: 'payment_date',
							sort: 'desc',
						},
					},
					orderableColumns: [
						'payment_date',
					],
				},
				total_count: 100,
			},

			filterDate: {
				model: {
					dateFrom: '' as string,
					dateTo: '' as string,
					paid: 1 as 1 | 0 | -1,
				},
				applied: {} as {
					dateFrom?: string;
					dateTo?: string;
					paid?: 1 | 0 | -1;
				},
				shortcut: '',
				query: '&paid=true',
			},

			selectPaidOptionsList: [
				{
					label: 'Realizadas', value: 1
				},
				{
					label: 'Previstas', value: -1
				},
				{
					label: 'Todas', value: 0
				},
			],

			filters: {
				model: {},
				applied: {},
				labels: {},
			},

			order: {
				column: 'payment_date',
				sort: 'desc',
			},

			show: true,

			dataConsolidated: [] as ReceivedEntryBlock[],

			perPage: 25,
			page: 1,

			requesting: {
				pagination: false,
				loadEntries: false,
			},

		};
	},
	computed: {
		...mapState(useAccountStore, [ 'user' ]),
		...mapState(useEntriesStore, [ 'entries', 'maxEntries', 'isRequestingEntries' ]),

		expensesSum(): number {
			return this.dataTableEntries.data
				.filter(item => ('expense_category' in item))
				.filter(item => {
					if (Number(this.filterDate.applied?.paid) === 0) {
						return true;
					}

					if (Number(this.filterDate.applied?.paid) === -1) {
						return !item.payment_date;
					}

					return !!item.payment_date;
				})
				.reduce((accumulator, row) => accumulator + row.amount, 0);
		},

		incomesSum(): number {
			return this.dataTableEntries.data
				.filter(item => ('income_category' in item))
				.filter(item => {
					if (Number(this.filterDate.applied?.paid) === 0) {
						return true;
					}

					if (Number(this.filterDate.applied?.paid) === -1) {
						return !item.payment_date;
					}

					return !!item.payment_date;
				})
				.reduce((accumulator, row) => accumulator + row.amount, 0);
		},

		itemsFilterDateShortcuts(): FilterItem<string>[] {
			return [
				{
					label: 'Hoje', value: 'today'
				},
				{
					label: 'Mês atual', value: 'current_month'
				},
				{
					label: 'Mês anterior', value: 'previous_month'
				},
				{
					label: 'Últimos 30 dias', value: 'last_30_days'
				},
				{
					label: '×', value: 'clear', tooltip: 'Limpar todos os filtros'
				},
			];
		},
	},
	watch: {
		async 'filterDate.model.paid'(newValue, oldValue): Promise<void> {
			if (newValue !== oldValue) {
				await this.filterDateApply();
			}
		},
	},
	async beforeMount() {
		await this.loadEntries(true);
	},
	methods: {
		...mapActions(useEntriesStore, [ 'paginatedLoadEntries' ]),

		async addEntriesToData(nextPage: number): Promise<void> {
			if (
				this.user?.office_id
				&& this.dataTableEntries.dataRaw.length === this.perPage * (nextPage - 1)
			) {
				this.requesting.pagination = true;


				const orderBy = 'payment_date:asc';

				try {
					if (this.maxEntries.expenses > this.perPage * (nextPage - 1)) {
						await this.paginatedLoadEntries(
							EntryBlockType.EXPENSES,
							orderBy,
							this.filterDate.query,
							false,
							this.perPage,
							nextPage,
						);
					}

					if (this.maxEntries.incomes > this.perPage * (nextPage - 1)) {
						await this.paginatedLoadEntries(
							EntryBlockType.INCOMES,
							orderBy,
							this.filterDate.query,
							false,
							this.perPage,
							nextPage,
						);
					}

					this.page = nextPage;

					this.useNormalEntriesList(EntryBlockType.EXPENSES);
					this.useNormalEntriesList(EntryBlockType.INCOMES);

					this.configDataTableEntries();
				} catch (err) {
					console.error(err);

					this.eventBus?.emit(
						'Toast/add',
						{
							content: 'Erro ao fazer a paginação',
							type: 'error'
						}
					);
				} finally {
					this.requesting.pagination = false;
				}
			}
		},
		async sequentialEntriesAddition(page: number): Promise<void> {
			const oldPage = Math.ceil(this.dataTableEntries.dataRaw.length / this.perPage);
			if (page <= oldPage || this.dataTableEntries.dataRaw.length % this.perPage !== 0) {
				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Erro ao fazer a paginação',
						type: 'error'
					}
				);

				return;
			}

			for (let i = oldPage + 1;i <= page;++i) {
				await this.addEntriesToData(i);
			}
		},

		async loadEntries(forceReload = false, usePaginationRequesting = false): Promise<void> {
			if (usePaginationRequesting) {
				this.requesting.pagination = true;
			} else {
				this.requesting.loadEntries = true;
			}

			try {
				if (!this.entries.expenses.length || !this.entries.incomes.length || forceReload) {
					const orderBy = `${this.order.column}:${this.order.sort}`;

					await this.paginatedLoadEntries(
						EntryBlockType.EXPENSES,
						orderBy,
						this.filterDate.query,
						false,
						this.perPage,
						1
					);
					await this.paginatedLoadEntries(
						EntryBlockType.INCOMES,
						orderBy,
						this.filterDate.query,
						false,
						this.perPage,
						1
					);
				}

				this.configDataTableEntries();
			} catch (err) {
				console.error(err);

				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Erro ao carregar entradas/saídas',
						type: 'error'
					}
				);
			} finally {
				if (usePaginationRequesting) {
					this.requesting.pagination = false;
				} else {
					this.requesting.loadEntries = false;
				}
			}
		},

		async filterDateApply(): Promise<void> {
			const filtersApplied = this.filterDate.applied = { ...this.filterDate.model };

			let builtFilter = '';

			if (filtersApplied.dateFrom) {
				const parsedDateFrom = this.luxon?.fromISO(filtersApplied.dateFrom);

				const dateFromFilter = filtersApplied.dateFrom
					? `&due_at_from=${parsedDateFrom?.toISODate() || filtersApplied.dateFrom}`
					: '';

				builtFilter += dateFromFilter;
			}

			if (filtersApplied.dateTo) {
				const parsedDateTo = this.luxon?.fromISO(filtersApplied.dateTo);

				const dateToFilter = filtersApplied.dateTo
					? `&due_at_to=${parsedDateTo?.toISODate() || filtersApplied.dateTo}`
					: '';

				builtFilter += dateToFilter;
			}

			const filterPaidNum = Number(filtersApplied.paid);

			const paidFilter = filterPaidNum > 0
				? '&paid=true'
				: (filtersApplied.paid < 0
					? '&unpaid=true'
					: ''
				);

			builtFilter += paidFilter;

			this.filterDate.query = builtFilter;

			this.loadEntries(true);
		},

		async orderApply(column: string, sort: string): Promise<void> {
			this.order = {
				column, sort
			};

			await this.loadEntries(true, true);
		},

		configDataTableEntries(): void {
			this.dataConsolidated = this.entries.incomes
				.concat(this.entries.expenses);

			this.dataConsolidated = [ ...this.dataConsolidated ].sort(this.sortEntries);

			this.dataTableEntries.data = this.dataTableEntries.dataRaw
				= this.dataConsolidated;

			this.dataTableEntries.total_count = this.maxEntries.incomes
				+ this.maxEntries.expenses;

			this.updateColumns();
		},

		updateColumns(): void {
			const baseColumns = [
				{
					key: 'amount_income', label: 'Entradas', initial: true
				},
				{
					key: 'amount_expense', label: 'Saídas', initial: true
				},
				{
					key: 'description', label: 'Descrição', initial: true
				},
			];

			const paymentDateColumn = {
				key: 'payment_date', label: 'Data de Realização', initial: true
			};

			const dueDateColumn = {
				key: 'due_date', label: 'Data do Vencimento', initial: true
			};

			if (!this.filterDate.applied?.paid || this.filterDate.applied?.paid > 0) {
				this.dataTableEntries.columns = [
					paymentDateColumn,
					...baseColumns,
				];

				return;
			}

			if (this.filterDate.applied?.paid < 0) {
				this.dataTableEntries.columns = [
					dueDateColumn,
					...baseColumns,
				];

				return;
			}

			this.dataTableEntries.columns = [
				dueDateColumn,
				paymentDateColumn,
				... baseColumns,
			];
		},

		formatDateToTable(isoStr?: string): string {
			if (!isoStr) {
				return '';
			}

			const date = this.luxon?.fromISO(isoStr);

			return date?.toFormat('dd/MM/yyyy') || '';
		},

		sortEntries(a: ReceivedEntryBlock, b: ReceivedEntryBlock): number {
			if (!a.payment_date?.length && b.payment_date?.length) {
				return this.order.sort === 'asc' ? 1 : -1;
			}

			if (a.payment_date?.length && !b.payment_date?.length) {
				return this.order.sort === 'asc' ? -1 : 1;
			}

			if (!a.payment_date?.length && !b.payment_date?.length) {
				const dueDateA = this.luxon?.fromISO(a.due_date);
				const dueDateB = this.luxon?.fromISO(b.due_date);

				// check if DateTime is valid, not 'Invalid DateTime'
				if (!dueDateA?.isValid || !dueDateB?.isValid) {
					return 0;
				}

				return this.order.sort === 'asc'
					? dueDateA.diff(dueDateB).milliseconds
					: dueDateB.diff(dueDateA).milliseconds;
			}

			const dateA = this.luxon?.fromISO(a.payment_date || '');
			const dateB = this.luxon?.fromISO(b.payment_date || '');

			if (!dateA?.isValid || !dateB?.isValid) {
				return 0;
			}

			return this.order.sort === 'asc'
				? dateA.diff(dateB).milliseconds
				: dateB.diff(dateA).milliseconds;
		},

		// Events

		buttonApplyFilterDateClick(): void {
			this.filterDateApply();
		},

		buttonDateShortcutClick(item: FilterItem<string>): void {
			if (!this.luxon) {
				return;
			}

			const { value } = item;

			this.filterDate.shortcut = value;

			if (value === 'today') {
				this.filterDate.model.dateFrom = this.luxon.now().startOf('day').toISO() || new Date().toISOString();
				this.filterDate.model.dateTo = this.luxon.now().endOf('day').toISO() || new Date().toISOString();
			}
			else if (value === 'current_month') {
				this.filterDate.model.dateFrom = this.luxon.now().startOf('month').toISO() || new Date().toISOString();
				this.filterDate.model.dateTo = this.luxon.now().endOf('month').toISO() || new Date().toISOString();
			}
			else if (value === 'previous_month') {
				this.filterDate.model.dateFrom = this.luxon.now().minus({ months: 1}).startOf('month').toISO() || new Date().toISOString();
				this.filterDate.model.dateTo = this.luxon.now().minus({ months: 1}).endOf('month').toISO() || new Date().toISOString();
			}
			else if (value === 'last_30_days') {
				this.filterDate.model.dateFrom = this.luxon.now().minus({ days: 30}).toISO()
					|| new Date().toISOString();
				this.filterDate.model.dateTo = this.luxon.now().toISO() || new Date().toISOString();
			} else {
				this.filterDate.applied = {};
				this.filterDate.model.dateFrom = '';
				this.filterDate.model.dateTo = '';
				this.filterDate.shortcut = '';
			}

			this.filterDateApply();
		},

		buttonEntryDescriptionClick(row: ReceivedEntryBlock): void {
			const isIncome = !!row.income_category;
			const name = isIncome ? 'income-details' : 'expense-details';
			const { id } = row;
			this.$router.push({
				name,
				params: {
					entryId: id,
				},
			});
		},

		buttonTitleClick(): void {
			this.show = !this.show;
		},

	},
});
</script>

<template>
	<button
		:class="[
			'bg-blue-500/10 block border border-blue-500 flex font-medium items-center justify-between rounded-tl rounded-tr w-full',
			{ 'rounded': !show }
		]"

		@click.stop="buttonTitleClick"
	>
		<div class="flex items-center gap-[24px]">
			<span class="font-semibold text-blue-700 m-3 mr-0">Movimento de Caixa</span>
			<SelectField
				v-model="filterDate.model.paid"
				:options="selectPaidOptionsList"
				required
				class="min-w-[120px] border-blue-500"

				@click.stop=""
			/>
		</div>
		<span
			:class="{ 'rotate-180': show }"
			class="my-3 mr-3"
		>
			<svg
				class="fill-blue-700 h-6 w-6"
				fill="currentColor"
				viewBox="0 0 20 20"
				xmlns="http://www.w3.org/2000/svg"
				aria-hidden="true"
			>
				<path
					clip-rule="evenodd"
					fill-rule="evenodd"
					d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
				/>
			</svg>
		</span>
	</button>
	<div
		v-if="show"
		class="border-b border-l border-r border-blue-500 rounded-bl rounded-br"
	>
		<div class="bg-white/50 p-3">
			<div class="gap-4 flex justify-between">
				<div>
					<div class="font-bold mb-1 text-gray-500 text-xs">
						De
					</div>
					<div>
						<DatePicker
							v-model="filterDate.model.dateFrom"

							:arrow-navigation="true"
							:close-on-auto-apply="true"
							:enable-time-picker="false"
							format="dd/MM/yyyy"
							:keep-action-row="false"
						/>
					</div>
				</div>
				<div>
					<div class="font-bold mb-1 text-gray-500 text-xs">
						Até
					</div>
					<div>
						<DatePicker
							v-model="filterDate.model.dateTo"

							:arrow-navigation="true"
							:close-on-auto-apply="true"
							:enable-time-picker="false"
							format="dd/MM/yyyy"
							:keep-action-row="false"
						/>
					</div>
				</div>
				<div class="flex items-end pb-1">
					<button
						class="btn btn--primary btn-sm !px-5 !py-1.5 rounded"
						title="Aplicar os filtros de data na lista abaixo"

						@click="buttonApplyFilterDateClick"
					>
						Aplicar
					</button>
				</div>
				<div class="flex flex-1 items-end justify-end mb-1">
					<FiltersButtonsGroup
						button-class="!p-1.5 whitespace-nowrap"
						:items="itemsFilterDateShortcuts"
						:selected-value="filterDate.model.dateFrom
							&& filterDate.model.dateTo ? filterDate.shortcut : ''"

						@clicked-filter-button="buttonDateShortcutClick"
					/>
				</div>
			</div>
		</div>

		<div class="p-3 container-datatable-entries">
			<template v-if="requesting.loadEntries">
				<div class="flex justify-center">
					<Loading
						class="opacity-75 py-14 text-center"
						icon-css-class="h-10 w-10 mr-3"
						text-css-class="text-2xl"
					/>
				</div>
			</template>
			<template v-else>
				<DataTable
					id="data_table_finance_reports"

					ref="dataTableEntries"
					:actions-column-show="false"
					:config-button-show="false"
					:columns="dataTableEntries.columns"
					:data="dataTableEntries.data"
					:data-raw="dataTableEntries.dataRaw"
					:filters="filters"
					:options="dataTableEntries.options"
					:pagination-row-top-show="false"
					:search-and-filters-show="false"
					:max-data-length="dataTableEntries.total_count"
					:loading-data="requesting.pagination || requesting.loadEntries"

					@next-page="addEntriesToData"
					@go-to-page="sequentialEntriesAddition"
					@order-apply="orderApply"
				>
					<template #filters />
					<template
						v-if="!!dataTableEntries.data.length"
						#tfoot
					>
						<tr class="border-t">
							<td>
								<span class="font-bold">Total</span>
							</td>
							<td v-if="Number(filterDate.applied.paid) === 0" />
							<td>
								<span class="font-bold text-green-600">
									<span>+</span>
									<span class="ml-1">
										{{ helpers?.getFormattedCurrencyFromNumber(incomesSum) }}
									</span>
								</span>
							</td>
							<td>
								<span class="font-bold text-red-500">
									<span>-</span>
									<span class="ml-1">
										{{ helpers?.getFormattedCurrencyFromNumber(expensesSum) }}
									</span>
								</span>
							</td>
							<td />
						</tr>
					</template>
					<template #col_due_date="{ row }">
						<span>{{ formatDateToTable(row.due_date) }}</span>
					</template>
					<template #col_payment_date="{ row }">
						<span>{{ formatDateToTable(row.payment_date) }}</span>
					</template>
					<template #col_amount_income="{ row }">
						<span
							v-if="row.income_category"
							class="bg-green-500/10 block p-1.5 text-green-600 w-full"
						>
							<span>+</span>
							<span class="ml-1">
								{{ helpers?.getFormattedCurrencyFromNumber(row.amount) }}
							</span>
						</span>
					</template>
					<template #col_amount_expense="{ row }">
						<span
							v-if="row.expense_category"
							class="bg-red-500/10 block p-1.5 text-red-500 w-full"
						>
							<span>-</span>
							<span class="ml-1">
								{{ helpers?.getFormattedCurrencyFromNumber(row.amount) }}
							</span>
						</span>
					</template>
					<template #col_description="{ row }">
						<button
							class="hover:text-blue-500 hover:underline"
							@click="buttonEntryDescriptionClick(row)"
						>
							{{ row.description }}
						</button>
					</template>
				</DataTable>
			</template>
		</div>
	</div>
</template>

<style lang="scss" scoped></style>