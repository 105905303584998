<script lang="ts">
import Loading from '../Loading/LoadingComponent.vue';
import {
	defineComponent, inject
} from 'vue';
import { EventBus } from '../../models/crm';

export default defineComponent({
	components: {
		Loading,
	},
	data() {
		return {

			text: '' as string,

			show: false as boolean,

			eventBus: inject<EventBus>('eventBus')
		};
	},
	created() {
		this.eventBus?.on(
			'BlockingOverlay/show',
			(obj = { text: 'Aguarde'}): void => {
				const { text } = obj;

				this.text = text;

				this.show = true;
			}
		);

		this.eventBus?.on('BlockingOverlay/hide',
			() => {
				this.text = '';
				this.show = false;
			}
		);
	},
});
</script>
<template>
	<div v-show="show"
		class="fixed flex items-center justify-center bg-white/40 h-screen left-0 top-0 w-screen z-[1001] component-blocking-overlay"
	>
		<div class="bg-white border font-medium p-4 rounded shadow-xl">
			<Loading :text="text" />
		</div>
	</div>
</template>