<script lang="ts">
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond/dist/filepond.min.css';
import vueFilePond from 'vue-filepond';
import { defineComponent } from 'vue';
import { File } from '../../models/crm';


// Create component
const FilePond = vueFilePond(
	FilePondPluginFileValidateType,
	FilePondPluginFileEncode,
);

export default defineComponent({
	name: 'FileUploadFilePond',
	props: {
		allowMultiple: {
			default: false,
			type: Boolean,
		},
		initialFiles: {
			default: () => [],
			type: Array,
		},
		labelIdle: {
			default: 'Arraste seus arquivos ou <span class="filepond--label-action">clique aqui</span>',
			type: String,
		},
	},
	components: {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		FilePond,
	},
	emits: [
		'addfile',
		'updatefiles',
	],
	methods: {

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		addfile(error: any, file: File): File | boolean {
			if (error) return false;
			return file;
		},

		reset(): void {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			((this as any).$refs?.filePond as typeof FilePond).removeFiles();
		},

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		updatefiles(files: File[]): void {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			this.$emit('updatefiles', files);
		},

	},
});
</script>
<template>
	<file-pond
		v-bind="$attrs"

		ref="filePond"

		:allow-file-encode="true"
		:allow-file-type-validation="false"
		:allow-multiple="allowMultiple"
		:allow-remove="true"
		:allow-replace="true"
		:files="initialFiles"
		:instant-upload="false"

		label-button-remove-item="Remover"
		label-file-loading="Carregando"
		:label-idle="labelIdle"

		@addfile="addfile"
		@updatefiles="updatefiles"
	/>

</template>
<style lang="scss" scoped></style>