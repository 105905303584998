<script lang="ts">
import {
	defineComponent, inject
} from 'vue';
import {
	// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
	SearchResult, ResultList, Result, EventBus, Lodash, LegalCaseResult, ContactResult
} from '../../models/crm';
import serviceSearch from '../../services/search';
import { mapState } from 'pinia';
import { useAccountStore } from '../../stores/account';


export default defineComponent({
	data() {
		return {
			searchStr: '' as string,

			searchResult: {
				contact: [],
				legalCase: [],
			} as SearchResult,

			selectedResultList: ResultList.CONTACTS,
			seeMoreIndex: 1 as number,

			buttonsList: [
				ResultList.CONTACTS,
				ResultList.LEGAL_CASES,
			],

			translation: {
				contact: 'Contatos',
				legalCase: 'Casos',
				task: 'Tarefas',
				funnel: 'Fluxos de Casos'
			} as Record<ResultList, string>,

			loading: false as boolean,

			ResultList,
			eventBus: inject<EventBus>('eventBus'),
			lodash: inject<Lodash>('lodash'),
		};
	},
	computed: {
		...mapState(useAccountStore, [ 'user' ]),

		officeId(): number {
			return +(this.user?.office_id || 0);
		},

		shouldShowSearchResult(): boolean {
			return this.searchStr.length > 0;
		},

		hasNoResults(): boolean {
			return !(Object.values(this.searchResult)).some(list => list.length > 0);
		},

		currentList(): Result[] {
			return this.searchResult[this.selectedResultList as keyof SearchResult]
				.slice(0, 5*this.seeMoreIndex);
		},
	},
	watch: {
		selectedResultList(newValue, oldValue): void {
			if (newValue !== oldValue) {
				this.seeMoreIndex = 1;
			}
		},

		searchStr(newValue, oldValue): void {
			if (newValue.length > 0 && newValue !== oldValue) {
				this.loading = true;
				this.lodash?.debounce(() => this.callSearch(newValue), 600)();
			}
		}
	},
	methods: {
		removeEm(htmlStr: string | undefined): string {
			return htmlStr?.replace(/(<em>)|(<\/em>)/gi, '') || '';
		},
		async callSearch(previousSearchStr : string): Promise<void> {
			if (previousSearchStr === this.searchStr) {
				try {
					const { data } = await serviceSearch.search(this.searchStr);

					this.searchResult = {
						contact: data.contacts,
						legalCase: data.legal_cases,
					};
				} catch (e) {
					console.error(e);

					this.eventBus?.emit(
						'Toast/add',
						{
							content: 'Erro ao pesquisar na busca geral',
							type: 'error'
						}
					);
				} finally {
					this.loading = false;
				}
			}
		},

		resetSearch(): void {
			this.searchStr = '';
		},

		seeMore(): void {
			this.seeMoreIndex += 1;
		},

		translate(resultList: ResultList): string {
			return this.translation[resultList] || resultList;
		},

		changeResultList(resultList: ResultList): void {
			this.selectedResultList = resultList;
		},

		goToOption(result: Result): void {
			if (this.selectedResultList === ResultList.CONTACTS) {
				this.$router.push({
					name: 'specific-contact', params: { id: result.id }
				});
			}
			if (this.selectedResultList === ResultList.LEGAL_CASES) {
				this.$router.push({
					name: 'legal-case-details', params: { id: result.id }
				});
			}
		}
	}
});
</script>
<template>
	<div class="flex ml-[40px] flex-1 items-center justify-start">
		<div
			v-click-outside="resetSearch"
			class="relative h-10 p-0 hidden sm:flex flex-1 max-w-[500px] lg:max-w-[600px]"
		>
			<div class="flex flex-1 relative">
				<input
					v-model="searchStr"
					type="text"
					placeholder="O que deseja encontrar? Busque por Contatos e Casos"
					autocomplete="off"
					:class="{ 'rounded-b-none border-[#3F87F5] hover:border-[#3F87F5] border-b-0 focus:border-b-0': shouldShowSearchResult }"
					class="z-20 outline-0 w-full text-sm font-medium text-[#3B424D] border border-[#A6AAAD] border-solid rounded-[20px] h-10 pl-10 items-center hover:border-gray-500 focus:border-[#3F87F5]"
				>
				<div class="absolute z-30 left-[10px] top-[10px] h-[20px] w-[20px] flex justify-center items-center rounded-full">
					<svg
						viewBox="0 0 17 17"
						fill="#A6AAAD"
					>
						<path
							d="M16.1306 13.7224L12.6547 10.2466C12.6363 10.2281 12.6146 10.2152 12.5953 10.198C13.2792 9.1605 13.6784 7.91859 13.6784 6.58304C13.6784 2.95001 10.7333 0.00488281 7.10031 0.00488281C3.46734 0.00488281 0.522217 2.95001 0.522217 6.58298C0.522217 10.2159 3.46728 13.1611 7.10025 13.1611C8.43586 13.1611 9.6777 12.7619 10.7152 12.0779C10.7324 12.0972 10.7452 12.1189 10.7637 12.1373L14.2397 15.6133C14.7619 16.1354 15.6084 16.1354 16.1306 15.6133C16.6527 15.0911 16.6527 14.2446 16.1306 13.7224ZM7.10031 10.8807C4.7267 10.8807 2.80257 8.95653 2.80257 6.58298C2.80257 4.20936 4.72676 2.28524 7.10031 2.28524C9.4738 2.28524 11.398 4.20943 11.398 6.58298C11.398 8.95653 9.4738 10.8807 7.10031 10.8807Z"
						/>
					</svg>
				</div>
			</div>
			<div
				v-if="shouldShowSearchResult"
				class="absolute top-[40px] flex flex-col w-full overflow-y-auto max-h-[500px] bg-white border border-[#3F87F5] border-solid rounded-b-[20px] rounded-t-0"
			>
				<span
					v-if="loading"
					class="flex items-center justify-center text-sm h-[100px]"
				>
					<svg
						viewBox="25 25 50 50"
						class="w-[3.75em] origin-center spinner"
						fill="#3F87F5"
					>
						<circle
							cx="50"
							cy="50"
							r="20"
						/>
					</svg>
				</span>
				<template v-else>
					<span
						v-if="hasNoResults"
						class="flex items-center justify-center text-sm h-[100px]"
						v-html="'Nenhum resultado encontrado'"
					/>
					<template v-else>
						<div
							class="p-2 flex flex-row gap-1 z-10"
						>
							<template
								v-for="button in buttonsList"
								:key="`button-${button}`"
							>
								<button
									v-show="searchResult[(button) as keyof (typeof searchResult)].length !== 0"
									:class="{'btn--ghost': selectedResultList !== button}"
									class="btn btn--primary normal-case text-[12px] leading-[13.5px] h-auto min-h-[auto] text-white font-medium !px-2 !py-2 rounded-full"
									@click="changeResultList(button as ResultList)"
								>
									{{ `${translate(button)} (${searchResult[(button) as keyof (typeof searchResult)].length})` }}
								</button>
							</template>
						</div>
						<ul class="box-border w-full z-20 m-0 p-2 pt-0 scroll-smooth leading-normal">
							<li
								class="text-[#3B424D] text-lg uppercase font-bold border-solid border-b border-[rgb(239,239,239)] px-2 w-full py-0 screen-bp:pt-0.5"
							>
								{{ translate(selectedResultList) }}
							</li>
							<li
								v-for="(result, index) in currentList"
								:key="`search-list-item-${index}`"
								class="flex gap-2 w-full pr-1 py-1 items-center screen-bp:py-2 text-xs screen-bp:text-sm pl-4 cursor-pointer rounded-[11px] hover:bg-[#3F87F5] active:bg-transparent text-[#3B424D] hover:text-white active:text-[#3B424D]"

								@click.stop="goToOption(result)"
							>
								<span
									class="flex-none flex items-center justify-center text-xs font-semibold text-[A6AAAD]"
									v-html="index+1"
								/>
								<div class="p-0 m-0 flex-1 max-w-full flex flex-col justify-center items-start">
									<span
										class="font-semibold capitalize flex flex-row gap-1"
										v-html="removeEm(result.name)"
									/>
									<span
										v-if="
											selectedResultList === ResultList.CONTACTS
												&& (result as ContactResult)?.cpf
										"
										class="text-[11px] text-ellipsis screen-bp:text-xs"
										v-html="`CPF: ${removeEm((result as ContactResult).cpf)}`"
									/>
									<span
										v-if="
											selectedResultList === ResultList.CONTACTS
												&& Array.isArray((result as ContactResult)?.emails)
												&& ((result as ContactResult).emails as string[]).length > 0
										"
										class="text-[11px] text-ellipsis screen-bp:text-xs"
										v-html="`Email: ${removeEm(((result as ContactResult).emails as string[])[0])}`"
									/>
									<span
										v-if="
											selectedResultList === ResultList.LEGAL_CASES
												&& (result as LegalCaseResult)?.case_number
										"
										class="text-[11px] text-ellipsis screen-bp:text-xs"
										v-html="`N.º do caso: ${removeEm((result as LegalCaseResult).case_number)}`"
									/>
								</div>
							</li>
							<li
								v-if="searchResult[selectedResultList as keyof SearchResult].length > 5*seeMoreIndex"
								class="flex btn text-primary normal-case h-auto min-h-[auto] font-medium bg-transparent mx-auto items-center justify-center px-2 py-1 hover:underline hover:bg-transparent text-xs rounded border-0"
								@click.stop="seeMore"
							>
								{{ 'Ver mais' }}
								<div class="w-[16px] h-[16px]">
									<svg
										viewBox="0 0 24 24"
										fill="#3F87F5"
									>
										<path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
									</svg>
								</div>
							</li>
						</ul>
					</template>
				</template>
			</div>
		</div>
	</div>
</template>

<style lang="scss">
	.spinner {
		animation: spinner__rotate 2s linear infinite;

		circle {
			fill: none;
			stroke: #3F87F5;
			stroke-width: 3;
			stroke-dasharray: 1, 200;
			stroke-dashoffset: 0;
			stroke-linecap: round;
			animation: spinner__dash 1.5s ease-in-out infinite;
		}
	}

	@keyframes spinner__rotate {
		100% {
			transform: rotate(360deg);
		}
	}

	@keyframes spinner__dash {
		0% {
			stroke-dasharray: 1, 200;
			stroke-dashoffset: 0;
		}

		50% {
			stroke-dasharray: 90, 200;
			stroke-dashoffset: -35px;
		}

		100% {
			stroke-dashoffset: -125px;
		}
	}

</style>