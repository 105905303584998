<script lang="ts">
import {
	defineComponent, PropType
} from 'vue';

type Option = {
	value: string | number;
	label: string;
}

export default defineComponent({
	props: {
		disabled: {
			default: false,
			type: Boolean,
		},
		label: {
			required: true,
			type: String,
		},
		modelValue: [String, Number],
		options: {
			type: Array as PropType<Option[]>,
		},
		required: {
			default: false,
			type: Boolean,
		},
	},
	emits: [
		'update:modelValue',
	],
	methods: {

		verifyToEmit(e: Event, optionValue: string | number): void {
			if ((<HTMLInputElement>e.target).value === optionValue) {
				this.$emit('update:modelValue', optionValue);
			}
		},

	},
});
</script>
<template>
	<div class="component-form-control-select">
		<div class="block font-semibold mb-1 text-gray-500 text-xs">
			<span>{{ label }}</span>
			<span v-if="required" class="ml-1 text-red-600">*</span>
		</div>
		<div class="flex w-full">
			<div v-for="(option, index) of options" :key="`radio-option-${option}`"
				class="flex items-center justify-center shrink-0 mx-2 mt-1" :class="[index === 0 ? 'ml-0' : '']">
				<input :id="`form-control-radio-${option.value}`" type="radio" :name="`input-radios-${label}`"
					:value="option.value" @change="verifyToEmit($event, option.value)"
					class="radio radio-sm radio-primary mr-2" :class="[modelValue !== option.value && 'border-[#e5e7eb]']"
					:checked="modelValue === option.value" :disabled="disabled">
				<label :for="`form-control-radio-${option.value}`"
					class="pointer font-semibold inline-block align-center m-0 box-border text-xs">
					{{ option.label }}
				</label>
			</div>
		</div>
	</div>
</template>
<style lang="scss" scoped>
.component-form-control-select {

	&.disabled {
		opacity: 0.5;
	}

	&.validation-error {
		input {
			border: 1px solid;
			border-color: #dc3545;
		}
	}
}
</style>
