<script lang="ts">
import {
	PropType, defineComponent
} from 'vue';
import {
	Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ChartData
} from 'chart.js';
import { Bar } from 'vue-chartjs';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ChartDataLabels);

export default defineComponent({
	name: 'ChartComponent',
	components: {
		Bar
	},
	props: {
		data: {
			required: true,
			type: Object as PropType<ChartData<'bar'>>,
		},
		options: {
			required: true,
			type: Object,
		}
	},
	data() {
		return {
			Bar,
		};
	},
});
</script>

<template>
	<Bar
		:data="data"
		:options="options"
	/>
</template>

<style lang="scss" scoped></style>