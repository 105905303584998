/* eslint-disable @typescript-eslint/no-explicit-any */
import FileSaver from 'file-saver';
import { DateTime } from 'luxon';
import hash from 'object-hash';
import prettyBytes from 'pretty-bytes';
import { v4 as uuidv4 } from 'uuid';

export default {

	downloadFileFromBuffer({
		blob, fileName
	}: {
		blob: Blob,
		fileName: string
	}): void {
		if (!fileName) fileName = `download-${DateTime.now()}`;

		FileSaver.saveAs(blob, fileName);
	},

	getFormattedCurrencyFromNumber(value: number): string {
		const numberFormat = Intl.NumberFormat('pt-BR', {
			style: 'currency',
			currency: 'BRL',
		});
		return numberFormat.format(value);
	},

	getNumberFromFormattedCurrency(value: string): number {
		const cleanValue = value.replace(/R\$/, '')
			.replace(/ /g, '')
			.replace(/\./g, '')
			.replace(/,/g, '.');

		return Number(cleanValue);
	},

	getFormattedFileSize(sizeInBytes: number): string {
		return prettyBytes(sizeInBytes, { locale: 'pt-BR' });
	},

	getFormattedPhoneNumber(phoneNumber: string): string {
		if (!phoneNumber) return '';

		if (phoneNumber.length === 8) return `${phoneNumber.slice(0, 4) }-${ phoneNumber.slice(4, 8)}`;

		if (phoneNumber.length === 9) return `${phoneNumber.slice(0, 5) }-${ phoneNumber.slice(5, 9)}`;

		return '';
	},

	getUniqueArrayValues(arr: any[]): any[] {
		return [ ...new Set(arr) ];
	},

	getTippy(content: string, options: Record<string, any> = {}): Record<string, any> {
		const placement = options && options.placement ? options.placement : 'right';

		return {
			arrow: true,
			content,
			placement,
			theme: 'material'
		};
	},

	getHash(value: string): string {
		return hash(value);
	},

	getUuid(): string {
		return uuidv4();
	},

};
