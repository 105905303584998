import { defineStore } from 'pinia';
import { LegalCase } from '../../../models/crm';
import serviceLegalCases from '../../../services/API/crm/legalCases';

type LegalCasesState = {
	legalCasesList: LegalCase[];
    maxLegalCases: number;
	isRequestingLegalCases: boolean;

	searchedLegalCases: LegalCase[];
	searchMaxLegalCases: number;
}

export const useLegalCasesStore = defineStore('legalCases', {
	state: (): LegalCasesState => ({
		legalCasesList: [] as LegalCase[],
		maxLegalCases: 100,
		isRequestingLegalCases: false,

		searchedLegalCases: [] as LegalCase[],
		searchMaxLegalCases: 0,
	}),
	actions: {
		setLegalCasesList(value: LegalCase[]): void {
			this.legalCasesList = value;
		},

		setMaxLegalCases(value: number): void {
			this.maxLegalCases = value;
		},

		addLegalCasesToList(value: LegalCase[]): void {
			this.legalCasesList = this.legalCasesList.concat(value);
		},

		async deleteLegalCase(legalCaseId: number | string): Promise<void> {
			await serviceLegalCases.delete(legalCaseId);

			const index = this.legalCasesList.findIndex(legalCase => legalCase.id == legalCaseId);

			if (index === -1) return;

			this.legalCasesList.splice(index, 1);
		},

		async loadLegalCases(): Promise<void> {
			this.isRequestingLegalCases = true;

			const { data } = await serviceLegalCases.getAll(9999, 1, 'name:asc', '');

			this.legalCasesList = data.legal_cases;

			this.isRequestingLegalCases = false;

			this.maxLegalCases = data.legal_cases.length;
		},

		async paginatedLoadLegalCases({
			perPage, page, orderBy, filters
		}: {
                perPage: number;
                page: number;
				orderBy: string;
				filters: string;
            }
		): Promise<void> {
			this.isRequestingLegalCases = true;

			const { data: mainData } = await serviceLegalCases.getAll(perPage, page, orderBy, filters);

			this.maxLegalCases = mainData.total_count;

			if (this.legalCasesList.length === 0) {
				this.legalCasesList = mainData.legal_cases;

				this.isRequestingLegalCases = false;

				return;
			}

			if (this.legalCasesList.length === perPage * (page - 1)) {
				this.legalCasesList = this.legalCasesList.concat(mainData.legal_cases);

				this.isRequestingLegalCases = false;

				return;
			}

			if (this.legalCasesList.length > perPage * (page - 1)) {
				if (page === 1) {
					this.legalCasesList = mainData.legal_cases;

					this.isRequestingLegalCases = false;

					return;
				}

				const { data: stepData } =
					await serviceLegalCases.getAll(perPage * page, 1, orderBy, filters);

				this.legalCasesList = stepData.legal_cases;

				this.isRequestingLegalCases = false;

				return;
			}

			throw new Error('Impossível paginar! Provavelmente a aplicação se encontra na página final');
		},

		async searchLegalCasesByName(
			name: string,
			orderBy: string,
			filters: string,
			page = 1,
			perPage = 10
		): Promise<void> {
			const { data: mainData } = await serviceLegalCases.searchByName({
				name, orderBy, filters, perPage, page
			});

			if (name.length > 0) {
				this.searchMaxLegalCases = mainData.total_count;
			} else {
				this.searchMaxLegalCases = 0;
			}

			this.searchedLegalCases = mainData.legal_cases;

			return;
		},

		useNormalLegalCases(): void {
			this.searchMaxLegalCases = 0;
			this.searchedLegalCases = [];
		}
	},
});