<script lang="ts">
import {
	DefineComponent,
	defineComponent,
	inject,
} from 'vue';
import { mapActions } from 'pinia';
import axios, { AxiosError } from 'axios';

import {
	AxiosErrorResponse, EventBus
} from '../../../models/crm';

import { useCategoriesStore } from '../../../stores/models/financial/categories';

import BlockingOverlay from '../../../components/BlockingOverlay/BlockingOverlay.vue';
import FormValidator from '../../../components/Form/FormValidator.vue';
import InputText from '../../../components/Form/InputText.vue';
import ModalBasic from '../../../components/Modal/ModalBasic.vue';
import {
	Category, CategoryToSend
} from '../../../models/financial';

export default defineComponent({
	components: {
		BlockingOverlay,
		FormValidator,
		InputText,
		ModalBasic,
	},
	emits: [
		'category-created',
	],
	data() {
		return {

			eventBus: inject<EventBus>('eventBus'),

			model: {
				description: '',
				name: '',
			} as CategoryToSend,

			requesting: {
				save: false,
			},

			type: null as 'income' | 'expense' | null,

			categoryToEdit: null as Category | null,
		};
	},
	methods: {
		...mapActions(useCategoriesStore, [ 'createIncomeCategory', 'createExpenseCategory', 'editExpenseCategory', 'editIncomeCategory' ]),

		hide(): void {
			this.type = null;
			(this.$refs.modal as typeof ModalBasic).hide();
		},

		resetModel(): void {
			this.model.description = '';
			this.model.name = '';
		},

		async save(): Promise<void> {
			const validateResult = (this.$refs.formValidator as typeof FormValidator).validate();
			const { isValid } = validateResult;

			if (isValid) {
				this.requesting.save = true;

				this.eventBus?.emit('BlockingOverlay/show', {
					text: 'Aguarde, criando categoria'
				});

				try {
					let response = false;

					if (this.categoryToEdit?.id) {
						if (this.type === 'income') {
							response = await this.editIncomeCategory(this.categoryToEdit.id, this.model);
						} else if (this.type === 'expense') {
							response = await this.editExpenseCategory(this.categoryToEdit.id, this.model);
						}
					} else {
						if (this.type === 'income') {
							response = await this.createIncomeCategory(this.model);
						} else if (this.type === 'expense') {
							response = await this.createExpenseCategory(this.model);
						}
					}


					if (!response) throw new Error('Response is false');

					(this.$refs.modal as typeof ModalBasic).hide();

					this.eventBus?.emit(
						'Toast/add',
						{
							content: 'Categoria criada com sucesso',
							type: 'success'
						}
					);
				} catch (err) {
					console.error(err);

					if (axios.isAxiosError(err) && err.response) {
						const axiosError = err as AxiosError;
						const response = axiosError.response as AxiosErrorResponse;

						const fieldsWithError = Object.entries(response.data.errors).map(([ key, value ]) => {
							return [ key, (value[0] ?? 'Falha ao criar categoria') ];
						});

						if (fieldsWithError.length > 0) {
							const formValidator = this.$refs.formValidator as typeof FormValidator;
							fieldsWithError.forEach(fieldWithError => {
								formValidator.setFieldError(fieldWithError[0], fieldWithError[1]);
							});
						}
					} else {
						// Handle non-Axios errors
						this.eventBus?.emit(
							'Toast/add',
							{
								content: 'Falha ao criar categoria',
								type: 'error'
							}
						);
					}
				}

				this.$emit('category-created', {
					type: this.type,
				});
			}

			this.eventBus?.emit('BlockingOverlay/hide');
		},

		show(type: 'income' | 'expense'): void {
			(this.$refs.formValidator as typeof FormValidator).reset();
			this.resetModel();

			this.type = type;

			this.model.name = this.categoryToEdit?.name || '';
			this.model.description = this.categoryToEdit?.description || '';

			(this.$refs.modal as typeof ModalBasic).show();
		},

		edit(type: 'income' | 'expense', category: Category): void {
			this.categoryToEdit = category;
			this.show(type);
		},

		// Events

		buttonCancelClick(): void {
			this.hide();
		},

		buttonSaveClick(): void {
			this.save();
		},

		modal_hide(): void {
			this.resetModel();
			this.categoryToEdit = null;
		},

	}
});
</script>

<template>
	<ModalBasic
		ref="modal"

		size="lg"
		:title="categoryToEdit?.id ? 'Editar Categoria' : 'Nova Categoria'"

		@hide="modal_hide"
	>
		<template #content>
			<FormValidator
				ref="formValidator"
				:refs="($refs as DefineComponent)"
				:validation-summary-show="true"
				validation-summary-class="mb-4 mx-4 p-3"
			>
				<template
					field="name"
					label="Nome"
					message="Preenchimento obrigatório"
					:invalidIf="() => !model.name"
				/>
			</FormValidator>

			<div class="mb-4 px-4">
				<InputText
					ref="name"
					v-model="model.name"

					label="Nome"
					required
				/>
			</div>
			<div class="mb-4 px-4">
				<InputText
					ref="inputDescription"
					v-model="model.description"
					label="Descrição"
				/>
			</div>
		</template>
		<template #footer>
			<fieldset class="flex-1 flex overflow-hidden rounded-b-md">
				<button
					class="flex-1 bg-gray-200 font-medium p-4 text-gray-500 hover:bg-gray-300 active:bg-gray-200"
					@click="buttonCancelClick"
				>
					CANCELAR
				</button>
				<button
					class="flex-1 bg-primary font-medium p-4 text-white hover:bg-blue-600 active:bg-primary"
					@click="buttonSaveClick"
				>
					SALVAR
				</button>
			</fieldset>
		</template>
	</ModalBasic>

	<Teleport to="body">
		<BlockingOverlay />
	</Teleport>

</template>

<style lang="scss" scoped></style>