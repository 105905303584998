<script lang="ts">
import {
	PropType, defineComponent
} from 'vue';
import { ReceivedEmail } from '../../../../models/crm';
import ModalBasic from '../../../../components/Modal/ModalBasic.vue';
import { DateTime } from 'luxon';
import DOMPurify from 'dompurify';

export default defineComponent({
	components: {
		ModalBasic,
	},
	props: {
		email: {
			type: Object as PropType<ReceivedEmail>,
			required: true,
		},
		isVisible: {
			type: Boolean,
			default: false,
		}
	},
	computed: {
		formattedDate() {
			return DateTime.fromISO(this.email.created_at).toFormat('dd/MM/yyyy HH:mm:ss');
		},
		sanitizedBody() {
			return DOMPurify.sanitize(this.email.body, { USE_PROFILES: { html: true } });
		}
	},
	methods: {
		show() {
			(this.$refs.modal as typeof ModalBasic).show();
		},

		hide() {
			(this.$refs.modal as typeof ModalBasic).hide();
		}
	}
});
</script>

<template>
	<ModalBasic
		ref="modal"
		extra-content-classes="!overflow-y-hidden"
		:dismissable="true"
		size="xl"
		:visible="isVisible"
		title="''"
	>
		<template #header>
			<div class="modal-header relative w-full">
				<h2 class="text-gray-500 mb-5 font-semibold">
					{{ email.sender }}
				</h2>
				<h2 class="text-2xl">
					{{ email.subject }}
				</h2>
				<p>{{ formattedDate }}</p>
				<button
					class="close-button absolute top-0 right-0"
					@click="hide"
				>
					<svg
						class="w-6 h-6"
						fill="none"
						stroke="currentColor"
						viewBox="0 0 24 24"
						xmlns="http://www.w3.org/2000/svg"
					><path
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="2"
						d="M6 18L18 6M6 6l12 12"
					/></svg>
				</button>
			</div>
		</template>

		<template #content>
			<iframe
				class="h-80 w-full"
				:srcdoc="sanitizedBody"
				width="100%"
				height="500"
			/>
		</template>
	</ModalBasic>
</template>

  <style scoped>
  </style>
