import { Notification } from './../models/crm';
import { AccountResponse } from '../models/crm';
import axios, {
	AxiosRequestConfig, AxiosResponse
} from 'axios';

import storage from './storage';

const getDefaultOptions = (): AxiosRequestConfig => {
	let accessToken = localStorage.getItem('cj-access-token') || storage.getItem('localStorage', 'access-token');
	if (accessToken) {
		accessToken = accessToken.replace(/"/g, '');
	}

	const defaultHeaders = {
		Accept: 'application/json',
		'Content-Type': 'application/json',
	};

	const defaultOptions = {
		headers: {
			...defaultHeaders,
			Authorization: `Bearer ${accessToken}`,
		},
	};

	return defaultOptions;
};

const getAccount = async(): Promise<AxiosResponse<AccountResponse>> => {
	const params =
    'include=office,office.users,office.notifications,office.next_plan';

	const url = `${import.meta.env.VITE_API_URL}/account?${params}`;

	const defaultOptions = getDefaultOptions();

	return await axios.get(url, defaultOptions);
};

const getNotifications = async(officeId: number)
	: Promise<AxiosResponse<{ notifications: Notification[]}>> => {
	const url = `${
		import.meta.env.VITE_API_URL
	}/offices/${officeId}/notifications`;
	const defaultOptions = getDefaultOptions();
	return await axios.get(url, defaultOptions);
};

export default {
	getAccount,
	getNotifications,
};
