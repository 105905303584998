<script lang="ts">
import draggable from 'vuedraggable';

import serviceFunnels from '../../../../services/API/crm/funnels';

import BlockingOverlay from '../../../../components/BlockingOverlay/BlockingOverlay.vue';
import FormValidator from '../../../../components/Form/FormValidator.vue';
import InputText from '../../../../components/Form/InputText.vue';
import SelectField from '../../../../components/Form/SelectField.vue';
import Loading from '../../../../components/Loading/LoadingComponent.vue';
import ModalBasic from '../../../../components/Modal/ModalBasic.vue';

import {
	DefineComponent,
	defineComponent, inject, PropType
} from 'vue';
import {
	mapState
} from 'pinia';

import { useAccountStore } from '../../../../stores/account';

import {
	EventBus, Lodash, Funnel, FunnelStep, FunnelStepIntermediate
} from '../../../../models/crm';

type FunnelStepsMixed = {
	loadedData: FunnelStep[],
	model: FunnelStepIntermediate[]
}

export default defineComponent({
	components: {
		draggable,

		BlockingOverlay,
		InputText,
		FormValidator,
		Loading,
		SelectField,
		ModalBasic,
	},
	props: {
		funnelId: {
			type: [ String, Number ],
			default: null
		},
		funnelsList: {
			default: () => [], type: Array as PropType<Funnel[]>
		},
	},
	emits: [
		'funnel-created',
		'funnel-updated',
		'funnel-steps-updated',
	],
	data() {
		return {
			eventBus: inject<EventBus>('eventBus'),
			lodash: inject<Lodash>('lodash'),

			funnelSteps: {
				loadedData: [],
				model: [],
			} as FunnelStepsMixed,

			loadedFunnel: null as Funnel | null,

			model: {
				description: '',
				is_main: false,
				name: '',
			},

			createdId: null as string | number | null,

			isEdit: false,

			requesting: {
				loadFunnel: false,
				save: false,
			} as Record<string, boolean>,

			stepToDeleteId: '' as string | number,
			stepToDeleteIndex: -1 as number,
			substituteStep: '' as string | number,
		};
	},
	computed: {

		hasValidSteps(): boolean {
			return (this.funnelSteps.model.length > 0) && this.funnelSteps.model.every(step => step.name !== '');
		},

		isEditing(): boolean {
			return this.isEdit;
		},

		...mapState(useAccountStore, [ 'user' ]),

		selectStepOptionsList(): { label: string, value: number }[] {
			if (this.funnelsList && !!this.funnelId) {
				return this.funnelsList
					.find((funnel: Funnel) => funnel.id == this.funnelId)
					?.funnel_steps?.map((step: FunnelStep) => ({
						label: step.name
						, value: step.id
					})).filter(option => option.value != this.stepToDeleteId) || [];
			}

			return [];
		},

	},
	watch: {
		'funnelSteps': {
			deep: true,
			handler: function(): void {
				this.scrollStepsAreaToRight();
			}
		},
	},
	methods: {
		async loadFunnel(): Promise<void> {
			if (!this.funnelId) {
				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Falha ao carregar fluxo de casos',
						type: 'error'
					}
				);

				return;
			}

			const { funnelId } = this;

			this.requesting.loadFunnel = true;

			try {
				const { data } = await serviceFunnels.getById(funnelId);

				this.loadedFunnel = data;

				this.model.name = data.name || '';
				this.model.description = data.description || '';
				this.model.is_main = data.is_main || false;

				this.funnelSteps.loadedData = data.funnel_steps;
				this.funnelSteps.model = this.lodash?.orderBy(data.funnel_steps, [ 'order' ], [ 'asc' ]) || data.funnel_steps;
			} catch (err) {
				console.error(err);

				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Falha ao carregar fluxo de casos',
						type: 'error'
					}
				);
			}

			this.requesting.loadFunnel = false;
		},

		resetModel(): void {
			this.model.description = '';
			this.model.is_main = false;
			this.model.name = '';

			this.funnelSteps.loadedData = [];
			this.funnelSteps.model = [];
		},

		async save(): Promise<void> {
			const validateResult =
				(this.$refs.formValidatorModalFunnel as typeof FormValidator)
					.validate();
			const { isValid } = validateResult;

			let { funnelId } = this;

			if (isValid) {
				const payload = {
					...this.model,
				};

				this.requesting.save = true;

				if (this.isEditing) {
					this.eventBus?.emit('BlockingOverlay/show', {
						text: 'Aguarde, atualizando fluxo de casos'
					});

					try {
						if (!funnelId) {
							console.error('Funnel id not found');

							this.eventBus?.emit(
								'Toast/add',
								{
									content: 'Falha ao salvar fluxo de casos',
									type: 'error'
								}
							);

							return;
						}

						const response = await serviceFunnels.update({
							data: payload,
							id: funnelId,
						});

						if (response.status !== 200) {
							throw new Error('Falha ao atualizar fluxo de casos');
						}

						(this.$refs.modal as typeof ModalBasic).hide();

						this.eventBus?.emit(
							'Toast/add',
							{
								content: 'Fluxo de casos atualizado com sucesso',
								type: 'success'
							}
						);

						this.$emit('funnel-updated', response.data);
					} catch (err) {
						console.error(err);

						this.eventBus?.emit(
							'Toast/add',
							{
								content: 'Falha ao atualizar fluxo de casos',
								type: 'error'
							}
						);
					}
				} else {
					this.eventBus?.emit('BlockingOverlay/show', {
						text: 'Aguarde, criando o fluxo de casos'
					});

					try {
						const createResponse = await serviceFunnels.create(payload);

						if (createResponse.status !== 200) {
							throw new Error('Falha ao criar fluxo de casos');
						}

						funnelId = createResponse.data.id;

						this.createdId = funnelId;

						(this.$refs.modal as typeof ModalBasic).hide();

						this.eventBus?.emit(
							'Toast/add',
							{
								content: 'Fluxo de casos criado com sucesso',
								type: 'success'
							}
						);

						this.$emit('funnel-created', createResponse.data);
					} catch (err) {
						console.error(err);

						this.eventBus?.emit(
							'Toast/add',
							{
								content: 'Falha ao criar fluxo de casos',
								type: 'error'
							}
						);
					}
				}

				if (!funnelId) {
					console.error('Funnel id not found');

					this.eventBus?.emit(
						'Toast/add',
						{
							content: 'Falha ao salvar fluxo de casos',
							type: 'error'
						}
					);

					return;
				}

				const parsedFunnelId = !Number.isInteger(funnelId)
					? parseInt(funnelId as string)
					: funnelId as number;

				if (!Number.isNaN(parsedFunnelId)) this.saveFunnelSteps(this.funnelSteps, parsedFunnelId);

				this.eventBus?.emit('BlockingOverlay/hide');

				this.requesting.save = false;
			} else {
				(this.$refs.modal as typeof ModalBasic).$refs.modalContent.scrollTo({
					behavior: 'smooth',
					left: 0,
					top: 0,
				});
			}
		},

		async saveFunnelSteps(funnelSteps: FunnelStepsMixed, funnelId: number): Promise<void> {
			const { model } = funnelSteps;

			try {
				for (let i = 0;i < model.length;++i) {
					const step = model[i];
					if (!step) break;

					if (!step?.id) {
						await serviceFunnels.funnelStepsCreate({
							funnel_id: funnelId,
							name: step.name,
							office_id: step.office_id,
							order: i + 1,
						});
					} else {
						await serviceFunnels.funnelStepsUpdate({
							data: {
								funnel_id: funnelId,
								name: step.name,
								order: i + 1,
							},
							id: step.id,
						});
					}
				}

				this.$emit('funnel-steps-updated');
			} catch (err) {
				console.error(err);

				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Falha ao criar etapa do fluxo de casos',
						type: 'error'
					}
				);
			}
		},

		scrollStepsAreaToRight(): void {
			this.$nextTick(
				() => {
					if (this.$refs.containerAddedSteps
						&& (this.$refs.containerAddedSteps as HTMLDivElement).scrollTo
					) {
						(this.$refs.containerAddedSteps as HTMLDivElement).scrollTo({ left: 99999999 });
					}
				}
			);
		},

		async show(isEdit?: boolean): Promise<void> {
			this.resetModel();
			this.isEdit = !!isEdit;

			if (!isEdit) {
				this.createdId = this.funnelId;
			}

			if (isEdit && this.funnelId) {
				this.loadFunnel();
			}

			(this.$refs.modal as typeof ModalBasic).show();
		},

		async stepRemove({
			id, index
		}: {
			id: string | number,
			index: number
		}, substituteStepId: string | number | undefined = undefined): Promise<void> {
			// Remove for steps created on UI only (not persisted yet)
			if (!id) {
				this.funnelSteps.model.splice(index, 1);
				return;
			}

			// Remove for step already persisted
			try {
				if (this.funnelSteps.loadedData.length < 2) {
					throw new Error('Não é possível excluir todas as etapas salvas de um fluxo de casos');
				}

				await serviceFunnels.funnelStepsDelete(id, substituteStepId);

				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Etapa excluída com sucesso',
						type: 'success'
					}
				);

				this.loadFunnel();

				this.$emit('funnel-steps-updated');
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (err: any) {
				console.error(err);

				this.eventBus?.emit(
					'Toast/add',
					{
						content: (err?.message as string) || 'Falha ao excluir etapa',
						type: 'error'
					}
				);
			}
		},

		closeModal(): void {
			(this.$refs.modalChangeStep as typeof ModalBasic).hide();
			this.resetModalChangeFunnel();
		},

		async confirmDeleteStep(): Promise<void> {
			try {
				if (!this.stepToDeleteId || this.stepToDeleteIndex < 0) {
					throw new Error('Etapa não encontrada');
				}

				if (!this.substituteStep) {
					throw new Error('Não foi especificada a etapa substituta');
				}

				const data = {
					id: this.stepToDeleteId,
					index: this.stepToDeleteIndex
				};

				await this.stepRemove(data, this.substituteStep);

				(this.$refs.modalChangeStep as typeof ModalBasic).hide();

				this.resetModalChangeFunnel();
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (e: any) {
				console.error(e);

				this.eventBus?.emit(
					'Toast/add',
					{
						content: (e?.message as string) || 'Erro ao deletar etap',
						type: 'error'
					}
				);
			}
		},

		resetModalChangeFunnel(): void {
			this.stepToDeleteId = '';
			this.substituteStep = '';
		},

		// Events

		buttonStepAddClick(): void {
			if (!this.user?.office_id) return;

			const officeId = this.user.office_id;

			const parsedoOfficeId = !Number.isInteger(officeId)
				? parseInt(officeId as string)
				: officeId as number;
			if (Number.isNaN(parsedoOfficeId)) return;

			const parsedFunnelId = !Number.isInteger(this.funnelId)
				? parseInt(this.funnelId as string)
				: this.funnelId as number;

			if (Number.isNaN(parsedFunnelId)) {
				this.funnelSteps.model.push({
					office_id: parsedoOfficeId,
					order: this.funnelSteps.model.length + 1,
					name: '',
				});

				this.scrollStepsAreaToRight();
				return;
			}

			this.funnelSteps.model.push({
				office_id: parsedoOfficeId,
				order: this.funnelSteps.model.length + 1,
				name: '',
				funnel_id: parsedFunnelId
			});

			this.scrollStepsAreaToRight();
		},

		async buttonStepRemoveClick(_e: Event, {
			id, index
		}: {
			id: string | number,
			index: number
		}): Promise<void> {
			if (!this.isEditing) {
				this.funnelSteps = {
					...this.funnelSteps,
					model: this.funnelSteps.model.filter((_, stepIndex) => stepIndex !== index),
				};
				return;
			}
			this.stepToDeleteId = id;
			this.stepToDeleteIndex = index;

			(this.$refs.modalChangeStep as typeof ModalBasic).show();
		},

		buttonCancelClick(): void {
			(this.$refs.modal as typeof ModalBasic).hide();
		},

		buttonSaveClick(): void {
			this.save();
		},

		modal_hide(): void {
			this.$router.push({
				name: 'specific-funnel', params: {id: !this.isEdit ? this.createdId : this.funnelId}
			});
		},

	},
});
</script>

<template>
	<ModalBasic
		ref="modal"
		:dismissable="!requesting.loadFunnel"
		:size="requesting.loadFunnel ? 'md' : 'lg'"
		:title="isEditing ? 'Editar fluxo de caso' : 'Novo fluxo de caso'"
		:is-create-modal="!isEditing"
		:is-edit-modal="isEditing"
		@hide="modal_hide"
	>
		<template #content>
			<template v-if="requesting.loadFunnel">
				<Loading class="opacity-50 py-14 text-center" />
			</template>
			<template v-else>
				<FormValidator
					ref="formValidatorModalFunnel"
					:refs="($refs as DefineComponent)"
					:validation-summary-show="true"
					validation-summary-class="mb-4 mx-4 p-3"
				>
					<template
						field="inputName"
						label="Nome"
						message="Preenchimento obrigatório"
						:invalidIf="() => model.name === ''"
					/>
					<template
						label="Etapas"
						message="É preciso adicionar pelo menos uma etapa"
						:invalidIf="() => funnelSteps.model.length === 0"
					/>
					<template
						label="Etapas"
						message="É preciso dar nome a todas as etapas"
						:invalidIf="() => funnelSteps.model.some(step => step.name.length === 0)"
					/>
				</FormValidator>
				<div class="mb-4 px-4">
					<InputText
						ref="inputName"
						v-model="model.name"
						class="w-full"
						label="Nome"
						custom-id="nome-fluxo"
						required
					/>
				</div>
				<div class="mb-4 px-4">
					<InputText
						ref="inputDescription"
						v-model="model.description"
						class="w-full"
						label="Descrição"
					/>
				</div>
				<hr>
				<div class="mb-8 mt-3 px-4 container-steps">
					<div class="block font-semibold mb-1 text-gray-500 text-md">
						Etapas
					</div>
					<div
						v-if="!hasValidSteps"
						class="alert bg-[#E5F0FB] font-medium rounded-md text-sm"
					>
						Nenhuma etapa
						adicionada ainda para este fluxo de casos
					</div>
					<div
						ref="containerAddedSteps"
						class="bg-[#E5F0FB] mt-4 overflow-auto p-4 relative rounded container-added-steps"
					>
						<!-- Arrows -->
						<div class="absolute flex pointer-events-none top-10">
							<template v-for="(_step, stepIndex) in funnelSteps.model">
								<div
									v-if="stepIndex < funnelSteps.model.length - 1"
									:key="`step-${stepIndex}`"
									class="bg-blue-500/25 h-16 ml-60 scale-90 -translate-x-1 w-16 step-arrow"
								/>
							</template>
						</div>
						<!-- Step items -->
						<div class="flex">
							<draggable
								v-model="funnelSteps.model"
								class="flex"
								item-key="id"
								tag="div"
							>
								<template #item="{ element, index }">
									<div
										class="bg-white border [&:not(:first-child)]:ml-16 rounded shadow-lg w-60 step-item"
									>
										<div class="flex items-start justify-between mb-4">
											<span
												class="bg-gray-100 border-b border-l border-r border-gray-300 inline-block font-semibold ml-4 px-3 rounded-br rounded-bl"
											>
												<span>{{ index + 1 }}</span>
											</span>
											<button @click="buttonStepRemoveClick($event, { id: element.id, index })">
												<svg
													class="inline fill-red-600 h-6 w-6"
													fill="currentColor"
													viewBox="0 0 20 20"
													xmlns="http://www.w3.org/2000/svg"
													aria-hidden="true"
												>
													<path
														d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"
													/>
												</svg>
											</button>
										</div>
										<div class="mb-4 px-4">
											<InputText
												v-model="element.name"
												class="border-none p-0 rounded-none w-full"
												label="Nome da etapa"
											/>
										</div>
									</div>
								</template>
							</draggable>
							<button
								:class="['text-primary', { 'ml-2': hasValidSteps }]"
								title="Adicionar estágio"
								@click="buttonStepAddClick"
							>
								<svg
									class="inline h-12 w-12"
									fill="currentColor"
									viewBox="0 0 20 20"
									xmlns="http://www.w3.org/2000/svg"
									aria-hidden="true"
								>
									<path
										clip-rule="evenodd"
										fill-rule="evenodd"
										d="M10 18a8 8 0 100-16 8 8 0 000 16zm.75-11.25a.75.75 0 00-1.5 0v2.5h-2.5a.75.75 0 000 1.5h2.5v2.5a.75.75 0 001.5 0v-2.5h2.5a.75.75 0 000-1.5h-2.5v-2.5z"
									/>
								</svg>
								<span
									v-if="funnelSteps.model.length === 0"
									class="font-semibold inline-block ml-2"
								>Adicionar primeira etapa do fluxo</span>
							</button>
						</div>
					</div>
				</div>
			</template>
		</template>
		<template
			v-if="!requesting.loadFunnel"
			#footer
		>
			<fieldset
				class="flex-1 flex overflow-hidden rounded-b-md"
				:disabled="requesting.loadFunnel"
			>
				<button
					class="flex-1 bg-gray-200 font-medium p-4 text-gray-500 hover:bg-gray-300 active:bg-gray-200"
					@click="buttonCancelClick"
				>
					CANCELAR
				</button>
				<button
					class="flex-1 bg-primary font-medium p-4 text-white hover:bg-blue-600 active:bg-primary"
					@click="buttonSaveClick"
				>
					SALVAR
				</button>
			</fieldset>
		</template>
	</ModalBasic>
	<Teleport to="body">
		<BlockingOverlay />
	</Teleport>
	<ModalBasic
		ref="modalChangeStep"
		title="Onde colocar os casos desta etapa?"
		size="md"
	>
		<template #content>
			<hr>
			<div class="flex flex-col flex-1 p-4 h-full">
				<SelectField
					v-model="substituteStep"
					:options="selectStepOptionsList"
					required
					label="Etapa substituta"
					class="flex-1"
				/>
			</div>
		</template>
		<template #footer>
			<div class="flex flex-1 overflow-hidden rounded-b-md">
				<button
					class="flex-1 bg-gray-200 font-medium p-4 text-gray-500 hover:bg-gray-300 active:bg-gray-200"
					@click.stop="closeModal"
				>
					CANCELAR
				</button>
				<button
					class="flex-1 bg-blue-500 font-medium p-4 text-white hover:bg-blue-600 active:bg-blue-500"
					@click.stop="confirmDeleteStep"
				>
					CONFIRMAR
				</button>
			</div>
		</template>
	</ModalBasic>
</template>

<style lang="scss" scoped>
.step-item {
	:deep() {

		label {
			padding: 0;
		}

		input {
			border-bottom: 1px solid #ccc;
			margin-top: 0.5em;
			padding: 0;
		}
	}
}

.step-arrow {
	clip-path: polygon(0% 20%, 50% 20%, 50% 0, 100% 50%, 50% 100%, 50% 80%, 0% 80%);

	min-width: 3rem;
}
</style>
