import {
	CRMPreferencesToSend, CRMPreferences, AccountResponse, SinglePreference
} from '../models/crm';
import { defineStore } from 'pinia';
import servicePlan from '../services/plan';
import serviceUsers from '../services/users';

import {
	User, Plan, Notification, Office
} from '../models/crm';
import axios from 'axios';

type AccountState = {
	office: Office;
	officeUsers: User[];
	notifications: Notification[];
	user: User;
	currentPlan: Plan;
	nextPlan: Plan;
	crmPreferences: CRMPreferences,
}

export const useAccountStore = defineStore('account', {
	state: (): AccountState => ({
		office: {} as Office,
		officeUsers: [] as User[],
		notifications: [] as Notification [],
		user: {} as User,
		currentPlan: {} as Plan,
		nextPlan: {} as Plan,
		crmPreferences: {} as CRMPreferences,
	}),
	actions: {
		setNotifications(obj: Notification[]): void {
			this.notifications = obj;
		},

		setNextPlan(obj: Plan): void {
			this.nextPlan = obj;
		},

		setUser(obj: User): void {
			this.user = obj;
		},

		setUserPreferences(
			obj: Record<string | number, SinglePreference>
		): void {
			if (!this.user) return;

			this.user.preferences = obj;
		},

		setAccount(obj: AccountResponse): void {
			if (obj?.user?.office_id) {
				axios.defaults.headers.common = {
					... axios.defaults.headers.common,
					'X-CJ-Office-Id': obj.user.office_id,
				};
			}

			this.office = obj.office;
			this.officeUsers = obj.users;
			this.notifications = obj.notifications;
			this.user = obj.user;
			this.nextPlan = obj.next_plan;
		},

		setOfficeFinancesSeeded(isSeeded: boolean): void {
			this.office.finances_seeded = isSeeded;
		},

		async getPlan(officeId: string | number): Promise<void> {
			const { data } = await servicePlan.getPlan(officeId);

			this.currentPlan = data.plan;
			this.office = data.office;
		},

		async getCRMPreferences(userId: string | number): Promise<void> {
			const { data } = await serviceUsers.getCRMPreferences(userId);

			this.crmPreferences = data;
		},

		async updateCRMPreferences(
			userId: string | number,
			crmPreferences: CRMPreferencesToSend
		): Promise<void> {
			const { data } = await serviceUsers.putCRMPreferences(userId, crmPreferences);

			this.crmPreferences = data;
		},

		async updateNotificationRead(
			isRead: boolean,
			officeId: number | string,
			notificationId: number | string
		): Promise<void> {
			await serviceUsers.patchNotificationRead(isRead, officeId, notificationId);

			const index = this.notifications.findIndex(
				(notification) => notification.id == notificationId
			);

			if (index > -1 && this.notifications[index]?.id) {
				(this.notifications[index] as Notification).read = isRead;
			}
		}
	},
	getters: {},
});