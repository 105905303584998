<script lang="ts">
import { defineComponent } from 'vue';
import { mapState } from 'pinia';

import { useAccountStore } from '../../../../stores/account';

import DefaultSeeds from '../../components/DefaultSeeds.vue';

export default defineComponent({
	components: {
		DefaultSeeds,
	},
	beforeRouteEnter(_to, _from, next) {
		next(
			vm => {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				if ((vm as any)?.isFinancesSeeded) {
					vm.$router.replace({name: 'settings'});
				}
			}
		);
	},
	computed: {
		...mapState(useAccountStore, [ 'office' ]),

		isFinancesSeeded(): boolean {
			return this.office.finances_seeded;
		},
	},
	methods: {

		// Events
		buttonStartNowClick(): void {
			(this.$refs.defaultSeeds as typeof DefaultSeeds).createFinanceDefaultSeeds();
		},

		createFinanceDefaultSeeds(): void {

		},

	},
});
</script>

<template>
	<section class="feature">
		<div class="text-sm w-1/2">
			<h1 class="!font-normal mb-5 mt-1">
				Seu acesso ao <b>CJ Finanças</b>!
			</h1>
			<p class="mb-5">
				Aqui você pode <b>controlar as finanças do seu escritório</b> de forma rápida e ter um controle tão bom quanto nos escritórios mais famosos.
			</p>
			<p class="mb-5">
				Você registra as <b>entradas e saídas</b> do seu escritório com as categorias e informações necessárias e depois gera <b>relatórios gerenciais completos</b> que vão te ajudar a crescer seu negócio.
			</p>
			<p class="mb-5">
				A ideia é <b>facilitar o controle das finanças</b> do seu escritório, previsões de receitas, controle de notas e comprovantes e mais.
			</p>
			<p class="mb-5">
				Essa funcionalidade <b class="text-blue-500">já está incluída no seu plano</b>. Os advogados que assinam o CJ sempre saem na frente.
			</p>
			<div class="mb-5">
				<button
					class="btn btn--ghost btn--primary w-full"
					title="Selecionando esta criaremos alguns Centros de Custos e Categorias comumente cadastrados pelos escritórios jurídicos"
					@click="buttonStartNowClick"
				>
					<span>Começar agora</span>
				</button>
			</div>
		</div>
	</section>

	<DefaultSeeds ref="defaultSeeds" />
</template>

<style lang="scss" scoped>
section.feature {
    background-image: url('/img/bg-finance.png');
    background-repeat: no-repeat;
    background-position: top right;
}
</style>
