<script lang="ts">
import {
	defineComponent, inject
} from 'vue';
import {
	EventBus, Helpers, Toast
} from '../../models/crm';

export default defineComponent({
	data() {
		return {

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			timeouts: {} as Record<string, any>,

			toasts: [] as Toast[],

			eventBus: inject<EventBus>('eventBus'),

			helpers: inject<Helpers>('helpers'),

		};
	}, // data
	computed: {

		toastsReverse(): Toast[] {
			const intermediateArray = Array.from(this.toasts);

			return intermediateArray.reverse();
		}

	}, // methods
	created() {
		this.eventBus?.on(
			'Toast/add',
			(obj: Toast): void => {
				this.add(obj);
			}
		);

		this.eventBus?.on(
			'Toast/remove',
			(uuid: string): void => {
				this.remove(uuid);
			}
		);
	}, // computed
	methods: {

		add(obj: Toast): void {
			const autoHide = obj.autoHide || true;
			const autoHideDelay = obj.autoHideDelay || 5000;
			const content = obj.content || '';
			const showCloseButton = obj.showCloseButton || true;
			const type = obj.type || 'error';
			const uuid = this.helpers?.getUuid();

			this.toasts.push(
				{
					content,
					showCloseButton,
					type,
					uuid
				}
			);

			if (autoHide && uuid) {
				this.timeouts[uuid] = setTimeout(
					() => {
						this.remove(uuid);
					},
					autoHideDelay
				);
			}
		},

		remove(uuid: string): void {
			this.toasts = this.toasts.filter(value => value.uuid !== uuid);

			clearTimeout(this.timeouts[uuid]);
			delete this.timeouts[uuid];
		},

		// Events

		buttonClose(_e: Event, uuid: string | undefined): void {
			if (!uuid) return;

			this.remove(uuid);
		}

	}, // created
});
</script>
<template>
	<div class="component-toast">
		<div
			v-for="item in toastsReverse"
			:key="item.uuid"
			:class="[
				'flex items-center justify-between relative rounded bg-white m-1 p-3 shadow-lg',
				'item-toast',
				item.type
			]"
		>
			<div
				class="flex-1 container-content mr-2"
			>
				{{ item.content }}
			</div>
			<button
				class="opacity-75"
				@click="buttonClose($event, item.uuid)"
			>
				<svg
					class="w-5 h-5"
					fill="currentColor"
					viewBox="0 0 20 20"
					xmlns="http://www.w3.org/2000/svg"
				><path
					fill-rule="evenodd"
					d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
					clip-rule="evenodd"
				/></svg>
			</button>
		</div>
	</div>
</template>
<style lang="scss" scoped>
    @keyframes slideUp {
        from {
            opacity: 0;
            top: 6px;
        }
        to {
            opacity: 1;
            top: 0;
        }
    }

    .component-toast
    {
        bottom: 0;
        right: 0;
        position: fixed;
        max-width: 100%;
        z-index: 10001;

        .item-toast
        {
            animation-duration: 0.35s;
            animation-name: slideUp;

            &.error
            {
                background-color: #D73542;
                color: #fff;
            }

            &.success
            {
                background-color: #28A345;
                color: #fff;
            }

            &.warning
            {
                background-color: #F9BD24;
                color: #000;
            }

            .container-button button
            {
                background-color: transparent;
                border: none;
                line-height: 100%;
            }

        }
    }
</style>