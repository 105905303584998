<script lang="ts">
import serviceContacts from '../../../../services/API/crm/contacts';

import {
	defineComponent, inject, PropType
} from 'vue';
import {
	EventBus,
	Luxon,
	ContactNote,
	EditorJSOutput,
} from '../../../../models/crm';

import { OutputData } from '@editorjs/editorjs';

import BlockingOverlay from '../../../../components/BlockingOverlay/BlockingOverlay.vue';
import EditorJs from '../../../../components/Editor/EditorJs.vue';

type SingleNoteState = {
	current: string | OutputData,
	isValueDiff: boolean,
	old: string | OutputData,
}

type NotesState = {
	[key: string | number]: SingleNoteState
}

export default defineComponent({
	components: {
		BlockingOverlay,
		EditorJs,
	},
	props: {
		contactId: {
			required: true,
			type: [ Number, String ]
		},
		notes: {
			required: true,
			type: Array as PropType<ContactNote[]>,
		}
	},
	emits: [
		'note-created',
		'note-deleted',
		'note-updated',
	],
	data() {
		return {

			initialQuantity: 3 as number,

			isAdding: false as boolean,

			notesState: {} as NotesState,

			showAll: false as boolean,

			eventBus: inject<EventBus>('eventBus'),

			luxon: inject<Luxon>('luxon'),

			oldNotesReadOnly: false as boolean,

		};
	},
	computed: {

		notesFiltered(): ContactNote[] {
			if (!this.showAll) {
				return this.notes.slice(0, this.initialQuantity);
			}
			return this.notes;
		},

	},
	mounted() {
		this.notes.forEach(
			(item): void => {
				const noteContent = item.editorjs_object || item.content;

				if (noteContent !== null && noteContent !== undefined) {
					this.notesState[item.id] = {
						current: noteContent,
						isValueDiff: false,
						old: noteContent,
					};
				}
			}
		);
	},
	methods: {

		getTitleUpdatedAt(note: ContactNote): string {
			return `Última atualização: ${this.luxon?.fromISO(note.updated_at).toFormat('dd/MM/yyyy HH:mm')}`;
		},

		// Events

		buttonAddClick(): void {
			this.isAdding = true;
		},

		buttonAddNoteCancelClick(): void {
			this.isAdding = false;
		},

		toggleReadOnly(): void {
			this.oldNotesReadOnly = !this.oldNotesReadOnly;
		},

		async buttonAddNoteSaveClick(): Promise<void> {
			const {contactId} = this;
			const { html: content } = await (this.$refs.editorNoteAdd as typeof EditorJs).getOutput();

			this.eventBus?.emit('BlockingOverlay/show', {
				text: 'Aguarde, salvando anotação'
			});

			try {
				await serviceContacts.noteCreate({
					contactId,
					content,
				});

				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Anotação criada com sucesso',
						type: 'success'
					}
				);

				this.isAdding = false;

				this.$emit('note-created');
			} catch (err) {
				console.error(err);

				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Falha ao salvar anotação',
						type: 'error'
					}
				);
			} finally {
				this.eventBus?.emit('BlockingOverlay/hide');
			}
		},

		async buttonSavedNoteSaveClick(_e: Event, noteId: number): Promise<void> {
			const {contactId} = this;
			const output = await (this.$refs[`editorNote_${noteId}`] as (typeof EditorJs)[])[0]?.getOutput();

			if (output?.content) {
				const {content} = output;

				this.eventBus?.emit('BlockingOverlay/show', {
					text: 'Aguarde, salvando anotação'
				});

				try {
					await serviceContacts.noteUpdate({
						contactId,
						content,
						noteId,
					});

					this.eventBus?.emit(
						'Toast/add',
						{
							content: 'Anotação atualizada com sucesso',
							type: 'success'
						}
					);

					this.$emit('note-updated', noteId);
				} catch (err) {
					console.error(err);

					this.eventBus?.emit(
						'Toast/add',
						{
							content: 'Falha ao salvar anotação',
							type: 'error'
						}
					);
				} finally {
					this.eventBus?.emit('BlockingOverlay/hide');
				}
			}
		},

		async buttonSavedNoteDeleteClick(_e: Event, noteId: number): Promise<void> {
			const {contactId} = this;

			this.eventBus?.emit('BlockingOverlay/show', {
				text: 'Aguarde, excluindo anotação'
			});

			try {
				await serviceContacts.noteDelete({
					contactId,
					noteId,
				});

				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Anotação excluída com sucesso',
						type: 'success'
					}
				);

				this.$emit('note-deleted', noteId);
			} catch (err) {
				console.error(err);

				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Falha ao excluir anotação',
						type: 'error'
					}
				);
			} finally {
				this.eventBus?.emit('BlockingOverlay/hide');
			}
		},

		async buttonSavedNoteUndoClick(_e: Event, noteId: number): Promise<void> {
			const old = this.notesState[noteId]?.old;

			if (old) {
				this.notesState[noteId] = {
					old: old,
					current: old,
					isValueDiff: false,
				};

				(this.$refs[`editorNote_${noteId}`] as (typeof EditorJs)[])[0]?.clear();
				(this.$refs[`editorNote_${noteId}`] as (typeof EditorJs)[])[0]?.renderFromHtml(old);
			}
		},

		buttonShowAllClick(): void {
			this.showAll = true;
		},

		savedNotesChange(output: EditorJSOutput, noteId: number): void {
			const isValueDiff = (current: string | OutputData, old: string | OutputData): boolean => {
				if (!this.notesState[noteId]) return false;
				return current !== old;
			};

			const { html } = output;

			const old = this.notesState[noteId]?.old;

			if (old) {
				this.notesState[noteId] = {
					old: old,
					current: html,
					isValueDiff: isValueDiff(html, old),
				};
			}
		},

	},
});
</script>
<template>
	<div class="component-quick-notes">
		<div class="flex items-center justify-between font-semibold mb-2 text-gray-500 text-xs">
			<span>
				<span>Anotações</span>
				<span
					v-if="notes.length > 0"
					class="ml-1"
				>({{ notes.length }})</span>
			</span>
			<button
				v-if="!isAdding"
				class="bg-blue-500 ml-1 px-1.5 py-0.5 rounded text-white text-[11px]"
				@click="buttonAddClick"
			>
				+ ADICIONAR
			</button>
		</div>
		<div
			v-if="isAdding"
			class="bg-yellow-100 border mb-1 p-2 pt-1"
		>
			<EditorJs
				ref="editorNoteAdd"

				:config="{ hideToolbar: true, readOnly: oldNotesReadOnly }"
				data="&zwnj;"
				placeholder="Texto da anotação..."
			/>
			<hr class="border-t border-black/10">
			<div class="mt-1 text-right">
				<button
					class="px-1 py-0.5 rounded-sm text-[11px]"
					@click="buttonAddNoteCancelClick"
				>
					CANCELAR
				</button>
				<button
					class="bg-green-600 ml-1 px-1 py-0.5 rounded-sm text-white text-[11px]"
					@click="buttonAddNoteSaveClick"
				>
					SALVAR
				</button>
			</div>
		</div>
		<div
			v-for="(note, noteIndex) in notesFiltered"
			:key="`note-${noteIndex}`"
			class="bg-white border mb-1 p-2 pt-1"
		>
			<EditorJs
				:ref="`editorNote_${note.id}`"

				:config="{ hideToolbar: true, readOnly: oldNotesReadOnly }"
				:in-data="note.content ? note.content : ''"
				placeholder="Texto da anotação..."

				@change="savedNotesChange($event, note.id)"
			/>
			<hr>
			<div
				v-if="notesState[note.id]"
				class="flex items-center justify-between min-w-0 mt-1 text-right"
			>
				<div
					class="flex-1 flex items-center justify-start overflow-hidden text-gray-500 text-xs whitespace-nowrap"
					:title="getTitleUpdatedAt(note)"
				>
					<svg
						class="inline h-4 shrink-0 w-4"
						fill="currentColor"
						viewBox="0 0 24 24"
						xmlns="http://www.w3.org/2000/svg"
						aria-hidden="true"
					>
						<path
							clip-rule="evenodd"
							fill-rule="evenodd"
							d="M6.75 2.25A.75.75 0 017.5 3v1.5h9V3A.75.75 0 0118 3v1.5h.75a3 3 0 013 3v11.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V7.5a3 3 0 013-3H6V3a.75.75 0 01.75-.75zm13.5 9a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5z"
						/>
					</svg>
					<span class="ml-1">{{ luxon?.fromISO(note.updated_at).toFormat("dd/MM/yyyy") }}</span>
					<span
						v-if="note.creator"
						class="ml-1 overflow-hidden text-ellipsis"
					>- {{ note.creator.name }}</span>
				</div>
				<div
					v-if="oldNotesReadOnly"
					class="flex items-center justify-end"
				>
					<button
						class="bg-blue-500 ml-2 px-1 py-0.5 rounded-sm text-white text-[10px]"
						title="Editar"
						type="button"
						@click="toggleReadOnly"
					>
						<svg
							class="inline w-6 h-6"
							fill="currentColor"
							viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg"
							aria-hidden="true"
						>
							<path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32L19.513 8.2z" />
						</svg>
					</button>
				</div>
				<div class="flex items-stretch">
					<button
						class="bg-red-600 ml-2 px-1 py-0.5 rounded-sm text-white text-[10px]"
						title="Excluir"
						@click="buttonSavedNoteDeleteClick($event, note.id)"
					>
						<svg
							class="h-3 w-3"
							fill="currentColor"
							viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg"
							aria-hidden="true"
						>
							<path
								clip-rule="evenodd"
								fill-rule="evenodd"
								d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
							/>
						</svg>
					</button>
					<template v-if="notesState[note.id]?.isValueDiff">
						<button
							class="bg-gray-400 ml-1 px-1 py-0.5 rounded-sm text-white text-[10px]"
							title="Desfazer"
							@click="buttonSavedNoteUndoClick($event, note.id)"
						>
							<svg
								class="h-3 w-3"
								fill="currentColor"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
								aria-hidden="true"
							>
								<path
									clip-rule="evenodd"
									fill-rule="evenodd"
									d="M9.53 2.47a.75.75 0 010 1.06L4.81 8.25H15a6.75 6.75 0 010 13.5h-3a.75.75 0 010-1.5h3a5.25 5.25 0 100-10.5H4.81l4.72 4.72a.75.75 0 11-1.06 1.06l-6-6a.75.75 0 010-1.06l6-6a.75.75 0 011.06 0z"
								/>
							</svg>
						</button>
						<button
							class="bg-green-600 ml-1 px-1 py-0.5 rounded-sm text-white text-[11px]"
							title="Salvar"
							@click="buttonSavedNoteSaveClick($event, note.id)"
						>
							SALVAR
						</button>
					</template>
				</div>
			</div>
		</div>
		<div
			v-if="notes.length > initialQuantity"
			class="flex items-center justify-between"
		>
			<div>
				<span
					v-if="!showAll"
					class="opacity-60 text-xs"
				>Exibindo as {{ initialQuantity }} últimas anotações</span>
			</div>
			<div>
				<button
					v-if="!showAll"
					class="font-semibold py-1 text-blue-500 text-xs underline uppercase"
					@click="buttonShowAllClick"
				>
					Mostrar todas
				</button>
			</div>
		</div>
	</div>
	<Teleport to="body">
		<BlockingOverlay />
	</Teleport>
</template>
<style lang="scss" scoped>
:deep(.codex-editor) {
    min-height: 2rem;

    .codex-editor__redactor {
        font-size: 0.9rem;
        padding-bottom: 0 !important;
    }
}
</style>
