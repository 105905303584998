<script lang="ts">
import draggable from 'vuedraggable';

import serviceFunnels from '../../../../services/API/crm/funnels';

import BlockingOverlay from '../../../../components/BlockingOverlay/BlockingOverlay.vue';
import DropdownSelectFunnel from './DropdownSelectFunnel.vue';
import ModalFunnel from '../../views/Funnels/ModalFunnel.vue';
import ModalBasic from '../../../../components/Modal/ModalBasic.vue';
import SelectField from '../../../../components/Form/SelectField.vue';

import {
	defineComponent, inject
} from 'vue';
import {
	EventBus, Luxon, Funnel, FunnelStep, LegalCase
} from '../../../../models/crm';

import { useLegalCasesStore } from '../../../../stores/models/crm/legalCases';
import { useAccountStore } from '../../../../stores/account';
import { useFunnelsStore } from '../../../../stores/models/crm/funnels';

import {
	mapActions, mapState
} from 'pinia';

export default defineComponent({
	components: {
		draggable,

		BlockingOverlay,
		DropdownSelectFunnel,
		ModalFunnel,
		ModalBasic,
		SelectField
	},
	emits: [ 'selected-funnel' ],
	data() {
		return {

			requesting: {
				dragToFunnelStep: false as boolean,
				deleteFunnel: false,
				loadFunnels: false,
				defineMainFunnel: false,
				createDefaultFunnels: false,
			},

			selectedFunnel: null as Funnel | null,

			funnelToDeleteId: '' as string | number,
			substituteFunnel: '' as string | number,
			substituteStep: '' as string | number,

			order: {
				column: 'name',
				sort: 'asc',
			},

			eventBus: inject<EventBus>('eventBus'),
			luxon: inject<Luxon>('luxon'),

		};
	},
	computed: {
		...mapState(useAccountStore, [ 'user' ]),
		...mapState(useFunnelsStore, [ 'funnelsList', 'maxFunnels', 'isRequestingFunnels' ]),
		...mapState(useLegalCasesStore, [ 'legalCasesList' ]),

		perPage(): number {
			const preferenceId = 'ui:data_table_funnels';
			const userPreferences = this.user?.preferences;
			const specificPreference = userPreferences && userPreferences[preferenceId]?.recordsPerPage;

			return (specificPreference as number | undefined) || 25;
		},

		funnelsSteps(): FunnelStep[] {
			return (this.selectedFunnel &&
				[ ...this.selectedFunnel.funnel_steps ]
					.map((step) => ({
						...step, legal_cases: step.legal_cases?.filter(item => item.is_active)
					}))
					?.sort((first, second) => first.order - second.order)
			) || [];
		},

		selectFunnelOptionsList(): {label: string, value: number}[] {
			if (this.funnelsList) {
				return this.funnelsList.map((funnel: Funnel) => ({
					label: funnel.name
					, value: funnel.id
				})).filter(option => option.value != this.funnelToDeleteId);
			}

			return [];
		},

		selectStepOptionsList(): {label: string, value: number}[] {
			if (this.funnelsList && !!this.substituteFunnel) {
				return this.funnelsList
					.find((funnel: Funnel) => funnel.id == this.substituteFunnel)
					?.funnel_steps?.map((step: FunnelStep) => ({
						label: step.name
						, value: step.id
					})) || [];
			}

			return [];
		},

	},
	watch: {
		'$route.path': {
			deep: true,
			immediate: true,
			async handler(newValue, oldValue): Promise<void> {
				if (newValue?.includes('fluxos') && newValue !== oldValue) {
					if (this.funnelsList.length === 0) await this.callLoadFunnels();
					await this.setSelectedFunnelFromUrlParam();

					if (oldValue) {
						if (newValue.includes('/criar')) {
							(this.$refs.modalFunnel as typeof ModalFunnel).show(false);

							return;
						}
						if (newValue.includes('editar')) {
							(this.$refs.modalFunnel as typeof ModalFunnel).show(true);

							return;
						}
					}
				}
			}

		}
	},
	mounted(): void {
		if (this.$route.path.includes('fluxos')) {
			if (this.$route.path.includes('criar')) {
				(this.$refs.modalFunnel as typeof ModalFunnel).show(false);

				return;
			}
			if (this.$route.path.includes('editar')) {
				(this.$refs.modalFunnel as typeof ModalFunnel).show(true);

				return;
			}
		}
	},
	methods: {
		...mapActions(useFunnelsStore, [
			'deleteFunnel',
			'paginatedLoadFunnels',
			'defineMainFunnel',
			'createDefaultFunnels',
			'updateFunnel'
		]),

		async callLoadFunnels(forceReload = false): Promise<void> {
			try {
				this.requesting.loadFunnels = true;

				const orderBy = `${this.order.column}:${this.order.sort}`;

				if (this.funnelsList.length > 0 && !forceReload) {
					this.requesting.loadFunnels = false;

					return;
				}

				await this.paginatedLoadFunnels({
					page: 1, perPage: this.perPage, orderBy, filters: ''
				});

				if (this.selectedFunnel?.id) {
					const foundFunnel = this.funnelsList
						.find(item => item.id == this.selectedFunnel?.id);

					if (!foundFunnel) {
						const response = await serviceFunnels.getById(this.selectedFunnel.id);

						if (response.status !== 200) {
							throw new Error('Fluxo de casos não encontrado');
						}

						this.selectedFunnel = response.data;
					} else {
						this.selectedFunnel = foundFunnel;
					}
				}

				this.requesting.loadFunnels = false;
			} catch (err) {
				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Erro ao carregar fluxos de casos',
						type: 'error'
					}
				);
			}
		},

		setFunnelParamInUrl(funnel: Funnel): void {
			this.$router.push({
				name: 'specific-funnel',
				params: { id: funnel.id }
			});
		},

		async setSelectedFunnelFromUrlParam(): Promise<void> {
			if (this.$route.path.includes('criar')) return;

			const paramFunnel = this.$route.params.id;

			if (!paramFunnel) {
				const chosenFunnel = this.funnelsList.find(item => item.is_main) || this.funnelsList[0];

				if (!chosenFunnel) return;

				this.setFunnelParamInUrl(chosenFunnel);

				return;
			}

			this.selectedFunnel = this.funnelsList.find(item => item.id == Number(paramFunnel)) || null;

			if (!this.selectedFunnel) {
				try {
					const response = await serviceFunnels.getById(Number(paramFunnel));
					this.selectedFunnel = response.data;
				} catch (e) {
					console.error(e);

					this.eventBus?.emit(
						'Toast/add',
						{
							content: 'Erro ao carregar fluxo de casos',
							type: 'error'
						}
					);
				}

				if (!this.selectedFunnel) {
					this.$router.push({name: 'funnels'});

					return;
				}
			}

			const sortedFunnelSteps =
				[ ... this.selectedFunnel.funnel_steps ]
					.sort((first, second) => first.order - second.order)
					|| [];

			this.selectedFunnel = {
				...this.selectedFunnel,
				funnel_steps: sortedFunnelSteps,
			};

			this.$emit('selected-funnel', this.selectedFunnel);
		},

		getPendingTasksQuantity(legalCase: LegalCase): number {
			const selectedCase = this.legalCasesList.find(item => item.id == legalCase.id);
			const pendingTasks = selectedCase?.tasks?.filter((item) => !item.done) || [];
			return pendingTasks.length;
		},

		async callDeleteFunnel(id: string | number): Promise<void> {
			this.eventBus?.emit('BlockingOverlay/show', {
				text: 'Aguarde, excluindo fluxo de casos'
			});

			try {
				this.requesting.deleteFunnel = true;

				await this.deleteFunnel(id, this.substituteStep);

				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Fluxo de casos excluído com sucesso',
						type: 'success'
					}
				);

				this.requesting.deleteFunnel = false;

				this.eventBus?.emit('BlockingOverlay/hide');
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (err: any) {
				console.error(err);

				this.eventBus?.emit(
					'Toast/add',
					{
						content: (err?.message as string) || 'Erro ao excluir fluxo de casos',
						type: 'error'
					}
				);

				this.eventBus?.emit('BlockingOverlay/hide');
			}
		},

		hasCases(funnelId: string | number): boolean {
			const funnel = this.funnelsList.find(funnel => funnel.id == funnelId);

			return funnel && funnel.active_cases_count > 0 || false;
		},

		resetModalChangeFunnel(): void {
			this.funnelToDeleteId = '';
			this.substituteFunnel = '';
			this.substituteStep = '';
		},

		closeModal(modalRef: string): void {
			(this.$refs[modalRef] as typeof ModalBasic).hide();
			this.resetModalChangeFunnel();
		},

		async confirmDeleteFunnel(modalRef: string): Promise<void> {
			try {
				if (!this.funnelToDeleteId) {
					throw new Error('Fluxo de casos não encontrado');
				}

				if (this.hasCases(this.funnelToDeleteId)
					&& (!this.substituteFunnel
					|| !this.substituteStep)
				) {
					throw new Error('Não foram especificados o fluxo de caso e/ou a etapa substitutos');
				}

				await this.callDeleteFunnel(this.funnelToDeleteId);

				(this.$refs[modalRef] as typeof ModalBasic).hide();

				this.resetModalChangeFunnel();

				this.$router.push({ name: 'funnels' });

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (e: any) {
				console.error(e);

				this.eventBus?.emit(
					'Toast/add',
					{
						content: (e?.message as string) || 'Erro ao deletar funil',
						type: 'error'
					}
				);
			}
		},

		async callDefineMainFunnel(id: string | number): Promise<void> {
			if (!this.user?.office_id) return;
			this.eventBus?.emit('BlockingOverlay/show', {
				text: 'Aguarde, definindo fluxo de caso principal'
			});

			try {
				this.requesting.defineMainFunnel = true;

				await this.defineMainFunnel(id);

				this.$router.push({ name: 'funnels' });

				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Fluxo de casos principal definido com sucesso',
						type: 'success'
					}
				);

				this.requesting.defineMainFunnel = false;
			} catch (err) {
				console.error(err);

				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Erro ao definir fluxo de casoprincipal',
						type: 'error'
					}
				);
			} finally {
				this.eventBus?.emit('BlockingOverlay/hide');
			}
		},

		// Events

		async change(e: Event, { step } : { step: FunnelStep}): Promise<void> {
			// The added element
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const legalCase = e.added;
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const movedLegalCase = e.moved;

			if (movedLegalCase) {
				const originalStep: FunnelStep | undefined =
					this.selectedFunnel?.
						funnel_steps
						.find((s) => s.id === step.id);

				const toElementId =
					originalStep?.legal_cases?.filter((lc) => lc.is_active).find((item, index) => index === movedLegalCase.newIndex)?.id;


				const toElementOriginalIndex =
					originalStep?.legal_cases?.findIndex((item) => item.id === toElementId)
					|| movedLegalCase.newIndex;

				this.eventBus?.emit('BlockingOverlay/show');
				try {
					const {data} = await serviceFunnels.moveLegalCaseInsideFunnelStep({
						legalCaseId: movedLegalCase.element.id,
						funnelStepId: step.id,
						newPosition: toElementOriginalIndex
					});

					this.selectedFunnel?.funnel_steps.forEach((item) => {
						if (item.id == step.id) {
							item.legal_cases = data.legal_cases;
						} else {
							item.legal_cases = item.legal_cases?.filter((legal_case) => {
								return legal_case.id !== movedLegalCase.element.id;
							});
						}
					});
				} catch (err) {
					console.error(err);

					this.eventBus?.emit(
						'Toast/add',
						{
							content: 'Erro ao alterar etapa do fluxo',
							type: 'error'
						}
					);
				} finally {
					this.eventBus?.emit('BlockingOverlay/hide');
				}
			}

			/**
             * Change is called for both the new funnel step (added)
             * and the old funnel step (removed). But we only want
             * to trigger the action for the new.
             */
			if (!legalCase) return;

			try {
				const originalStep: FunnelStep | undefined =
					this.selectedFunnel?.
						funnel_steps
						.find((s) => s.id === step.id);

				if (!originalStep || !originalStep.legal_cases) return;

				const toElementId =
					originalStep.legal_cases.filter((lc) => lc.is_active).find((item, index) => index === legalCase.newIndex)?.id;

				const toElementOriginalIndex =
					originalStep.legal_cases.findIndex((item) => item.id === toElementId);

				const newPosition = toElementOriginalIndex < 0 ? originalStep.legal_cases.length : toElementOriginalIndex;

				this.eventBus?.emit('BlockingOverlay/show');

				const {data} = await serviceFunnels.dragLegalCaseToFunnelStep({
					funnelStepId: step.id,
					legalCaseId: legalCase.element.id,
					newPosition: newPosition,
				});

				this.selectedFunnel?.funnel_steps.forEach((item) => {
					if (item.id == step.id) {
						item.legal_cases = data.legal_cases;
					} else {
						item.legal_cases = item.legal_cases?.filter((legal_case) => {
							return legal_case.id !== legalCase.element.id;
						});
					}
				});

				this.eventBus?.emit('BlockingOverlay/hide');
			} catch (err) {
				console.error(err);

				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Erro ao alterar etapa do fluxo',
						type: 'error'
					}
				);
			}
		},

		selectFunnel(funnel: Funnel): void {
			this.setFunnelParamInUrl(funnel);
		},

		cardClick(row: LegalCase): void {
			this.$router.push({
				name: 'legal-case-details', params: { id: row.id }
			});
		},

		buttonCreateClick(): void {
			this.$router.push({
				name: 'create-funnel'
			});
		},

		buttonEditClick(): void {
			this.$router.push({
				name: 'edit-funnel', params: { id: this.selectedFunnel?.id }
			});
		},

		async buttonDefineMainClick(): Promise<void> {
			if (!this.selectedFunnel?.id) return;

			await this.callDefineMainFunnel(this.selectedFunnel.id);
		},

		async buttonDeleteClick(funnelId?: string | number): Promise<void> {
			if (!funnelId) return;

			this.funnelToDeleteId = funnelId;

			if (this.hasCases(funnelId)) {
				(this.$refs.modalChangeFunnel as typeof ModalBasic).show();
				return;
			}

			await this.confirmDeleteFunnel('modalChangeFunnel');
		},

		async buttonCreateDefaultFunnels(): Promise<void> {
			if (!this.user?.office_id) return;

			const officeId = this.user.office_id;

			this.eventBus?.emit('BlockingOverlay/show', {
				text: 'Aguarde, criando fluxos de casos'
			});

			try {
				this.requesting.createDefaultFunnels = true;

				await this.createDefaultFunnels(officeId);

				this.$router.push({ name: 'funnels' });

				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Fluxos de Casos criados com sucesso',
						type: 'success'
					}
				);

				this.requesting.createDefaultFunnels = false;
			} catch (err) {
				console.error(err);

				this.eventBus?.emit(
					'Toast/add',
					{
						content: 'Erro ao criar Fluxos de Casos',
						type: 'error'
					}
				);
			} finally {
				this.eventBus?.emit('BlockingOverlay/hide');
			}
		},

		async modalFunnel_funnelCreated(funnel?: Funnel): Promise<void> {
			if (!funnel) {
				await this.callLoadFunnels(true);

				return;
			}

			this.updateFunnel(funnel);
		},

		async modalFunnel_funnelUpdated(funnel?: Funnel): Promise<void> {
			if (!funnel) {
				await this.callLoadFunnels(true);

				return;
			}

			this.updateFunnel(funnel);
		},

		async modalFunnel_funnelStepsUpdated(): Promise<void> {
			await this.callLoadFunnels(true);
		},

	},
});
</script>
<template>
	<div class="flex flex-1 flex-col min-h-0 min-w-0 component-legal-cases-mode-funnel">
		<template v-if="selectedFunnel">
			<div>
				<hr class="mb-5 mt-3">
				<div class="flex items-center justify-between mb-5 flex-wrap">
					<template v-if="!isRequestingFunnels && funnelsList.length === 0">
						<div class="flex flex-column justify-center items-center gap-3">
							<span class="text-xl">
								Ainda não há fluxos de casos criados
							</span>
							<hr>
							<div class="flex items-center gap-1">
								<button
									class="btn btn--primary !px-2 !py-1"
									@click="buttonCreateDefaultFunnels"
								>
									Clique aqui
								</button>
								<span>para criar os fluxos de casos padrão do sistema.</span>
							</div>
						</div>
					</template>
					<div
						v-else
						class="flex items-center gap-4 flex-wrap"
					>
						<span class="text-xl font-semibold">
							Fluxo de casos:
						</span>
						<div class="flex m-0 p-0">
							<dropdown-select-funnel
								:funnels="funnelsList"
								:selected-funnel="selectedFunnel"
								@funnel-selected="selectFunnel"
							/>
							<button
								id="btn-create-funnel"
								v-tippy="{ content: 'Criar fluxo de casos' }"
								class="btn btn--primary btn--ghost h-auto min-h-0 !px-1.5 !py-2"
								style="border-bottom-left-radius: 0; border-top-left-radius: 0;"
								@click="buttonCreateClick"
							>
								<svg
									class="inline w-5 h-5"
									fill="currentColor"
									viewBox="0 0 24 24"
								>
									<path d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
								</svg>
							</button>
						</div>
					</div>
					<div class="flex items-center gap-2">
						<button
							v-if="!selectedFunnel?.is_main"
							id="btn-define-main-funnel"
							v-tippy="{ content: 'Definir Fluxo de Casos como principal' }"
							class="btn btn--yellow h-auto min-h-0 !px-2 !py-2"
							@click="buttonDefineMainClick"
						>
							<svg
								class="inline w-5 h-5"
								fill="currentColor"
								viewBox="0 0 20 20"
								xmlns="http://www.w3.org/2000/svg"
								aria-hidden="true"
							>
								<path
									clip-rule="evenodd"
									fill-rule="evenodd"
									d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
								/>
							</svg>
						</button>
						<button
							id="btn-edit-funnel"
							v-tippy="{ content: 'Editar fluxo de casos' }"
							class="btn btn--primary h-auto min-h-0 !px-2 !py-2"
							@click="buttonEditClick"
						>
							<svg
								class="inline w-5 h-5"
								fill="currentColor"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
								aria-hidden="true"
							>
								<path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32L19.513 8.2z" />
							</svg>
						</button>

						<button
							id="btn-delete-funnel"
							v-tippy="{ content: 'Excluir fluxo de casos' }"
							class="btn btn--red h-auto min-h-0 !px-2 !py-2"
							@click="buttonDeleteClick(selectedFunnel?.id)"
						>
							<svg
								class="inline w-5 h-5"
								fill="currentColor"
								viewBox="0 0 20 20"
								xmlns="http://www.w3.org/2000/svg"
							><path
								fill-rule="evenodd"
								d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
								clip-rule="evenodd"
							/></svg>
						</button>
					</div>
				</div>
			</div>
			<div class="flex flex-1 gap-3 overflow-auto pb-4">
				<template
					v-for="step in funnelsSteps"
					:key="`step-${step.id}`"
				>
					<div
						class="flex flex-col bg-gray-200 border-gray-300 p-3 rounded min-w-[300px] container-step"
						style="max-width: min(400px, 50%);"
						:data-step="step"
					>
						<div class="font-bold text-gray-500 text-xs uppercase">
							{{ step.name }}
						</div>
						<hr class="border-gray-300 my-3">
						<div class="flex-1 flex flex-col overflow-y-auto">
							<draggable
								ghost-class="ghost"
								group="default"
								:list="step.legal_cases"
								item-key="name"
								class="flex-1"

								@change="change($event, { step })"
							>
								<template #item="{ element }">
									<div
										class="cursor-grab mb-3 rounded list-group-item"
										@click="cardClick(element)"
									>
										<div class="bg-white shadow-md p-2 rounded list-group-item-inner">
											<div
												class="font-medium mb-2 max-w-[330px] text-blue-500 overflow-hidden text-ellipsis"
											>
												{{ element.name }}
											</div>
											<div class="text-xs">
												<b>Pendências:</b>
												<span class="ml-1">{{ getPendingTasksQuantity(element) }}</span>
											</div>
											<div class="text-xs">
												<b>Última atualização:</b>
												<span class="ml-1">
													{{ luxon?.fromISO(element.updated_at).toFormat('dd/MM/yyyy') }}
												</span>
											</div>
										</div>
									</div>
								</template>
							</draggable>
						</div>
					</div>
				</template>
			</div>
		</template>

	</div>

	<ModalFunnel
		ref="modalFunnel"
		:funnel-id="selectedFunnel?.id || ($route.params.id as string | undefined)"
		:funnels-list="funnelsList"

		@funnel-created="modalFunnel_funnelCreated"
		@funnel-updated="modalFunnel_funnelUpdated"
		@funnel-steps-updated="modalFunnel_funnelStepsUpdated"
	/>

	<ModalBasic
		ref="modalChangeFunnel"
		title="Onde colocar os casos deste fluxo?"
		size="md"
	>
		<template #content>
			<hr>
			<div
				class="flex flex-col flex-1 gap-4 p-4 h-full"
			>
				<SelectField
					v-model="substituteFunnel"
					:options="selectFunnelOptionsList"
					required
					label="Fluxo de casos substituto"
					class="flex-1"
				/>
				<SelectField
					v-model="substituteStep"
					:disabled="!substituteFunnel"
					:options="selectStepOptionsList"
					required
					label="Etapa do fluxo substituto"
					class="flex-1"
				/>

			</div>
		</template>
		<template #footer>
			<div class="flex flex-1 overflow-hidden rounded-b-md">
				<button
					class="flex-1 bg-gray-200 font-medium p-4 text-gray-500 hover:bg-gray-300 active:bg-gray-200"
					@click.stop="closeModal('modalChangeFunnel')"
				>
					CANCELAR
				</button>
				<button
					class="flex-1 bg-blue-500 font-medium p-4 text-white hover:bg-blue-600 active:bg-blue-500"
					@click.stop="confirmDeleteFunnel('modalChangeFunnel')"
				>
					CONFIRMAR
				</button>
			</div>
		</template>
	</ModalBasic>

	<Teleport to="body">
		<BlockingOverlay />
	</Teleport>
</template>
<style lang="scss" scoped>
.ghost {
    background-color: rgba(255, 255, 255, 0.65);
    border: 1px dotted rgba(0, 0, 0, 0.125);

    .list-group-item-inner {
        opacity: 0;
    }
}
</style>
